import React, { useEffect, useState } from "react";
import "./SchedulerFilters.scss";
import { withTranslation } from 'react-i18next';
import { useStaffDesignation } from 'repositories/scheduler-repository';
import { useAllStaffByDesignationIdAndOfficeId } from 'repositories/notes-repository';
import Select from "react-select";
import Loader from "components/Loader";
import SearchIcon from "../../../../../assets/images/search.svg";
import FilterIcon from "../../../../../assets/images/new_filter_icon.svg";
import styles from "../../../../pages/ManagerDashboard/Dashboard.module.scss";
import Text from "components/Text";

function EmployeesFilter({ t , selectedEmpFilter, setEmpFilter, ownerId, selectedOfficeFilter, selectedRoles, setSelectedRoles, ownerData }) {
     const [rolesData, setRolesData] = useState([]);
      const [selectAll, setSelectAll] = useState(false);
      const [selectDropdown, setSelectDropdown] = useState(true);
      const [staffData, setStaffData] = useState([]);
      const [filteredStaffData, setFilteredStaffData] = useState([]);
      const [searchValue, setSearchValue] = useState("");
      const [selectedOption, setSelectedOption] = useState({
        value: "",
        label: "All Roles",
      });
    const { isLoading: loadinStaffData, data: DashBoardRoleData } =
        useStaffDesignation();
    const { data, isLoading } = useAllStaffByDesignationIdAndOfficeId(
        selectedRoles,
        selectedOfficeFilter,
        ownerId,
        { enabled: !!ownerId }
    );


    useEffect(() => {
        if (!loadinStaffData && DashBoardRoleData?.length) {
          let flatData = DashBoardRoleData?.map(
            (item) => item?.designations
          )?.flat();
          if (flatData?.length) {
            let data = flatData?.map((item) => {
              return {
                label: item?.name,
                value: item?.id,
              };
            });
            setRolesData([{ value: "", label: "All Roles" }, ...data]);
          }
        }
      }, [loadinStaffData, DashBoardRoleData]);
    
      const handleMemberType = (selected) => {
        setSelectedOption(selected);
        if (selected?.value) {
          setSelectedRoles([selected?.value]);
        } else {
          setSelectedRoles([]);
        }
      };
    
      useEffect(() => {
        if (!isLoading && data && data?.data) {
          let name =ownerData?.role?.id ? t("dashBoard.mySelf") : t("dashBoard.AccountOwner"); 
          const updatedStaff = [
            {
              id: ownerData?.id,
              userId: ownerData?.id,
              user: { ...ownerData },
              designation: { name },
            },
            ...data.data];
          setStaffData(updatedStaff);
          setFilteredStaffData(updatedStaff);
        }
      }, [isLoading, data]);
    
      useEffect(() => {
        if (selectedEmpFilter?.length === staffData?.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }, [selectedEmpFilter, staffData]);
    
      const handleChange = (e, item) => {
        if (e.target.checked) {
            setEmpFilter([
            ...selectedEmpFilter,
            item?.userId
          ]);
        } else {
            setEmpFilter(state => state.filter(thisId => thisId !== item?.userId))
        }
        setSelectAll(false);
      };
    
      const handleAllChange = (checked = false) => {
        setSelectAll(checked);
        if (checked) {
            setEmpFilter(staffData?.length ? staffData?.map((item) => item?.userId) : []);
        } else {
            setEmpFilter([]);
        }
      };

    
      const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
    
        if (value.trim() === "") {
          setFilteredStaffData(staffData);
        } else {
          setFilteredStaffData(
            staffData?.filter((item) =>
              `${item?.user?.firstName} ${item?.user?.lastName}`
                .toLowerCase()
                .includes(value)
            )
          );
        }
      };
    
      useEffect(() => {
        if (selectedRoles?.length) {
          handleAllChange(true);
        }
        if (!selectedEmpFilter?.length && !selectedOption?.value) {
            setEmpFilter([]);
        }
      }, [selectedRoles, isLoading, staffData]);

    return (
        <>
            {isLoading && <Loader />}
            <div className={styles.flex_col_div}>
                <div className={styles.filter_search_icon_wrapper}>
                    <div className={styles.new_search_bar}>
                        <span className={styles.search_icon}>
                            <img src={SearchIcon} alt="search icon" />
                        </span>
                        <input
                            className={styles.search_input}
                            type="text"
                            placeholder="Search by Name"
                            onChange={handleSearchChange}
                            value={searchValue}
                        />
                    </div>
                    <div
                        onClick={() => {
                            setSelectDropdown(!selectDropdown);
                        }}
                    >
                        <img
                            src={FilterIcon}
                            alt="FilterIcon"
                            className={styles.filter_icon}
                        />
                    </div>
                </div>
                {selectDropdown && (
                    <div
                        className={
                            styles.select_container_new +
                            " " +
                            "member-filter review-rating-filter all-option custom-scroll"
                        }
                    >
                        <Select
                            className={
                                styles.react_select_box + " " + "react-select-container"
                            }
                            classNamePrefix="react-select"
                            options={rolesData}
                            value={selectedOption}
                            onChange={handleMemberType}
                            isSearchable={false}
                        />
                    </div>
                )}
                <ul className={["filter-list"]}>
                    {filteredStaffData?.length ? (
                        <ul>
                            <li>
                                <div className="ch-checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => handleAllChange(e.target.checked)}
                                            checked={selectAll}
                                        />
                                        <span>{t("taskManagement.allStaff")}</span>
                                    </label>
                                </div>
                            </li>
                            <hr className="mr-4" />
                        </ul>
                    ) : (
                        <span>{t("dashBoard.notFoundStaff")}</span>
                    )}

                    {filteredStaffData &&
                        filteredStaffData?.map((item, key) => {
                            return (
                                <div className={styles["checkbox_wrapper_filter"]} key={key}>
                                    <div
                                        className={
                                            styles["check_box_width"] +
                                            " " +
                                            "ch-checkbox c-field all-event-checkbox m-0"
                                        }
                                    >
                                        <label>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleChange(e, item)}
                                                checked={selectedEmpFilter?.includes(item.userId)}
                                            />
                                            <span className={styles["checkbox_height"]}></span>
                                        </label>
                                    </div>
                                    <div className={styles["name_office"]}>
                                        <label className="m-0">
                                            <Text
                                                size="14px"
                                                weight="600"
                                                color="#102C42"
                                                className={styles.office_name}
                                            >
                                                {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                            </Text>
                                            <Text size="12px" weight="400" color="#87928D">
                                                {item?.designation?.name}
                                            </Text>
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                </ul>
            </div>
        </>
    );
}

export default withTranslation()(EmployeesFilter);