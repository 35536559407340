import React from 'react';
import Text from 'components/Text';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Modal } from 'reactstrap';
import Loader from 'components/Loader';
import crossIcon from '../../../../assets/images/cross.svg';
import styles from '../PerformanceReview.module.scss';

export default function RatingCriteriaModal({ t, isRatingModalOpen, closeRatingModal, showLoader, ratingCriteria, questionTitle }) {
    return (
        <Modal
            isOpen={isRatingModalOpen}
            toggle={closeRatingModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            {showLoader && <Loader />}
            <span className="close-btn" onClick={closeRatingModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles.rating_modal_container}>
                    <Text size="25px" weight="600" color="#587E85">{t('performanceReview.ratingCriteria')}</Text>
                    <Text size="25px" weight="600" color="#111B45">{questionTitle}</Text>
                    <div className={styles.content_wrapper}>
                        {ratingCriteria.length > 0 && ratingCriteria.map((c) => (
                            <div className={styles.content_container} key={c?.ratingValue}>
                                <div className={styles.content_number}>{c?.ratingValue}</div>
                                <div className={styles.content}>{c?.ratingDescription}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
