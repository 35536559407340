
import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { OfficeInvitationsActions, useDashboardApprovalsAndRequests, useOfficeInvitations } from "repositories/office-repository";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { getStorage, handleSuccess } from "utils";
import constants from "../../../../constants";
import { updateEditTimesheetRequestStatus, updateTimesheetStatus } from "repositories/timesheet-repository";
import { updateLeaveStatus } from "repositories/leave-repository";
import toast from "react-hot-toast";

const pageSize = 12;
const useRequestAcceptModule = () => {
    const profile = useSelector((state) => state.userProfile.profile);
    const cachedSelectedOwnerTab = getStorage(constants.notes.cache.dashboardAccountOwnerCheck);
    const [pageNumber, setPageNumber] = useState(1);
    const [acceptAndReject, setAcceptAndReject] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [officeFilter, setOfficeFilter] = useState([]);
    const [staffFilter, setStaffFilter] = useState([]);
    let userId = profile?.id;
    const isAccountOwner = profile?.role?.systemRole === constants.systemRoles.accountOwner;
    let ownerId = isAccountOwner ? profile?.id : cachedSelectedOwnerTab?.owner?.id;

    const { isLoading, data, refetch } = useDashboardApprovalsAndRequests(
        ownerId,
        pageNumber,
        pageSize,
        staffFilter,
        officeFilter
    );

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data?.data) {
                setAcceptAndReject([...data?.data?.recentUpdates]);
                setTotalItems(data?.pagination?.totalItems);
                setTotalPages(data?.pagination?.totalPages);
            }
        }
        else if (!isLoading && data && data?.data) {
            setAcceptAndReject(prev => [...prev, ...data?.data?.recentUpdates]);
            setTotalItems(data?.pagination?.totalItems);
            setTotalPages(data?.pagination?.totalPages);
        }
    }, [isLoading, data]);


    const acceptRejectTimesheet = async (timesheetId, statusId, reason) => {
        try {
          await updateTimesheetStatus({
            timesheetId: timesheetId?.toString(),
            statusId: statusId,
            reason: reason ? reason : null,
          });
          setAcceptAndReject((prev) =>
            prev.filter((item) => item.timesheetId !== timesheetId)
          );
        } catch (error) {
          toast.error(error?.message);
        }
      };
    
      const acceptRejectLeave = async (actionTypeId, isApproved) => {
        try {
          await updateLeaveStatus({
            id: actionTypeId.toString(),
            isAccepted: isApproved,
          });
          setAcceptAndReject((prev) =>
            prev.filter((item) => item.leave_Id !== actionTypeId)
          );
        } catch (error) {
          toast.error(error?.message);
        }
      };
    
      const acceptRejectEditTimesheetRequest = async (actionTypeId, isApproved) => {
        try {
          await updateEditTimesheetRequestStatus(actionTypeId, isApproved);
          setAcceptAndReject((prev) =>
            prev.filter((item) => item.clockInOutTimeSheetEditRequestId !== actionTypeId)
          );
        } catch (error) {
          toast.error(error?.message);
        }
      };
    
    return {
        data: {
            isLoading,
            pageNumber,
            acceptAndReject,
            totalItems,
            totalPages
        },
        methods: {
            setPageNumber,
            setOfficeFilter,
            setStaffFilter,
            acceptRejectTimesheet,
            acceptRejectLeave,
            acceptRejectEditTimesheetRequest
        }
    }
}

export default useRequestAcceptModule;
