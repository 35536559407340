import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import constants from "../../../../constants";
import moment from "moment";
import CustomDropdown from "components/Dropdown";
import styles from "../Dashboard.module.scss";
import Text from "components/Text";
import OpenInIcon from "../../../../assets/images/open_in_new.svg";
import SchedulerModal from "./modals/SchedulerModal";
import { setStorage } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tooltip from "reactstrap/lib/Tooltip";

const SchedulerDashboardHeader = ({
  t,
  handleSchedule,
  isMySchedule,
  staffAvailabilityViewSelectedFromDashBoard,
  handleAvailabilityViewSelectedFromDashBoard,
  currentDateFromDashBoard,
  setCurrentDateFromDashBoard,
  isSidebarActive,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipEditOpen, setTooltipEditOpen] = useState(false);
  const openModalHandler = () => setIsModalOpen(true);
  const closeModalHandler = () => setIsModalOpen(false);
  const history = useHistory();

  const staffAvailabilityView = [
    { id: "1", name: "Daily" },
    { id: "2", name: "Weekly" },
  ];

  // Retrieve view selection from sessionStorage or set default
  const initialView = staffAvailabilityView[0].id;

  const [staffAvailabilityViewSelected, setStaffAvailabilityViewSelected] =
    useState(initialView);

  // Retrieve date from sessionStorage or set default to current date
  const initialDate = sessionStorage.getItem(constants.staffAvalibilityDate);
  const [currentDate, setCurrentDate] = useState(
    initialDate ? new Date(initialDate) : new Date()
  );

  // Update sessionStorage whenever the date changes
  useEffect(() => {
    sessionStorage.setItem(
      constants.staffAvalibilityDate,
      moment(currentDate).toISOString()
    );
  }, [currentDate]);

  // Handle navigation between dates
  const dateChange = (type) => {
    let newDate = new Date(currentDate);

    switch (staffAvailabilityViewSelectedFromDashBoard) {
      case "1": // Daily View
        newDate =
          type === constants.arrow.PREVIOUS
            ? moment(currentDateFromDashBoard).subtract(1, "day").toDate()
            : moment(currentDateFromDashBoard).add(1, "day").toDate();
        break;
      case "2": // Weekly View
        newDate =
          type === constants.arrow.PREVIOUS
            ? moment(currentDateFromDashBoard)
                .subtract(1, "week")
                .startOf("week")
                .toDate()
            : moment(currentDateFromDashBoard)
                .add(1, "week")
                .startOf("week")
                .toDate();
        break;
      default:
        break;
    }

    setCurrentDateFromDashBoard(newDate);
  };

  const getDisplayDate = () => {
    switch (staffAvailabilityViewSelectedFromDashBoard) {
      case "1": // Daily
        return moment(currentDateFromDashBoard).format("ddd, MMM DD, YYYY");
      case "2": // Weekly
        const startOfWeek = moment(currentDateFromDashBoard)
          .startOf("isoweek")
          .format("ddd, MMM DD");
        const endOfWeek = moment(currentDateFromDashBoard)
          .endOf("isoweek")
          .format("ddd, MMM DD");
        return `${startOfWeek} - ${endOfWeek}`;
      default:
        return "";
    }
  };

  const handleRedirect = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    history.push(constants.routes.scheduler.calendar);
    setStorage("isChatFromDashBoard", true);
  };

  return (
    <div className={styles.scheduler_header}>
      <div className={styles.title_checkbox_wrapper}>
        <Text size="16px" weight="600" color="#2D3245">
          Scheduler
        </Text>
        <div className={styles.checkbox_date_dropdown}>
          {/* Legend Section */}
          <div className={styles.checkbox_wrapper}>
            <div className="ch-checkbox c-field all-event-checkbox m-0">
              <label>
                <input
                  type="checkbox"
                  checked={isMySchedule || false}
                  onChange={handleSchedule}
                />
                <span>{t("My Schedule")}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Date Navigation Section */}
      <div className={styles.date_drop_wrapper}>
        <div className="monthly-calendar-arrows m-0">
          <div
            className="arrow-img"
            onClick={() => dateChange(constants.arrow.PREVIOUS)}
          >
            <img
              src={require("assets/images/monthly-arrow-left.svg").default}
              alt="Previous"
            />
          </div>

          <div className={styles.monthly_calendar_text}>{getDisplayDate()}</div>

          <div
            className="arrow-img"
            onClick={() => dateChange(constants.arrow.NEXT)}
          >
            <img
              src={require("assets/images/monthly-arrow-right.svg").default}
              alt="Next"
            />
          </div>
        </div>

        <div className={styles.dropdownContainer}>
          <CustomDropdown
            options={staffAvailabilityView}
            selectedOption={staffAvailabilityViewSelectedFromDashBoard}
            selectOption={(id) => {
              handleAvailabilityViewSelectedFromDashBoard(id);
            }}
          />
        </div>

        <div className="pl-2" onClick={handleRedirect}>
          <img src={OpenInIcon} alt="open-icon" id="schedulaer_tooltip" />
        </div>
        <Tooltip
          isOpen={tooltipEditOpen}
          placement="top"
          target="schedulaer_tooltip"
          toggle={() => {
            setTooltipEditOpen(!tooltipEditOpen);
          }}
        >
          {t("dashBoard.gotoSchedular")}
        </Tooltip>
      </div>

      <SchedulerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeModalHandler={closeModalHandler}
      />
    </div>
  );
};

export default withTranslation()(SchedulerDashboardHeader);
