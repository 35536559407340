import React, { Fragment, memo } from "react";
import Text from "components/Text";
import Input from "components/Input";
import styles from "../PerformanceReview.module.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const getValue = (arr, questionId) => {
    const val = arr.find(v => (v?.questionnaireId == questionId));
    return {
        questionId: questionId,
        ratingValue: val?.ratingValue,
        comment: val?.comment
    }
}

const RatingSection = memo(({ t, questionId, uniqueId, questionnaireResponses, handleRadioChange, handleCommentChange, isView }) => {
    const optionVal = questionnaireResponses?.length > 0 ? getValue(questionnaireResponses, questionId) : {};
    const location = useLocation();
    const previewForm = location?.pathname.includes('preview-performance-review-form');


    return (
        <Fragment>
            <div className={styles["add_note_radio_btn"] + " " + "ch-radio"}>
                {Array.from({ length: 5 }, (_, i) => i + 1).map((ratingValue) => {
                    return (
                        <label key={ratingValue}>
                            <input
                                type="radio"
                                name={`rating-${ratingValue}-${uniqueId}`}
                                checked={optionVal?.ratingValue === ratingValue}
                                onChange={() => handleRadioChange(questionId, ratingValue)}
                                disabled={isView}
                            />
                            <span>{ratingValue}</span>
                        </label>
                    )
                })}
            </div>
            {(isView && !previewForm) ? <div>
                <Text size="12px" weight="400" color="#6F7788">
                    {t('performanceReview.comments')}
                </Text>
                <p className={styles.comment_text}>
                    {optionVal?.comment ? optionVal?.comment : t('performanceReview.noComments')}
                </p>
            </div> :
                <div className={"c-field mb-0 mt-4"}>
                    <Input
                        Title={t('performanceReview.comments')}
                        Type="text"
                        Name={`comment-${uniqueId}`}
                        Placeholder={t('performanceReview.comments')}
                        Value={optionVal?.comment}
                        HandleChange={(e) => handleCommentChange(questionId, e.target.value)}
                        CustomClass={styles.bgYellow}
                        Disabled={previewForm}
                        MaxLength="500"
                    />
                </div>}

        </Fragment>
    );
});

export default RatingSection;