import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import constants from "./../../../../../../constants";
import Text from "components/Text";
import styles from "../../../Appointments.module.scss";
import appointmentStyles from "../../../Appointments.module.scss";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  useGlobalRemidnerPlanDetail,
  useRemidnerPlanDetail,
} from "repositories/patient-appointment-repository";
import { decodeId, getStorage, setStorage } from "utils";
import Loader from "components/Loader";
import { AppointmentsContext } from "accountOwner/pages/Appointments/AppointmentsContext";
import { useQueryClient } from "react-query";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import Tooltip from "reactstrap/lib/Tooltip";
import Card from "components/Card";
import Empty from "components/Empty";

const KEY_NAME_ONE = "appointment-reminder";
const KEY_NAME_GLOBAL = "global-appointment-reminder";
const ReminderPlan = ({ t }) => {
  const history = useHistory();
  let { appointmentId } = useParams();
  let { officeId } = useParams();
  let { patientId } = useParams();
  const decodeAppointmentId = parseInt(decodeId(appointmentId));
  const decodeOfficeId = parseInt(decodeId(officeId));
  const decodePatientId = parseInt(decodeId(patientId));
  const queryClient = useQueryClient();
  const location = useLocation();
  const isRecallReminderPlan = getStorage("isRecallReminderPlan");

  let { state } = location;
  let KEY_NAME = appointmentId ? KEY_NAME_ONE : KEY_NAME_GLOBAL;
  const goBack = () => {
    if (patientId) {
      history.push({
        pathname: constants.routes.appointment.patients.replace(
          ":officeId",
          officeId
        ),
        state: { officeName: state?.officeName },
      });
    } else if (officeId && !patientId) {
      history.push({
        pathname: constants.routes.accountOwner.timelinePreferences.replace(
          ":officeId",
          officeId
        ),
        state: { officeName: state?.officeName },
      });
    } else if (!patientId && !officeId) {
      history.push({
        pathname: constants.routes.accountPreferences,
        state: { officeName: state?.officeName },
      });
    } else {
      setStorage("appointmentReminder", {
        appointmentReminder: false,
      });
      history.push({
        pathname: constants.routes.appointment.appointmentScreen,
        state: { officeName: state?.officeName },
      });
    }
  };
  const [tooltipEditOpen, setTooltipEditOpen] = useState(false);

  const { reminderPlan, options, setReminderPlan, ownerId } =
    useContext(AppointmentsContext);

  const {
    isLoading,
    data,
    refetch: defaultAppointmentFetch,
  } = useRemidnerPlanDetail(decodeAppointmentId, {
    enabled: !!decodeAppointmentId,
  });

  let reminderPlanType = 2;
  if (patientId && !isRecallReminderPlan?.isRecallReminderPlan) {
    reminderPlanType = 2;
  }

  if (patientId && isRecallReminderPlan?.isRecallReminderPlan) {
    reminderPlanType = 5;
  }

  if (officeId && !patientId && !isRecallReminderPlan?.isRecallReminderPlan) {
    reminderPlanType = 3;
  }

  if (officeId && !patientId && isRecallReminderPlan?.isRecallReminderPlan) {
    reminderPlanType = 6;
  }

  if (!patientId && !officeId && !isRecallReminderPlan?.isRecallReminderPlan) {
    reminderPlanType = 4;
  }

  if (!patientId && !officeId && isRecallReminderPlan?.isRecallReminderPlan) {
    reminderPlanType = 7;
  }

  const {
    isLoading: globalReminderLoading,
    data: globalRemidnerData,
    refetch: globalReminderFetch,
  } = useGlobalRemidnerPlanDetail(
    decodeOfficeId,
    ownerId,
    decodePatientId,
    reminderPlanType
  );

  useEffect(() => {
    if (appointmentId) {
      setStorage("appointmentReminder", {
        appointmentReminder: true,
      });
    }
  }, []);

  const redirectSelectReminder = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    if (appointmentId) {
      history.push({
        pathname: constants.routes.appointment.appointmentReminder
          .replace(":officeId", officeId)
          .replace(":requestId", appointmentId),
        state: { isFromGloabList: true },
      });
    } else {
      history.push({
        pathname: constants.routes.appointment.patientAppointmentReminder
          .replace("/:officeId?", officeId ? `/${officeId}` : "")
          .replace("/:patientId?", patientId ? `/${patientId}` : ""),
        state: { isFromGloabList: true },
      });
    }
  };

  const fetchDefaultData = async () => {
    queryClient.invalidateQueries([
      "/AppointmentReminder/ReminderPlanMappingByAppointmentId",
      decodeAppointmentId,
    ]);
    const refetchData = await defaultAppointmentFetch();
    if (refetchData?.data?.appointmentReminderPlanMappings?.length) {
      setReminderPlan((prevReminderPlan) => ({
        ...prevReminderPlan,
        [KEY_NAME]: {
          title: refetchData?.data?.appointmentReminderPlan?.title,
          id: refetchData?.data?.appointmentReminderPlan?.id,
          appointmentReminderPlanSetting: [
            ...refetchData?.data?.appointmentReminderPlanMappings,
          ],
        },
      }));
    }
  };

  const fetchDefaultGlobalData = async () => {
    queryClient.invalidateQueries([
      "/AppointmentReminder/ByPlanType",
      decodeOfficeId,
      ownerId,
      decodePatientId,
      reminderPlanType,
    ]);
    const refetchData = await globalReminderFetch();
    if (refetchData?.data?.appointmentReminderPlanSetting?.length) {
      setReminderPlan((prevReminderPlan) => ({
        ...prevReminderPlan,
        [KEY_NAME]: {
          title: refetchData?.data?.title,
          id: refetchData?.data?.id,
          reminderPlanType: refetchData?.data?.reminderPlanType,
          appointmentReminderPlanSetting: [
            ...refetchData?.data?.appointmentReminderPlanSetting,
          ],
        },
      }));
    }
  };

  const handleAddRemidnerRedirection = () => {
    history.push(
      constants.routes.appointment.patientAddAppointmentReminderPlan
        .replace("/:officeId?", officeId ? `/${officeId}` : "")
        .replace("/:patientId?", patientId ? `/${patientId}` : "")
    );
  };

  useEffect(() => {
    if (appointmentId) {
      if (!isLoading) {
        fetchDefaultData();
      }
    } else {
      if (!globalReminderLoading) {
        fetchDefaultGlobalData();
      }
    }
  }, [data, isLoading, globalReminderLoading, globalRemidnerData]);

  return (
    <Page onBack={goBack} className="main-page">
      {(isLoading || globalReminderLoading) && <Loader />}
      <div className={styles.btn_flex_cont}>
        <div className="d-flex flex-column manage-schedule-heading">
          {state?.officeName ? (
            <>
              <p className={styles["mobile-text-size"] + " " + "mb-0"}>
                {state?.officeName}
              </p>
              <p className="pms_subtitle">
                {isRecallReminderPlan?.isRecallReminderPlan
                  ? t("accountPreferences.appointmentRecallPlan")
                  : t("accountPreferences.appointmentReminderPlan")}
              </p>
            </>
          ) : (
            <p className={styles["mobile-text-size"] + " " + "mb-0"}>
              {isRecallReminderPlan?.isRecallReminderPlan
                ? t("accountPreferences.appointmentRecallPlan")
                : t("accountPreferences.appointmentReminderPlan")}
            </p>
          )}
        </div>
        {appointmentId || globalRemidnerData?.id ? (
          <>
            <button
              className={styles.edit_recall_btn}
              onClick={redirectSelectReminder}
              id="edit_icon"
            >
              <img src={editIcon} alt="edit" />
            </button>
            <Tooltip
              isOpen={tooltipEditOpen}
              placement="top"
              target="edit_icon"
              toggle={() => {
                setTooltipEditOpen(!tooltipEditOpen);
              }}
            >
              {t("discussionNote.edit")}
            </Tooltip>
          </>
        ) : (
          <div onClick={handleAddRemidnerRedirection} className="w-sm-100 mt-2">
            <button className="button button-round button-shadow mr-md-4 w-sm-100">
              {t("patientAppointmentMembers.addNewReminderPlan")}
            </button>
          </div>
        )}
      </div>
      {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.length ? (
        <div className={appointmentStyles["heading-data-Wrapper"]}>
          <div className={appointmentStyles["head-link-wrapper"]}>
            <div className={appointmentStyles["appointment-head"]}>
              {t("patientAppointmentMembers.reminderPlan")}
            </div>
          </div>
          {reminderPlan?.[KEY_NAME] && (
            <Card className={appointmentStyles["appointment_modal_card_new"]}>
              <Text size="14px" weight="600" color="#102C42">
                {reminderPlan?.[KEY_NAME]?.title || ""}
              </Text>
              {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.map(
                (plan, index) => (
                  <div className={styles.recall_small_card} key={plan?.id}>
                    <Text size="16px" weight="600" color="#111B45">{`Reminder ${
                      index + 1
                    }`}</Text>
                    <Text
                      size="14px"
                      weight="600"
                      color="#102C42"
                      marginTop="4px"
                    >
                      {plan?.reminderBefore +
                        " " +
                        options.find((m) => m.id == plan?.reminderBeforeType)
                          ?.name +
                        " " +
                        t("patientAppointmentMembers.before")}
                    </Text>
                    <Text
                      size="14px"
                      weight="500"
                      color="#102C42"
                      marginTop="4px"
                    >
                      {[
                        plan.isEmail && t("patientAppointmentMembers.isEmail"),
                        plan.isSms && t("patientAppointmentMembers.isSms"),
                        plan.isPushNotification &&
                          t("patientAppointmentMembers.isPushNotification"),
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </Text>
                    <Text
                      size="12px"
                      weight="400"
                      color="#6F7788"
                      marginTop="6px"
                    >
                      {t("patientAppointmentMembers.messageTemPlate")}
                    </Text>
                    <Text size="14px" weight="500" color="#102C42">
                      {plan?.messageTemplateDetail?.templateName}
                    </Text>
                  </div>
                )
              )}
            </Card>
          )}
        </div>
      ) : (
        <Empty
          Message={
            isRecallReminderPlan?.isRecallReminderPlan
              ? t("waitingList.recallNotFound")
              : t("waitingList.notFound")
          }
        />
      )}
    </Page>
  );
};
export default withTranslation()(ReminderPlan);
