import ChannelUI from "@sendbird/uikit-react/Channel/components/ChannelUI";
import { ChannelProvider } from "@sendbird/uikit-react/Channel/context";
import { ChannelListProvider } from "@sendbird/uikit-react/ChannelList/context";
import React, { memo, useMemo, useState } from "react";
import CustomChannelList from "../SendbirdCustomComponents/CustomChannelList";
import CustomChannelListHeader from "../SendbirdCustomComponents/CustomChannelListHeader";
import CustomChannelPreview from "../SendbirdCustomComponents/CustomChannelPreview";
import CustomConversationHeader from "../SendbirdCustomComponents/CustomConversationHeader";
import CustomMessageInput from "../SendbirdCustomComponents/CustomMessageInput";
import CustomMessageItem from "../SendbirdCustomComponents/CustomMessageItem";
import "./messenger.scss";
import { withTranslation } from "react-i18next";
import ContactInfo from "../SendbirdCustomComponents/ContactInfo";
import Loader from "components/Loader";
import ImageViewer from "../Modals/ImageViewer";
import useConversation from "Messenger/hooks/conversation/useConversation";
import constants from "../../../../../constants";
import ChatClosed from "Messenger/pages/PatientConversation/component/ChatClosed";
import ChatLoader from "Messenger/chatLoader";

const InternalTabContent = ({
  t,
  currentUser,
  selectedOwner,
  currentChannel,
  setCurrentChannel,
  externalTabActive,
  internalTabActive,
  setViewAppointmentCred,
  doctorChatCheck,
}) => {
  const { state, otherData, updateMethods, otherMethods } = useConversation({
    currentUser,
    currentChannel,
    setCurrentChannel,
    selectedOwner,
    ...(internalTabActive !== undefined && {
      isInternalChat: internalTabActive,
    }),
    ...(externalTabActive !== undefined && {
      isExternalChat: externalTabActive,
    }),
    t,
  });
  const {
    profileContactInfo,
    channelListQuery,
    fileMessage,
    fileViewer,
    filteredOffices,
    filteredOfficesForNew,
    searchText,
    selectedOption,
  } = state;
  const { isSdkLoading, isUserLoading, sdk, isSdkError } = otherData;
  const {
    setChannelListQuery,
    setFileMessage,
    setFileViewer,
    setFilteredOffices,
    updateChannelList,
    setSearchText,
    setFilteredOfficesForNew,
    setSelectedOption,
  } = updateMethods;
  const {
    handleProfileBtn,
    handleProfileBtnClose,
    handleChannelSelect,
    handleBackBtn,
    handleOnBeforeSendUserMessage,
    sendUserMessage,
    handleSort,
    sendFileMessage,
    handleOnBeforeSendFileMessage,
    handleRequestStatusFilter,
  } = otherMethods;
  const doctorChatCheckExistInProps = doctorChatCheck !== undefined;
  const isChatClosed =
    doctorChatCheckExistInProps &&
    currentChannel?.customType === constants.chat.patient.close;
  const [allChannelsOfficesForDoctor, setAllChannelsOfficesForDoctor] =
    useState([]);
  const customChannelListProps = useMemo(() => {
    //internal Chat
    if (internalTabActive) {
      return {
        filteredOffices,
        selectedOwner,
        internalTabActive,
      };
    }
    // external chat
    if (externalTabActive !== undefined) {
      return { externalTabActive };
    }
    // patient and doctor side
    if (doctorChatCheckExistInProps) {
      return {
        filteredOffices,
        selectedOwner,
        doctorChatCheck,
        setAllChannelsOfficesForDoctor,
        searchText,
        setCurrentChannel,
      };
    }
    return null;
  }, [
    filteredOffices,
    selectedOwner,
    doctorChatCheckExistInProps,
    doctorChatCheck,
    setAllChannelsOfficesForDoctor,
    searchText,
    setCurrentChannel,
    externalTabActive,
    internalTabActive,
  ]);

  const customChannelListHeaderProps = useMemo(() => {
    //internal Chat
    if (internalTabActive) {
      return {
        filteredOffices,
        setFilteredOffices,
      };
    }
    // external chat
    if (externalTabActive !== undefined) {
      return { externalTabActive };
    }
    // patient and doctor side
    if (doctorChatCheckExistInProps) {
      return {
        doctorChatCheck,
        allChannelsOfficesForDoctor,
        setFilteredOffices,
        filteredOffices,
        isPatientConversation: true,
        searchText,
        setSearchText,
        selectedOption,
        setSelectedOption,
        handleRequestStatusFilter,
        setFilteredOfficesForNew,
        filteredOfficesForNew,
      };
    }
    return null;
  }, [
    filteredOffices,
    setFilteredOffices,
    externalTabActive,
    doctorChatCheck,
    doctorChatCheckExistInProps,
    allChannelsOfficesForDoctor,
    searchText,
    setSearchText,
    selectedOption,
    setSelectedOption,
    handleRequestStatusFilter,
    setFilteredOfficesForNew,
    filteredOfficesForNew,
    internalTabActive,
  ]);
  const customConversationHeaderProps = useMemo(() => {
    // external chat only
    if (externalTabActive !== undefined) {
      return { externalTabActive };
    }
    // patient and doctor side
    if (doctorChatCheckExistInProps) {
      return {
        doctorChatCheck,
        isFromPatientChat: true,
        setViewAppointmentCred,
      };
    }
    return null;
  }, [
    externalTabActive,
    doctorChatCheckExistInProps,
    doctorChatCheck,
    setViewAppointmentCred,
  ]);
  const noChatDetail = useMemo(() => {
    if (internalTabActive) {
      return t("messenger.pleaseInternalSelectConversationToStart");
    }
    if (externalTabActive) {
      return t("messenger.pleaseExternalSelectConversationToStart");
    }
    if (doctorChatCheck) {
      return t("messenger.pleaseDoctorSelectConversationToStart");
    }
    return t("messenger.pleasePatientSelectConversationToStart");
  }, [doctorChatCheck, internalTabActive, externalTabActive]);

  return (
    <div
      className={
        isChatClosed
          ? `miraxis_messenger_new miraxis-messenger`
          : `miraxis-messenger`
      }
    >
      {(isSdkLoading || isUserLoading) && <Loader />}
      {/* Chat list */}

      <ChannelListProvider
        className={!currentChannel ? "" : "hide-channel-list"}
        queries={channelListQuery}
        onChannelSelect={handleChannelSelect}
        sortChannelList={handleSort}
        disableAutoSelect={true}
      >
        <CustomChannelList
          isSdkError={isSdkError}
          currentUser={currentUser}
          setCurrentChannel={setCurrentChannel}
          {...customChannelListProps}
          renderHeader={() => (
            <CustomChannelListHeader
              currentUser={currentUser}
              sdk={sdk}
              setCurrentChannel={setCurrentChannel}
              setChannelListQuery={setChannelListQuery}
              selectedOwner={selectedOwner}
              updateChannelList={updateChannelList}
              {...customChannelListHeaderProps}
            />
          )}
          renderChannelPreview={(props) => (
            <CustomChannelPreview
              {...props}
              key={props?.channel?._iid}
              currentUser={currentUser}
              currentChannelUrl={currentChannel?.url}
              setCurrentChannel={setCurrentChannel}
              handleProfileBtnClose={handleProfileBtnClose}
              // external chat only
              {...(externalTabActive && { externalTabActive })}
              profileContactInfo={profileContactInfo}
              sdk={sdk}
            />
          )}
        />
      </ChannelListProvider>

      <div
        className={`conversation-wrapper  
                ${!currentChannel ? "" : "show-conversation"}
                ${!profileContactInfo ? "" : "contact-info-active"}`}
      >
        {/* Main Chat */}
        <div className="back-arrow">
          <span className=" link-btn" onClick={handleBackBtn}>
            <img
              src={require("assets/images/arrow-back-icon.svg").default}
              alt="arrow"
            />{" "}
            {t("back")}
          </span>
        </div>
        <ChannelProvider
          channelUrl={currentChannel?.url}
          onBeforeSendFileMessage={handleOnBeforeSendFileMessage}
          onBeforeSendUserMessage={handleOnBeforeSendUserMessage}
        >
          <ChannelUI
            renderTypingIndicator={() => <></>}
            renderPlaceholderLoader={() => <ChatLoader />}
            renderPlaceholderEmpty={() => <></>}
            renderPlaceholderInvalid={() => (
              <div className="empty-chat-box">{noChatDetail} </div>
            )}
            renderChannelHeader={() => (
              <CustomConversationHeader
                currentUser={currentUser}
                channel={currentChannel}
                handleProfileBtn={handleProfileBtn}
                setCurrentChannel={setCurrentChannel}
                sdk={sdk}
                // external chat only
                {...customConversationHeaderProps}
              />
            )}
            renderMessage={(props) => (
              <CustomMessageItem
                {...props}
                t={t}
                currentUser={currentUser}
                setFileMessage={setFileMessage}
                setFileViewer={setFileViewer}
                currentChannel={currentChannel}
                // external chat only
                {...(externalTabActive && { externalTabActive })}
                // patient and doctor side
                {...(doctorChatCheckExistInProps && {
                  currentUser,
                  setFileMessage,
                  setFileViewer,
                })}
              />
            )}
            renderMessageInput={() => (
              <CustomMessageInput
                t={t}
                sendUserMessage={sendUserMessage}
                sendFileMessage={sendFileMessage}
                currentChannel={currentChannel}
                sdk={sdk}
                {...(doctorChatCheckExistInProps && {
                  isPatient: true,
                  renderClosedState: <ChatClosed />,
                })}
              />
            )}
          />
        </ChannelProvider>
      </div>
      {!!(currentChannel && profileContactInfo) && (
        <ContactInfo
          handleProfileBtnClose={handleProfileBtnClose}
          channel={currentChannel}
          currentUser={currentUser}
          setCurrentChannel={setCurrentChannel}
          setFileMessage={setFileMessage}
          setFileViewer={setFileViewer}
          updateChannelList={updateChannelList}
          sdk={sdk}
          // patient and doctor side or internal chat

          {...((internalTabActive || doctorChatCheckExistInProps) && {
            setChannelListQuery,
            selectedOwner,
            setFilteredOffices,
            filteredOffices,
          })}
          {...(doctorChatCheckExistInProps && {
            doctorChatCheck,
            isPatient: true,
          })}
        />
      )}
      {fileViewer && fileMessage && (
        <ImageViewer
          imageUrl={fileMessage.url}
          isOpen={fileViewer}
          closeImageViewerModal={() => setFileViewer(false)}
        />
      )}
    </div>
  );
};

export default withTranslation()(memo(InternalTabContent));
