import Empty from "components/Empty";
import Page from "components/Page";
import Table from "components/table";
import constants from "../../../../constants";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getEventHistoryLogs } from "repositories/scheduler-repository";
import { decodeId, encodeId, handleError } from "utils";
import styles from "../../Appointments/Appointments.module.scss";
import moment from "moment-timezone";
import InfoModal from "components/CustomModal/InfoModal";
import ActionDateTimePopup from "./Modals/ActionDateTimePopup";
import InviteesPopup from "./Modals/InviteesPopup";
import Loader from "components/Loader";

function EventHistoryLogs({ history, location, t }) {
  let { eventId } = useParams();
  eventId = decodeId(eventId);

  const [isLoading, setIsLoading] = useState(false);
  const [eventHistoryLogs, setEventHistoryLogs] = useState([]);
  const [isDeleteReasonModalOpen, setIsDeleteReasonModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({
    top: 0,
    right: 0,
  });
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);
  const [invitePopupPosition, setInvitePopupPosition] = useState({
    top: 0,
    right: 0,
  });
  const [deletedEmployeesList, setDeletedEmployeesList] = useState([]);
  const [eventDeletedDates, setEventDeletedDates] = useState([]);
  const allEmployeesList = location?.state?.eventEmployeesList;
  const eventEndDate = location?.state?.eventRepeatedEndDate;

  const columns = [
    {
      attrs: {
        datatitle: t("scheduler.eventHistoryTable.actions"),
      },
      dataField: "actions",
      text: t("scheduler.eventHistoryTable.actions"),
      formatter: (cellContent, row) => (
        <span
          className={row?.deletedById ? styles.delete_btn : styles.edit_btn}
        >
          {row?.deletedById
            ? t("scheduler.eventHistoryTable.delete")
            : t("scheduler.eventHistoryTable.edit")}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t("scheduler.eventHistoryTable.actionTakenBy"),
      },
      dataField: "actionTakenBy",
      text: t("scheduler.eventHistoryTable.actionTakenBy"),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.deletedBy?.fullName ? row?.deletedBy?.fullName : "--"}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t("scheduler.eventHistoryTable.actionDateAndTime"),
      },
      dataField: "actionDateAndTime",
      text: t("scheduler.eventHistoryTable.actionDateAndTime"),
      formatter: (cellContent, row) => (
        <>
          <span
            className={`${styles["patient-appointment-table-common-columns"]} `}
          >
            {moment(row?.deletedAt).format("llll")}
          </span>
        </>
      ),
    },

    {
      attrs: {
        datatitle: t("scheduler.eventHistoryTable.date"),
      },
      dataField: "date",
      text: t("scheduler.eventHistoryTable.date"),
      formatter: (cellContent, row) => {
        // Handle different deletion types
        if (row?.deletionType === 1) {
          // For deletionType 1, just show the single deletedDate
          return (
            <div className={styles["patient-appointment-table-common-columns"]}>
              <span className={styles.action_date}>
                {row?.deletedDate
                  ? moment(row?.deletedDate).format("ll")
                  : "--"}
              </span>
            </div>
          );
        } else if (row?.deletionType === 2) {
          // For deletionType 2, show deletedDate - eventEndDate format
          return (
            <div className={styles["patient-appointment-table-common-columns"]}>
              <span className={styles.action_date}>
                {row?.deletedDate && eventEndDate
                  ? `${moment(row?.deletedDate).format("ll")} - ${moment(
                      eventEndDate
                    ).format("ll")}`
                  : "--"}
              </span>
            </div>
          );
        } else if (row.deletionType === 3) {
          // For deletionType 3, use schedulerEventDeletedDate array
          const deletedDates = row?.schedulerEventDeletedDate || [];

          if (deletedDates.length === 0) {
            return (
              <div
                className={styles["patient-appointment-table-common-columns"]}
              >
                --
              </div>
            );
          }

          // If 2 or fewer dates, display all of them
          if (deletedDates.length <= 2) {
            return (
              <div
                className={`${styles["patient-appointment-table-common-columns"]} ${styles.date_container}`}
              >
                {deletedDates.map((dateObj, index) => (
                  <span
                    key={dateObj?.id || `date-${index}`}
                    className={styles.action_date}
                  >
                    {moment(dateObj.selectedDate).format("ll")}
                  </span>
                ))}
              </div>
            );
          } else {
            // If more than 2 dates, display the first two and a "+X more" link
            const restCount = deletedDates.length - 2;

            return (
              <div
                className={`${styles["patient-appointment-table-common-columns"]} ${styles.date_container}`}
              >
                <span className={styles.action_date}>
                  {moment(deletedDates[0].selectedDate).format("ll")}
                </span>
                <span className={styles.action_date}>
                  {moment(deletedDates[1].selectedDate).format("ll")}
                </span>
                <span
                  className={`${styles.link_new} link-btn`}
                  onClick={(e) => {
                    setEventDeletedDates(deletedDates);
                    handlePopupToggle(e);
                  }}
                >
                  +{restCount} more
                </span>
              </div>
            );
          }
        } else {
          // Default case
          return (
            <div className={styles["patient-appointment-table-common-columns"]}>
              --
            </div>
          );
        }
      },
    },

    {
      attrs: { datatitle: t("scheduler.eventHistoryTable.invitees") },
      dataField: "invitees",
      text: t("scheduler.eventHistoryTable.invitees"),
      formatter: (cellContent, row) => {
        // Determine which array to use based on isForAllStaff flag
        const employeesList = row?.isForAllStaff
          ? allEmployeesList
          : row?.schedulerEventDeletedEmployee;

        return (
          <>
            {employeesList && employeesList.length > 0 ? (
              <span
                className={`${
                  styles["patient-appointment-table-common-columns"]
                } ${
                  employeesList.length > 0
                    ? `${styles.link_new} cursor-pointer`
                    : ""
                }`}
                onClick={(e) => {
                  handleInvitePopupToggle(e);
                  setDeletedEmployeesList(employeesList);
                }}
              >
                {`${employeesList.length} Users`}
                {staffSection(employeesList)}
              </span>
            ) : (
              <div
                className={styles["patient-appointment-table-common-columns"]}
              >
                --
              </div>
            )}
          </>
        );
      },
    },

    {
      attrs: {
        datatitle: t("scheduler.eventHistoryTable.reason"),
      },
      dataField: "reason",
      text: t("scheduler.eventHistoryTable.reason"),
      formatter: (cellContent, row) => (
        <span
          className={`${styles["patient-appointment-table-common-columns"]} ${styles.truncate_text}`}
        >
          {row?.reason ? row.reason : "--"}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: "viewDeatils",
      },
      dataField: "viewDeatils",
      formatter: (cellContent, row) => (
        <div className={styles["table-status-box"]}>
          <div
            className={`${
              row?.deletedById && row?.reason
                ? styles["appointment-table-row-main-link"]
                : ""
            }`}
          >
            {row?.deletedById ? (
              <>
                {row?.reason ? (
                  <span
                    className={styles["appointment-table-patient-name"]}
                    onClick={() => {
                      setDeleteReason(row?.reason);
                      setIsDeleteReasonModalOpen(true);
                    }}
                  >
                    {t("scheduler.eventHistoryTable.viewReason")}
                  </span>
                ) : (
                  <span>{"--"}</span>
                )}
              </>
            ) : (
              <span className={styles["appointment-table-patient-name"]}>
                {t("scheduler.eventHistoryTable.viewDetails")}
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchEventHistoryLogs();
  }, []);

  const goBack = () =>
    history.push({
      pathname: constants.routes.scheduler.eventDetails.replace(
        ":eventId",
        encodeId(eventId)
      ),
      state: { requestedDate: location?.state?.requestedDate },
    });

  const fetchEventHistoryLogs = async () => {
    try {
      setIsLoading(true);
      let result = await getEventHistoryLogs(eventId);
      setEventHistoryLogs(result?.data);
    } catch (err) {
      handleError(err);
    }
    setIsLoading(false);
  };

  const handleInvitePopupToggle = (event) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setInvitePopupPosition({
      top: rect.bottom + window.scrollY + 5,
      right: window.innerWidth - rect.right,
    });
    setInvitePopupOpen((prev) => !prev);
  };

  const handlePopupToggle = (event) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({
      top: rect.bottom + window.scrollY + 5,
      right: window.innerWidth - rect.right,
    });
    setPopupOpen((prev) => !prev);
  };

  const staffSection = (row) => {
    return (
      <div className={styles.staff_list}>
        {row?.length > 0 ? (
          <ul>
            {row?.slice(0, 4).map((val) => (
              <li key={val?.id}>
                <div className={styles.container_box}>
                  <img
                    src={
                      val?.user?.profilePic ||
                      require("assets/images/default-image.svg").default
                    }
                    alt="DummyImg"
                    className={styles.invitees_img}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        require("assets/images/default-image.svg").default;
                    }}
                  />
                </div>
              </li>
            ))}
            {deletedEmployeesList.length > 4 && (
              <li>
                <div className={styles.more}>
                  <span>{deletedEmployeesList.length - 4}+</span>
                </div>
              </li>
            )}
          </ul>
        ) : (
          <ul>
            <li>&nbsp;</li>
          </ul>
        )}
      </div>
    );
  };

  const renderheader = () => {
    return (
      <div className="mb-4">
        <h2 className="title pms_heading">{t("scheduler.historyLogs")}</h2>
      </div>
    );
  };

  const renderTableListing = () => (
    <>
      {eventHistoryLogs?.length > 0 && (
        <div className="table-td-last-50 td-first-col-color common-fw-400 sales-representative-table shadow-responsive">
          <Table keyField="id" data={eventHistoryLogs} columns={columns} />
        </div>
      )}
      {(!eventHistoryLogs?.length || eventHistoryLogs.length === 0) &&
        !isLoading && (
          <div className={styles["empty-list"]}>
            <Empty Message={t("scheduler.eventHistoryTable.emptyMsg")} />
          </div>
        )}
    </>
  );

  return (
    <Page onBack={goBack} className="scheduler-page">
      {isLoading && <Loader />}

      {renderheader()}
      {renderTableListing()}
      {isDeleteReasonModalOpen && (
        <InfoModal
          isOpen={isDeleteReasonModalOpen}
          setIsOpen={() => setIsDeleteReasonModalOpen(!isDeleteReasonModalOpen)}
          title={t("scheduler.eventHistoryTable.reason")}
          subTitle={t("scheduler.reasonForDelete")}
          content={deleteReason}
          btnText={t("close")}
          isContentCenter={false}
          modalStyle={{
            ContentSize: "14px",
            weight: "600",
            color: " #102C42",
            textTransform: "unset",
          }}
        />
      )}
      {popupOpen && (
        <ActionDateTimePopup
          handlePopupToggle={handlePopupToggle}
          position={popupPosition}
          list={eventDeletedDates}
        />
      )}
      {invitePopupOpen && (
        <InviteesPopup
          position={invitePopupPosition}
          handleInvitePopupToggle={handleInvitePopupToggle}
          list={deletedEmployeesList}
          officeName={location?.state?.officeName}
        />
      )}
    </Page>
  );
}

export default withTranslation()(EventHistoryLogs);
