import Page from "components/Page";
import React from "react";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import Select from "react-select";
import styles from "../../TaskManagement.module.scss";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import ReactDatePicker from "react-datepicker";
import PerformanceDetailCard from "./PerformanceDetailCard";
import usePerformanceDetailsController from "../../hooks/performanceDetailsController";
import Loader from "components/Loader";
import {
  taskStatusOptions
} from "../../../../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";
import Empty from "components/Empty";
import { useLocation } from 'react-router-dom';

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const PerformanceDetail = ({ t, history, }) => {
  const {
    data: {
      performanceLoader,
      assigneeList,
      appliedStatusFilters,
      totalItems,
      dates
    },
    methods: {
      setPageNumber,
      handleRequestStatusFilter,
      updateDatesValues
    }
  } = usePerformanceDetailsController()

  const location = useLocation();
  const { state } = location;
  const goToPreviousScreen = () => {
    history.push("/task-performance");
  };


  const startDatePickerRef = useReadOnlyDateTextInput();

  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };
  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  return (
    <Page onBack={goToPreviousScreen}>
      {performanceLoader && <Loader />}
      <h2 className="title"> {t("taskManagement.taskPerformance")}</h2>
      <Text size="14px" marginBottom="4px" weight="300" color="#000000">
        {`${state?.userFirstName} ${state?.userLastName}`}
      </Text>
      <Text size="12px" weight="400" color="#6F7788">
        {state?.officeName}
      </Text>
      <div className={styles["dropdown_date_wrapper"] + " " + "margin-top-6x"}>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={taskStatusOptions}
          value={appliedStatusFilters}
          onChange={handleRequestStatusFilter}
          styles={customStyles}
          menuPortalTarget={document.body}
        />
        <div className={styles["date_wrapper"] + " " + "c-field mb-0"}>
          <div className="inputdate">
            <Text size="13px" weight="400" color="#79869A">
              {t("taskManagement.from")}
            </Text>
            <ReactDatePicker
              dateFormat="dd-MM-yyyy"
              className="c-form-control"
              selected={dates.from}
              onChange={(e) => updateDatesValues(e, "from")}
              maxDate={dates.to}
              popperPlacement="bottom-start"
              popperContainer={CalendarContainer}
              ref={startDatePickerRef}
            />
          </div>
          <div className="inputdate">
            <Text size="13px" weight="400" color="#79869A">
              {t("taskManagement.to")}
            </Text>
            <ReactDatePicker
              dateFormat="dd-MM-yyyy"
              className="c-form-control"
              selected={dates.to}
              onChange={(e) => updateDatesValues(e, "to")}
              popperPlacement="bottom-start"
              popperContainer={CalendarContainer}
              ref={startDatePickerRef}
            />
          </div>
        </div>
      </div>
      {totalItems > 0 && <Text size="14px" weight="600" color="#102C42" marginTop="30px">
        {totalItems} {t("taskManagement.tasksAssigned")}
      </Text>}
      {assigneeList?.length === 0 ? (<div className={styles["not_found"]}>
        <Empty Message={t('taskManagement.taskNotFound')} />
      </div>) :
        <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
          <InfiniteScroll
            className="w-100"
            dataLength={assigneeList?.length}
            hasMore={assigneeList?.length < totalItems}
            next={() => setPageNumber((v) => v + 1)}
          >
            <div>
              {assigneeList?.map((assigne, index) => (
                <PerformanceDetailCard
                  key={index}
                  t={t}
                  assigne={assigne}
                />
              ))}
            </div>
          </InfiniteScroll>
        </motion.div>
      }
    </Page>
  );
};
export default withTranslation()(PerformanceDetail);
