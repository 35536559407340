import React from "react";
import Text from "components/Text";
import styles from "../Notices.module.scss";
import DefaultImage from '../../../../assets/images/staff-default-rounded.png';

const PatientCard = ({ staff,  selectedStaff, handleStaffRadio}) => {

    return (
        <div className={styles.checkbox_wrapper}>
            <div className={"ch-radio"}>
                <label>
                    <input
                        type="radio"
                        name="blockTimeslotAallDoctors"
                        checked={selectedStaff?.id === staff?.id}
                        onChange={() => handleStaffRadio(staff)}
                    />
                    <span className={styles.checkbox_height}></span>
                </label>
            </div>

            <img
                src={staff?.user?.profilePic || DefaultImage}
                alt={`${staff?.firstName}'s logo`}
                className={styles.popup_patient_image}
            />
            <div className={styles.name_office}>
                <Text size="14px" weight="600" color="#102C42" >
                    {`${staff?.firstName} ${staff?.lastName}`}
                </Text>
                <Text size="12px" weight="400" color="#87928D">
                    {staff?.designation?.title}
                </Text>
            </div>
        </div>
    );
};

export default PatientCard;