import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {  reAssignAppointment, reScheduleAppointment, useAppointmentDetail, useOfficeDoctor } from 'repositories/patient-appointment-repository';
import { decodeId, encodeId} from "utils";
import constants from "../../../../constants";
import toast from 'react-hot-toast';


const useReAssigneAppointmentModule = () => {
    const history = useHistory();
    const [showLoader ,setShowLoader] = useState(false);
    let { appointmentId, officeId } = useParams();
    appointmentId = decodeId(appointmentId);
    officeId = decodeId(officeId)


    const goToPreviousScreen = () => {
        history.push(constants.routes.appointment.patientAppointmentDetails.replace(":appointmentId", encodeId(appointmentId)));
    };

    const { isLoading: appointmentLoading, data: appointmentDetail } = useAppointmentDetail(
        appointmentId
    );

    const handleSubmit = async (e, params) => {
            e.preventDefault();
            setShowLoader(true);
            try {
                let res = await reAssignAppointment(params);
                if (res?.statusCode === 200) {
                    history.push(constants.routes.appointment.appointmentScreen)
                    toast.success(res.message);
                }

            } catch (err) {
                setShowLoader(true);
                toast.error(err.message);
            } finally {
                setShowLoader(false);
            }
        }

        const handleRescheduleSubmit = async (e, params) => {
            e.preventDefault();
            setShowLoader(true);
            try {
                let res = await reScheduleAppointment(params);
                if (res?.statusCode === 200) {
                    goToPreviousScreen();
                    toast.success(res.message);
                }

            } catch (err) {
                setShowLoader(true);
                toast.error(err.message);
            } finally {
                setShowLoader(false);
            }
        }

    let loader = appointmentLoading || showLoader
    return {
        data: {
            appointmentId,
            officeId,
            loader,
            appointmentDetail
        },
        methods: {
            goToPreviousScreen,
            handleSubmit,
            handleRescheduleSubmit
        }
    }
}

export default useReAssigneAppointmentModule;