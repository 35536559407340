import React, { useEffect, useState } from "react";
import SchedulerDashboardHeader from "./SchedulerDashboardHeader";
import useStaffAvailabilityFilter from "hooks/useStaffAvailabilityFilter";
import OfficeSchedule from "./OfficeSchedule";
import useAgendaFilter from "hooks/useAgendaFilter";
import MySchedule from "./MySchedule";
import SchedularDashboardHeaderMobile from "./SchedularDashboardHeaderMobile";
import { getStorage, setStorage } from "utils";
import constants from "../../../../constants";

export default function SchedulerDashboard({
  currentDate,
  onDateChange,
  appliedAgendaTypes,
  appliedViewSettig,
  appliedOfficeFilters,
  appliedStaffFilters,
  isSidebarActive,
}) {
  const cacheIsMySchedule = getStorage(constants.notes.cache.isMySchedule);
  const [isMySchedule, setIsMySchedule] = useState(cacheIsMySchedule || false);
  const [
    staffAvailabilityViewSelectedFromDashBoard,
    setStaffAvailabilityViewSelectedFromDashBoard,
  ] = useState("1");
  const staffAvailFiltersData = useStaffAvailabilityFilter();
  const agendaFiltersData = useAgendaFilter();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);

  useEffect(() => {
    setStaffAvailabilityViewSelectedFromDashBoard(
      sessionStorage.getItem(
        isMySchedule
          ? constants.notes.cache.myScheduleView
          : constants.notes.cache.otherScheduleView
      ) || "1"
    );
  }, [isMySchedule]);

  useEffect(() => {
    staffAvailFiltersData?.setApiOffices(appliedOfficeFilters);
    staffAvailFiltersData?.setApiEmps(appliedStaffFilters);
    agendaFiltersData?.setApiOffices(appliedOfficeFilters);
  }, [appliedOfficeFilters, appliedStaffFilters]);

  const handleAvailabilityViewSelectedFromDashBoard = (id) => {
    setStaffAvailabilityViewSelectedFromDashBoard(id);
    if (isMySchedule) {
      sessionStorage.setItem(constants.notes.cache.myScheduleView, id);
    } else {
      sessionStorage.setItem(constants.notes.cache.otherScheduleView, id);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSchedule = (e) => {
    setIsMySchedule(e.target.checked);
    setStorage(constants.notes.cache.isMySchedule, e.target.checked);
  };

  return (
    <div className="h-100">
      {isMobile ? (
        <SchedularDashboardHeaderMobile
          handleSchedule={handleSchedule}
          isMySchedule={isMySchedule}
          staffAvailabilityViewSelectedFromDashBoard={
            staffAvailabilityViewSelectedFromDashBoard
          }
          handleAvailabilityViewSelectedFromDashBoard={
            handleAvailabilityViewSelectedFromDashBoard
          }
          currentDateFromDashBoard={currentDate}
          setCurrentDateFromDashBoard={onDateChange}
          isSidebarActive={isSidebarActive}
        />
      ) : (
        <SchedulerDashboardHeader
          handleSchedule={handleSchedule}
          isMySchedule={isMySchedule}
          staffAvailabilityViewSelectedFromDashBoard={
            staffAvailabilityViewSelectedFromDashBoard
          }
          handleAvailabilityViewSelectedFromDashBoard={
            handleAvailabilityViewSelectedFromDashBoard
          }
          currentDateFromDashBoard={currentDate}
          setCurrentDateFromDashBoard={onDateChange}
          isSidebarActive={isSidebarActive}
        />
      )}

      {isMySchedule ? (
        <MySchedule
          agendaFiltersData={agendaFiltersData}
          staffAvailabilityViewSelectedFromDashBoard={
            staffAvailabilityViewSelectedFromDashBoard
          }
          currentDateFromDashBoard={currentDate}
          setCurrentDateFromDashBoard={onDateChange}
        />
      ) : (
        <OfficeSchedule
          staffAvailFiltersData={staffAvailFiltersData}
          staffAvailabilityViewSelectedFromDashBoard={
            staffAvailabilityViewSelectedFromDashBoard
          }
          currentDateFromDashBoard={currentDate}
          setCurrentDateFromDashBoard={onDateChange}
          appliedAgendaTypes={appliedAgendaTypes}
          appliedViewSettig={appliedViewSettig}
          appliedOfficeFilters={appliedOfficeFilters}
        />
      )}
    </div>
  );
}
