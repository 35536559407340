import { useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { completeAppointment, useAppointmentDetail, useDefultAppointmentReminder } from 'repositories/patient-appointment-repository';
import { decodeId, encodeId, getStorage, setStorage } from "utils";
import constants from "../../../../constants";
import { useQueryClient } from 'react-query';
import { AppointmentsContext } from '../AppointmentsContext';


const usePatientAppointmentCompletedModule = (t, state, keyName) => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    let { officeId, appointmentId } = useParams();
    const isFromAppointmentDetail = getStorage('isFromAppointmentDetail');
    const isFromManagePage = getStorage('isFromManagePage');
    const [showLoader, setShowLoader] = useState(false);
    const {
        reminderPlan,
        setReminderPlan,
    } = useContext(AppointmentsContext);
    appointmentId = decodeId(appointmentId);
    officeId = decodeId(officeId);

    const { isLoading: appointmentLoading, data: appointmentDetail } = useAppointmentDetail(
        appointmentId
    );
    let IsForRecall = true;
    const { isLoading: defaultAppointmentLoading, refetch: defaultAppointmentFetch } = useDefultAppointmentReminder(officeId, appointmentDetail?.patient?.id, IsForRecall);

    let isLoading = defaultAppointmentLoading || appointmentLoading

    const goToPreviousScreen = () => {
        setStorage('complete', {
            isFromCompletePage: false,
        });
        if (!isFromManagePage?.isFromManagePage && !isFromAppointmentDetail?.isFromAppointmentDetail && state?.isfromPaitentDetail) {
            history.push(constants.routes.appointment.appointmentScreen);
        }
        else if (isFromAppointmentDetail?.isFromAppointmentDetail) {
            history.push({
                pathname: constants.routes.appointment.patientAppointmentDetails.replace(":appointmentId", encodeId(appointmentId)),
                state: state
            });
        }
        else if (isFromManagePage?.isFromManagePage) {
            history.push({
                pathname: constants.routes.appointment.officeSelection.replace(":officeId", encodeId(appointmentDetail?.office?.id)),
                state: state
            });
        }
        else {
            history.push(constants.routes.appointment.patientDetail.replace(':officeId', encodeId(appointmentDetail?.office?.id)).replace(':patientId', encodeId(appointmentDetail?.patient?.id)))
        }
    };

    const fetchDefaultData = async () => {
        queryClient.invalidateQueries(["/AppointmentReminder/Default", officeId, appointmentDetail?.patient?.id, IsForRecall],);
        const refetchData = await defaultAppointmentFetch();
      if(refetchData?.data?.appointmentReminderPlanSetting?.length) {
        setReminderPlan(prevReminderPlan => ({
            ...prevReminderPlan,
            [keyName]: {
                title: refetchData?.data?.title,
                id: refetchData?.data?.id,
                appointmentReminderPlanSetting: [...refetchData?.data?.appointmentReminderPlanSetting]
            }
        }));
      }
    }

    useEffect(() => {
        if (!reminderPlan?.[keyName] && !isLoading) {
            fetchDefaultData();
        }
    }, [isLoading, defaultAppointmentFetch]);

    const isValidEvent = (selectedAppointmentType, AddNextRecall) => {
        const errorsData = { ...errors };
        if (!selectedAppointmentType) {
            errorsData.TypeError = t("patientAppointmentMembers.selectTypeError");
        } else {
            delete errorsData.TypeError;
        }

        if (!AddNextRecall) {
            delete errorsData.TypeError;
        }

        setErrors(errorsData);

        return !Object.values(errorsData).some(Boolean);
    };

    const handleCompleted = async (e, params) => {
        e.preventDefault();
        if (isValidEvent(params?.AppointmentTypeId, params?.AddNextRecall)) {
            setShowLoader(true);
            try {
                let res = await completeAppointment(params);
                if (res?.statusCode === 200) {
                    goToPreviousScreen();
                    toast.success(t("patientAppointmentMembers.markAsCompeltedToaster"));
                }

            } catch (err) {
                setShowLoader(true);
                toast.error(err.message);
            } finally {
                setShowLoader(false);
            }
        }
    }

    return {
        data: {
            showLoader,
            message,
            errors,
            isLoading,
        },
        methods: {
            handleCompleted,
            goToPreviousScreen
        }
    }
}

export default usePatientAppointmentCompletedModule;