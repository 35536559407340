import React from 'react'
import { withTranslation } from 'react-i18next'
import Text from "components/Text";
import Empty from 'components/Empty';
import "../../../Appointments.scss"
import styles from "../../../Appointments.module.scss"
import { useAppointmentCovidHistory } from 'repositories/patient-appointment-repository';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { decodeId, encodeId, formatUTCDateTime } from 'utils';
import Page from 'components/Page';
import constants from "../../../../../../constants";
import Card from 'components/Card';

const CovidForm = ({ t }) => {
    const history = useHistory();
    let { appointmentId } = useParams();
    appointmentId = decodeId(appointmentId);
    const { data: appointmentCovindHistory } = useAppointmentCovidHistory(appointmentId);

    const covidFormQuestions = [
        {
            question: t("patientAppointmentMembers.covidFormQuestions.questionOne"),
            answer: `${appointmentCovindHistory?.temperature} ${appointmentCovindHistory?.temperatureUnit}`,
        },
        {
            question: t("patientAppointmentMembers.covidFormQuestions.questionTwo"),
            answer: appointmentCovindHistory?.symptoms?.length && appointmentCovindHistory?.symptoms[0]?.name,
        },
        {
            question: t("patientAppointmentMembers.covidFormQuestions.questionThree"),
            answer: appointmentCovindHistory?.hasTravelled ? "YES" : "NO",
        },
        {
            question: t("patientAppointmentMembers.covidFormQuestions.questionFour"),
            answer: appointmentCovindHistory?.hasContacted ? "YES" : "NO",
        },
        {
            question: t("patientAppointmentMembers.covidFormQuestions.questionFive"),
            answer: appointmentCovindHistory?.hasTestedAndAwaitingResult ? "YES" : "NO",
        },
    ];

    const CovidFormQuestion = ({ question, answer }) => (
        <div className={styles["covid-form-question"]}>
            <Text size="16px" weight="600" color="#587E85" marginBottom="12px">{question}</Text>
            <Text size="14px" weight="400" color="#2F3245">{t("patientAppointmentMembers.yourAnswer")}: <span>{answer}</span></Text>
        </div>
    )
    const goBack = () => {
        history.push(constants.routes.appointment.patientAppointmentDetails.replace(":appointmentId", encodeId(appointmentId)));
    };

    return (
        <Page onBack={goBack}>
            {appointmentCovindHistory ?
                <Card className={styles.covid_form_card}>
                    <Text size="14px" weight="400" color="#535B5F" marginBottom="30px">Filled on: {formatUTCDateTime(appointmentCovindHistory?.submittedOn)}</Text>
                    <div className={styles['appointment-history-modal-scrollbar']}>
                        {covidFormQuestions?.map((item, index) => {
                            return (
                                <CovidFormQuestion key={index} question={item.question} answer={item.answer} />
                            )
                        })}
                    </div>
                </Card>
                : <Empty Message= {t("patientAppointmentMembers.covidFormQuestions.formNotFilled")}/>
            }
        </Page>
    )

}
export default withTranslation()(CovidForm)