import React, { useState } from 'react';
import Input from 'components/Input';
import Text from 'components/Text';
import { Modal, ModalBody } from "reactstrap";
import styles from "../../Appointments/Appointments.module.scss"
import { blockRepeatDateSlot, blockSelectedDateSlot } from 'repositories/patient-appointment-repository';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import Loader from 'components/Loader';
import moment from 'moment';

const BlockTimeSlot = ({ t,blockReason ,setBlockReason, setModalOpen, modalOpen, officeId, selectedSlotDate, doctorId, selectedSlots, setSelectedSlots }) => {
    const [radioState, setRadioState] = useState({
        blockOnly: true,
        repeatday: false,
        repeatEveryday: false,
    });
    const [error, setError] = useState("");

    const { mutate: blockSelectedDate, isLoading: blockSelectedDateLoading } = useMutation((data) => blockSelectedDateSlot(data));
    const { mutate: blockRepeatDate, isLoading: blockRepeatDateSlotLoading } = useMutation((data) => blockRepeatDateSlot(data));


    const handleRadioChange = (e) => {
        const { name, checked } = e.target;
        setRadioState({
            blockOnly: false,
            repeatday: false,
            repeatEveryday: false,
            [name]: checked,
        });
    };

    const handleChange = (e) => {
        setBlockReason(e.target.value);
    }

    const handleClick = () => {
        if (!blockReason?.length) {
            setError(t("patientAppointmentMembers.addYourComment"))
        } else if (blockReason?.length > 500) {
            setError(t("patientAppointmentMembers.reasonBlock"))
        } else {
            if (error) setError("")
            const startTimes = selectedSlots?.filter(app => app.startTime)?.map(app => app.startTime);
            let date = moment(selectedSlotDate).format('YYYY-MM-DD')
            const Params = {
                officeId: officeId,
                date: date,
                doctorId: doctorId,
                slots: startTimes,
                reason: blockReason
            }
            if (radioState.blockOnly) {
                blockSelectedDate(Params, {
                    onSuccess: (res) => {
                        setSelectedSlots([])
                        setBlockReason("");
                        toast.success(res.message);
                        setModalOpen(false)
                    }
                });
            } else {
                let day = radioState.repeatday ? moment(selectedSlotDate)?.day()  : null;
                Params.day = day;
                blockRepeatDate(Params, {
                    onSuccess: (res) => {
                        setSelectedSlots([])
                        setBlockReason("");
                        toast.success(res.message);
                        setModalOpen(false)
                    }
                });
            }
        }
    }
let loader = blockSelectedDateLoading || blockRepeatDateSlotLoading
    return (
        <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} className="modal-dialog-centered deactivate-modal modal-width-660">
            {loader && <Loader />}
            <div className={styles['timeslot-modal']}>
                <ModalBody className={styles[' modal-content-part'] + " " + "d-flex flex-column p-0"}>
                    <div>
                        <span className={styles["close-btn"] + " " + "d-flex justify-content-end"} onClick={() => setModalOpen(!modalOpen)}>
                            <img src={require('assets/images/cross.svg').default} alt="close" />
                        </span>
                        <div>
                            <Text size="25px" weight="500" color="#111B45" marginBottom="30px">{t('patientAppointmentMembers.blockTimeSlot')}</Text>
                        </div>
                    </div>
                    <div className='select-reason ch-radio d-flex flex-column'>
                        <label>
                            <input
                                type="radio"
                                name="blockOnly"
                                checked={radioState.blockOnly}
                                onChange={handleRadioChange}
                            />
                            <span> {t('patientAppointmentMembers.blockSelectedDate')}</span>
                        </label>
                        <label className='mt-1'>
                            <input
                                type="radio"
                                name="repeatday"
                                checked={radioState.repeatday}
                                onChange={handleRadioChange}
                            />
                            <span>{t('patientAppointmentMembers.repeatblock')} {moment(selectedSlotDate).format('dddd')}</span>
                        </label>
                        <label className='mt-1 mb-1'>
                            <input
                                type="radio"
                                name="repeatEveryday"
                                checked={radioState.repeatEveryday}
                                onChange={handleRadioChange}
                            />
                            <span>{t('patientAppointmentMembers.repeatblockforAll')}</span>
                        </label>
                    </div>
                    <div className='mt-4'>
                        <Input
                            Title='Reason of Blocking Timeslot'
                            Type="text"
                            Placeholder='Reason'
                            Name={"BlockReason"}
                            Value={blockReason}
                            HandleChange={handleChange}
                            Error={error}
                        />
                    </div>
                    <div className={styles["common-appointment-btn-container"]}>
                        <button className='button button-round button-shadow mr-md-4 mb-3 w-sm-100' onClick={handleClick}> {t('patientAppointmentMembers.submit')} </button>
                        <button className='button button-round button-border button-dark btn-mobile-link' onClick={() => setModalOpen(!modalOpen)}>  {t('patientAppointmentMembers.cancel')} </button>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default BlockTimeSlot