import Text from 'components/Text';
import React from 'react';
import styles from "../TaskManagement.module.scss";
import { withTranslation } from 'react-i18next';

const PriorityFilter = ({ t, priorityFilter, setPriority}) => {
     const prioritiesOptions = [
        { id: 4, name: "All" },
        { id: 3, name: "Low" },
        { id: 2, name: "Medium" },
        { id: 1, name: "High" },
      ];
      
    return (
        <>
            <div className={styles["priority_text_radio"]}>
                <Text size="14px" weight="500" color="#77928B">
                    {t("taskManagement.priority")}
                </Text>

                <div className={styles["radio_btn_wrapper"]}>
                    {prioritiesOptions.map((priority) => (
                        <div key={priority?.name} className="ch-radio">
                            <label
                                className="mr-4 p-0"
                            >
                                <input
                                    type="radio"
                                    name="blockTimeslotAllDoctors"
                                    checked={priorityFilter?.id === priority?.id}
                                    onChange={() => setPriority(priority)}
                                />
                                <span> {priority.name} </span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default withTranslation()(PriorityFilter)