import React, { useEffect, useState } from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useDoctorOffices } from "repositories/doctor-repository";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";


function OfficeFilterList({t, selectedOffice, setOfficeFilter,}) {
    const profile = useSelector(state => state.userProfile.profile);
    const [doctorOffices ,setDoctorOffices] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const {data, isLoading } = useDoctorOffices(profile?.id, pageNumber, 100);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setDoctorOffices(uniqBy([...data?.items], "id"));
                setTotalItems(data?.pagination?.totalItems);
            }
        }
        else if (!isLoading && data && data.items) {
            setDoctorOffices(prev => uniqBy([...prev, ...data?.items], "id"));
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);


    useEffect(() => {
        if (selectedOffice?.length === doctorOffices?.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedOffice, doctorOffices]);

    const handleChange = (e, item) => {
        const offices = [...selectedOffice];
        const officeIndex = offices.findIndex((v) => +item.id === +v);
        if (e.target.checked) {
            offices.push(item.id);
        } else {
            if (officeIndex > -1) offices.splice(officeIndex, 1);
        }
        setOfficeFilter(offices);
        setSelectAll(false);
    };

    const handleAllChange = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setOfficeFilter(doctorOffices?.length ? doctorOffices?.map((item) => item.id) : []);
        } else {
            setOfficeFilter([]);
        }
    };

    return (
        <ul className={["filter-list"]}>
            {doctorOffices?.length && (
                <ul>
                    <li>
                        <div className="ch-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={handleAllChange}
                                    checked={selectAll}
                                />{" "}
                                <span>
                                  {t("notes.allFilters")}
                                  </span>
                            </label>
                        </div>
                    </li>
                    <hr className="mr-4" />
                </ul>
            )}
            {doctorOffices?.length &&
                doctorOffices?.map((item, key) => (
                    <li key={key}>
                        <div className="ch-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleChange(e, item)}
                                    checked={selectedOffice?.includes(item.id)}
                                />{" "}
                                <span> {item.name}</span>
                            </label>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default OfficeFilterList;