import axios from "axios";
import { useQuery } from "react-query";
import { Config } from "../config";

const performanceReviewBaseUrl = Config.serviceUrls.performanceReviewBaseUrl;
const USER_POINT_URL = Config.serviceUrls.userBaseUrl;

export async function getPerformanceReviewList(pageNumber, pageSize, officeId, dates, statuses, isArchive) {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/ListForReviewer`, {
        pageNumber,
        pageSize,
        officeId,
        fromDate: dates.from,
        toDate: dates.to,
        statuses,
        isArchived: isArchive
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function usePerformanceReviewList(pageNumber, pageSize, officeId, dates, statuses, isArchive) {
    return useQuery(
        ["performance-review", pageNumber, pageSize, officeId, dates, statuses, isArchive],
        () => getPerformanceReviewList(pageNumber, pageSize, officeId, dates, statuses, isArchive),
        {
            staleTime: 0,
            cacheTime: 0
        }
    );
}

export async function getPerformanceRespondList(pageNumber, pageSize, officeId, dates, statuses, isArchive) {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/ListForRespondent`, {
        pageNumber,
        pageSize,
        officeId,
        fromDate: dates.from,
        toDate: dates.to,
        statuses,
        isArchived: isArchive
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function usePerformanceRespondList(pageNumber, pageSize, officeId, dates, statuses, isArchive) {
    return useQuery(
        ["performance-Respond", pageNumber, pageSize, officeId, dates, statuses, isArchive],
        () => getPerformanceRespondList(pageNumber, pageSize, officeId, dates, statuses, isArchive),
        {
            staleTime: 0,
            cacheTime: 0
        }
    );
}

export async function getPerformanceByAdmin(pageNumber, pageSize, officeId, dates, statuses, isArchive) {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/AllInitiatedByAdmins`, {
        pageNumber,
        pageSize,
        officeId,
        fromDate: dates.from,
        toDate: dates.to,
        statuses,
        isArchived: isArchive
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function usePerformanceByAdmin(pageNumber, pageSize, officeId, dates, statuses, isArchive) {
    return useQuery(
        ["performance-by-admin", pageNumber, pageSize, officeId, dates, statuses, isArchive],
        () => getPerformanceByAdmin(pageNumber, pageSize, officeId, dates, statuses, isArchive),
        {
            staleTime: 0,
            cacheTime: 0
        }
    );
}

export async function getAllPerformanceReviewStaff(officeId) {
    const response = await axios.get(`${performanceReviewBaseUrl}/PerformanceReviewForm/StaffList/${officeId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;;
}

export function useAllPerformanceReviewStaff(officeId) {
    return useQuery(
        ["performance-review/staff", officeId],
        () => getAllPerformanceReviewStaff(officeId)
    );
}

export const sendReview = async (params) => {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};


export async function archieveByReviewer(formId) {
    const response = await axios.patch(`${performanceReviewBaseUrl}/PerformanceReviewForm/ArchiveByReviewer?formId=${formId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function unArchieveByReviewer(formId) {
    const response = await axios.patch(`${performanceReviewBaseUrl}/PerformanceReviewForm/UnarchiveByReviewer?formId=${formId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteByReviewer(formId) {
    const response = await axios.delete(`${performanceReviewBaseUrl}/PerformanceReviewForm/${formId}/DeleteByReviewer`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function archieveByRespondent(formId) {
    const response = await axios.patch(`${performanceReviewBaseUrl}/PerformanceReviewForm/ArchiveByRespondent?formId=${formId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function unArchieveByRespondent(formId) {
    const response = await axios.patch(`${performanceReviewBaseUrl}/PerformanceReviewForm/UnarchiveByRespondent?formId=${formId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteByRespondent(formId) {
    const response = await axios.delete(`${performanceReviewBaseUrl}/PerformanceReviewForm/${formId}/DeleteByRespondent`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function cancelReview(formId) {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/${formId}/Cancel`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getQuestions(formId, isMyPerformanceReview) {
    const paramValue = isMyPerformanceReview  ? 'ListForRespondent' : 'ListForReviewer';
    const response = await axios.get(`${performanceReviewBaseUrl}/Questionnaire/${paramValue}?formId=${formId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data?.data;
}

export function useGetQuestions(formId, isMyPerformanceReview) {
    return useQuery(
        ["performance-review/questions", formId, isMyPerformanceReview],
        () => getQuestions(formId, isMyPerformanceReview)
    );
}

export const submitForm = async (params, typeOfSubmit) => {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/${typeOfSubmit}`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const finalizeForm = async (params) => {
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/Finalize`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const saveAsDraft = async (params, isMyPerformanceReview) => {
    const paramValue = isMyPerformanceReview  ? 'SaveAsDraftByRespondent' : 'SaveAsDraftByReviewer';
    const response = await axios.post(`${performanceReviewBaseUrl}/PerformanceReviewForm/${paramValue}`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getFormData(formId) {
    const response = await axios.get(`${performanceReviewBaseUrl}/PerformanceReviewForm/${formId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data?.data;
}

export function useGetFormData(formId) {
    return useQuery(
        ["performance-review/form-data", formId],
        () => getFormData(formId)
    );
}

export async function disablePerformanceReview() {
    const response = await axios.put(`${USER_POINT_URL}/Preferences/DisablePerformanceReviewTemplateMessage`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}