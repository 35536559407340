import React, { useState } from "react";
import styles from "../EventDetails.module.scss";
import Text from "components/Text";
import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";
import {
  getNotificationNameById,
  getSchedulerStatusById,
  notifyAttendeesOptions,
  notifyAttendeesOptionsForAllDayEvent,
} from "../../../../constants";
import { getEventIcsLink } from "repositories/scheduler-repository";
import toast from "react-hot-toast";
import CustomModal from "components/CustomModal";
import AddToCalendar from "accountOwner/pages/components/AddToCalendar";
import Loader from "components/Loader";
import { addToCalenderText } from "utils";

function DetailsTab({ t, eventDetails }) {
  const [toolTipModal, setToolTipModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleAddToCalendar = async () => {
    setIsDownloading(true);
    try {
      const url = await getEventIcsLink(eventDetails?.id);
      window?.open(url, "_self");
      toast.success(t("fileDownloaded"));
    } catch (error) {
      toast.error(error?.message);
    }
    setIsDownloading(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  const selectedEmployeesCount = () => {
    const filteredEmployees =
      eventDetails?.eventEmployees?.filter(
        (employee) => employee.isDeletedForThisSchedulerEvent === false
      ) || [];

    // Implement the display logic
    if (filteredEmployees.length === 0) {
      return t("accountOwner.noEmployeesSelected");
    } else if (filteredEmployees.length === 1) {
      const employee = filteredEmployees[0];
      return employee?.user?.firstName + " " + employee?.user?.lastName;
    } else {
      return filteredEmployees.length + " " + t("Selected");
    }
  };

  return (
    <Row>
      {isDownloading && <Loader />}
      <Col lg="12">
        <div className="d-flex justify-content-md-end mb-3">
          <div
            className={
              styles["status-tag"] +
              " " +
              (eventDetails?.isDraft
                ? styles["draft-warning-bg"]
                : styles["dark-grey-bg"])
            }
          >
            {eventDetails?.isDraft
              ? t("scheduler.isDraft")
              : t("scheduler.publishedEvent")}
          </div>
        </div>
      </Col>
      <Col lg="6">
        <ul className={styles["white-col-list"]}>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("accountOwner.date")}
            </Text>
            <Text size="14px" marginBottom="0" weight="600" color="#102c42">
              {moment(eventDetails?.date, "YYYY-MM-DDTHH:mm:ss").format(
                "MMM DD, YYYY - ddd"
              )}
              {eventDetails?.isAllDayEvent
                ? ", " + t("accountOwner.allDayEvent")
                : ""}
              {eventDetails?.office?.timezoneCode && `(${eventDetails?.office?.timezoneCode})`}
            </Text>
          </li>
          <li>
            <Row>
              <Col xs="6">
                <Text
                  size="12px"
                  marginBottom="5px"
                  weight="400"
                  color="#6f7788"
                >
                  {t("staff.startTime")}
                </Text>
                <Text size="14px" marginBottom="0" weight="600" color="#102c42">
                  {eventDetails?.startTime
                    ? moment(
                        eventDetails?.startTime,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("h:mm A")
                    : ""}
                </Text>
              </Col>
              <Col xs="6">
                <Text
                  size="12px"
                  marginBottom="5px"
                  weight="400"
                  color="#6f7788"
                >
                  {t("staff.endTime")}
                </Text>
                <Text size="14px" marginBottom="0" weight="600" color="#102c42">
                  {eventDetails?.endTime
                    ? moment(
                        eventDetails?.endTime,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("h:mm A")
                    : ""}
                </Text>
              </Col>
            </Row>
          </li>

          <li>
            <Row>
              <Col sm="6">
                <Text
                  size="12px"
                  marginBottom="5px"
                  weight="400"
                  color="#6f7788"
                >
                  {t("repeat")}
                </Text>
                <Text size="14px" marginBottom="0" weight="600" color="#102c42">
                  {eventDetails?.repeatedType === 2
                    ? `${t("scheduler.repeatForAll")} ${moment(
                        eventDetails.date,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("dddd")}`
                    : eventDetails?.repeatedType === 3
                    ? t("scheduler.repeatForAllFuture")
                    : t("scheduler.never")}
                </Text>
              </Col>
              {eventDetails?.repeatedType !== 1 &&
                eventDetails?.repeatedEndDate && (
                  <Col sm="6">
                    <Text
                      size="12px"
                      marginBottom="5px"
                      weight="400"
                      color="#6f7788"
                    >
                      {t("accountOwner.endDateRepeatedEvents")}
                    </Text>
                    <Text
                      size="14px"
                      marginBottom="0"
                      weight="600"
                      color="#102c42"
                    >
                      {moment(
                        eventDetails?.repeatedEndDate,
                        "YYYY-MM-DDTHH:mm:ss"
                      ).format("MMM DD, YYYY - ddd")}{" "}
                      {eventDetails?.office?.timezoneCode && `(${eventDetails?.office?.timezoneCode})`}
                    </Text>
                  </Col>
                )}
            </Row>
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("accountOwner.offices")}
            </Text>
            <Text size="14px" marginBottom="0" weight="600" color="#102c42">
              {eventDetails?.office?.name}
            </Text>
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("location")}
            </Text>
            <Text size="14px" marginBottom="0" weight="600" color="#102c42">
              {eventDetails?.location}
            </Text>
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("superAdmin.role")}
            </Text>
            {eventDetails?.eventRoles?.length > 0 &&
              eventDetails?.eventRoles?.map((v, index) => (
                <Text
                  size="14px"
                  marginBottom="5px"
                  weight="600"
                  color="#102c42"
                  key={index}
                >
                  {v?.designations?.name}
                </Text>
              ))}
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("accountOwner.employe")}
            </Text>
            <Text size="14px" marginBottom="0" weight="600" color="#102c42">
              {selectedEmployeesCount()}
            </Text>
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("accountOwner.notes")}
            </Text>
            <Text size="14px" marginBottom="0" weight="600" color="#102c42">
              {eventDetails?.note}
            </Text>
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("accountOwner.tags")}
            </Text>
            <div className={styles["tag-list"]}>
              {eventDetails?.eventTags?.length > 0 &&
                eventDetails?.eventTags?.map((v, index) => (
                  <span key={index}>{v?.title}</span>
                ))}
              {eventDetails?.eventTags?.length === 0 && <span>-</span>}
            </div>
          </li>
          <li>
            <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
              {t("scheduler.notifyAttendees")}
            </Text>
            <Text size="14px" marginBottom="0" weight="600" color="#102c42">
              {getNotificationNameById(
                eventDetails?.reminderBeforeInMins,
                eventDetails?.isAllDayEvent
                  ? notifyAttendeesOptionsForAllDayEvent
                  : notifyAttendeesOptions
              )}
            </Text>
          </li>
          <li>
            <AddToCalendar
              firstIcon={require("assets/images/download-icon.svg").default}
              middleText={t("accountOwner.addToCalendar")}
              secondIcon={require("assets/images/alert-circle.svg").default}
              handleAddToCalendar={handleAddToCalendar}
              setToolTipModal={setToolTipModal}
            />
          </li>
        </ul>
      </Col>

      <Col lg="6">
        <ul className={styles["white-col-list"]}>
          <div className={styles["invitation-status"]}>
            <Text size="20px" marginBottom="10px" weight="500" color="#111b45">
              {t("accountOwner.employeesInvitationStatus")}
            </Text>
            <ul className={styles["invitation-list"]}>
              {eventDetails?.eventEmployees &&
              eventDetails.eventEmployees.length > 0 ? (
                eventDetails.eventEmployees.some(
                  (item) => !item.isDeletedForThisSchedulerEvent
                ) ? (
                  eventDetails.eventEmployees.map((item, index) =>
                    !item.isDeletedForThisSchedulerEvent ? (
                      <li key={index}>
                        <Text
                          size="14px"
                          marginBottom="0px"
                          weight="500"
                          color="#587e85"
                          className="col-sm-4"
                        >
                          {item.user?.firstName}
                        </Text>
                        <Text
                          size="14px"
                          marginBottom="0px"
                          weight="400"
                          color="#535b5f"
                          className="col-sm-4"
                        >
                          {item?.user?.role?.systemRole === 2
                            ? "-"
                            : item?.user?.designation?.name}
                        </Text>
                        <Text
                          size="14px"
                          marginBottom="0px"
                          weight="400"
                          color="#535b5f"
                          className={"col-sm-4 " + styles["pending_li"]}
                        >
                          {t(getSchedulerStatusById(item?.status))}
                        </Text>

                        {(item?.reasonForRejection ||
                          item?.reasonForCancel) && (
                          <Text
                            size="14px"
                            marginBottom="0"
                            weight="200"
                            color="#587e85"
                            margin="5px 0px"
                            className={styles["para_p"]}
                          >
                            <b>
                              {" "}
                              {item?.reasonForCancel
                                ? t("accountOwner.reasonOfCancellation")
                                : t("accountOwner.reasonOfRejection")}
                              :
                            </b>
                            &nbsp;&nbsp;&nbsp;
                            {item?.reasonForRejection || item?.reasonForCancel}
                          </Text>
                        )}
                      </li>
                    ) : null
                  )
                ) : (
                  <li style={{ marginLeft: "1px" }}>
                    <span>{t("accountOwner.noUsersAdded")}</span>
                  </li>
                )
              ) : (
                <li style={{ marginLeft: "1px" }}>
                  <span>{t("accountOwner.noUsersAdded")}</span>
                </li>
              )}
            </ul>
          </div>
        </ul>
      </Col>
      <CustomModal
        isOpen={toolTipModal}
        setIsOpen={setToolTipModal}
        title={t("accountOwner.addToCalendar")}
        subTitle1={addToCalenderText()}
        calender={true}
      />
    </Row>
  );
}

export default withTranslation()(DetailsTab);
