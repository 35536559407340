import React, { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styles from "../../../../../Appointments.module.scss";
import { Card } from 'reactstrap';
import Page from 'components/Page';
import plusImg from "../../../../../../../../assets/images/Group 6.svg";
import AppointmentReminder from 'accountOwner/pages/Appointments/components/AppointmentReminder';
import Input from 'components/Input';
import { calculateReminderHours, decodeId, getStorage, handleSuccess } from 'utils';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import constants from '../../../../../../../../constants';
import { AppointmentsContext } from 'accountOwner/pages/Appointments/AppointmentsContext';
import DeleteReminderModal from 'accountOwner/pages/Appointments/modal/DeleteReminderModal';
import { addNewAppointmentReminder, addNewGlobalAppointmentReminder } from 'repositories/patient-appointment-repository';
import { useMutation } from 'react-query';
import Loader from 'components/Loader';

const KEY_NAME = 'add-reminder';
function AddAppointmentReminderPlan({ t }) {
    const { officeId, requestId } = useParams();
    let { patientId } = useParams();
    const decodeOfficeId = parseInt(decodeId(officeId));
    const decodePatientId = parseInt(decodeId(patientId));
    const isRecallReminderPlan = getStorage("isRecallReminderPlan");
    const location = useLocation();
    const { state } = location;
    const { mutate: addAppointmentReminderMutate, isLoading } = useMutation((data) => addNewAppointmentReminder(data));
    const { mutate: addGlobalAppointmentReminderMutate, isLoading: addGlobalReminderLoading } = useMutation((data) => addNewGlobalAppointmentReminder(data));
    const {
        templateLoading,
        reminderSets,
        templateOptions,
        options,
        setReminderSets,
        handleOnChange,
        handleAddReminderClick,
        history,
        reminderNames,
        handleOnReminderNameChange,
        deleteReminderKeys,
        setDeleteReminderKeys,
        deleteReminderItem,
        errorMsg,
        validateForm,
        templateLists,
        setReminderNames,
        ownerId
    } = useContext(AppointmentsContext);

    useEffect(() => {
        if (!reminderSets?.[KEY_NAME] || reminderSets?.[KEY_NAME]?.length === 0) {
            setReminderSets(prev => ({
                ...prev,
                [KEY_NAME]: [{
                    selectTemplate: null,
                    selectBeforeType: 2,
                    reminderBefore: 1,
                    isEmail: true,
                    isPushNotification: true,
                    isSms: true,
                    id: 1
                }]
            }));
        }
    }, []);

    let reminderPlanType = 2;
    if (patientId && !isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 2;
    }

    if (patientId && isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 5;
    }

    if (officeId && !patientId && !isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 3;
    }

    if (officeId && !patientId && isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 6;
    }

    if (!patientId && !officeId && !isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 4;
    }

    if (!patientId && !officeId && isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 7;
    }


    const goBack = () => {
        if (requestId) {
            history.push({ pathname: constants.routes.appointment.appointmentReminder.replace(':officeId', officeId).replace(':requestId', requestId), state });
        } else if (patientId) {
            history.push(constants.routes.appointment.globalReminder.replace(':officeId', officeId).replace(':patientId', patientId));
        } else if (officeId && !patientId) {
            history.push(constants.routes.appointment.AccountGlobalOfficeReminder.replace(':officeId', officeId));
        } else {
            history.push({ pathname: constants.routes.appointment.AccountGlobalReminder, state });
        }
    }

    const handleUpdateGlobalReminderMapping = (data) => {
        addGlobalAppointmentReminderMutate(data, {
            onSuccess: async (res) => {
                handleSuccess(res.message);
                goBack();
            }
        });
    }

    const handleSave = () => {
        const isReminderName = true;
        if (validateForm(KEY_NAME, isReminderName)) {
            const updatedReminderData = reminderSets?.[KEY_NAME]?.map(reminder => {
                const matchedTemplate = templateLists?.data?.find(template => template.id === reminder.selectTemplate);
                if (matchedTemplate) {
                    return {
                        ...reminder,
                        messageTemplateId: reminder?.selectTemplate,
                        reminderBeforeType: reminder?.selectBeforeType,
                        reminderBeforeHours: calculateReminderHours(reminder?.reminderBefore, reminder?.selectBeforeType),
                        messageTemplateDetail: matchedTemplate
                    };
                }
                delete reminder?.selectBeforeType;
                delete reminder?.selectTemplate;
                return reminder;
            });

            const addReminderData = {
                Title: reminderNames[KEY_NAME],
                officeId: decodeOfficeId,
                AppointmentReminderSetting: updatedReminderData
            };
            if (requestId) {
                addAppointmentReminderMutate(addReminderData, {
                    onSuccess: (res) => {
                        handleSuccess(res.message);
                        setReminderSets(prev => ({
                            ...prev,
                            [KEY_NAME]: []
                        }));
                        setReminderNames(prev => ({
                            ...prev,
                            [KEY_NAME]: null
                        }));
                        goBack();
                    }
                });
            } else {
                const replaceData = {
                    ...addReminderData,
                    isTempPlan: false,
                    ownerId: ownerId,
                    patientId: decodePatientId,
                    reminderPlanType: reminderPlanType,
                };
                handleUpdateGlobalReminderMapping(replaceData)
            }

        }
    };

    return (
        <Page onBack={goBack}>
            {isLoading || addGlobalReminderLoading && <Loader />}
            <p className={styles["mobile-text-size"]}>{t('patientAppointmentMembers.addAppointmnetReminderPlan')}</p>
            <Card className="form-wrapper">
                <div className={styles['select-appointment-reminder-plan']}>
                    <Input
                        className="appointment-input-box"
                        Title={t('patientAppointmentMembers.specifyName')}
                        Type="text"
                        Value={reminderNames[KEY_NAME]}
                        HandleChange={(e) => handleOnReminderNameChange(KEY_NAME, e.target.value)}
                        Placeholder={t('form.placeholder1', { field: t('patientAppointmentMembers.specifyName') })}
                        Name={"SpecifyReminderName"}
                        MaxLength={'120'}
                    />
                    {!templateLoading && reminderSets?.[KEY_NAME]?.map((appointmentReminder, index) => (
                        <div className={styles['appointment-reminder-modal-card-Wrapper']} key={appointmentReminder?.id}>
                            <AppointmentReminder
                                appointmentReminder={appointmentReminder}
                                setReminderSets={setReminderSets}
                                handleOnChange={handleOnChange}
                                templateOptions={templateOptions}
                                options={options}
                                isCreateTemplateVisable={true}
                                officeId={officeId}
                                requestId={requestId}
                                setDeleteReminderKeys={setDeleteReminderKeys}
                                keyName={KEY_NAME}
                                index={index}
                            />
                        </div>
                    ))}
                </div>
                <button className={styles['illness-type-save-cancel-btn'] + " " + styles["add-new-member-btn"]} onClick={() => handleAddReminderClick(KEY_NAME)}>
                    <span>
                        <img src={plusImg} alt="Add Reminder" className="mr-1" />
                    </span>
                    {t('patientAppointmentMembers.addNewReminder')}
                </button>
                {errorMsg && <span className="error-msg mt-4">{errorMsg}</span>}
                <div className={styles['common-appointment-btn-container'] + " float-left"}>
                    <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={handleSave}>
                        {t('patientAppointmentMembers.save')}
                    </button>
                    <button className="button button-round button-border button-dark btn-mobile-link" onClick={goBack}>
                        {t('patientAppointmentMembers.cancel')}
                    </button>
                </div>
            </Card>
            {deleteReminderKeys?.[KEY_NAME] && <DeleteReminderModal isModalOpen={deleteReminderKeys?.[KEY_NAME]} keyName={KEY_NAME} closeModal={() =>
                setDeleteReminderKeys(prev => ({
                    ...prev,
                    [KEY_NAME]: null
                }))}
                deleteReminderItem={deleteReminderItem}
                title={t('patientAppointmentMembers.confirm')}
            />}
        </Page>
    );
}

export default withTranslation()(AddAppointmentReminderPlan);
