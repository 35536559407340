import React from 'react';
import Card from 'components/Card';
import Text from 'components/Text';
import Page from 'components/Page';
import styles from "../Notices.module.scss";
import { useSingleNotices } from 'repositories/notices-repository';
import useNoticesDetailModule from '../hook/useNoticesDetailModule';
import Loader from 'components/Loader';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const NoticeDetails = ({ t, history }) => {
    const location = useLocation();
    const isMynoticeDetails = location?.pathname.includes('my-notice-details');
    const {
        data: {
            loader,
            noticesDetail,
            reasonsDescription,
            officeDetail
        },
        methods: {
            goToPreviousScreen
        }

    } = useNoticesDetailModule(useSingleNotices, history, isMynoticeDetails);

    const resson = () => {
        return (
            <>
                {reasonsDescription?.length > 0 && reasonsDescription?.map(item => {
                    return (
                        <div>
                            <Text size="14px" lineHeight="14px" weight="600" color="#102C42" marginTop="10px">{item}</Text>
                        </div>
                    )
                })}
                {noticesDetail?.otherReason && <Text size="14px" lineHeight="14px" weight="600" color="#102C42" marginTop="10px">Other: {noticesDetail?.otherReason}</Text>}
            </>
        )
    }

    const noticeDetails = [
        {
            label: t("notices.nameTitle"),
            value: `${noticesDetail?.user?.firstName} ${noticesDetail?.user?.lastName}, ${noticesDetail?.designation?.title}`,
        },
        {
            label: t("notices.sentBy"),
            value: `${noticesDetail?.sender?.firstName} ${noticesDetail?.sender?.lastName}`,
        },
        {
            label: t("notices.reasonOfCorrectivection"),
            value: resson(),
        },
        {
            label: t("notices.DescriptionProblem"),
            value: noticesDetail?.problemDescription,
        },
        {
            label: t("notices.companyExpectation"),
            value: noticesDetail?.companyExpectation,
        },
    ];
    return (
        <Page title={officeDetail?.name} onBack={goToPreviousScreen}>
            {loader && <Loader />}
            <h3 className={styles.details_sub_title}>{t("notices.noticeDetails")}</h3>
            <Card className={styles.details_notice_card}>
                <div className={styles.notice_details}>
                    {noticeDetails.map((detail, index) => {
                        return (
                            <div key={index}>
                                <p className={styles.detail_label}>{detail.label}</p>
                                <p className={styles.detail_value}>{detail.value}</p>
                            </div>
                        )
                    })}
                </div>
            </Card>
        </Page>
    );
}

export default withTranslation()(NoticeDetails);
