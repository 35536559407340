import React, { useState } from 'react';
import { Button, Card, Modal, ModalBody } from 'reactstrap';
import styles from "../../Appointments/Appointments.module.scss";
import Text from 'components/Text';
import starImage from "../../../../assets/images/star-rating.svg";
import crossImg from "../../../../assets/images/cross-mark-button.svg";
import Input from 'components/Input';

const FeedBackModal = ({ t, setModalOpen, modalOpen, appointmentDetail, addReviewComment = () => {} }) => {
    const [comment, setComment] = useState("");
    const [error, setError] = useState("");
    const handleChange = (e) => {
        setComment(e.target.value);
    }

    const handleClick = async () => {
        if (!comment?.length) {
            setError(t("patientAppointmentMembers.addYourComment"))
            return null
        } else {
            let prams = {
                appointmentId: appointmentDetail?.id,
                feedbackComment: comment
            }
            await addReviewComment(prams);
            setModalOpen(false);
        }
    }

    return (
        <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} centered={true} className={"modal-dialog-centered modal-width-660 "} modalClassName='custom-modal'>
            <Button className={styles['PatientAppointmentDetails-cross-btn']} aria-label="Close" type="button" onClick={() => setModalOpen(!modalOpen)}>
                <img src={crossImg} alt="cross button" />
            </Button>
            <Text size="25px" weight="500" color="#111B45" marginBottom="30px"> {t("patientAppointmentMembers.feedback")}</Text>
            <ModalBody>
                <div>
                    <Text size="12px" weight="400" color="#6F7788" marginTop="0px">{t("Patient’s Feedback")}</Text>
                    <div>
                        <img src={starImage} alt="starImage" />
                    </div>
                </div>
                <Card className={styles["patient-appointment-detail-modal-card"]}>
                    <Text size="12px" weight="400" color="#6F7788" marginBottom="5px">{t("Patient’s Comment")}</Text>
                    <Text size="14px" weight="600" color="#102C42">{appointmentDetail?.feedback}</Text>
                </Card>
                <Text size="13px" weight="400" color="#6F7788" marginBottom="5px">{t("Your Comment")}</Text>
                {appointmentDetail?.feedbackComment ? (<Card className={styles["patient-appointment-detail-modal-card"]}>
                    <Text size="14px" weight="600" color="#102C42">{appointmentDetail?.feedbackComment}</Text>
                </Card>
                ) : (
                    <Input
                    className={styles["appointment-input-box"]}
                    Title={t('This will be seen to patients')}
                    Type="text"
                    Placeholder={t('Type your message', { field: t('your message') })}
                    Name={"MessageToPatients"}
                    Value={comment}
                    HandleChange={handleChange}
                    Error={error}
                />)}

            </ModalBody>
            {!appointmentDetail?.feedbackComment && <div className={styles["common-appointment-btn-container"]}>
                <Button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={handleClick}>{t("patientAppointmentMembers.submit")}</Button>
                <Button className="button button-round button-border button-dark btn-mobile-link" onClick={() => setModalOpen(!modalOpen)}>{t("patientAppointmentMembers.cancel")}</Button>
            </div>}
        </Modal>
    )
}

export default FeedBackModal