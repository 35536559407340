import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'rc-time-picker/assets/index.css'
import { Modal } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import crossIcon from '../../../../assets/images/cross.svg'
import Text from 'components/Text'
import styles from "../../Notes/Notes.module.scss"
import Loader from 'components/Loader'
import Button from 'components/Button'


function RejectionModal({ t, title, isRejectionModalOpen, setIsRejectionModalOpen, confirmReject, isCancel, appointmentID, actionStatus, showLoader, manageTab, isFromDetail = false }) {

    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState('');

    const handleChange = (val) => {
        if (val && val.trim().length && val.trim().length > 400) {
            val = val.trim().substring(0, 400);
        }
        setReason(val);
        setReasonError('');
    }
    const submitReason = async () => {
        if (reason && reason.trim().length > 1) {
            const params = {
                appointmentId: appointmentID,
                status: actionStatus,
                message: reason,
            };

            let res = await confirmReject(params);
            if (res?.status) {
                if (!isFromDetail) {
                    manageTab(3);
                }
                isCancel();
            }
        } else {
            setReasonError(t('taskManagement.errorMsgOfRejection'));
        }
    }
    return (
        <Fragment>
            <Modal
                isOpen={isRejectionModalOpen}
                toggle={() => setIsRejectionModalOpen(false)}
                className="modal-lg modal-dialog-centered"
                modalClassName="custom-modal"
            >
                <span className='close-btn' onClick={() => setIsRejectionModalOpen(false)}><img src={crossIcon} alt='close' /></span>
                <ModalBody>
                    {showLoader && <Loader />}
                    <Text size='20px' marginBottom="15px" weight='500' color='#111b45' >
                        <span className='modal-title-25'>{title}</span>
                    </Text>
                    <div className="c-field">
                        <label>{t("patientAppointmentMembers.cancelRequestLabel")}</label>
                        <textarea
                            placeholder={"Add a Reason"}
                            className={"c-form-control " + styles["custom-textarea-control"]}
                            name="reason"
                            maxLength="400"
                            onChange={(e) => handleChange(e.currentTarget.value)}
                            value={reason}
                        ></textarea>
                        {(!reason || !reason.trim().length) && reasonError && <span className="error-msg">{reasonError}</span>}
                    </div>
                    <div className={styles.main_btn_container}>
                        <Button onClick={() => submitReason()} title={t("accountOwner.cancelSchedule")}>{t('submit')}</Button>
                        <Button borderedSecondaryNew title={t('accountOwner.goBack')} onClick={() => setIsRejectionModalOpen(false)}>
                            <span> {t('accountOwner.goBack')}</span>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default withTranslation()(RejectionModal)