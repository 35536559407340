import React, { useEffect, useState } from "react";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import {
  exportEmploymentProof,
  useGetFormDataById,
} from "repositories/employment-proof";
import { useOfficeDetail } from "repositories/office-repository";
import toast from "react-hot-toast";
import ReviewForm from "../components/ReviewForm";
import styles from "../../Contracts/Contracts.module.scss";
import { b64toBlob, decodeId, isMobileTab, openAndDownloadPdf } from "utils";
import FileSaver from "file-saver";

const initialValues = {
  Date: new Date(),
  EmployeeName: "",
  Position: "",
  HiredDate: new Date(),
  TypeOfEmployment: 1,
  RateOfPay: "",
  AnnualSalary: "",
};

const EmploymentProofReview = ({ t, history, location }) => {
  const { state } = location;
  const query = new URLSearchParams(location.search);

  const proofIdFromQuery = query.get("proofId");
  const officeIdFromQuery = query.get("officeId");
  const isViewModeFromQuery = decodeId(query.get("isViewMode")) === "true";
const proofId = state?.proofId || decodeId(proofIdFromQuery);
const officeId = state?.officeId || decodeId(officeIdFromQuery);
const isViewMode = state?.isViewMode ?? isViewModeFromQuery;
  const { isLoading, data: template } = useGetFormDataById(proofId);
  const { data: officeDetail } = useOfficeDetail(officeId);
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [selectType, setType] = useState(1);

  useEffect(() => {
    setShowLoader(isLoading);
    if (!isLoading && template) {
      let newTemplate = {};
      template?.employmentProofDetails?.forEach((element) => {
        newTemplate[element?.title] = element?.body;
      });

      newTemplate["EmployeeName"] = template?.employeeName || "";
      newTemplate["Position"] = template?.position;
      newTemplate["HiredDate"] = template?.hiredDate;
      newTemplate["RateOfPay"] = template?.rateOfPay;
      newTemplate["AnnualSalary"] = template?.annualSalary;
      newTemplate["Date"] = template?.date;
      setType(template?.typeOfEmployment);
      setFormData((prev) => ({ ...prev, ...newTemplate }));
    }
  }, [isLoading, template, officeDetail]);

  const submitData = () => {
    return {
      Date: formData?.Date,
      EmployeeName: formData?.EmployeeName,
      Position: formData?.Position,
      HiredDate: formData?.HiredDate,
      TypeOfEmployment: selectType,
      RateOfPay: formData?.RateOfPay,
      AnnualSalary: formData?.AnnualSalary,
      EmploymentProofDetail: [
        {
          title: "Salutation",
          body: formData?.Salutation,
          overallOrder: 1,
        },
        {
          title: "Subject",
          body: formData?.Subject,
          overallOrder: 2,
        },
        {
          title: "Heading",
          body: formData?.Heading,
          overallOrder: 3,
        },
        {
          title: "Declaration",
          body: formData?.Declaration,
          overallOrder: 4,
        },
        {
          title: "Office Phone Number",
          body: formData?.["Office Phone Number"],
          overallOrder: 5,
        },
        {
          title: "Account Owner’s Email Address",
          body: formData?.["Account Owner’s Email Address"],
          overallOrder: 6,
        },
        {
          title: "Sincerely",
          body: formData?.Sincerely,
          overallOrder: 7,
        },
        {
          title: "Account Owner/Account Admin Name",
          body: formData?.["Account Owner/Account Admin Name"],
          overallOrder: 8,
        },
        {
          title: "Office Name",
          body: formData?.["Office Name"],
          overallOrder: 9,
        },
      ],
    };
  };

  const exportProof = async () => {
    setShowLoader(true);
    try {
      let res = await exportEmploymentProof(officeId, submitData());
      if (res) {
        openAndDownloadPdf(res);
      }
    } catch (err) {
      toast.error(t(err));
    } finally {
      setShowLoader(false);
    }
  };

  const goToPreviousScreen = () => {
    if (history.length > 1) {
        history.goBack();
    } else {
      history.replace("/notification");
    }
  };

  const TypeOfEmployment = [
    { id: 1, name: "Full Time" },
    { id: 2, name: "Part Time" },
  ];

  return (
    <Page onBack={goToPreviousScreen} title={officeDetail?.name}>
      <p className={styles.sub_title}>{t("employmentProof.employmentProof")}</p>
      <ReviewForm
        t={t}
        formData={formData}
        selectType={selectType}
        TypeOfEmployment={TypeOfEmployment}
        exportProof={exportProof}
        showLoader={showLoader}
        isShowSend={isViewMode}
      />
    </Page>
  );
};
export default withTranslation()(EmploymentProofReview);
