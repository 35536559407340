import React from "react";
import { withTranslation } from "react-i18next";
import StaffTaskDetails from "./staff";

const TaskDetails = () => {
    return (
        <>
            <StaffTaskDetails />
        </>
    );
};
export default withTranslation()(TaskDetails);
