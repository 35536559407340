import { useState, useEffect } from 'react';
import constants from "../../../../constants";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { appointmentCancelNextVisit, appointmentDetailActions, reviewComment } from 'repositories/patient-appointment-repository';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import {getStorage, setStorage } from 'utils';

const defaultDate = {
    from: moment().startOf('year').toDate(),
    to: moment().toDate()
}

const defaultUtcDate = {
    from: moment().startOf('year').utc().format('YYYY-MM-DD'),
    to: moment().utc().format('YYYY-MM-DD')
}


const pageSize = 10;
const useUpCommingModule = (useAllAppointments, state, currentTab , isRecall = false ) => {
    let appointmentFilter = {
        upcoming: constants.appointmentList.cache.upCommingfilters,
        recall: constants.appointmentList.cache.recallfilters,
        past: constants.appointmentList.cache.pastfilters
    };

    const globalFilterKey = appointmentFilter[currentTab];
    const officeSpecificFilterKey = `${appointmentFilter[currentTab]}_office_${state?.officeId || 'global'}`;
    const cacheFilters = getStorage(state?.officeId ? officeSpecificFilterKey : globalFilterKey) || {};
    const cacheIsClicked = getStorage(constants.appointmentList.cache.isTabClicked) || false;
    const isFilterApply = cacheFilters?.offices?.length > 0 || cacheFilters?.statuses?.length > 0 || cacheFilters?.doctors?.length > 0 ;
    const profile = useSelector(state => state.userProfile.profile);
    const [pageNumber, setPageNumber] = useState(1);
    const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(cacheFilters?.offices || []);
    const [appliedDocotorFilters, setAppliedDoctorFilters] = useState(cacheFilters?.doctors || []);
    const [searchValue, setSearchValue] = useState("");
    const [appliedAppointmentStatusFilters, setSppliedAppointmentStatusFilters] = useState(cacheFilters?.statuses || []);
    const [appliedRecallTypeFilters, setAppliedRecallTypeFilters] = useState(cacheFilters?.recallType || []);
    const [appointments, setAppointments] = useState([]);
    const [officeFilter, setOfficeFilter] = useState(cacheFilters?.offices || []);
    const [doctorFilter, setDocotrFilter] = useState(cacheFilters?.doctors || []);
    const [statusFilter, setStatusFilter] = useState(cacheFilters?.statuses || []);

    const [recallTypeFilter, setRecallTypeFilter] = useState(cacheFilters?.recallType || []);
    const [totalItems, setTotalItems] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [dates, setdates] = useState(defaultDate);
    const [utcdates, setUtcdates] = useState({from : null, to : null});
    const queryClient = useQueryClient();
    let params = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        doctorId: profile?.id,
        fromDate: utcdates.from,
        toDate: utcdates.to,
        officeIds: appliedOfficeFilters,
        appointmentStatus: appliedAppointmentStatusFilters,
        searchTerm: searchValue
    }
    if (state?.officeId && !state?.patientId) {
        params.officeId = state?.officeId;
        params.doctorIds = appliedDocotorFilters;
        params.isAdmin = false;
        delete params.doctorId;
        delete params.officeIds;
    }
    if (state?.patientId) {
        params.patientId = +state?.patientId;
        params.officeId = +state?.officeId;
        delete params.fromDate;
        delete params.toDate;
        delete params.officeIds;
    }
    if(state?.isAdmin) {
        params.isAdmin = true;
    }

    if(isRecall) { 
        delete params.appointmentStatus;
        delete params.fromDate;
        delete params.toDate;
        params.recallStatus =appliedAppointmentStatusFilters 
        params.recallTypeIds =appliedRecallTypeFilters
    }

    const { isLoading, data, refetch } = useAllAppointments(
        params,
    );
    useEffect(() => {
        if (state?.statusFilter && !cacheIsClicked?.isTabClicekd) {
            setStatusFilter([...state?.statusFilter]);
            setSppliedAppointmentStatusFilters([...state?.statusFilter]);
            setStorage(state?.officeId ? officeSpecificFilterKey : globalFilterKey, {
                offices: officeFilter,
                statuses: [...state?.statusFilter],
                recallType : recallTypeFilter,
                doctors : doctorFilter
            });
            setStorage(constants.appointmentList.cache.isTabClicked, {
                isTabClicekd: true,
            });
        }
    }, [])

    useEffect(() => {
        if (!isLoading && data && data.items) {
            setAppointments([...data?.items]);
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    }

    const appointmentDetailAction = async (params) => {
        setShowLoader(true);
        try {
            let res = await appointmentDetailActions(params);
            if (res?.statusCode === 200) {
                setShowLoader(false);
                toast.success(res.message);
                queryClient.invalidateQueries(["/Appointment/Upcoming", params]);
                refetch();
            }
            return res
        } catch (err) {
            setShowLoader(false);
            toast.error(err.message);
            return { status: false }
        }
    }

    const cancelNextRecallDate = async (AppointmentRecallId, RecallDate) => {
        setShowLoader(true);
        try {
            let res = await appointmentCancelNextVisit(AppointmentRecallId, RecallDate);
            if (res?.statusCode === 200) {
                setShowLoader(false);
                toast.success(res.message);
                queryClient.invalidateQueries(["/Appointment/Upcoming", params]);
                refetch();
            }
            return res
        } catch (err) {
            setShowLoader(false);
            toast.error(err.message);
            return { status: false }
        }
    }

    
    const addReviewComment = async (params) => {
        setShowLoader(true);
        try {
            let res = await reviewComment(params);
            if (res?.statusCode === 200) {
                setShowLoader(false);
                toast.success(res.message);
                queryClient.invalidateQueries(["/Appointment/Upcoming", params]);
                refetch();
            }
            return res
        } catch (err) {
            setShowLoader(false);
            toast.error(err.message);
            return { status: false }
        }
    }


    const handleApplyFilters = () => {
        setAppliedOfficeFilters(officeFilter);
        setSppliedAppointmentStatusFilters(statusFilter);
        setAppliedRecallTypeFilters(recallTypeFilter);
        setAppliedDoctorFilters(doctorFilter);

        setStorage(state?.officeId ? officeSpecificFilterKey : globalFilterKey, {
            offices: officeFilter,
            statuses: statusFilter,
            recallType : recallTypeFilter,
            doctors : doctorFilter
        });
        setPageNumber(1);
    };

    const resetFilter = () => {
        setPageNumber(1);
        setAppliedOfficeFilters([]);
        setSppliedAppointmentStatusFilters([]);
        setAppliedRecallTypeFilters([]);
        setAppliedDoctorFilters([]);
        setOfficeFilter([]);
        setStatusFilter([]);
        setRecallTypeFilter([]);
        setDocotrFilter([]);
        setStorage(state?.officeId ? officeSpecificFilterKey : globalFilterKey, {
            offices: [],
            statuses: [],
            recallType :[],
            doctors : []
        });
        setdates(defaultDate);
        setUtcdates({from : null, to : null});
        queryClient.invalidateQueries(["/Appointment/Upcoming", params]);
        refetch();
    };

    const updateDatesValues = (e, type) => {
        const currentDate = moment().utc().local().format("YYYY-MM-DD");
        const yearStartDate = moment().startOf('year').utc().local().format("YYYY-MM-DD");
        setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }))
        setUtcdates(prevState => ({
            ...prevState,
            from: type === 'from'
                ? moment.utc(e).local().format("YYYY-MM-DD")
                : prevState.from || yearStartDate,
            to: type === 'to'
                ? moment.utc(e).local().format("YYYY-MM-DD")
                : prevState.to || currentDate,
        }));
    }

    return {
        data: {
            isLoading,
            totalItems,
            pageNumber,
            appointments,
            showLoader,
            searchValue,
            officeFilter,
            statusFilter,
            dates,
            recallTypeFilter,
            isFilterApply,
            doctorFilter,
        },
        methods: {
            setPageNumber,
            appointmentDetailAction,
            handleInputChange,
            setOfficeFilter,
            handleApplyFilters,
            resetFilter,
            setStatusFilter,
            updateDatesValues,
            setRecallTypeFilter,
            cancelNextRecallDate,
            setDocotrFilter,
            addReviewComment
        }
    }
}

export default useUpCommingModule;