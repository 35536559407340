import React from 'react'
import Card from 'components/Card'
import Page from 'components/Page'
import Button from 'components/Button';
import styles from "../Notices.module.scss"
import NoticesCard from '../cards/NoticesCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useNoticesModule from '../hook/useNoticesModule';
import { useAllNotices } from 'repositories/notices-repository';
import Loader from 'components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { motion } from "framer-motion";
import { withTranslation } from 'react-i18next';
import Empty from 'components/Empty';

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const MyNotices = ({ t, isLoadingOfficeDetail, officeDetail, isMynotices }) => {
    const history = useHistory();

    const {
        data: {
            notices,
            isLoading,
            totalItems
        },
        methods: {
            setPageNumber,
            handleOwnerCardClick,
            goToPreviousScreen,
            AddNoticeClick
        }
    } = useNoticesModule(useAllNotices, history, isMynotices);
    let loader = isLoading || isLoadingOfficeDetail
    return (
        <Page onBack={goToPreviousScreen}>
            {loader && <Loader />}
            <div className={styles.title_btn_wrapper}>
                <div>
                    <h2 className="page-title m-0 mb-2">{officeDetail?.name}</h2>
                    <h3 className={styles.sub_title}>{t("notices.notice")}</h3>
                </div>
                    {!isMynotices ? <Button onClick={AddNoticeClick}>
                        {t("notices.addNotice")}
                    </Button> : ""}
            </div>
            <Card className={styles.notice_parent_card}>
                {notices?.length === 0 ? (<div className={styles["not_found"]}>
                    <Empty Message={t("notices.noNotice")} />
                </div>) :
                    <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                        <InfiniteScroll
                            className="task_assignee w-100"
                            dataLength={notices?.length}
                            hasMore={notices?.length < totalItems}
                            next={() => setPageNumber((v) => v + 1)}
                        >
                            <div className={styles.grid_container}>
                                {notices?.map((notice) => (
                                    <NoticesCard
                                        key={notice?.id}
                                        notice={notice}
                                        handleOwnerCardClick={handleOwnerCardClick}
                                        t={t}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    </motion.div>
                }
            </Card>
        </Page >
    )
}
export default withTranslation()(MyNotices); 