import React from 'react'
import Page from 'components/Page'
import constants from "../../../../../constants"
import Card from 'components/Card';
import Text from 'components/Text';
import styles from "../PatientMessageCenter.module.scss"
import DownloadPdfIcon from "../../../../../assets/images/document-icon.svg"

export default function ViewTemplate({ history }) {
    const goToPreviousScreen = () => {
        history.push(constants.routes.superAdmin.patientNewMessage);
    };
    return (
        <Page title="View Template" onBack={goToPreviousScreen}>
            <Card>
                <Text size="16px" weight="600" color="#587E85">Preview for Message Title/Email Subject</Text>
                <Text size="12px" weight="400" color="#6F7788">Created By</Text>
                <Text size="14px" weight="600" color="#102C42">You</Text>
                <div className={styles.view_template_small_card_wrapper}>
                    <div className={styles.view_template_msg_container}>
                        <Text size="16px" weight="600" color="#111B45">Message Title</Text>
                        <Text size="16px" weight="300" color="#535B5F">Appointment Reminder</Text>
                    </div>
                    <div className={styles.view_template_msg_container}>
                        <Text size="16px" weight="600" color="#111B45">Content for Push Notifications & SMS</Text>
                        <Text size="16px" weight="300" color="#535B5F">You have new appointment on may 05, 2023. Please fill the Covid Form.</Text>
                    </div>
                    <div className={styles.view_template_msg_container}>
                        <Text size="16px" weight="600" color="#111B45">Content for Emails</Text>
                        <Text size="16px" weight="300" color="#535B5F">Hi [Daniel Route], You have new appointment on Mar 01, 2022. Please fill the Covid Form. Also, bring the documents with you on the date of appointment.</Text>
                        <hr />
                        <div className={styles.download_btn_wrapper}>
                            <Text size="16px" weight="600" color="#111B45">Attachment</Text>
                            <div className={styles.icon_text}>
                                <img src={DownloadPdfIcon} alt='DownloadPdfIcon' className={styles.document_icon} />
                                <Text size="14px" weight="600" color="#102C42"> new_file.pdf</Text>
                            </div>
                            <button className={styles.link + " " + "link-btn"} >Download</button>
                        </div>
                    </div>
                </div>
            </Card>
        </Page>
    )
}
