import { useCallback, useEffect, useState } from "react";
import constants from "../../../../constants";
import useHandleApiError from "hooks/useHandleApiError";
import toast from "react-hot-toast";
import {
  inviteSikkaPatient,
  manageGlobalNotification,
  manageGlobalNotificationForOfficePreference,
  mannualInviteSikkaPatient,
  useGetPmsMemberList,
} from "repositories/pms-repository";
import { setStorage, getStorage } from "utils";
import { useOfficeDetail } from "repositories/office-repository";
import { debounce } from "lodash";

export default function useToGetPmsPatientsList({
  tabStatus,
  officeId,
  IsForDoctor,
  pageSize,
}) {
  const cacheValue = getStorage(constants.pmsMembers.cache.pmsPatientslisting);
  const sikkaInviteStatus = constants.SikkaUserInvitationStatus;
  const [pmsPatientsList, setPmsPatientsList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [apiSearchText, setApiSearchText] = useState("");
  const [selectAllIds, setSelectAllIds] = useState(false);
  const [notificationTooltip, setNotificationTooltip] = useState({
    globalNotification: false,
    autoInvite: false,
  });
  const [globalNotificationToggle, setGlobalNotificationToggle] =
    useState(false);
  const [globalNotificationModal, setGlobalNotificationModal] = useState(false);
  const [autoInviteToggle, setAutoInviteToggle] = useState(false);

  const [selectedPatientData, setSelectedPatientData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(cacheValue?.pageNumber || 1);
  const searchTerm = apiSearchText ? apiSearchText : "";

  const { data, isLoading, isFetching, error, refetch } = useGetPmsMemberList(
    officeId,
    pageNumber,
    pageSize,
    IsForDoctor,
    searchTerm
  );

  const { data: officeDetail, refetch: refetchOfficeDetails } =
    useOfficeDetail(officeId);

  useHandleApiError(isLoading, isFetching, error);

  const [globalNotificationStatus, setGlobalNotificationStatus] =
    useState(false);
  const [autoInviteStatus, setAutoInviteStatus] = useState(false);

  let totalItems = data?.pagination?.totalItems;

  useEffect(() => {
    setGlobalNotificationStatus(
      officeDetail?.isGlobalNotificationEnabledForNonMiraxisPatients
    );

    setAutoInviteStatus(officeDetail?.isAutoInviteEnabledForPatients);

  }, [
    officeDetail?.isGlobalNotificationEnabledForNonMiraxisPatients,
    officeDetail?.isAutoInviteEnabledForPatients,
  ]);

  useEffect(() => {
    setStorage(constants.pmsMembers.cache.pmsPatientslisting, {
      pageNumber: pageNumber,
      status: tabStatus,
    });
  }, [pageNumber, tabStatus]);

  useEffect(() => {
    if (pageNumber === 1) refetch();
  }, []);

  const handleSearchText = useCallback(
    debounce((searchTextValue) => {
      setPageNumber(1);
      setApiSearchText(searchTextValue);
    }, 1000),
    []
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
    handleSearchText(value);
  };

  useEffect(() => {
    if (data?.data) {
      let prev = data?.data;
      const updatedList =
        prev?.map((val) => {
          val.counter = 1;
          val.enableNotifications = 1;
          return val;
        }) || [];

      setPmsPatientsList(structuredClone([...updatedList]));
    } else {
      setPmsPatientsList([]);
    }
  }, [data]);

  useEffect(() => {
    //This use effect is very importent to run the column formatter on state changes.
    if (data?.data) {
      const updatedPmsPateintsList =
        pmsPatientsList?.map((val) => {
          val.counter = val.counter + 1;
          val.enableNotifications = val.enableNotifications + 1;
          return val;
        }) || [];

      setPmsPatientsList([...updatedPmsPateintsList]);
    }
  }, [selectedIds, officeDetail]);

  const refetchData = () => {
    if (!!searchTerm) {
      setApiSearchText("");
      setPageNumber(1);
    }
    refetch();
  };

  const toggleAllCheckbox = (e) => {
    let selectedData = e.target.checked ? pmsPatientsList : [];
    let ids = selectedData.map((val) => {
      if (val?.sikkaEmailId || val?.contactNumber) {
        return val?.id;
      }
    });

    setSelectedPatientData(selectedData);
    setSelectedIds([...ids]);
    setSelectAllIds(e.target.checked);
  };

  const toggleCheckbox = (e, row) => {
    const selectedValue = parseInt(e.target.value);
    if (!e.target.checked) {
      setSelectedIds((prev) => prev?.filter((it) => it !== selectedValue));
      setSelectedPatientData((prev) =>
        prev?.filter((it) => it.id !== selectedValue)
      );
    } else {
      setSelectedIds((prev) => [...prev, selectedValue]);
      setSelectedPatientData((prev) => [...prev, row]);
    }
  };

  const handleNotification = async (patientId, status) => {
    setShowLoader(true);
    try {
      let response = await manageGlobalNotification({
        SikkaDoctorPatientId: patientId,
        IsEnabledForGlobalNotifications: status,
      });

      refetch();
      toast.success(response?.message);
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setShowLoader(false);
    }
  };

  const handleGlobalNotificationForOfficePreference = async (id, notificationStatus, inviteStatus) => {
    setShowLoader(true);
    try {
      let response = await manageGlobalNotificationForOfficePreference({
        officeId: id,
        IsGlobalNotificationEnabledForNonMiraxisPatients: notificationStatus,
        IsAutoInviteEnabledForPatients: inviteStatus
      });

      refetchOfficeDetails();
      toast.success(response?.message);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setShowLoader(false);
    }
  };

  const sendInvite = async (patientEmailId) => {
    setShowLoader(true);
    try {
      const sikkaUserIds = [];

      pmsPatientsList?.map((val) => {
        if (selectedIds.includes(val?.id)) {
          if (val?.sikkaUserId) sikkaUserIds.push(val.sikkaUserId);
        }
      });

      let params = {
        PatientSikkaIds: patientEmailId ? patientEmailId : sikkaUserIds,
        OfficeId: officeId,
      };

      let response = await inviteSikkaPatient(params);

      refetch();
      toast.success(response?.message);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setShowLoader(false);
    }

    setSelectedPatientData([]);
    setSelectedIds([]);
  };

  const sendMannualInvite = async (
    patientEmailId,
    sikkaUserId,
    selectedContactNumber
  ) => {
    setShowLoader(true);
    try {
      let params = {
        MiraxisInviteEmailId: patientEmailId,
        SikkaUserId: sikkaUserId,
        OfficeId: officeId,
      };

      if (selectedContactNumber) params.ContactNumber = selectedContactNumber;

      let response = await mannualInviteSikkaPatient(params);

      refetch();
      toast.success(response?.message);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setShowLoader(false);
    }

    setSelectedPatientData([]);
    setSelectedIds([]);
  };

  return {
    patientData: {
      isLoading,
      pmsPatientsList,
      totalItems,
      pageNumber,
      sikkaInviteStatus,
      selectedIds,
      selectedPatientData,
      showLoader,
      selectAllIds,
      officeDetail,
      officeId,
      notificationTooltip,
      globalNotificationToggle,
      globalNotificationStatus,
      globalNotificationModal,
      searchText,
      autoInviteToggle,
      autoInviteStatus,
    },
    methods: {
      setPageNumber,
      toggleCheckbox,
      sendInvite,
      setSelectedIds,
      toggleAllCheckbox,
      sendMannualInvite,
      handleNotification,
      handleGlobalNotificationForOfficePreference,
      setNotificationTooltip,
      setGlobalNotificationToggle,
      setGlobalNotificationStatus,
      setGlobalNotificationModal,
      setSearchText,
      handleSearch,
      refetchData,
      setAutoInviteToggle,
      setAutoInviteStatus,
    },
  };
}
