import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Config } from "../config";

const baseUrl = Config.serviceUrls.timesheetBaseUrl;
const officeBaseUrl = Config.serviceUrls.officeBaseUrl;

export async function getTimesheet(userId, officeId, startDate, endDate) {
  const response = await axios.get(`${baseUrl}/Timesheet`, {
    params: {
      userId,
      officeId,
      startDate,
      endDate,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}

export function useTimesheet(userId, officeId, startDate, endDate) {
  return useQuery(["getTimesheet", userId, officeId, startDate, endDate], () =>
    getTimesheet(userId, officeId, startDate, endDate)
  );
}

export async function addTimesheet({
  userId,
  officeId,
  statusId,
  date,
  startDateTime,
  endDateTime,
  timeSpentInMinutes,
  breakDurationInMinutes,
  ip = null,
  IsTypeAdvance,
  AdvanceTimesheetType,
  TimesheetTasks,
  currentDate,
  note
}, isAdmin) {
  const url = isAdmin ? 'TimeSheet/ByOwnerAndAdmin' : 'Timesheet'
  const response = await axios.post(`${baseUrl}/${url}`, {
    userId,
    officeId,
    statusId,
    date,
    startTime: startDateTime,
    endTime: endDateTime,
    timeSpent: timeSpentInMinutes,
    breakTime: breakDurationInMinutes,
    ip,
    geocoordinates: "",
    IsRequestFromMobile: false,
    IsTypeAdvance,
    AdvanceTimesheetType,
    TimesheetTasks,
    currentDate,
    note
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.message;
}

export function useAddTimesheetMutation(isAdmin) {
  const queryClient = useQueryClient();
  return useMutation((addTimesheetDto) => addTimesheet(addTimesheetDto, isAdmin), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getTimesheet"]);
      queryClient.invalidateQueries(["checkRequestApproval"]);
    },
  });
}

export async function updateTimesheet({
  userId,
  officeId,
  timesheetId,
  statusId,
  date,
  startDateTime,
  endDateTime,
  timeSpentInMinutes,
  breakDurationInMinutes,
  ip = null,
  IsTypeAdvance,
  AdvanceTimesheetType,
  TimesheetTasks,
  currentDate,
  note
}, isAdmin) {
  const url = isAdmin ? `Timesheet/${timesheetId}/ByOwnerAndAdmin` : `Timesheet/${timesheetId}`
  const response = await axios.put(`${baseUrl}/${url}`, {
    userId,
    officeId,
    id: timesheetId,
    statusId,
    date,
    startTime: startDateTime,
    endTime: endDateTime,
    timeSpent: timeSpentInMinutes,
    breakTime: breakDurationInMinutes,
    ip,
    geocoordinates: "",
    IsRequestFromMobile: false,
    IsTypeAdvance,
    AdvanceTimesheetType,
    TimesheetTasks,
    currentDate,
    note
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.message;
}

export function useUpdateTimesheetMutation(isAdmin) {
  const queryClient = useQueryClient();
  return useMutation(
    (updateTimesheetDto) => updateTimesheet(updateTimesheetDto, isAdmin),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getTimesheet"]);
        queryClient.invalidateQueries(["checkRequestApproval"]);
      },
    }
  );
}

export async function getWorkTypeList(OfficeId, PageSize, PageNumber) {
  const response = await axios.get(`${baseUrl}/TimeSheet/WorkTypeList`, {
    params: {
      OfficeId,
      PageNumber,
      PageSize,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}

export async function getTaskList(OfficeId, PageSize, PageNumber) {
  const response = await axios.get(`${baseUrl}/TimeSheet/TaskList`, {
    params: {
      OfficeId,
      PageNumber,
      PageSize,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}

export async function addCustomTask({ OfficeId, title }) {
  const response = await axios.post(`${baseUrl}/TimeSheet/CreateCustomTask`, {
    OfficeId,
    title,
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.message;
}

export function useCustomTaskMutation() {
  const queryClient = useQueryClient();
  return useMutation((data) => addCustomTask(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getWorkTypeList"]);
    },
  });
}

export async function addCustomWorkType({ OfficeId, title }) {
  const response = await axios.post(`${baseUrl}/TimeSheet/CreateWorkType`, {
    OfficeId,
    title,
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.message;
}

export function useCustomWorkTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation((data) => addCustomWorkType(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getTaskList"]);
    },
  });
}

export async function getTimesheetDetail(userId, officeId, startDate, endDate, pageNo, pageSize) {
  const response = await axios.get(`${baseUrl}/Timesheet/Details`, {
    params: {
      userId,
      officeId,
      startDate,
      endDate,
      pageNo,
      pageSize
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data;
}

export function useTimesheetDetail(userId, officeId, startDate, endDate, pageNo, pageSize) {
  return useQuery(
    ["getTimesheetDetail", userId, officeId, startDate, endDate, pageNo, pageSize],
    () => getTimesheetDetail(userId, officeId, startDate, endDate, pageNo, pageSize)
  );
}

export async function getTimesheetTaskList(TimesheetId, AdvanceTimesheetType) {
  const response = await axios.get(`${baseUrl}/Timesheet/TimesheetTaskList`, {
    params: {
      TimesheetId,
      AdvanceTimesheetType,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}
export async function getAdvancedTimesheetDetails(
  TimesheetId,
  AdvanceTimesheetType
) {
  const response = await axios.get(
    `${baseUrl}/TimeSheet/AdvanceTimesheetDetails`,
    {
      params: {
        TimesheetId,
        AdvanceTimesheetType,
      },
    }
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}
export function useAdvancedTimesheetDetails(TimesheetId, AdvanceTimesheetType) {
  return useQuery(
    ["getAdvancedTimesheetDetails", TimesheetId, AdvanceTimesheetType],
    () => getAdvancedTimesheetDetails(TimesheetId, AdvanceTimesheetType)
  );
}

export async function checkRequestApproval(timesheetDate, officeId) {
  const response = await axios.get(`${baseUrl}/Timesheet/EditRequest`, {
    params: {
      timesheetDate,
      officeId,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}

export function useCheckTimesheetRequest(timesheetDate, officeId) {
  return useQuery(["checkRequestApproval", timesheetDate, officeId], () =>
    checkRequestApproval(timesheetDate, officeId)
  );
}
export async function editRequest({
  timesheetRequestDate,
  userId,
  officeId,
  reason,
}) {
  const response = await axios.post(`${baseUrl}/TimeSheet/EditRequest`, {
    timesheetRequestDate,
    userId,
    officeId,
    reason,
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.message;
}

export function useEditRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation((data) => editRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["checkRequestApproval"]);
    },
  });
}

export async function getTimesheetBreak(timesheetId) {
  const response = await axios.get(`${baseUrl}/Timesheet/Break`, {
    params: {
      timesheetId,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.data;
}

export async function startStopTimesheetBreak(
  timesheetId,
  startbreak,
  timeZoneOffset
) {
  const response = await axios.post(`${baseUrl}/TimeSheet/Break`, {
    timesheetId,
    break: startbreak,
    timeZoneOffset,
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export async function getStaffList(
  officeId,
  pageNumber,
  pageSize,
  searchTerm
) {
  const response = await axios.post(`${officeBaseUrl}/Staff`, {
    officeId,
    pageNumber,
    pageSize,
    searchTerm
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data;
}

export const useStaffList = (officeId, pageNumber, pageSize, searchTerm,
) => {
  return useQuery(
    ["timesheet-staff-listing", officeId, pageNumber, pageSize, searchTerm],
    () => getStaffList(officeId, pageNumber, pageSize, searchTerm)
  );
};

const getStaffTimesheet = async ({
  officeId,
  pageNumber,
  pageSize,
  from,
  to,
  searchTerm,
  status,
}) => {
  const response = await axios.get(
    `${baseUrl}/TimeSheet/Office?officeId=${officeId}&pageNo=${pageNumber}&pageSize=${pageSize}&fromDate=${from}&toDate=${to}&searchTerm=${searchTerm}&statusId=${status}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const useStaffTimesheet = (
  officeId,
  pageNumber,
  pageSize,
  from,
  to,
  searchTerm,
  status,
  options = {}
) => {
  return useQuery(
    [
      "staff-listing-timesheet",
      officeId,
      pageNumber,
      pageSize,
      from,
      to,
      searchTerm,
      status,
    ],
    () =>
      getStaffTimesheet({
        officeId,
        pageNumber,
        pageSize,
        from,
        to,
        searchTerm,
        status,
      }),
    options
  );
};

const getTimesheetListing = async ({
  officeId,
  userId,
  pageNumber,
  pageSize,
  from,
  to,
  status,
}) => {
  const response = await axios.get(
    `${baseUrl}/TimeSheet/Details?officeId=${officeId}&userId=${userId}&pageNo=${pageNumber}&pageSize=${pageSize}&startDate=${from}&endDate=${to}&statusId=${status}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const useTimesheetListing = (
  officeId,
  userId,
  pageNumber,
  pageSize,
  from,
  to,
  status,
  options = {}
) => {
  return useQuery(
    [
      "timesheet-listing",
      officeId,
      userId,
      pageNumber,
      pageSize,
      from,
      to,
      status,
    ],
    () =>
      getTimesheetListing({
        officeId,
        userId,
        pageNumber,
        pageSize,
        from,
        to,
        status,
      }),
    options
  );
};

export const timeSheetDataExport = async (
  officeId,
  startDate,
  endDate,
  userId,
  statusId
) => {
  const response = await axios.get(
    `${baseUrl}/TimeSheet/Export?officeId=${officeId}&startDate=${startDate}&endDate=${endDate}&userId=${userId}&statusId=${statusId}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const updateTimesheetStatus = async (params) => {
  let response = await axios.put(`${baseUrl}/TimeSheet/Office`, params);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data;
};

export const timeSheetReportExport = async (startDate,endDate, payload) => {
  const response = await axios.post(
    `${baseUrl}/TimeSheet/ExportReport?startDate=${startDate}&endDate=${endDate}`,{
      ...payload
    }
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export const updateEditTimesheetRequestStatus = async (sheetId, isApproved) => {
  let response = await axios.put(
    `${baseUrl}/TimeSheet/ApproveEditRequest?clockInOutTimeSheetEditRequestId=${sheetId}&IsRequestApproved=${isApproved}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data;
};

const getTimesheetReport = async ({
  OfficeId,
  OwnerId,
  StartDate,
  EndDate,
  SortBy
}) => {
  
  const response = await axios.post(`${baseUrl}/TimeSheet/GetTimesheetReport`, {
    OfficeId,
    OwnerId,
    StartDate,
    EndDate,
    SortBy
  });
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const useTimesheetReport = (
  OfficeId,
  OwnerId,
  StartDate,
  EndDate,
  SortBy,
  options = {}
) => {
  
  return useQuery(
    ["timesheet-report", OfficeId, OwnerId, StartDate, EndDate, SortBy],
    () =>
      getTimesheetReport({ OfficeId, OwnerId, StartDate, EndDate, SortBy }),
    options
  );
};

