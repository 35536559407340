import React, { useEffect, useState } from "react";
import Card from "components/Card";
import Page from "components/Page";
import CustomDropdown from "components/Dropdown";
import constants, {
  PERFORMANCE_REVIEW_FORM_TYPES,
} from "../../../../constants";
import Text from "components/Text";
import PerformanceReviewSearchBar from "../components/PerformanceReviewSearchBar";
import styles from "../PerformanceReview.module.scss";
import ConfirmationModal from "../modals/ConfirmationModal";
import Button from "components/Button";
import {
  sendReview,
  useAllPerformanceReviewStaff,
} from "repositories/performance-review-repository";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId } from "utils";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Loader from "components/Loader";
import Empty from "components/Empty";

function AddNewPerformanceReview({ t, history }) {
  let { officeId } = useParams();
  officeId = parseInt(decodeId(officeId));
  const [statusType, setStatusType] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [staffMembers, setStaffMembers] = useState([]);
  const [searchValue, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const { data, isLoading } = useAllPerformanceReviewStaff(officeId);

  useEffect(() => {
    if (data && data?.data.length > 0) {
      setStaffMembers(data.data);
    }
  }, [data, isLoading]);

  const isSelected = (staffId) => {
    return selectedStaff.includes(staffId);
  };

  const toggleSelectStaff = (staffId) => {
    setSelectedStaff((prevSelected) =>
      isSelected(staffId)
        ? prevSelected.filter((id) => id !== staffId)
        : [...prevSelected, staffId]
    );
  };

  const selectAll = () => {
    if (filteredStaffMembers.every((staff) => isSelected(staff.id))) {
      setSelectedStaff((prevSelected) =>
        prevSelected.filter(
          (id) => !filteredStaffMembers.some((staff) => staff.id === id)
        )
      );
    } else {
      setSelectedStaff((prevSelected) => [
        ...new Set([
          ...prevSelected,
          ...filteredStaffMembers.map((staff) => staff.id),
        ]),
      ]);
    }
  };

  const Options = PERFORMANCE_REVIEW_FORM_TYPES.map((name, id) => ({
    name: t(`performanceReview.${name}`),
    id,
  }));

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredStaffMembers = staffMembers.filter(
    (s) =>
      s.firstName.toLowerCase().includes(searchValue) ||
      s.lastName.toLowerCase().includes(searchValue)
  );

  const goBack = () => history.goBack();

  const isValid = () => {
    let errorsData = error;
    if (selectedStaff.length === 0) {
      errorsData = t("performanceReview.errorMsg");
    } else {
      errorsData = "";
    }
    setError(errorsData);
    return errorsData;
  };

  const handleSendReview = async () => {
    if (!isValid()) {
      setShowLoader(true);
      try {
        const params = {
          officeId: officeId,
          type: statusType,
          userIds: selectedStaff,
        };
        let res = await sendReview(params);
        toast.success(res.message);
        if (res.status) goBack();
      } catch (err) {
        toast.error(err.message);
        setIsDeleteModalOpen(false);
      } finally {
        setShowLoader(false);
        setIsDeleteModalOpen(false);
      }
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <Page onBack={goBack} title={t("performanceReview.addNewReview")}>
      {isLoading && <Loader />}
      <Card className={styles.add_new_performance_card}>
        <div className={styles.card_container}>
          <div className="custom-dropdown-only">
            <CustomDropdown
              options={Options}
              selectedOption={statusType}
              selectOption={(id) => {
                setStatusType(id);
              }}
            />
          </div>
          <div className={styles.searchbar_wrapper}>
            <Text size="13px" weight="400" color="#79869A">
              {t("performanceReview.selectStaff")}
            </Text>
            <div className={styles.margin16}>
              <PerformanceReviewSearchBar
                t={t}
                searchValue={searchValue}
                handleSearchTerm={handleSearchTerm}
                placeholderText={t("performanceReview.searchByStaffName")}
              />
            </div>
            <Text size="14px" weight="600" color="#102C42" marginTop="30px">
              {selectedStaff.length} {t("performanceReview.selected")}
            </Text>
            <div className={styles.demo}>
              <div className={styles.checkbox_wrapper}>
                {filteredStaffMembers.length > 0 && (
                  <div
                    className={
                      styles.checkbox_container +
                      " " +
                      "ch-checkbox c-field all-event-checkbox m-0"
                    }
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          selectedStaff.length ===
                            filteredStaffMembers.length &&
                          filteredStaffMembers.length > 0
                        }
                        onChange={selectAll}
                      />
                      <span>{t("performanceReview.selectAll")}</span>
                    </label>
                  </div>
                )}
                {filteredStaffMembers.length > 0 ? (
                  filteredStaffMembers.map((staff) => (
                    <div
                      key={staff.id}
                      className={
                        styles.checkbox_container +
                        " " +
                        "ch-checkbox c-field all-event-checkbox m-0"
                      }
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={isSelected(staff.id)}
                          onChange={() => toggleSelectStaff(staff.id)}
                        />
                        <span>{`${staff?.firstName} ${staff?.lastName}`}</span>
                      </label>
                    </div>
                  ))
                ) : (
                  <div className={styles["not_found"]}>
                    <Empty
                      Message={t("performanceReview.noStaffMemberFound")}
                    />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>

          {error && <span className="error-msg">{error}</span>}

          <div className={styles.home_btn_wrapper + " " + styles.margin40}>
            <Button
              onClick={() => {
                !isValid() && setIsDeleteModalOpen(true);
              }}
            >
              {t("performanceReview.sendReview")}
            </Button>
            <Button borderedSecondaryNew onClick={goBack}>
              <span>{t("performanceReview.cancel")}</span>
            </Button>
          </div>
        </div>
      </Card>
      {isDeleteModalOpen && (
        <ConfirmationModal
          closeDeleteModal={closeDeleteModal}
          isDeleteModalOpen={isDeleteModalOpen}
          handleSendReview={handleSendReview}
          showLoader={showLoader}
        />
      )}
    </Page>
  );
}

export default withTranslation()(AddNewPerformanceReview);
