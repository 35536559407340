import React, { useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import styles from "../WaitingList.module.scss";
import { withTranslation } from "react-i18next";

const WaitingFilter = ({
  t,
  isSidebarActive,
  handleSidebarToggle,
  statuslistdata,
  doctorsFilterData,
  statusFilter,
  doctorFilter,
  setStatusFilter,
  setDoctorsFilter,
  resetFilter,
  handleApplyFilters,
  isFilterApply,
}) => {
  const handleStatusChange = useCallback(
    (e, item) => {
      const statuses = [...statusFilter];
      const statusIndex = statuses.findIndex((v) => +item.id === +v);
      if (e.target.checked) {
        statuses.push(item.id);
      } else {
        if (statusIndex > -1) statuses.splice(statusIndex, 1);
      }
      setStatusFilter(statuses);
    },
    [setStatusFilter]
  );

  const handleDoctorChange = useCallback(
    (e, item) => {
      const doctors = [...doctorFilter];
      const doctorIndex = doctors.findIndex((v) => +item.id === +v);
      if (e.target.checked) {
        doctors.push(item.id);
      } else {
        if (doctorIndex > -1) doctors.splice(doctorIndex, 1);
      }
      setDoctorsFilter(doctors);
    },
    [setDoctorsFilter]
  );

  const handleApplyFilter = () => {
    handleApplyFilters();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleResetFliter = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleClose = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const renderStatusList = useMemo(
    () =>
      statuslistdata.map((v, key) => (
        <li key={key}>
          <div className="ch-checkbox">
            <label>
              <input
                type="checkbox"
                name="status"
                onChange={(e) => handleStatusChange(e, v)}
                checked={statusFilter?.includes(v.id)}
              />
              <span> {t(v.type)} </span>
            </label>
          </div>
        </li>
      )),
    [statuslistdata, statusFilter, t, handleStatusChange]
  );

  const renderDoctorList = useMemo(
    () =>
      doctorsFilterData?.items?.map((v, key) => (
        <li key={key}>
          <div className="ch-checkbox">
            <label>
              <input
                type="checkbox"
                onChange={(e) => handleDoctorChange(e, v)}
                checked={doctorFilter?.includes(v.id)}
              />
              <span> {v?.firstName + " " + v?.lastName}</span>
            </label>
          </div>
        </li>
      )),
    [doctorsFilterData, doctorFilter, handleDoctorChange]
  );

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleResetFliter}
    >
      <span className={styles.waiting_filter}>
        {t("accountOwner.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>
      <Accordion
        preExpanded={["a"]}
        className="filter-accordion"
        allowZeroExpanded
        allowMultipleExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>{t("waitingList.status")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className="filter-list">{renderStatusList}</ul>
          </AccordionItemPanel>
        </AccordionItem>
        {doctorsFilterData?.items?.length > 0 && (
          <AccordionItem uuid="b">
            <AccordionItemHeading>
              <AccordionItemButton>
                {t("waitingList.doctors")}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ul className="filter-list">{renderDoctorList}</ul>
            </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className="button button-round button-shadow mr-3"
          title={t("apply")}
          onClick={handleApplyFilter}
        >
          {t("apply")}
        </button>
        <button
          className="button button-round button-border button-dark reset-btn"
          title={t("reset")}
          onClick={handleResetFliter}
        >
          {t("reset")}
        </button>
        <button
          className="button button-round button-border button-dark cancel-btn"
          onClick={handleClose}
          title={t("close")}
        >
          {t("close")}
        </button>
      </div>
    </StickySidebar>
  );
};

export default withTranslation()(WaitingFilter);
