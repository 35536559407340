import React from "react";
import RequestApprovalNotificationCard from "./RequestApprovalNotificationCard";
import useRequestInvitationModule from "../hooks/RequestInvitationModule";
import Empty from "components/Empty";
import styles from "../Dashboard.module.scss";
import { withTranslation } from "react-i18next";
import CardLoader from "../components/CardLoader";
import NotAccessible from "../components/NotAccessible";
import { LoaderIcon } from "react-hot-toast";
import RequestInvitationCard from "./RequestInvitationCard";

const RequestInvitation = ({ t }) => {
  const {
    data: { isLoading, invitations, totalItems },
    methods: { setPageNumber, handleInvitationActions },
  } = useRequestInvitationModule();

  return (
    <>
      {isLoading ? (
        <LoaderIcon className={styles.loader_style} />
      ) : (
        <div
          className={`mx-auto ${
            !invitations?.length ? styles.no_invitations : ""
          }`}
        >
          {invitations?.length > 0 ? (
            invitations?.map((invitation) => {
              return (
                <RequestInvitationCard
                  invitation={invitation}
                  handleInvitationActions={handleInvitationActions}
                />
              );
            })
          ) : (
            <div className={styles["not_found"]}>
              <NotAccessible
                isNotFound={true}
                Message={t("dashBoard.invitationNotFound")}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withTranslation()(RequestInvitation);
