import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "../AddEvent.module.scss";
import userDefaultImage from "./../../../../assets/images/staff-default-rounded.png";

function SelectEmployeeModal({
  t,
  issaveEmployeeModalOpen,
  setIsSaveEmployeeModalOpen,
  allMembersList,
  officeType,
  memberIds,
  setMemberIds,
  eventId,
}) {
  const [membersList, setMembersList] = useState(allMembersList);
  const [selectedMembers, setSelectedMembers] = useState(memberIds);
  const [search, setSearch] = useState("");

  const onSearch = (val) => {
    setSearch(val);
    const allMembers = [...allMembersList];
    const filterArray = allMembers.filter((item) => {
      const fullName = `${item.firstName}${item.lastName}`.toLowerCase();
      const reversedFullName = `${item.lastName}${item.firstName}`.toLowerCase();
      const trimmedSearchValue = val.replace(/\s+/g, "").toLowerCase();
      return (
        fullName.includes(trimmedSearchValue) ||
        reversedFullName.includes(trimmedSearchValue)
      );
    });
    setMembersList(filterArray);
  };

  const checkMember = (member) => {
    const selectedMemberIds = [...selectedMembers];
    const dIndex = selectedMemberIds.findIndex((v) => v.userId === member.id);
    if (dIndex > -1) {
      selectedMemberIds.splice(dIndex, 1);
    } else {
      selectedMemberIds.push({
        id: 0,
        schedulerEventId: eventId || 0,
        userId: member.id,
        officeId: officeType,
        isFromDetail: false,
      });
    }
    setSelectedMembers([...selectedMemberIds]);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = userDefaultImage;
    ev.target.onerror = null;
  };

  const checkMemberRoleAvaliable = (array, value) => {
    return array.some(
      (e) => e.userId === value.id
    );
  };

  const toggleSelectAll = () => {
    const allSelected = membersList.every(member => 
      selectedMembers.some(selected => selected.userId === member.id)
    );
    
    if (allSelected) {
      const remainingMembers = selectedMembers.filter(selected => 
        !membersList.some(member => member.id === selected.userId)
      );
      setSelectedMembers(remainingMembers);
    } else {
      const existingUserIds = selectedMembers.map(member => member.userId);
      const newMembers = membersList
        .filter(member => !existingUserIds.includes(member.id))
        .map(member => ({
          id: 0,
          schedulerEventId: eventId || 0,
          userId: member.id,
          officeId: officeType,
          isFromDetail: false,
        }));
      
      setSelectedMembers([...selectedMembers, ...newMembers]);
    }
  };

  return (
    <>
      <Modal
        isOpen={issaveEmployeeModalOpen}
        toggle={() => setIsSaveEmployeeModalOpen(false)}
        className="modal-dialog-centered modal-lg"
        modalClassName="custom-modal"
      >
        <span
          className="close-btn"
          onClick={() => setIsSaveEmployeeModalOpen(false)}
        >
          <img src={crossIcon} alt="close" />
        </span>
        <ModalBody className={styles["add-employee-modal-dialog"]}>
          <Text size="25px" marginBottom="10px" weight="500" color="#111b45">
            <span className="modal-title-25">
              {t("accountOwner.selectEmployees")}
            </span>
          </Text>
          <div className={"search-box w-100 " + styles["search-box"]}>
            <input
              type="text"
              placeholder={t("accountOwner.searchEmployee")}
              value={search}
              onChange={(e) => onSearch(e.currentTarget.value)}
            />
            <span className="ico">
              <img
                src={require("assets/images/search-icon.svg").default}
                alt="icon"
              />
            </span>
          </div>
          <Text
            size="12px"
            marginBottom="10px"
            weight="400"
            color="rgb(111, 119, 136)"
            marginTop="30px"
          >
            {selectedMembers?.length}{" "}
            {t("Selected")}
          </Text>

          <div className="ch-checkbox">
            <label>
              <input
                type="checkbox"
                checked={
                  membersList.length > 0 &&
                  membersList.every(member => 
                    selectedMembers.some(selected => selected.userId === member.id)
                  )
                }
                onChange={toggleSelectAll}
              />
              <span> {t("staffTimesheet.selectAll")}</span>
            </label>
          </div>
          <ul className={"modal-employee-list " + styles["employee-list"]}>
            {membersList.length > 0 &&
              membersList.map((val, key) => (
                <li key={key}>
                  <div className="ch-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={checkMemberRoleAvaliable(
                          selectedMembers,
                          val
                        )}
                        onChange={() => checkMember(val)}
                      />
                      <span className="d-flex">
                        <img
                          src={val.profilePic || userDefaultImage}
                          onError={addDefaultSrc}
                          alt="profile"
                        />
                        <div>
                          {val.firstName} {val.lastName}
                          <Text size="12px" weight="400" color="#87928D">
                            {val?.designation?.name}
                          </Text>
                        </div>
                      </span>
                    </label>
                  </div>
                </li>
              ))}
            {membersList.length === 0 && (
              <li>
                <div className="ch-checkbox">
                  <label>{t("noRecordFound")}</label>
                </div>
              </li>
            )}
          </ul>

          <button
            className="button button-round button-shadow mr-sm-3 mb-3 w-sm-100"
            title={t("accountOwner.addEmployees")}
            onClick={() => {
              setMemberIds(selectedMembers);
              setIsSaveEmployeeModalOpen(false);
            }}
          >
            {t("questionnaire.select")}
          </button>
          <button
            className="button button-round button-border btn-mobile-link button-dark mb-md-3"
            onClick={() => setIsSaveEmployeeModalOpen(false)}
            title={t("cancel")}
          >
            {t("cancel")}
          </button>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTranslation()(SelectEmployeeModal);