import React from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useOfficeList } from "repositories/task-management-repository";

function PerformanceOfficeFilter({ setOfficeFilter, selectedOffice, isStaff = false, accountOwnerId = null }) {
  const { data } = useOfficeList(accountOwnerId ,{
      enabled: !isStaff
    });

    const handleChange = (item) => {
         setOfficeFilter(item);
    };

    return (
        <ul className={["filter-list"]}>
            {data?.length &&
                data?.map((item, key) => (
                    <li key={key}>
                        <div className={`ch-radio`}>
                            <label>
                                <input
                                    type="radio"
                                    onChange={() => handleChange(item)}
                                    checked={selectedOffice?.id === item?.id}
                                />{" "}
                                <span> {item.name}</span>
                            </label>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default PerformanceOfficeFilter;