import React, { useState } from "react";
import Page from "components/Page";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./TaskManagement.scss";
import styles from "./TaskManagement.module.scss";
import MyTasks from "./MyTasks";
import AdminTask from './Admin';
import constants from "../../../constants";
import { useSelector } from "react-redux";
import Text from "components/Text";
import useCardModule from "./hooks/useCardModule";
import AccountOwnerModal from "./Modals/AccountOwnerModal";
import { useAllTasks } from "repositories/task-management-repository";
import useTaskModule from "./hooks/useTaskModule";
import { getStorage, setStorage } from "utils";

const TaskManagement = ({ t, history }) => {
  const cacheActiveArchived = getStorage(constants.tasks.cache.archivedListing);
  const [isArchive, setIsArchive] = useState(cacheActiveArchived?.isArchived || false);
  const profile = useSelector((state) => state.userProfile.profile);
   const isChatFromDashBoard = getStorage("isChatFromDashBoard");
  const {
    data: {
      activeTab,
      isAdminModalOpen,
    },
    methods: {
      manageTab,
      handleToggleAccountOwner,
    }
  } = useCardModule()

  const {
    tasks,
    searchValue,
    handleSearchTerm,
    selectedOwner,
    officeFilter,
    staffFilter,
    statusFilter,
    priorityFilter,
    isLoading,
    totalItems,
    isFilterApply,
    accountOwnerId,
    sortBy,
    priority,
    status,
    ownerList,
    setStatus,
    setPriority,
    setPageNumber,
    setStatusFilter,
    setPriorityFilter,
    handleOwnerSelection,
    handleOnChange,
    handleApplyFilters,
    setOfficeFilter,
    setStaffFilter,
    resetFilter,
    handleAction,
    deleteAction,
    setSortBy,
    handleArchiveApi
  } = useTaskModule(useAllTasks, isArchive);

  const goToPreviousScreen = () => {
    if(isChatFromDashBoard) {
      history.push(constants.routes.dashboard.managerDashboard);
    } else {
      history.push("/Offices");
    }
  };

  return (
    <Page onBack={goToPreviousScreen} className={styles["task_management_main_parent_wrapper"] + " " + "scheduler-page"}>
      <div className={styles["btn_heading_wrapper"]}>
        <div>
          <h1 className={styles["heading"]}>
            {!isArchive ? t("taskManagement.ongoingTasks") : t("taskManagement.archiveTask")}
          </h1>
          {profile?.isAdmin && activeTab === 2 ? (<>
            <Text size="14px" weight="300" color="#000000" >{t("taskManagement.showTaskfor")} {selectedOwner?.firstName} {selectedOwner?.
              lastName
            } {t("taskManagement.office")}</Text>
            <button className={styles.no_border} onClick={handleToggleAccountOwner}>
              <p className="link-btn m-0">{t("taskManagement.change")}</p>
            </button>
          </>) : ""}
        </div>
        <div className={styles["btn_wrapper"]}>
          <button className="button button-round button-border button-dark w-sm-100"
            title="cancel"
            onClick={() => {
              setIsArchive(!isArchive);
              setStorage(constants.tasks.cache.archivedListing, {
                isArchived: !isArchive,
              });
            }}
          >
            {!isArchive ? t("taskManagement.archivedNotes") : t("taskManagement.onGoingTasks")}
          </button>
          {(profile?.role?.systemRole === constants.systemRoles.accountOwner || activeTab === 2) && <Link to={constants.routes.taskManagements.taskPerformance} className="button button-round button-border button-dark w-sm-100" title="cancel">
            {t("taskManagement.taskPerformance")}
          </Link>}
          {(profile?.role?.systemRole === constants.systemRoles.accountOwner || activeTab === 2) && <Link to={constants.routes.taskManagements.taskManagementAddNote} className="button button-round button-shadow w-sm-100" title="save">
            {t("taskManagement.addNewTask")}
          </Link>}
        </div>
      </div>
      <div className={styles["wrapper_card"]}>
        {(profile?.role?.systemRole === constants.systemRoles.accountOwner) ? (
          <MyTasks
            tasks={tasks}
            searchValue={searchValue}
            handleSearchTerm={handleSearchTerm}
            officeFilter={officeFilter}
            setOfficeFilter={setOfficeFilter}
            handleApplyFilters={handleApplyFilters}
            staffFilter={staffFilter}
            setStaffFilter={setStaffFilter}
            resetFilter={resetFilter}
            statusFilter={statusFilter}
            priorityFilter={priorityFilter}
            setStatusFilter={setStatusFilter}
            setPriorityFilter={setPriorityFilter}
            isLoading={isLoading}
            totalItems={totalItems}
            setPageNumber={setPageNumber}
            handleAction={handleAction}
            deleteAction={deleteAction}
            isArchive={isArchive}
            isFilterApply={isFilterApply}
            accountOwnerId={accountOwnerId}
            sortBy={sortBy}
            setSortBy={setSortBy}
            priority ={priority}
            setPriority ={setPriority}
            status={status}
            setStatus ={setStatus}
            handleArchiveApi={handleArchiveApi}
          />
        ) :
          <AdminTask
            activeTab={activeTab}
            manageTab={manageTab}
            tasks={tasks}
            searchValue={searchValue}
            handleSearchTerm={handleSearchTerm}
            officeFilter={officeFilter}
            setOfficeFilter={setOfficeFilter}
            handleApplyFilters={handleApplyFilters}
            staffFilter={staffFilter}
            setStaffFilter={setStaffFilter}
            resetFilter={resetFilter}
            statusFilter={statusFilter}
            priorityFilter={priorityFilter}
            setStatusFilter={setStatusFilter}
            setPriorityFilter={setPriorityFilter}
            isLoading={isLoading}
            totalItems={totalItems}
            setPageNumber={setPageNumber}
            handleAction={handleAction}
            deleteAction={deleteAction}
            isArchive={isArchive}
            isFilterApply={isFilterApply}
            accountOwnerId={accountOwnerId}
            sortBy={sortBy}
            setSortBy={setSortBy}
            priority ={priority}
            setPriority ={setPriority}
            status={status}
            setStatus ={setStatus}
            handleArchiveApi={handleArchiveApi}
          />}
      </div>
      {isAdminModalOpen && (
        <AccountOwnerModal
          isModalOpen={isAdminModalOpen}
          closeModal={handleToggleAccountOwner}
          handleCancel={handleToggleAccountOwner}
          handleStaffSelection={handleOwnerSelection}
          allMembersList={ownerList || []}
          selectedOwner={selectedOwner}
          handleOnChange={handleOnChange}
        />
      )}
    </Page>
  );
};
export default withTranslation()(TaskManagement);
