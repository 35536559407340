import React, { Fragment,  useState } from "react";
import Page from "components/Page";
import Card from "components/Card";
import styles from "../PerformanceReview.module.scss";
import Form from "../components/Form";
import ReviewSection from "../components/ReviewSection";
import { withTranslation } from "react-i18next";
import { REVEIWQUESTIONLIST } from "../../../../constants";

const initialValues = {
    position: '',
    beginningSalary: '',
    amountOfLastRaise: '',
    proposedIncrease: '',
    employeeName: '',
    dateOfEmployment: new Date(),
    presentSalary: '',
    lastRaiseDate: new Date(),
    proposedIncreaseDate: new Date()
}

function PreviewReviewForm({ t, history }) {

    const [formData,] = useState(initialValues);
    const goBack = () => history.goBack();

    return (
        <Page onBack={goBack}>
            <Card className={styles.performance_review_wrapper_card} radius="10px" marginTop="30px">
                <div className={styles.performance_review_wrapper}>
                    <div className={styles.performance_review_left_section}>
                        <Fragment>
                            <Form
                                t={t}
                                formData={formData}
                            />
                        </Fragment>
                    </div>
                    <div className={styles.performance_review_right_section}>
                        <ReviewSection
                            t={t}
                            questionsList={REVEIWQUESTIONLIST}
                            isView={true}
                            giveFeedback={false}
                        />
                    </div>
                </div>
            </Card>
        </Page>
    );
}

export default withTranslation()(PreviewReviewForm);
