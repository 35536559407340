import { Store } from "containers/routes";
import React, { useEffect, useState, useContext } from "react";
import { withTranslation } from "react-i18next";
import { useGetNotifications } from "repositories/notification-repository";
import { checkNotificationAccesibility } from "repositories/subscription-repository";
import { encodeId, formatDate, handleError } from "utils";
import { unionBy } from "lodash";
import { useHistory } from "react-router-dom";
import Text from "components/Text";
import constants from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getEventListDetails } from "repositories/scheduler-repository";
import NotificationCard from "./NotificationCard";
import CardLoader from "../components/CardLoader";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../Dashboard.module.scss";
import { LoaderIcon } from "react-hot-toast";
import moment from "moment-timezone";

const PAGE_SIZE = 10;

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

function DashBoardNotification({ t }) {
  const history = useHistory();
  const profile = useSelector((state) => state.userProfile.profile);

  const { setIsSubscriptionModel } = useContext(Store);
  const { setIsModelOpenForNotification } = useContext(Store);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { isLoading, data: apiData } = useGetNotifications(
    PAGE_SIZE,
    currentPage,
    true
  );
  const { data, pagination } = apiData || {};
  const { totalPages } = pagination || {};
  const { notification_list = [] } = data || {};
  const dispatch = useDispatch();

  useEffect(() => {
    setNotifications((prev) => [...unionBy(prev, notification_list, "id")]);
    //eslint-disable-next-line
  }, [apiData]);

  useEffect(() => {
    dispatch({ type: constants.NOTIFICATIONREADED });
  }, []);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const isNotificationAccessible = async (
    id,
    redirectionCode,
    officeId,
    globalNoteId,
    taskAssignmentId,
    performanceReviewId,
    employmentProofId,
    appointmentRequestId,
    dateCreated
  ) => {
    setShowLoader(true);

    try {
      let response = await checkNotificationAccesibility(officeId);
      const accessResponse = response;
      const schedulerNotification = accessResponse?.planFeature?.find(
        (val) => val.id === constants.moduleNameWithId.scheduler
      );
      const TaskNotification =
        accessResponse?.length > 0 &&
        accessResponse?.map((item) => {
          return item?.planFeature?.find(
            (val) => val.id === constants.moduleNameWithId.scheduler
          );
        });

      if (schedulerNotification?.isAvailable || TaskNotification) {
        const url = await redirectToNotification(
          dateCreated,
          id,
          redirectionCode,
          officeId,
          globalNoteId,
          taskAssignmentId,
          performanceReviewId,
          employmentProofId,
          appointmentRequestId
        );

        if (url) {
          window.open(url, "_blank");
        }
      } else {
        setIsSubscriptionModel(true);
        setIsModelOpenForNotification(true);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const redirectToNotification = async (
    dateCreated,
    id,
    redirectionCode,
    officeId,
    globalNoteId,
    taskAssignmentId,
    performanceReviewId,
    employmentProofId,
    appointmentRequestId,
  ) => {
    const {
      eventAcceptAndReject,
      RequestToJoin,
      PulishEvent,
      EventRequestToJoin,
      EventEmployee,
      eventJoinandInvite,
      globalNotes,
      globalNoteReminder,
      cancaltask,
      newComment,
      newTaskassigned,
      taskActions,
      taskdelete,
      ReviewAssigned,
      ReviewSubmitted,
      ReviewFinalized,
      ReviewCompleted,
      ReviewCancelled,
      employmentProofRequest,
      employementProofSubmitted,
      notices,
      newAppointmentRequest,
      cancelAppointmentRequest,
      busySlotDetail,
      eventDetail,
    } = constants.notificationRedirectionCode;

    let url = "";

    const schedularEventDate = moment(dateCreated).format("YYYY-MM-DD");

    if (
      redirectionCode === eventAcceptAndReject ||
      redirectionCode === RequestToJoin
    ) {
      url = constants.routes.scheduler.eventDetails.replace(
        ":eventId",
        encodeId(id)
      );
    } else if (
      redirectionCode === PulishEvent ||
      redirectionCode === EventRequestToJoin
    ) {
      url = constants.routes.scheduler.EventWorkingDetails.replace(
        ":eventId",
        encodeId(id)
      );
    } else if (
      redirectionCode === EventEmployee ||
      redirectionCode === eventJoinandInvite
    ) {
      try {
        let response = await getEventListDetails(id, schedularEventDate);
        if (response?.data?.createdById === profile.id) {
          url = constants.routes.scheduler.eventDetails.replace(
            ":eventId",
            encodeId(id)
          );
        } else {
          url = constants.routes.scheduler.eventRequestDetails.replace(
            ":eventId",
            encodeId(id)
          );
        }
      } catch (error) {
        console.log(error);
        return "";
      }
    } else if (redirectionCode === globalNotes) {
      url = constants.routes.notes;
    } else if (redirectionCode === globalNoteReminder) {
      url = constants.routes.noteDetails.replace(
        ":noteId",
        encodeId(globalNoteId)
      );
    } else if (
      redirectionCode === cancaltask ||
      redirectionCode === newTaskassigned ||
      redirectionCode === taskActions ||
      redirectionCode === taskdelete
    ) {
      url = constants.routes.taskManagements.taskManagement;
    } else if (redirectionCode === newComment) {
      url = constants.routes.taskManagements.taskDetails.replace(
        ":taskId",
        encodeId(taskAssignmentId)
      );
    } else if (redirectionCode === ReviewAssigned) {
      const basicUrl = constants.routes.accountOwner.performanceReviewForm.replace(
        ":officeId",
        encodeId(officeId)
      );
      const queryParams = `reviewId=${encodeId(performanceReviewId)}&isMyPerformanceReview=${encodeId(true)}&isGiveFeedback=${encodeId(false)}`;
      url = `${basicUrl}?${queryParams}`;
    } else if (
      redirectionCode === ReviewSubmitted ||
      redirectionCode === ReviewFinalized ||
      redirectionCode === ReviewCompleted
    ) {
      const basicUrl = constants.routes.accountOwner.performanceReviewForm.replace(
        ":officeId",
        encodeId(officeId)
      );
      const queryParams = `reviewId=${encodeId(performanceReviewId)}&isMyPerformanceReview=${encodeId(false)}&isGiveFeedback=${encodeId(false)}`;
      url = `${basicUrl}?${queryParams}`;
    } else if (redirectionCode === ReviewCancelled) {
      url = constants.routes.accountOwner.myPerformanceReview.replace(
        ":officeId",
        encodeId(officeId)
      );
    } else if (redirectionCode === employmentProofRequest) {
      url = constants.routes.accountOwner.employementProof.replace(
        ":officeId",
        encodeId(officeId)
      );
    } else if (redirectionCode === employementProofSubmitted) {
      const basicUrl = constants.routes.accountOwner.employmentProofFormReview;
      const queryParams = `isViewMode=${encodeId(true)}&proofId=${encodeId(employmentProofId)}&officeId=${encodeId(officeId)}`;
      url = `${basicUrl}?${queryParams}`;
    } else if (redirectionCode === notices) {
      url = constants.routes.accountOwner.myNotices.replace(
        ":officeId",
        encodeId(officeId)
      );
    } else if (
      redirectionCode === newAppointmentRequest ||
      redirectionCode === cancelAppointmentRequest
    ) {
      url = constants.routes.waitingAppoimentDetail
        .replace(":officeId", encodeId(officeId))
        .replace(":requestId", encodeId(appointmentRequestId));
    } else if (redirectionCode === busySlotDetail) {
      url = constants.routes.scheduler.busySlotDetail
        .replace(":busySlotId", encodeId(id))
        .replace(":requestId", encodeId(appointmentRequestId));
    } else if (redirectionCode === eventDetail) {
      url = constants.routes.scheduler.eventDetails.replace(
        ":eventId",
        encodeId(id)
      );
    } else {
      url = constants.routes.scheduler.eventDetailsOnly.replace(
        ":eventId",
        encodeId(id)
      );
    }

    return url;
  };

  return (
    <div
      className={`${styles.request_and_approval_notification_container} ${
        isLoading ? styles.request_and_approval_loading : ""
      }`}
    >
      {(isLoading && currentPage === 1) || showLoader ? (
        <LoaderIcon className={styles.loader_style} />
      ) : (
        <>
          {!notifications.length ? (
            <div className="empty-block">
              <img
                src={require("assets/images/notification_bell.svg").default}
                alt="icon"
              />
              <Text size="20px" weight="500" color="#111B45" marginTop="6px">
                {t("accountOwner.NoNotificationsFound")}
              </Text>
            </div>
          ) : (
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
              className={styles.height_404 + " " + "w-100"}
            >
              <InfiniteScroll
                dataLength={notifications?.length}
                hasMore={currentPage < totalPages}
                next={() => {
                  if (!isLoading) setCurrentPage((prev) => prev + 1);
                }}
                loader={<LoaderIcon className={styles.loader_style} />}
                scrollableTarget="scrollableNotifications"
                className={styles.notificard_card_wrapper_col}
              >
                {notifications.map((n) => {
                  const {
                    id,
                    dateCreated,
                    title,
                    schedulerEventId,
                    redirectionCode,
                    officeId,
                    globalNoteId,
                    taskAssignmentId,
                    performanceReviewId,
                    employmentProofId,
                    appointmentRequestId,
                  } = n;
                  const formatedDate = formatDate(dateCreated);

                  return (
                    <NotificationCard
                      key={id}
                      title={title}
                      formatedDate={formatedDate}
                      onClick={() =>
                        isNotificationAccessible(
                          schedulerEventId,
                          redirectionCode,
                          officeId,
                          globalNoteId,
                          taskAssignmentId,
                          performanceReviewId,
                          employmentProofId,
                          appointmentRequestId,
                          dateCreated
                        )
                      }
                    />
                  );
                })}
              </InfiniteScroll>
            </motion.div>
          )}
        </>
      )}
    </div>
  );
}

export default withTranslation()(DashBoardNotification);
