import React, { Fragment } from 'react'
import Page from 'components/Page'
import constants from "../../../../constants"
import Card from 'components/Card'
import styles from "../WaitingList/WaitingList.module.scss"
import userDefaultImage from "./../../../../assets/images/staff-default.svg";
import Text from 'components/Text'
import AppointmentRequest from './AppointmentRequest'
import AppointmentRequestDate from './AppointmentRequestDate'
import { useAppointmentRequestDetail } from 'repositories/patient-appointment-repository'
import Loader from 'components/Loader'
import { decodeId } from 'utils'
import { useOfficeDetail } from 'repositories/office-repository'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { withTranslation } from 'react-i18next'
import CancelRequestModal from '../modal/CancelRequestModal'
import DeleteRequestModal from '../modal/DeleteRequestModal'
import { useWaitingRequestModal } from '../hooks/useRequestModal'

function WaitingAppointmentDetail({ t, history }) {

    let { officeId, requestId } = useParams();
    const decodeRequestId = parseInt(decodeId(requestId));
    const decodeOfficeId = parseInt(decodeId(officeId));
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);

    const { data: appointmentRequestData, isLoading } = useAppointmentRequestDetail(decodeRequestId);

    const {
        data: {
            cancelAppointmentModal,
            cancelReason,
            errorReasonMsg,
            cancelRequsetLoading,
            deleteRequsetLoading,
            deleteAppointmModal
        },
        methods: {
            closeCancelModal,
            handleCancelRason,
            handleCancelRequest,
            setCancelAppointmentMoal,
            setDeleteAppointment,
            closeDeleteModal,
            handleDeleteRequest
        }
    } = useWaitingRequestModal(t);

    const goBack = () => history.push(constants.routes.waitingList.replace(':officeId', officeId).replace(':requestId', requestId));

    const { bookedByUser, bookedForFamilyMember } = appointmentRequestData || {};
    const bookedUserDetail = bookedForFamilyMember ? bookedForFamilyMember : bookedByUser;

    const appointmentsRequest = [
        {
            title: t('waitingList.patientAndAppointmentDetail'),
            isStatus: true,
            details: [
                { label: t('waitingList.patientName'), value: bookedUserDetail?.firstName + ' ' + bookedUserDetail?.lastName },
                { label: t('waitingList.patientEmailAddress'), value: bookedUserDetail?.email || bookedUserDetail?.emailId },
                { label: t('waitingList.doctor'), value: appointmentRequestData?.doctor?.firstName + ' ' + appointmentRequestData?.doctor?.lastName },
                { label: t('waitingList.clinicName'), value: appointmentRequestData?.office?.name },
            ]
        }
    ];

    return (
        <Page title={officeDetail?.name} onBack={goBack}>
            <Text size="14px" weight="300" color="#000000" className='mb-2'>
                {t("waitingList.patientAppointmentDetails")}
            </Text>
            {(isLoading || isLoadingOfficeDetail) && <Loader />}
            <Card className={styles.details_card}>
                <div className={styles.waiting_list_details_left_section}>
                    <img src={bookedUserDetail?.profilePic || bookedUserDetail?.image || userDefaultImage} alt='' className={styles.patient_image} />
                    <Text size="18px" weight="600" color="#587E85" marginTop="12px" marginBottom="30px" paddingLeft="18px">{bookedUserDetail?.firstName + ' ' + bookedUserDetail?.lastName}</Text>
                    {appointmentRequestData?.appointmentRequestStatus === 1 && <div className={styles.btn_container}>
                        <button className={"button button-round button-shadow text-nowrap w-100"} onClick={() => history.push({ pathname: constants.routes.appointment.createAppointment.replace(':requestId', requestId).replace(':officeId', officeId), state: { fromWaitingDetail: true } })}>{t('waitingList.createAppointment')}</button>
                        <button className={"button button-round button-border button-dark reset-btn btn-mobile-link w-100 "} onClick={() => setCancelAppointmentMoal(appointmentRequestData?.id)}>{t('waitingList.cancelRequest')}</button>
                    </div>}
                    {appointmentRequestData?.appointmentRequestStatus !== 1 && <button className={"button button-round button-border button-dark reset-btn w-100"} onClick={() => setDeleteAppointment(appointmentRequestData?.id)}>{t('patientAppointmentMembers.delete')}</button>}
                </div>
                <div className='d-flex flex-column w-100'>
                    <div className={styles.waiting_list_details_right_section}>
                        {!isLoading && appointmentsRequest?.map((appointment, index) => (
                            <Fragment key={index}>
                                <AppointmentRequest
                                    t={t}
                                    appointment={appointment}
                                    status={appointmentRequestData?.appointmentRequestStatus}
                                />
                                {index < appointmentsRequest.length - 1 && <hr />}
                            </Fragment>
                        ))}
                        <hr />
                        <AppointmentRequestDate t={t} appointmentRequestData={appointmentRequestData} />
                        <hr />
                        <Text size="16px" weight="600" color="#587E85">{t('waitingList.reason')}</Text>
                        <Text size="16px" weight="300" color="#535B5F" wordBreak="break-word">{appointmentRequestData?.description}</Text>
                    </div>
                    {appointmentRequestData?.bookedForFamilyMember && (
                        <div className='mt-4'>
                            <div className={styles.details_ui}>
                                <Text size="14px" weight="400" color="#102C42">{t("patientAppointmentMembers.faimlyMemberMsg")}</Text>
                            </div>
                            <div className={`${styles.waiting_list_details_right_section}`}>

                                <Text size="16px" weight="600" color="#587E85" marginTop="20px">{t('waitingList.name')}</Text>
                                <Text size="16px" weight="300" color="#535B5F" wordBreak="break-word">{bookedByUser?.firstName + ' ' + bookedByUser?.lastName}</Text>
                                <Text size="16px" weight="600" color="#587E85" marginTop="20px">{t('waitingList.email')}</Text>
                                <Text size="16px" weight="300" color="#535B5F" wordBreak="break-word">{bookedByUser?.emailId}</Text>
                                <Text size="16px" weight="600" color="#587E85" marginTop="20px">{t('waitingList.contact')}</Text>
                                <Text size="16px" weight="300" color="#535B5F" wordBreak="break-word">{bookedByUser?.contactNumber}</Text>
                            </div>
                        </div>
                    )}
                </div>
            </Card>

            {cancelAppointmentModal && (
                <CancelRequestModal
                    t={t}
                    showLoader={cancelRequsetLoading}
                    openModal={!!cancelAppointmentModal}
                    closeModal={closeCancelModal}
                    isCancel={closeCancelModal}
                    title={t('patientAppointmentMembers.cancelRequestTitle')}
                    inputLabel={t('patientAppointmentMembers.cancelRequestLabel')}
                    handleChange={handleCancelRason}
                    reason={cancelReason}
                    submitReason={handleCancelRequest}
                    errorMsg={errorReasonMsg}
                />
            )}
            {deleteAppointmModal &&
                <DeleteRequestModal t={t} showLoader={deleteRequsetLoading} isModalOpen={deleteAppointmModal} closeModal={closeDeleteModal} deleteRequest={handleDeleteRequest} title={t('patientAppointmentMembers.deleteRequestTitle')} />
            }
        </Page >
    )
}

export default withTranslation()(WaitingAppointmentDetail);