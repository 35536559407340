import React from 'react';
import "./SchedulerFilters.scss";

function AgendaFilter({ setAgendaTypes, agendaTypes, FilterEventsData }) {

    const handleChange = (e, item) => {
        const agendas = [...agendaTypes];
        
        const oIndex = agendas.findIndex(v => item.type === v);
        if (e.target.checked) {
            agendas.push(item.type);
        } else {
            if (oIndex > -1) agendas.splice(oIndex, 1);
        }
        setAgendaTypes(agendas);
    }

    return (
        <ul className={["filter-list"]}>
            {
                FilterEventsData.map((item, key) =>
                    <li key={key}>
                        <div className="ch-checkbox">
                            <label>
                                <input type="checkbox" checked={agendaTypes?.includes(item.type)} onChange={(e) => handleChange(e, item)} /> <span> {item.title}</span>
                            </label>
                        </div>
                    </li>
                )
            }

        </ul>
    );
}

export default AgendaFilter;