import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Config } from "../config";

const baseUrl = Config.serviceUrls.patientSchedulingBaseUrl;
// https://patient-scheduling-dev.miraxisonline.com/api/v1

// https://patient-scheduling.miraxisonline.com/api/v1/Appointment/OfficeAppointments?PageNumber=1&PageSize=10&OfficeId=5472&PatientId=5805


export async function getOfficesForPatientChat(
  pageNumber, pageSize, OwnerId
) {
  const response = await axios.get(`${baseUrl}/Doctor/DoctorOfficesUnderOwner`, {
    params: {
      pageNumber,
      pageSize,
      OwnerId,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return {
    data: response.data.data,
    pagination: response.data.pagination,
  };
}

export function useOfficesPatientChat(
  pageNumber,
  pageSize,
  OwnerId,
  options = {}
) {
  return useQuery(
    ["/Doctor/DoctorOfficesUnderOwner", pageNumber, pageSize, OwnerId],
    () => getOfficesForPatientChat(pageNumber, pageSize, OwnerId),
    options
  );
}



export async function getAllPatientAppointments(PageNumber, PageSize, OfficeId, PatientId) {
  const { error, data } = await axios.get(`${baseUrl}/Appointment/OfficeAppointments`, {
    params: {
      PageNumber, PageSize, OfficeId, PatientId
    }
  });
  if (error) {
    throw new Error(error?.message);
  }
  return data;
}


export function useGetAllPatientAppointments(PageNumber, PageSize, OfficeId, PatientId) {
  return useQuery(["/Appointment/OfficeAppointments", PageNumber, PageSize, OfficeId, PatientId], () =>
    getAllPatientAppointments(PageNumber, PageSize, OfficeId, PatientId)
  );
}

export async function getPatients(pageNumber, pageSize, searchTerm) {
  const response = await axios.get(`${baseUrl}/Patient/Patients`, {
    params: {
      pageNumber,
      pageSize,
      searchTerm,
    },
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return {
    items: response.data.data,
    totalCount: response.data.pagination.totalItems,
  };
}




export async function getConfirmedPatientAppoinments(params) {
  const { error, data } = await axios.post(`${baseUrl}/Patient/AppointmentWithDoctor`, params);
  if (error) {
    throw new Error(error?.message);
  }
  return data;
}

export function usePatients(pageNumber, pageSize, searchTerm) {
  return useQuery(["/Patient/Patients", pageNumber, pageSize, searchTerm], () =>
    getPatients(pageNumber, pageSize, searchTerm)
  );
}

export function useAppointedPatients() {
  const queryClient = useQueryClient();
  /*
    params={
      searchTerm,
      pageNumber,
      pageSize,
      ownerId
    } 
*/
  return useMutation(
    (params) => getConfirmedPatientAppoinments(params),
    { onSuccess: () => queryClient.invalidateQueries(["/Patient/AppointmentWithDoctor"]) }
  );

}

export async function activatePatient(patientId) {
  const response = await axios.put(
    `${baseUrl}/Patient/Activate`,
    {},
    {
      params: {
        patientId,
      },
    }
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.message;
}

export async function deactivatePatient(patientId) {
  const response = await axios.put(
    `${baseUrl}/Patient/Deactivate`,
    {},
    {
      params: {
        patientId,
      },
    }
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data.message;
}

export function usePatientStatusUpdateMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ patientId, newStatus }) => {
      if (newStatus) {
        return activatePatient(patientId);
      } else {
        return deactivatePatient(patientId);
      }
    },
    { onSuccess: () => queryClient.invalidateQueries(["/Patient/Patients"]) }
  );
}

export const listYourPracticeEmail = async (params) => {
  let response = await axios.post(
    `${baseUrl.replace("/api/v1", "/Common/ListYourPracticeEmail")}`,
    null,
    { params: params }
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};
