import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card } from "reactstrap";
import styles from "../../../Appointments.module.scss";
import Text from "components/Text";
import starImage from "../../../../../../assets/images/star-rating.svg";
import { formatUTCDateTime } from "utils";
import FeedBackModal from "accountOwner/pages/Appointments/modal/FeedBackModal";

function PendingCard({
  t,
  appointmentDetail,
  getStatus,
  handleRedirectAppointmentReAssign,
  handleRedirectAppointmentReSchedule,
  getStatusClass,
  addReviewComment,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Card className={styles["patient-appointment-detail-right-card"]}>
      <div className={styles["patient-appointment-detail-right-card-data"]}>
        <div
          className={styles["patient-appointment-detail-right-card-heading "]}
        >
          <Text size="12px" weight="400" color="#6F7788" marginBottom="5px">
            {t("patientAppointmentMembers.dateOfAppointment")}
          </Text>
          <Text size="14px" weight="600" color="#102C42">
            {formatUTCDateTime(appointmentDetail?.appointmentDate)}
          </Text>
        </div>
        <div
          className={styles["patient-appointment-detail-right-card-heading"]}
        >
          <Text size="12px" weight="400" color="#6F7788" marginBottom="5px">
            {t("patientAppointmentMembers.appointmentFor")}
          </Text>
          <Text
            size="14px"
            weight="600"
            color="#102C42"
          >{`${appointmentDetail?.doctor?.firstName} ${appointmentDetail?.doctor?.lastName}`}</Text>
        </div>
        <div
          className={styles["patient-appointment-detail-right-card-heading"]}
        >
          <Button
            className={`appointment-table-custom-btn ${getStatusClass(
              appointmentDetail?.status
            )}`}
          >
            {getStatus(appointmentDetail?.status)}
          </Button>
        </div>
        {appointmentDetail?.feedback && (
          <div>
            <Text size="12px" weight="400" color="#6F7788">
              {t("Patient’s Feedback")}
            </Text>
            <div>
              <img src={starImage} alt="starImage" />
            </div>
            <button
              className={styles["appointment-link"]}
              onClick={() => setModalOpen(!modalOpen)}
            >
              {t("patientAppointmentMembers.viewFeedback")}
            </button>
          </div>
        )}
      </div>
      {(appointmentDetail?.status == 1 ||
        appointmentDetail?.status == 2 ||
        appointmentDetail?.status == 7) && (
        <div className={styles["btn-box"]}>
          <button
            className="button button-round button-border button-dark"
            onClick={() =>
              handleRedirectAppointmentReSchedule(appointmentDetail)
            }
          >
            {t("patientAppointmentMembers.reschedule")}
          </button>
          <div
            className={
              styles[
                "patient-appointment-detail-right-card-data-reschedule-reassign-btn"
              ]
            }
            onClick={() => handleRedirectAppointmentReAssign(appointmentDetail)}
          >
            <button className="button button-round button-border button-dark w-sm-100">
              {t("patientAppointmentMembers.reassign")}
            </button>
          </div>
        </div>
      )}
      {modalOpen && (
        <FeedBackModal
          t={t}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          appointmentDetail={appointmentDetail}
          addReviewComment={addReviewComment}
        />
      )}
    </Card>
  );
}
export default withTranslation()(PendingCard);
