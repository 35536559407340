import React, { createContext } from "react";
import { Switch } from "react-router";
import constants from "../../../constants";
import WaitingList from "./WaitingList/WaitingList";
import WaitingAppointmentDetails from "./components/WaitingAppointmentDetails";
import CreateAppointment from "./CreateAppointment";
import TypeOfAppointment from "./components/TypeOfAppointment";
import PatientAppointmentReminder from "./AppointmentsTable/AddNewAppointment/PatientAppointmentDetails/PatientAppointmentReminder";
import ManageMessageTemplates from "pages/AccountPreferences/components/ManageMessageTemplate";
import PrivateRoute from "containers/privateRoute";
import { withTranslation } from "react-i18next";
import useReminderModule from "./hooks/useReminderModule";
import AddAppointmentReminderPlan from "./AppointmentsTable/AddNewAppointment/PatientAppointmentDetails/PatientAppointmentReminder/AddAppointmentReminderPlan";
import PatientAppointmentDetails from "./AppointmentsTable/AddNewAppointment/PatientAppointmentDetails";
import FeedbackConfirmation from "./AppointmentsTable/FeedbackConfirmation";
import PatientNewAppointment from "./PatientNewAppointment/index";
import UrbanDental from "./ManageSchedule/UrbanDental";
import RecallAppointment from "./RecallTab/ReacllNewAppointment/RecallAppointment";
import NextRecallTime from "./RecallTab/ReacllNewAppointment/NextRecallTime/NextRecallTime";
import RecallTime from "./RecallTab/ReacllNewAppointment/NextRecallTime/RecallTime";
import ReminderPlan from "./RecallTab/ReacllNewAppointment/NextRecallTime/ReminderPlan";
import RecallReminderEdit from "./RecallTab/ReacllNewAppointment/NextRecallTime/RecallReminderEdit";

export const AppointmentsContext = createContext();

function AppointmentRoutes({ t }) {

    const {
        data: {
            templateLoading,
            reminderSets,
            reminderNames,
            deleteReminderKeys,
            templateOptions,
            options,
            history,
            errorMsg,
            templateLists,
            selectedReminderPlan,
            patientMsg,
            selectedAppointmentType,
            selectedSlotDate,
            appointmentSlots,
            reminderPlan,
            patientRadioCheck,
            nextRecallRadioCheck,
            repeatedType,
            reoccuringcheck,
            dates,
            utcDates,
            message,
            selectedOffice,
            createDescription,
            selectedPatient,
            email,
            selectedNextRecall,
            selectedOperatory,
            appointmentAccepted,
            ownerId
        },
        methods: {
            setReminderSets,
            handleOnReminderNameChange,
            handleOnChange,
            handleAddReminderClick,
            setDeleteReminderKeys,
            deleteReminderItem,
            validateForm,
            setSelectReminderPlan,
            setPatientMsg,
            setSelectAppointmentType,
            setSlotDate,
            setAppointmentSlots,
            setReminderPlan,
            handleChangePatientRadioCheck,
            handleChangeNextRecallRadioCheck,
            handleSelectRecallDropDown,
            handleReoccuringcheck,
            updateDatesValues,
            handlechangeMessageInput,
            setReminderNames,
            setSelectedOffice,
            setCreateDescription,
            setSelectedPatient,
            setEmail,
            setSelectedNextRecall,
            setselectedOperatory,
            setappointmentAccepted
        } = {}
    } = useReminderModule(t) || {};



    const contextValue = {
        templateLoading,
        reminderSets,
        templateOptions,
        reminderNames,
        options,
        setReminderSets,
        handleOnChange,
        handleAddReminderClick,
        handleOnReminderNameChange,
        history,
        deleteReminderKeys,
        setDeleteReminderKeys,
        deleteReminderItem,
        errorMsg,
        validateForm,
        templateLists,
        selectedReminderPlan,
        setSelectReminderPlan,
        patientMsg,
        selectedAppointmentType,
        selectedSlotDate,
        appointmentSlots,
        setPatientMsg,
        setSelectAppointmentType,
        setSlotDate,
        setAppointmentSlots,
        reminderPlan,
        setReminderPlan,
        patientRadioCheck,
        nextRecallRadioCheck,
        handleChangePatientRadioCheck,
        handleChangeNextRecallRadioCheck,
        repeatedType,
        handleSelectRecallDropDown,
        reoccuringcheck,
        handleReoccuringcheck,
        dates,
        utcDates,
        updateDatesValues,
        message,
        handlechangeMessageInput,
        setReminderNames,
        setSelectedOffice,
        selectedOffice,
        setCreateDescription,
        createDescription,
        selectedPatient,
        setSelectedPatient,
        email,
        setEmail,
        selectedNextRecall,
        setSelectedNextRecall,
        setselectedOperatory,
        selectedOperatory,
        setappointmentAccepted,
        appointmentAccepted,
        ownerId
    };

    return (
        <AppointmentsContext.Provider value={contextValue}>
            <Switch>
                <PrivateRoute
                    component={WaitingList}
                    path={constants.routes.waitingList}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]}
                />
                <PrivateRoute
                    component={WaitingAppointmentDetails}
                    path={constants.routes.waitingAppoimentDetail}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff,]}
                />
                <PrivateRoute component={CreateAppointment}
                    path={constants.routes.appointment.createAppointment}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} />

                <PrivateRoute
                    component={TypeOfAppointment}
                    path={constants.routes.appointment.appointmentType}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]}
                />
                <PrivateRoute
                    component={PatientAppointmentReminder}
                    path={constants.routes.appointment.appointmentReminder}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff,]}
                />
                <PrivateRoute
                    component={AddAppointmentReminderPlan}
                    path={constants.routes.appointment.addAppointmentReminderPlan}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff,]}
                />
                <PrivateRoute
                    component={ManageMessageTemplates}
                    path={[constants.routes.appointment.createMessageTemplates]}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]}
                />

                <PrivateRoute
                    component={PatientAppointmentDetails}
                    path={constants.routes.appointment.acceptPatientAppointment}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]}
                />
                <PrivateRoute 
                  component={FeedbackConfirmation}
                   path={constants.routes.appointment.feedbackConfirmation}
                   roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]}
                />
                <PrivateRoute
                component={PatientNewAppointment}
                path={constants.routes.appointment.patientNewAppointment}
                roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />
                <PrivateRoute
                component={UrbanDental}
                path={constants.routes.appointment.officeSelection}
                roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />
                <PrivateRoute
                  component={RecallAppointment}
                  path={constants.routes.appointment.createRecallPatientAppointment} 
                  roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />
                <PrivateRoute
                  component={NextRecallTime}
                  path={constants.routes.appointment.linkToNextRecall} 
                  roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />

                <PrivateRoute
                  component={RecallTime}
                  path={constants.routes.appointment.recall} 
                  roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />

                <PrivateRoute
                  component={ReminderPlan}
                  path={constants.routes.appointment.reminder} 
                  roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />

                <PrivateRoute
                  component={RecallReminderEdit}
                  path={constants.routes.appointment.recallReminderEdit} 
                  roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />

               <PrivateRoute
                  component={ReminderPlan}
                  path={[constants.routes.appointment.globalReminder,constants.routes.appointment.AccountGlobalReminder, constants.routes.appointment.AccountGlobalOfficeReminder]} 
                  roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]} 
                />

              <PrivateRoute
                    component={PatientAppointmentReminder}
                    path={constants.routes.appointment.patientAppointmentReminder}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff,]}
                />

             <PrivateRoute
                    component={AddAppointmentReminderPlan}
                    path={constants.routes.appointment.patientAddAppointmentReminderPlan}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff,]}
                />

            <PrivateRoute
                    component={ManageMessageTemplates}
                    path={[constants.routes.appointment.createMessageHistoryTemplates]}
                    roles={[constants.systemRoles.accountOwner, constants.systemRoles.staff]}
                   />
            </Switch>
        </AppointmentsContext.Provider>
    );
}

export default withTranslation()(AppointmentRoutes);
