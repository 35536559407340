import React, { useState } from "react";
import Table from "components/table";
import constants from "../../../../../constants";
import { withTranslation } from "react-i18next";
import styles from "../PatientMessageCenter.module.scss";
import Loader from "components/Loader";
import { formatUTCDateTime } from "utils";
import Empty from "components/Empty";

const PatientMessageCenterTable = ({
  t,
  loading,
  totalItems,
  pageNumber,
  setPageNumber,
  pageSize,
  patientMessageHistory,
  officeDetail,
  handleRedirectViewMessage,
}) => {
  const columns = [
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.patientName"
        ),
      },
      dataField: "patientName",
      text: t("patientAppointmentMembers.appointmentTableHeading.patientName"),
      formatter: (cellContent, row, rowIndex) => (
        <span
          className={styles.name}
          onClick={() => handleRedirectViewMessage(row)}
        >
          {row?.patientName}{" "}
        </span>
      ),
    },

    {
      attrs: { datatitle: "Doctor Name" },
      dataField: "office",
      text: "Clinic Name",
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {officeDetail?.name}
        </span>
      ),
    },

    {
      attrs: { datatitle: "Doctor Name" },
      dataField: "office",
      text: "Template Name",
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.templateName || row?.notificationTitle}
        </span>
      ),
    },

    {
      attrs: { datatitle: "Date of Message" },
      dataField: "appointmentTime",
      text: "Date of Message",
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {formatUTCDateTime(row?.sentAt)}
        </span>
      ),
    },

    {
      attrs: { datatitle: "Mode of Message" },
      dataField: "appointmentType",
      text: "Mode of Message",
      formatter: (cellContent, row, rowIndex) => {
        const notificationTypes = [
          row?.isEmail ? "Email" : "",
          row?.isSms ? "SMS" : "",
          row?.isPushNotification ? "Notifications" : "",
        ]
          .filter(Boolean)
          .join(", ");
        return (
          <span className={styles["patient-appointment-table-common-columns"]}>
            {notificationTypes}
          </span>
        );
      },
    },
  ];

  return (
    <div
      className={`test-table-box ${
        patientMessageHistory?.length === 0 ? "center_property" : ""
      }`}
    >
      {loading && <Loader />}
      {patientMessageHistory?.length === 0 ? (
        <div className={styles["not_found"]}>
          <Empty Message={t("patientAppointmentMembers.noMessageHistory")} />
        </div>
      ) : (
        <Table
          keyField="id"
          className="change-heading"
          data={patientMessageHistory}
          columns={columns}
          bordered={false}
          handlePagination={setPageNumber}
          pageNumber={pageNumber}
          totalItems={totalItems}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};
export default withTranslation()(PatientMessageCenterTable);
