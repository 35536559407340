import axios from "axios";
import { useQuery } from "react-query";
import { Config } from "./../config";

const baseUrl = Config.serviceUrls.patientSchedulingBaseUrl;
const officeBaseUrl = Config.serviceUrls.officeBaseUrl;

export async function getUpCommingAppointments(params) {
    const response = await axios.post(`${baseUrl}/Appointment/Upcoming`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllUpCommingAppointments(params, options = {}) {
    return useQuery(
        ["/Appointment/Upcoming", params],
        () => getUpCommingAppointments(params),
        options
    );
}

export async function getRecallListAppointments(params) {
    const response = await axios.post(`${baseUrl}/Appointment/RecallList`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllRecallListAppointments(params, options = {}) {
    return useQuery(
        ["/Appointment/RecallList", params],
        () => getRecallListAppointments(params),
        options
    );
}

export async function getPastAppointments(params) {
    const response = await axios.post(`${baseUrl}/Appointment/Past`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAllPastAppointments(params, options = {}) {
    return useQuery(
        ["/Appointment/Past", params],
        () => getPastAppointments(params),
        options
    );
}

export async function getWaitingList(params) {
    const response = await axios.post(`${baseUrl}/AppointmentRequest/WaitingList`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useWaitingList(params, options = {}) {
    return useQuery(
        ["/appointment/waiting", params],
        () => getWaitingList(params),
        options
    );
}

export async function getDoctorsForFilter(params) {
    const response = await axios.get(`${baseUrl}/Receptionist/DoctorsForFilter`, {
        params: {
            ...params
        }
    })

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useDoctorsForFilter(params, options = {}) {
    return useQuery(
        ["/appointment/doctor-for-filter", params],
        () => getDoctorsForFilter(params),
        options
    );
}

export async function getAppointmentRequestDetail(requestId) {
    const response = await axios.get(`${baseUrl}/AppointmentRequest?AppointmentRequestId=${requestId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useAppointmentRequestDetail(requestId) {
    return useQuery(
        ["/appointment/request-detail", requestId],
        () => getAppointmentRequestDetail(requestId));
}


export async function getAppointmentDetail(appointmentId) {
    const response = await axios.get(`${baseUrl}/Appointment?appointmentId=${appointmentId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useAppointmentDetail(appointmentId) {
    return useQuery(
        ["/Appointment?appointmentId", appointmentId],
        () => getAppointmentDetail(appointmentId));
}

export async function getDefultAppointmentReminder(officeId, patientId, IsForRecall) {
    const response = await axios.get(`${baseUrl}/AppointmentReminder/Default?officeId=${officeId}&patientId=${patientId}&IsForRecall=${IsForRecall}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useDefultAppointmentReminder(officeId, patientId, IsForRecall, options = {}) {
    return useQuery(
        ["/AppointmentReminder/Default", officeId, patientId, IsForRecall],
        () => getDefultAppointmentReminder(officeId, patientId, IsForRecall),
        {
            enabled: options.enabled,
            ...options
        }

    );
}

export async function getSelectedAppointmentReminderPlan(appointmentReminderPlanId) {
    const response = await axios.get(`${baseUrl}/AppointmentReminder?appointmentReminderPlanId=${appointmentReminderPlanId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export const appointmentDetailActions = async (params) => {
    const response = await axios.put(`${baseUrl}/Appointment`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const appointmentCancelNextVisit = async (AppointmentRecallId, RecallDate) => {
    const response = await axios.delete(`${baseUrl}/Appointment/CancelRecall?AppointmentRecallId=${AppointmentRecallId}&RecallDate=${RecallDate}`
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const completeAppointment = async (params) => {
    const response = await axios.put(`${baseUrl}/Appointment/Complete`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getSlot(officeId, date, doctorId) {
    const response = await axios.get(`${baseUrl}/Slot?officeId=${officeId}&date=${date}&doctorId=${doctorId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useSlot(officeId, date, doctorId) {
    return useQuery(
        ["/Appointment/slot", officeId, date, doctorId],
        () => getSlot(officeId, date, doctorId));
}

export async function getAppointmentType(pageNumber, pageSize, officeId) {
    let appointmentTypebaseUrl = `${baseUrl}/Appointment/AppointmentTypeList?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const queryParams = [];
    if (officeId) {
        queryParams.push(`&officeId=${officeId}`);
    }
    if (queryParams.length > 0) {
        appointmentTypebaseUrl += `${queryParams.join('&')}`;
    }
    const response = await axios.get(appointmentTypebaseUrl);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAppointmentType(pageNumber, pageSize, officeId) {
    return useQuery(
        ["/appointment-type/list", pageNumber, pageSize, officeId],
        () => getAppointmentType(pageNumber, pageSize, officeId));
}

export async function addAppointmentType(payload) {
    const response = await axios.post(`${baseUrl}/Appointment/AppointmentType`, payload);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteAppointmentType(appointmentTypeId) {
    const response = await axios.delete(`${baseUrl}/Appointment/AppointmentType?AppointmentTypeId=${appointmentTypeId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getDashboard(officeId, isAdmin , fromDate, toDate) {
    let appointmentTypeListbaseUrl = `${baseUrl}/Doctor/Dashboard?OfficeId=${officeId}&isAdmin=${isAdmin}`;
    const queryParams = [];
    if (fromDate) {
        queryParams.push(`&fromDate=${fromDate}`);
    }
    if (toDate) {
        queryParams.push(`&toDate=${toDate}`);
    }
    if (queryParams.length > 0) {
        appointmentTypeListbaseUrl += `${queryParams.join('')}`;
    }
    const response = await axios.get(appointmentTypeListbaseUrl);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}


export function useDashboard(officeId, isAdmin , fromDate, toDate) {
    return useQuery(
        ["/Doctor/Dashboard", officeId, fromDate, toDate, isAdmin],
        () => getDashboard(officeId, isAdmin , fromDate, toDate),
    );
}

export async function getAppointmentReminder(pageNumber, pageSize, officeId, userId) {
    const response = await axios.get(`${baseUrl}/AppointmentReminder/List?pageNumber=${pageNumber}&pageSize=${pageSize}&officeId=${officeId}&userId=${userId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAppointmentReminder(pageNumber, pageSize, officeId, userId) {
    return useQuery(
        ["/appointment-reminder/list", pageNumber, pageSize, officeId, userId],
        () => getAppointmentReminder(pageNumber, pageSize, officeId, userId));
}

export async function addNewAppointmentReminder(data) {
    const response = await axios.post(`${baseUrl}/AppointmentReminder`, {
        ...data
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function addNewGlobalAppointmentReminder(data) {
    const response = await axios.post(`${baseUrl}/AppointmentReminder/Global`, {
        ...data
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function updateAppointmentReminder(data) {
    const response = await axios.put(`${baseUrl}/AppointmentReminder`, {
        ...data
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function updateAppointmentRemindermapping(data) {
    const response = await axios.put(`${baseUrl}/Appointment/ReminderPlanMapping`, {
        ...data
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function createAppointment(data) {
    const response = await axios.post(`${baseUrl}/Appointment/Book`, {
        ...data
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function cancelAppointmentRequest(data) {
    const response = await axios.put(`${baseUrl}/AppointmentRequest/Cancel`, {
        ...data
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteAppointmentRequest(requestId) {
    const response = await axios.delete(`${baseUrl}/AppointmentRequest?AppointmentRequestId=${requestId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getPatientsList(params) {
    const response = await axios.get(`${baseUrl}/PatientStatus/MyPatient`, {
        params: {
            ...params
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function usePatientsList(params, options = {}) {
    return useQuery(
        ["/appointment/patients", params],
        () => getPatientsList(params),
        options
    );
}

export async function getOtherDoctorsList(params) {
    const response = await axios.post(`${baseUrl}/PatientStatus/OtherDoctor`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useOtherDoctorsList(params, options = {}) {
    return useQuery(
        ["/appointment/otherDoctors", params],
        () => getOtherDoctorsList(params),
        options
    );
}

export async function getPatientNotes(params) {
    const response = await axios.get(`${baseUrl}/PatientNote`, {
        params: {
            ...params
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function usePatientNotes(params, options = {}) {
    return useQuery(
        ["/appointment/patient-notes", params],
        () => getPatientNotes(params),
        options
    );
}

export async function getPatientNoteById(patientId) {
    const response = await axios.get(`${baseUrl}/PatientNote/${patientId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function usePatientNoteById(patientId, options = {}) {
    return useQuery(
        ["/appointment/patient-notes-id", patientId],
        () => getPatientNoteById(patientId),
        options
    );
}

export const addPatientnote = async (params) => {
    const response = await axios.post(`${baseUrl}/PatientNote`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};


export const getPatientStatus = async (params) => {
    const response = await axios.get(`${baseUrl}/PatientStatus/Status`,
        {
            params: {
                ...params
            }
        }
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getAppointmentRecallList(startDate, endDate, appointmentId, pageNumber, pageSize) {
    const response = await axios.get(`${baseUrl}/Appointment/AppointmentRecallList?startDate=${startDate}&endDate=${endDate}&appointmentId=${appointmentId}&pageNumber=${pageNumber}&pageSize=${pageSize}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAppointmentRecallList(startDate, endDate, appointmentId, pageNumber, pageSize, options = {}) {
    return useQuery(
        ["/Appointment/AppointmentRecallList", startDate, endDate, appointmentId, pageNumber, pageSize],
        () => getAppointmentRecallList(startDate, endDate, appointmentId, pageNumber, pageSize),
        options
    );
}

export const updatePatientStatus = async (params) => {
    const response = await axios.put(`${baseUrl}/PatientStatus`,
        {
            ...params
        }

    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getOfficeDoctors(officeId, pageNumber, pageSize) {
    const response = await axios.get(
      `${baseUrl}/Receptionist/Doctors?pageNumber=${pageNumber}&pageSize=${pageSize}&officeId=${officeId}`);
  
    if (response.data.statusCode !== 200) {
      throw new Error(response.data.message);
    }
  
    return {
      items: response.data.data,
      totalItems: response.data.pagination.totalItems,
    };
  }
  
  export function useOfficeDoctor(officeId, pageNumber, pageSize) {
    return useQuery(
      ["Receptionist/Doctors", officeId, pageNumber, pageSize],
      () => getOfficeDoctors(officeId, pageNumber, pageSize)
    );
  }

  export async function getAppointmentNotes(params) {
    const response = await axios.get(`${baseUrl}/Appointment/Notes`, {
        params: {
            ...params
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAppointmentNotes(params, options = {}) {
    return useQuery(
        ["/Appointment/Notes", params],
        () => getAppointmentNotes(params),
        options
    );
}


export const addAppointmentNotes = async (params) => {
    const response = await axios.post(`${baseUrl}/Appointment/AddNote`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getAppointmentHistory(params) {
    const response = await axios.get(`${baseUrl}/Appointment/PatientHistory`, {
        params: {
            ...params
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAppointmentHistory(params, options = {}) {
    return useQuery(
        ["Appointment/PatientHistory", params],
        () => getAppointmentHistory(params),
        options
    );
}

export async function getAppointmentCovidHistory(appointmentId) {
    const response = await axios.get(`${officeBaseUrl}/Covid/Appointment/${appointmentId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data.data;
    
}

export function useAppointmentCovidHistory(appointmentId, options = {}) {
    return useQuery(
        ["Covid/Appointment", appointmentId],
        () => getAppointmentCovidHistory(appointmentId),
        options
    );
}

export async function getQuestionnaireResponse(officeId, doctorId,patientId, appointmentId) {
    const response = await axios.get(`${baseUrl.replace( "/api/v1", "")}/Common/QuestionnaireResponse?officeId=${officeId}&doctorId=${doctorId}&patientId=${patientId}&appointmentId=${appointmentId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data.data;
    
}

export function useQuestionnaireResponse(officeId, doctorId,patientId, appointmentId, options = {}) {
    return useQuery(
        ["Covid/Appointment", officeId, doctorId,patientId, appointmentId],
        () => getQuestionnaireResponse(officeId, doctorId,patientId, appointmentId),
        options
    );
}


export const reAssignAppointment = async (params) => {
    const response = await axios.post(`${baseUrl}/AppointmentSchedule/Reassign`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const reScheduleAppointment = async (params) => {
    const response = await axios.post(`${baseUrl}/AppointmentSchedule`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const createNewAppointment = async (params) => {
    const response = await axios.post(`${baseUrl}/appointment/Book`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getTodaysAppointments(params) {
    const response = await axios.post(`${baseUrl}/Doctor/Schedules`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllTodaysAppointments(params, options = {}) {
    return useQuery(
        ["/Doctor/Schedules", params],
        () => getTodaysAppointments(params),
        options
    );
}

export const reviewComment = async (params) => {
    const response = await axios.post(`${baseUrl}/Review/Comment`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
export async function getTrackScheduleChangesAppointments(params) {
    const response = await axios.get(`${baseUrl}/TrackScheduleChanges`, {
        params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllTrackScheduleChangesAppointments(params, options = {}) {
    return useQuery(
        ["/TrackScheduleChanges", params],
        () => getTrackScheduleChangesAppointments(params),
        options
    );
}

export const blockSelectedDateSlot = async (params) => {
    const response = await axios.post(`${baseUrl}/Slot/Block`, {
        ...params
     });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const blockRepeatDateSlot = async (params) => {
    const response = await axios.post(`${baseUrl}/Slot/BlockFutureTimeSlot`,{
        ...params
     });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const unblockSelectedDateSlot = async (params) => {
    const response = await axios.post(`${baseUrl}/Slot/unblock`, {
        ...params
     });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const unblockRepeatDateSlot = async (params) => {
    const response = await axios.post(`${baseUrl}/Slot/unblockFutureTimeSlot`,{
        ...params
     });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
export async function getSlotDetail(params) {
    const response = await axios.get(`${baseUrl}/Slot/SlotDetail`, {
        params
    })

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}


export const updateLinkToNextRecall = async (params) => {
    const response = await axios.put(`${baseUrl}/Appointment/RecallDate`,
        {
            ...params
        }

    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export async function getRemidnerPlanDetail(appointmentId) {
    const response = await axios.get(`${baseUrl}/AppointmentReminder/ReminderPlanMappingByAppointmentId?appointmentId=${appointmentId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useRemidnerPlanDetail(appointmentId, options= {}) {
    return useQuery(
        ["/AppointmentReminder/ReminderPlanMappingByAppointmentId", appointmentId],
        () => getRemidnerPlanDetail(appointmentId),
        options
    );
}

export async function getAppointmentSingleReminder(appointmentReminderPlanId, ) {
    const response = await axios.get(`${baseUrl}/AppointmentReminder?appointmentReminderPlanId=${appointmentReminderPlanId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAppointmentSingleReminder(appointmentReminderPlanId, options= {}) {
    return useQuery(
        ["/AppointmentReminder", appointmentReminderPlanId],
        () => getAppointmentSingleReminder(appointmentReminderPlanId)),
        options
}

export async function getRecallReminder(appointmentId) {
    const response = await axios.get(`${baseUrl}/AppointmentRecall?appointmentId=${appointmentId}`)

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useRecallReminder(appointmentId) {
    return useQuery(
        ["/AppointmentRecall", appointmentId],
        () => getRecallReminder(appointmentId));
}

export async function updateRecallReminder(params) {
    const response = await axios.put(`${baseUrl}/AppointmentRecall`,
        params
    );

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getDoctorMessageHistory(params) {
    const response = await axios.post(`${baseUrl}/Doctor/MessageHistory`, {
        ...params
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllDoctorMessageHistory(params, options = {}) {
    return useQuery(
        ["/Doctor/MessageHistory", params],
        () => getDoctorMessageHistory(params),
        options
    );
}

export async function sendDoctorMessageHistory(params) {
    const response = await axios.post(`${baseUrl}/Doctor/SendMessage`, params);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getMarketingPatient(pageNumber, pageSize, officeId, searchTerm) {
    let patientbaseUrl = `${baseUrl}/Marketing/patients?pageNumber=${pageNumber}&pageSize=${pageSize}&officeId=${officeId}`;
    const queryParams = [];
    if (searchTerm) {
        queryParams.push(`&searchTerm=${searchTerm}`);
    }
  
    if (queryParams.length > 0) {
        patientbaseUrl += `${queryParams.join('')}`;
    }
    const response = await axios.get(patientbaseUrl);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllMarketingPatient(pageNumber, pageSize, officeId, searchTerm) {
    return useQuery(
        ["/Marketing/patients", pageNumber, pageSize, officeId, searchTerm],
        () => getMarketingPatient(pageNumber, pageSize, officeId, searchTerm)
    );
}

export async function getGlobalRemidnerPlanDetail(officeId, ownerId, patientId, reminderPlanType) {
    // const response = await axios.get(`${baseUrl}/AppointmentReminder/ByPlanType?officeId=${officeId}&ownerId=${ownerId}&patientId=${patientId}&reminderPlanType=${reminderPlanType}`)
    let patientbaseUrl = `${baseUrl}/AppointmentReminder/ByPlanType?ownerId=${ownerId}&reminderPlanType=${reminderPlanType}`;
    const queryParams = [];
    if (officeId) {
        queryParams.push(`&officeId=${officeId}`);
    }

    if(patientId) {
        queryParams.push(`&patientId=${patientId}`);
    }
  
    if (queryParams.length > 0) {
        patientbaseUrl += `${queryParams.join('')}`;
    }
    const response = await axios.get(patientbaseUrl);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
}

export function useGlobalRemidnerPlanDetail(officeId, ownerId, patientId, reminderPlanType, options= {}) {
    return useQuery(
        ["/AppointmentReminder/ByPlanType", officeId, ownerId, patientId, reminderPlanType],
        () => getGlobalRemidnerPlanDetail(officeId, ownerId, patientId, reminderPlanType),
        options
    );
}

