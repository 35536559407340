import React, { useContext, useEffect, useState } from 'react'
import Page from 'components/Page';
import Text from 'components/Text';
import { withTranslation } from 'react-i18next'
import {  Card } from 'reactstrap';
import BookTimeslot from '../../components/BookTimeslot';
import SchedulerCalendar from 'accountOwner/pages/Scheduler/components/SchedulerCalendar';
import styles from "../../Appointments.module.scss";
import "../../Appointments.module.scss";
import Loader from 'components/Loader';
import {  getStorage, setStorage } from 'utils';
import useCommonModule from 'accountOwner/pages/Appointments/hooks/useCommonModule';
import { AppointmentsContext } from 'accountOwner/pages/Appointments/AppointmentsContext';
import appointmentStyles from "../../Appointments.module.scss";
import constants from "../../../../../constants";
import usePatientAppointmentAcceptModule from 'accountOwner/pages/Appointments/hooks/usePatientAppointmentAcceptModule';
import ToggleSwitch from 'components/ToggleSwitch';
import OperatoryModal from "../../modal/OperatoryModal"
import moment from 'moment';

const KEY_NAME = 'acceptance-reminder';
const ReacllAppointment = ({ t, history }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const officeState = getStorage('officeState')
    const {
        data: {
            appointmentDetail,
            showLoader,
            errors,
        },
        methods: {
            goToPreviousScreen,
            handleCreateRecall
        }
    } = usePatientAppointmentAcceptModule(t, officeState.officeState, KEY_NAME);

    const {
        methods: {
            redirectAppointmentType,
            redirectSelectReminder,
            redirectNextRecall
        },
    } = useCommonModule(history, t);


    const {
        reminderPlan,
        options,
        selectedAppointmentType,
        selectedSlotDate,
        appointmentSlots,
        setSlotDate,
        setAppointmentSlots,
        message,
        handlechangeMessageInput,
        selectedNextRecall,
        setselectedOperatory,
        selectedOperatory,
        appointmentAccepted,
        setappointmentAccepted,
    } = useContext(AppointmentsContext);
    const selectedDate = moment(selectedSlotDate).startOf('day');
    const [selectedOperatoryRadio, setselectedOperatoryRadio] = useState(selectedOperatory);


    useEffect(() => {
        setStorage('createRecall', {
            createRecall: true,
        });
        setStorage(constants.patientScheduling.cache.eventlisting, {
            activeTab: 1,
        });
    }, [])

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const handleSelectOperatory = () => {
        setselectedOperatory(selectedOperatoryRadio);
        closeModal()
    }


    const TopCardData = ({ heading, data }) => (
        <div>
            <Text size="12px" weight="400" color="#6F7788"> {heading} </Text>
            <Text size="14px" weight="600" color="#102C42" >{data}</Text>
        </div>
    )

    const BottomCardData = ({ typeOfAppointment, selectType, data, onClick }) => (
        <div className={styles['heading-data-Wrapper']}>
            <div className={styles['head-link-wrapper']}>
                <div className={styles['appointment-head']}>{typeOfAppointment}</div>
                <div onClick={(e) => onClick(e, appointmentDetail)} className={styles["appointment-link"]}>{selectType}</div>
            </div>
            <div className={styles['appointment-text']}> {data?.title || data?.recallType?.title}</div>
        </div>
    )

    const OperatoryCardData = ({ typeOfAppointment, selectType, data, onClick }) => (
        <div className={styles['heading-data-Wrapper']}>
            <div className={styles['head-link-wrapper']}>
                <div className={styles['appointment-head']}>{typeOfAppointment}</div>
                <div onClick={(e) => onClick(e, appointmentDetail)} className={styles["appointment-link"]}>{selectType}</div>
            </div>
            <div className={styles['appointment-text']}> {data?.appointments_operatory || ""}</div>
        </div>
    )

    const onActiveStartDateChange = (value) => {
        if (constants.calanderActions.includes(value?.action) || (value?.action === constants.calanderActionKey.drillDown && (value?.view === 'month' || value?.view === 'year'))) {
            value?.activeStartDate && setSlotDate(value.activeStartDate);
        }
    }

    const handleCreateSubmit = async (e) => {
        const startTimes = appointmentSlots?.filter(app => app.startTime)?.map(app => app.startTime);
       
        const params = {
            doctorId: appointmentDetail?.doctor?.id,
            officeId: appointmentDetail?.office?.id,
            patientId: appointmentDetail?.patient?.id,
            date: moment(selectedSlotDate).format("YYYY-MM-DD"),
            reminderDate: moment.utc(selectedSlotDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            slots: startTimes,
            Description: message,
            appointmentReminderPlanId: reminderPlan?.[KEY_NAME]?.id,
            appointmentTypeId: selectedAppointmentType?.[KEY_NAME]?.appointmentTypeId,
            createdFromRecallId: selectedNextRecall?.createdFromRecallId,
            recallDate: selectedNextRecall?.recallDueDate,
            appointmentRequestId: null
        };

        if (appointmentDetail?.office?.isConnectedWithSikka) {
            params.MarkAppointmentStatusAsAccepted = appointmentAccepted;
            params.Operatory = selectedOperatory?.appointments_operatory || null;
        }
        await handleCreateRecall(e, params);
    }

    return (
        <Page onBack={goToPreviousScreen}>
            {showLoader && <Loader />}
            <p className={styles["mobile-text-size"] + " " + "mb-0"}>{t('Create Appointment for Recall')}</p>
            <Text size="14px" weight="300" color="#000000" marginBottom="30px">{t("patientAppointmentMembers.patientAndAppointmentDetails")}</Text>
            <Card className="form-wrapper p-4">
                <Text color="#587E85" size="16px" weight="600" marginBottom="16px">{t("patientAppointmentMembers.patientAndAppointmentDetails")}</Text>
                <div className={styles["patient-appointment-details-upper-card-data"]}>
                    <TopCardData heading="Patient Name" data={`${appointmentDetail?.patient?.firstName} ${appointmentDetail?.patient?.lastName}`} />
                    <TopCardData heading="Patient Email Address" data={`${appointmentDetail?.patient?.emailId}`} />
                    <TopCardData heading="Doctor" data={`${appointmentDetail?.doctor?.firstName} ${appointmentDetail?.doctor?.lastName}`} />
                    <TopCardData heading="Clinic Name" data={appointmentDetail?.office?.name} />
                </div>
            </Card>
            <Card className="form-wrapper">
                <div className={styles['recall-patient-appointment-details-wrapper']}>
                    <div className={styles["patientAppointmentDetails-left-part"]}>
                        <div className="c-field">
                            <label>{t('Message To Patients*')}</label>
                            <textarea
                                className="c-form-control"
                                Placeholder={t('Type your message', { field: t('your message') })}
                                name="MessageToPatients"
                                maxLength="256"
                                onChange={handlechangeMessageInput}
                                error={errors.MessageError}
                                value={message}
                            ></textarea>
                            {errors.MessageError && (
                                <span className="error-msg">{errors.MessageError}</span>
                            )}
                        </div>
                        <BottomCardData
                            typeOfAppointment="Type of Appointment*"
                            selectType="Select Type"
                            data={selectedAppointmentType?.[KEY_NAME]}
                            onClick={redirectAppointmentType}
                        />
                        {errors?.TypeError && <span className="error-msg">{errors?.TypeError}</span>}

                        {appointmentDetail?.office?.isConnectedWithSikka && <OperatoryCardData
                            typeOfAppointment="Operatory*"
                            selectType="Select Operatory"
                            data={selectedOperatory}
                            onClick={() => setIsModalOpen(!isModalOpen)}
                        />}

                        <div className={appointmentStyles['heading-data-Wrapper']}>
                            <div className={appointmentStyles['head-link-wrapper']}>
                                <div className={appointmentStyles['appointment-head']}>{t("patientAppointmentMembers.reminderPlan")}</div>
                                <div onClick={(e) => redirectSelectReminder(e, appointmentDetail)} className={styles["appointment-link"]}>
                                    {reminderPlan?.[KEY_NAME]?.title ? t("patientAppointmentMembers.changeReminderPlan") : t("patientAppointmentMembers.selectReminderPlan")}
                                </div>
                            </div>
                            {reminderPlan?.[KEY_NAME] && <div className={appointmentStyles['appointment-modal-card']}>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{reminderPlan?.[KEY_NAME]?.title || ''}</Text>
                                {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.map(plan =>
                                    <div className='mt-2' key={plan?.id}>
                                        <Text size="14px" weight="600" color="#102C42" >{plan?.reminderBefore + ' ' +
                                            options.find(
                                                m => m.id == plan?.reminderBeforeType,
                                            )?.name +
                                            ' ' +
                                            t("patientAppointmentMembers.before")}
                                        </Text>
                                        <Text size="14px" weight="600" color="#102C42" >
                                            {[plan.isEmail && t("patientAppointmentMembers.isEmail"), plan.isSms && t("patientAppointmentMembers.isSms"), plan.isPushNotification && t("patientAppointmentMembers.isPushNotification")]
                                                .filter(Boolean)
                                                .join(', ')}
                                        </Text>
                                    </div>
                                )}
                            </div>}
                        </div>
                        <BottomCardData
                            typeOfAppointment="Link to Next Recall"
                            selectType="Select Next Recall"
                            data={selectedNextRecall}
                            onClick={(e) => redirectNextRecall(e, appointmentDetail)}
                        />
                        {appointmentDetail?.office?.isConnectedWithSikka && <div className="d-flex align-items-center mt-4">
                            <ToggleSwitch
                                className="mt-0"
                                label="bill-me-later-toggle"
                                value={appointmentAccepted}
                                onChange={(e) => setappointmentAccepted(e.target.checked)}
                            />
                            <Text
                                size="13px"
                                marginBottom="0px"
                                weight="400"
                                className="mr-2 mt-2"
                                color="#79869A"
                            >
                                {t("patientAppointmentMembers.appointmentAsAccepted")}
                            </Text>
                        </div>}

                    </div>
                    <Card className={styles["recall-patientAppointmentDetails-right-part-card"]}>
                        <div className={styles['book-slot'] + " " + 'd-flex flex-column'}>
                            <div>
                                <div className='schedular-calendar-box mb-3'>
                                    <SchedulerCalendar value={selectedSlotDate} onChange={setSlotDate} onActiveStartDateChange={onActiveStartDateChange} />
                                </div>
                                <Text size="12px" weight="400" color="#6F7788">{t("patientAppointmentMembers.reassignTimezone")}</Text>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{t("patientAppointmentMembers.reassignPst")}</Text>
                                <div className={styles['scheduler-calendar-timezone-separator']}></div>

                            </div>
                            <div className={styles["book-slot-new-appointment"]}>
                                <div>
                                    <Text color='#587E85' weight='600' size='16px' >{t("patientAppointmentMembers.reassignBookingTimeslot")}</Text>
                                </div>
                                <div className={styles['notify-checkbox'] + " " + " d-flex "}>
                                    <div className={styles['checkbox-container'] + " " + " d-flex flex-row "}>
                                        <div className={styles['show-checkedbox'] + " " + styles["box1"]}></div>
                                        {t("patientAppointmentMembers.reassignBooked")}
                                    </div>
                                    <div className={styles['checkbox-container'] + " " + " d-flex flex-row"}>
                                        <div className={styles['show-checkedbox'] + " " + styles['box2']}></div>
                                        {t("patientAppointmentMembers.reassignAvailable")}
                                    </div>
                                    <div className={styles['checkbox-container'] + " " + "d-flex flex-row"}>
                                        <div className={styles['show-checkedbox'] + " " + styles["box3"]}></div>
                                        {t("patientAppointmentMembers.reassignBlocked")}
                                    </div>
                                </div>
                            </div>
                            <BookTimeslot
                                t={t}
                                officeId={appointmentDetail?.office?.id}
                                date={moment(selectedDate).format('YYYY-MM-DD')}
                                doctorId={appointmentDetail?.doctor?.id}
                                setSelectedSlots={setAppointmentSlots}
                                selectedSlots={appointmentSlots}
                                errors={errors}
                            />
                             {errors.TimeSlot && (
                                <span className="error-msg">{errors.TimeSlot}</span>
                            )}
                        </div>
                        <div className={styles.recall_appointment_btn}>
                            <button className='button button-round button-shadow w-sm-100 text-nowrap' onClick={(e) => handleCreateSubmit(e)}>
                                {t("patientAppointmentMembers.reassigncreateAppointment")}
                            </button>
                            <button className='button button-round button-border button-dark btn-mobile-link text-nowrap' onClick={goToPreviousScreen} >
                                {t("patientAppointmentMembers.cancelAppointment")}
                            </button>
                        </div>
                    </Card>
                </div>
            </Card>

            {isModalOpen && (
                <OperatoryModal
                    t={t}
                    closeDeleteModal={closeModal}
                    isDeleteModalOpen={isModalOpen}
                    officeId={ appointmentDetail?.office?.id}
                    setselectedOperatoryRadio={setselectedOperatoryRadio}
                    selectedOperatoryRadio={selectedOperatoryRadio}
                    handleSelectOperatory={handleSelectOperatory}
                />
            )}
        </Page>
    )
};
export default withTranslation()(ReacllAppointment);

