import React, { useEffect, useRef, useState } from "react";
import Text from "components/Text";
import { DropdownItem, Modal, ModalBody } from "reactstrap";
// import Tooltip from "reactstrap/lib/Tooltip";
import styles from "../PatientConversation.module.scss";
import Oval from "../../../../assets/images/Oval.svg";
import DummyImage1 from "../../../../assets/images/staff-default-rounded.png";
import AudioCallIcon from "../../../../assets/images/ico_audio_call.svg";
import VideoCallIcon from "../../../../assets/images/ico_video_call.svg";
import CallMadeIcon from "../../../../assets/images/call_made.svg";
import CallMadeDownIcon from "../../../../assets/images/call_made_down.svg";
import ClockSmall from "../../../../assets/images/clock_small.svg";
import tooltipImg from "../../../../assets/images/info_black-tooltip.svg";
import { useSbCalls } from "Messenger/calling/context";
import SendbirdCall, { DirectCallEndResult } from "sendbird-calls";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderIcon } from "react-hot-toast";
import constants from "../../../../constants";
import { useHistory } from "react-router-dom";
import Empty from "components/Empty";
import moment from "moment";
import { getCallLog } from "repositories/chat-repository";
import { encodeId } from "utils";

export default function CallHistoryModal({
  isOpen,
  toggleModal,
  t,
  currentUser,
  doctorChatCheck,
}) {
  const { appIntialized, profile } = useSbCalls();
  const [callHistory, setCallHistory] = useState([]);
  const queryRef = useRef(false);
  const [isMore, setIsMore] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (appIntialized && profile.id) {
      SendbirdCall.authenticate({ userId: profile.id.toString() }).then(() => {
        queryRef.current = SendbirdCall.createDirectCallLogListQuery({
          // myRole: 'ALL',
          endResults: [
            DirectCallEndResult.COMPLETED,
            // DirectCallEndResult.CANCELED,
            // DirectCallEndResult.DECLINED,
            // DirectCallEndResult.DIAL_FAILED,
            // DirectCallEndResult.ACCEPT_FAILED,
          ],
        });
        loadMore();
      });
    }
  }, [appIntialized, profile]);
  const durationCalculation = (duration) => {
    const [h, m, s] = new Date(duration)
      .toISOString()
      .substring(11, 19)
      .split(":");
    const hms = Object.entries({ h, m, s });
    return hms
      .reduce(
        (prev, curr) => (
          Number(curr[1]) > 0 && prev.push(curr[1] + curr[0]), prev
        ),
        []
      )
      .join(" ");
  };
  const loadMore = () => {
    if (!queryRef.current.hasNext) {
      setIsMore(queryRef.current.hasNext);
      return;
    }
    queryRef.current.next().then((res) => {
      setCallHistory((prev) => [...prev, ...res]);
      if (isMore && !res?.length) {
        setIsMore(false);
      }
    });
  };

  const redirectToDetails = (item) => {
    const appointmentId = item._customItems?.appointmentId;
    if (!appointmentId) {
      getCallLog(item.callId).then((res) => {
        if (res.id) {
          history.push({
            pathname:
              constants.routes.appointment.patientAppointmentDetails.replace(
                ":appointmentId",
                encodeId(res.id)
              ),
            state: {
              isFromPatientChat: true,
            },
          });
        }
      });
      return;
    }
    history.push({
      pathname: constants.routes.appointment.patientAppointmentDetails.replace(
        ":appointmentId",
        appointmentId
      ),
      state: {
        isFromPatientChat: true,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-dialog-centered modal-width-660 new-conversation-popup"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={toggleModal}>
        <img src={require("assets/images/cross.svg").default} alt="close" />
      </span>
      <ModalBody>
        <Text size="25px" marginBottom="24px" weight="500" color="#111b45">
          {t("messenger.callHistory")}
        </Text>
        <div id="call-history-list" className={styles.call_history_scroll}>
          {callHistory.length === 0 && !isMore && (
            <div className={styles["not_found"]}>
              <Empty
                Message={t("messenger.noCallHistoryFound")}
                anotherChild={<p>{t("messenger.noCallHistoryAvailable")}</p>}
              />
            </div>
          )}
          <InfiniteScroll
            dataLength={callHistory.length}
            hasMore={isMore}
            next={loadMore}
            scrollableTarget="call-history-list"
            loader={
              <DropdownItem tag="div">
                <LoaderIcon style={{ margin: "0 auto" }} />
              </DropdownItem>
            }
            scrollThreshold={0.1}
            className="pr-3"
          >
            {callHistory.map((call, index) => {
              const showDetail =
                call.callee.userId === currentUser?.id?.toString()
                  ? call.caller
                  : call.callee;

              return (
                <div key={call.callId} className={styles.call_history_row}>
                  <div className={styles.image_details_wrapper}>
                    <div className={styles.image_container}>
                      <img
                        src={showDetail?.profileUrl || DummyImage1}
                        alt="patientImage"
                      />
                    </div>
                    <div className={styles.details_wrapper}>
                      <span className={styles.call_icon_content}>
                        <p className={styles.patient_name}>
                          {showDetail?.nickname}
                        </p>
                        {!doctorChatCheck && (
                          <img
                            src={tooltipImg}
                            id={`patient_name_${index}`}
                            alt="tooltip"
                            className={styles.tooltip_icon}
                            onClick={() => redirectToDetails(call)}
                          />
                        )}
                        {/* <Tooltip
                        className="new-item-card-catalogue-tooltip"
                        isOpen={callHistoryTooltip === index}
                        placement="top"
                        target={`patient_name_${index}`}
                      >
                      {call?.customItems?.name}
                      </Tooltip> */}
                      </span>
                      <span className={styles.call_icon_content}>
                        {/* {moment(call.startedAt).startOf("day").valueOf() !==
                      moment().startOf("day").valueOf()
                      ? moment(call.startedAt).format("DD MMM YYYY hh:mm a")
                      : "Today " + moment(call.startedAt).format("hh:mm a")} */}

                        <img
                          src={
                            call.callee.userId === currentUser?.id?.toString()
                              ? CallMadeDownIcon
                              : CallMadeIcon
                          }
                          alt="call made"
                        />
                        <p className={styles.patient_office_name}>
                          {moment(call.startedAt).format("DD MMM YYYY")}
                          <img src={Oval} alt="oval icon" height={8} />
                          {moment(call.startedAt).format("hh:mm a")}
                          {/* {call?.customItems?.office_name} */}
                        </p>
                      </span>
                      <span className={styles.call_icon_content}>
                        <img src={ClockSmall} alt="clock" />
                        <p className={styles.patient_office_name}>
                          Duration: {durationCalculation(call.duration)}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div>
                    <img
                      src={call.isVideoCall ? VideoCallIcon : AudioCallIcon}
                      alt={call.isVideoCall ? "Video Call" : "Audio Call"}
                    />
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </ModalBody>
    </Modal>
  );
}
