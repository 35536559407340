import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "../../../DiscussionNotes/DiscussionNotes.module.scss";
import Loader from "components/Loader";

const DeleteModal = ({ t, isDeleteModalpen, closeDeleteModal, handleDelete, loading }) => {
    return (
        <Modal
            isOpen={isDeleteModalpen}
            toggle={closeDeleteModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            {loading && <Loader />}
            <span className="close-btn" onClick={closeDeleteModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles.delete_modal_container}>
                    <Text size="25px" weight="500" color="#111B45">
                        {t('employmentProof.deleteTitle')}
                    </Text>
                    <Text size="16px" weight="300" color="#535B5F">
                        {t("employmentProof.deleteMsg")}
                    </Text>

                    <div className={styles.btn_container + " " + "btn-box d-md-flex"}>
                        <button
                            className={
                                styles.green_btn + " " + "button button-round button-shadow"
                            }
                            onClick={handleDelete}
                        >
                            {t("employmentProof.delete")}
                        </button>
                        <button
                            className={
                                styles.white_btn + " button button-round button-border btn-mobile-link button-dark"
                            }
                            title="cancel"
                            onClick={closeDeleteModal}
                        >
                            {t("employmentProof.form.cancel")}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default DeleteModal
