import { useState } from "react";
import { encodeId } from "utils";
import constants from "../../../../constants";


const useCommonModule = (history, t, state, isFrompatientDetail) => {
  const [isRejectModal, setRejectModal] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isCancelModal, setCancelModal] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [appointmentDetail, setAppointmentDetail] = useState(null);

  let Appointmentstatus = {
    Pending: 1,
    Accepted: 2,
    Rejected: 3,
    Cancelled: 4,
    Completed: 5,
    NoShow: 6,
    Confirmed: 7,
    Overdue: 8,
    Upcoming: 9,
    Converted: 10
  }

  const getStatus = (overallStatus) => {
    const statusMap = {
      1: "Pending",
      2: "Accepted",
      3: "Rejected",
      4: "Cancelled",
      5: "Completed",
      6: "No Show",
      7: "Confirmed",
      8: "Overdue",
      9: "Upcoming",
      10: "Converted"
    };
    return statusMap[overallStatus] || "";
  };

  const getStatusClass = (status) => {
    switch (getStatus(status)) {
      case "Pending":
        return 'blue-accepted-btn';
      case "Accepted":
        return 'red-pending-btn';
      case "Confirmed":
        return 'green-confirmed-btn';
      case "No Show":
        return 'no-show-btn';
      case "Rejected":
        return 'red-pending-btn';
      case "Cancelled":
        return 'red-pending-btn';
      case "Completed":
        return 'green-confirmed-btn';
      case "Overdue":
        return 'red-pending-btn';
      case "Upcoming":
        return 'green-confirmed-btn';
      case "Converted":
        return 'blue-accepted-btn';
      default:
        return '';
    }
  };


  const getMenuoptions = item => {

    const menuOption = [];
    // if status is pending and appointment booked by patient
    if (
      (item?.status === Appointmentstatus.Pending) &&
      (item?.bookedByUserId == item?.patient?.id || item?.bookedByUserId == item?.bookedForFamilyMember?.createdBy)
    ) {
      menuOption.push(
        { text: t('rejectAppointment'), onclick: handleRejectToggle },
        { text: t('acceptAppointment'), onclick: redirectPatientAppointDetail }
      );
    }
    // if status is pending or accepted and appointment not booked by patient
    else if (
      (item?.status === Appointmentstatus.Pending ||
        item?.status === Appointmentstatus.Accepted) &&
      item?.bookedByUserId != item?.patient?.id && item?.bookedByUserId != item?.bookedForFamilyMember?.createdBy
    ) {

      menuOption.push({ text: t('cancel'), onclick: handleCancelToggle });
      if (item?.status != Appointmentstatus.Pending) {

        menuOption.push(
          { text: t('confirmAppointment'), onclick: handleConfirmToggle },
          { text: t('markAsCompleted'), onclick: completeAppointment },
          { text: t('Recall'), onclick: redirectRecall },
          { text: t('reminders'), onclick: redirectReminder },
        );
      }
    }
    else if (
      item?.status === Appointmentstatus.Accepted &&
      (item?.bookedByUserId == item?.patient?.id || item?.bookedByUserId == item?.bookedForFamilyMember?.createdBy)
    ) {
      menuOption.push({ text: t('cancel'), onclick: handleCancelToggle });
      if (item?.status !== Appointmentstatus.Pending) {
        menuOption.push(
          { text: t('confirmAppointment'), onclick: handleConfirmToggle },
          { text: t('markAsCompleted'), onclick: completeAppointment },
          { text: t('Recall'), onclick: redirectRecall },
          { text: t('reminders'), onclick: redirectReminder },
        );
      }
    }
    else if (item?.status === Appointmentstatus.Completed && item.feedback) {
      menuOption.push(
        { text: t("patientAppointmentMembers.viewFeedback"), onclick: viewFeeddBackToggle },
        { text: t("patientAppointmentMembers.editRecallPLan"), onclick: handleEdtiRecall }
      );
    } else if (item?.status === Appointmentstatus.Confirmed) {
      menuOption.push(
        { text: t('markAsCompleted'), onclick: completeAppointment },
        { text: t('cancel'), onclick: handleCancelToggle },
        { text: t('Recall'), onclick: redirectRecall },
        { text: t('reminders'), onclick: redirectReminder },
      );
    } else if (item?.recallStatus === Appointmentstatus.Upcoming ||
      item?.recallStatus === Appointmentstatus.Overdue) {
      menuOption.push(
        { text: t("waitingList.createAppointment"), onclick: createRecallPatientAppointment },
        { text: t("patientAppointmentMembers.cancelNextVisit"), onclick: handleConfirmToggle }
      );
    } else if (item?.status === Appointmentstatus.Completed && !item.feedback) {
      menuOption.push({ text: t("patientAppointmentMembers.editRecallPLan"), onclick: handleEdtiRecall });
    }
    return menuOption;
  };

  const getPatientMenuoptions = item => {

    const menuOption = [];
    // if status is pending and appointment booked by patient
    if (
      (item?.status === Appointmentstatus.Pending) &&
      (item?.bookedByUserId == item?.patient?.id || item?.bookedByUserId == item?.bookedForFamilyMember?.createdBy)
    ) {
      menuOption.push(
        { text: t('rejectAppointment'), onclick: handleRejectToggle },
        { text: t('acceptAppointment'), onclick: redirectPatientAppointDetail },
        { text: t('View Message History'), onclick: redirectMessageHistory },
      );
    }
    // if status is pending or accepted and appointment not booked by patient
    else if (
      (item?.status === Appointmentstatus.Pending ||
        item?.status === Appointmentstatus.Accepted) &&
      item?.bookedByUserId != item?.patient?.id && item?.bookedByUserId != item?.bookedForFamilyMember?.createdBy
    ) {

      menuOption.push(
        { text: t('cancel'), onclick: handleCancelToggle },
        { text: t('View Message History'), onclick: redirectMessageHistory },
      );
      if (item?.status != Appointmentstatus.Pending) {

        menuOption.push(
          { text: t('confirmAppointment'), onclick: handleConfirmToggle },
          { text: t('markAsCompleted'), onclick: completeAppointment },
          { text: t('View Message History'), onclick: redirectMessageHistory },
        );
      }
    }
    else if (
      item?.status === Appointmentstatus.Accepted &&
      (item?.bookedByUserId == item?.patient?.id || item?.bookedByUserId == item?.bookedForFamilyMember?.createdBy)
    ) {
      menuOption.push({ text: t('cancel'), onclick: handleCancelToggle });
      if (item?.status !== Appointmentstatus.Pending) {
        menuOption.push(
          { text: t('confirmAppointment'), onclick: handleConfirmToggle },
          { text: t('markAsCompleted'), onclick: completeAppointment },
          { text: t('View Message History'), onclick: redirectRecall },
        );
      }
    }
    else if (item?.status === Appointmentstatus.Completed && item.feedback) {
      menuOption.push(
        { text: t("patientAppointmentMembers.viewFeedback"), onclick: viewFeeddBackToggle },
        { text: t('View Message History'), onclick: redirectMessageHistory },
      );
    } else if (item?.status === Appointmentstatus.Confirmed) {
      menuOption.push(
        { text: t('markAsCompleted'), onclick: completeAppointment },
        { text: t('cancel'), onclick: handleCancelToggle },
        { text: t('View Message History'), onclick: redirectMessageHistory },
      );
    } else if (item?.recallStatus === Appointmentstatus.Upcoming ||
      item?.recallStatus === Appointmentstatus.Overdue) {
      menuOption.push(
        { text: t("waitingList.createAppointment"), onclick: createRecallPatientAppointment },
        { text: t("patientAppointmentMembers.cancelNextVisit"), onclick: handleConfirmToggle }
      );
    } else if ((item?.status === Appointmentstatus.Completed || Appointmentstatus.NoShow || Appointmentstatus.Cancelled || Appointmentstatus.Rejected) && !item.feedback) {
      menuOption.push({ text: t('View Message History'), onclick: redirectMessageHistory },);
    }
    return menuOption;
  };


  const getMenuoptionsTwo = item => {
    const menuOption = [];
    // if status is pending and appointment booked by patient
    if (
      (item?.status === Appointmentstatus.Pending) &&
      (item?.bookedByUserId == item?.patient?.id || item?.bookedByUserId == item?.bookedForFamilyMember?.createdBy)
    ) {
      menuOption.push(
        { text: t('rejectAppointment'), onclick: handleRejectToggle },
        { text: t('acceptAppointment'), onclick: redirectPatientAppointDetail }
      );
    }
    // if status is pending or accepted and appointment not booked by patient
    else if (
      (item?.status === Appointmentstatus.Pending ||
        item?.status === Appointmentstatus.Accepted) &&
      item?.bookedByUserId != item?.patient?.id && item?.bookedByUserId != item?.bookedForFamilyMember?.createdBy
    ) {
      menuOption.push({ text: t('cancel'), onclick: handleCancelToggle });
      if (item?.status != Appointmentstatus.Pending) {

        menuOption.push(
          { text: t('confirmAppointment'), onclick: handleConfirmToggle },
          { text: t('markAsCompleted'), onclick: completeAppointment },
        );
      }
    }
    else if (
      item?.status === Appointmentstatus.Accepted &&
      (item?.bookedByUserId == item?.patient?.id || item?.bookedByUserId == item?.bookedForFamilyMember?.createdBy)
    ) {
      menuOption.push({ text: t('cancel'), onclick: handleCancelToggle });
      if (item?.status !== Appointmentstatus.Pending) {
        menuOption.push(
          { text: t('confirmAppointment'), onclick: handleConfirmToggle },
          { text: t('markAsCompleted'), onclick: completeAppointment },
        );
      }
    }
    else if (item?.status === Appointmentstatus.Confirmed) {
      menuOption.push(
        { text: t('markAsCompleted'), onclick: completeAppointment },
      );
    }
    return menuOption;
  };

  const redirectPatientAppointDetail = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push(
      {
        pathname: constants.routes.appointment.acceptPatientAppointment.replace(":appointmentId", encodeId(appointment?.id)),
        state: state
      });
  };

  const createRecallPatientAppointment = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push(
      {
        pathname: constants.routes.appointment.createRecallPatientAppointment.replace(":appointmentId", encodeId(appointment?.appointmentId)),
        state: state
      });
  };

  const redirectAppointmentType = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push({ pathname: constants.routes.appointment.appointmentType.replace(':officeId', encodeId(appointment?.office?.id)).replace(':requestId', encodeId(appointment?.id)), state: { isFromGloabList: true } });
  };

  const redirectSelectReminder = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push({ pathname: constants.routes.appointment.appointmentReminder.replace(':officeId', encodeId(appointment?.office?.id)).replace(':requestId', encodeId(appointment?.id)), state: { isFromGloabList: true } });
  };

  const redirectNextRecall = (e, appointment, state = false) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push({ pathname: constants.routes.appointment.linkToNextRecall.replace(':appointmentId', encodeId(appointment?.id || appointment?.appointmentId)), state: { isFromRecall: state } });
  };

  const redirectRecall = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push({ pathname: constants.routes.appointment.recall.replace(':appointmentId', encodeId(appointment?.id)) });
  };

  const redirectReminder = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push({ pathname: constants.routes.appointment.reminder.replace(':appointmentId', encodeId(appointment?.id)).replace(':officeId', encodeId(appointment?.office?.id)) });
  };


  const redirectMessageHistory = (e, row) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }

    history.push({ pathname: constants.routes.appointment.patientMessageCenter.replace(':officeId', encodeId(row?.office?.id)).replace('/:patientId?', row?.patient?.id ? `/${encodeId(row?.patient?.id)}` : "").replace('/:appointmentId?', row?.id ? `/${encodeId(row?.id)}` : ""), state: { isFrompatientDetail } })
  }

  const completeAppointment = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push(
      {
        pathname: constants.routes.appointment.feedbackConfirmation.replace(':officeId', encodeId(appointment?.office?.id)).replace(':appointmentId', encodeId(appointment?.id)),
        state: state
      });
  };

  const handleRedirectPatientAppointDetail = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push({
      pathname: constants.routes.appointment.patientAppointmentDetails.replace(":appointmentId", encodeId(appointment?.id)),
      state: { ...state, isFrompatientDetail }
    });
  };

  const handleEdtiRecall = (e, appointment) => {
    if (e.target.closest('.interactive-element')) {
      return;
    }
    history.push(constants.routes.appointment.recallReminderEdit.replace(':appointmentId', encodeId(appointment?.id)).replace(':officeId', encodeId(appointment?.office?.id)))
  }

  const handleRedirectAppointmentReAssign = (appointment) => {
    history.push(constants.routes.appointment.reassignAppointment.replace(":appointmentId", encodeId(appointment?.id)).replace(":officeId", encodeId(appointment?.office?.id)));
  };

  const handleRedirectAppointmentReSchedule = (appointment) => {
    history.push(constants.routes.appointment.rescheduleAppointment.replace(":appointmentId", encodeId(appointment?.id)).replace(":officeId", encodeId(appointment?.office?.id)));
  };

  const handleRejectToggle = (e, appointment) => {
    setRejectModal(!isRejectModal);
    setAppointmentDetail(appointment);
  }

  const handleCancelToggle = (e, appointment) => {
    setCancelModal(!isCancelModal);
    setAppointmentDetail(appointment);
  }

  const handleConfirmToggle = (e, appointment) => {
    setConfirmModal(!isConfirmModal);
    setAppointmentDetail(appointment);
  }

  const viewFeeddBackToggle = (e, appointment) => {
    setFeedbackModalOpen(!feedbackModalOpen);
    setAppointmentDetail(appointment);
  }

  function getNameById(id) {
    const gender = constants?.genderOptions?.find(option => option.id === id);
    return gender ? gender.name : "Gender not added";
  }


  return {
    data: {
      isRejectModal,
      appointmentDetail,
      isConfirmModal,
      isCancelModal,
      feedbackModalOpen
    },
    methods: {
      getStatus,
      getStatusClass,
      redirectPatientAppointDetail,
      getMenuoptions,
      handleRejectToggle,
      handleConfirmToggle,
      redirectAppointmentType,
      redirectSelectReminder,
      redirectNextRecall,
      handleCancelToggle,
      handleRedirectPatientAppointDetail,
      getNameById,
      handleRedirectAppointmentReAssign,
      handleRedirectAppointmentReSchedule,
      getMenuoptionsTwo,
      setFeedbackModalOpen,
      getPatientMenuoptions
    }
  }
}

export default useCommonModule;