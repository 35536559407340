import { useContext, useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  appointmentDetailActions,
  createNewAppointment,
  useAppointmentDetail,
  useDefultAppointmentReminder,
} from "repositories/patient-appointment-repository";
import { decodeId, encodeId, getStorage, setStorage } from "utils";
import constants from "../../../../constants";
import { AppointmentsContext } from "../AppointmentsContext";
import { useQueryClient } from "react-query";

const usePatientAppointmentAcceptModule = (t, state = {}, keyName) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const isFromAppointmentDetail = getStorage("isFromAppointmentDetail");
  const isFromManagePage = getStorage("isFromManagePage");

  const [errors, setErrors] = useState({});
  let { appointmentId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const { reminderPlan, setReminderPlan } = useContext(AppointmentsContext);
  appointmentId = decodeId(appointmentId);

  const { isLoading: appointmentLoading, data: appointmentDetail } =
    useAppointmentDetail(appointmentId);

  let IsForRecall = false;

  const {
    isLoading: defaultAppointmentLoading,
    refetch: defaultAppointmentFetch,
  } = useDefultAppointmentReminder(
    appointmentDetail?.office?.id,
    appointmentDetail?.patient?.id,
    IsForRecall,
    {
      enabled: !!appointmentDetail?.office?.id,
    }
  );

  let isLoading = appointmentLoading || defaultAppointmentLoading;

  const goToPreviousScreen = () => {
    setStorage("accept", {
      isFromGloabList: false,
    });
    setStorage("createRecall", {
      createRecall: false,
    });
    if (
      !isFromManagePage?.isFromManagePage &&
      !isFromAppointmentDetail?.isFromAppointmentDetail &&
      state?.isfromPaitentDetail
    ) {
      setStorage(constants.patientScheduling.cache.eventlisting, {
        activeTab: 1,
      });
      history.push(constants.routes.appointment.appointmentScreen);
    } else if (isFromAppointmentDetail?.isFromAppointmentDetail) {
      history.push({
        pathname:
          constants.routes.appointment.patientAppointmentDetails.replace(
            ":appointmentId",
            encodeId(appointmentId)
          ),
        state: state,
      });
    } else if (isFromManagePage?.isFromManagePage) {
      history.push({
        pathname: constants.routes.appointment.officeSelection.replace(
          ":officeId",
          encodeId(appointmentDetail?.office?.id)
        ),
        state: state,
      });
    } else {
      history.push(
        constants.routes.appointment.patientDetail
          .replace(":officeId", encodeId(appointmentDetail?.office?.id))
          .replace(":patientId", encodeId(appointmentDetail?.patient?.id))
      );
    }
  };

  const isValidEvent = (message, selectedAppointmentType) => {
    const errorsData = { ...errors };

    if (!message.trim().length) {
      errorsData.MessageError = t("patientAppointmentMembers.noteError");
    } else if (message.trim().length > 256) {
      errorsData.MessageError = t("patientAppointmentMembers.messageErro");
    } else {
      delete errorsData.MessageError;
    }

    if (!selectedAppointmentType?.title?.trim()?.length > 0) {
      errorsData.TypeError = t("patientAppointmentMembers.selectTypeError");
    } else {
      delete errorsData.TypeError;
    }

    setErrors(errorsData);

    return !Object.values(errorsData).some(Boolean);
  };

  const isValidCreateEvent = (params) => {
    const errorsData = { ...errors };

    if (!params.Description.trim().length) {
      errorsData.MessageError = t("patientAppointmentMembers.noteError");
    } else if (params.Description.trim().length > 256) {
      errorsData.MessageError = t("patientAppointmentMembers.messageErro");
    } else {
      delete errorsData.MessageError;
    }

    if (!params.appointmentTypeId) {
      errorsData.TypeError = t("patientAppointmentMembers.selectTypeError");
    } else {
      delete errorsData.TypeError;
    }

    if (!params.slots?.length) {
      errorsData.TimeSlot = t("patientAppointmentMembers.errorTimeSlot");
    } else {
      delete errorsData.TimeSlot;
    }

    setErrors(errorsData);

    return !Object.values(errorsData).some(Boolean);
  };

  const fetchDefaultData = async () => {
    queryClient.invalidateQueries([
      "/AppointmentReminder/Default",
      appointmentDetail?.office?.id,
      appointmentDetail?.patient?.id,
      IsForRecall,
    ]);
    const refetchData = await defaultAppointmentFetch();
    if (refetchData?.data?.appointmentReminderPlanSetting?.length) {
      setReminderPlan((prevReminderPlan) => ({
        ...prevReminderPlan,
        [keyName]: {
          title: refetchData?.data?.title,
          id: refetchData?.data?.id,
          appointmentReminderPlanSetting: [
            ...refetchData?.data?.appointmentReminderPlanSetting,
          ],
        },
      }));
    }
  };

  useEffect(() => {
    if (!reminderPlan?.[keyName] && !isLoading) {
      fetchDefaultData();
    }
  }, [defaultAppointmentLoading, defaultAppointmentFetch]);

  const handleSubmit = async (
    e,
    selectedAppointmentType,
    selectedReminderPlan,
    message
  ) => {
    e.preventDefault();
    if (isValidEvent(message, selectedAppointmentType)) {
      setShowLoader(true);
      try {
        const params = {
          appointmentId: appointmentId,
          status: 2,
          message: message,
          appointmenttypeId: selectedAppointmentType?.appointmentTypeId,
          AppointmentReminderPlanId:
            selectedReminderPlan?.appointmentReminderPlanId || null,
          createdFromRecallId: null,
          recallDate: null,
        };

        let res = await appointmentDetailActions(params);
        if (res?.statusCode === 200) {
          goToPreviousScreen();
          toast.success(res.message);
        }
      } catch (err) {
        setShowLoader(true);
        toast.error(err.message);
      } finally {
        setShowLoader(false);
      }
    }
  };

  const handleCreateRecall = async (e, params) => {
    e.preventDefault();
    if (isValidCreateEvent(params)) {
      setShowLoader(true);
      try {
        let res = await createNewAppointment(params);
        if (res?.statusCode === 200) {
          goToPreviousScreen();
          toast.success(res.message);
        }
      } catch (err) {
        setShowLoader(true);
        toast.error(err.message);
      } finally {
        setShowLoader(false);
      }
    }
  };

  return {
    data: {
      appointmentDetail,
      isLoading,
      showLoader,
      errors,
    },
    methods: {
      handleSubmit,
      goToPreviousScreen,
      handleCreateRecall,
    },
  };
};

export default usePatientAppointmentAcceptModule;
