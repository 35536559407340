import { useState, useEffect } from 'react';
import constants from "../../../../constants";
import { encodeId, getStorage, handleSuccess, setStorage } from 'utils';
import { useSelector } from 'react-redux';
import { getPatientStatus, updatePatientStatus } from 'repositories/patient-appointment-repository';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryClient } from 'react-query';

const pageSize = 20;

const usePatientListModule = (officeId, usePatientList, staffDetail, isMyPatients) => {
    const history = useHistory();
    const profile = useSelector(state => state.userProfile.profile);
    const queryClient = useQueryClient();

    const cachePatientsListFilter = constants.patientsList.cache.filters;
    const cacheFilters = getStorage(cachePatientsListFilter);
    const isFilterApply = cacheFilters?.doctors?.length > 0 || cacheFilters?.statuses?.length > 0;

    const [pageNumber, setPageNumber] = useState(1);
    const [patientsLists, setPatientsList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [statusLoader, setStatusLoader] = useState(null);
    const [openConfirmModal, setConfirmModal] = useState({ isModalOpen: false, status: null, userId: null });
    const [statusFilter, setStatusFilter] = useState(cacheFilters?.statuses || []);
    const [appliedStatusFilters, setAppliedStatusFilters] = useState(cacheFilters?.statuses || []);
    const [sortingList, setSortingList] = useState({
        SortByPatientNameAscending: true,
        SortByPatientEmailAscending: null,
        SortByPatientContactAscending: null,
    });

    let params = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        patientId: null,
        doctorId: profile?.id,
        officeId: officeId,
        searchTerm: searchValue,
        isPatientActive: appliedStatusFilters?.length === 2 ? null : appliedStatusFilters?.length ? appliedStatusFilters[0] === 1 ? true : false : null,
        SortByPatientNameAscending: sortingList?.SortByPatientNameAscending,
        SortByPatientEmailAscending: sortingList?.SortByPatientEmailAscending,
        SortByPatientContactAscending: sortingList?.SortByPatientContactAscending
    }

    const { isLoading, data, refetch } = usePatientList(
        params
    );

    const closeConfirmModal = () => setConfirmModal(null);

    useEffect(() => {
        if (!isLoading && data && data.items) {
            const convertedData = [...data?.items].map((item) => {
                const {
                    id,
                    patient,
                    isPatientActive,
                } = item;
                return {
                    id,
                    patientName: patient?.firstName + ' ' + patient?.lastName,
                    email: patient?.emailId,
                    contact: patient?.contactNumber || '-',
                    status: isPatientActive,
                    patientId: patient?.id
                };
            });
            setPatientsList(convertedData);
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);

    const resetFilter = () => {
        setAppliedStatusFilters([]);
        setStatusFilter([]);
        setStorage(cachePatientsListFilter, {
            doctors: [],
            statuses: []
        });
        setPageNumber(1);
    };

    const handleApplyFilters = () => {
        setAppliedStatusFilters(statusFilter);
        setStorage(cachePatientsListFilter, {
            statuses: statusFilter,
        });
        setPageNumber(1);
    };

    const handleSearchTerm = (e) => {
        setPageNumber(1);
        setSearchValue(e.target.value);
    };

    const handleSort = (keyName, value) => {
        setSortingList(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => {
                acc[key] = key === keyName ? value : null;
                return acc;
            }, {})
        }));
    }


    const handleChangeStatus = async (patientId, status, id) => {
        const isAllowUser = (profile?.isAccountOwnerSetup || (staffDetail?.isDoctor || staffDetail?.isAdmin) )&& isMyPatients;

        try {
            setStatusLoader(true);
            const params = {
                patientId: patientId,
                officeId: isAllowUser ? 0 : officeId,
                doctorId: isAllowUser ? profile?.id : 0,
                isStatus: !status
            }
            const patientStatus = await getPatientStatus(params);
            if (patientStatus && patientStatus?.data?.isStatusMultiple) {
                history.push({
                    pathname: constants.routes.appointment.patientStatus.replace(':officeId', encodeId(officeId)).replace(':patientId', encodeId(patientId)),
                    state: { status, userId: id }
                })
            } else {
                setConfirmModal({
                    isModalOpen: true,
                    status: status,
                    userId: id,
                    patientId: patientId
                });
            }
        } catch (err) {
            setStatusLoader(false);
        } finally {
            setStatusLoader(false);
        }

    }

    const handleActivity = async () => {
        try {
            setStatusLoader(true);
            const statusParams = {
                patientActiveStatusDoctorOfficeId: openConfirmModal?.userId,
                patientId: openConfirmModal?.patientId,
                officeId: 0,
                doctorId: 0,
                patientStatus: !openConfirmModal?.status
            }
            const res = await updatePatientStatus(statusParams);
            handleSuccess(res.message);
            queryClient.invalidateQueries([isMyPatients ? "/appointment/patients" : "/appointment/otherDoctors", params]);
            refetch();
            setConfirmModal(null);
        } catch (err) {
            setStatusLoader(false);
        } finally {
            setStatusLoader(false);
        }
    }

    const laoding = isLoading || statusLoader;

    return {
        data: {
            laoding,
            totalItems,
            pageNumber,
            pageSize,
            patientsLists,
            statusFilter,
            isFilterApply,
            searchValue,
            openConfirmModal
        },
        methods: {
            setPageNumber,
            setStatusFilter,
            resetFilter,
            handleApplyFilters,
            handleSort,
            handleSearchTerm,
            handleChangeStatus,
            closeConfirmModal,
            handleActivity
        }
    }
};

export default usePatientListModule;