import React, { useState } from 'react';
import "../../Scheduler/Scheduler.module.scss";
import { withTranslation } from 'react-i18next';
import { PERFORMANCE_REVIEW_STATUS } from '../../../../constants';

function StatusFilter({ t, setStatusFilter, selectedStatus }) {

    const handleChange = (e, item) => {
        const status = [...selectedStatus];
        const oIndex = status.findIndex(v => +item.id === +v);
        if (e.target.checked) {
            status.push(item.id);
        } else {
            if (oIndex > -1) status.splice(oIndex, 1);
        }
        setStatusFilter(status);
    }
    
    return (
        <ul className={["filter-list"]}>
            {PERFORMANCE_REVIEW_STATUS.map((v, key) => (
                <li key={key}>
                    <div className="ch-checkbox">
                        <label>
                            <input
                                type='checkbox'
                                name='eventfilter'
                                onChange={(e) => handleChange(e, v)} checked={selectedStatus && selectedStatus.length && selectedStatus.includes(v.id)}
                            />
                            <span> {t(`performanceReview.${v.title}`)} </span>
                        </label>
                    </div>
                </li>
            ))}
        </ul>
    );
}
export default withTranslation()(StatusFilter);