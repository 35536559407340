import CustomModal from "components/CustomModal";
import useCustomChannelPreview from "Messenger/hooks/useCustomChannelPreview";
import React, { useEffect } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { withTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import UsersModal from "../Modals/UsersModal";
import styles from "../../../../../accountOwner/pages/ManagerDashboard/Dashboard.module.scss";
import constants from "../../../../../constants";

const CustomChannelPreview = ({ t, isFromDashBoard = false, ...props }) => {
  const {
    channel: currentChannel,
    currentChannelUrl,
    setCurrentChannel,
    currentUser,
    externalTabActive,
    handleProfileBtnClose,
    profileContactInfo,
  } = props;

  const channelPreviewStateData = useCustomChannelPreview({
    setCurrentChannel,
    currentChannel,
    currentUser,
    currentChannelUrl,
    externalTabActive,
    handleProfileBtnClose,
  });
  const { state, updateMethods, otherMethods } = channelPreviewStateData;
  const {
    dropdownEllipsisOpen,
    isDeleteConversationPopupOpen,
    actionInProgress,
    membersModal,
  } = state;
  const { setIsDeleteConversationPopupOpen, setMembersModal } = updateMethods;
  const { toggleEllipsis, getChannelData, deleteChat, handleMembersClick } =
    otherMethods;

  const activeClass = () => {
    return getChannelData().isActive ? "active-channel" : "";
  };

  const isClosed = currentChannel.customType === constants.chat.patient.close;
  useEffect(() => {
    if (currentChannel.url === currentChannelUrl) {
      setCurrentChannel(currentChannel);
    }
  }, [isClosed]);
  return (
    <div
      className={
        isFromDashBoard
          ? styles.dashboard_channel_chat_signal
          : `channel-chat-single ${activeClass()}`
      }
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        if (profileContactInfo) {
          handleProfileBtnClose();
        }
        setCurrentChannel(currentChannel);
      }}
    >
      <div
        className={
          isFromDashBoard ? styles.profile_text_wrapper : "img-content-box"
        }
      >
        <div
          className={
            isFromDashBoard ? styles.dashboard_preview_image : "preview-image"
          }
        >
          <img
            className={isFromDashBoard ? styles.profile_pic : "img-cover"}
            src={getChannelData().channelImage}
            onError={(e) => {
              e.currentTarget.src =
                require("assets/images/staff-default-rounded.png").default;
            }}
            alt=""
          />
        </div>
        <div
          className={
            isFromDashBoard
              ? styles.preview_dashboard_info
              : "preview-member-info"
          }
        >
          <div
            className={
              isFromDashBoard
                ? styles.preview_dashboard_member_name
                : "member-name"
            }
          >
            {getChannelData().channelName}
          </div>
          {!!getChannelData().officeName && (
            <div
              className={
                isFromDashBoard
                  ? styles.preview_dashboard_member_office_name
                  : "member-office-name"
              }
            >
              {getChannelData().officeName}
            </div>
          )}
          <div
            className={
              isFromDashBoard
                ? styles.preview_dashboard_member_last_message
                : "member-last-message"
            }
          >
            <span>{getChannelData().messageText}</span>
          </div>
        </div>
      </div>
      <div className="channel-preview-right">
        {!!currentChannel.unreadMessageCount && (
          <div
            className={
              isFromDashBoard
                ? styles.unread_messages_count
                : "unread-messages-count"
            }
          >
            {currentChannel.unreadMessageCount}
          </div>
        )}
        <div
          className={
            isFromDashBoard
              ? styles.preview_dashboard_date_action_box
              : "date-action-box"
          }
        >
          {isClosed && (
            <p className={styles.closed_btn}>{t("messenger.chatClosed")}</p>
          )}
          <span
            className={isFromDashBoard ? styles.dashboard_date_box : `date-box`}
          >
            {getChannelData().timeToShow}
          </span>
          {!!externalTabActive && (
            <span className={`external-icon`}>
              <img
                src={require("assets/images/mir_externalchat.svg").default}
                alt="external-chat"
              />
            </span>
          )}
          {currentChannel?.myRole !== "operator" && (
            <div className="empty-ellipsis-icon"></div>
          )}
          {currentChannel?.myRole === "operator" && (
            <Dropdown
              isOpen={dropdownEllipsisOpen}
              toggle={toggleEllipsis}
              className={` ${styles.ellipsis_icon} cursor-pointer `}
            >
              {isFromDashBoard ? (
                <DropdownToggle
                  caret={false}
                  tag="div"
                  className="dropdown-btn px-3"
                >
                  <img
                    src={require("assets/images/dots-icon.svg").default}
                    alt="icon"
                  />
                </DropdownToggle>
              ) : (
                <DropdownToggle
                  caret={false}
                  className="dropdown-btn"
                  tag="div"
                >
                  <div className={`ellipsis-icon`}>
                    <ThreeDotsVertical />
                  </div>
                </DropdownToggle>
              )}

              <DropdownMenu right>
                {currentChannel?.myRole === "operator" && (
                  <DropdownItem
                    tag="div"
                    onClick={() => setIsDeleteConversationPopupOpen(true)}
                  >
                    {t("messenger.deleteChat")}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {currentChannel?.memberCount > 2 && (
          <div
            className="total-members link-btn mt-2"
            onClick={handleMembersClick}
          >
            {currentChannel?.memberCount} {t("messenger.members")}
          </div>
        )}
      </div>
      {isDeleteConversationPopupOpen && (
        <CustomModal
          isOpen={isDeleteConversationPopupOpen}
          setIsOpen={setIsDeleteConversationPopupOpen}
          onConfirm={deleteChat}
          title={
            currentChannel?.memberCount > 2
              ? t("messenger.deleteGroupChat")
              : t("messenger.deleteSingleChat")
          }
          subTitle1={
            currentChannel?.memberCount > 2
              ? t("messenger.deleteGroupChatDesc")
              : t("messenger.deleteSingleChatDesc")
          }
          actionInProgress={actionInProgress}
          leftBtnText={t("delete")}
          rightBtnText={t("cancel")}
        />
      )}
      {membersModal && (
        <UsersModal
          users={getChannelData().groupMembers}
          isOpen={membersModal}
          onClose={() => setMembersModal(false)}
        />
      )}
    </div>
  );
};

export default withTranslation()(CustomChannelPreview);
