import React from 'react';
import StatusFilter from './StatusFilter';
import PriorityFilter from "./Priority";
import styles from "../TaskManagement.module.scss";
import SortingFilter from './SortingFilter';

const HeaderFilter = ({ setSortBy, sortBy, priority, setPriority, status, setStatus }) => {
  return (
    <div className={styles["dropdown_radio_wrapper"]}>
      <StatusFilter status={status} setStatus={setStatus} />
      <SortingFilter setSortBy={setSortBy} sortBy={sortBy} />
      <PriorityFilter priorityFilter={priority} setPriority={setPriority} />
    </div>
  )
}

export default HeaderFilter