import { useState, useEffect } from 'react';
import constants from "../../../../constants";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { appointmentDetailActions } from 'repositories/patient-appointment-repository';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import {getStorage, setStorage } from 'utils';


const defaultCurrentDate = moment()?.toDate();

const currentUtcDate = moment()?.utc()?.format('YYYY-MM-DDTHH:mm:ss');


const pageSize = 10;
const useTodaysAppointmetModule = (useAllAppointments, state) => {
    
    const cacheFilters = getStorage(constants.appointmentList.cache.todaysFilter) || {};
    const isFilterApply = cacheFilters?.offices?.length > 0  || cacheFilters?.doctors?.length > 0 ;
    const profile = useSelector(state => state.userProfile.profile);
    const [pageNumber, setPageNumber] = useState(1);
    const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(cacheFilters?.offices || []);
    const [appliedDocotorFilters, setAppliedDoctorFilters] = useState(cacheFilters?.doctors || []);
    const [appointments, setAppointments] = useState([]);
    const [officeFilter, setOfficeFilter] = useState(cacheFilters?.offices || []);
    const [doctorFilter, setDocotrFilter] = useState(cacheFilters?.doctors || []);
    const [totalItems, setTotalItems] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [currentDates, setCurrentDates] = useState(defaultCurrentDate);
    const [currentUtcdates, setCurrentUtcdates] = useState(currentUtcDate);
    const queryClient = useQueryClient();

    let params = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        doctorId: profile?.id,
        officeIds: appliedOfficeFilters,
        date : currentUtcdates
    }
 
    const { isLoading, data, refetch } = useAllAppointments(
        params
    );
   
    useEffect(() => {
        if (!isLoading && data && data.items) {
            setAppointments([...data?.items]);
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);


    const appointmentDetailAction = async (params) => {
        setShowLoader(true);
        try {
            let res = await appointmentDetailActions(params);
            if (res?.statusCode === 200) {
                setShowLoader(false);
                toast.success(res.message);
                queryClient.invalidateQueries(["/Doctor/Schedules", params]);
                refetch();
            }
            return res
        } catch (err) {
            setShowLoader(false);
            toast.error(err.message);
            return { status: false }
        }
    }


    const handleApplyFilters = () => {
        setAppliedOfficeFilters(officeFilter);
        setAppliedDoctorFilters(doctorFilter);

        setStorage(constants.appointmentList.cache.todaysFilter, {
            offices: officeFilter,
            doctors : doctorFilter
        });
        setPageNumber(1);
    };

    const resetFilter = () => {
        setPageNumber(1);
        setAppliedOfficeFilters([]);
        setAppliedDoctorFilters([]);
        setOfficeFilter([]);
        setDocotrFilter([]);
        setStorage(constants.appointmentList.cache.todaysFilter, {
            offices: [],
            statuses: [],
            recallType :[],
            doctors : []
        });
        queryClient.invalidateQueries(["/Doctor/Schedules", params]);
        refetch();
    };

    const updateCurrentDateValue = (e) => {
        setCurrentDates(e);
        setCurrentUtcdates(moment.utc(e).local().format("YYYY-MM-DDTHH:mm:ss"));
    }

    return {
        data: {
            isLoading,
            totalItems,
            pageNumber,
            appointments,
            showLoader,
            officeFilter,
            isFilterApply,
            doctorFilter,
            currentDates
        },
        methods: {
            setPageNumber,
            appointmentDetailAction,
            setOfficeFilter,
            handleApplyFilters,
            resetFilter,
            setDocotrFilter,
            updateCurrentDateValue
        }
    }
}

export default useTodaysAppointmetModule;