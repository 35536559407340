import { getFullName } from "Messenger/pages/TeamConversation/utils";
import { SendBirdProvider } from "@sendbird/uikit-react/.";
import constants from "../../constants";
import DirectCallMain from "Messenger/calling/DirectCallMain/DirectCallMain";
import SbCallsProvider from "Messenger/calling/sendbird-calls/SbCallsContext/provider";
import { useSelector } from "react-redux";
import { useMemo } from "react";
export const chatNotEnabled = [
  constants.systemRoles.superAdmin,
  constants.systemRoles.vendor,
  constants.systemRoles.staff,
];
const SenbirdChatCallWrapper = ({ children, t }) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const incomingCall = useMemo(() => {
    return profile?.role?.systemRole === constants.systemRoles.patient;
  }, [profile?.role?.systemRole]);

  if (
    !profile?.id ||
    !profile?.role?.systemRole ||
    (chatNotEnabled.includes(profile?.role?.systemRole) &&
      !profile?.isPatientChat &&
      !profile?.isMessenger)
  ) {
    return children;
  }
  return (
    <SendBirdProvider
      appId={process.env.REACT_APP_SENDBIRD_APP_ID}
      userId={`${profile.id}`}
      nickname={getFullName(profile)}
      profileUrl={profile.profilePic || null}
      isMentionEnabled={false}
      isReactionEnabled={false}
    >
      <SbCallsProvider t={t}>
        {incomingCall && <DirectCallMain dial={false} />}
        {children}
      </SbCallsProvider>
    </SendBirdProvider>
  );
};
export default SenbirdChatCallWrapper;
