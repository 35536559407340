import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Table from "components/table";
import SortDown from "../../../../assets/images/pms-icons/sort_down.svg";
import SortUp from "../../../../assets/images/pms-icons/sort_up.svg";
import constants from "../../../../constants";
import styles from "../Appointments.module.scss";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { encodeId, setStorage } from "utils";
import { ThreeDotsVertical } from "react-bootstrap-icons";

function PatientListTable({
  t,
  officeId,
  waitingLists,
  totalItems,
  pageNumber,
  pageSize,
  setPageNumber,
  handleSort,
  handleChangeStatus,
}) {
  const handleReminderClick = (row) => {
    history.push(
      history.push(
        constants.routes.appointment.globalReminder
          .replace(":officeId", officeId)
          .replace(":patientId", encodeId(row?.patientId))
      )
    );
    setStorage("isRecallReminderPlan", {
      isRecallReminderPlan: false,
    });
  };

  const handleRecallClick = (row) => {
    history.push(
      history.push({
        pathname: constants.routes.appointment.globalReminder
          .replace(":officeId", officeId)
          .replace(":patientId", encodeId(row?.patientId)),
        state: { isRecallReminderPlan: true },
      })
    );
    setStorage("isRecallReminderPlan", {
      isRecallReminderPlan: true,
    });
  };

  const redirectMessageHistory = (e, row) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }

    history.push(
      constants.routes.appointment.patientMessageCenter
        .replace(":officeId", officeId)
        .replace(
          "/:patientId?",
          row?.patientId ? `/${encodeId(row?.patientId)}` : ""
        )
        .replace("/:appointmentId?", "")
    );
  };

  const history = useHistory();
  const columns = [
    {
      attrs: { datatitle: t("patients.patientName") },
      dataField: "patientName",
      text: t("patients.patientName"),
      formatter: (cellContent, row, rowIndex) => (
        <Link
          className={styles["appointment-table-row-main-link"]}
          to={constants.routes.appointment.patientDetail
            .replace(":officeId", officeId)
            .replace(":patientId", encodeId(row?.patientId))}
        >
          <span className={styles["appointment-table-patient-name"]}>
            {row.patientName}{" "}
          </span>
        </Link>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span
              onClick={() => handleSort("SortByPatientNameAscending", true)}
            >
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span
              onClick={() => handleSort("SortByPatientNameAscending", false)}
            >
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("patients.emailAddress") },
      dataField: "email",
      text: t("patients.emailAddress"),
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row.email}
        </span>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span
              onClick={() => handleSort("SortByPatientEmailAscending", true)}
            >
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span
              onClick={() => handleSort("SortByPatientEmailAscending", false)}
            >
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("patients.contactNo") },
      dataField: "contact",
      text: t("patients.contactNo"),
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row.contact}
        </span>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span
              onClick={() => handleSort("SortByPatientContactAscending", true)}
            >
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span
              onClick={() => handleSort("SortByPatientContactAscending", false)}
            >
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("patients.status") },
      dataField: "status",
      text: t("patients.status"),
      formatter: (cellContent, row, rowIndex) => (
        <Button
          className={`appointment-table-custom-btn ${
            row.status ? "green-confirmed-btn" : "red-pending-btn"
          }`}
        >
          <span>
            {row.status
              ? t("patients.active").toUpperCase()
              : t("patients.inactive").toUpperCase()}
          </span>
        </Button>
      ),
    },

    {
      attrs: { datatitle: t("patients.action") },
      dataField: "action",
      text: t("patients.action"),
      formatter: (cellContent, row, rowIndex) => (
        <UncontrolledDropdown>
          <DropdownToggle caret={false} tag="div" className="cursor-pointer">
            <ThreeDotsVertical />
          </DropdownToggle>
          <DropdownMenu right className={styles["office-dropdown-menu"]}>
            <DropdownItem className={styles["office-dropdown-item"]}>
              <div
                onClick={() =>
                  history.push(
                    constants.routes.appointment.patientNotes
                      .replace(":officeId", officeId)
                      .replace(":patientId", encodeId(row?.patientId))
                  )
                }
              >
                {t("patients.patientNote")}
              </div>
            </DropdownItem>
            {!row?.status ? (
              <DropdownItem className={styles["office-dropdown-item"]}>
                <div
                  onClick={() => {
                    handleChangeStatus(row?.patientId, row?.status, row?.id);
                  }}
                >
                  {t("patients.markAsActive")}
                </div>
              </DropdownItem>
            ) : (
              <DropdownItem className={styles["office-dropdown-item"]}>
                <div
                  onClick={() => {
                    handleChangeStatus(row?.patientId, row?.status, row?.id);
                  }}
                >
                  {t("patients.markAsInactive")}
                </div>
              </DropdownItem>
            )}
            <DropdownItem className={styles["office-dropdown-item"]}>
              <div onClick={(e) => redirectMessageHistory(e, row)}>
                {t("patients.viewMessageHistory")}
              </div>
            </DropdownItem>
            <DropdownItem className={styles["office-dropdown-item"]}>
              <div onClick={() => handleReminderClick(row)}>
                {t("Appointment Reminder Plan")}
              </div>
            </DropdownItem>
            <DropdownItem className={styles["office-dropdown-item"]}>
              <div onClick={() => handleRecallClick(row)}>
                {t("Recall Reminder Plan")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <div className={styles["appointment-table-box"] + " " + "w-100"}>
      <div className="test-table-box">
        <Table
          keyField="id"
          data={waitingLists}
          columns={columns}
          bordered={false}
          handlePagination={setPageNumber}
          pageNumber={pageNumber}
          totalItems={totalItems}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
}
export default withTranslation()(PatientListTable);
