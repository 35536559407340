import React, { useState, Suspense } from "react";
import Card from "components/Card";
import Page from "components/Page";
import Button from "components/Button";
import Loader from "components/Loader";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import InitiatedByYou from "./InitiatedByYou/InitiatedByYou";
import constants from "../../../constants";
import editProfileWarningIcon from "../../../assets/images/info-icon.png";
import styles from "./PerformanceReview.module.scss";
import { decodeId, getStorage, setStorage } from "utils";
import { useOfficeById, useOfficeDetail } from "repositories/office-repository";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InitiatedByAdmin from "./InitiatedByAdmin/InitiatedByAdmin";
import Text from "components/Text";
import { useMutation } from "react-query";
import { disablePerformanceReview } from "repositories/performance-review-repository";

function PerformanceReview({ t, history }) {
  const cachedActiveTab = getStorage(
    constants.performanceReview.cache.performanceReviewlisting
  );
  const cacheActiveArchived = getStorage(
    constants.performanceReview.cache.performanceArchivedListing
  );
  const profile = useSelector((state) => state.userProfile.profile);
  let { officeId } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();
  const [showDontMeassage, setDontMessage] = useState(
    profile?.showPerformanceReviewTemplateMessage
  );

  const { mutate: disablePreviewForm } = useMutation(disablePerformanceReview, {
    onSuccess: () => {
      dispatch({
        type: "PROFILE",
        payload: { ...profile, showPerformanceReviewTemplateMessage: false },
      });
      setDontMessage(false);
    },
    onError: (error) => {
      console.error("Update profile failed: ", error);
    },
  });

  const decodeOfficeId = parseInt(decodeId(officeId));
  const { data: officeData } = useOfficeById(decodeOfficeId);

  const isMyPerformanceReview = location?.pathname.includes(
    "my-performance-review"
  );

  const { data: officeDetail } = useOfficeDetail(decodeOfficeId);

  const [activeTab, setActiveTab] = useState(
    cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1
  );
  const [isArchive, setArchive] = useState(
    cacheActiveArchived?.isArchived ? cacheActiveArchived.isArchived : false
  );

  const tabName = constants.performanceReviewTab;

  const manageTab = (tab) => {
    window.scrollTo(0, 0);
    setActiveTab(tab);
    setStorage(constants.performanceReview.cache.performanceReviewlisting, {
      activeTab: tab,
    });
  };

  const goBack = () => {
    const backToPrevious =
      profile?.role?.systemRole === constants.systemRoles.staff
        ? constants.routes.staff.officeOptions
        : constants.routes.accountOwner.officeOptions;
    history.push({
      pathname: backToPrevious.replace(":officeId", officeId),
      state: {
        officeData,
      },
    });
  };

  return (
    <div className="scheduler-page">
      {!isMyPerformanceReview && showDontMeassage && (
        <div className={styles.text_link_wrapper}>
          <div className={styles.image_text_link}>
            <img
              className="edit-profile-warning-icon"
              src={editProfileWarningIcon}
              alt="warning icon"
            />
            <div className={styles.text_wrap}>
              <div className={styles.text_link}>
                <p className={styles.p_tag}>
                  {t("performanceReview.whatNew")}
                  <Link
                    className={styles.link_text}
                    to={constants.routes.accountOwner.previewReviewForm}
                  >
                    {" " + t("performanceReview.previewForm")}
                  </Link>
                </p>
              </div>
              <div onClick={() => disablePreviewForm()}>
                <Text className={`${styles.link_text} cursor-pointer`}>
                  {t("performanceReview.dontShowMsg")}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
      <Page onBack={goBack}>
        <div className={styles.btn_title_wrapper}>
          <div>
            <p className={styles.title}>{officeDetail?.name}</p>
            <p className={styles.sub_title}>
              {isArchive
                ? t("performanceReview.archivedPerformaceReview")
                : t("performanceReview.onGoingperformanceReview")}
            </p>
          </div>
          <div className={styles.home_btn_wrapper}>
            <Button
              className="w-sm-100"
              borderedSecondary
              bgColor="#F2F3EF"
              onClick={() => {
                setArchive(!isArchive);
                setStorage(
                  constants.performanceReview.cache.performanceArchivedListing,
                  {
                    isArchived: !isArchive,
                  }
                );
              }}
            >
              {!isArchive
                ? t("performanceReview.goToArchive")
                : t("performanceReview.ongoingReview")}
            </Button>
            {!isMyPerformanceReview && (
              <Link
                to={constants.routes.accountOwner.addNewPerformanceReview.replace(
                  ":officeId",
                  officeId
                )}
                className="text-decoration-none w-sm-100"
              >
                <Button className="w-sm-100">
                  {t("performanceReview.addNewReview")}
                </Button>
              </Link>
            )}
          </div>
        </div>
        <Card className={styles.performance_review_card}>
          <div className="common-tabs scheduler-tabs">
            {!isMyPerformanceReview && !profile?.isAdmin && (
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={
                      activeTab == tabName.initiatedByYou ? "active" : ""
                    }
                    onClick={() => manageTab(tabName.initiatedByYou)}
                  >
                    {t("performanceReview.initiatedByYou")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      activeTab == tabName.initiatedByAdmin ? "active" : ""
                    }
                    onClick={() => manageTab(tabName.initiatedByAdmin)}
                  >
                    {t("performanceReview.initiatedByAdmin")}
                  </NavLink>
                </NavItem>
              </Nav>
            )}
            <TabContent activeTab={activeTab}>
              <Suspense fallback={<Loader />}>
                <TabPane tabId={1}>
                  {activeTab === tabName.initiatedByYou && officeDetail?.id && (
                    <InitiatedByYou
                      officeId={officeDetail?.id}
                      isArchive={isArchive}
                      isMyPerformanceReview={isMyPerformanceReview}
                      officeData={location?.state?.officeData}
                    />
                  )}
                </TabPane>
                <TabPane tabId={2}>
                  {activeTab === tabName.initiatedByAdmin &&
                    officeDetail?.id && (
                      <InitiatedByAdmin
                        officeId={officeDetail?.id}
                        isArchive={isArchive}
                        isMyPerformanceReview={isMyPerformanceReview}
                      />
                    )}
                </TabPane>
              </Suspense>
            </TabContent>
          </div>
        </Card>
      </Page>
    </div>
  );
}

export default withTranslation()(PerformanceReview);
