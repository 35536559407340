import Page from "components/Page";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Suspense } from "react";
import Loader from "components/Loader";
import styles from "../Appointments.module.scss";
import alertImage from "../../../../assets/images/alert-circle-black.svg";
import crossIcon from "../../../../assets/images/mir_cross grey.svg"
import Text from 'components/Text';
import constants from "../../../../constants";
import { decodeId, getStorage, setStorage } from "utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PatientList from "./PatientList";
import Card from "components/Card";
import { useOfficeDetail, useStaffByUserId } from "repositories/office-repository";
import { useSelector } from "react-redux";

const Patients = ({ t, history }) => {
    const cachedActiveTab = getStorage(constants.patientsList.cache.patientList);
    let { officeId } = useParams();
    const decodeOfficeId = parseInt(decodeId(officeId));
    const profile = useSelector(state => state.userProfile.profile);
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);
    const { isLoading: isLoadingStaffDetail, data: staffDetail } = useStaffByUserId(decodeOfficeId, profile?.id);
    const [activeTab, setActiveTab] = useState(cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : (staffDetail?.isAdmin || profile?.isAccountOwnerSetup) ? 1 : 2);

    const tabName = constants.patientsTab;

    const goToPreviousScreen = () => {
        if(profile?.role?.systemRole === constants.systemRoles.accountOwner) {
            history.push(constants.routes.accountOwner.officeOptions.replace(":officeId", officeId));
        }
        else if(staffDetail?.isAdmin) {
            history.push(constants.routes.staff.officeAdmin.replace(":officeId",officeId))
        } else {
            history.push(constants.routes.staff.officeOptions.replace(":officeId", officeId));
        }
    };

    const manageTab = (tab) => {
        window.scrollTo(0, 0);
        setActiveTab(tab);
        setStorage(constants.patientsList.cache.eventlisting, {
            activeTab: tab,
        });
    };

    return (
        <Page className="scheduler-page" onBack={goToPreviousScreen} title={officeDetail?.name}>
            {(isLoadingOfficeDetail || isLoadingStaffDetail) && <Loader />}
            <div className={styles["first-row-top"]}>
                <div className={styles["first-row"]}>
                    <div className={styles["first-row-text-part"]}>
                        <p > {t("patients.patients")}</p>
                    </div>

                    <div className={styles['yellow-warning-box'] + " " + "d-flex"}>
                        <img src={alertImage} alt="blackAlertImage" />
                        <Text size="14px" weight="400" color="#2F3245" >{t('patientAppointmentMembers.appointmentReminderPlanText')}</Text>
                        <img src={crossIcon} alt="blackCrossImage" />
                    </div>
                </div>
            </div>
            <Card>
                <div className="common-tabs scheduler-tabs">
                    {(staffDetail?.isAdmin || profile?.isAccountOwnerSetup) && <Nav tabs >
                        <NavItem >
                            <NavLink
                                className={
                                    activeTab == tabName.myPatients ? "active" : ''
                                }
                                onClick={() => manageTab(1)}
                            >
                                {t("patients.myPatients")}
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink
                                className={
                                    activeTab == tabName.otherDoctors ? "active" : ''
                                }
                                onClick={() => manageTab(2)}
                            >
                                {t("patients.otherDoctors")}
                            </NavLink>
                        </NavItem>
                    </Nav>}
                    <TabContent activeTab={activeTab}>
                        <Suspense fallback={<Loader />}>
                            {(staffDetail?.isAdmin || profile?.isAccountOwnerSetup) && <TabPane tabId={1}>
                                {activeTab === tabName.myPatients && <PatientList isMyPatients={true} staffDetail={staffDetail}/>}
                            </TabPane>}
                            <TabPane tabId={2}>
                                {activeTab === tabName.otherDoctors && <PatientList isMyPatients={false} staffDetail={staffDetail}/>}
                            </TabPane>
                        </Suspense>
                    </TabContent>
                </div>
            </Card>
        </Page>
    );
};
export default withTranslation()(Patients);
