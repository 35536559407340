import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Text from "components/Text";
import WhiteLogo from "assets/images/mir_white_logo.svg";
import VideoCallIcon from "assets/images/incoming_video_icon.svg";
import AudioCallIcon from "assets/images/ico_audio_call.svg";
import { handleError } from "utils";

export default function IncomingCall({ call }) {
  const { localMediaView, isVideoCall, remoteMediaView, callState } = call;
  const [showCall, setShowCAll] = useState(true);
  const accept = useCallback(async () => {
    const microphoneRespo = await navigator.permissions.query({
      name: "microphone",
    });
    const microphoneEnable = microphoneRespo.state === "denied";
    if (microphoneEnable && !isVideoCall) {
      return handleError(new Error("Please enable your microphone"));
    }
    if (isVideoCall) {
      const cameraRespo = await navigator.permissions.query({
        name: "camera",
      });
      if (cameraRespo.state === "denied") {
        return handleError(
          new Error(
            `Please enable your camera${microphoneEnable ? "/microphone" : ""}`
          )
        );
      }
    }
    call.accept({
      callOption: {
        localMediaView,
        remoteMediaView,
        audioEnabled: true,
        videoEnabled: true,
      },
    });
  }, [localMediaView, remoteMediaView]);
  useEffect(() => {
    setTimeout(() => {
      setShowCAll(false);
    }, 2000);
  }, []);
  if (showCall || callState !== "ringing") return null;
  return (
    <IncomingCallContainer>
      <MiraxisLogo src={WhiteLogo} alt="mir-white-logo" />
      <Text size="18px" weight="600" color="#FFFFFF">
        Dr. {call?.caller?.nickname}
      </Text>
      <VideoIconTextWrapper>
        <VideoIcon
          src={isVideoCall ? VideoCallIcon : AudioCallIcon}
          alt="video-white-logo"
        />
        <Text size="12px" weight="400" color="#FFFFFF">
          Incoming {isVideoCall ? "video" : "audio"} call...
        </Text>
      </VideoIconTextWrapper>
      <RejectAnswerButtonContainer>
        <RejectButton
          onClick={() => {
            call.end();
          }}
        >
          Reject
        </RejectButton>
        <AnswerButton onClick={accept}>Answer</AnswerButton>
      </RejectAnswerButtonContainer>
    </IncomingCallContainer>
  );
}

const IncomingCallContainer = styled.div`
  background-color: #2d3245;
  padding: 24px;
  border-radius: 10px;
  max-width: 320px;
  min-width: 320px;
  width: 100%;
  position: fixed;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;
`;

const MiraxisLogo = styled.img`
  max-width: 36px;
  max-height: 6px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const VideoIconTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

const VideoIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const RejectAnswerButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 34px;
  width: 100%;
`;

const RejectButton = styled.button`
  background-color: #ee4f4f;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 26px;
  cursor: pointer;
  width: 50%;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
`;

const AnswerButton = styled.button`
  background-color: #a9cf3d;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 26px;
  cursor: pointer;
  width: 50%;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
`;
