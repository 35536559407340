import React, { useContext, useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Text from "components/Text";
import SchedulerCalendar from "accountOwner/pages/Scheduler/components/SchedulerCalendar";
import styles from "../../Appointments.module.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId, encodeId, getStorage, setStorage } from "utils";
import { AppointmentsContext } from "../../AppointmentsContext";
import { useSelector } from "react-redux";
import constants from "../../../../../constants";
import moment from "moment";
import Timeslot from "./Timeslot";
import BlockTimeSlot from "../../modal/BlockTimeSlot";
import UnBlockTimeSlot from "../../modal/UnBlockTimeSlot";
import Loader from "components/Loader";
import useCommonModule from "../../hooks/useCommonModule";
import RejectionModal from "../../modal/RejectionModal";
import ConfirmationModal from "../../modal/ConfirmationModal";
import usePatientAppointmentDetailModule from "../../hooks/usePatientAppointmentDetailModule";
import ShowBlockReason from "../../modal/ShowBlockReasonModal";
import AppointmentDetailModal from "../../modal/AppointmentDetailModal";
import { useOfficeDetail } from "repositories/office-repository";

const ManageUrbanDental = ({ t }) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const [modalOpen, setModalOpen] = useState(false);
  const [blockReason, setBlockReason] = useState("");
  const [appointmentDetail, setAppointmentdetails] = useState({});
  const [confirmBlocked, setconfirmBlocked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [unBlocked, setUnBlocked] = useState(false);
  const [apptDetails, setApptDetails] = useState(false);
  const [errors, setErrors] = useState([]);
  let { officeId } = useParams();
  officeId = decodeId(officeId);
  const history = useHistory();
  const officeState = getStorage("officeState");

  const {
    methods: { appointmentDetailAction },
  } = usePatientAppointmentDetailModule();
  const {
    data: { isRejectModal, isConfirmModal, isCancelModal },
    methods: {
      handleRejectToggle,
      handleConfirmToggle,
      handleCancelToggle,
      getMenuoptionsTwo,
    },
  } = useCommonModule(history, t);

  const { data: OfficeData } = useOfficeDetail(officeId);

  const menuOptions = getMenuoptionsTwo(appointmentDetail);
  const {
    selectedSlotDate,
    appointmentSlots,
    setSlotDate,
    setAppointmentSlots,
  } = useContext(AppointmentsContext);
  const startTime = new Date();
  startTime.setHours(9, 30, 0);

  const goBack = () => {
    if (officeState?.officeState?.officeId) {
      history.push(constants.routes.appointment.appointmentScreen);
    } else {
      history.push(constants.routes.appointment.manageSchedule);
    }
  };

  const handleredirectToAddNewAppointment = () => {
    history.push(`/patient-new-appointment/${encodeId(officeId)}`);
    setStorage("isFromManagePage", {
      isFromManagePage: true,
    });
  };

  useEffect(() => {
    setStorage("isFromManagePage", {
      isFromManagePage: true,
    });
  }, []);

  const onActiveStartDateChange = (value) => {
    if (
      constants.calanderActions.includes(value?.action) ||
      (value?.action === constants.calanderActionKey.drillDown &&
        (value?.view === "month" || value?.view === "year"))
    ) {
      value?.activeStartDate && setSlotDate(value.activeStartDate);
    }
  };

  const handleApply = async () => {
    const params = {
      appointmentId: appointmentDetail?.id,
      status: 7,
      message: "",
    };

    let res = await appointmentDetailAction(params);
    if (res?.status) {
      handleConfirmToggle();
    }
  };

  const handleToggle = (e, option) => {
    setApptDetails(false);
    option?.onclick(e, appointmentDetail);
  };

  useEffect(() => {
    setAppointmentSlots([]);
  }, [isCancelModal, isRejectModal, isConfirmModal]);

  return (
    <Page onBack={goBack} className={styles["main-page"]}>
      {showLoader && <Loader />}
      <div
        className={
          styles["manage-schedule-heading"] +
          " " +
          "d-flex justify-content-between"
        }
      >
        <div>
          <p className={styles["mobile-text-size"] + " " + "mb-0"}>
            {t("patientAppointmentMembers.manageAvailabilityofPatient")}
          </p>
          <span className={styles["select-office"]}>{OfficeData?.name}</span>
        </div>
        <Link to={`/track-schedule-change/${encodeId(officeId)}`}>
          <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100">
            {t("patientAppointmentMembers.trackScheduleChanges")}
          </button>
        </Link>
      </div>
      <Card className="form-wrapper">
        <div className={styles["content-part"]}>
          <div>
            {/* <SchedulerCalendar /> */}
            <SchedulerCalendar
              value={selectedSlotDate}
              onChange={setSlotDate}
              onActiveStartDateChange={onActiveStartDateChange}
            />
            <div
              className={styles["scheduler-calendar-timezone-separator"]}
            ></div>
            <Text size="12px" weight="400" color="#6F7788">
              {t("patient.timezone")}
            </Text>
            <Text size="14px" weight="600" color="#102C42" className="mb-4">
              {t("patientAppointmentMembers.pst")}
            </Text>
          </div>
          <div className="w-100">
            <div className={styles["book-slot"] + " " + "d-flex flex-column"}>
              <Text
                color="#587E85"
                weight="600"
                size="16px"
                className={styles["book-slot-heading"]}
              >
                {t("patientAppointmentMembers.bookingTimeslot")}
              </Text>
              <div>
                <Text
                  color="#6F7788"
                  weight="400"
                  size="12px"
                  className={styles["timeslot-text"]}
                >
                  {t(
                    "patientAppointmentMembers.SelectTimeslotsAndThenChooseIfYouWantToBlockIt"
                  )}
                </Text>
              </div>
              <div className={styles["notify-checkbox"] + " " + " d-flex "}>
                <div
                  className={
                    styles["checkbox-container"] + " " + " d-flex flex-row "
                  }
                >
                  <div
                    className={styles["show-checkedbox"] + " " + styles["box1"]}
                  ></div>
                  {t("patientAppointmentMembers.booked")}
                </div>
                <div
                  className={
                    styles["checkbox-container"] + " " + " d-flex flex-row"
                  }
                >
                  <div
                    className={styles["show-checkedbox"] + " " + styles["box2"]}
                  ></div>
                  {t("patientAppointmentMembers.available")}
                </div>
                <div
                  className={
                    styles["checkbox-container"] + " " + "d-flex flex-row"
                  }
                >
                  <div
                    className={styles["show-checkedbox"] + " " + styles["box3"]}
                  ></div>
                  {t("patientAppointmentMembers.blocked")}
                </div>
              </div>
              <div
                className={
                  styles["show-time-box"] + " " + "d-flex flex-wrap w-100"
                }
              >
                <Timeslot
                  t={t}
                  officeId={officeId}
                  date={moment(selectedSlotDate).format("YYYY-MM-DD")}
                  doctorId={profile?.id}
                  setSelectedSlots={setAppointmentSlots}
                  selectedSlots={appointmentSlots}
                  errors={errors}
                  setconfirmBlocked={setconfirmBlocked}
                  setBlockReason={setBlockReason}
                  setAppointmentdetails={setAppointmentdetails}
                  setApptDetails={setApptDetails}
                  setShowLoader={setShowLoader}
                  apptDetails={apptDetails}
                />
              </div>
            </div>
            {appointmentSlots?.length && !blockReason ? (
              <div className={styles["common-appointment-btn-container"]}>
                <button
                  className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                  onClick={handleredirectToAddNewAppointment}
                >
                  {t("patientAppointmentMembers.addPatientAppointment")}
                </button>

                <button
                  className="button button-round button-border button-dark btn-mobile-link"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  {t("accountOwner.blockSlot")}
                </button>
              </div>
            ) : null}
            {appointmentSlots?.length && blockReason ? (
              <button
                className="button button-round button-shadow  w-100 "
                onClick={() => setUnBlocked(!unBlocked)}
              >
                {t("patientAppointmentMembers.reassignUnblockTimeslot")}
              </button>
            ) : null}
          </div>
        </div>
      </Card>

      {modalOpen && (
        <BlockTimeSlot
          t={t}
          blockReason={blockReason}
          setBlockReason={setBlockReason}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          officeId={officeId}
          selectedSlotDate={selectedSlotDate}
          doctorId={profile?.id}
          selectedSlots={appointmentSlots}
          setSelectedSlots={setAppointmentSlots}
        />
      )}
      {unBlocked && (
        <UnBlockTimeSlot
          t={t}
          setUnBlocked={setUnBlocked}
          unBlocked={unBlocked}
          officeId={officeId}
          selectedSlotDate={selectedSlotDate}
          doctorId={profile?.id}
          selectedSlots={appointmentSlots}
          setSelectedSlots={setAppointmentSlots}
          setBlockReason={setBlockReason}
        />
      )}
      {confirmBlocked && (
        <ShowBlockReason
          t={t}
          setconfirmBlocked={setconfirmBlocked}
          confirmBlocked={confirmBlocked}
          blockReason={blockReason}
        />
      )}
      {apptDetails && (
        <AppointmentDetailModal
          t={t}
          setApptDetails={setApptDetails}
          apptDetails={apptDetails}
          appointmentDetail={appointmentDetail}
          menuOptions={menuOptions}
          handleToggle={handleToggle}
        />
      )}

      {isRejectModal && (
        <RejectionModal
          showLoader={showLoader}
          isRejectionModalOpen={isRejectModal}
          setIsRejectionModalOpen={handleRejectToggle}
          confirmReject={appointmentDetailAction}
          isCancel={handleRejectToggle}
          appointmentID={appointmentDetail?.id}
          actionStatus={3}
          title={t("patientAppointmentMembers.reasonOfRejection")}
          isFromDetail={true}
        />
      )}
      {isCancelModal && (
        <RejectionModal
          showLoader={showLoader}
          isRejectionModalOpen={isCancelModal}
          setIsRejectionModalOpen={handleCancelToggle}
          confirmReject={appointmentDetailAction}
          isCancel={handleCancelToggle}
          appointmentID={appointmentDetail?.id}
          actionStatus={4}
          title={t("patientAppointmentMembers.reasonOfCancellation")}
          isFromDetail={true}
        />
      )}
      {isConfirmModal && (
        <ConfirmationModal
          closeModal={handleConfirmToggle}
          isModalOpen={isConfirmModal}
          handleApply={handleApply}
          showLoader={showLoader}
          message={t("patientAppointmentMembers.confirmationMsg")}
          isFromDetail={true}
        />
      )}
    </Page>
  );
};
export default withTranslation()(ManageUrbanDental);
