import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createNewAppointment, useDefultAppointmentReminder } from 'repositories/patient-appointment-repository';
import { decodeId, encodeId, getStorage } from "utils";
import constants from "../../../../constants";
import { useAllPatient, useDoctorOffices } from 'repositories/doctor-repository';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useOfficeDetail } from 'repositories/office-repository';
import { AppointmentsContext } from '../AppointmentsContext';
import { useQueryClient } from 'react-query';


const useCreatePatientAppointmentModule = (keyName) => {
    const profile = useSelector(state => state.userProfile.profile);
    const [repeatOptions, setRepeatOptions] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [searchTerm, setSearchterm] = useState("");
    const [selectedOfficeData, setSelectedOfficeData] = useState("");
    const history = useHistory();
    let { officeId } = useParams();
    officeId = decodeId(officeId);
    const [errors, setErrors] = useState({});
    const isFromManagePage = getStorage("isFromManagePage");
    const queryClient = useQueryClient();


    const { data: DoctorOfficeData, isLoading: doctorOfficeLoading } = useDoctorOffices(profile?.id, 1, 100);

    const { data: patientData, isLoading: patientDataLoading } = useAllPatient(1, 100, searchTerm);

    const { isLoading : officeLoading, data : OfficeData } = useOfficeDetail(officeId);
    const {
           reminderPlan,
           setReminderPlan,
           selectedPatient
    } = useContext(AppointmentsContext);
   let IsForRecall = false;
   const { isLoading: defaultAppointmentLoading, refetch: defaultAppointmentFetch } = useDefultAppointmentReminder(selectedOfficeData?.id, selectedPatient?.id, IsForRecall, {
            enabled: !!( selectedOfficeData?.id && selectedPatient?.id),
    });


    useEffect(() => {
        const options = DoctorOfficeData?.items?.map(item => ({
            id: item.id,
            name: item?.name
        }));
        setRepeatOptions(options);
    }, [DoctorOfficeData])


    const goToPreviousScreen = () => {
        if(isFromManagePage?.isFromManagePage) {
            history.push(constants.routes.appointment.officeSelection.replace(":officeId", encodeId(officeId)));
        } else {
            history.push(constants.routes.appointment.appointmentScreen);
        }
    };

    const isValidEvent = (params) => {
        const errorsData = { ...errors };
       
        if ((params?.officeId===1)) {
            errorsData.OfficeError = 'Please Select Office';
        }
        else {
            delete errorsData.OfficeError;
        }

        if (!params?.patientId) {
            errorsData.patientError = 'Please Select patient';
        } else {
            delete errorsData.patientError;
        }

        if (!params?.Description?.length>0) {
            errorsData.desCriptionError = 'Please Enter Description';
        } else {
            delete errorsData.desCriptionError;
        }

        if (!params?.appointmentTypeId) {
            errorsData.appointmentTypeError = 'Please Enter Appointment Type';
        } else {
            delete errorsData.appointmentTypeError;
        }

        setErrors(errorsData);

        return !Object.values(errorsData).some(Boolean);
    };

    const fetchDefaultData = async () => {
        queryClient.invalidateQueries(["/AppointmentReminder/Default", selectedOfficeData?.id, selectedPatient?.id, IsForRecall],);
        const refetchData = await defaultAppointmentFetch();
        if (refetchData?.data?.appointmentReminderPlanSetting?.length) {
            setReminderPlan(prevReminderPlan => ({
                ...prevReminderPlan,
                [keyName]: {
                    title: refetchData?.data?.title,
                    id: refetchData?.data?.id,
                    appointmentReminderPlanSetting: [...refetchData?.data?.appointmentReminderPlanSetting]
                }
            }));
        }
    }

    useEffect(() => {
     if(selectedOfficeData?.id && selectedPatient?.id) {
        if (!reminderPlan?.[keyName] && !isLoading) {
            fetchDefaultData();
        }
     }
    }, [defaultAppointmentLoading, defaultAppointmentFetch]);

    const handleSubmit = async (e, params) => {
        e.preventDefault();
        if (isValidEvent(params)) {
            setShowLoader(true);
            try {
                let res = await createNewAppointment(params);
                if (res?.statusCode === 200) {
                    goToPreviousScreen();
                    toast.success(res.message);
                }

            } catch (err) {
                setShowLoader(true);
                toast.error(err.message);
            } finally {
                setShowLoader(false);
            }
        }
    }
    let isLoading = doctorOfficeLoading || showLoader || patientDataLoading || officeLoading || defaultAppointmentLoading

    return {
        data: {
            isFromManagePage,
            DoctorOfficeData,
            repeatOptions,
            isLoading,
            searchTerm,
            patientData,
            OfficeData,
            errors,
            selectedOfficeData
        },
        methods: {
            goToPreviousScreen,
            handleSubmit,
            setSearchterm,
            setSelectedOfficeData
        }
    }
}

export default useCreatePatientAppointmentModule;