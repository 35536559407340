import React from "react";
import Text from "components/Text";
import styles from "../../Contracts/Contracts.module.scss";
import Card from "components/Card";
import moment from "moment";
import Loader from "components/Loader";

export default function ReviewForm({ t, formData, selectType, TypeOfEmployment, setViewMode, sendEmploymentProof, showLoader, exportProof, isShowSend }) {

    const userData = [
        { title: "Name", value: formData?.EmployeeName },
        { title: t('employmentProof.form.position'), value: formData?.Position },
        { title: t('employmentProof.form.dateHired'), value: moment(formData?.HiredDate).format('MMMM Do, YYYY') },
        { title: t('employmentProof.form.typeOfEmployment'), value: TypeOfEmployment.find(v => v?.id === selectType)?.name },
        { title: t('employmentProof.form.rateOfPay'), value: formData?.RateOfPay },
        { title: t('employmentProof.form.annualSalary'), value: formData?.AnnualSalary },
    ];

    const contactData = [
        { title: t('employmentProof.form.officePhoneNumber'), value: formData?.['Office Phone Number'] },
        { title: t('employmentProof.form.accountOWnerEmail'), value: formData?.['Account Owner’s Email Address'] },
    ];

    return (
        <Card className={styles.employment_proof_review_wrapper_card}>
            {showLoader && <Loader />}
            {!isShowSend && <button className="back-btn" onClick={() => setViewMode(false)}>
                <div className="back-btn-text">
                    <span className="ico">
                        <img
                            src={require("assets/images/arrow-back-icon.svg").default}
                            alt="arrow"
                        />
                    </span>
                    {t("employmentProof.backToEmploymentProofFoam")}
                </div>
            </button>}
            <div className={styles.border + ' ' + 'mt-4'}>
                <Text size="13px" weight="400" color="#79869A" marginBottom="2px">{t('employmentProof.form.date')}</Text>
                <p className={styles.review_text}>{moment(formData?.Date).format('MMM Do, YYYY')}</p>
            </div>
            <hr />
            <div className={styles.text_wrapper}>
                <p className={styles.review_text}>{`${t('employmentProof.dear')} ${formData?.EmployeeName}`}</p>
                <p className={styles.review_text}>{formData?.Subject}</p>
                <p className={styles.review_text}>{formData?.Heading}</p>
            </div>
            <hr />
            <div className={styles.forms_review_grid_container}>
                {userData.map((item, index) => (
                    <div key={index}>
                        <Text size="13px" weight="400" color="#79869A" marginBottom="2px">{item.title}</Text>
                        <div className={styles.review_text}>{item.value}</div>
                    </div>
                ))}
            </div>
            <hr />
            <div className={styles.name_email_wrapper}>
                <p className={styles.review_text}>{formData?.Declaration}</p>
                <div className={styles.forms_review_grid_container}>
                    {contactData.map((item, index) => (
                        <div key={index}>
                            <Text size="13px" weight="400" color="#79869A" marginBottom="2px">{item.title}</Text>
                            <div className={styles.review_text}>{item.value}</div>
                        </div>
                    ))}
                </div>
            </div>
            <hr />
            <div className={styles.margin}>
                <Text size="13px" weight="400" color="#79869A" marginBottom="10px">{formData?.Sincerely}</Text>
                <p className={styles.review_text}>{formData?.['Account Owner/Account Admin Name']}</p>
                <p className={styles.review_text}>{formData?.['Office Name']}</p>
            </div>
            {!isShowSend ? <div className={styles.margin}>
                <button
                    className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                    title="save"
                    onClick={() => sendEmploymentProof()}
                >
                    {t('employmentProof.form.send')}
                </button>
                <button
                    className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
                    onClick={() => exportProof()}
                >
                    {t('employmentProof.form.export')}
                </button>
            </div> :
                <div className={styles.margin}>
                    <button
                        className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                        onClick={() => exportProof()}
                    >
                        {t('employmentProof.form.exportEmploymentProof')}
                    </button>
                </div>
            }
        </Card>
    );
}
