import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import SendbirdCall, { LoggerLevel, RoomType, TokenType } from "sendbird-calls";
import { reducer } from "./reducer";
import { initialState } from "./state";
import { statefyDirectCall } from "./statefy";
import { CallContext, initialContext } from "Messenger/calling/context";
import { useSelector } from "react-redux";
import { getFullName } from "Messenger/pages/TeamConversation/utils";
import {
  useSendbirdStateContext,
  sendbirdSelectors,
} from "@sendbird/uikit-react";
import {
  createCallLog,
  createSendbirdUser,
  getSendbirdUserDetail,
  updateSendbirdUserStatus,
} from "repositories/chat-repository";
import { encodeId, handleError } from "utils";
import { getFCMToken } from "firebase";
import { ConnectionHandler } from "@sendbird/chat";

/**
 * Provider
 * ```tsx
 * <SbCallsProvider>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the SbCallsProvider to its child components.
 */

const SbCallsProvider = ({ children, t }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const [connection, setConnection] = useState("");
  const profile = useSelector((state) => state.userProfile.profile);
  const context = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(context);
  const { calls } = state;
  const currentCall = useMemo(
    () => calls.find((call) => !call.isEnded),
    [calls]
  );

  const isBusy = useMemo(() => calls.some((call) => !call.isEnded), [calls]);
  const init = useCallback((nAppId) => {
    const listenerId = "device-change-listener";
    try {
      SendbirdCall.removeListener(listenerId);
    } catch (error) {}
    SendbirdCall.init(nAppId);
    SendbirdCall.setLoggerLevel(LoggerLevel.ERROR);
    dispatch({
      type: "APP_INITIALIZED",
    });
    SendbirdCall.updateMediaDevices({ audio: true, video: true });
    SendbirdCall.addListener(listenerId, {
      onRinging: (call) => {},
      onAudioInputDeviceChanged: (current, available) => {
        dispatch({
          type: "UPDATE_AUDIO_INPUT_DEVICE_INFO",
          payload: { current, available },
        });
      },
      onAudioOutputDeviceChanged: (current, available) => {
        dispatch({
          type: "UPDATE_AUDIO_OUTPUT_DEVICE_INFO",
          payload: { current, available },
        });
      },
      onVideoInputDeviceChanged: (current, available) => {
        dispatch({
          type: "UPDATE_VIDEO_INPUT_DEVICE_INFO",
          payload: { current, available },
        });
      },
    });
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_SENDBIRD_APP_ID)
      init(process.env.REACT_APP_SENDBIRD_APP_ID);
  }, []);

  const ringingListenerId = "sb-call-listener";
  const connectionListenerId = "connection-listener";
  const auth = useCallback(
    async (authOption) => {
      const user = await SendbirdCall.authenticate(authOption);

      sdk.addConnectionHandler(
        connectionListenerId,
        new ConnectionHandler({
          onConnected: () => {
            setConnection("connected");
          },
          onDisconnected: () => {
            setConnection("disconnected");
          },
          onReconnectStarted: () => {
            setConnection("reconnectingStarted");
          },
          onReconnectSucceeded: () => {
            setConnection("reconnectingSucceeded");
          },
          onReconnectFailed: () => {
            setConnection("reconnectingFailed");
          },
        })
      );
      SendbirdCall.addListener(ringingListenerId, {
        onRinging: (call) => {
          dispatch({
            type: "RINGING",
            payload: statefyDirectCall(call, dispatch),
          });
        },
      });

      await SendbirdCall.connectWebSocket();
      const notificationPermission = await Notification.requestPermission();
      if (notificationPermission === "granted") {
        const token = await getFCMToken();
        if (token) {
          await SendbirdCall.unregisterAllPushTokens(TokenType.FCM);
          await SendbirdCall.registerPushToken(token, TokenType.FCM);
          await sdk.registerFCMPushTokenForCurrentUser(token);
        }
      }
      dispatch({ type: "AUTH", payload: user });
      return user;
    },
    [context]
  );

  const deauth = useCallback(() => {
    SendbirdCall.removeListener(ringingListenerId);
    sdk.removeConnectionHandler(connectionListenerId);
    SendbirdCall.deauthenticate();
    dispatch({ type: "DEAUTH" });
  }, []);

  /*
    Media Device Control
   */
  const updateMediaDevices = useCallback((constraints) => {
    SendbirdCall.updateMediaDevices(constraints);
  }, []);

  const selectAudioInputDevice = useCallback((mediaInfo) => {
    SendbirdCall.selectAudioInputDevice(mediaInfo);
    dispatch({
      type: "UPDATE_AUDIO_INPUT_DEVICE_INFO",
      payload: { current: mediaInfo },
    });
  }, []);

  const selectAudioOutputDevice = useCallback((mediaInfo) => {
    SendbirdCall.selectAudioOutputDevice(mediaInfo);
    dispatch({
      type: "UPDATE_AUDIO_OUTPUT_DEVICE_INFO",
      payload: { current: mediaInfo },
    });
  }, []);

  const selectVideoInputDevice = useCallback((mediaInfo) => {
    SendbirdCall.selectVideoInputDevice(mediaInfo);
    dispatch({
      type: "UPDATE_VIDEO_INPUT_DEVICE_INFO",
      payload: { current: mediaInfo },
    });
  }, []);
  const createUserLoadingRef = useRef(false);
  const userCreateOnSendBird = async (selectedUser, initialLoad = true) => {
    if (createUserLoadingRef.current && initialLoad) return;
    const SU_id = selectedUser?.id?.toString() || "";
    const name = getFullName(selectedUser);
    const profilePic = selectedUser?.profilePic || "";
    //Check if selected user has active acc in sendbird chat
    const userListQueryParam = {
      limit: 1,
      userIdsFilter: [SU_id],
    };
    const userListQuery =
      sdk.createApplicationUserListQuery(userListQueryParam);
    const sendbirdUser = await userListQuery.next();
    const checkIfuserExists = sendbirdUser.find((s) => s.userId === SU_id);
    createUserLoadingRef.current = true;
    if (!checkIfuserExists) {
      const userData = {
        user_id: SU_id,
        nickname: name,
        profile_url: profilePic || "",
      };
      await createSendbirdUser(userData);
    }
    return { ...selectedUser, SU_id };
  };
  useEffect(() => {
    if (profile.id && sdk && "createApplicationUserListQuery" in sdk) {
      userCreateOnSendBird(profile);
    }
  }, [profile, sdk]);

  //Check If User Exists
  async function callPatient(isVideoCall, callOption) {
    if (sdk) {
      try {
        const microphoneRespo = await navigator.permissions.query({
          name: "microphone",
        });
        const microphoneDisabled = microphoneRespo.state === "denied";
        if (microphoneDisabled && !isVideoCall) {
          throw new Error(t("messenger.enableMicrophone"));
        }
        if (isVideoCall) {
          const cameraRespo = await navigator.permissions.query({
            name: "camera",
          });
          if (cameraRespo.state === "denied") {
            throw new Error(
              microphoneDisabled
                ? t("messenger.enableCameraMicrophone")
                : t("messenger.enableCamera")
            );
          }
        }
        const selectedUser = await userCreateOnSendBird(
          state.appointmentDetail.patient,
          false
        );
        const params = {
          userId: selectedUser.SU_id,
          isVideoCall,
          callOption,
          customItems: {
            name:
              selectedUser.firstName +
              (selectedUser.lastName ? " " + selectedUser.lastName : ""),
            office_name: state.appointmentDetail.office.name ?? "",
            // profile_pic: selectedUser.profilePic,
            appointmentId: encodeId(state.appointmentDetail?.id),
            is_from: "web",
          },
        };

        SendbirdCall.dial(params, (call, error) => {
          if (error) {
            handleError(new Error("Call Error"));
            return;
          }
          createCallLog({
            sendbirdCallId: call.callId,
            appointmentId: state.appointmentDetail?.id,
          });
          const statefulCall = statefyDirectCall(call, dispatch);
          dispatch({ type: "ADD_CALL", payload: statefulCall });
          return statefulCall;
        });
      } catch (error) {
        if (error?.response?.data?.message) {
          handleError(new Error(error.response.data.message));
          return;
        }
        handleError(error);
      }
    }
  }

  /*
    Direct Calls
   */
  const dial = useCallback(callPatient, [sdk, state?.appointmentDetail, calls]);

  const clearCalls = useCallback(() => {
    dispatch({ type: "CLEAR_CALLS" });
  }, []);

  const callContext = {
    ...initialContext,
    ...state,
    profile,
    init,
    auth,
    deauth,
    sdk,
    // sendbird connection
    connection,
    // callHistory,
    isAuthenticated: !!state.user,

    // Media Device Control
    updateMediaDevices,
    selectAudioInputDevice,
    selectAudioOutputDevice,
    selectVideoInputDevice,

    // Direct Calls
    currentCall,
    isBusy,
    dial,
    addDirectCallSound: SendbirdCall.addDirectCallSound,
    clearCalls,
    dispatch,
    // Rooms
    RoomType,
  };

  useEffect(() => {
    if (
      state.appIntialized &&
      sdk &&
      "registerFCMPushTokenForCurrentUser" in sdk
    ) {
      auth({ userId: profile.id.toString() });
    }
  }, [state.appIntialized, sdk]);
  useEffect(() => {
    if (profile.contactNumber) {
      getSendbirdUserDetail(profile.id).then((res) => {
        if (
          !res.metadata?.phone_no ||
          profile.contactNumber !== res.metadata?.phone_no
        ) {
          updateSendbirdUserStatus(profile.id, {
            phone_no: profile.contactNumber,
          });
        }
      });
    }
  }, [profile.contactNumber]);
  return (
    <CallContext.Provider value={callContext}>{children}</CallContext.Provider>
  );
};

export default memo(SbCallsProvider);
