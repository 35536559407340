import React, { Fragment, useState } from "react";
import Card from "components/Card";
import Text from "components/Text";
import styles from "./DiscussionNotes.module.scss";
import noteIcon from "../../../assets/images/new_note.svg";
import addNote from "../../../assets/images/circular-add.svg";
import redFlagimage from "../../../assets/images/mir_red flag.svg";
import constants from "../../../constants";
import DiscussionNoteModal from "./modals/DiscussionNoteModal";
import { encodeId } from "utils";

export default function DiscussionNotesCard({ t, staff, history, handleUpdateList, officeName }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const staffId = staff?.staffId;

  const handleRedirect = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    const route = constants.routes.accountOwner.discussionNotesDetail;
    const updatedRoute = route.replace(":staffId", encodeId(staffId));
    history.push({ pathname: updatedRoute, state: { officeName, staffName: `${staff?.firstName} ${staff?.lastName}` } });
  };

  return (
    <Fragment>
      <Card
        className={
          staff?.isActive
            ? styles.discussion_card_wrapper
            : styles.discussion_card_wrapper_new
        }
        onClick={(e) => handleRedirect(e)}
      >
        <div className={styles.image_heading_wrapper}>
          {staff?.profilePic ? (
            <img src={staff?.profilePic} alt="staff" className={styles.profile_image} />
          ) : (
            <img
              src={require("assets/images/staff-default.svg").default}
              alt="staff"
            />
          )}
          <Text size="16px" weight="600" color="#587E85" marginTop="20px" className={styles.staff_name}>
            {`${staff?.firstName} ${staff?.lastName}`}
          </Text>
          <Text size="12px" weight="500" color="#2A4642" marginTop="8px">
            {staff?.designation}
          </Text>
        </div>

        {!staff?.isActive && (
          <div className={styles.red_flag}>
            <img src={redFlagimage} alt="redFlagimage" />
          </div>
        )}

        <div className={styles.doctor_note_wrapper}>
          <div className={styles.doctor_wrapper}>
            <img src={noteIcon} alt="noteIcon" />
            <Text size="12px" weight="500" color="#2A4642" className={staff?.isActive ? styles.active_text : styles.deactive_text}>
              {t('discussionNote.noOfnotes', { assignedNoteCount: staff?.assignedNoteCount || 0 })}
            </Text>
          </div>
          <button className={`${staff?.isActive ? styles.note_wrapper : styles.note_wrapper_new} interactive-element`}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <div className={`${staff?.isActive ? styles.active_text : styles.deactive_text}`}>
              <img src={addNote} alt="addNote" />
              <Text size="12px" weight="500" color="#2A4642" >{t('discussionNote.addNote')}</Text>
            </div>
          </button>
        </div>
      </Card >

      {isModalOpen && (
        <DiscussionNoteModal
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          staffId={staff?.staffId}
          handleUpdateList={handleUpdateList}
          isEditing={false}
        />
      )
      }
    </Fragment >
  );
}
