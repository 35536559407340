import React, { useState } from "react";
import Select from "react-select";
import TriangleIcon from "../../assets/images/Rectangle.svg";
import "./CustomSortDropdown.scss";

const customDefaultValue = {
  label: (
    <div className="dropdown_label">
      Sort By <img src={TriangleIcon} alt="TriangleIcon" />
    </div>
  ),
  value: "default",
};

export default function CustomSortDropdown({ setSortBy, sortBy, sortByList }) {
  const [selectedOption, setSelectedOption] = useState(customDefaultValue);

  const handleRequestStatusFilter = (selected) => {
    setSortBy(selected.value);
    setSelectedOption(customDefaultValue);
  };

  return (
    <div className="dropdown_container_x7y9z3">
      <Select
        classNamePrefix="react-select"
        value={selectedOption}
        defaultValue={customDefaultValue}
        options={sortByList}
        onChange={handleRequestStatusFilter}
        isSearchable={false}
        getOptionLabel={(option) => (
          <div className={sortBy === option.value ? option.className : ""}>
            {option.label}
          </div>
        )}
        getOptionValue={(option) => option.value}
      />
    </div>
  );
}
