import { useState, useEffect, useMemo } from 'react';
import constants from "../../../../constants";
import { getStorage, setStorage } from 'utils';
import { useDoctorsForFilter, useWaitingList } from 'repositories/patient-appointment-repository';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useStaffByUserId } from 'repositories/office-repository';

const pageSize = 20;

const useWaitingListModule = (officeId) => {
    const profile = useSelector(state => state.userProfile.profile);
    const { isLoading: isLoadingStaffDetail, data: staffDetail } = useStaffByUserId(officeId, profile?.id);

    const cacheWaitingListFilter = constants.waitingList.cache.filters;
    const cacheFilters = getStorage(cacheWaitingListFilter);
    const queryClient = useQueryClient();

    const [filters, setFilters] = useState({
        statusFilter: cacheFilters?.statuses || [],
        doctorFilter: cacheFilters?.doctors || [],
        appliedStatusFilters: cacheFilters?.statuses || [],
        appliedDoctorFilters: cacheFilters?.doctors || [],
    });

    const [pagination, setPagination] = useState({
        pageNumber: 1,
        totalItems: 0,
    });

    const [sortingList, setSortingList] = useState({
        patientNameAscending: null,
        doctorNameAscending: null,
        appointmentDateAscending: null,
        requestedByAscending: null
    });

    const isFilterApply = useMemo(() => {
        return filters.appliedDoctorFilters.length > 0 || filters.appliedStatusFilters.length > 0;
    }, [filters]);

    const waitingParams = useMemo(() => ({
        pageNumber: pagination.pageNumber,
        pageSize,
        officeId,
        status: filters.appliedStatusFilters,
        doctorId: staffDetail?.isDoctor ? profile?.id : null,
        doctorIds: filters.appliedDoctorFilters,
        isAdmin: staffDetail?.isAdmin ? true : false,
        SortByPatientNameAscending: sortingList.patientNameAscending,
        SortByDoctorNameAscending: sortingList.doctorNameAscending,
        SortByAppointmentDateAscending: sortingList.appointmentDateAscending,
        SortByRequestedByAscending: sortingList.requestedByAscending,
    }), [pagination.pageNumber, officeId, filters, sortingList]);

    const doctorParams = {
        pageNumber: pagination.pageNumber,
        pageSize,
        officeId,
        isAdmin: (staffDetail?.isAdmin || staffDetail?.isDoctor) ? true : false,
    };

    const { isLoading, data, refetch } = useWaitingList(waitingParams);
    const { isLoading: doctorsLoading, data: doctorsFilterData } = useDoctorsForFilter(doctorParams);

    useEffect(() => {
        if (!isLoading && data?.items) {
            const convertedData = data.items.map((item) => ({
                id: item.id,
                doctorName: `${item?.doctor?.firstName} ${item?.doctor?.lastName}`,
                patientName: item?.bookedForFamilyMember ? `${item?.bookedForFamilyMember?.firstName} ${item?.bookedForFamilyMember?.lastName}`: item?.bookedByUser ? `${item?.bookedByUser?.firstName} ${item?.bookedByUser?.lastName}` : '',
                appointmentDate: item?.requestedOn,
                requestedFor: item?.description,
                status: item?.appointmentRequestStatus,
            }));
            setPagination((prev) => ({
                ...prev,
                totalItems: data?.pagination?.totalItems,
            }));
            setFilters((prev) => ({
                ...prev,
                waitingLists: convertedData,
            }));
        }
    }, [isLoading, data]);

    const resetFilter = () => {
        setFilters((prev) =>({
            ...prev,
            statusFilter: [],
            doctorFilter: [],
            appliedStatusFilters: [],
            appliedDoctorFilters: [],
        }));
        setStorage(cacheWaitingListFilter, { doctors: [], statuses: [] });
        setPagination((prev) => ({ ...prev, pageNumber: 1 }));
    };

    const handleApplyFilters = () => {
        setFilters((prev) => ({
            ...prev,
            appliedDoctorFilters: prev?.doctorFilter,
            appliedStatusFilters: prev?.statusFilter,
        }));
        setStorage(cacheWaitingListFilter, {
            doctors: filters?.doctorFilter,
            statuses: filters?.statusFilter,
        });
        setPagination((prev) => ({ ...prev, pageNumber: 1 }));
    };

    const handleSort = (keyName, value) => {
        setSortingList((prevState) => ({
            ...Object.keys(prevState).reduce((acc, key) => {
                acc[key] = key === keyName ? value : null;
                return acc;
            }, {}),
        }));
    };

    const handleUpdateList = () => {
        queryClient.invalidateQueries(["/appointment/waiting", waitingParams]);
        refetch();
    };

    const loading = isLoading || doctorsLoading || isLoadingStaffDetail;

    return {
        data: {
            loading,
            totalItems: pagination?.totalItems,
            pageNumber: pagination?.pageNumber,
            pageSize,
            waitingLists: filters?.waitingLists,
            doctorsFilterData: (staffDetail?.isDoctor || staffDetail?.isReceptionist) ? [] : doctorsFilterData,
            statusFilter: filters?.statusFilter,
            doctorFilter: filters?.doctorFilter,
            isFilterApply,
        },
        methods: {
            setPageNumber: (pageNumber) => setPagination((prev) => ({ ...prev, pageNumber })),
            setStatusFilter: (statusFilter) => setFilters((prev) => ({ ...prev, statusFilter })),
            setDoctorsFilter: (doctorFilter) => setFilters((prev) => ({ ...prev, doctorFilter })),
            resetFilter,
            handleApplyFilters,
            handleUpdateList,
            handleSort,
        },
    };
};

export default useWaitingListModule;
