import React, { useState } from "react";
import Card from "components/Card";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { cacheSideBarActive } from "utils";
import Text from "components/Text";
import TaskPerformanceCard from "./TaskPerformanceCard";
import styles from "../TaskManagement.module.scss";
import DateRangeFilter from "accountOwner/pages/PerformanceReview/components/DateRangeFilter";
import useTaskPerformanceController from "../hooks/useTaskPerformanceController";
import Loader from "components/Loader";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import PerformanceOfficeFilter from "../Filters/PerfoemanceOfficeFilter";
import Empty from "components/Empty";

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const TaskPerformance = ({ t, history }) => {
  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

  const {
    data: {
      performanceLoader,
      dates,
      performaceList,
      totalItems,
      officeFilter,
      accountOwnerId,
    },
    methods: {
      updateDatesValues,
      setPageNumber,
      setOfficeFitler,
      handleApply,
      resetFilter,
    },
  } = useTaskPerformanceController();

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  const goToPreviousScreen = () => {
    history.push("/task-management");
  };

  const handleApplyFilter = () => {
    handleApply();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleResetFliter = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleClose = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  return (
    <Page
      onBack={goToPreviousScreen}
      className={styles["task_performance_wrapper"] + " " + "scheduler-page"}
      title={t("taskManagement.taskPerformance")}
    >
      {performanceLoader && <Loader />}
      <Card className={styles["card_padding"]}>
        <div className="scheduler-tabs-main-wrapper">
          <StickySidebar
            isSidebarActive={isSidebarActive}
            handleSidebarToggle={handleSidebarToggle}
            resetFilter={handleResetFliter}
          >
            <Text size="15px" marginBottom="12px" weight="600" color="#102C42">
              {t("taskManagement.filters")}
            </Text>
            <Accordion
              preExpanded={["a"]}
              className="filter-accordion"
              allowZeroExpanded
            >
              <AccordionItem uuid="a">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {t("taskManagement.offices")}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <PerformanceOfficeFilter
                    setOfficeFilter={setOfficeFitler}
                    selectedOffice={officeFilter}
                    isStaff={false}
                    accountOwnerId={accountOwnerId}
                  />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="b">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {t("accountOwner.dateRange")}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <DateRangeFilter
                  t={t}
                  dates={dates}
                  updateDatesValues={updateDatesValues}
                />
              </AccordionItem>
            </Accordion>
            <div className="filter-btn-box filter-btn-box--sticky-footer">
              <button
                className={"button button-round button-shadow mr-3"}
                title="apply"
                onClick={handleApplyFilter}
              >
                {t("taskManagement.apply")}
              </button>
              <button
                className={
                  "button button-round button-border button-dark reset-btn"
                }
                title="reset"
                onClick={handleResetFliter}
              >
                {t("taskManagement.reset")}
              </button>
              <button
                className="button button-round button-border button-dark cancel-btn"
                title="close"
                onClick={handleClose}
              >
                {t("taskManagement.close")}
              </button>
            </div>
          </StickySidebar>
          {performaceList?.length === 0 ? (
            <div className={styles["not_found"]}>
              <Empty Message={t("taskManagement.taskNotFound")} />
            </div>
          ) : (
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
              className="w-100"
            >
              <InfiniteScroll
                className="w-100"
                dataLength={performaceList?.length}
                hasMore={performaceList?.length < totalItems}
                next={() => setPageNumber((v) => v + 1)}
              >
                <div className={styles["task_management_card_grid"]}>
                  {performaceList?.map((performace) => (
                    <TaskPerformanceCard performace={performace} />
                  ))}
                </div>
              </InfiniteScroll>
            </motion.div>
          )}
        </div>
      </Card>
    </Page>
  );
};
export default withTranslation()(TaskPerformance);
