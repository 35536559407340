import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import "./SubscriptionAndInvoices.scss";
import AlertCircle from "./../../../../assets/images/alert-circle-black.svg";

function InvoiceDetailModal({ invoiceEntry, isOpen, onClose, t }) {
  if (!isOpen) return null;

  // Calculate total charge amount
  const totalChargeAmount = invoiceEntry?.reduce(
    (sum, record) => sum + record.totalChargeAmountInCents,
    0
  );

  return (
    <Modal
      isOpen={true}
      toggle={onClose}
      className="modal-dialog-centered deactivate-modal sai-pi-invoice-modal"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={onClose}>
        <img src={require("assets/images/cross.svg").default} alt="close" />
      </span>

      <ModalBody>
        <h3 className="sai-pi-invoice-modal-title">
          {t("superAdmin.invoiceDetails")}
        </h3>

        <div className="invoice_yellow_heading">
          <img src={AlertCircle} alt="AlertCircle" />
          <span className="invoice_alert_text">
            {t("superAdmin.invoiceModalWarningMsg")}
          </span>
        </div>

        <div className="invoice_modal_scrollar">
          {invoiceEntry?.map((val) => {
            return (
              <>
                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {val.packageName} {t("subscription")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.perOfficeChargeAmountInCents / 100).toFixed(2)} /{" "}
                    {t("perMonth")}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalOffices")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {val.officeCount}{" "}
                    {t("superAdmin.office", {
                      count: val.officeCount,
                    })}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalCost")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.totalOfficeChargeAmountInCents / 100).toFixed(2)} /{" "}
                    {t("perMonth")}
                  </span>
                </div>

                <div className="d-flex justify-content-between mt-5">
                  <span className="sai-pi-invoice-modal-key">
                    {t("perActivePermanentStaffMember")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.perPermanentStaffChargeAmountInCents / 100).toFixed(
                      2
                    )}{" "}
                    / {t("perMonth")}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalActivePermanentStaffMember")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {val.permanentStaffCount}{" "}
                    {t("superAdmin.permanentStaff", {
                      count: val.permanentStaffCount,
                    })}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalCost")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.totalPermanentStaffChargeAmountInCents / 100).toFixed(
                      2
                    )}{" "}
                    / {t("perMonth")}
                  </span>
                </div>

                <div className="d-flex justify-content-between mt-5">
                  <span className="sai-pi-invoice-modal-key">
                    {t("perActiveTemporaryStaffMember")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.perTemporaryStaffChargeAmountInCents / 100).toFixed(
                      2
                    )}{" "}
                    / {t("perMonth")}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalActiveTemporaryStaffMember")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {val.temporaryStaffCount}{" "}
                    {t("superAdmin.temporaryStaff", {
                      count: val.temporaryStaffCount,
                    })}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalCost")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.totalTemporaryStaffChargeAmountInCents / 100).toFixed(
                      2
                    )}{" "}
                    / {t("perMonth")}
                  </span>
                </div>

                <div className="d-flex justify-content-between mt-5">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalNumberOfPlacements")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {val.placementCount}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.costPerPlacement")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.perPlacementChargeAmountInCents / 100).toFixed(2)} /{" "}
                    {t("perMonth")}
                  </span>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <span className="sai-pi-invoice-modal-key">
                    {t("superAdmin.totalCost")}
                  </span>
                  <span className="sai-pi-invoice-modal-value">
                    {t("cad")}{" "}
                    {(val.totalPlacementChargeAmountInCents / 100).toFixed(2)} /{" "}
                    {t("perMonth")}
                  </span>
                </div>
              </>
            );
          })}
        </div>
        <hr className="my-3" />

        <div className="d-flex justify-content-between">
          <span className="sai-pi-invoice-modal-key">
            <strong>{t("superAdmin.totalCost")}</strong>
          </span>
          <span className="sai-pi-invoice-modal-value">
            {t("cad")} {(totalChargeAmount / 100).toFixed(2)} / {t("perMonth")}
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default withTranslation()(InvoiceDetailModal);
