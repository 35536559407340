import React, { useState } from "react";
import Page from "components/Page";
import Card from "components/Card";
import styles from "./PatientMessageCenter.module.scss";
import { withTranslation } from "react-i18next";
import PatientMessageCenterTable from "./components/PatientMessageCenterTable";
import PatientMessageFilter from "./filters/index.jsx";
import usePatientMessageHistoryModule from "../hooks/usePatietnMessageHistoryModule";

const PatientMessageCenter = ({ t }) => {
  const [isSidebarActive, setSidebarActive] = useState(true);

  const {
    data: {
      loading,
      totalItems,
      pageNumber,
      pageSize,
      patientMessageHistory,
      templateLists,
      messageTemplateFilter,
      isFilterApply,
      officeDetail,
      patientId,
      officeId,
      doctorFilter,
      patientFilter,
      dates,
    },
    methods: {
      setPageNumber,
      goBack,
      setMessageTemplateFilter,
      handleApplyFilters,
      resetFilter,
      handleRedirectAddMessage,
      handleRedirectViewMessage,
      setDoctorFilter,
      setPatientFilter,
      updateDatesValues,
    },
  } = usePatientMessageHistoryModule(t);

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  return (
    <Page className="scheduler-page" onBack={goBack}>
      <div className={styles.title_btn_wrapper}>
        <div>
          <h2 className="title"> {officeDetail?.name}</h2>
          <p className="sub-title m-0">Patient Message Center</p>
        </div>
        <button
          className="button button-round button-shadow w-sm-100"
          onClick={handleRedirectAddMessage}
        >
          Add Message
        </button>
      </div>
      <Card>
        <div className={styles.sidebar_table_wrapper}>
          <PatientMessageFilter
            isSidebarActive={isSidebarActive}
            handleSidebarToggle={handleSidebarToggle}
            templateLists={templateLists}
            setMessageTemplateFilter={setMessageTemplateFilter}
            messageTemplateFilter={messageTemplateFilter}
            handleApplyFilters={handleApplyFilters}
            resetFilter={resetFilter}
            isFilterApply={isFilterApply}
            patientId={patientId}
            officeId={officeId}
            setDoctorFilter={setDoctorFilter}
            setPatientFilter={setPatientFilter}
            doctorFilter={doctorFilter}
            patientFilter={patientFilter}
            dates={dates}
            updateDatesValues={updateDatesValues}
          />
          <div
            className={
              isSidebarActive ? styles.right_side_active : styles.right_side
            }
          >
            <PatientMessageCenterTable
              loading={loading}
              totalItems={totalItems}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              patientMessageHistory={patientMessageHistory}
              officeDetail={officeDetail}
              handleRedirectViewMessage={handleRedirectViewMessage}
            />
          </div>
        </div>
      </Card>
    </Page>
  );
};
export default withTranslation()(PatientMessageCenter);
