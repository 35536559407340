import React from "react";
import styles from "../../../../../accountOwner/pages/Appointments/Appointments.module.scss";
import Text from "components/Text";
import crossIcon from "../../../../../assets/images/cross.svg";
import moment from "moment-timezone";

export default function ActionDateTimePopup({
  handlePopupToggle,
  position,
  list,
}) {
  // Sort the dates chronologically
  const sortedList = [...list].sort((a, b) => {
    return moment(a.selectedDate).valueOf() - moment(b.selectedDate).valueOf();
  });

  return (
    <div
      className={styles.schedular_popup_daily_view}
      style={{
        top: position?.top,
        right: position?.right,
        position: "absolute",
      }}
    >
      <span className={styles.close_btn} onClick={handlePopupToggle}>
        <img src={crossIcon} alt="close" />
      </span>
      <div className={styles.container_box}>
        {sortedList.length > 0 &&
          sortedList.map((val, index) => (
            <div key={index} className={styles.content_wrapper}>
              <Text size="14px" weight="500" color="#EE4F4F">
                {moment(val.selectedDate).format("ll")}
              </Text>
            </div>
          ))}
      </div>
    </div>
  );
}
