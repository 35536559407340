import Card from "components/Card";
import Page from "components/Page";
import React, { useMemo, useState } from "react";
import constants from "../../../constants";
import { getFullName } from "../TeamConversation/utils";
import { withTranslation } from "react-i18next";
import styles from "./PatientConversation.module.scss";
import useTeamConversationState from "Messenger/hooks/useTeamConversationState";
import useSelectedAccountOwner from "Messenger/hooks/useSelectedAccountOwner";
import SwitchOwnerModal from "accountOwner/pages/Scheduler/components/Modals/SwitchOwnerModal";
import { Redirect, useLocation } from "react-router-dom";
import MessageDot from "components/MessageDot";
import AppointmentListTable from "./component/AppointmentListTable";
import Loader from "components/Loader";
import InternalTabContent from "../TeamConversation/components/TabsContent/InternalTabContent";

const PatientConversation = ({ t }) => {
  const [currentChannel, setCurrentChannel] = useState(null);
  const [viewAppointmentCred, setViewAppointmentCred] = useState(null);
  const location = useLocation();
  const doctorChatCheck = useMemo(
    () =>
      location.pathname === constants.routes.patientWithOwnerOrDoctorChatModule,
    [location.pathname]
  );
  const {
    state: { ownerData, selectedOwner, showSwitchOwnerModal, loadinOwnerData },
    updateMethods: { setShowSwitchOwnerModal },
    otherMethods: { switchNewAccountOwner },
  } = useSelectedAccountOwner({
    localStorageKey:
      constants.localStorageKeys.selectedChatAccountOwnerForPatient,
    setCurrentChannel,
    isPatient: true,
    doctorChatCheck,
  });

  const { state, otherMethods } = useTeamConversationState({
    selectedOwner,
  });
  const { profile } = state;
  const { handleBack } = otherMethods;

  let isStaff = false;
  if (profile?.role) {
    isStaff = profile.role.systemRole === constants.systemRoles.staff;
  }

  if (loadinOwnerData) {
    return <Loader />;
  }
  if (profile?.role?.systemRole === constants.systemRoles.staff) {
    if (!profile.isAdmin && !profile.isMessenger) {
      return <Redirect to={"/"} />;
    }
  }
  const renderUi = () => {

    if (viewAppointmentCred) {
      return (
        <AppointmentListTable
          creds={viewAppointmentCred}
          doctorChatCheck={doctorChatCheck}
          close={() => setViewAppointmentCred(null)}
        />
      );
    }
    return (
      <div
        className={
          styles.patient_live_chat_page_content + " messenger-page-content"
        }
      >
        <Page onBack={handleBack}>
          <div className="d-md-flex justify-content-between scheduler-page">
            <h2 className="page-title mb-0">
              {t(
                `messenger.${
                  doctorChatCheck ? "doctorLiveChat" : "patientLiveChat"
                }`
              )}
            </h2>
            {isStaff && ownerData?.length > 1 && (
              <div className="top-right-text">
                {selectedOwner && (
                  <div className="show-text">
                    {t("messenger.selectedAccountOwnerText")}{" "}
                    <b>'{getFullName(selectedOwner)}'</b>
                    <MessageDot />
                  </div>
                )}
                <span
                  className="link-btn"
                  onClick={() => setShowSwitchOwnerModal(true)}
                >
                  {t("messenger.changeAccountOwner")}
                </span>
              </div>
            )}
          </div>
          <Card
            radius="10px"
            marginBottom="18px"
            shadow="0 0 15px 0 rgba(0, 0, 0, 0.08)"
            cursor="default"
            padding="70px"
            className={styles.team_chat_card}
          >
            <div className="messenger-content">
              <InternalTabContent
                currentUser={profile}
                doctorChatCheck={doctorChatCheck}
                selectedOwner={selectedOwner}
                currentChannel={currentChannel}
                setCurrentChannel={setCurrentChannel}
                setViewAppointmentCred={setViewAppointmentCred}
              />
            </div>
          </Card>
          <SwitchOwnerModal
            subTitle={t(
              `messenger.${
                doctorChatCheck ? "doctorLiveChat" : "patientLiveChat"
              }`
            )}
            showSwitchOwnerModal={showSwitchOwnerModal}
            setShowSwitchOwnerModal={setShowSwitchOwnerModal}
            ownerList={ownerData}
            selectedOwner={selectedOwner}
            setSelectedOwner={switchNewAccountOwner}
          />
        </Page>
      </div>
    );
  };

  return renderUi();
};

export default withTranslation()(PatientConversation);
