
import { useEffect, useState } from "react";
import { decodeId, encodeId } from "utils";
import constants from "../../../../constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { uniqBy } from "lodash";
import { getStaffMembers } from "repositories/contract-repository";
import toast from "react-hot-toast";
import { addNewNotice } from "repositories/notices-repository";


let pageSize = 20;
const useAddNoticeController = (history, t) => {
    const [formData, setFormData] = useState({
        otherReason: "",
        problemDescription: "",
        companyExpectation: ""
    });
    const [reasonsOfCorrectiveAction, setReasonsOfCorrectiveAction] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [staffMembers, setStaffMembers] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [staff, setStaff] = useState(null);
    const [confirmedSelectedStaff, setConfirmedSelectedStaff] = useState(null);
    const [errors, setErrors] = useState({});
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    let { officeId } = useParams();
    officeId = decodeId(officeId);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === "otherReason" && value.length > 400) {
            return; 
        }
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleCheckboxChange = (id, event) => {
        const { checked } = event.target;
        setReasonsOfCorrectiveAction((prev) => ({
            ...prev,
            [id]: checked
        }));
    };

    const getStaff = async (searchTerm) => {
        setShowLoader(true);
        try {
            const res = await getStaffMembers(officeId, searchTerm, pageNumber, pageSize);
            setStaffMembers(uniqBy([...res?.items], "id"));
            setTotalItems(res?.pagination?.totalItems);
            setShowLoader(false);
        } catch (e) {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        getStaff(searchValue);
    }, [searchValue])

    const handleModal = (searchTerm = "") => {
        if (isModalOpen) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true);
            getStaff(searchTerm);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setStaff(confirmedSelectedStaff);
    }

    const handleConfirmationModal = () => {
        if (isAlertModalOpen) {
            setIsAlertModalOpen(false);
        } else {
            if (isValidEvent()) {
                setIsAlertModalOpen(true);
            }
        }
    }

    const handleStaffRadio = (id) => {
        setStaff(id)
    }

    const handleApply = () => {
        setConfirmedSelectedStaff(staff);
        setIsModalOpen(false);
    }

    const goToPreviousScreen = () => {
        history.push(
            constants.routes.accountOwner.notices.replace(":officeId", encodeId(officeId))
        );
    };

    const validateField = (condition, errorKey, errorMessage) => {
        if (condition) {
            return { [errorKey]: errorMessage };
        }
        return { [errorKey]: null };
    };

    const isValidEvent = () => {
        const errorsData = { ...errors };
        const keysWithTrueValue = Object.keys(reasonsOfCorrectiveAction).filter(key => reasonsOfCorrectiveAction[key]);
        const validations = [
            validateField(!confirmedSelectedStaff?.firstName, "confirmedSelectedStaff", t("notices.selectMemberError")),
            validateField(!formData.problemDescription.trim().length, "problemDescription", t("notices.selectDescriptionError")),
            validateField(!formData.companyExpectation.trim().length, "companyExpectation", t("notices.compandeyExpectationError")),
            validateField(!keysWithTrueValue.length, "reasonsOfCorrectiveAction", t("notices.correctiveReasonError")),
            validateField(reasonsOfCorrectiveAction["others"] && !formData.otherReason.trim().length, "otherReason", t("notices.correctiveOtherReasonError")),
        ];
        validations.forEach(validation => {
            Object.assign(errorsData, validation);
        });

        Object.keys(errorsData).forEach(key => {
            if (!errorsData[key]) {
                delete errorsData[key];
            }
        });

        setErrors(errorsData);

        return !Object.values(errorsData).some(Boolean);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selected = Object.keys(reasonsOfCorrectiveAction).filter(key => (reasonsOfCorrectiveAction[key] && (key !== "others"))).map(key => +key);
        setShowLoader(true);
        try {
            const params = {
                staffId: confirmedSelectedStaff?.id,
                reasonsOfCorrectiveAction: selected,
                problemDescription: formData.problemDescription,
                companyExpectation: formData.companyExpectation
            };

            if (reasonsOfCorrectiveAction["others"] && formData.otherReason) {
                params.otherReason = formData.otherReason
            }
            let res = await addNewNotice(params);
            if (res?.statusCode === 200) {
                goToPreviousScreen();
            }
            toast.success(res.message);
        } catch (err) {
            setShowLoader(true);
            toast.error(err.message);
        } finally {
            setShowLoader(false);
        }
    }

    return {
        data: {
            formData,
            reasonsOfCorrectiveAction,
            isModalOpen,
            staffMembers,
            staff,
            showLoader,
            errors,
            confirmedSelectedStaff,
            isAlertModalOpen,
            searchValue,
            totalItems
        },
        methods: {
            handleOnChange,
            handleCheckboxChange,
            handleModal,
            handleStaffRadio,
            handleApply,
            goToPreviousScreen,
            handleSubmit,
            handleConfirmationModal,
            setSearchValue,
            setPageNumber,
            handleCancel
        }
    }
}

export default useAddNoticeController;
