const constants = {
  subscriptionTerminated: "subscriptionTerminated",
  staffActionEvents: {
    activation: 0,
    deactivation: 1,
    removal: 2,
    removalAsAdmin: 3,
  },
  packageTypes: {
    trial: "trial",
    free: "free",
  },
  routes: {
    home: "/",
    callViewNew: "/call-view-new",
    paymentfailed: "/paymentfailed",
    findDoctors: "/find-doctors",
    officeStaffs: "/offices-Staff",
    appVersionList: "/app-version",
    appVersionForm: "/app-version/form",
    specialtyList: "/specialty",
    specialtyForm: "/specialty/form",
    account: "/account",
    addSubscription: "/add-subscription",
    paymentDetailsNew: "/payment-details-new",
    setupCard: "/setup-card",
    doctors: "/doctors",
    searchDoctorBySpecialty: "/doctor-by-specialty",
    doctor: "/doctor",
    watingListRequest: "/doctor/waiting-list-form",
    setLocation: "/set-location",
    doctorOffices: "/doctor/:doctorId/offices",
    editProfile: "/edit-profile",
    changePassword: "/change-password",
    termsAndConditions: "/terms-conditions",
    privacyPolicy: "/privacy-policy",
    termsAndConditionsPatient: "/terms-conditions-for-patient",
    privacyPolicyPatient: "/privacy-policy-for-patient",
    viewProfile: "/profile",
    doctorReviews: "/doctor/reviews",
    requestAnAppointment: "/doctor/request-appointment",
    downloadIcs: "/download-ics",
    chatModule: "/team-conversation",
    patientChatModule: "/patient-conversation",
    conversationRedirection: "/conversation-redirection",
    patientWithOwnerOrDoctorChatModule: "/doctors-conversation",
    patientListOfAppointment: "/patient-list-appointment",
    notes: "/notes",
    noteDetails: "/my-note-details/:noteId",
    transferredNoteDetails: "/transferred-details/:noteId",
    addNote: "/addNote",
    questionnaireForm: "/questionnaire-form",
    getStarted: "/getstarted",
    waitingList: "/waiting-list/:officeId",
    waitingAppoimentDetail: "/waiting-appointment-detail/:officeId/:requestId",
    familyMembers: "/family-members",
    addNewMember: "/add-new-member",
    editMember: "/edit-member/:memberId",
    viewMember: "/view-member/:memberId",
    demoRequestPage: "/demo-request-page",
    demoRequestThank: "/demo-request-thank-page",
    pmsMembers: "/pms-members/:officeId",
    accountPreferences: "/account-preferences",
    appointementConfirmationForNonMiraxis:
      "/confirm-appointment/:appointmntId/:officeId",

    taskManagements: {
      taskManagement: "/task-management",
      taskManagementAddNote: "/task-management-add-note",
      taskManagementEditNote: "/task-management-edti-note/:taskId",
      taskDetails: "/staff-task-details/:taskId",
      ownerTaskDetails: "/owner-task-details/:taskId",
      patientDetails: "/patient-details",
      taskPerformance: "/task-performance",
      performanceDetail: "/performance-detail/:userId/:officeId",
    },

    blogs: {
      blog1: "/blogs/9-reasons-why-dentists-are-automating-their-practice",
      blog2: "/blogs/top-9-ways-for-you-and-your-staff-to-avoid-burnout",
      blog3:
        "/blogs/top-9-benefits-of-streamlining-and-automating-workflows-in-your-dental-practice",
      blog4:
        "/blogs/communication-with-your-team-how-to-be-hands-offand-still-be-hands-on",
      blog5:
        "/blogs/3-effective-ways-technology-can-help-you-efficiently-manage-staff-in-your-practice",
      blog6:
        "/blogs/8-ways-leading-dental-practices-enhance-their-staff-experience",
      blog7: "/blogs/6-ways-to-recruit-and-retain-practice-employees",
    },
    landingPages: {
      guideDownloadPage: "/landing-pages/guide-download-page",
      guideThankPage: "/landing-pages/guide-thank-page",
      ebookDownloadPage: "/landing-pages/ebook-download-page",
      ebookThankPage: "/landing-pages/ebook-thank-page",
      guideDownloadPageFb: "/landing-pages/guide-download-page-fb",
      guideDownloadPageGgl: "/landing-pages/guide-download-page-ggl",
      guideDownloadPageSm: "/landing-pages/guide-download-page-sm",
      ebookDownloadPageFb: "/landing-pages/ebook-download-page-fb",
      ebookDownloadPageGgl: "/landing-pages/ebook-download-page-ggl",
      ebookDownloadPageSm: "/landing-pages/ebook-download-page-sm",
    },
    vendor: {
      dashboard: "/vendor-dashboard",
      manageOrders: "/manage-orders",
      manageCatalogue: "/manage-catalogue",
      manageCustomers: "/manage-customers",
      promotion: "/promotion",
      promoCodes: "/promocodes",
      topup: "/topup",
      supportHelpdesk: "/support-helpdesk",
      manageSalesRep: "/manage-sales-rep",
      manageInvoices: "/manage-invoices",
      addNewItem: "/add-new-item",
      catalogueDetail: "/catalogue-detail",
      addNewTicket: "/add-new-ticket",
      ticketDetail: "/vendor-ticket-detail/:id",
      customerDetail: "/customer-detail/:id",
      inviteCustomers: "/invite-customers",
      vendorProfile: "/vendor-profile",
      orderDetail: "/order-detail/:orderId",
      addPromoCode: "/add-new-promocode",
      addPromotion: "/add-new-promotion",
      promotionDetail: "/promotion-detail/:promotionId",
      inviteSalesRep: "/invite-sales-rep",
      editSalesRep: "/edit-sales-rep",
      salesRepDetail: "/sales-rep-detail",
      accountSetup: "/vendor-account-setup",
      editProfile: "/vendor-edit-profile",
      cardSetup: "/vendor-card-setup",
      bankSetup: "/vendor-bank-setup",
      paymentSuccess: "/vendor-payment-success",
      manageSubscription: "/manage-subscription",
      manageVendorCards: "/manage-vendor-cards",
      editVendorCards: "/edit-vendor-cards",
      changeSubscription: "/change-subscription",
      vendorPurchaseSubscription: "/vendor-purchase-subscription",
    },
    accountOwner: {
      offices: "/offices",
      officeOptions: "/office/:officeId",
      staffGrid: "/staff/:officeId",
      preferences: "/office/:officeId/preferences",
      timelinePreferences: "/timeline-preferences/:officeId",
      discussionNotes: "/discussion-notes/:officeId",
      discussionNotesDetail: "/discussion-notes-detail/:staffId",
      geoFence: "/office/:officeId/geo-fence",
      managePlan: "/manage-plan",
      viewFeatures: "/view-plan",
      editOffice: "/editOffice",
      manageCards: "/manage-cards",
      changePlan: "/change-plan",
      addSubscription: "/add-subscription",
      selectOffice: "/Select-Office",
      profile: "/profile",
      editProfile: "/edit-profile",
      contracts: "/contracts/:officeId",
      forms: "/forms/:officeId",
      myForms: "/my-forms/:officeId",
      employementProof: "/employement-proof/:officeId",
      myEmployementProof: "/my-employment-proof/:officeId",
      employmentProofForm: "/employment-proof-form/:officeId",
      employmentProofFormReview: "/employment-proof-form-review",
      performanceReview: "/performance-review/:officeId",
      myPerformanceReview: "/my-performance-review/:officeId",
      addNewPerformanceReview: "/add-performance-review/:officeId",
      staffContracts: "/staff-contracts/:officeId/:staffId",
      newContract: "/new-contract/:officeId/:staffId",
      editContract: "/edit-contract/:officeId/:staffId/:contractId",
      viewContract: "/view-contract/:officeId/:contractId",
      timesheet: "/timesheet/:officeId",
      leaves: "/leaves/:officeId",
      officesList: "/offices-list",
      performanceReviewForm: "/performance-review-form/:officeId",
      previewReviewForm: "/preview-performance-review-form",
      notices: "/notices/:officeId",
      myNotices: "/my-notices/:officeId",
      addNotice: "/add-notice/:officeId",
      noticeDetails: "/notice-details/:noticeId",
      myNoticeDetails: "/my-notice-details/:noticeId",
      updateTimesheetForStaff: "/timesheet/:officeId/:staffId",
      editTimesheetForStaff: "/edittimesheet/:officeId/:staffId",
      timesheetReport: "/timeheet/:officeId/timesheet-report",
    },

    staff: {
      offices: "/staff-offices",
      officeOptions: "/staff-office/:officeId",
      officeAdmin: "/staff-admin/:officeId",
      officeContracts: "/office-contracts/:officeId",
      viewContract: "/view-staff-contract/:officeId/:contractId",
      timesheet: "/staff-office/:officeId/timesheet",
      timesheetSummary: "/timesheet-summary",
      timesheetViewDetail: "/timesheet-view-detail",
      staffListingTimesheet: "/timesheet/:officeId",
      timesheetDetail: "/timeheet/:officeId/timesheet-detail/:userId",
      timesheetDateDetail: "/timesheet-date-detail",
      leaves: "/leaves/:officeId",
      applyLeaves: "/apply-leaves/:officeId",
    },

    superAdmin: {
      patientList: "/manage-patients",
      reviews: "/reviews",
      patientReviews: "/reviews/patient/:patientId",
      doctorReviews: "/reviews/doctor/:doctorId",
      broadCastMessages: "/broadcast-messages",
      createBroadCastMessage: "/create-broadcast-message",
      appointmentRequestList: "/appointment-request-list",
      vendorSubscriptionPlans: "/vendor-subscription-plans",
      appointmentRequestDetail: "/appointment-request-detail/:requestId",
      feedback: "/feedbackandsuggestions",
      feedbackView: "/feedbackview/:feedbackId",
      SupportAndHelp: "/support",
      supportTicketDetail: "/ticket-detail/:ticketId",
      editSubscription: "/edit-subscription/:subscriptionId",
      categories: "/categories",
      tax: "/tax",
      sales: "/sales",
      trialSubscription: "/trial-subscription",
      basicSubscription: "/basic-subscription",
      vendorSubscriptionView: "/view-subscription/:subscriptionType",

      enterpriseSubscription: "/enterprise-subscription",
      manageVendors: "/manage-vendors",
      VendorDetails: "/vendor-details/:vendorId",
      VendorProfile: "/admin-vendor-profile",
      TopUpPromotions: "/top-up-promotions",
      helpDesk: "/help-desk",
      ticketDetails: "/helpdesk-ticket-details",
      manageCommissions: "/manage-commissions",
      manageSalesRepAdmin: "/manage-sale-representatives",
      accountBasicSubscription: "/subscription-details/:subscriptionType",
      editAccountBasicSubscription: "/update-subscription/:subscriptionType",
      manageFeatures: "/manage-features",
      editBasicSubscription: "/edit-subscription",

      editVendorSubscription: "/edit-vendor-subscription/:subscriptionType",
      addNewPlan: "/add-vendor-enterprise-plan",
      editEnterPricePlan: "/edit-vendor-enterprise-plan",
      enterpriseSubscriptionDetails: "/enterprise-subscription-details",
      addNewVendor: "/add-new-vendor",
      editAssignedVendor: "/edit-assigned-vendor",
      vendorSubscriptionDetails: "/vendor-subscription-details",
      editSubscriptionForVendors: "/edit-vendor-subscription-price",
      vendorTransactionHistory: "/vendor-transaction-history",

      appointmentDashboard: "/appointment-dashboard/:officeId",
    },

    appointment: {
      appointmentScreen: "/appointments",
      patients: "/patients/:officeId",
      patientDetail: "/patient-detail/:officeId/:patientId",
      patientNotes: "/patient-notes/:officeId/:patientId",
      addPatientNotes: "/add-patient-notes/:officeId/:patientId",
      patientMessageCenter:
        "/patient-message-center/:officeId/:patientId?/:appointmentId?",
      patientNewMessage:
        "/patient-new-message/:officeId/:patientId?/:appointmentId?",
      createMessageHistoryTemplates:
        "/create-message-history-template/:officeId/:patientId?",
      patientViewTemplate: "/patient-view-template",
      patientStatus: "/patient-status/:officeId/:patientId",
      patientNoteDetail:
        "/patient-note-detail/:officeId/:patientId/:patientNoteId",
      manageSchedule: "/manage-schedule",
      urbanDental: "/urban-dental",
      officeSelection: "/office-manage-schedule/:officeId",
      createAppointment: "/create-appointment/:officeId/:requestId",
      appointmentType: "/appointment-type/:officeId/:requestId",
      appointmentReminder: "/appointment-reminder/:officeId/:requestId",
      createMessageTemplates: "/create-message-template/:officeId/:requestId",
      trackSchedule: "/track-schedule-change/:officeId?",
      recallAppointment: "/create-recall-appointment",
      selectNextRecall: "/select-next-recall",
      selectDateTime: "/select-date-time",
      addNewAppointment: "/add-new-appointment/:appointmentId",
      submittedQuestionnaire: "/submitted-questionnaire/:appointmentId",
      covingQuestionnaire: "/covid-questionnaire/:appointmentId",
      patientAppointmentDetails: "/patient-appointment-details/:appointmentId",
      acceptPatientAppointment: "/accept-patient-appointment/:appointmentId",
      patientAppointmentType: "/patient-appointment-type/:appointmentId",
      patientReminderPlan: "/patient-reminder-plan",
      feedbackConfirmation: "/feedback-confirmation/:officeId/:appointmentId",
      editRecall: "/edit-recall",
      reassignAppointment: "/reassign-appointment/:appointmentId/:officeId",
      rescheduleAppointment: "/reschedule-appointment/:appointmentId/:officeId",
      reassignDateTime: "/reassign-date-time",
      addAppointmentReminderPlan: "/add-reminder-plan/:officeId/:requestId",
      seeTodaySchedule: "/see-today-schedule",
      selectAppointmentType: "/select-appointment-type",
      selectReminderPlan: "/select-reminder-plan",
      patientNewAppointment: "/patient-new-appointment/:officeId?",
      selectPatientAppointmentType: "/select-patient-appointment-type",
      selectPatientAppointmentReminderPlan:
        "/select-patient-appointment-reminder-plan",
      linkToNextRecall: "/link-to-next-recall/:appointmentId",
      recall: "/recall/:appointmentId",
      reminder: "/reminder/:appointmentId/:officeId",
      recallPatientAppointmentType: "/recall-patient-appointment-type",
      recallPatientReminderPlan: "/recall-patient-reminder-plan",
      pastPatientReminderPlan: "/past-patient-reminder-plan",
      createRecallPatientAppointment:
        "/create-recall-patient-appointment/:appointmentId",
      // recallReminderPlan:"/past-recall-reminder-plan"
      recallReminderEdit: "/recall-reminder-edit/:appointmentId/:officeId",
      nextEditRecall: "/next-edit-recall/:appointmentId/:officeId",
      globalReminder: "/global-reminder/:officeId/:patientId",
      patientAppointmentReminder:
        "/patient-appointment-reminder/:officeId?/:patientId?",
      patientAddAppointmentReminderPlan:
        "/patient-add-reminder-plan/:officeId?/:patientId?",
      AccountGlobalReminder: "/global-reminder",
      AccountGlobalOfficeReminder: "/global-office-reminder/:officeId",
    },

    scheduler: {
      calendar: "/scheduler",
      addEvent: "/add-event",
      editEvent: "/edit-event/:eventId",
      deleteEvent: "/delete-event/:eventId",
      eventDetails: "/event-details/:eventId",
      eventRequestDetails: "/event-request-details/:eventId",
      EventWorkingDetails: "/event-working-details/:eventId",
      addBusySlot: "/add-busy-slots",
      editBusySlot: "/edit-busy-slots/:busySlotId",
      busySlotDetail: "/busy-slot-detail/:busySlotId",
      eventDetailsOnly: "/event-details-only/:eventId",
      eventHistoryLogs: "/event-history-logs/:eventId",
    },

    dashboard: {
      managerDashboard: "/manager-dashboard",
    },

    notification: {
      notificationDetail: "/notification",
    },

    accountPreference: {
      messageTemplates: "/message-templates",
      addMessageTemplates: "/add-message-templates",
      editMessageTemplates: "/edit-message-templates/:templateId",
      viewMessageTemplates: "/view-message-template/:templateId",
    },

    help: "/help",
    faq: "/faq",
    onlineHelp: "/onlineHelp",
    feedback: "/feedback",
  },

  systemRoles: {
    superAdmin: 1,
    accountOwner: 2,
    staff: 3,
    patient: 4,
    vendor: 5,
  },

  localStorageKeys: {
    agenda: {
      staffFilter: "agenda-staff-filter",
      officeFilter: "agenda-office-filter",
      eventFilter: "agenda-event-filter",
      weeklySettingFilter: "view-setting-filter-weekly",
      monthlySettingFilter: "view-setting-filter-monthly",
      mySettingFilter: "my-view-setting-filter",
    },
    waitingListFormData: "request-waiting-list-form-data",
    requestAppointmentData: "request-appointment-form-data",
    staffAvailablilityFilter: "staff-availability-filter",
    doctorListLocation: "saved-geolocation",
    selectedChatAccountOwner: "selected-chat-account-owner",
    selectedChatAccountOwnerForPatient: "selected-chat-account-owner-patient",
    questionnaireResponse: "questionnaire-response",
    filledQuestionnaireData: "filled-questionnaire-data",
    selectedAppointmentDate: "selected-appointment-date",
    activeChatTab: "active-chat-tab",
    paymentMethod: "paymentMethod",
    persistOfficeFilter: "Selected_Offices",
  },

  helpPages: {
    onlineHelp: [
      { id: "OnlineHelpForStaff", role: 3 },
      { id: "OnlineHelpForAccountOwner", role: 2 },
      { id: "OnlineHelpForPatient", role: 4 },
    ],

    Faq: [
      { id: "FAQForStaff", role: 3 },
      { id: "FAQForAccountOwner", role: 2 },
      { id: "FAQForPatient", role: 4 },
    ],
  },

  regex: {
    // eslint-disable-next-line
    validURL:
      /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
    validIP:
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  },

  deviceTypes: [
    {
      id: 1,
      title: "Android",
    },
    {
      id: 2,
      title: "iOS",
    },
  ],

  notificationRedirectionCode: {
    eventAcceptAndReject: 136,
    eventJoinandInvite: 137,
    PulishEvent: 146,
    RequestToJoin: 144,
    EventEmployee: 145,
    EventRequestToJoin: 148,
    globalNotes: 121,
    globalNoteReminder: 120,
    cancaltask: 113,
    newComment: 119,
    newTaskassigned: 111,
    taskActions: 112,
    taskdelete: 126,
    ReviewSubmitted: 117,
    ReviewCompleted: 116,
    ReviewFinalized: 116,
    ReviewCancelled: 118,
    ReviewAssigned: 115,
    employmentProofRequest: 132,
    employementProofSubmitted: 133,
    notices: 114,
    newAppointmentRequest: 141,
    cancelAppointmentRequest: 142,
    busySlotDetail: 159,
    eventDetail: 145,
    timesheetViewDetail: 161,
  },

  appTypes: [
    {
      id: 1,
      title: "Personnel/Account Owner App",
    },
    {
      id: 2,
      title: "Patient App",
    },
    {
      id: 3,
      title: "Supply App",
    },
  ],

  timesheetStatuses: [
    {
      id: 1,
      title: "Pending",
    },
    {
      id: 2,
      title: "Waiting for Approval",
    },
    {
      id: 3,
      title: "Approved",
    },
    {
      id: 4,
      title: "Rejected",
    },
    {
      id: 5,
      title: "Paid",
    },
  ],

  SchedulerStatuses: [
    {
      id: 1,
      title: "pending",
    },
    {
      id: 2,
      title: "accepted",
    },
    {
      id: 3,
      title: "rejected",
    },
    {
      id: 4,
      title: "cancelled",
    },
  ],
  SchedulerStatuseWorkingEvents: [
    {
      id: 1,
      title: "Pending",
    },
    {
      id: 2,
      title: "Accepted",
    },
    {
      id: 3,
      title: "Rejected",
    },
  ],

  upComingAppointmentStatus: [
    {
      id: 1,
      title: "Pending",
    },
    {
      id: 2,
      title: "Accepted",
    },
    {
      id: 7,
      title: "Confirmed",
    },
  ],

  pastAppointmentStatus: [
    {
      id: 3,
      title: "Rejected",
    },
    {
      id: 4,
      title: "Cancelled",
    },
    {
      id: 5,
      title: "Completed",
    },
    {
      id: 6,
      title: "No Show",
    },
  ],

  recallAppointmentStatus: [
    {
      id: 8,
      title: "Overdue",
    },
    {
      id: 9,
      title: "Upcoming",
    },
    {
      id: 10,
      title: "Converted",
    },
  ],

  appointmentTimeTypes: {
    Morning: 1,
    Afternoon: 2,
    Evening: 3,
    Anytime: 4,
  },

  completedAppointmentDateTime: [
    {
      name: "Custom Date",
      id: 1,
    },
    {
      name: "1 Week After Completed Appointment",
      id: 7,
    },
    {
      name: "2 Week After Completed Appointment",
      id: 14,
    },
    {
      name: "3 Week After Completed Appointment",
      id: 21,
    },
    {
      name: "1 Month After Completed Appointment",
      id: 30,
    },
    {
      name: "2 Month After Completed Appointment",
      id: 60,
    },
    {
      name: "3 Month After Completed Appointment",
      id: 90,
    },
    {
      name: "1 Year After Completed Appointment",
      id: 365,
    },
  ],

  googleDoctorsStatusFilter: {
    All: 1,
    Pending: 2,
    Completed: 3,
  },

  agendaType: {
    APPOINTMENT: 1,
    LEAVE: 2,
    BUSY_SLOT: 3,
    EVENT: 4,
    BLOCKED: 5,
  },

  appointmentStatus: {
    Pending: 1,
    Accepted: 2,
    Rejected: 3,
    Cancelled: 4,
    Completed: 5,
    NoShow: 6,
    Confirmed: 7,
    Overdue: 8,
    Upcoming: 9,
    Converted: 10,
  },

  agendaTitleLength: 80,

  SCHEDULERSTATUS: {
    PENDING: 1,
    ACCEPT: 2,
    REJECT: 3,
  },

  SCHEDULEREVENTTYPE: {
    All: "FOR_JOIN_ALL_FUTURE_EVENTS",
    SPECFIC: "FOR_JOIN_SPECFIC_EVENTS",
    SINGLEDAY: 1,
    SPECFICDATES: 4,
    AllWeekDay: 2,
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
    REASONOFREJECT: "REASONOFREJECT",
  },
  titles: [
    {
      id: 2,
      text: "Mr.",
    },
    {
      id: 5,
      text: "Ms.",
    },
    {
      id: 1,
      text: "Dr.",
    },
    {
      id: 6,
      text: "None",
    },
  ],

  titleIds: {
    mr: 2,
    ms: 5,
    dr: 1,
  },

  animation: {
    hoverScale: 1.05,
  },

  lsKeys: {
    bookAppointmentData: "book_appointment_data",
    staffOnlineToast: "staffOnlineToast",
    OwnerOnlineToast: "OwnerOnlineToast",
  },
  containerName: {
    CONTAINER_NAME_CONTRACTS: "contracts",
    CONTAINER_NAME_CONTRACTS_SIGNATURE: "contract-signatures",
  },
  appLinks: {
    patientAndroidAppStoreLink:
      process.env.REACT_APP_PATIENT_ANDROID_APP_STORE_LINK,
    patientIosAppStoreLink: process.env.REACT_APP_PATIENT_IOS_APP_STORE_LINK,
  },

  sikkaMiraxisUrl: process.env.REACT_APP_SIKKA_MIRAXIS_URL || "",

  NOTIFICATIONREADED: "NOTIFICATIONREADED",
  NOTIFICATIONUNREAD: "NOTIFICATIONUNREAD",
  SUBSCRIPTIONMODULEADDED: "SUBSCRIPTIONMODULEADDED",
  SUBSCRIPTIONMODULEREMOVED: "SUBSCRIPTIONMODULEREMOVED",
  OWNERMESSAENGERMODULEADDED: "OWNERMESSAENGERMODULEADDED",
  OWNERMESSAENGERMODULEREMOVED: "OWNERMESSAENGERMODULEREMOVED",

  wordLimits: {
    REJECTOINREQUESTJOIN: 400,
    ADDOFFICEADDRESS: 150,
  },

  MAPKEY: {
    COUNTRY: "country",
    ADMINISTRATIVE: "administrative_area_level_1",
    LOCALITY: "locality",
    POSTALCODE: "postal_code",
  },

  GOOGLEPLACETYPE: [
    "doctor",
    "dentist",
    "hospital",
    "pharmacy",
    "physiotherapist",
  ],
  INITIAL_GOOGLE_RADIUS: 10000,
  EXTENDED_GOOGLE_RADIUS: 20000,
  COUNTRY: {
    US: "US",
    CA: "CA",
    USLATLNG: { lat: 38.34, lng: -100.69 },
    CALATLNG: { lat: 57.89, lng: -101.61 },
    CABOUNDS: {
      north: 83.23324,
      south: 41.6751050889,
      west: -140.99778,
      east: -52.6480987209,
    },
    USBOUNDS: {
      north: 71.3577635769,
      south: 18.91619,
      west: -171.791110603,
      east: -66.96466,
    },
  },
  NOTINGOOGLE: "NOTINGOOGLE",
  ADDOFFICEADDRESS: 150,
  messageLimit: 400,

  JOBTYPE: {
    Temporary: 1,
    Permanent: 2,
  },

  DOWNLOADLINK: {
    playStore:
      "https://play.google.com/store/apps/details?id=com.miraxis.healthhubapp&hl=en_IN&gl=US",
    appStore: "https://apps.apple.com/in/app/miraxis-healthhub/id1548418438",
  },
  DOWNLOADLINKFORPATIENT: {
    playStore:
      "https://play.google.com/store/apps/details?id=com.miraxis.healthhubpatientapp",
    appStore: "https://apps.apple.com/be/app/miraxis-patients/id1614384418",
  },
  DOWNLOADLINKFORSUPPLY: {
    playStore:
      "https://play.google.com/store/apps/details?id=com.miraxis.healthhubsupply",
    appStore: "https://apps.apple.com/us/app/miraxis-supply/id6451264069",
  },
  weekday: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],

  chat: {
    internal: "internal",
    external: "external",
    patientChat: "patient",
    teamLiveChat: "team_live",
    patient: {
      open: "PATIENT_MESSENGER_OPEN_CHAT",
      close: "PATIENT_MESSENGER_CLOSE_CHAT",
    },
    unArchived: "unhidden_only",
    archived: "hidden_only",
    latestLastMessage: "latest_last_message",
    chatListLimit: 100,
    chatGroupImageSizeInMbs: 10,
    ALL_OFFICES: 0,
    externalChatTitleLength: 120,
    groupNameLimit: 100,
    acceptForGroupImage: ".jpg,.jpeg,.png",
    acceptForMessageInput:
      ".jpg,.jpeg,.png,.pdf,.txt,.csv,.doc,.docx,.xls,.xlsx",
    allowedTypesForGroupImage: ["image/png", "image/jpg", "image/jpeg"],
    allowedTypesForMessage: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "text/csv",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    allowedTypesForDocs: [
      "text/csv",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
  vendor: {
    step: {
      addProfileDetails: "1",
      purchaseSubscription: "2",
      addCardDetails: "3",
      bankDetails: "4",
      completed: "completed",
    },
    productDetails: "product-details",
    productImageContainer: "product-images",
    acceptForProductImage: ".jpg,.jpeg,.png",
    productImageSizeInMbs: 5,
    allowedTypesForProductImage: ["image/png", "image/jpg", "image/jpeg"],
    cache: {
      salesRepList: "sales-rep-list-cache",
      catalogueList: "catalogue-list-cache",
      salesRepDetail: "sales-rep-detail-cache",
      manageOrderslisting: "manage-orders-listing-cache",
      promoCodesList: "promo-codes-list-cache",
      editingValue: "editing-value",
      manageOrders: "Manage_Orders",
      manageSalesRepAdmin: "Manage_Sales_Rep_Admin",
      orderDetail: "order-detail-cache",
      salesRepVendorInAdmin: "sales-rep-cache-in-venodr-admin",
      orderDetailsTabs: "order-details-tab-in-venodr-admin",
      orderHistoryFilter: "order-history-filter-cache",
      customerOrderList: "customer-order-list-cache",
      manageCustomersList: "manage-customers-list-cache",
      manageCommissionsCache: "Manage_Commissions_cache",
      supportHelpVendorCache: "Support_help_vendor-cache",
      superAdminTopUp: "super-admin-top-up-promotion-cache",
      promotionTabCache: "promotion-tab-cache",
      promotionPaginationCache: "promotion-tab-pagination-cache",
      vendorDashBoardDateCache: "Vendor-dashboard-date-cache",
      vendorInvoiceCache: "Vendor-Invoice-Cache",
      vendorEnterpriceCache: "Vendor-Enterprice-Cache",
      vendorEnterPriceAssignCache: "Vendor-Enterprice-Assign-Cache",
    },
    enums: {
      orderStatus: {
        all: 0,
        pending: 1,
        accepted: 2,
        shipped: 4,
        partiallyShipped: 5,
        delivered: 6,
        cancelled: 7,
        list: [
          { name: "All", id: null },
          { name: "Pending", id: 1 },
          { name: "Accepted", id: 2 },
          { name: "Partially Accepted", id: 3 },
          { name: "Shipped", id: 4 },
          { name: "Partially Shipped", id: 5 },
          { name: "Delivered", id: 6 },
          { name: "Cancelled", id: 7 },
        ],
      },
      paymentMethod: {
        billMeLater: 1,
        paidOnline: 2,
        list: [
          { id: null, name: "All" },
          { id: 1, name: "Bill Me Later" },
          { id: 2, name: "Online by Credit Card" },
        ],
      },
      paymentStatus: {
        notPaid: 1,
        paid: 2,
        list: [
          { id: null, name: "All" },
          { id: 1, name: "Not Paid" },
          { id: 2, name: "Paid" },
        ],
      },
    },
  },

  arrow: {
    NEXT: 1,
    PREVIOUS: 2,
  },

  PopUp: {
    innerPopUp: 1,
    outerPopUp: 2,
  },
  staffAvalibility: "staffAvalibility",
  staffAvalibilityDate: "staffAvalibilityDateKey",
  EVENT_DATE_SESSION_KEY: "Event_DATE_KEY",
  EVENT_OFFICE_FILTER: "EVENT_OFFICE_FILTER",
  EVENT_DISPLAY_SETTINGS: "EVENT_DISPLAY_SETTINGS",
  EVENT_TYPE_SESSION_KEY: "Event_TYPE_KEY",
  SCHEDULER_INVITATION_REQUEST_OFFICES: "SCHEDULER_INVITATION_REQUEST_OFFICES",
  SCHEDULER_INVITATION_REQUEST_STATUS: "SCHEDULER_INVITATION_REQUEST_STATUS",
  SCHEDULER_EVENT_SHIT_STATUS: "SCHEDULER_EVENT_SHIT_STATUS",

  ticketStatus: {
    resolved: 3,
    progress: 2,
    pending: 1,
  },
  calanderActions: ["next", "next2", "prev", "prev2"],
  calanderActionKey: {
    drillDown: "drillDown",
    year: "year",
  },
  cmsPageKey: {
    TermsAndConditions: "TermsAndConditions",
    PrivacyPolicy: "PrivacyPolicy",
    AboutUs: "AboutUs",
    Testimonials: " Testimonials",
    ContactUs: "ContactUs",
    Email: "Email",
    Phone: "Phone",
    Address: "Address",
    TermsAndConditionForContractTemplate:
      "TermsAndConditionForContractTemplate",
    TermsAndConditionOfContractForStaff: "TermsAndConditionOfContractForStaff",
    TermsAndConditionsForPatient: "TermsAndConditionsForPatient",
    PrivacyPolicyForPatient: "PrivacyPolicyForPatient",
    TermsAndConditionsForVendor: "TermsAndConditionsForVendor",
    PrivacyPolicyForVendor: "PrivacyPolicyForVendor",
    FAQForAccountOwner: "FAQForAccountOwner",
    OnlineHelpForPatient: "OnlineHelpForPatient",
    OnlineHelpForAccountOwner: "OnlineHelpForAccountOwner",
    OnlineHelpForStaff: "OnlineHelpForStaff",
    FAQForPatient: "FAQForPatient",
    FAQForStaff: "FAQForStaff",
    OurMission: "OurMission",
  },
  requestEventStatus: {
    Pending: 1,
    Accepted: 2,
    Reject: 3,
    Cancel: 4,
  },
  OnlineFaqPages: [
    "/owner/faq",
    "/patient/faq",
    "/staff/faq",
    "/owner/help",
    "/patient/help",
    "/staff/help",
    "/blogs/9-reasons-why-dentists-are-automating-their-practice",
    "/blogs/top-9-ways-for-you-and-your-staff-to-avoid-burnout",
    "/blogs/top-9-benefits-of-streamlining-and-automating-workflows-in-your-dental-practice",
    "/blogs/communication-with-your-team-how-to-be-hands-offand-still-be-hands-on",
    "/blogs/3-effective-ways-technology-can-help-you-efficiently-manage-staff-in-your-practice",
    "/blogs/8-ways-leading-dental-practices-enhance-their-staff-experience",
    "/blogs/6-ways-to-recruit-and-retain-practice-employees",
    "/landing-pages/guide-download-page",
    "/landing-pages/guide-thank-page",
    "/landing-pages/ebook-download-page",
    "/landing-pages/ebook-thank-page",
    "/getstarted",
    "/landing-pages/guide-download-page-fb",
    "/landing-pages/guide-download-page-ggl",
    "/landing-pages/guide-download-page-sm",
    "/landing-pages/ebook-download-page-fb",
    "/landing-pages/ebook-download-page-ggl",
    "/landing-pages/ebook-download-page-sm",
    "/demo-request-page",
    "/demo-request-thank-page",
  ],
  faqRequestType: {
    HelpGuideRequest: 1,
    ChecklistRequest: 2,
  },
  categoryType: {
    add: 1,
    edit: 2,
  },
  selectType: {
    selectAll: 1,
    selectQuantity: 2,
  },
  taxTypeModal: {
    add: 1,
    edit: 2,
  },
  taxType: {
    oneTax: 1,
    ProvienceTax: 2,
  },

  orderStatus: {
    all: 0,
    Pending: 1,
    Accepted: 2,
    PartiallyAccepted: 3,
    Shipped: 4,
    PartiallyShipped: 5,
    Delivered: 6,
    Cancelled: 7,
    UnPaid: 8,

    newShipment: true,
  },

  orderStatusTitle: [
    {
      title: "Pending",
      value: 1,
    },
    {
      title: "Accepted",
      alt: "Not Shipped",
      value: 2,
    },
    {
      title: "Partially Accepted",
      value: 3,
    },
    {
      title: "Shipped",
      value: 4,
    },
    {
      title: "Partially Shipped",
      value: 5,
    },
    {
      title: "Delivered",
      value: 6,
    },
    {
      title: "Cancelled",
      value: 7,
    },
  ],

  paymentMethod: [
    { value: 1, title: "Bill Me Later" },
    { value: 2, title: "Online by Credit Card" },
  ],

  paymentStatus: [
    { value: 1, title: "Not Paid" },
    { value: 2, title: "Paid" },
  ],

  paymentStatusObj: {
    paid: 2,
    notPaid: 2,
  },

  paymentMethodObj: {
    billMeLater: 1,
    paid: 2,
  },

  orderDetails: {
    ACCEPT: "ACCEPT",
    DECLINE: "DECLINE",
    AcceptPartially: "ACCEPTPARTIALLY",
  },
  vendorDetailsTab: {
    orderHistory: "1",
    salesRep: "2",
  },

  hubspotFormPages: {
    ebookDownloadPage: "ebookDownloadPage",
    fbHubspotPage: "fbHubspotPage",
    gglHubspotPage: "gglHubspotPage",
    smHubspotPage: "smHubspotPage",
    guideDownloadPage: "guideDownloadPage",
    fbGuideHubspotPage: "fbGuideHubspotPage",
    gglGuideHubspotPage: "gglGuideHubspotPage",
    smGuideHubspotPage: "smGuideHubspotPage",
  },
  vendorTicketType: [
    {
      value: null,
      label: "All Tickets",
      class: "",
    },
    {
      value: 1,
      label: "Pending",
      class: "pending",
    },
    {
      value: 2,
      label: "In Progress",
      class: "inProgress",
    },
    {
      value: 3,
      label: "Resolved",
      class: "resolved",
    },
  ],

  genderOptions: [
    { name: "Male", id: 1 },
    { name: "Female", id: 2 },
    { name: "Other", id: 3 },
  ],

  relationOptions: [
    { name: "Father ", id: 1 },
    { name: "Mother", id: 2 },
    { name: "Daughter", id: 3 },
    { name: "Son", id: 4 },
    { name: "Husband", id: 5 },
    { name: "Wife", id: 6 },
    { name: "Others", id: 7 },
  ],

  notes: {
    cache: {
      Noteslisting: "notes-listing-cache",
      archivedListing: "archived-listing-cache",
      myNotesFilter: "my-notes-filter-cache",
      transferedNoteFilter: "transferred-notes-filter-cache",
      dashboardFilter: "dash-board-filter-cache",
      dashboardAccountOwnerCheck: "dashboard-account-owner-check",
      isMySchedule: "dashboard-isMySchedule",
      myScheduleView: "dashboard-myScheduleView",
      otherScheduleView: "dashboard-otherScheduleView",
      isChatFromDashBoard: "isChatFromDashBoard",
    },
  },

  waitingList: {
    cache: {
      filters: "waiting-list-filter-cache",
    },
  },

  appointmentList: {
    cache: {
      upCommingfilters: "appointment-list-upComingfilter-cache",
      recallfilters: "appointment-list-recallfilter-cache",
      pastfilters: "appointment-list-pastfilter-cache",
      todaysFilter: "appointment-list-todaysfilter-cache",
      isTabClicked: "isClicked",
    },
  },

  patientsList: {
    cache: {
      filters: "patients-list-filter-cache",
      patientList: "patient-list-cache",
      patientListMessageHistory: "patient-list-message-history",
    },
  },

  performanceReview: {
    cache: {
      performanceReviewlisting: "performance-review-listing-cache",
      performanceArchivedListing: "performance-archived-listing-cache",
      performanceReviewFilter: "performance-review-filter",
    },
  },

  employementProof: {
    cache: {
      employementProofList: "employement-proof-listing-cache",
    },
  },

  notesTabStatus: {
    myNote: 1,
    transfferedNote: 2,
  },

  requestApprovalTabStatus: {
    requestsInvitations: 1,
    Notifications: 2,
  },

  employementProofTab: {
    ongoing: 1,
    archived: 2,
  },

  performanceReviewTab: {
    initiatedByYou: 1,
    initiatedByAdmin: 2,
  },

  patientAppointMentTab: {
    upCommingAppointment: 1,
    recallAppointment: 2,
    pastAppointment: 3,
  },

  patientScheduling: {
    cache: {
      eventlisting: "event-listing-cache",
    },
  },

  patientsTab: {
    myPatients: 1,
    otherDoctors: 2,
  },

  familyMembers: {
    cache: {
      familyMemberlisting: "family-member-listing-cache",
      familyMemberAddedYoulisting: "family-member-listing-cache",
      selectedMemberData: "selected-member-data",
    },
    inviteStatus: {
      Pending: 1,
      Accepted: 2,
      Rejected: 3,
      NotRegister: 4,
    },
  },

  familyMemberTabStatus: {
    addedMembers: 1,
    membersAddedYou: 2,
  },

  subscriptionType: {
    basic: 1,
    advanced: 2,
    professional: 3,
    free: 4,
    trial: 5,
    enterprise: 6,
  },
  subscriptionTypesArray: [1, 2, 3],
  sessionStoragecache: {
    subscriptionDetails: "subscription-plan-Details-cache-tab",
    officeKey: "OFFFICE_KEY_CACHE",
    ownerEnterPriseListng: "owner-EnterPrise-Listng-cache",
  },

  subscriptionModuleType: {
    Default: 1,
    LinkedWithFeature: 2,
    Textual: 3,
  },

  OfficeTimesheetStatus: [
    {
      value: null,
      label: "All",
    },
    {
      value: 1,
      label: "Pending For Approval",
    },
    {
      value: 2,
      label: "Approved",
    },
    {
      value: 3,
      label: "Rejected",
    },
    {
      value: 4,
      label: "Partially Paid",
    },
    {
      value: 5,
      label: "Paid",
    },
    {
      value: 6,
      label: "Pending",
    },
  ],

  TimesheetListingStatus: [
    {
      value: null,
      label: "All",
    },
    {
      value: 2,
      label: "Pending For Approval",
    },
    {
      value: 3,
      label: "Approved",
    },
    {
      value: 4,
      label: "Rejected",
    },
    {
      value: 5,
      label: "Paid",
    },
  ],

  TimesheetType: {
    AllDay: 1,
    Hourly: 2,
  },

  Timesheet: {
    cache: {
      staffMemberTimesheetlisting: "staff-member-timesheet-listing-cache",
      timesheetListingCache: "timesheet-listing-cache",
      timesheetReportCache: "timesheet-report-cache",
      staffTimesheetDetailListing: "staff-timesheet-detail-listing",
    },
  },

  LeaveStatus: [
    {
      value: null,
      label: "All",
    },
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Approved",
    },
    {
      value: 3,
      label: "Rejected",
    },
  ],

  LeaveTypeStatus: [
    {
      value: null,
      label: "All",
    },
    {
      value: 1,
      label: "Casual",
    },
    {
      value: 2,
      label: "Medical",
    },
    {
      value: 3,
      label: "Vacation",
    },
  ],

  Leave: {
    cache: {
      staffLeavelisting: "staff-leave-listing-cache",
      leaveListing: "leave-listing-cache",
    },
  },

  leavesAndTimesheet: {
    cache: {
      list: "list-leaves-and-timesheet",
    },
  },

  pmsMembers: {
    cache: {
      pmsMemberlisting: "pms-member-listing-cache",
      pmsDoctorslisting: "pms-doctors-listing-cache",
      pmsPatientslisting: "pms-patients-listing-cache",
    },
  },

  messageTemplates: {
    cache: {
      list: "message-templates-list-cache",
    },
  },

  pmsMemberTabStatus: {
    doctors: 1,
    patients: 2,
  },

  SikkaUserInvitationStatus: {
    new: 1,
    inviteSent: 2,
  },

  WeekDays: [
    {
      id: 7,
      day: " Sun ",
    },
    {
      id: 1,
      day: " Mon ",
    },
    {
      id: 2,
      day: " Tue ",
    },
    {
      id: 3,
      day: " Wed ",
    },
    {
      id: 4,
      day: " Thu ",
    },
    {
      id: 5,
      day: " Fri ",
    },
    {
      id: 6,
      day: " Sat ",
    },
  ],

  subscriptionPlan: [
    { value: 1, name: "Basic" },
    { value: 2, name: "Advanced" },
    { value: 3, name: "Professional" },
    { value: 4, name: "Free" },
    { value: 5, name: "Trial" },
    { value: 6, name: "Enterprise " },
  ],

  currenyArray: [
    { value: 1, name: "CAD" },
    { value: 2, name: "USD" },
  ],

  confirmedThrough: {
    email: 1,
    sms: 2,
    mobile: 3,
  },

  curreny: {
    CAD: 1,
    USD: 2,
  },
  radioTypeSubscription: {
    SINGLE: 1,
    MULTIPLE: 2,
  },
  moduleNameWithId: {
    formAndContracts: 15,
    teamLiveChat: 11,
    scheduler: 9,
    patientLiveChat: 22,
  },
  pageSize: 10,
  tasks: {
    cache: {
      taskslisting: "tasks-listing-cache",
      accountownercheck: "account-owner-check",
      myTaskFilter: "my-tasks-filter-cache",
      staffTaskFilter: "staff-tasks-filter-cache",
      archivedListing: "archived-task-listing",
    },
  },
};

export const PERFORMANCE_REVIEW_FORM_TYPES = [
  "general",
  "probationPeriod",
  "monthlyReview",
  "quarterlyReview",
  "yearlyReview",
  "others",
];

export const PERFORMANCE_REVIEW_STATUS = [
  {
    id: 1,
    title: "pending",
  },
  {
    id: 2,
    title: "submitted",
  },
  {
    id: 3,
    title: "completed",
  },
  {
    id: 4,
    title: "cancelled",
  },
  {
    id: 5,
    title: "finalized",
  },
];

export const ReviewStatus = {
  pending: 1,
  submitted: 2,
  completed: 3,
  cancelled: 4,
  finalized: 5,
};

export const notifyAttendeesOptions = [
  {
    id: 5,
    name: "5 minutes",
  },
  {
    id: 10,
    name: "10 minutes",
  },
  {
    id: 15,
    name: "15 minutes",
  },
  {
    id: 20,
    name: "20 minutes",
  },
  {
    id: 30,
    name: "30 minutes",
  },
  {
    id: 60,
    name: "1 hour",
  },
  {
    id: 120,
    name: "2 hours",
  },
  {
    id: 180,
    name: "3 hours",
  },
  {
    id: 240,
    name: "4 hours",
  },
  {
    id: 300,
    name: "5 hours",
  },
  {
    id: 360,
    name: "6 hours",
  },
  {
    id: 1440,
    name: "1 day",
  },
  {
    id: 2880,
    name: "2 days",
  },
  {
    id: 4320,
    name: "3 days",
  },
  {
    id: 5760,
    name: "4 days",
  },
  {
    id: 10080,
    name: "1 week",
  },
  {
    id: 0,
    name: "Do not notify",
  },
];

export const notifyAttendeesOptionsForAllDayEvent = [
  {
    id: -420,
    name: "07:00 AM on the day of the event",
  },
  {
    id: -480,
    name: "08:00 AM on the day of the event",
  },
  {
    id: -540,
    name: "09:00 AM on the day of the event",
  },
  {
    id: 1440,
    name: "1 day",
  },
  {
    id: 2880,
    name: "2 days",
  },
  {
    id: 4320,
    name: "3 days",
  },
  {
    id: 5760,
    name: "4 days",
  },
  {
    id: 10080,
    name: "1 week",
  },
];

export const isModuleAccessable = (arrayOfModules, currentModuleId) => {
  try {
    if (Array.isArray(arrayOfModules) && arrayOfModules.length) {
      let module = arrayOfModules.find((item) => item.id === currentModuleId);
      if (module) {
        return module?.isAvailable;
      }
    }
    return false;
  } catch (err) {
    console.log(err);
  }
};

export function getcurreny(id) {
  let currency = constants.currenyArray.find((item) => item.value == id)?.name;
  return currency ? currency : "--";
}

export function getsubcriptionPlanTitle(id) {
  let subName = constants.subscriptionPlan.find(
    (item) => item.value == id
  )?.name;
  return subName ? subName : "--";
}

export function getDeviceTypeById(deviceTypeId) {
  let deviceType = constants.deviceTypes.find((it) => it.id === deviceTypeId);
  return deviceType === undefined ? { id: 0, title: "Unknown" } : deviceType;
}

export function getAppTypeById(appTypeId) {
  let appType = constants.appTypes.find((it) => it.id === appTypeId);
  return appType === undefined ? { id: 0, title: "Unknown" } : appType;
}

export function getTimesheetStatusById(timesheetStatusId) {
  let timesheetStatus = constants.timesheetStatuses.find(
    (it) => it.id === timesheetStatusId
  );
  return timesheetStatus === undefined
    ? { id: 0, title: "Unknown" }
    : timesheetStatus;
}

export function getSchedulerStatusById(schedulerStatusId) {
  let schedulerStatus = constants.SchedulerStatuses.find(
    (it) => it.id === schedulerStatusId
  );
  return schedulerStatus === undefined
    ? { id: 0, title: "Unknown" }
    : schedulerStatus.title;
}

export const orderStatus = (statusId, alt = false) => {
  const status = constants.orderStatusTitle.find(
    (orderStatusTitle) => orderStatusTitle.value === statusId
  );
  if (status) {
    return alt ? status?.alt : status?.title;
  }

  return "Unknown";
};

export const paymentMethodStatus = (paymentMethodId) => {
  const status = constants.paymentMethod.find(
    (paymentMethod) => paymentMethod.value === paymentMethodId
  );
  if (status) {
    return status?.title;
  }

  return "Unknown";
};

export const paymentStatus = (paymentMethodId) => {
  const status = constants.paymentStatus.find(
    (item) => item.value === paymentMethodId
  );
  if (status) {
    return status?.title;
  }

  return "Unknown";
};

export const getClassNameVenodorTicket = (ticketStatus) => {
  let className = constants.vendorTicketType.find(
    (item) => item.value === ticketStatus
  )?.class;
  if (className) return className;
  else return "";
};

export const getStatusVenodorTicket = (ticketStatus) => {
  let className = constants.vendorTicketType.find(
    (item) => item.value === ticketStatus
  )?.label;
  if (className) return className;
  else return "--";
};

export const getKeyValueFromList = (list, id, key) => {
  const status = list.find((item) => item.id === id);
  if (status) {
    return status?.[key];
  }

  return "--";
};

/**
 * Gets the notification name from the array based on the provided time in minutes
 * @param {number} timeInMinutes - The time value in minutes to look up
 * @param {Array} optionsArray - The array of notification options to search in
 * @returns {string} - The name of the matching notification option or "Unknown notification time" if not found
 */
export const getNotificationNameById = (timeInMinutes, optionsArray) => {
  const option = optionsArray.find((item) => item.id === timeInMinutes);
  return option ? option.name : "--";
};

export const taskStatusOptions = [
  {
    value: "",
    label: "All Tasks",
  },
  {
    value: 1,
    label: "In Progress",
  },
  {
    value: 2,
    label: "Pending",
  },
  {
    value: 3,
    label: "Accepted",
  },
  {
    value: 4,
    label: "Started",
  },
  {
    value: 5,
    label: "Completed",
  },
  {
    value: 6,
    label: "Rejected",
  },
  {
    value: 7,
    label: "Cancelled",
  },
];

export const prioritiesOptions = [
  { id: 3, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 1, name: "High" },
];

export const sortByOptions = [
  {
    value: 2,
    label: "Creation Date",
    className: "creation-date-option",
  },
  {
    value: 1,
    label: "Due Date",
    className: "due-date-option",
  },
];

export const taskTabStatus = {
  assigntoMe: 1,
  assignedByMe: 2,
};
export const reasonsList = [
  { value: 1, label: "Absenteeism/Insubordination" },
  { value: 2, label: "Tardiness" },
  { value: 3, label: "PolicyOrProcedureViolation" },
  { value: 4, label: "Behavorial" },
  { value: "others", label: "Others" },
];

export const Daysarray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const PofileColors = [
  "#8C0346",
  "#367B60",
  "#6E2995",
  "#42738C",
  "#220953",
  "#96731A",
  "#606015",
  "#1338AB",
  "#293E39",
  "#B55234",
];

export const REVEIWQUESTIONLIST = [
  {
    id: 6346,
    question: {
      id: 1,
      questionTitle: "Job Knowledge & Technical Skills",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 1,
        ratingValue: 1,
        ratingDescription:
          "Has difficulty understanding work duties and/or computer systems.",
      },
      {
        id: 2,
        ratingValue: 2,
        ratingDescription:
          "Needs to increase knowledge of some phases of work and/or computer system.",
      },
      {
        id: 3,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements. Actively upgrades application of skills and knowledge.",
      },
      {
        id: 4,
        ratingValue: 4,
        ratingDescription:
          "Better than average skills. Understands job and technical skills required.",
      },
      {
        id: 5,
        ratingValue: 5,
        ratingDescription:
          "Excellent.  Has complete mastery of all phases of job.",
      },
    ],
  },
  {
    id: 6347,
    question: {
      id: 2,
      questionTitle: "Organization/Dependability",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 6,
        ratingValue: 1,
        ratingDescription:
          "Disorganized; cannot take direction. Requires close supervision; is unreliable.",
      },
      {
        id: 7,
        ratingValue: 2,
        ratingDescription:
          "Learning how to take direction and prioritize. Sometimes requires prompting.",
      },
      {
        id: 8,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements. Approaches work in an orderly fashion. Usually takes care of necessary tasks and completes with reasonable promptness.",
      },
      {
        id: 9,
        ratingValue: 4,
        ratingDescription:
          "Better than average most of the time. Can research source materials quickly. Requires little prompting; is reliable.",
      },
      {
        id: 10,
        ratingValue: 5,
        ratingDescription:
          "Excellent. Requires no supervision. Thrives under pressure and solving crises; dependable.\r\n",
      },
    ],
  },
  {
    id: 6348,
    question: {
      id: 3,
      questionTitle: "Deadlines/Accuracy/Standards",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 11,
        ratingValue: 1,
        ratingDescription:
          "Disorderly, does not check work, makes frequent errors, never meets deadlines",
      },
      {
        id: 12,
        ratingValue: 2,
        ratingDescription:
          "Tendency to be careless and then miss deadlines. Standards in work production could be improved.",
      },
      {
        id: 13,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements. Meets deadlines, avoids careless errors. Follows-up on outstanding issues.",
      },
      {
        id: 14,
        ratingValue: 4,
        ratingDescription:
          "Better than average. Requires little or no supervision. Is exact and precise most of the time. ",
      },
      {
        id: 15,
        ratingValue: 5,
        ratingDescription:
          "Excellent. Requires no supervision. Accurate and precise. Follows-up on own initiative.",
      },
    ],
  },
  {
    id: 6349,
    question: {
      id: 4,
      questionTitle: "Problem Solving",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 16,
        ratingValue: 1,
        ratingDescription: "Has difficulty solving problems.",
      },
      {
        id: 17,
        ratingValue: 2,
        ratingDescription: "Attempts to recognize a problem and rectify it.",
      },
      {
        id: 18,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements. Recognizes problems; offers solutions.",
      },
      {
        id: 19,
        ratingValue: 4,
        ratingDescription:
          "Better than average. Acts independently in resolving problems; recommends appropriate action.",
      },
      {
        id: 20,
        ratingValue: 5,
        ratingDescription:
          "Excellent. Determines cause of problem; considers alternatives; reliable action taken",
      },
    ],
  },
  {
    id: 6350,
    question: {
      id: 5,
      questionTitle: "Relationships – Patients and Public",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 21,
        ratingValue: 1,
        ratingDescription: "Discourteous, antagonistic, unprofessional manner.",
      },
      {
        id: 22,
        ratingValue: 2,
        ratingDescription:
          "Inconsistent in approach, too informal, lacking confidence.",
      },
      {
        id: 23,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements. Good interaction, tactful, courteous, polite, professional.",
      },
      {
        id: 24,
        ratingValue: 4,
        ratingDescription:
          "Better than average. Demonstrates consideration, caring, effective interaction.\r\n",
      },
      {
        id: 25,
        ratingValue: 5,
        ratingDescription:
          "Excellent. Confident, helpful. Interaction complimented by patients",
      },
    ],
  },
  {
    id: 6351,
    question: {
      id: 6,
      questionTitle: "Relationships – Staff",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 21,
        ratingValue: 1,
        ratingDescription: "Discourteous, antagonistic, unprofessional manner.",
      },
      {
        id: 22,
        ratingValue: 2,
        ratingDescription:
          "Inconsistent in approach, too informal, lacking confidence.",
      },
      {
        id: 23,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements. Good interaction, tactful, courteous, polite, professional.",
      },
      {
        id: 24,
        ratingValue: 4,
        ratingDescription:
          "Better than average. Demonstrates consideration, caring, effective interaction.\r\n",
      },
      {
        id: 25,
        ratingValue: 5,
        ratingDescription:
          "Excellent. Confident, helpful. Interaction complimented by patients",
      },
    ],
  },
  {
    id: 6352,
    question: {
      id: 7,
      questionTitle: "Attendance",
      typeOfResponse: 3,
    },
    ratingCriteria: [
      {
        id: 26,
        ratingValue: 1,
        ratingDescription: "Unhelpful and uncooperative.",
      },
      {
        id: 27,
        ratingValue: 2,
        ratingDescription:
          "Approachable, friendly.  Needs to embrace team concept.",
      },
      {
        id: 28,
        ratingValue: 3,
        ratingDescription:
          "Meets requirements.  Warm, friendly, helpful, comfortable in team setting.",
      },
      {
        id: 29,
        ratingValue: 4,
        ratingDescription:
          "Better than average in helpfulness and courtesy and tone. Shares knowledge with co-workers.",
      },
      {
        id: 30,
        ratingValue: 5,
        ratingDescription:
          "Excellent. Consistently cheerful in attitude. Accommodating and supportive to all co-workers.",
      },
    ],
  },
];

export default constants;
