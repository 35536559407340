import axios from "axios";
import { useQueries, useQuery } from "react-query";
import { Config } from "../config";

const baseUrl = Config.serviceUrls.officeBaseUrl;
const taskManagementBaseUrl = Config.serviceUrls.taskManagementBaseUrl;
const OFFICE_POINT_URL = Config.serviceUrls.officeBaseUrl;

export async function getAllTasks(
  pageNumber,
  pageSize,
  appliedOfficeFilters,
  appliedStaffFilters,
  searchValue,
  isArchive,
  appliedPriorityFilters,
  appliedStatusFilters,
  sortBy,
  accountOwnerId
) {
  const params = new URLSearchParams({
    pageNumber,
    pageSize,
    searchTerm: searchValue,
    showArchived: isArchive,
    dateSortBy: sortBy,
  });

  // Append array parameters correctly
  if (appliedOfficeFilters?.length) {
    appliedOfficeFilters.forEach((id) => params.append("officeIds", id));
  }
  if (appliedStaffFilters?.length) {
    appliedStaffFilters.forEach((id) => params.append("UserIds", id));
  }
  if (appliedPriorityFilters?.length) {
    appliedPriorityFilters.forEach((priority) =>
      params.append("priorities", priority)
    );
  }
  if (appliedStatusFilters?.length) {
    appliedStatusFilters.forEach((status) => params.append("statuses", status));
  }
  if (accountOwnerId) {
    params.append("accountOwnerId", accountOwnerId)
  }

  // Make GET request with query parameters
  const response = await axios.get(
    `${taskManagementBaseUrl}/Task/list?${params.toString()}`
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return {
    items: response.data.data,
    pagination: response.data.pagination,
  };
}

export function useAllTasks(
  pageNumber,
  pageSize,
  appliedOfficeFilters,
  appliedStaffFilters,
  searchValue,
  isArchive,
  appliedPriorityFilters,
  appliedStatusFilters,
  sortBy,
  accountOwnerId,
  options = {}
) {
  return useQuery(
    [
      "/tasks/accountowner",
      pageNumber,
      pageSize,
      appliedOfficeFilters,
      appliedStaffFilters,
      searchValue,
      isArchive,
      appliedPriorityFilters,
      appliedStatusFilters,
      sortBy,
      accountOwnerId,
    ],
    () =>
      getAllTasks(
        pageNumber,
        pageSize,
        appliedOfficeFilters,
        appliedStaffFilters,
        searchValue,
        isArchive,
        appliedPriorityFilters,
        appliedStatusFilters,
        sortBy,
        accountOwnerId
      ),
    options
  );
}

export const addNewTask = async (params) => {
  const response = await axios.post(`${taskManagementBaseUrl}/Task`, params);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const EditTask = async (params) => {
  const response = await axios.put(`${taskManagementBaseUrl}/Task`, params);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const getStaffList = async (ownerId) => {
  const response = await axios.post(
    `${taskManagementBaseUrl}/Task/FilterOptions/Staff`,
    {
      ownerId: ownerId,
    }
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data;
};

export async function filterOptionsStaff(accountOwnerId, taskId) {
  let staffBaseUrl = `${taskManagementBaseUrl}/Task/FilterOptions/Staff`;
  const queryParams = [];
  if (accountOwnerId) {
    queryParams.push(`AccountOwnerId=${accountOwnerId}`);
  }
  if (taskId) {
    queryParams.push(`TaskId=${taskId}`);
  }
  if (queryParams.length > 0) {
    staffBaseUrl += `?${queryParams.join("&")}`;
  }

  const response = await axios.get(staffBaseUrl);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export async function getAssigneeStaff(taskId, pageSize, pageNumber = 1) {
  const response = await axios.get(
    `${taskManagementBaseUrl}/Task/${taskId}/Assignments?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return {
    items: response.data.data,
    pagination: response.data.pagination,
  };
}

export async function getStaffTaskList(
  pageNumber = 1,
  pageSize = 10,
  appliedOfficeFilters = [],
  appliedAssignorFilter = [],
  searchValue = "",
  isArchive = false,
  appliedPriorityFilters = [],
  appliedStatusFilters = [],
  sortBy
) {
  const response = await axios.post(`${taskManagementBaseUrl}/TaskAssignment`, {
    pageNumber,
    pageSize,
    showArchived: isArchive,
    officeIds: appliedOfficeFilters,
    assignorIds: appliedAssignorFilter,
    statuses: appliedStatusFilters,
    priorities: appliedPriorityFilters,
    searchTerm: searchValue,
    sortBy: sortBy,
  });

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return {
    items: response.data.data,
    pagination: response.data.pagination,
  };
}

export function useStaffTaskList(
  pageNumber,
  pageSize,
  appliedOfficeFilters,
  appliedAssignorFilter,
  searchValue,
  isArchive,
  appliedPriorityFilters,
  appliedStatusFilters,
  sortBy
) {
  return useQuery(
    [
      "/task/mytask",
      pageNumber,
      pageSize,
      appliedOfficeFilters,
      appliedAssignorFilter,
      searchValue,
      isArchive,
      appliedPriorityFilters,
      appliedStatusFilters,
      sortBy,
    ],
    () =>
      getStaffTaskList(
        pageNumber,
        pageSize,
        appliedOfficeFilters,
        appliedAssignorFilter,
        searchValue,
        isArchive,
        appliedPriorityFilters,
        appliedStatusFilters,
        sortBy
      ),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
}

export async function taskAssignmentAction(taskID, taskAction, params) {
  const response = await axios.post(
    `${taskManagementBaseUrl}/TaskAssignment/${taskID}/${taskAction}`,
    {
      ...params,
    }
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function getAdminOwnerList() {
  const response = await axios.get(
    `${taskManagementBaseUrl}/TaskFilter/AccountOwner`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export function useAccountOwners(options = {}) {
  return useQuery(["/Admin/OwnerList"], () => getAdminOwnerList(), options);
}

export async function getOfficeList(accountOwnerId) {
  const staffBaseUrl = `${taskManagementBaseUrl}/TaskFilter/Office`;
  const url = accountOwnerId
    ? `${staffBaseUrl}?AccountOwnerId=${accountOwnerId}`
    : staffBaseUrl;
  const response = await axios.get(url);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export function useOfficeList(accountOwnerId, options = {}) {
  return useQuery(
    ["/task/officeList", accountOwnerId],
    () => getOfficeList(accountOwnerId),
    options
  );
}

export async function getAssignedStaffList(accountOwnerId) {
  const staffBaseUrl = `${taskManagementBaseUrl}/TaskFilter/AssignedUsers`;
  const url = accountOwnerId
    ? `${staffBaseUrl}?AccountOwnerId=${accountOwnerId}`
    : staffBaseUrl;
  const response = await axios.get(url);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export function useAssignedStaffList(accountOwnerId, options = {}) {
  return useQuery(
    ["/task/AssignedStaffList", accountOwnerId],
    () => getAssignedStaffList(accountOwnerId),
    options
  );
}

export async function taskAction(taskID, taskAction) {
  const response = await axios.post(
    `${taskManagementBaseUrl}/Task/${taskID}/${taskAction}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function deleteTask(taskId) {
  const response = await axios.delete(
    `${taskManagementBaseUrl}/Task/${taskId}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function archieveTasks(taskId, action) {
  const response = await axios.patch(
    `${taskManagementBaseUrl}/Task/${taskId}/${action}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export const getTaskById = async (taskId) => {
  const response = await axios.get(`${taskManagementBaseUrl}/task/${taskId}`);

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export function useMyTaskById(taskId, pageSize, pageNumber, options = {}) {
  return useQueries([
    {
      queryKey: ["mytasks/id", taskId],
      queryFn: () => getTaskById(taskId),
      ...options,
    },
    {
      queryKey: ["myAssignee/id", taskId, pageNumber],
      queryFn: () => getAssigneeStaff(taskId, pageSize, pageNumber),
      ...options,
    },
  ]);
}

export async function getAssigneeOfficeList() {
  const response = await axios.get(
    `${taskManagementBaseUrl}/TaskAssignmentFilter/Offices`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export function useAssigneeOfficeList(options) {
  return useQuery(
    ["/TaskAssignmentFilter/officeList"],
    () => getAssigneeOfficeList(),
    options
  );
}

export async function getAssigneeList() {
  const response = await axios.get(
    `${taskManagementBaseUrl}/TaskAssignmentFilter/TaskOwners`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
}

export function useAssignorList(options = {}) {
  return useQuery(
    ["/TaskAssignmentFilter/TaskOwners"],
    () => getAssigneeList(),
    options
  );
}

export async function archieveStaffTasks(taskAssignmentId, action) {
  const response = await axios.patch(
    `${taskManagementBaseUrl}/TaskAssignment/${taskAssignmentId}/${action}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function getStaffTaskDetail(taskAssignmentId) {
  const response = await axios.get(
    `${taskManagementBaseUrl}/TaskAssignment/${taskAssignmentId}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response?.data?.data;
}

export function useStaffTaskDetail(taskAssignmentId, options = {}) {
  return useQuery(
    ["/TaskAssignmentFilter/staff", taskAssignmentId],
    () => getAssigneeList(taskAssignmentId),
    options
  );
}

export async function getCommentsByStaff(
  taskAssignmentId,
  pageSize,
  pageNumber = 1
) {
  const response = await axios.get(
    `${taskManagementBaseUrl}/TaskAssignmentComment/${taskAssignmentId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return {
    items: response.data.data,
    pagination: response.data.pagination,
  };
}

export function useStaffTaskById(taskAssignmentId, pageSize, pageNumber) {
  return useQueries([
    {
      queryKey: ["mytasks/id", taskAssignmentId],
      queryFn: () => getStaffTaskDetail(taskAssignmentId),
    },
    {
      queryKey: ["myAssignee/id", taskAssignmentId, pageNumber],
      queryFn: () => getCommentsByStaff(taskAssignmentId, pageSize, pageNumber),
    },
  ]);
}

export async function upDateNotifications(taskId, params) {
  const response = await axios.patch(
    `${taskManagementBaseUrl}/Task/${taskId}/Notification`,
    params
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function taskActionAssigneeTask(taskAssignmentId, taskAction) {
  const response = await axios.post(
    `${taskManagementBaseUrl}TaskAssignment/${taskAssignmentId}/${taskAction}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function addComment(taskAssignmentId, params) {
  const response = await axios.post(
    `${taskManagementBaseUrl}/TaskAssignmentComment/${taskAssignmentId}`,
    params
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export async function getComment(taskAssignmentId, pageNumber, pageSize) {
  const response = await axios.get(
    `${taskManagementBaseUrl}/TaskAssignmentComment/${taskAssignmentId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
}

export function useGetComment(
  taskAssignmentId,
  pageNumber,
  pageSize,
  options = {}
) {
  return useQuery(
    ["/addComment/staff", taskAssignmentId, pageNumber, pageSize],
    () => getComment(taskAssignmentId, pageNumber, pageSize),
    options
  );
}

export async function getAllTasksPerformance(
  pageNumber,
  pageSize,
  accountOwnerId,
  startDate,
  endDate,
  officeId
) {
  let baseUrl = `${taskManagementBaseUrl}/TaskPerformance/List?pageNumber=${pageNumber}&pageSize=${pageSize}&fromDate=${startDate}&toDate=${endDate}`;
  const queryParams = [];
  if (accountOwnerId) {
    queryParams.push(`&AccountOwnerId=${accountOwnerId}`);
  }

  if (officeId) {
    queryParams.push(`&officeId=${officeId}`);
  }

  if (queryParams.length > 0) {
    baseUrl += `${queryParams.join("&")}`;
  }

  const response = await axios.get(baseUrl);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return {
    items: response.data.data,
    pagination: response.data.pagination,
  };
}

export function useAllTasksPerformance(
  pageNumber,
  pageSize,
  accountOwnerId,
  startDate,
  endDate,
  officeId
) {
  return useQuery(
    [
      "/tasks/Performance/List",
      pageNumber,
      pageSize,
      accountOwnerId,
      startDate,
      endDate,
      officeId,
    ],
    () =>
      getAllTasksPerformance(
        pageNumber,
        pageSize,
        accountOwnerId,
        startDate,
        endDate,
        officeId
      )
  );
}

export async function getTaskPerformanceDetail(
  pageNumber,
  pageSize,
  startDate,
  endDate,
  userId,
  officeId,
  status
) {
  let baseUrl = `${taskManagementBaseUrl}/TaskPerformance/Assignments?pageNumber=${pageNumber}&pageSize=${pageSize}&fromDate=${startDate}&toDate=${endDate}&userId=${userId}&officeId=${officeId}`;
  const queryParams = [];
  if (status) {
    queryParams.push(`&status=${status}`);
  }
  if (queryParams.length > 0) {
    baseUrl += `${queryParams.join("&")}`;
  }

  const response = await axios.get(baseUrl);
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return {
    items: response.data.data,
    pagination: response.data.pagination,
  };
}

export function useTaskPerformanceDetail(
  pageNumber,
  pageSize,
  startDate,
  endDate,
  userId,
  officeId,
  status
) {
  return useQuery(
    [
      "/tasks/Performance/details",
      pageNumber,
      pageSize,
      startDate,
      endDate,
      userId,
      officeId,
      status,
    ],
    () =>
      getTaskPerformanceDetail(
        pageNumber,
        pageSize,
        startDate,
        endDate,
        userId,
        officeId,
        status
      )
  );
}
