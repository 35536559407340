import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./Modals.module.scss";

function ChatClosed({ t }) {
  return (
    <div className="edit-profile-warning-container container m-0 p-0">
      <div className="edit-profile-warning-bg ">
        <div className={styles.warning_text_font}>
          {t("messenger.closeChatPreventInput")}
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(ChatClosed);
