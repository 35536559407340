import React, { Fragment } from 'react'
import Text from 'components/Text'
import styles from "../WaitingList/WaitingList.module.scss"

const AppointmentRequest = ({ t, appointment, status }) => {
    const getStatus = (overallStatus) => {
        const statusMap = {
            1: "Pending",
            2: "Cancelled",
            3: "Converted",
        };
        return statusMap[overallStatus] || "";
    };

    const getStatusClass = (status) => {
        switch (getStatus(status)) {
            case "Pending":
                return 'blue-accepted-btn';
            case "Cancelled":
                return 'red-pending-btn';
            case "Converted":
                return 'green-confirmed-btn';
            default:
                return '';
        }
    };
    return (
        <Fragment>
            <div className={styles.title_btn_wrapper}>
                <Text size="16px" weight="600" color="#587E85">{appointment?.title}</Text>
                {appointment?.isStatus && <button className={`appointment-table-custom-btn
                    ${getStatusClass(status)}`}
                >
                    <span>{getStatus(status)}</span>
                </button>}
            </div>

            <div className={styles.grid_container}>
                {appointment?.details.map((detail, index) => (
                    <div key={index} className={styles.grid_item}>
                        <Text size="12px" weight="400" color="#6F7788">{detail.label}</Text>
                        <Text size="14px" weight="600" color="#102C42">{detail.value || ''}</Text>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
export default AppointmentRequest; 
