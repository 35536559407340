import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetMessageTemplatesList, useTemplateAccountOwners } from "repositories/message-templates-repository";
import constants from "../../../../constants";
import { createDateStringForAPIRequest } from "utils";
import moment from "moment";

const pageSize = 100;
const pageNumber = 1;

const useReminderModule = (t) => {
    const history = useHistory();
    const profile = useSelector((state) => state.userProfile.profile);
    const isAccountOwner = profile?.role?.systemRole === constants.systemRoles.accountOwner;

    const [patientMsg, setPatientMsg] = useState('');
    const [selectedAppointmentType, setSelectAppointmentType] = useState(null);
    const [selectedSlotDate, setSlotDate] = useState(new Date());
    const [appointmentSlots, setAppointmentSlots] = useState([]);
    const [selectedReminderPlan, setSelectReminderPlan] = useState(null);
    const [reminderPlan, setReminderPlan] = useState(null);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [reminderSets, setReminderSets] = useState({});
    const [reminderNames, setReminderNames] = useState({});
    const [deleteReminderKeys, setDeleteReminderKeys] = useState({});
    const [patientRadioCheck, setPatientRadioCheck] = useState({ label: "Yes", id: 1 });
    const [nextRecallRadioCheck, setNextRecallRadioCheck] = useState({ label: "No", id: 2 });
    const [repeatedType, setRepeatedType] = useState(7);
    const [reoccuringcheck, setReoccuringcheck] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [dates, setDates] = useState({ startDate: moment().toDate() });
    const [utcDates, setUtcDates] = useState({
        startDate: createDateStringForAPIRequest(moment().toDate()),
    });

    const [selectedOffice, setSelectedOffice] = useState(1);
    const [createDescription, setCreateDescription] = useState('');
    const [selectedPatient, setSelectedPatient] = useState({});
    const [selectedNextRecall, setSelectedNextRecall] = useState({});
    const [selectedOperatory, setselectedOperatory] = useState({});
    const [appointmentAccepted, setappointmentAccepted] = useState(false);
    const [email, setEmail] = useState('');
    const { data: ownerData } = useTemplateAccountOwners({ enabled: !isAccountOwner });
    let ownerId = isAccountOwner ? profile?.id : selectedOwner?.id;
    const { data: templateLists, isLoading: templateLoading } = useGetMessageTemplatesList(
        pageNumber,
        pageSize,
        isAccountOwner ? profile?.id : selectedOwner?.id
    );

    useEffect(() => {
        if (!isAccountOwner && ownerData?.length) {
            const _selectedOwner = JSON.parse(localStorage.getItem("selectedOwner")) || ownerData[0];
            setSelectedOwner(_selectedOwner);
            localStorage.setItem("selectedOwner", JSON.stringify(_selectedOwner));
        }
    }, [ownerData, isAccountOwner]);

    const handleSelectRadioCheck = useCallback((setter) => (item) => setter(item), []);

    const handleOnChange = useCallback((key, name, value, index) => {
        setReminderSets((prev) => ({
            ...prev,
            [key]: updateData(prev[key] || [], name, value, index),
        }));
    }, []);

    const deleteReminderItem = (key, id) => {
        setReminderSets(prev => ({
            ...prev,
            [key]: (prev[key] || []).filter(reminder => reminder.id !== id)
        }));
        setDeleteReminderKeys(prev => ({
            ...prev,
            [key]: null
        }));
    };

    const handleOnReminderNameChange = (key, value) => {
        setReminderNames(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const updateData = useCallback((prev, name, value, index) => {
        const newData = [...prev];
        if (newData.length > 0) {
            newData[index] = { ...newData[index], [name]: value };
        } else {
            newData.push({ [name]: value });
        }
        return newData;
    }, []);

    const updateDatesValues = useCallback((e, type) => {
        setDates((prev) => ({ ...prev, [type]: e }));
        setUtcDates((prev) => ({
            ...prev,
            startDate: type === 'startDate' ? createDateStringForAPIRequest(e) : prev.startDate,
        }));
    }, []);

    const validateForm = useCallback((key, isReminderName) => {
        const dataToValidate = reminderSets[key] || [];
        const reminderName = reminderNames[key];
        let errorMsg = '';

        if (!dataToValidate.length) {
            errorMsg = t('patientAppointmentMembers.reminderValidation');
        } else if (isReminderName && !reminderName) {
            errorMsg = t('patientAppointmentMembers.reminderNameValidation');
        } else if (dataToValidate.some((m) => m.reminderBefore === 0)) {
            errorMsg = t('patientAppointmentMembers.reminderBeforeZeroValidation');
        } else if (dataToValidate.some((m) => !m.selectTemplate)) {
            errorMsg = t('patientAppointmentMembers.reminderMsgTempValidation');
        } else if (dataToValidate.some((m) => !m.isEmail && !m.isSms && !m.isPushNotification)) {
            errorMsg = t('patientAppointmentMembers.reminderServiceValidation');
        } else if (dataToValidate.filter(m => m?.selectBeforeType === 2).some(m => m.reminderBefore > 365)) {
            errorMsg = t('patientAppointmentMembers.reminderBeforeDayValidation');
        } else if (dataToValidate.filter(m => m?.selectBeforeType === 3).some(m => m.reminderBefore > 12)) {
            errorMsg = t('patientAppointmentMembers.reminderBeforeMonthValidation');
        } else if (dataToValidate.filter(m => m?.selectBeforeType === 1).some(m => m.reminderBefore > 24)) {
            errorMsg = t('patientAppointmentMembers.reminderBeforeHourValidation');
        } else if (dataToValidate.filter(m => m?.selectBeforeType === 4).some(m => m.reminderBefore > 1)) {
            errorMsg = t('patientAppointmentMembers.reminderBeforeYearValidation');
        } else {
            const combinationTracker = {};
            for (let item of dataToValidate) {
                const combinationKey = `${item.selectBeforeType}-${item.reminderBefore}`;
                if (combinationTracker[combinationKey]) {
                    errorMsg = t('patientAppointmentMembers.reminderSameDueDate');
                    break;
                }
                combinationTracker[combinationKey] = true;
            }
        }

        setErrorMsg(errorMsg);
        return !errorMsg;
    }, [reminderSets, reminderNames, t]);

    const templateOptions = useMemo(() => templateLists?.data?.map((val) => ({ id: val?.id, name: val?.templateName })), [templateLists]);

    const options = useMemo(() => [
        { name: t('patientAppointmentMembers.hours'), id: 1 },
        { name: t('patientAppointmentMembers.days'), id: 2 },
        { name: t('patientAppointmentMembers.months'), id: 3 },
        { name: t('patientAppointmentMembers.years'), id: 4 },
    ], [t]);

    return {
        data: {
            templateLoading,
            reminderSets,
            reminderNames,
            deleteReminderKeys,
            templateOptions,
            options,
            history,
            errorMsg,
            templateLists,
            selectedReminderPlan,
            patientMsg,
            selectedAppointmentType,
            selectedSlotDate,
            appointmentSlots,
            reminderPlan,
            patientRadioCheck,
            nextRecallRadioCheck,
            repeatedType,
            reoccuringcheck,
            dates,
            utcDates,
            message,
            selectedOffice,
            createDescription,
            selectedPatient,
            email,
            selectedNextRecall,
            selectedOperatory,
            appointmentAccepted,
            ownerId
        },
        methods: {
            setReminderSets,
            handleOnReminderNameChange,
            handleOnChange,
            handleAddReminderClick: (key) =>
                setReminderSets((prev) => ({
                    ...prev,
                    [key]: [
                        ...(prev[key] || []),
                        {
                            selectTemplate: null,
                            selectBeforeType: 2,
                            reminderBefore: 1,
                            isEmail: true,
                            isPushNotification: true,
                            isSms: true,
                            id: (prev[key]?.length || 0) + 1,
                        },
                    ],
                })),
            setDeleteReminderKeys,
            deleteReminderItem,
            validateForm,
            setSelectReminderPlan,
            setPatientMsg,
            setSelectAppointmentType,
            setSlotDate,
            setAppointmentSlots,
            setReminderPlan,
            setReminderNames,
            handleChangePatientRadioCheck: handleSelectRadioCheck(setPatientRadioCheck),
            handleChangeNextRecallRadioCheck: handleSelectRadioCheck(setNextRecallRadioCheck),
            handleSelectRecallDropDown: setRepeatedType,
            handleReoccuringcheck: (e) => setReoccuringcheck(e.target.checked),
            updateDatesValues,
            handlechangeMessageInput: (e) => setMessage(e.target.value),
            setSelectedOffice,
            setCreateDescription,
            setSelectedPatient,
            setEmail,
            setSelectedNextRecall,
            setselectedOperatory,
            setappointmentAccepted
        },
    };
};

export default useReminderModule;
