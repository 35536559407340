import React, { Fragment, memo, useState } from "react";
import Text from "components/Text";
import styles from "../PerformanceReview.module.scss";
import RatingCriteriaModal from "../modals/RatingCriteriaModal";
import RatingSection from "./RatingSection";

const QuestionSection = memo(({ t, questionId, questionnaireResponses, handleRadioChange, handleCommentChange, ratingCriteria, questionTitle, isView, title, isSeeRating, giveFeedback, uniqueId, review }) => {
    const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
    const closeRatingModal = () => {
        setIsRatingModalOpen(!isRatingModalOpen);
    };

    return (
        <Fragment>
            <div className={`${styles.name_link_wrapper} d-flex justify-content-between`}>
                <Text size="12px" weight="400" color="#6F7788">
                    {title}
                </Text>
                {isSeeRating && <p className="link-btn m-0" onClick={() => {
                    setIsRatingModalOpen(true);
                }}>{t('performanceReview.seeRating')}</p>}
            </div>
            <RatingSection 
            t={t} 
            questionId={questionId} 
            uniqueId={uniqueId} 
            giveFeedback={giveFeedback} 
            questionnaireResponses={questionnaireResponses} 
            handleRadioChange={handleRadioChange} 
            handleCommentChange={handleCommentChange} 
            isView={isView} />
            {isRatingModalOpen && (
                <RatingCriteriaModal
                    t={t}
                    closeRatingModal={closeRatingModal}
                    isRatingModalOpen={isRatingModalOpen}
                    ratingCriteria={ratingCriteria}
                    questionTitle={questionTitle}
                />
            )}
        </Fragment>
    );
});

export default QuestionSection;