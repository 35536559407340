import { useEffect } from "react";
import { UserEventHandler } from "@sendbird/chat";
import { ADD_UNREAD_MESSAGE_COUNT } from "reducers/ProfileRedcuer";
import { v4 as uuidv4 } from "uuid";
import {
  useSendbirdStateContext,
  sendbirdSelectors,
} from "@sendbird/uikit-react";
import constants from "../constants";
export const getUnreadMessagesCount = async (sdk) => {
  try {
    const sbInstance = sdk;
    const params = {
      channelCustomTypesFilter: [
        constants.chat.external,
        constants.chat.internal,
      ],
    };
    const count = await sbInstance.groupChannel.getTotalUnreadMessageCount(
      params
    );
    const Patientparams = {
      channelCustomTypesFilter: [
        constants.chat.patient.open,
        constants.chat.patient.close,
      ],
    };
    const patientChatChannelParams =
      await sbInstance.groupChannel.getTotalUnreadMessageCount(Patientparams);

    return {
      live: count,
      patient: patientChatChannelParams,
    };
  } catch (err) {
    return err;
  }
};
export const useSendbirdMessageCount = (dependencies) => {
  const { dispatch } = dependencies;
  const context = useSendbirdStateContext();

  //In case of superadmin context will be undefined because we are not wrapping the
  //Header with SendbirdProvider in case of super admin in withMessageCount() HOC

  let sdk;
  if (context) {
    sdk = sendbirdSelectors?.getSdk(context);
  }

  const onTotalUnreadMessageCountUpdated = () => {
    getUnreadMessagesCount(sdk).then((res) => {
      if ("live" in res) {
        dispatch({
          type: ADD_UNREAD_MESSAGE_COUNT,
          payload: res,
        });
      }
    });
  };

  useEffect(() => {
    const eventHandlerId = uuidv4();
    if (sdk?.addUserEventHandler) {
      const userEventHandler = new UserEventHandler({
        onTotalUnreadMessageCountUpdated,
      });
      sdk?.addUserEventHandler(eventHandlerId, userEventHandler);
    }
    return () => {
      if (eventHandlerId && sdk?.removeUserEventHandler) {
        sdk?.removeUserEventHandler(eventHandlerId);
      }
    };
  }, [sdk]);
};
