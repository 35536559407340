import React from "react";
import Card from "components/Card";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import styles from "../../TaskManagement.module.scss";
import DeleteModal from "../../Modals/DeleteModal";
import { useStaffTaskById } from "repositories/task-management-repository";
import Loader from "components/Loader";
import LeftSection from "./LeftSection";
import useTaskDetails from "../../hooks/useTaskDetails";
import RightSection from "./RightSection";
import useCardModule from "../../hooks/useCardModule";
import { useLocation } from 'react-router-dom';
import Text from "components/Text";



const StaffTaskDetails = ({ t }) => {
    const {
        data: {
            anyLoading,
            taskDetail,
            isModalOpen,
            comments,
            totalItems
        },
        methods: {
            goToPreviousScreen,
            getStatus,
            checkOverdueTasks,
            closeModal,
            handleActiontask,
            addComments,
            setPageNumber
        }
    } = useTaskDetails(useStaffTaskById)

    const location = useLocation();
    const { state } = location;

    const {
        data: {
            activeTab,
        }
      } = useCardModule()

    const getButtonClass = (taskStatus) => {
        const statusClasses = {
            Pending: styles.Pending,
            Cancelled: styles.Cancelled,
            Started: styles.Started,
            Accepted: styles.Accepted,
            Rejected: styles.Rejected,
            "In Progress": styles.InProgress,
            Completed: styles.Completed
        };

        return `${styles.task_management_card_btn} ${statusClasses[taskStatus] || ""}`;
    };


    return (
        <Page onBack={goToPreviousScreen}>
            {(anyLoading) && <Loader />}
            <div class="row no-gutters align-items-center">
                <div class="col-md-7">
                    <h2 class="title">{t("taskManagement.taskDetails")}</h2>
                    <Text size="14px" marginBottom="4px" weight="300" color="#000000">
                          {`${state?.user?.firstName} ${state?.user?.lastName}`}
                    </Text>
                    <Text size="12px" weight="400" color="#6F7788">
                            {state?.office?.name}
                    </Text>
                </div>
            </div>
            <Card className={styles["task_details_card"]}>
                <div className={styles["task_detail"]}>
                    <LeftSection
                        taskDetail={taskDetail || state}
                        checkOverdueTasks={checkOverdueTasks}
                        getButtonClass={getButtonClass}
                        getStatus={getStatus}
                        handleActiontask={handleActiontask}
                        activeTab={activeTab}
                    />
                    <RightSection 
                      t={t}
                      taskAssignmentId={taskDetail?.id || state?.id}
                      addComments={addComments}
                      setPageNumber={setPageNumber}
                      comments = {comments}
                      totalItems = {totalItems}
                    />
                </div>
            </Card>
            {isModalOpen && (
                <DeleteModal
                    closeDeleteModal={closeModal}
                    isDeleteModalOpen={isModalOpen}
                />
            )}
        </Page>
    );
};
export default withTranslation()(StaffTaskDetails);
