
import { useEffect, useState } from "react";
import { decodeId, encodeId } from "utils";
import constants from "../../../../constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { uniqBy } from "lodash";

const pageSize = 10;
const useNoticesModule = (useAllNotices, history, isMynotices) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [notices, setNotices] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    let { officeId } = useParams();
    officeId = decodeId(officeId);

    const { isLoading, data } = useAllNotices(
        pageNumber,
        pageSize,
        officeId,
        isMynotices
    );

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setNotices(uniqBy([...data?.items], "id"));
                setTotalItems(data?.pagination?.totalItems);
            }
        }
        else if (!isLoading && data && data.items) {
            setNotices(prev => uniqBy([...prev, ...data?.items], "id"));
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);

    const handleOwnerCardClick = (e, noticeId) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        const route = isMynotices
            ? constants.routes.accountOwner.myNoticeDetails
            : constants.routes.accountOwner.noticeDetails;
        history.push(route.replace(":noticeId", encodeId(noticeId)));
    };

    const goToPreviousScreen = () => {
        history.push(
            constants.routes.accountOwner.officeOptions.replace(":officeId", encodeId(officeId))
        );
    };

    const AddNoticeClick = () => {
        history.push(
            constants.routes.accountOwner.addNotice.replace(":officeId", encodeId(officeId))
        );
    };

    return {
        data: {
            isLoading,
            notices,
            totalItems
        },
        methods: {
            setPageNumber,
            handleOwnerCardClick,
            goToPreviousScreen,
            AddNoticeClick
        }
    }
}

export default useNoticesModule;
