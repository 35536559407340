import React from "react";
import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import OfficeFilter from "./OfficeFilterList";
import StaffFilter from "./StaffFilter";
import { withTranslation } from "react-i18next";
import StatusCheckFitler from "./StatusCheckFilter";
import styles from "../../Notes/Notes.module.scss";
import PriorityCheckFitler from "./PriorityCheckFilter";

const FilterTasks = ({
  t,
  isSidebarActive,
  handleSidebarToggle,
  resetFilter,
  officeFilter,
  setOfficeFilter,
  handleApplyFilters,
  staffFilter,
  setStaffFilter,
  statusFilter,
  priorityFilter,
  setStatusFilter,
  setPriorityFilter,
  isFilterApply,
  isStaff,
  accountOwnerId,
}) => {
  const handleApplyFilter = () => {
    handleApplyFilters();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleResetFliter = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleClose = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleResetFliter}
    >
      <span className={styles.notes_filter}>
        {t("taskManagement.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>
      <Accordion
        preExpanded={["a"]}
        className="filter-accordion"
        allowZeroExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>
              {t("taskManagement.offices")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <OfficeFilter
              selectedOffice={officeFilter}
              setOfficeFilter={setOfficeFilter}
              isStaff={isStaff}
              accountOwnerId={accountOwnerId}
              t={t}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>
              {isStaff
                ? t("taskManagement.assigonr")
                : t("taskManagement.staffMembers")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StaffFilter
              seletedStaff={staffFilter}
              setStaffFilter={setStaffFilter}
              isStaff={isStaff}
              accountOwnerId={accountOwnerId}
              t={t}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>{t("notes.status")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StatusCheckFitler
              seletedStatus={statusFilter}
              setStatusFilter={setStatusFilter}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="d">
          <AccordionItemHeading>
            <AccordionItemButton>
              {t("taskManagement.priority")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <PriorityCheckFitler
              seletedPriorty={priorityFilter}
              setPriorityFilter={setPriorityFilter}
            />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className="button button-round button-shadow mr-3"
          title="apply"
          onClick={handleApplyFilter}
        >
          {t("taskManagement.apply")}
        </button>
        <button
          className="button button-round button-border button-dark reset-btn"
          title="reset"
          onClick={handleResetFliter}
        >
          {t("taskManagement.reset")}
        </button>
        <button
          className="button button-round button-border button-dark cancel-btn"
          title="close"
          onClick={handleClose}
        >
          {t("taskManagement.close")}
        </button>
      </div>
    </StickySidebar>
  );
};

export default withTranslation()(FilterTasks);
