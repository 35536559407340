import React from "react";
import { motion } from "framer-motion";
import constants from "./../../../constants";
import styles from "./OfficeOptions.module.scss";
import { Link } from "react-router-dom";

const Card = ({ to, icon, title, officeName, redirectTo, designation }) => {
  if (redirectTo) {
    return (
      <motion.div
        whileHover={{ scale: constants.animation.hoverScale }}
        whileTap={{ scale: constants.animation.hoverScale }}
      >
        <span onClick={redirectTo} className="pointer">
          <div className={styles["card"]}>
            <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
            <h4>{title}</h4>
          </div>
        </span>
      </motion.div>
    );
  }
  return (
    <motion.div
      whileHover={{ scale: constants.animation.hoverScale }}
      whileTap={{ scale: constants.animation.hoverScale }}
    >
      <Link
        to={{
          pathname: to,
          state: { officeName, designation },
        }}
        className="text-decoration-none"
      >
        <div className={styles["card"]}>
          <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
          <h4>{title}</h4>
        </div>
      </Link>
    </motion.div>
  );
};

export default Card; // Correct the export statement here
