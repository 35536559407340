import React from 'react';
import Input from 'components/Input';
import { Modal, ModalBody } from "reactstrap";
import styles from "../../Appointments/Appointments.module.scss"
import Text from 'components/Text';

const ShowBlockReason = ({ t, setconfirmBlocked, confirmBlocked, blockReason }) => {

  return (
    <Modal toggle={() => setconfirmBlocked(!confirmBlocked)} isOpen={confirmBlocked} className="modal-dialog-centered deactivate-modal modal-width-660">
      <div className={styles['timeslot-modal']}>
        <ModalBody className={styles['modal-content-part'] + " " + "d-flex flex-column p-0 mb-3"}>
          <div className={styles['blocking-content-box']}>
            <span className="close-btn d-flex justify-content-end" onClick={() => setconfirmBlocked(!confirmBlocked)}>
              <img src={require('assets/images/cross.svg').default} alt="close" />
            </span>
            <div>
              <Text>{t("patientAppointmentMembers.reasonofBlockingTimeSLot")}</Text>
              {blockReason?.map((reason, index) => (
              <Input
                key={index}
                Type="text"
                Placeholder="Reason"
                Name="BlockReason"
                Value={reason}
                ReadOnly
              />
            ))}
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ShowBlockReason