import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../../Notes/Notes.module.scss"
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import Loader from "components/Loader";
import Button from "components/Button";

const DeleteRequestModal = ({ t, showLoader, isModalOpen, closeModal, deleteRequest, title }) => {

    return (
        <Modal
            isOpen={!!isModalOpen}
            toggle={closeModal}
            className="modal-lg modal-dialog-centered"
            modalClassName="custom-modal"
        >
            <span className="close-btn" onClick={closeModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                {showLoader && <Loader />}
                <div className={styles["delete_task_modal"]}>
                    <Text size="25px" weight="500" color="#111B45">{title}</Text>
                    <Text size="16px" weight="300" color="#535B5F" marginTop="11px" marginBottom='10px'>{t('patientAppointmentMembers.deleteRequestMsg')}</Text>
                    <div className={styles.main_btn_container}>
                        <Button title="save" onClick={deleteRequest}>{t("patientAppointmentMembers.delete")}</Button>
                        <Button borderedSecondaryNew title="cancel" onClick={closeModal}>
                            <span>{t("patientAppointmentMembers.cancel")}</span>
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default withTranslation()(DeleteRequestModal);
