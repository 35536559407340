import { createContext, useContext } from 'react';
import { RoomType } from 'sendbird-calls';
import { initialState } from './sendbird-calls/SbCallsContext/state';
export function requestMediaPermissions(ref) {
  navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    .then(function(stream) {
      // If permission is granted, you can use the stream (for example, attach it to a video element)
      console.log("Permission granted. Stream: ", stream);
      ref.current=stream
      // You can attach the stream to a video element:
    })
    .catch(function(error) {
      // If permission is denied, handle the error
      console.log("Permission denied or an error occurred:", error);
    });
}
  const stub = () => {
    throw new Error('You forgot to wrap your component in <AuthContext>.');
  };
  /** State */
  export const initialContext = {
    ...initialState,
    isAuthenticated: false,
    init: stub,
    auth: stub,
    deauth: stub,
  
    // Media Device Control
    updateMediaDevices: stub,
    selectAudioInputDevice: stub,
    selectAudioOutputDevice: stub,
    selectVideoInputDevice: stub,
  
    // Direct Calls
    isBusy: false,
    currentCall: undefined,
    dial: stub,
    addDirectCallSound: stub,
    clearCalls: stub,
  
    // Rooms
    createRoom: stub,
    getCachedRoomById: stub,
    fetchRoomById: stub,
    RoomType: RoomType,
  };
  
  export const CallContext = createContext(initialContext);
  export const useSbCalls = () => useContext(CallContext);

