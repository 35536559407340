import { Fragment } from "react";
import Text from "components/Text";
import Input from "components/Input";
import moment from "moment";
import QuestionSection from "./QuestionSection";
import ReactDatePicker from "react-datepicker";
import { Portal } from "react-overlays";
import styles from "../PerformanceReview.module.scss";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";

const AdminRating = ({ t, id, questionId, question, handleCommentChange, handleRadioChange, questionnaireResponses, isView, title, giveFeedback, review, handleSetDate, handleSetText, ratingCriteria }) => {
    const isInvalidDate = (date) => !moment(date, moment.ISO_8601, true).isValid();

    const getExtraFieldValue = (key, id, questionnaireFeedback) => {
        const feedbackData = questionnaireFeedback?.find(v => (v?.questionnaireId == id));
        if (key === 'textField') {
            return feedbackData?.[key];
        }
        if (key === 'dateField') {
            return isInvalidDate(feedbackData?.[key]) ? moment().toDate() : moment(feedbackData?.[key]).toDate();;
        }

    }

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };

    const startDatePickerRef = useReadOnlyDateTextInput();
    return (
        <Fragment>
            {question?.typeOfQuestion === 1 ?
                <div className={`${question?.typeOfResponse === 3 ? styles.AccordionItemPanel : ''} mt-2`}>
                    <QuestionSection
                        t={t}
                        questionId={questionId}
                        ratingCriteria={ratingCriteria}
                        handleCommentChange={handleCommentChange}
                        handleRadioChange={handleRadioChange}
                        questionnaireResponses={questionnaireResponses}
                        isView={isView}
                        giveFeedback={giveFeedback}
                        uniqueId={`your-${questionId}`}
                        review={review}
                        title={title}
                        isSeeRating={question?.typeOfResponse === 1}
                    />
                </div>
                :
                <Fragment>
                    {(isView && !giveFeedback) ? <div className='d-flex flex-column'>
                        <Text size="12px" weight="400" color="#6F7788">
                            {t('performanceReview.employerFeedback')}
                        </Text>
                        <div className={styles.my_flex_container}>
                            <div className='d-flex flex-column w-50'>
                                <Text size="12px" weight="400" color="#6F7788">
                                    {question?.textTitle}
                                </Text>
                                <p className={styles.accordion_data_element}>
                                    {getExtraFieldValue('textField', id, questionnaireResponses)}
                                </p>
                            </div>
                            <div className='d-flex flex-column w-50'>
                                <Text size="12px" weight="400" color="#6F7788">
                                    {question?.dateTitle}
                                </Text>
                                <p className={styles.accordion_data_element}>
                                    {moment(getExtraFieldValue('dateField', id, questionnaireResponses)).format('MMM D, YYYY')}
                                </p>
                            </div>
                        </div>
                    </div> :
                        <div className={`d-flex flex-column`}>
                            <Input
                                Title={question?.textTitle}
                                Type="text"
                                Placeholder={question?.textTitle}
                                Name={"textTitle"}
                                HandleChange={(e) => handleSetText(e, id, getExtraFieldValue('dateField', id, questionnaireResponses))}
                                Value={getExtraFieldValue('textField', id, questionnaireResponses)}
                                MaxLength="55"
                                CustomClass={styles.bgYellow}
                            />
                            <div className={styles.new_inputdate + " " + "inputdate"}>
                                <Text size="13px" weight="400" color="#79869A">
                                    {question?.dateTitle}
                                </Text>
                                <div className="c-field mb-0">
                                    <div className="inputdate">
                                        <ReactDatePicker
                                            dateFormat="dd-MM-yyyy"
                                            className="c-form-control"
                                            selected={getExtraFieldValue('dateField', id, questionnaireResponses)}
                                            onChange={(e) => handleSetDate(e, id)}
                                            popperPlacement="bottom-start"
                                            popperContainer={CalendarContainer}
                                            ref={startDatePickerRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>}
        </Fragment>
    )
}

export default AdminRating;