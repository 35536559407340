import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import Text from 'components/Text';
import Input from 'components/Input';
import CustomDropdown from 'components/Dropdown';
import deleteImg from "../../../../assets/images/Combined Shape.svg";
import styles from "../Appointments.module.scss";
import constants from '../../../../constants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function AppointmentReminder({
    t,
    appointmentReminder,
    handleOnChange,
    templateOptions,
    options,
    officeId,
    requestId,
    patientId,
    index,
    isCreateTemplateVisable,
    setDeleteReminderKeys,
    keyName
}) {
    const history = useHistory();
    const location = useLocation();
    const { state } = location;
    const handleClick = () => {
        if (requestId) {
            history.push({ pathname:constants.routes.appointment.createMessageTemplates.replace(':officeId', officeId).replace(':requestId', requestId),state: state})
        } else {
            history.push({ pathname: constants.routes.appointment.createMessageHistoryTemplates.replace(':officeId', officeId).replace("/:patientId?", patientId ? `/${patientId}` : ""), state: state })
        }
    }

    return (
        <Fragment>
            <Card className={styles['appointment-modal-card']}>
                <div className={styles['appointment-modal-data']}>
                    <div className={styles['appointment-date-dropdown-wrapper']} >
                        <div className='w-50'>
                            <Input
                                Title={t("patientAppointmentMembers.beforDueDate")}
                                Type="number"
                                Placeholder={t('form.placeholder1', { field: t("patientAppointmentMembers.beforDueDate") })}
                                Name={"reminderBefore"}
                                Value={appointmentReminder?.reminderBefore}
                                HandleChange={(e) => handleOnChange(keyName, 'reminderBefore', e.target.value, index)}
                                Id={appointmentReminder?.id}
                            />
                        </div>
                        <div className="c-field appointment-custom-dropdown w-50">
                            <div className="custom-dropdown-only">
                                <label></label>
                                <CustomDropdown
                                    options={options}
                                    selectedOption={appointmentReminder?.selectBeforeType}
                                    selectOption={(id) => handleOnChange(keyName, 'selectBeforeType', id, index)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles['appointment-checkboxes']}>
                        {['isEmail', 'isSms', 'isPushNotification'].map((field, idx) => (
                            <div key={idx} className="ch-checkbox c-field all-event-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={field}
                                        onChange={(e) => handleOnChange(keyName, field, e.target.checked, index)}
                                        checked={appointmentReminder?.[field]}
                                    />
                                    <span>{t(`patientAppointmentMembers.${field}`)}</span>
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className={styles['custom-dropdown-wrapper']}>
                        <Text size="13px" weight="400" color="#79869A">{t('patientAppointmentMembers.selectMessageTemplate')}</Text>
                        <div className="c-field w-100">
                            <div className="custom-dropdown-only w-100">
                                <CustomDropdown
                                    options={templateOptions}
                                    selectedOption={appointmentReminder?.selectTemplate}
                                    selectOption={(id) => handleOnChange(keyName, 'selectTemplate', id, index)}
                                    defaultValue={t('patientAppointmentMembers.selectTemplate')}
                                />
                            </div>
                        </div>
                    </div>

                    {isCreateTemplateVisable && (
                        <button
                            className={`${styles['illness-type-save-cancel-btn']} ${styles['add-new-member-btn']}`}
                            onClick={handleClick}
                        >
                            {t('patientAppointmentMembers.createMessageTemplate')}
                        </button>
                    )}

                    <div
                        className={`${styles["delete-icon-text-wrapper"]} cursor-pointer`}
                        onClick={() => setDeleteReminderKeys(prev => ({
                            ...prev,
                            [keyName]: appointmentReminder?.id
                        }))}
                    >
                        <span>
                            <img src={deleteImg} alt="delete icon" />
                        </span>
                        <Text size="12px" weight="500" color="#EE4F4F">
                            {t('patientAppointmentMembers.delete')}
                        </Text>
                    </div>
                </div>
            </Card>
        </Fragment>
    );
}

export default withTranslation()(AppointmentReminder);
