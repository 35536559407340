
import React, { useContext, useEffect} from 'react'
import Page from 'components/Page';
import Text from 'components/Text';
import { withTranslation } from 'react-i18next'
import { Button, Card} from 'reactstrap';
import styles from "../../../Appointments.module.scss";
import "../../../Appointments.module.scss";
import { getSelectedAppointmentReminderPlan } from 'repositories/patient-appointment-repository';
import Loader from 'components/Loader';
import { formatUTCDateTime, getStorage, setStorage } from 'utils';
import useCommonModule from 'accountOwner/pages/Appointments/hooks/useCommonModule';
import { AppointmentsContext } from 'accountOwner/pages/Appointments/AppointmentsContext';
import appointmentStyles from "../../../Appointments.module.scss";
import usePatientAppointmentAcceptModule from 'accountOwner/pages/Appointments/hooks/usePatientAppointmentAcceptModule';

const KEY_NAME = 'acceptance-reminder';
const PatientAppointmentDetails = ({ t, history }) => {
  const officeState = getStorage('officeState')
  const {
    data: {
      appointmentDetail,
      isLoading,
      showLoader,
      errors,
    },
    methods: {
      handleSubmit,
      goToPreviousScreen
    }
  } = usePatientAppointmentAcceptModule(t, officeState?.officeState, KEY_NAME );

  const {
    methods: {
      redirectAppointmentType,
      redirectSelectReminder,
      redirectNextRecall
    },
  } = useCommonModule(history, t);

  const {
    reminderPlan,
    options,
    selectedAppointmentType,
    selectedReminderPlan,
    message,
    handlechangeMessageInput,
  } = useContext(AppointmentsContext);


useEffect(() => {
    setStorage('accept', {
      isFromGloabList :true,
    });
}, [])



  const TopCardData = ({ heading, data }) => (
    <div>
      <Text size="12px" weight="400" color="#6F7788"> {heading} </Text>
      <Text size="14px" weight="600" color="#102C42" >{data}</Text>
    </div>
  )

  const BottomCardData = ({ typeOfAppointment, selectType, data, onClick }) => (
    <div className={styles['heading-data-Wrapper']}>
      <div className={styles['head-link-wrapper']}>
        <div className={styles['appointment-head']}>{typeOfAppointment}</div>
        <div onClick={(e) => onClick(e, appointmentDetail)} className={styles["appointment-link"]}>{selectType}</div>
      </div>
      <div className={styles['appointment-text']}> {data?.title || ""}</div>
    </div>
  )
  return (
    <Page onBack={goToPreviousScreen}>
      {isLoading || showLoader && <Loader />}
      <Text size="25px" weight="500" color="#111B45" marginBottom="30px">{t('patientAppointmentMembers.acceptAppointment')}</Text>

      <Card className={styles["patient-appointment-details-upper-card"] + " " + "form-wrapper p-4"}>
        <Text color="#587E85" size="16px" weight="600" marginBottom="16px">Patient and Appointment Details</Text>
        <div className={styles["patient-appointment-details-upper-card-data"] + " " + "d-flex justify-content-between"}>
          <TopCardData heading="Patient Name" data={`${appointmentDetail?.patient?.firstName} ${appointmentDetail?.patient?.lastName}`} />
          <TopCardData heading="Date" data={formatUTCDateTime(appointmentDetail?.appointmentDate)} />
          <TopCardData heading="Clinic Name" data={appointmentDetail?.office?.name} />
          <TopCardData heading="Appointment Type" data={appointmentDetail?.appointmentType?.title || "--"} />
        </div>
      </Card>

      <Card className="form-wrapper">
        <div className={styles["patientAppointmentDetails-left-part"]}>
            <div className="c-field">
              <label>{t('Message To Patients*')}</label>
              <textarea
                className="c-form-control"
                Placeholder={t('Type your message', { field: t('your message') })}
                name="MessageToPatients"
                maxLength="256"
                onChange={handlechangeMessageInput}
                error={errors.MessageError}
                value={message}
              ></textarea>
              {errors.MessageError && (
                <span className="error-msg">{errors.MessageError}</span>
              )}
            </div>
          <BottomCardData
            typeOfAppointment="Type of Appointment*"
            selectType="Select Type"
            data={selectedAppointmentType?.[KEY_NAME]}
            onClick={redirectAppointmentType}
          />
          {errors?.TypeError && <span className="error-msg">{errors?.TypeError}</span> }
          <div className={appointmentStyles['heading-data-Wrapper']}>
            <div className={appointmentStyles['head-link-wrapper']}>
              <div className={appointmentStyles['appointment-head']}>{t("patientAppointmentMembers.reminderPlan")}</div>
              <div onClick={(e) => redirectSelectReminder(e, appointmentDetail)} className={styles["appointment-link"]}>
                {reminderPlan?.[KEY_NAME]?.title ? t("patientAppointmentMembers.changeReminderPlan") : t("patientAppointmentMembers.selectReminderPlan")} 
              </div>
            </div>
            {reminderPlan?.[KEY_NAME] && <div className={appointmentStyles['appointment-modal-card']}>
              <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{reminderPlan?.[KEY_NAME]?.title || ''}</Text>
              {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.map(plan =>
                <div className='mt-2' key={plan?.id}>
                  <Text size="14px" weight="600" color="#102C42" >{plan?.reminderBefore + ' ' +
                    options.find(
                      m => m.id == plan?.reminderBeforeType,
                    )?.name +
                    ' ' +
                    t("patientAppointmentMembers.before")}
                  </Text>
                  <Text size="14px" weight="600" color="#102C42" >
                    {[plan.isEmail && t("patientAppointmentMembers.isEmail"), plan.isSms && t("patientAppointmentMembers.isSms"), plan.isPushNotification && t("patientAppointmentMembers.isPushNotification")]
                      .filter(Boolean)
                      .join(', ')}
                  </Text>
                </div>
              )}
            </div>}
          </div>
          <BottomCardData
            typeOfAppointment="Link to Next Recall"
            selectType="Select Next Plan"
            data="No next recall selected"
            onClick={() => {}}
          />
          <div className={styles['common-appointment-btn-container']}>
            <Button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={(e) => handleSubmit(e, selectedAppointmentType?.[KEY_NAME], selectedReminderPlan, message)}>{t('patientAppointmentMembers.accept')}</Button>
            <Button className="button button-round button-border button-dark btn-mobile-link" onClick={() => { history.push(`/appointments`) }}>{t('patientAppointmentMembers.cancel')}</Button>
          </div>
        </div>
      </Card>
    </Page>
  )
}
export default withTranslation()(PatientAppointmentDetails);