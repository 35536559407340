import React, { useState } from "react";
import Card from "components/Card";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import styles from "../../TaskManagement.module.scss";
import Exlamatory_Icon from "../../../../../assets/images/red_exclamatory_icon.svg";
import Text from "components/Text";

const PatientDetails = ({ history, t }) => {
  const [status, setStatus] = useState("Rejected");
  const [priority, setPriority] = useState("LOW");

  const goToPreviousScreen = () => {
    history.push("/task-details");
  };

  const getButtonClass = () => {
    setStatus("");
    return `${styles.task_management_card_btn} ${
      status === "Pending"
        ? styles.Pending
        : status === "Cancelled"
        ? styles.Cancelled
        : status === "Started"
        ? styles.Started
        : status === "Accepted"
        ? styles.Accepted
        : status === "Rejected"
        ? styles.Rejected
        : status === "In Progress"
        ? styles.InProgress
        : ""
    }`;
  };

  const getPriorityClass = (taskPriority) => {
    setPriority("");
    switch (taskPriority) {
      case "HIGH":
        return {
          wrapperClass: styles["high-priority-wrapper"],
          dotClass: styles["high-priority-dot"],
          textClass: styles["high-priority-text"],
        };
      case "MEDIUM":
        return {
          wrapperClass: styles["medium-priority-wrapper"],
          dotClass: styles["medium-priority-dot"],
          textClass: styles["medium-priority-text"],
        };
      case "LOW":
        return {
          wrapperClass: styles["low-priority-wrapper"],
          dotClass: styles["low-priority-dot"],
          textClass: styles["low-priority-text"],
        };
      default:
        return {
          wrapperClass: "",
          dotClass: "",
          textClass: "",
        };
    }
  };
  const { wrapperClass, dotClass, textClass } = getPriorityClass(priority);

  return (
    <Page onBack={goToPreviousScreen}>
      <div class="row no-gutters align-items-center">
        <div class="col-md-7">
          <h2 class="title">Task Details</h2>
          <p class="pms_subtitle">Patient Scheduling Preferences</p>
          <Text size="12px" weight="400" color="#6F7788" marginTop="4px">
            Call Patient 103
          </Text>
        </div>
      </div>
      <Card className={styles["task_details_card"]}>
        <div className={styles["task_detail"]}>
          <div className={styles["left_section"]}>
            <div className="d-flex align-items-center gap-3">
              <img
                src={Exlamatory_Icon}
                alt="Exlamatory_Icon"
                className="mr-2"
              />
              <p className={styles["overdue_warning"] + " " + "m-0"}>
                This task is Overdue.
              </p>
            </div>
            <div className={styles["status_priority_date_wrapper"]}>
              <button className={getButtonClass()}>{status}</button>
              <div
                className={`${styles["circle_priority_text_wrapper"]} ${wrapperClass}`}
              >
                <span
                  className={`${styles["circular_dot"]} ${dotClass}`}
                ></span>
                <p className={`${styles["priority_text"]} ${textClass}`}>
                  {priority}
                </p>
              </div>
              <div className={styles["date"]}>May 27, 2024</div>
            </div>
            <Text size="20px" weight="500" color="#111B45" marginTop="30px">
              Call Patient 103
            </Text>
            <div className={styles["office_assignby_wrapper"]}>
              <div className={styles["office_name"]}>
                <Text size="12px" weight="400" color="#6F7788">
                  Start Date
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  May 30, 2024
                </Text>
              </div>
              <div className={styles["office_name"]}>
                <Text size="12px" weight="400" color="#6F7788">
                  Due Date
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  May 30, 2024
                </Text>
              </div>
            </div>
            <div className={styles["office_assignby_wrapper"]}>
              <div className={styles["office_name"]}>
                <Text size="12px" weight="400" color="#6F7788">
                  Assigned By
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  Florian Smith
                </Text>
              </div>
              <div className={styles["office_name"]}>
                <Text size="12px" weight="400" color="#6F7788">
                  Assigned To
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  3 Assignees
                </Text>
              </div>
            </div>

            <div className={styles["description"]}>
              <Text size="12px" weight="400" color="#6F7788">
                Description
              </Text>
              <Text size="14px" weight="600" color="#102C42">
                Enim quam est quis magnam odit et harum sequi. Qui harum
                perspiciatis culpa ut ipsum vel consequatur et reprehenderit.
                Autem reprehenderit adipisci esse sunt molestiae alias dolorum
                reprehenderit.
              </Text>
            </div>
          </div>
          <div className={styles["right_section"]}>
            <h1>call component for chat module</h1>
          </div>
        </div>
      </Card>
    </Page>
  );
};
export default withTranslation()(PatientDetails);
