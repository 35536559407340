import React, { Fragment, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import MediaDocsCard from "./MediaDocsCard";
import useContactInfoState from "Messenger/hooks/useContactInfoState";
import UpdateGroupPopup from "../Modals/UpdateGroupPopup";
import { LoaderIcon } from "react-hot-toast";
import CustomModal from "components/CustomModal";
import ProgressiveImage from "./ProgressiveImage";
import AddMemberInExistingGroupPopup from "../Modals/AddMemberInExistingGroupPopup";
import useChannelListHeaderState from "Messenger/hooks/useChannelListHeaderState";
import PatientConfirmationModal from "Messenger/pages/PatientConversation/component/PatientConfirmationModal";
import { getSendbirdUserDetail } from "repositories/chat-repository";

const ContactInfo = ({
  t,
  handleProfileBtnClose,
  channel,
  currentUser,
  setCurrentChannel,
  setFileMessage,
  setFileViewer,
  updateChannelList,
  setChannelListQuery,
  setFilteredOffices,
  filteredOffices,
  externalTabActive,
  selectedOwner,
  sdk,
  isPatient,
  doctorChatCheck,
}) => {
  const [isNewGroupPopupOpen, setIsNewGroupPopupOpen] = useState(false);
  const stateData = useContactInfoState({
    channel,
    currentUser,
    setCurrentChannel,
    t,
    handleProfileBtnClose,
    sdk,
  });

  const stateTestData = useChannelListHeaderState({
    setChannelListQuery,
    setFilteredOffices,
    filteredOffices,
    setCurrentChannel,
    selectedOwner,
    sdk,
    currentUser,
    externalTabActive,
    t,
    updateChannelList,
    channel,
    doctorChatCheck,
  });

  const { state, updateMethods, otherMethods } = stateData;
  const {
    isDeleteConversationPopupOpen,
    isMediaDocActive,
    isUpdateGroupPopupOpen,
    mediaMessages,
    isMediaLoading,
    actionInProgress,
    isPopOpen,
    isConversationStateLoading,
    isConversationClosed,
  } = state;
  const {
    getChannelData,
    handleSeeAllBtn,
    handleMediaDocBackBtn,
    deleteChat,
    setIsPopOpen,
    toggleChatOpenState,
  } = otherMethods;
  const { setIsDeleteConversationPopupOpen, setIsUpdateGroupPopupOpen } =
    updateMethods;

  const handleModal = (status) => {
    setIsNewGroupPopupOpen(status);
  };
  const channelData = useMemo(() => getChannelData(), [getChannelData()]);

  const [userMetaData, setUserMetaData] = useState(null);
  useEffect(() => {
    if (channelData?.emailId && !channelData.contactNumber && !userMetaData) {
      const anotherUser = Array.isArray(channelData.customMembers)
        ? channelData.customMembers?.find((res) => res.id !== currentUser.id)
        : null;
      if (anotherUser) {
        getSendbirdUserDetail(anotherUser.id).then((res) => {
          if (res.user_id) {
            setUserMetaData(res);
          }
        });
      }
    }
  }, [channelData?.emailId]);
  useEffect(() => {
    return () => {
      setUserMetaData(null);
    };
  }, []);
  const phone_no = useMemo(
    () =>
      userMetaData?.metadata?.phone_no &&
      userMetaData?.metadata?.phone_no !== channelData?.contactNumber
        ? userMetaData?.metadata?.phone_no
        : channelData?.contactNumber || userMetaData?.metadata?.phone_no,
    [channelData?.contactNumber, userMetaData?.metadata?.phone_no]
  );
  return (
    <>
      <div className="profile-contact-info">
        {!isMediaDocActive ? (
          <Fragment>
            <div className="close-btn" onClick={handleProfileBtnClose}>
              <img
                src={require("assets/images/close-icon.svg").default}
                alt="icon"
              />
            </div>
            <h4 className="contact-heading">{t("messenger.contactInfo")}</h4>
            <div className="profile-img-box">
              {channel?.myRole === "operator" &&
                channelData?.memberCount > 2 && (
                  <div className="text-right mt-3">
                    <span
                      className="cursor-pointer"
                      onClick={() => setIsUpdateGroupPopupOpen(true)}
                    >
                      <img
                        src={
                          require("assets/images/edit-group-icon.svg").default
                        }
                        alt="icon"
                      />
                    </span>
                  </div>
                )}
              <div className="img-box mt-0">
                <img
                  className="img-cover"
                  src={
                    channelData?.channelImage ||
                    require("assets/images/staff-default-rounded.png").default
                  }
                  onError={(e) => {
                    e.currentTarget.src =
                      require("assets/images/staff-default-rounded.png").default;
                  }}
                  alt="icon"
                />
              </div>
              <div className="profile-name">{channelData?.channelName}</div>
              {channelData?.memberCount > 2 && (
                <div className="account-ownner-desc">
                  {t("messenger.createdBy")} {channel?.creator?.nickname}
                </div>
              )}
            </div>
            <div className="media-doc-box">
              <div className="media-doc-header">
                <div className="text-box"> {t("messenger.mediaDocs")}</div>
                {!isMediaLoading && !mediaMessages.length && (
                  <span className="no-media-text">
                    {t("messenger.noDataFound")}
                  </span>
                )}
                {mediaMessages.length > 0 && (
                  <span className="link-btn" onClick={handleSeeAllBtn}>
                    {t("messenger.seeAll")}
                  </span>
                )}
              </div>
              {mediaMessages.length > 0 && (
                <div className="media-gallery">
                  {!isMediaLoading ? (
                    mediaMessages?.map((eachMsg, idx) => {
                      const { thumbnails, customType, name, type } = eachMsg;
                      const isDoc = customType === "document";
                      let thumbnailUrl;
                      let docIcon;
                      if (!isDoc && thumbnails && thumbnails.length) {
                        thumbnailUrl = thumbnails[0]?.url?.startsWith("?")
                          ? ""
                          : thumbnails[0].url;
                      }
                      if (isDoc) {
                        if (type?.includes("pdf")) {
                          docIcon =
                            require("assets/images/document-icon-pdf.svg").default;
                        } else if (
                          type?.includes("wordprocessingml") ||
                          type?.includes("msword")
                        ) {
                          docIcon =
                            require("assets/images/document-icon-word.svg").default;
                        } else if (
                          type?.includes("spreadsheetml") ||
                          type?.includes("msexcel")
                        ) {
                          docIcon =
                            require("assets/images/document-icon-excel.svg").default;
                        } else {
                          docIcon =
                            require("assets/images/document-icon.svg").default;
                        }
                      }
                      return (
                        <div
                          key={idx}
                          className={
                            (isDoc ? "doc-box" : "img-box") + " cursor-pointer"
                          }
                          title={name}
                          onClick={() => {
                            if (!isDoc) {
                              setFileMessage(eachMsg);
                              setFileViewer(true);
                            } else {
                              window?.open(eachMsg.url, "_blank");
                            }
                          }}
                        >
                          <ProgressiveImage
                            className={isDoc ? "" : "img-cover"}
                            src={
                              thumbnailUrl || (isDoc ? docIcon : eachMsg.url)
                            }
                            alt="icon"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <span style={{ margin: "0 auto" }} className="text-box">
                      <LoaderIcon />
                    </span>
                  )}
                </div>
              )}

              {channelData?.memberCount === 2 && (
                <div className="personnal-detail">
                  <div className="c-field">
                    <label> {t("form.fields.officeName")}</label>
                    <div> {channelData?.officeName} </div>
                  </div>
                  <div className="c-field">
                    <label> {t("form.fields.emailAddress")}</label>
                    <div>
                      <a href={`mailto:${channelData?.emailId}`}>
                        {channelData?.emailId}
                      </a>
                    </div>
                  </div>
                  <div className="c-field">
                    <label> {t("form.fields.phoneNumber")}</label>
                    <div>
                      {phone_no && <a href={`tel:${phone_no}`}>{phone_no}</a>}
                    </div>
                  </div>
                </div>
              )}
              {channelData?.memberCount > 2 && (
                <div className="personnal-detail">
                  <div className="group-member-count">
                    {channelData?.memberCount} {t("messenger.members")}
                    {channel?.myRole === "operator" &&
                      channelData?.memberCount > 2 && (
                        <span
                          className="cursor-pointer"
                          onClick={() => setIsNewGroupPopupOpen(true)}
                        >
                          <img
                            src={
                              require("assets/images/add-member.svg").default
                            }
                            alt="icon"
                          />
                        </span>
                      )}
                  </div>
                  <ul className="group-member-list">
                    {channelData?.customMembers?.map((eachMember, index) => {
                      return (
                        <li key={index}>
                          <div className="img-box">
                            <img
                              className="img-cover"
                              src={
                                eachMember?.coverImage ||
                                require("assets/images/staff-default-rounded.png")
                                  .default
                              }
                              onError={(e) => {
                                e.currentTarget.src =
                                  require("assets/images/staff-default-rounded.png").default;
                              }}
                              alt="icon"
                            />
                          </div>
                          <div>
                            <div className="group-name">{eachMember?.name}</div>
                            <div className="group-desc">
                              {eachMember?.officeName}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div className="delete-open-close-chat-container">
                {channel?.myRole === "operator" && (
                  <div
                    className="delete-chat"
                    onClick={() => setIsDeleteConversationPopupOpen(true)}
                  >
                    <span className="delete-icon">
                      <img
                        src={require("assets/images/delete-red.svg").default}
                        alt="icon"
                      />
                    </span>
                    {t("messenger.deleteChat")}
                  </div>
                )}

                {isPatient && !doctorChatCheck && (
                  <div
                    className="delete-chat"
                    onClick={() => setIsPopOpen(true)}
                  >
                    <span className="delete-icon">
                      <img
                        src={
                          require(`assets/images/chat-${
                            isConversationClosed ? "open" : "close"
                          }.svg`).default
                        }
                        alt="icon"
                      />
                    </span>
                    {isConversationClosed
                      ? t("messenger.openChat")
                      : t("messenger.closeChat")}
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <MediaDocsCard
            handleMediaDocBackBtn={handleMediaDocBackBtn}
            channel={channel}
            setFileMessage={setFileMessage}
            setFileViewer={setFileViewer}
          />
        )}
      </div>

      {isPopOpen && (
        <PatientConfirmationModal
          title={
            isConversationClosed
              ? t("messenger.openChat")
              : t("messenger.closeChat")
          }
          description={
            isConversationClosed
              ? t("messenger.openChatDescription")
              : t("messenger.closeChatDescription")
          }
          confirmLabel={
            isConversationClosed
              ? isConversationStateLoading
                ? t("messenger.loading")
                : t("messenger.confirmOpenChatLabel")
              : t("messenger.confirmCloseChatLabel")
          }
          action={toggleChatOpenState}
          close={() => setIsPopOpen(false)}
        />
      )}

      {isUpdateGroupPopupOpen && (
        <UpdateGroupPopup
          stateData={stateData}
          channel={channel}
          updateChannelList={updateChannelList}
          setCurrentChannel={setCurrentChannel}
        />
      )}

      {isDeleteConversationPopupOpen && (
        <CustomModal
          isOpen={isDeleteConversationPopupOpen}
          setIsOpen={setIsDeleteConversationPopupOpen}
          onConfirm={deleteChat}
          title={
            channelData?.memberCount > 2
              ? t("messenger.deleteGroupChat")
              : t("messenger.deleteSingleChat")
          }
          subTitle1={
            channelData?.memberCount > 2
              ? t("messenger.deleteGroupChatDesc")
              : t("messenger.deleteSingleChatDesc")
          }
          actionInProgress={actionInProgress}
          leftBtnText={t("delete")}
          rightBtnText={t("cancel")}
        />
      )}
      {isNewGroupPopupOpen && (
        <AddMemberInExistingGroupPopup
          stateData={stateTestData}
          selectedUsers={channelData?.customMembers}
          closeModal={(status) => {
            handleModal(status);
          }}
        />
      )}
    </>
  );
};

export default withTranslation()(ContactInfo);
