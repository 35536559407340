import React from "react";
import NotAccessibleImage from "../../../../assets/images/not_accessible_image.svg";
import NotFoundImage from "../../../../assets/images/empty-icon.svg";
import Text from "components/Text";

export default function NotAccessible({ Message, isNotFound = false }) {
  return (
    <div className="empty-block m-0">
      <img src={isNotFound ? NotFoundImage : NotAccessibleImage} alt="icon" />
      <Text size="20px" weight="500" color="#111B45">
        {Message}
      </Text>
    </div>
  );
}
