import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Card from "components/Card";
import Text from "components/Text";
import moment from "moment";
import { withTranslation } from "react-i18next";
import styles from "../../Contracts/Contracts.module.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { exportEmploymentProof, submitEmploymentProof, useGetFormTemplate } from "repositories/employment-proof";
import { decodeId,  openAndDownloadPdf } from "utils";
import Form from "../components/Form";
import { useSelector } from "react-redux";
import { useOfficeDetail } from "repositories/office-repository";
import toast from "react-hot-toast";
import { cloneDeep } from "lodash";
import ReviewForm from "../components/ReviewForm";
import ConfirmModal from "../modals/ConfirmModal";
import SaveTemplateModal from "../modals/SaveTemplate";
import Loader from "components/Loader";
import FileSaver from "file-saver";

const initialValues = {
  Date: new Date(),
  EmployeeName: "",
  Position: "",
  HiredDate: new Date(),
  TypeOfEmployment: 1,
  RateOfPay: "",
  AnnualSalary: "",
}

const EmploymentProofForm = ({ t, history, location }) => {
  let { officeId } = useParams();
  const decodeOfficeId = parseInt(decodeId(officeId));
  const { state } = location;
  const [defaultTemplate, setDefaultTemplate] = useState(false);
  const [isConfirmOpen, setConfirmModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const { isLoading, data: template } = useGetFormTemplate(decodeOfficeId, defaultTemplate);
  const profile = useSelector((state) => state.userProfile.profile);
  const { data: officeDetail } = useOfficeDetail(decodeOfficeId);
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [isSaveTemplateOpen, setSaveTemplate] = useState(false);

  const [errors, setErrors] = useState({});
  const [selectType, setType] = useState(1);

  const closeConfirmModal = () => setConfirmModal(false);
  const closeSaveTemplateModal = () => {
    setSaveTemplate(false);
    history.goBack();
  }

  useEffect(() => {

    if (template?.length === 0) {
      setDefaultTemplate(false);
      toast.error(t('employmentProof.noRecentSavedFoam'));
    }
    if (!isLoading && template?.length > 0) {
      const { role, contactNumber, emailId, firstName, lastName } = profile;
      let newTemplate = {};
      template.forEach(key => {
        newTemplate[key?.title] = key?.body
      })
      newTemplate['EmployeeName'] = location?.state?.employeeName || '';
      newTemplate['Position'] = role?.title;
      newTemplate['Office Phone Number'] = contactNumber;
      newTemplate['Account Owner’s Email Address'] = emailId;
      newTemplate['Account Owner/Account Admin Name'] = `${firstName} ${lastName}`;
      newTemplate['Office Name'] = officeDetail?.name;

      setFormData(prev => ({ ...prev, ...newTemplate }))
    }
  }, [isLoading, template, officeDetail]);


  const updateDatesValues = (e, type) => {
    setFormData((prevData) => ({ ...prevData, [type]: e }));
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const TranslateKeys = {
    Salutation: "salutation",
    Subject: "subject",
    Heading: "heading",
    EmployeeName: "name",
    Position: "position",
    RateOfPay: "rateOfPay",
    AnnualSalary: "annualSalary",
    'Office Phone Number': "officePhoneNumber",
    'Account Owner’s Email Address': "accountOWnerEmail",
    Sincerely: "sincerely",
    'Account Owner/Account Admin Name': "accountOwnerAdminName",
    'Office Name': "officeName",
    "Declaration": "description"
  }

  const submitData = () => {
    return {
      "Date": moment(formData?.Date),
      "EmployeeName": formData?.EmployeeName,
      "Position": formData?.Position,
      "HiredDate": moment(formData?.HiredDate),
      "TypeOfEmployment": selectType,
      "RateOfPay": formData?.RateOfPay,
      "AnnualSalary": formData?.AnnualSalary,
      "EmploymentProofDetail": [
        {
          "title": "Salutation",
          "body": formData?.Salutation,
          "overallOrder": 1
        },
        {
          "title": "Subject",
          "body": formData?.Subject,
          "overallOrder": 2
        },
        {
          "title": "Heading",
          "body": formData?.Heading,
          "overallOrder": 3
        },
        {
          "title": "Declaration",
          "body": formData?.Declaration,
          "overallOrder": 4
        },
        {
          "title": "Office Phone Number",
          "body": formData?.['Office Phone Number'],
          "overallOrder": 5
        },
        {
          "title": "Account Owner’s Email Address",
          "body": formData?.['Account Owner’s Email Address'],
          "overallOrder": 6
        },
        {
          "title": "Sincerely",
          "body": formData?.Sincerely,
          "overallOrder": 7
        },
        {
          "title": "Account Owner/Account Admin Name",
          "body": formData?.['Account Owner/Account Admin Name'],
          "overallOrder": 8
        },
        {
          "title": "Office Name",
          "body": formData?.['Office Name'],
          "overallOrder": 9
        }
      ]
    }
  }

  const isValidFields = () => {
    const errorsData = cloneDeep(errors);

    for (const [key, value] of Object.entries(formData)) {
      if (!value) {
        errorsData[key] = t("form.errors.emptyField", {
          field: t(`employmentProof.form.${TranslateKeys[key]}`),
        });
      } else {
        delete errorsData[key];
      }
    }

    setErrors(errorsData);
    return !Object.values(errorsData).some(Boolean);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidFields()) {
      if (!viewMode) {
        setViewMode(true);
      } else {
        setShowLoader(true);
        try {
          let res = await submitEmploymentProof(location?.state?.proofId, submitData());
          toast.success(t(res?.message));
          closeConfirmModal();
          setSaveTemplate(true);
        } catch (err) {
          toast.error(t(err));
        } finally {
          setShowLoader(false);
        }
      }
    }
  };

  const exportProof = async () => {
    setShowLoader(true);
    try {
      let res = await exportEmploymentProof(decodeOfficeId, submitData());
      if (res) {
        openAndDownloadPdf(res);
      }
    } catch (err) {
      toast.error(t(err));
    } finally {
      setShowLoader(false);
    }
  };


  const handleCancel = () => {
    history.goBack();
  }

  const sendEmploymentProof = () => {
    setConfirmModal(true);
  }

  const goToPreviousScreen = () => {
    history.goBack();
  };

  const TypeOfEmployment = [
    { id: 1, name: "Full Time" },
    { id: 2, name: "Part Time" }
  ];

  const handleToggle = () => {
    setDefaultTemplate(!defaultTemplate)
  }

  return (
    <Page onBack={goToPreviousScreen} title={officeDetail?.name}>
      {isLoading && <Loader/>}
      {!viewMode ? <Card className={styles.employment_proof_form_main_wrapper_card}>
        <div className={styles.employment_proof_form_content_container}>
          <div className={styles.radio_btn_wrapper + " " + "ch-radio"}>
            <Text size="13px" weight="400" color="#79869A" marginBottom="2px">
              {t('employmentProof.form.selectTemplate')}
            </Text>
            <label className="mr-5">
              <input
                type="radio"
                name="default"
                onChange={handleToggle}
                checked={!defaultTemplate}
              />
              <span>{t('employmentProof.form.defaultTemplate')}</span>
            </label>
            <label className="pb-0">
              <input
                type="radio"
                name="saved"
                onChange={handleToggle}
                checked={defaultTemplate}
              />
              <span>{t('employmentProof.form.recentlySavedTemplate')}</span>
            </label>
          </div>
          <Form
            t={t}
            errors={errors}
            formData={formData}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            updateDatesValues={updateDatesValues}
            handleOnChange={handleOnChange}
            selectType={selectType}
            setType={setType}
            TypeOfEmployment={TypeOfEmployment}
          />
        </div>
      </Card>
        :
        <ReviewForm
          t={t}
          formData={formData}
          selectType={selectType}
          TypeOfEmployment={TypeOfEmployment}
          setViewMode={setViewMode}
          sendEmploymentProof={sendEmploymentProof}
          exportProof={exportProof}
          showLoader={showLoader}
          isShowSend={state?.isViewMode}
        />
      }
      {isConfirmOpen &&
        <ConfirmModal t={t} history={history} isConfirmOpen={isConfirmOpen} closeConfirmModal={closeConfirmModal} handleSend={handleSubmit} updateLoading={showLoader} />
      }
      {
        isSaveTemplateOpen &&
        <SaveTemplateModal t={t} isSaveTemplateOpen={isSaveTemplateOpen} closeSaveTemplateModal={closeSaveTemplateModal} proofId={location?.state?.proofId} />
      }
    </Page>
  );
};
export default withTranslation()(EmploymentProofForm);
