import React from "react";
import styles from "./TaskManagement.module.scss";
import Text from "components/Text";

const PatientCard = ({ staff, onCheckboxChange, selectedStaff }) => {
  const checkboxId = `checkbox-${staff?.staffMemberId}`;
  const nameId = `name-${staff?.staffMemberId}`;
  const officeId = `office-${staff?.staffMemberId}`;
  return (
    <div className={styles["checkbox_wrapper"]}>
      <div
        className={
          styles["check_box_width"] +
          " ch-checkbox c-field all-event-checkbox m-0"
        }
      >
        <label htmlFor={checkboxId}>
          <input
            type="checkbox"
            id={checkboxId}
            aria-labelledby={nameId}
            aria-describedby={officeId}
            name={nameId}
            checked={selectedStaff[staff?.staffMemberId] || false}
            onChange={(e) => onCheckboxChange(staff?.staffMemberId, e)}
          />
          <span className={styles["checkbox_height"]}></span>
        </label>
      </div>
      <div className={styles["name_office"]}>
        <label htmlFor={checkboxId} className="m-0">
          <Text
            size="14px"
            weight="600"
            color="#102C42"
            id={nameId}
            className={styles.office_name}
          >
            {`${staff?.staffFirstName} ${staff?.staffLastName}`}
          </Text>
          <Text size="12px" weight="400" color="#87928D" id={officeId}>
            {staff?.officeName}
          </Text>
        </label>
      </div>
    </div>
  );
};

export default PatientCard;
