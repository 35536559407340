import React from "react";
import Card from "components/Card";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import styles from "../../TaskManagement.module.scss";
import DeleteModal from "../../Modals/DeleteModal";
import { useMyTaskById } from "repositories/task-management-repository";
import Loader from "components/Loader";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import useTaskDetails from "../../hooks/useTaskDetails";
import constants from "../../../../../constants"
import { encodeId } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useCardModule from "../../hooks/useCardModule";



const OwnerTaskDetails = ({ t }) => {
    const history = useHistory();
    let isFromOwner = true
    const {
        data: {
            anyLoading,
            taskDetail,
            filteredTasks,
            Assignee,
            totalItems,
            OfficeList,
            StatuList,
            selectedOffice,
            selectedStatus,
            taskId,
            notifications
        },
        methods: {
            goToPreviousScreen,
            getStatus,
            checkOverdueTasks,
            handleOfficeChange,
            handleStatusChange,
            setPageNumber,
            deleteAction,
            handleNotifications,
            handleActiontask
        }
    } = useTaskDetails(useMyTaskById, isFromOwner)

    const {
		data: {
			isDeleteModalOpen
		},
		methods: {
			handleDeleteModal,
		}
	} = useCardModule()

    const getButtonClass = (taskStatus) => {
        const statusClasses = {
            Pending: styles.Pending,
            Cancelled: styles.Cancelled,
            Started: styles.Started,
            Accepted: styles.Accepted,
            Rejected: styles.Rejected,
            "In Progress": styles.InProgress,
            Completed: styles.Completed
        };

        return `${styles.task_management_card_btn} ${statusClasses[taskStatus] || ""}`;
    };

    const editTask = () => {
		history.push(
			constants.routes.taskManagements.taskManagementEditNote.replace(":taskId", encodeId(taskId))
		);
	};

    return (
        <Page onBack={goToPreviousScreen}>
            {(anyLoading) && <Loader />}
            <div class="row no-gutters align-items-center">
                <div class="col-md-7">
                    <h2 class="title">{t("taskManagement.taskDetails")}</h2>
                </div>
                <div class="col-md-5 text-md-right">
                    {((taskDetail?.overallStatus === 2) || (taskDetail?.overallStatus === 6) || (taskDetail?.overallStatus === 7)) && <button
                        class="button button-round button-border button-dark mr-3"
                        title="Delete"
                        onClick={handleDeleteModal}
                    >
                        {t("taskManagement.delete")}
                    </button>}
                    <button
                        class="button button-round button-width-large add-button"
                        title="Edit"
                        onClick={editTask}
                    >
                        {t("taskManagement.edit")}
                    </button>
                </div>
            </div>
            <Card className={styles["task_details_card"]}>
                <div className={styles["task_detail"]}>
                    <LeftSection
                        taskDetail={taskDetail}
                        checkOverdueTasks={checkOverdueTasks}
                        getButtonClass={getButtonClass}
                        getStatus={getStatus}
                        notifications={notifications}
                        handleNotifications={handleNotifications}
                    />
                    <RightSection
                        t={t}
                        filteredTasks={filteredTasks}
                        OfficeList={OfficeList}
                        selectedOffice={selectedOffice}
                        handleOfficeChange={handleOfficeChange}
                        selectedStatus={selectedStatus}
                        StatuList={StatuList}
                        handleStatusChange={handleStatusChange}
                        Assignee={Assignee}
                        totalItems={totalItems}
                        setPageNumber={setPageNumber}
                        checkOverdueTasks={checkOverdueTasks}
                        getButtonClass={getButtonClass}
                        handleActiontask={handleActiontask}
                    />
                </div>
            </Card>
            {isDeleteModalOpen && (
				<DeleteModal
					closeDeleteModal={handleDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					deleteAction={deleteAction}
					taskId={taskDetail?.id}
                    routes = {constants.routes.taskManagements.taskManagement}
				/>
			)}
        </Page>
    );
};
export default withTranslation()(OwnerTaskDetails);
