import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Table from "components/table";
import styles from "../../../../accountOwner/pages/Appointments/Appointments.module.scss";
import "../../../../accountOwner/pages/Appointments/Appointments.scss";
import { withTranslation } from "react-i18next";
import { useGetAllPatientAppointments } from "repositories/patient-repository";
import { encodeId, formatUTCDateTime } from "utils";
import Loader from "components/Loader";
import Card from "components/Card";
import { useHistory } from "react-router-dom";
import constants from "../../../../constants";

const PAGE_SIZE = 10;

function AppointmentListTable({ close, creds, doctorChatCheck }) {
  const history = useHistory();
  const columns = [
    {
      attrs: {
        datatitle: "Patient Name",
      },
      dataField: "patientName",
      text: "Patient Name",
      formatter: (cellContent, row) => (
        <p
          className={styles["appointment-table-patient-name"] + " m-0"}
          onClick={() => {
            if (!doctorChatCheck) {
              history.push({
                pathname:
                  constants.routes.appointment.patientAppointmentDetails.replace(
                    ":appointmentId",
                    encodeId(row.id)
                  ),
                state: {
                  isFromPatientChat: true,
                },
              });
            }
          }}
        >
          {`${row?.patient?.firstName} ${row?.patient?.lastName}`}
        </p>
      ),
    },
    {
      attrs: {
        datatitle: "Office",
      },
      dataField: "office",
      text: "Office",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.office?.name || "--"}
        </span>
      ),
    },
    {
      attrs: {
        datatitle: "Booked By",
      },
      dataField: "bookedBy",
      text: "Booked By",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.bookedByUser || "--"}
        </span>
      ),
    },
    {
      attrs: {
        datatitle: "Appointment Date",
      },
      dataField: "appointmentDate",
      text: "Appointment Date",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {formatUTCDateTime(row?.appointmentDate)}
        </span>
      ),
    },
    {
      attrs: {
        datatitle: "Appointment Time",
      },
      dataField: "appointmentTime",
      text: "Appointment Time",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.appointmentTime?.length > 1
            ? `${row?.appointmentTime[0]} - ${
                row?.appointmentTime[row?.appointmentTime?.length - 1]
              }`
            : row?.appointmentTime[0]}
        </span>
      ),
    },
    {
      attrs: {
        datatitle: "Appointment Type",
      },
      dataField: "appointmentType",
      text: "Appointment Type",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.appointmentType || "--"}
        </span>
      ),
    },
  ];

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const { isLoading, data } = useGetAllPatientAppointments(
    page,
    PAGE_SIZE,
    creds?.officeId,
    creds?.patientId,
    {
      enabled: Boolean(creds.officeId && creds?.patientId),
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    setTotalItems(data.pagination.totalItems);
  }, [data]);

  return (
    <Page
      className="scheduler-page"
      title="List of Appointments"
      onBack={close}
    >
      <Card>
        {isLoading && <Loader />}
        <div className={styles["appointment-table-box"]}>
          <div className={styles.appointment_list_table}>
            <Table
              keyField="id"
              className="change-heading"
              data={data?.data || []}
              columns={columns}
              bordered={false}
              handlePagination={(p) => setPage(p)}
              pageNumber={page}
              totalItems={totalItems}
              pageSize={PAGE_SIZE}
            />
          </div>
        </div>
      </Card>
    </Page>
  );
}

export default withTranslation()(AppointmentListTable);
