import Button from "components/Button";
import Card from "components/Card";
import Page from "components/Page";
import Text from "components/Text";
import styles from '../Appointments.module.scss';
import dummyImg from '../../../../assets/images/dummy.jpg'
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useUser } from "repositories/user-repository";
import { useOfficeDetail } from "repositories/office-repository";
import Loader from "components/Loader";
import constants from "../../../../constants";
import { decodeId, handleSuccess } from "utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { updatePatientStatus } from "repositories/patient-appointment-repository";

function PatientStatus({ t, history, location }) {

    let { officeId, patientId } = useParams();
    const profile = useSelector(state => state.userProfile.profile);
    const decodeOfficeId = parseInt(decodeId(officeId));
    const decodePatientId = parseInt(decodeId(patientId));

    const { data: patientData, isLoading } = useUser(decodePatientId);
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);
    const { state } = location;
    const [loader, setLoader] = useState(false);
    const [selectStatus, setStatus] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleActivity = async () => {
        if (selectStatus) {
            if (errorMsg) setErrorMsg(null);
            try {
                setLoader(true);
                const params = {
                    patientActiveStatusDoctorOfficeId: selectStatus === 1 ? state?.userId : 0,
                    patientId: decodePatientId,
                    officeId: 0,
                    doctorId: selectStatus === 2 ? profile?.id : 0,
                    patientStatus: !state?.status
                }
                const res = await updatePatientStatus(params);
                handleSuccess(res.message);
                goBack();
            } catch (err) {
                setLoader(false);
            } finally {
                setLoader(false);
            }
        } else {
            setErrorMsg(t("vendorManagement.errors.selectOneItems"))
        }
    }


    const goBack = () => { history.push(constants.routes.appointment.patients.replace(':officeId', officeId)) };

    return (
        <Page onBack={goBack} title={officeDetail?.name}>
            <Text size="14px" weight="300" color="#000000" className='mb-2'>
                {t(`patients.${!state?.status ? 'activate' : 'deactivate'}`) + ' ' + t('patients.patient')}
            </Text>
            {(isLoadingOfficeDetail || isLoading || loader) && <Loader />}
            <Card className={styles.deactivated_patient_card}>
                <div className={styles.patient_img_container}>
                    <img src={patientData?.data?.profilePic || dummyImg} alt="" className={styles.deactive_patient_img} />
                    <Text size="18px" weight="600" color="#587E85">{patientData?.data?.firstName + ' ' + patientData?.data?.lastName}</Text>
                </div>
                <Text size="16px" weight="300" color="##535B5F">{!state?.status ? t('patients.activePatientMsg') : t('patients.deactivePatientMsg')}</Text>
                <div className={styles.radio_btn_wrapper + " " + "ch-radio"}>
                    <label className="mr-5">
                        <input type="radio" name="patientStatus" onChange={e => setStatus(1)} />
                        <span> {!state?.status ? t('patients.activeOnlyOffice') : t('patients.deactiveOnlyOffice')} </span>
                    </label>
                    <label className="pb-0" >
                        <input type="radio" name="patientStatus" onChange={e => setStatus(2)} />
                        <span>{!state?.status ? t('patients.activeMultiOffice') : t('patients.deactiveMultiOffice')}</span>
                    </label>
                </div>
                {errorMsg && (
                    <span className="error-msg mt-2">{errorMsg}</span>
                )}
                <div className={styles.main_btn_container}>
                    <Button onClick={handleActivity}>{!state?.status ? t('patients.activate') : t('patients.deactivate')}</Button>
                    <Button borderedSecondaryNew onClick={goBack}>
                        <span>{t('cancel')}</span>
                    </Button>
                </div>
            </Card>
        </Page >
    )
}

export default withTranslation()(PatientStatus);