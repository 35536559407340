import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import { Card } from "reactstrap";
import Table from "components/table";
import constants from "./../../../../../../constants";
import Text from 'components/Text';
import styles from "../../../Appointments.module.scss";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAppointmentDetail,  } from 'repositories/patient-appointment-repository';
import { decodeId, formatUTCDateTime } from 'utils';
import Loader from 'components/Loader';
import Empty from 'components/Empty';
import useCommonModule from 'accountOwner/pages/Appointments/hooks/useCommonModule';

const RecallTime = ({ t }) => {
	const history = useHistory();
	let { appointmentId } = useParams();
	const decodeAppointmentId = parseInt(decodeId(appointmentId));
	const goBack = () => {
			history.push(constants.routes.appointment.appointmentScreen)
	};
	const [pageNumber, setPageNumber] = useState(1);

    const {
        methods: {
            redirectNextRecall
        },
    } = useCommonModule(history, t);
	
	const { isLoading: appointmentLoading, data: appointmentDetail } = useAppointmentDetail(
        decodeAppointmentId
    );
  let isFromRecall = true;
	const columns = [
		{
			attrs: { datatitle: t('patientAppointmentMembers.nextRecallHeading.originalAppointmentDate') },
			dataField: 'originalAppointmentDate',
			text: t('patientAppointmentMembers.nextRecallHeading.originalAppointmentDate'),
			formatter: (cellContent, row, rowIndex) => {
				return (
				<div className='select-reason d-flex flex-column'>
					<label>
						<span> {formatUTCDateTime(row?.appointmentDate)} </span>
					</label>
				</div>
			)},
		},
		{
			attrs: { datatitle: t('patientAppointmentMembers.nextRecallHeading.recallDueDate') },
			dataField: 'recallDueDate',
			text: t('patientAppointmentMembers.nextRecallHeading.recallDueDate'),
			formatter: (cellContent, row, rowIndex) => (
				<span className={styles["appointment-table-patient-name"]}>{formatUTCDateTime(row?.recallDate)} </span>
			),
		},
		{
			attrs: { datatitle: t('patientAppointmentMembers.nextRecallHeading.recallType') },
			dataField: 'recallType',
			text: t('patientAppointmentMembers.nextRecallHeading.recallType'),
			formatter: (cellContent, row, rowIndex) => (
				<span className={styles["appointment-table-patient-name"]}>{row?.recallType?.title} </span>
			),
		},
		{
			attrs: { datatitle: t("discussionNote.edit") },
			dataField: t("discussionNote.edit"),
			text: t("discussionNote.edit"),
			formatter: (cellContent, row, rowIndex) => (
				<button className={`link-btn ${styles.appointment_table_btn}`} onClick={(e) => redirectNextRecall(e, row, isFromRecall )}>Edit</button>
			),
		},
	]

	return (
		<Page onBack={goBack} className="main-page">
			 {appointmentLoading && <Loader />}
			<div className='d-flex flex-column manage-schedule-heading'>
				<Text color='#111B45' weight='500' size='25px'>Recall</Text>
			</div>
			<Card className="form-wrapper p-3">
				<div className="test-table-box">
					{ appointmentDetail?.recallDate ? <Table
						keyField={'id'}
						data={ [appointmentDetail] || []}
						columns={columns}
						bordered={false}
						handlePagination={setPageNumber}
						pageNumber={pageNumber}
						totalItems={1}
						pageSize={10}
					/> : <Empty Message={"Not Availabel"} />}
				</div>
				 
			</Card>
		</Page>
	);
};
export default withTranslation()(RecallTime);