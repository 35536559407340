import React from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import TypeOfAppointment from 'accountOwner/pages/Appointments/components/TypeOfAppointment';
import Page from 'components/Page';

const PatientAppointmentType = ({ t }) => {

    const history = useHistory();

    return (
        <Page onBack={() => { history.push(`/patient-appointment-details`) }}>
            <TypeOfAppointment />
        </Page>
    )
}
export default withTranslation()(PatientAppointmentType)