import React from 'react'
import Card from 'components/Card'
import Page from 'components/Page'
import Text from 'components/Text'
import styles from "../PatientMessageCenter.module.scss"
import SelectPatientModal from '../modals/SelectPatientModal'
import SelectMessageTemplateModal from '../modals/SelectMessageTemplateModal'
import NewMessageDetails from './NewMessageDetails'
import constants from "../../../../../constants"
import { Link } from 'react-router-dom'
import useAddNewHistoryMessageModule from '../../hooks/useAddNewHistoryMessageModule'
import { withTranslation } from 'react-i18next'
import Loader from 'components/Loader'

const PatientNewMessage = ({ t }) => {

    const {
        data: {
            loading,
            templateLists,
            officeDetail,
            selectedMessageTemplate,
            isSelectMessageModalOpen,
            appliedMessageTemplate,
            messageNotifications,
            isAttachMent,
            patientId,
            officeId,
            isPatientModalOpen,
            selectedPatients,
            appliedSelectedPatients,
            contentForPreview,
            error
        },
        methods: {
            goBack,
            setSelectedMessageTemplate,
            handleToggleModal,
            handleSelectTemplate,
            handleNotificationsChange,
            handleToggleAttachment,
            handleSubmit,
            handleRedirectAddCustomeMessage,
            handleTogglePatientModal,
            setSelectedPatients,
            handleSelectedPatients,
            handlePreview
        }
    } = useAddNewHistoryMessageModule(t);

    return (
        <Page title={officeDetail?.name} onBack={goBack}>
            {loading && <Loader />}
            <Card className={styles.new_message_card}>
                <div className={styles.new_message_wrapper}>
                    {!patientId && <div className={styles.select_patient_wrapper}>
                        <div>
                            <Text size="13px" weight="400" color="#79869A">Select Patient</Text>
                            <Text size="14px" weight="600" color="#102C42">
                                {appliedSelectedPatients?.length === 1
                                    ? appliedSelectedPatients[0]?.firstName
                                    : appliedSelectedPatients?.length > 1
                                        ? `${appliedSelectedPatients.length} Selected`
                                        : 'No patient selected'}
                            </Text>
                        </div>
                        <button className={styles.link + " " + "link-btn"} onClick={handleTogglePatientModal}>
                            Select
                        </button>
                    </div>}
                    <div className={styles.select_patient_wrapper}>
                        <div>
                            <Text size="13px" weight="400" color="#79869A">Select Message Template*</Text>
                            <Text size="14px" weight="600" color="#102C42">{appliedMessageTemplate?.templateName || 'No Message selected'}</Text>
                        </div>
                        <button className={styles.link + " " + "link-btn"} onClick={handleToggleModal}>
                            Select
                        </button>
                    </div>
                    {error && (
                      <span className="error-msg">{error}</span>
                    )}
                </div>
                {appliedMessageTemplate?.id ? <div className={styles.message_details_right_side}>
                    <NewMessageDetails
                    messageNotifications={messageNotifications}
                    handleNotificationsChange={handleNotificationsChange}
                    appliedMessageTemplate={appliedMessageTemplate}
                    isAttachMent={isAttachMent}
                    handleToggleAttachment={handleToggleAttachment}
                    handlePreview={handlePreview}
                    contentForPreview={contentForPreview}
                    error={error}
                     />
                    <div className="btn-box d-md-flex mt-4">
                        <Link to={constants.routes.superAdmin.patientViewTemplate}>
                            <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" title="Select" onClick={handleSubmit}>Send Message</button>
                        </Link>
                        <button className="mb-md-3 button button-round button-border btn-mobile-link button-dark" title="cancel" onClick={goBack}>cancel</button>
                    </div>
                </div> : null}
            </Card>
            {isPatientModalOpen && (
                <SelectPatientModal
                    isModalOpen={isPatientModalOpen}
                    closeModal={handleTogglePatientModal}
                    officeId={officeId}
                    selectedPatients={selectedPatients}
                    setSelectedPatients={setSelectedPatients}
                    handleSelectedPatients={handleSelectedPatients}
                />
            )}

            {isSelectMessageModalOpen && (
                <SelectMessageTemplateModal
                    isModalOpen={isSelectMessageModalOpen}
                    closeModal={handleToggleModal}
                    templateLists={templateLists}
                    setSelectedMessageTemplate={setSelectedMessageTemplate}
                    selectedMessageTemplate={selectedMessageTemplate}
                    handleSelectTemplate={handleSelectTemplate}
                    handleRedirectAddCustomeMessage={handleRedirectAddCustomeMessage}
                />

            )}
        </Page>
    )
}

export default withTranslation()(PatientNewMessage);
