
import { useState } from "react";
import { encodeId, getStorage, setStorage } from "utils";
import constants from "../../../../constants";

const useCardModule = () => {
    const cachedActiveTab = getStorage(constants.tasks.cache.taskslisting);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);
    const handleToggle = () => {
        if (isConfirmModalOpen) {
            setIsConfirmModalOpen(false);
        } else {
            setIsConfirmModalOpen(true);
        }
    }

    const handleToggleAccountOwner = () => {
        setIsAdminModalOpen(!isAdminModalOpen);
    }

    const manageTab = (tab) => {
        window.scrollTo(0, 0);
        setActiveTab(tab);
        setStorage(constants.tasks.cache.taskslisting, {
            activeTab: tab,
        });
    };

    const handleCancelModal = () => {
        if (isCancelModalOpen) {
            setIsCancelModalOpen(false);
        } else {
            setIsCancelModalOpen(true);
        }
    }

    const handleDeleteModal = () => {
        if (isDeleteModalOpen) {
            setIsDeleteModalOpen(false);
        } else {
            setIsDeleteModalOpen(true);
        }
    }

    const handleCompletionModal = () => {
        if (isCompletionModalOpen) {
            setIsCompletionModalOpen(false);
        } else {
            setIsCompletionModalOpen(true);
        }
    }

    const handleCardClick = (e, task, history, isfromDashBOard = false) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        if(isfromDashBOard) {
            setStorage("isChatFromDashBoard", true);
        }
        history.push({
        pathname : constants.routes.taskManagements.taskDetails.replace(":taskId", encodeId(task?.id)),
        state : task
        });
    };

    const handleOwnerCardClick = (e, taskId, history, isfromDashBOard = false) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        if(isfromDashBOard) {
            setStorage("isChatFromDashBoard", true);
        }
        history.push(
        constants.routes.taskManagements.ownerTaskDetails.replace(":taskId", encodeId(taskId))
        );
    };

    const handlePerformanceCardClick = (e,performace, history) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        const url = constants.routes.taskManagements.performanceDetail
        .replace(":userId", encodeId(performace?.userId))
        .replace(":officeId", encodeId(performace?.officeId));
        history.push({pathname : url, state: {...performace}});
    };

    return {
        data: {
            isConfirmModalOpen,
            activeTab,
            isAdminModalOpen,
            isCancelModalOpen,
            isDeleteModalOpen,
            isCompletionModalOpen
        },
        methods: {
            handleToggle,
            setActiveTab,
            manageTab,
            handleToggleAccountOwner,
            handleCancelModal,
            handleDeleteModal,
            handleCardClick,
            handleCompletionModal,
            handleOwnerCardClick,
            handleOwnerCardClick,
            handlePerformanceCardClick
        }
    }
}

export default useCardModule;
