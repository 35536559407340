import { withTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "./../Notes.module.scss";
import { Fragment } from "react";
import Empty from "components/Empty";

function StaffModel({
    t,
    isModalOpen,
    closeModal,
    handleApply,
    handleCancel,
    handleStaffSelection,
    allMembersList,
    selectedStaff
}) {

    return (
        <Fragment>
            <Modal
                isOpen={isModalOpen}
                toggle={closeModal}
                className="modal-dialog-centered modal-lg"
                modalClassName="custom-modal"
            >
                <span className="close-btn" onClick={closeModal}>
                    <img src={crossIcon} alt="close" />
                </span>
                <ModalBody>
                    <h1 className={styles["modal_heading"]}>{t("notes.selectStaffMember")}</h1>
                    {allMembersList.length === 0 ? <div className={styles["not_found"]}>
                        <Empty Message={t('notes.noStaffMemberFound')} />
                    </div>
                        :
                        allMembersList?.map((staff) => (
                            <div
                                key={staff?.id}
                                className={`${styles["staff_popup_wrapper"]} ch-radio`}
                            >
                                <label
                                    className="mr-5 pt-3 pb-3"
                                    onClick={() => handleStaffSelection(staff)}
                                >
                                    <input
                                        type="radio"
                                        name="blockTimeslotAllDoctors"
                                        checked={selectedStaff?.id === staff?.id}
                                        readOnly
                                    />
                                    <span> {staff?.firstName + ' ' + staff?.lastName} </span>
                                </label>
                            </div>
                        ))}

                    <div
                        className={`${styles["btn_container"]} btn-box d-md-flex mt-4`}
                    >
                        <button
                            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                            title="save"
                            onClick={handleApply}
                            disabled={allMembersList.length === 0 || !selectedStaff?.id}
                        >
                            {t("notes.apply")}
                        </button>
                        <button
                            className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
                            title="cancel"
                            onClick={handleCancel}
                        >
                            {t("notes.cancel")}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )

}

export default withTranslation()(StaffModel);