import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../../assets/images/cross.svg";
import plusImg from "../../../../../assets/images/Group 6.svg";

import styles from "../PatientMessageCenter.module.scss";

export default function SelectMessageTemplateModal({
  isModalOpen,
  closeModal,
  templateLists,
  selectedMessageTemplate,
  setSelectedMessageTemplate,
  handleSelectTemplate,
  handleRedirectAddCustomeMessage,
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-dialog-centered"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <h2 className="title mt-2">Select Message Template</h2>

        <button
          className={styles["illness-type-save-cancel-btn"]}
          onClick={handleRedirectAddCustomeMessage}
        >
          <span>
            <img src={plusImg} alt="ds" className="mr-1" />
          </span>
          Add New Message
        </button>

        <div className={styles.patient_radion_btn_wrapper}>
          {templateLists?.data?.length &&
            templateLists?.data?.map((template) => (
              <div
                key={template.id}
                className={styles.modal_checkbox_border + " " + "ch-radio"}
              >
                <label className="mr-5">
                  <input
                    type="radio"
                    name="messageTemplate"
                    checked={selectedMessageTemplate?.id === template?.id}
                    onChange={() => setSelectedMessageTemplate(template)}
                  />
                  <span>{template?.templateName}</span>
                </label>
              </div>
            ))}
        </div>

        <div className="btn-box d-md-flex mt-4">
          <button
            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
            title="Select"
            onClick={handleSelectTemplate}
          >
            Select
          </button>
          <button
            className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
            title="cancel"
            onClick={closeModal}
          >
            cancel
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
