import React, { useEffect, useState, useMemo } from "react";
import SchedulerCalendar from "../../../components/SchedulerCalendar";
import StickySidebar from "../../../components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import OfficeFilter from "../../../components/SchedulerFilters/OfficeFilter";
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import "./../../../Scheduler.scss";
import styles from "./AgendaTab.module.scss";
import AgendaDailyView from "./AgendaView/AgendaDailyView";
import AgendaWeeklyView from "./AgendaView/AgendaWeeklyView";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import constants from "../../../../../../constants";
import { useSelector } from "react-redux";
import { isMobileTab, cacheSideBarActive, getStorage, setStorage } from "utils";
import moment from "moment";
import AddToCalendar from "accountOwner/pages/components/AddToCalendar";
import CustomModal from "components/CustomModal";
import {
  getSyncCalendar,
  useGetAgenda,
} from "repositories/scheduler-repository";
import toast from "react-hot-toast";
import Loader from "components/Loader";
import SyncModal from "accountOwner/pages/Scheduler/components/Modals/SyncModal";
import AgendaMonthView from "./AgendaView/AgendaMonthView";
import CustomDropdown from "components/Dropdown";
import AgendaFilter from "accountOwner/pages/Scheduler/components/SchedulerFilters/AgendaFilter";

function AgendaTab({
  t,
  agendaFiltersData,
  staffAvailabilityViewSelectedFromDashBoard,
  currentDateFromDashBoard,
  setCurrentDateFromDashBoard,
  isFromDashBoard = false,
}) {
  const [toolTipModal, setToolTipModal] = useState(false);
  const [syncModal, setSyncModal] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [syncUrl, setSyncUrl] = useState(null);
  const profile = useSelector((state) => state.userProfile.profile);
  const cachedSelectedOwnerTab = getStorage(
    constants.notes.cache.dashboardAccountOwnerCheck
  );
  let selectedOwnerId = 0;
  let currentUserId = null;
  if (profile && profile.role) {
    if (profile.role.systemRole !== constants.systemRoles.staff) {
      selectedOwnerId = profile.id;
    } else {
      if (isFromDashBoard) {
        selectedOwnerId = cachedSelectedOwnerTab?.owner
          ? cachedSelectedOwnerTab?.owner?.id
          : selectedOwnerId;
      } else {
        selectedOwnerId = localStorage.getItem("selectedOwner")
          ? JSON.parse(localStorage.getItem("selectedOwner"))?.id
          : selectedOwnerId;
      }
    }
    currentUserId = profile.id;
  }

  const FilterEventsData = [
    { title: t("accountOwner.createdByYou"), type: 0 }, // here 0 is not a type taking to check user filter by there id
    { title: t("accountOwner.createdByOthers"), type: -1 }, // here -1 is not a type taking to check user filter by there id
    { title: t("accountOwner.eventsNotAccepted"), type: 4 },
    { title: t("accountOwner.busy"), type: 3 },
    // { title: t('accountOwner.nonAvailableDays'), type: -2 }, // here -2 is not a type taking to check user filter by there id
    { title: t("accountOwner.onLeave"), type: 2 },
    { title: t("scheduler.agenda.appointmentTitle"), type: 1 },
  ];

  const ViewSettingFilter = [
    { title: t("title"), type: "title" },
    { title: t("waitingList.clinicName"), type: "officeName" },
    { title: t("dateAndTime"), type: "timeString" },
  ];

  let cachedEventFilter = getStorage(
    constants.localStorageKeys.agenda.eventFilter
  );
  cachedEventFilter = cachedEventFilter ? cachedEventFilter : [];

  let cacheDashBoardFilter = getStorage(constants.notes.cache.dashboardFilter);

  let agendaEventFilter = cacheDashBoardFilter?.agenda
    ? cacheDashBoardFilter?.agenda
    : [];

  let cachedSettingFilter = getStorage(
    constants.localStorageKeys.agenda.settingFilter
  );
  cachedSettingFilter = cachedSettingFilter ? cachedSettingFilter : [];

  let cachedDate = sessionStorage.getItem("selectedDate");
  cachedDate = cachedDate ? new Date(cachedDate) : new Date();
  const [currentDate, onDateChange] = useState(cachedDate);
  const [agenda, setAgenda] = useState(null);
  const [agendaTypes, setAgendaTypes] = useState(cachedEventFilter);
  const [agendaFilter, setAgendaFilter] = useState([]);
  const [viewSettig, setViewSetting] = useState([]);
  const [settingFilter, setSettingFilter] = useState([]);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    sessionStorage.setItem("selectedDate", moment(currentDate));
  }, [currentDate]);

  const agendaView = [
    { id: "1", name: "Daily" },
    { id: "2", name: "Weekly" },
    { id: "3", name: "Monthly" },
  ];
  const cachedView = sessionStorage.getItem("agendaView") || null;

  const [agendaViewSelected, setAgendaViewSelected] = useState(
    cachedView || agendaView[0].id
  );

  useEffect(() => {
    sessionStorage.setItem("agendaView", agendaViewSelected);
  }, [agendaViewSelected]);

  const {
    selectedOfficeFilter,
    setOfficeFilter,
    apiOffices,
    setApiOffices,
    resetFilters: resetAgendaFilters,
    appliedOfficeFilter,
    setAppliedOfficeFilter,
  } = agendaFiltersData;

  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  const enableFetch = selectedOwnerId && dateRange ? true : false;
  const {
    isLoading,
    isFetching,
    data: agendaData,
    error,
  } = useGetAgenda(
    selectedOwnerId,
    dateRange?.startDate,
    dateRange?.endDate,
    apiOffices,
    { enabled: enableFetch }
  );

  useEffect(() => {
    if (!isLoading && agendaData?.data) {
      setAgenda(agendaData?.data);
      setAgendaFilter(agendaData?.data);
    }
  }, [isLoading, agendaData]);

  const scrollToElement = () => {
    const element = document.getElementById("targetElementIdForMySchedule");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (agendaTypes?.length > 0) {
      filterByEvents();
    }
  }, [agenda]);

  const filterByEvents = () => {
    const updatedAgenda = agenda?.map((item) => {
      let eventsCreatedByUser = [];
      let eventsNotCreatedByUser = [];
      let filteredSchedulerEvents = [];
      let busySlots = [];
      let appointmentsWithBusyType = [];

      if (agendaTypes?.length > 0) {
        // Handle "Created by you" filter
        if (agendaTypes.includes(0)) {
          eventsCreatedByUser =
            item?.schedulerEvents?.filter(
              (event) => event?.createdById === currentUserId
            ) || [];
        }

        // Handle "Created by others" filter
        if (agendaTypes.includes(-1)) {
          eventsNotCreatedByUser =
            item?.schedulerEvents?.filter(
              (event) => event?.createdById !== currentUserId
            ) || [];
        }

        // Normal filtering for schedulerBusySlots
        busySlots =
          item?.schedulerBusySlots?.filter((event) =>
            agendaTypes.includes(event.agendaType)
          ) || [];

        // Special handling for Busy filter
        if (agendaTypes.includes(3)) {
          // Include appointmentSlots with agendaType 5 when Busy filter is selected
          appointmentsWithBusyType =
            item?.appointmentSlots?.filter((event) => event.agendaType === 5) ||
            [];
        } else {
          // Normal filtering for appointmentSlots
          appointmentsWithBusyType =
            item?.appointmentSlots?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [];
        }

        // Normal filtering for scheduler events
        filteredSchedulerEvents =
          item?.schedulerEvents?.filter((event) =>
            agendaTypes.includes(event.agendaType)
          ) || [];

        return {
          ...item,
          schedulerEvents: [
            ...eventsCreatedByUser,
            ...eventsNotCreatedByUser,
            ...filteredSchedulerEvents,
          ],

          appointments:
            item?.appointments?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          appointmentSlots: appointmentsWithBusyType,

          leaves:
            item?.leaves?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          schedulerBusySlots: busySlots,
        };
      }

      return {
        ...item,
        schedulerEvents: [],
        appointments: [],
        leaves: [],
        schedulerBusySlots: [],
        appointmentSlots: [],
      };
    });
    setAgendaFilter(updatedAgenda);
  };

  const resetFilter = () => {
    scrollToElement();
    resetAgendaFilters();
    setAgendaFilter(agenda);
    setAgendaTypes([]);
    setStorage(constants.localStorageKeys.agenda.eventFilter, []);
    setViewSetting([]);
    setSettingFilter([]);
    setStorage(constants.localStorageKeys.agenda.mySettingFilter, []);
    setAppliedOfficeFilter([]);
  };

  useEffect(() => {
    let mySettingFilter = getStorage(
      constants.localStorageKeys.agenda.mySettingFilter
    );
    let settingFilter = mySettingFilter?.length > 0 ? mySettingFilter : [];
    setViewSetting(settingFilter);
    setSettingFilter(settingFilter);
  }, [agendaViewSelected]);

  const applyFilter = () => {
    scrollToElement();
    setApiOffices(selectedOfficeFilter);
    setAppliedOfficeFilter(selectedOfficeFilter);
    if (isMobileTab() && isSidebarActive && selectedOfficeFilter?.length) {
      handleSidebarToggle();
    }

    if (agendaTypes?.length > 0) {
      filterByEvents();
      setStorage(constants.localStorageKeys.agenda.eventFilter, agendaTypes);
    } else {
      setAgendaFilter(agenda);
      setStorage(constants.localStorageKeys.agenda.eventFilter, agendaTypes);
    }
    if (viewSettig?.length > 0) {
      setSettingFilter(viewSettig);
      setStorage(constants.localStorageKeys.agenda.mySettingFilter, viewSettig);
    } else {
      setSettingFilter([]);
      setStorage(constants.localStorageKeys.agenda.mySettingFilter, viewSettig);
    }
  };

  const handleAddToCalendar = async () => {
    setSyncLoading(true);
    const isWeekly = agendaViewSelected === "2";
    const isMonthly = agendaViewSelected === "3";
    let startDate = moment(currentDate).format("YYYY-MM-DD");
    if (isWeekly) {
      startDate = moment(currentDate).startOf("isoWeek").format("YYYY-MM-DD");
    } else if (isMonthly) {
      startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
    }
    try {
      const { data } = await getSyncCalendar(selectedOwnerId, startDate);
      setSyncLoading(false);
      setSyncUrl(data);
      setSyncModal(true);
    } catch (error) {
      toast.error(error.message);
      setSyncLoading(false);
    }
  };

  const dateChange = (type) => {
    let changeDate = agendaViewSelected === "2" ? "week" : "month";
    let date = null;
    if (agendaViewSelected === "1") {
      date =
        type === constants.arrow.PREVIOUS
          ? new Date(moment(currentDate).subtract(1, "day"))
          : new Date(moment(currentDate).add(1, "day"));
    }
    if (agendaViewSelected === "2") {
      date =
        type === constants.arrow.PREVIOUS
          ? new Date(
              moment(currentDate).subtract(1, changeDate).startOf("isoweek")
            )
          : new Date(moment(currentDate).add(1, changeDate).startOf("isoweek"));
    }
    if (agendaViewSelected === "3") {
      date =
        type === constants.arrow.PREVIOUS
          ? new Date(
              moment(currentDate).subtract(1, changeDate).startOf("month")
            )
          : new Date(moment(currentDate).add(1, changeDate).startOf("month"));
    }

    date && onDateChange(date);
  };

  const onActiveStartDateChange = (value) => {
    if (
      constants.calanderActions.includes(value?.action) ||
      (value?.action === constants.calanderActionKey.drillDown &&
        (value?.view === "month" || value?.view === "year"))
    ) {
      value?.activeStartDate && onDateChange(value.activeStartDate);
    }
  };

  const syncCalenderText = useMemo(
    () => (
      <>
        <p>
          Syncing the calendar allows you to add all the events showing in the
          Agenda to your personal calendar on the web.
        </p>
        <p>
          You can sync the Scheduler with your personal calendar by following
          the below-mentioned steps:
        </p>
        <ol>
          <li>Click on the Sync link.</li>
          <li>Click on the link to copy the link.</li>
          <li>
            To sync Agenda on Google Calendar, open{" "}
            <a
              href="http://calendar.google.com"
              rel="noreferrer"
              className="link-btn"
              target="_blank"
            >
              calendar.google.com
            </a>{" "}
            on the browser.
          </li>
          <li>Click on Add Calendar + icon on Google Calendar.</li>
          <li>Select “From URL” from the available options.</li>
          <li>Paste the URL that you copied from Agenda.</li>
          <li>Click on the button for “Add Calendar”.</li>
        </ol>
        <p>
          Once added, all the events from the Agenda screen will be added to
          Google Calendar. By doing so, whenever a new event is added in the
          Scheduler Agenda, the same will be added in the Google calendar as
          well.
        </p>
        <p>
          You can follow the same steps to sync the Agenda with other calendars
          such as Outlook etc.
        </p>
        <p>
          You can also unsubscribe to the calendar to remove the Agenda events
          from your personal calendar.
        </p>
      </>
    ),
    []
  );

  const isFilterApply =
    appliedOfficeFilter?.length ||
    cachedEventFilter?.length ||
    settingFilter?.length;

  return (
    <div
      style={isFromDashBoard ? { maxHeight: "410px", height: "100%" } : {}}
      className={
        isFromDashBoard
          ? "scheduler-tabs-main-wrapper agenda-daily-view m-0"
          : "scheduler-tabs-main-wrapper agenda-daily-view"
      }
    >
      {syncLoading && <Loader />}
      {!isFromDashBoard && (
        <StickySidebar
          isSidebarActive={isSidebarActive}
          handleSidebarToggle={handleSidebarToggle}
          resetFilter={resetFilter}
        >
          <SchedulerCalendar
            value={currentDate}
            onChange={onDateChange}
            onActiveStartDateChange={onActiveStartDateChange}
          />
          <span className={styles.notes_filter}>
            {t("notes.filters")}
            {!!isFilterApply && <span className={styles.red_dot}></span>}
          </span>
          <Accordion
            preExpanded={["a", "b", "c"]}
            className="filter-accordion"
            allowZeroExpanded
            allowMultipleExpanded
          >
            <AccordionItem uuid="a">
              <AccordionItemHeading>
                <AccordionItemButton>{t("offices")}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <OfficeFilter
                  selectedOffice={selectedOfficeFilter}
                  setOfficeFilter={setOfficeFilter}
                  selectedOwnerId={selectedOwnerId}
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="b">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("accountOwner.filterEvents")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <AgendaFilter
                  setAgendaTypes={setAgendaTypes}
                  agendaTypes={agendaTypes}
                  FilterEventsData={FilterEventsData}
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="c">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("accountOwner.viewSetting")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <AgendaFilter
                  setAgendaTypes={setViewSetting}
                  agendaTypes={viewSettig}
                  FilterEventsData={ViewSettingFilter}
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div className="filter-btn-box filter-btn-box--sticky-footer">
            <button
              className={"button button-round button-shadow mr-3"}
              title={t("apply")}
              onClick={applyFilter}
            >
              {" "}
              {t("apply")}
            </button>
            <button
              className={
                "button button-round button-border button-dark reset-btn "
              }
              title={t("reset")}
              onClick={resetFilter}
            >
              {t("reset")}
            </button>
            <button
              className={
                "button button-round button-border button-dark cancel-btn"
              }
              title={t("close")}
              onClick={handleSidebarToggle}
            >
              {t("close")}
            </button>
          </div>
        </StickySidebar>
      )}
      <div
        className={
          isFromDashBoard ? "tabs-right-col p-0 h-100" : "tabs-right-col"
        }
        id="targetElementIdForMySchedule"
      >
        {!isFromDashBoard && (
          <div className={styles["staff-availability-header"]}>
            <ul className={styles["view-color-list"]}>
              <li>
                <div className={styles["color-box"]} />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.createdByYou")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-grey"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.createdByOthers")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-blue"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.eventsNotAccepted")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-orange"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.busy")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-red"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.onLeave")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={
                    styles["color-box"] + " " + styles["light-purple-bg"]
                  }
                />
                <div className={styles["text-box"]}> {t("appointments")} </div>
              </li>
            </ul>
            <div className="d-flex staff-calendar-select-box">
              <div className="monthly-calendar-arrows">
                <>
                  <div
                    className="arrow-img"
                    onClick={() => dateChange(constants.arrow.PREVIOUS)}
                  >
                    <img
                      src={
                        require("assets/images/monthly-arrow-left.svg").default
                      }
                      alt="icon"
                    />
                  </div>

                  <div className="monthly-calendar-text">
                    {agendaViewSelected === "1" &&
                      moment(currentDate).format("ddd, MMM DD")}
                    {agendaViewSelected === "3" &&
                      moment(currentDate).format("MMM YYYY")}

                    {agendaViewSelected === "2" && (
                      <>
                        {`${moment(currentDate)
                          .startOf("isoweek")
                          .format("ddd, MMM DD")} - ${moment(currentDate)
                          .endOf("isoweek")
                          .format("ddd, MMM DD")} `}
                      </>
                    )}
                  </div>
                  <div
                    className="arrow-img"
                    onClick={() => dateChange(constants.arrow.NEXT)}
                  >
                    <img
                      src={
                        require("assets/images/monthly-arrow-right.svg").default
                      }
                      alt="icon"
                    />
                  </div>
                </>
              </div>
              <CustomDropdown
                options={agendaView}
                selectedOption={agendaViewSelected}
                selectOption={setAgendaViewSelected}
              />
            </div>
          </div>
        )}
        {!isFromDashBoard && (
          <div className={styles["agenda-header"]}>
            <div>
              <AddToCalendar
                firstIcon={require("assets/images/ico_sync.svg").default}
                middleText={t("accountOwner.sync")}
                secondIcon={require("assets/images/alert-circle.svg").default}
                handleAddToCalendar={handleAddToCalendar}
                setToolTipModal={setToolTipModal}
              />
            </div>
            <Link
              to="/add-busy-slots"
              className={"button button-round button-shadow " + styles.button}
            >
              {t("accountOwner.addBusyTimeslots")}
            </Link>
          </div>
        )}
        {isFromDashBoard ? (
          <>
            {staffAvailabilityViewSelectedFromDashBoard == "1" && (
              <AgendaDailyView
                isSidebarActive={isSidebarActive}
                currentDate={currentDateFromDashBoard}
                currentUserId={currentUserId}
                setCurrentDate={setCurrentDateFromDashBoard}
                isFromDashBoard={isFromDashBoard}
                viewSettingFilter={settingFilter}
                setDateRange={setDateRange}
                agenda={agendaFilter}
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
              />
            )}
            {staffAvailabilityViewSelectedFromDashBoard == "2" && (
              <AgendaWeeklyView
                isSidebarActive={isSidebarActive}
                currentDate={currentDateFromDashBoard}
                setCurrentDate={onDateChange}
                isWeekly={staffAvailabilityViewSelectedFromDashBoard == "2"}
                currentUserId={currentUserId}
                isFromDashBoard={isFromDashBoard}
                viewSettingFilter={settingFilter}
                setDateRange={setDateRange}
                agenda={agendaFilter}
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
              />
            )}
          </>
        ) : (
          <>
            {agendaViewSelected === "1" && (
              <AgendaDailyView
                isSidebarActive={isSidebarActive}
                currentDate={currentDate}
                currentUserId={currentUserId}
                apiOffices={apiOffices}
                setCurrentDate={onDateChange}
                agendaTypes={agendaTypes}
                cachedEventFilter={agendaEventFilter}
                viewSettingFilter={settingFilter}
                setDateRange={setDateRange}
                agenda={agendaFilter}
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
              />
            )}
            {agendaViewSelected === "2" && (
              <AgendaWeeklyView
                isSidebarActive={isSidebarActive}
                currentDate={currentDate}
                isWeekly={agendaViewSelected === "2"}
                currentUserId={currentUserId}
                viewSettingFilter={settingFilter}
                setDateRange={setDateRange}
                agenda={agendaFilter}
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
              />
            )}

            {agendaViewSelected === "3" && (
              <AgendaMonthView
                currentDate={currentDate}
                setCurrentDate={onDateChange}
                currentUserId={currentUserId}
                viewSettingFilter={settingFilter}
                setDateRange={setDateRange}
                agenda={agendaFilter}
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
              />
            )}
          </>
        )}

        <CustomModal
          isOpen={toolTipModal}
          setIsOpen={setToolTipModal}
          title={t("accountOwner.syncModalTitle")}
          subTitle1={syncCalenderText}
          calender={true}
        />
        <SyncModal
          isOpen={syncModal}
          setIsOpen={setSyncModal}
          title={t("accountOwner.syncModalTitle")}
          syncUrl={syncUrl}
        />
      </div>
    </div>
  );
}

export default withTranslation()(AgendaTab);
