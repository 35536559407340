import React, { useState } from "react";
import Text from "components/Text";
import styles from "../DiscussionNotes.module.scss";
import moment from "moment-timezone";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import DeleteModal from "../modals/DeleteModal";
import DiscussionNoteModal from "../modals/DiscussionNoteModal";

export default function NoteDetailsCard({ t, note, staffId, selectNote, setSelectNote, handleUpdateNotes, deleteNotes }) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const closeModal = () => {
		setIsEditModalOpen(!isEditModalOpen);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(!isDeleteModalOpen);
	};

	const handleCardClick = () => {
		setSelectNote(note);
	};

	return (
		<div className={(selectNote?.id === note?.id) ? styles.dental_card_data : styles.dental_card_data_new} onClick={handleCardClick}>
			<div className={styles.text_dropdown_wrapper}>
				<p className={styles.detail_text}>{note?.description}</p>
				<Dropdown
					isOpen={dropdownOpen}
					toggle={toggle}
					className={styles.dropdown}
				>
					<DropdownToggle
						caret={false}
						tag="div"
						className={styles.padding12 + " " + "dropdown-btn"}
					>
						<img
							src={require("assets/images/dots-icon.svg").default}
							alt="icon"
						/>
					</DropdownToggle>
					<DropdownMenu right className={styles.custom_dropdown_wrapper}>
						<DropdownItem className={`${styles.dropdown_list} p-0`}>
							<p
								to="#"
								className={styles.dropdown_link + " " + "m-0"}
								onClick={() => {
									setIsEditModalOpen(true);
								}}
							>
								{t('discussionNote.edit')}
							</p>
						</DropdownItem>
						<DropdownItem className={`${styles.dropdown_list} p-0`}>
							<p
								className={`${styles.dropdown_link} mb-0`}
								onClick={() => { setIsDeleteModalOpen(true); }}
							>
								{t('discussionNote.delete')}
							</p>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
			<div className={styles.date_wrapper}>
				<Text size="12px" weight="500" color="#87928D">
					{moment(note?.createdAt).format("MMMM D, YYYY")}
				</Text>
				<span className={styles.dot}></span>
				<Text size="12px" weight="500" color="#87928D">
					{moment(note?.createdAt).format("h:mm A")}
				</Text>
			</div>

			{isEditModalOpen && (
				<DiscussionNoteModal
					closeModal={closeModal}
					isModalOpen={isEditModalOpen}
					staffId={staffId}
					selectNote={selectNote}
					isEditing={true}
					handleUpdateNotes={handleUpdateNotes}
				/>
			)}

			{isDeleteModalOpen && (
				<DeleteModal
					closeDeleteModal={closeDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					selectNote={selectNote}
					deleteNotes={deleteNotes}
				/>
			)}
		</div>
	)
}
