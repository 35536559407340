import React, { useState } from "react";
import Select from "react-select";
import { sortByOptions } from "../../../../constants";
import SortingIcon from "../../../../assets/images/more-options-dot.svg";
import styles from "./../TaskManagement.module.scss";

const customDefaultValue = {
  label: (
    <>
      <img src={SortingIcon} alt="bab" className="cursor-pointer" />
    </>
  ),
  value: "default",
};

export default function SortingFilter({ setSortBy, sortBy }) {
  const [selectedOption, setSelectedOption] = useState(customDefaultValue);
  const handleRequestStatusFilter = (selected) => {
    setSortBy(selected.value);
    setSelectedOption(customDefaultValue);
  };

  return (
    <div
      className={
        styles.sorting_filter_width +
        " " +
        "react-select-container cursor-pointer"
      }
    >
      <Select
        classNamePrefix="react-select"
        value={selectedOption}
        defaultValue={customDefaultValue}
        options={sortByOptions}
        onChange={handleRequestStatusFilter}
        isSearchable={false}
        getOptionLabel={(option) => (
          <div className={sortBy === option.value && option.className}>
            {option.label}
          </div>
        )}
        getOptionValue={(option) => option.value}
      />
    </div>
  );
}
