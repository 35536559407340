import React, { useEffect, useState } from 'react'
import { Card } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Text from 'components/Text';
import ReactDatePicker from 'react-datepicker';
import styles from "../../Appointments.module.scss"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { decodeId, encodeId } from 'utils';
import { useAllTrackScheduleChangesAppointments } from 'repositories/patient-appointment-repository';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Loader from 'components/Loader';
import constants from "../../../../../constants";
import Empty from 'components/Empty';
import { useOfficeDetail } from 'repositories/office-repository';

const defaultCurrentDate = moment()?.toDate();
const currentUtcDate = moment()?.utc()?.format('YYYY-MM-DDTHH:mm:ss');

const PAGE_SIZE = 10;
const TrackSchedule = ({ t }) => {
  const profile = useSelector(state => state.userProfile.profile);
  const [pageNumber, setPageNumber] = useState(1)
  const [appointmentSchedules, setAppointmentSchedules] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentDates, setCurrentDates] = useState(defaultCurrentDate);
  const [currentUtcdates, setCurrentUtcdates] = useState(currentUtcDate);
  let { officeId } = useParams();
  officeId = decodeId(officeId);
  let params = {
    pageNumber: pageNumber,
    pageSize: PAGE_SIZE,
    date: currentUtcdates,
    officeId: officeId,
    doctorId: profile?.id
  }
  const { data: trackChangeData, isLoading: trackChangeLoading } = useAllTrackScheduleChangesAppointments(params);

  const { isLoading: officeLoading, data: OfficeData } = useOfficeDetail(officeId);

  const history = useHistory();
  const goBack = () => {
    history.push(constants.routes.appointment.officeSelection.replace(":officeId", encodeId(officeId)))
  };

  useEffect(() => {
    if (!trackChangeLoading && trackChangeData && trackChangeData?.items) {
      setAppointmentSchedules([...trackChangeData?.items]);
      setTotalItems(trackChangeData?.pagination?.totalItems);
    }
  }, [trackChangeLoading, trackChangeData]);


  const updateCurrentDateValue = (e) => {
    setCurrentDates(e);
    setCurrentUtcdates(moment.utc(e).local().format("YYYY-MM-DDTHH:mm:ss"));
  }

  const ShowDate = () => {
    return (
      <ReactDatePicker selected={currentDates} dateFormat="MMM d, yyyy" onChange={(date) => updateCurrentDateValue(date)} className="c-form-control" popperPlacement="bottom" />
    );
  };

  return (
    <Page onBack={goBack} className={styles["main-page"]}>
      {trackChangeLoading || officeLoading && <Loader />}
      <div className={styles['manage-schedule-heading'] + " " + "d-flex justify-content-between"}>
        <div>
          <h3>{t('patientAppointmentMembers.trackScheduleChanges')}</h3>
          <span className={styles['select-office']}>{OfficeData?.name}</span>
        </div>
        <div className="c-field mb-0">
          <label>{t('patientAppointmentMembers.changeDate')}</label>
          <div className="d-flex inputdate">
            <ShowDate />
          </div>
        </div>
      </div>
      {appointmentSchedules?.length ? appointmentSchedules?.map((item) => (
        <Card className={styles['track-schedule'] + " " + "form-wrapper"}>
          <div className={styles['schedule-update']}>
            <Text color='#587E85' weight='600' size='16px' marginBottom='20px' className={styles['schedule-update-text']}> {item?.patient?.id ? t('patientAppointmentMembers.appointmentUpdated') : t('patientAppointmentMembers.scheduleUpdated')}</Text>
            <button className={styles['custom-schedule-btn']}><Text color='#FFFFFF' weight='500' size='12px'>{item?.patient?.id ? t('patientAppointmentMembers.appointment') : t('patientAppointmentMembers.schedule')}</Text></button>
          </div>
          <div className={styles['track-schedul-row']}>
            <div className={styles['track-schedul-col']}>
              <Text color='#102C42' weight='600' size='14px' key={item.id}>{t('patientAppointmentMembers.date')}</Text>
              <Text color='#6F7788' weight='400' size='12px' >{moment(item?.updatedAt)?.local()?.format("MMM DD, YYYY")}</Text>
            </div>
            <div className={styles['track-schedul-col']}>
              <Text color='#6F7788' weight='400' size='12px'>{t('patientAppointmentMembers.time')}</Text>
              <Text color='#102C42' weight='600' size='14px' key={item.id}>{moment(item?.updatedAt)?.local()?.format("hh:mm A")}</Text>
            </div>
            <div className={styles['track-schedul-col']}>
              <Text color='#6F7788' weight='400' size='12px'>{t('patientAppointmentMembers.doneBy')}</Text>
              <Text color='#102C42' weight='600' size='14px' key={item.id}>{`${item?.updatedBy?.firstName} ${item?.updatedBy?.lastName}`}</Text>
            </div>
            {!item?.patient?.id && <div className={styles['track-schedul-col']}>
              <Text color='#6F7788' weight='400' size='12px'>{t('patientAppointmentMembers.scheduleUpdatedfor')}</Text>
              <Text color='#102C42' weight='600' size='14px' key={item.id}>{moment(item?.updatedAt)?.local()?.format("hh:mm A")}</Text>
            </div>}

            {item?.patient?.id && <div className={styles['track-schedul-col']}>
              <Text color='#6F7788' weight='400' size='12px'>{t('patientAppointmentMembers.scheduleUpdatedfor')}</Text>
              <Text color='#102C42' weight='600' size='14px' key={item.id}>{`${item?.patient?.firstName} ${item?.patient?.lastName}`}</Text>
            </div>}
          </div>
        </Card>
      )) : <Empty Message={t('patientAppointmentMembers.notAvailable')} />}
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          sizePerPage: PAGE_SIZE,
          totalSize: totalItems,
          page: pageNumber,
          onPageChange: setPageNumber,
        })}
      >
        {({ paginationProps, paginationTableProps }) => {
          return (
            <div className="data-table-block">
              <div style={{ display: "none" }}>
                <BootstrapTable
                  keyField="id"
                  data={[]}
                  columns={[{ text: "sometext" }]}
                  {...paginationTableProps}
                />
              </div>
              <div className={"pagnation-block "}>
                {totalItems > PAGE_SIZE && (
                  <PaginationListStandalone {...paginationProps} />
                )}
              </div>
            </div>
          )
        }}
      </PaginationProvider>
    </Page>
  );
}
export default withTranslation()(TrackSchedule);