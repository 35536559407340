import React, { useState } from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "../../../DiscussionNotes/DiscussionNotes.module.scss";
import Loader from "components/Loader";
import toast from "react-hot-toast";
import { requestEmploymentProof } from "repositories/employment-proof";
import Input from "components/Input";
import { useQueryClient } from "react-query";

const RequestModal = ({ t, isRequestOpen, closeRequestModal, officeId, profile }) => {
    const queryClient = useQueryClient();
    const [request, setRequest] = useState('');
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const handleOnChange = (e) => {
        setRequest(e.target.value);
    }


    const handleRequest = async () => {
        if (!request) return setError(t("form.errors.emptyField", {
            field: t(`employmentProof.reason`),
        }))
        if (request) setError('');
        setLoader(true);
        try {
            const { firstName, lastName, role } = profile;
            let res = await requestEmploymentProof(officeId, role?.title, `${firstName} ${lastName}`, request);
            toast.success(res.message);
            queryClient.invalidateQueries(["performance-review"]);
            if (res.status) closeRequestModal();
        } catch (err) {
            toast.error(err.message);
            setLoader(false);
        } finally {
            setLoader(false);

        }
    }

    return (
        <Modal
            isOpen={isRequestOpen}
            toggle={closeRequestModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            {loader && <Loader />}
            <span className="close-btn" onClick={closeRequestModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className="mt-3">
                    <Text size="25px" weight="500" color="#111B45">
                        {t('employmentProof.requestHeading')}
                    </Text>
                    <div className="mt-4">
                        <Input
                            Title={t('employmentProof.form.name')}
                            Type="text"
                            Placeholder={t('employmentProof.form.name')}
                            Name={"name"}
                            Value={profile?.firstName + ' ' + profile?.lastName}
                            Disabled={true}
                        />
                    </div>
                    <Input
                        Title={t('employmentProof.form.position')}
                        Type="text"
                        Placeholder={t('employmentProof.form.position')}
                        Name={"position"}
                        Value={profile?.role?.title}
                        Disabled={true}
                    />
                    <Input
                        Title={t('employmentProof.reason')}
                        Type="email"
                        Placeholder={t('employmentProof.reason')}
                        Name={"reason"}
                        HandleChange={handleOnChange}
                        Error={error}
                        Value={request}
                        MaxLength="36"
                    />

                    <div className={styles.btn_container + " " + "btn-box d-md-flex"}>
                        <button
                            className={
                                styles.green_btn + " " + "button button-round button-shadow mr-4 w-sm-100"
                            }
                            title="save"
                            onClick={handleRequest}
                        >
                            {t("employmentProof.request")}
                        </button>
                        <button
                            className={
                                styles.white_btn + " button button-round button-border btn-mobile-link button-dark"
                            }
                            title="cancel"
                            onClick={closeRequestModal}
                        >
                            {t("employmentProof.form.cancel")}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default RequestModal;
