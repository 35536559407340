import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../Notes.module.scss";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import ToggleSwitch from "components/ToggleSwitch";
import ReactDatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import { Fragment, useEffect, useState } from "react";
import moment from "moment-timezone";
import { updateReminder } from "repositories/notes-repository";
import { cloneDeep } from "lodash";
import { useMutation } from "react-query";
import Loader from "components/Loader";
import { handleError, handleSuccess } from "utils";

const ReminderModel = ({ t, noteId, isModalOpen, closeModal, updateKey, handleUpdateReminder, reminderDate, isReminderOn }) => {

    const [dates, setdates] = useState({
        from: moment().toDate(),
        to: moment().endOf("year").toDate(),
    });
    const [startTime, setStartTime] = useState('')
    const startDatePickerRef = useReadOnlyDateTextInput();
    const [isReminder, setIsReminder] = useState(isReminderOn || false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (reminderDate) {
            const date = moment(reminderDate);
            const sameDate = moment(reminderDate).startOf('day').toDate();
            setdates({
                from: sameDate,
                to: moment().endOf("year").toDate(),
            });
            setStartTime(date);
        }
    }, [reminderDate]);

    const convertDateAndTime = () => {
        const datePart = moment(dates?.from);
        const timePart = moment(startTime);
        const combinedDateTimeInIST = datePart.hour(timePart.hour()).minute(timePart.minute()).second(timePart.second());
        const combinedDateTimeInUTC = combinedDateTimeInIST.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        return combinedDateTimeInUTC;
    }


    const { mutate: reminderMutate, isLoading: loading } = useMutation(
        ({ id, payload }) => updateReminder(id, payload), {
        onSuccess: (data) => {
            if (updateKey) updateKey();
            const formateDateAndTime = convertDateAndTime();
            if (handleUpdateReminder) handleUpdateReminder(noteId, { isReminderOn: isReminder, reminderDate: formateDateAndTime });
            handleSuccess(data.message);
            closeModal();
        },
        onError: () => {
            const err = { message: t('notes.reminderErrorMessage') }
            handleError(err);
        }
    });

    const isValidEvent = () => {
        const errorsData = cloneDeep(errors);
        if (!startTime) {
            errorsData.startTime = t("form.errors.emptySelection", {
                field: t("staff.startTime"),
            });
        } else {
            delete errorsData.startTime;
        }
        setErrors(errorsData);
        return !Object.values(errorsData).some((err) => !!err);
    }

    const updateDatesValues = (e, type) => {
        dates[type] = e;
        setdates({ ...dates });
    };

    const handleTimer = (value) => {
        setStartTime(value)
    };

    const handleToggle = () => {
        setIsReminder(!isReminder);
    }

    const handleReminder = async () => {
        if (isValidEvent()) {
            const combinedDateTimeInUTC = convertDateAndTime();
            reminderMutate({ id: noteId, payload: { isReminderOn: isReminder, reminderDate: combinedDateTimeInUTC } })
        }
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <Modal
                isOpen={isModalOpen}
                toggle={closeModal}
                className="modal-dialog-centered modal-lg"
                modalClassName="custom-modal"
            >
                <span className="close-btn" onClick={closeModal}>
                    <img src={crossIcon} alt="close" />
                </span>
                <ModalBody>
                    <h1 className={styles["modal_heading"]}>
                        {t("notes.changeReminder")}
                    </h1>

                    <div className="d-flex align-items-center justify-content-between">
                        <Text
                            size="14px"
                            marginBottom="0px"
                            weight="600"
                            className="mr-2 mt-2"
                            color="#2F3245"
                        >
                            {t("notes.wantToReceiveReminder")}
                        </Text>
                        <ToggleSwitch
                            className="mt-0"
                            label="bill-me-later-toggle"
                            onChange={handleToggle}
                            value={isReminder}
                        />
                    </div>
                    <div className={styles["addnote_calendar_box"] + " " + "w-100 "}>
                        <div className={styles["datpicker_width"] + " " + "c-field mb-3"}>
                            <Text size="13px" weight="400" color="#79869A">
                                {t("notes.date")}
                            </Text>
                            <div className="d-flex inputdate">
                                <ReactDatePicker
                                    dateFormat="dd-MM-yyyy"
                                    className="c-form-control"
                                    selected={dates.from}
                                    onChange={(e) => updateDatesValues(e, "from")}
                                    maxDate={dates.to}
                                    popperPlacement="bottom-start"
                                    ref={startDatePickerRef}
                                    minDate={moment().toDate()}
                                />
                            </div>
                        </div>
                        <div className={styles["datpicker_width"] + " " + "c-field mb-3"}>
                            <div className="c-field">
                                <Text size="13px" weight="400" color="#79869A">
                                    {t("notes.time")}
                                </Text>
                                <div className="custom-dropdown-only">
                                    <TimePicker
                                        showSecond={false}
                                        placeholder="--"
                                        format="h:mm A"
                                        use12Hours
                                        onChange={handleTimer}
                                        className={"busy-slot-time-picker"}
                                        value={startTime}
                                        minuteStep={5}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles["btn_container"] + " " + "btn-box d-md-flex"}
                    >
                        <button
                            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                            title="save"
                            onClick={handleReminder}
                        >
                            {t("notes.changeReminder")}
                        </button>
                        <button
                            className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
                            title="cancel"
                            onClick={closeModal}
                        >
                            {t("notes.cancel")}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default withTranslation()(ReminderModel);