import React, { useState } from 'react'
import Page from 'components/Page'
import styles from "../WaitingList/WaitingList.module.scss"
import { withTranslation } from 'react-i18next'
import Card from 'components/Card'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { decodeId } from 'utils'
import Loader from 'components/Loader'
import Empty from 'components/Empty'
import PatientListTable from '../components/PatientListTable'
import usePatientListModule from '../hooks/usePatientListModule'
import PatientsFilter from './filters/PatientsFilter'
import SearchBar from 'accountOwner/pages/Notes/component/Filters/SearchBar'
import { useOtherDoctorsList, usePatientsList } from 'repositories/patient-appointment-repository'
import ConfirmationModal from '../modal/ConfirmationModal'

const PatientsList = ({ t, isMyPatients, staffDetail }) => {
    const [isSidebarActive, setSidebarActive] = useState(false);
    const handleSidebarToggle = () => {
        setSidebarActive(!isSidebarActive);
        localStorage.setItem("isSidebarActive", !isSidebarActive);
    };

    let { officeId } = useParams();
    const decodeOfficeId = parseInt(decodeId(officeId));
    const usePatientList = isMyPatients ? usePatientsList : useOtherDoctorsList;


    const {
        data: {
            laoding,
            totalItems,
            pageNumber,
            pageSize,
            patientsLists,
            statusFilter,
            isFilterApply,
            searchValue,
            openConfirmModal
        },
        methods: {
            setPageNumber,
            setStatusFilter,
            resetFilter,
            handleApplyFilters,
            handleSearchTerm,
            handleSort,
            handleChangeStatus,
            closeConfirmModal,
            handleActivity
        }
    } = usePatientListModule(decodeOfficeId, usePatientList, staffDetail, isMyPatients);

    let statuslistdata = [
        {
            id: 1,
            type: 'patients.active',

        },
        {
            id: 2,
            type: 'patients.inactive',

        }
    ];

    return (
        <div className="">
            {(laoding) && <Loader />}
            {patientsLists.length > 0 && <SearchBar searchValue={searchValue} handleSearchTerm={handleSearchTerm} t={t} placeholder={t("patients.searchBy")} />}
            <div className={styles.sidebar_table_wrapper}>
                <PatientsFilter
                    isSidebarActive={isSidebarActive}
                    handleSidebarToggle={handleSidebarToggle}
                    statuslistdata={statuslistdata}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    resetFilter={resetFilter}
                    handleApplyFilters={handleApplyFilters}
                    isFilterApply={isFilterApply}
                />
                {patientsLists.length === 0 ? <div className={styles["not_found"]}>
                    <Empty Message={t('patients.notFound')} />
                </div> :
                    <div className={isSidebarActive ? styles.right_side_active : styles.right_side}>
                        <PatientListTable
                            officeId={officeId}
                            waitingLists={patientsLists}
                            totalItems={totalItems}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            setPageNumber={setPageNumber}
                            handleSort={handleSort}
                            handleChangeStatus={handleChangeStatus}
                        />
                    </div>}
            </div>
            {openConfirmModal?.isModalOpen &&
                <ConfirmationModal
                    closeModal={closeConfirmModal}
                    isModalOpen={openConfirmModal}
                    handleApply={handleActivity}
                    message={t("patients.deactiveConfirmMsg", {
                        deactivate: openConfirmModal?.status ? t("patients.deactivate") : t("patients.activate")
                    })}
                />
            }
        </div>
    )
}
export default withTranslation()(PatientsList);