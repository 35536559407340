import { useState, useEffect } from "react";
import constants from "../../../../constants";
import { uniqBy } from "lodash";
import {
  archieveTasks,
  deleteTask,
  getAssigneeStaff,
  taskAction,
  useAccountOwners,
} from "repositories/task-management-repository";
import { useSelector } from "react-redux";
import { getStorage, setStorage } from "utils";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
const pageSize = 10;
const useTaskModule = (
  useAllTasks,
  isArchive = false,
  isFromDashBoard = false
) => {
  const cachedSelectedOwnerTab = getStorage(
    constants.tasks.cache.accountownercheck
  );
  const cachedSelectedDashBoardTab = getStorage(
    constants.notes.cache.dashboardAccountOwnerCheck
  );
  const cacheFilters = getStorage(constants.tasks.cache.myTaskFilter);
  const isFilterApply =
    cacheFilters?.offices?.length > 0 ||
    cacheFilters?.staff?.length > 0 ||
    cacheFilters?.status?.length > 0 ||
    cacheFilters?.priority?.length > 0;
  const [showLoader, setShowLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(
    cacheFilters?.offices || []
  );
  const [appliedStaffFilters, setAppliedStaffFilters] = useState(
    cacheFilters?.staff || []
  );
  const [appliedStatusFilters, setAppliedStatusFilters] = useState(
    cacheFilters?.status || []
  );
  const [appliedPriorityFilters, setAppliedPriorityFilters] = useState(
    cacheFilters?.priority || []
  );
  const [searchValue, setSearchValue] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isAssigneeModal, setIsAssigneeModal] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const profile = useSelector((state) => state.userProfile.profile);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [isAccessible, setIsAccessible] = useState(true);
  const [accountOwner, setAccountOwner] = useState([]);
  const [officeFilter, setOfficeFilter] = useState(cacheFilters?.offices || []);
  const [staffFilter, setStaffFilter] = useState(cacheFilters?.staff || []);
  const [statusFilter, setStatusFilter] = useState(cacheFilters?.status || []);
  const [priorityFilter, setPriorityFilter] = useState(
    cacheFilters?.priority || []
  );
  const [priority, setPriority] = useState({ id: 4, name: "All" });
  const [status, setStatus] = useState({ value: "", label: "All" });
  const [sortBy, setSortBy] = useState(2);
  const queryClient = useQueryClient();

  const { data: ownerList, loading: OwnerLoading } = useAccountOwners({
    enabled: profile?.isAdmin,
  });

  useEffect(() => {
    if (!ownerList?.length) return;

    let selectedOwner = cachedSelectedOwnerTab?.owner;

    const existingOwner = ownerList?.find(
      (item) => item.id === selectedOwner?.id
    );

    const finalOwner = existingOwner || ownerList[0];
    setSelectedOwner(finalOwner);
    setStorage(constants.tasks.cache.accountownercheck, { owner: finalOwner });
  }, [ownerList, cachedSelectedOwnerTab]);

  useEffect(() => {
    if (isFromDashBoard) {
      if (!ownerList?.length) return;
      let selectedOwner = cachedSelectedDashBoardTab?.owner;

      const existingOwner = ownerList?.find(
        (item) => item.id === selectedOwner?.id
      );
      const finalOwner = existingOwner || false;
      if (existingOwner) {
        setSelectedOwner(finalOwner);
        setIsAccessible(true);
      } else {
        setIsAccessible(false);
        setSelectedOwner(existingOwner);
      }
    }
  }, [ownerList, cachedSelectedDashBoardTab]);

  let accountOwnerId = selectedOwner?.id;

  const handleOnChange = (ownerData) => {
    if (ownerData?.id) {
      setSelectedOwner(ownerData);
      setStorage(constants.tasks.cache.accountownercheck, {
        owner: ownerData,
      });
    }
  };

  const { isLoading, data, refetch, isFetching } = useAllTasks(
    pageNumber,
    pageSize,
    appliedOfficeFilters,
    appliedStaffFilters,
    searchValue,
    isArchive,
    appliedPriorityFilters,
    appliedStatusFilters,
    sortBy,
    accountOwnerId,
    {
      enabled:
        profile?.role?.systemRole === constants.systemRoles.accountOwner ||
        !!selectedOwner?.id,
    }
  );

  useEffect(() => {
    if (pageNumber === 1) {
      if (!isLoading && data && data.items) {
        setTasks(uniqBy([...data?.items], "id"));
        setTotalItems(data?.pagination?.totalItems);
        setTotalPages(data?.pagination?.totalPages);
      }
    } else if (!isLoading && data && data.items) {
      setTasks((prev) => uniqBy([...prev, ...data?.items], "id"));
      setTotalItems(data?.pagination?.totalItems);
      setTotalPages(data?.pagination?.totalPages);
    }
  }, [isLoading, data]);

  useEffect(() => {
    setPageNumber(1);
    if (priority.id) {
      if (priority.id < 4) {
        setAppliedPriorityFilters([priority.id]);
        setPriorityFilter([priority.id]);
      } else {
        setAppliedPriorityFilters([]);
        setPriorityFilter([]);
      }
    }
    if (status.value) {
      setAppliedStatusFilters([status.value]);
      setStatusFilter([status.value]);
    } else {
      setAppliedStatusFilters([]);
      setStatusFilter([]);
    }
    queryClient.invalidateQueries([
      "/tasks/accountowner",
      pageNumber,
      pageSize,
      appliedOfficeFilters,
      appliedStaffFilters,
      searchValue,
      isArchive,
      appliedPriorityFilters,
      appliedStatusFilters,
      sortBy,
      accountOwnerId,
    ]);
    refetch();
  }, [isArchive, sortBy, priority, status]);

  const handleOwnerSelection = (owner) => {
    setSelectedOwner(owner);
  };

  const resetFilter = () => {
    setOfficeFilter([]);
    setStaffFilter([]);
    setStatusFilter([]);
    setPriorityFilter([]);
    setAppliedOfficeFilters([]);
    setAppliedStaffFilters([]);
    setAppliedStatusFilters([]);
    setAppliedPriorityFilters([]);
    setPriority({ id: 4, name: "All" });
    setStatus({ value: "", label: "All" });
    setStorage(constants.tasks.cache.myTaskFilter, {
      offices: [],
      staff: [],
      status: [],
      priority: [],
    });
    setPageNumber(1);
  };

  const handleApplyFilters = () => {
    setAppliedOfficeFilters(officeFilter);
    setAppliedStaffFilters(staffFilter);
    setAppliedStatusFilters(statusFilter);
    setAppliedPriorityFilters(priorityFilter);
    setStorage(constants.tasks.cache.myTaskFilter, {
      offices: officeFilter,
      staff: staffFilter,
      status: statusFilter,
      priority: priorityFilter,
    });
    setPageNumber(1);
  };

  const handleSearchTerm = (e) => {
    setPageNumber(1);
    setSearchValue(e.target.value);
  };

  const StaffMembers = async (taskId, pageSize) => {
    setShowLoader(true);
    try {
      const res = await getAssigneeStaff(taskId, pageSize);
      if (res && res?.items?.length) {
        setAssigneeList(res?.items);
        setShowLoader(false);
      }
    } catch (e) {
      setShowLoader(false);
    }
  };

  const handleToggleAssigne = (taskId = "", pageSize) => {
    if (isAssigneeModal) {
      setIsAssigneeModal(false);
      setAssigneeList([]);
    } else {
      StaffMembers(taskId, pageSize);
      setIsAssigneeModal(true);
    }
  };

  const handleAction = async (taskId, TaskAction) => {
    try {
      const res = await taskAction(taskId, TaskAction);
      if (res?.status) {
        toast.success(res.message);
        if (TaskAction === "Cancel") {
          setTasks((prevTasks) => {
            return prevTasks.map((task) =>
              task.id === taskId ? { ...task, overallStatus: 7 } : task
            );
          });
        } else {
          setTasks((prevTasks) => {
            return prevTasks.filter((task) => task.id !== taskId);
          });
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const deleteAction = async (taskId) => {
    try {
      const res = await deleteTask(taskId);
      if (res?.status) {
        toast.success(res.message);
        setTasks((prevTask) => {
          const updatetask = prevTask?.filter((task) => task?.id !== taskId);
          return updatetask;
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleArchiveApi = async (taskId, action) => {
    try {
      const res = await archieveTasks(taskId, action);
      if (res?.status) {
        toast.success(res.message);
        setTasks((prevTask) => {
          const updatetask = prevTask?.filter((task) => task?.id !== taskId);
          return updatetask;
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const isAnyLoading = isLoading || isFetching || showLoader || OwnerLoading;

  return {
    isLoading: isAnyLoading,
    tasks,
    totalItems,
    searchValue,
    isAssigneeModal,
    assigneeList,
    showLoader,
    accountOwner,
    selectedOwner,
    accountOwnerId,
    officeFilter,
    staffFilter,
    statusFilter,
    priorityFilter,
    isFilterApply,
    sortBy,
    priority,
    status,
    pageNumber,
    ownerList,
    isAccessible,
    totalPages,
    setStatus,
    setPriority,
    setTasks,
    setPageNumber,
    handleSearchTerm,
    handleToggleAssigne,
    setAppliedOfficeFilters,
    setAppliedStaffFilters,
    setAppliedStatusFilters,
    setAppliedPriorityFilters,
    setSelectedOwner,
    handleOwnerSelection,
    handleOnChange,
    setOfficeFilter,
    handleApplyFilters,
    setStaffFilter,
    resetFilter,
    setStatusFilter,
    setPriorityFilter,
    handleAction,
    deleteAction,
    handleArchiveApi,
    setSortBy,
  };
};

export default useTaskModule;
