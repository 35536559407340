import React, { Fragment, useCallback, useEffect, useState } from "react";
import Page from "components/Page";
import Text from "components/Text";
import constants, {
  PERFORMANCE_REVIEW_FORM_TYPES,
  PERFORMANCE_REVIEW_STATUS,
  ReviewStatus,
} from "../../../../constants";
import Card from "components/Card";
import styles from "../PerformanceReview.module.scss";
import Form from "../components/Form";
import ReviewSection from "../components/ReviewSection";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId, encodeId } from "utils";
import { useOfficeDetail } from "repositories/office-repository";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { cloneDeep } from "lodash";
import {
  finalizeForm,
  saveAsDraft,
  submitForm,
  useGetFormData,
  useGetQuestions,
} from "repositories/performance-review-repository";
import toast from "react-hot-toast";
import Loader from "components/Loader";
import ReviewDetailSection from "../components/ReviewDetailSection";
import CancelFormModal from "../modals/CancelFormModal";
import { useSelector } from "react-redux";

const initialValues = {
  position: "",
  beginningSalary: "",
  amountOfLastRaise: "",
  proposedIncrease: "",
  employeeName: "",
  dateOfEmployment: new Date(),
  presentSalary: "",
  lastRaiseDate: new Date(),
  proposedIncreaseDate: new Date(),
};

function ReviewForm({ t, history, location }) {
  let { officeId } = useParams();

  const query = new URLSearchParams(location.search);

  const reviewIdFromQuery  = decodeId(query.get("reviewId"));
  const isMyPerformanceReviewFromQuery  = decodeId(query.get("isMyPerformanceReview")) === "true";
  const isGiveFeedbackFromQuery  = decodeId(query.get("isGiveFeedback")) === "true";

  officeId = parseInt(decodeId(officeId));
  const { data: officeDetail } = useOfficeDetail(officeId);
  const {
     review = {id : reviewIdFromQuery},
     isMyPerformanceReview = isMyPerformanceReviewFromQuery,
     isGiveFeedback = isGiveFeedbackFromQuery,
     officeData
      } =
    location?.state || {};

  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [questionnaireResponses, setquestionnaire] = useState([]);
  const [feedbackQuestionnaireResponses, setFeedbackQuestionnaire] = useState(
    []
  );
  const { data: questionsList } = useGetQuestions(
    review?.id,
    isMyPerformanceReview
  );
  const { data: formValue } = useGetFormData(review?.id);

  const [formData, setFormData] = useState({
    ...initialValues,
    position: officeData?.designation,
    employeeName: formValue?.employeeName
      ? formValue?.employeeName
      : formValue?.respondentUser
      ? `${formValue.respondentUser.firstName} ${formValue.respondentUser.lastName}`
      : "",
  });
  const isViewForm =
    ReviewStatus?.submitted === formValue?.status ||
    ReviewStatus?.finalized === formValue?.status ||
    ReviewStatus?.completed === formValue?.status;
  const [isValueChanged, setValueChanged] = useState({});
  const [giveFeedback, setFeedback] = useState(isGiveFeedback);
  const redirectPath = isMyPerformanceReview
    ? "myPerformanceReview"
    : "performanceReview";
  const goBack = () =>
    history.push({
      pathname: constants.routes.accountOwner[redirectPath].replace(
        ":officeId",
        encodeId(officeId)
      ),
      state: { officeData },
    });
  const closeCancelModal = () => setCancelModal(false);

  useEffect(() => {
    if (formValue) {
      const updatedFormValue = {
        ...formValue,
        position: officeData?.designation,
        employeeName: formValue?.employeeName
          ? formValue?.employeeName
          : formValue?.respondentUser
          ? `${formValue.respondentUser.firstName} ${formValue.respondentUser.lastName}`
          : "",
        dateOfEmployment: moment(formValue.dateOfEmployment).toDate(),
        lastRaiseDate: moment(formValue.lastRaiseDate).toDate(),
        proposedIncreaseDate: moment(formValue.proposedIncreaseDate).toDate(),
      };
      setFormData(updatedFormValue);
      setValueChanged((prev) => {
        if (prev) {
          return { ...prev, formData: updatedFormValue };
        } else {
          return { formData: updatedFormValue };
        }
      });
    }
  }, [formValue]);

  const updateDatesValues = (e, type) => {
    setFormData((prevData) => ({ ...prevData, [type]: e }));
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const setMethod = (newItems, id) => {
    const setquestionsResponse = giveFeedback
      ? setFeedbackQuestionnaire
      : setquestionnaire;

    setquestionsResponse((prevResponses = []) => {
      if (prevResponses?.length === 0) {
        return [{ ...newItems }];
      } else {
        const isUpdated = prevResponses?.some((q) => q?.questionnaireId === id);

        if (isUpdated) {
          return prevResponses?.map((q) =>
            q?.questionnaireId === id ? { ...q, ...newItems } : q
          );
        } else {
          return [...prevResponses, newItems];
        }
      }
    });
  };

  const handleSetText = (e, id, date) => {
    const newItems = {
      questionnaireId: id,
      textField: e.target.value,
    };
    if (date) {
      newItems["dateField"] = date;
    }
    setMethod(newItems, id);
  };

  const handleSetDate = (e, id) => {
    const newItems = {
      questionnaireId: id,
      dateField: e,
    };
    setMethod(newItems, id);
  };

  const handleCommentChange = useCallback(
    (id, value) => {
      const newItems = {
        questionnaireId: id,
        comment: value,
      };
      setMethod(newItems, id);
    },
    [giveFeedback]
  );

  const handleRadioChange = useCallback(
    (id, value) => {
      const newItems = {
        questionnaireId: id,
        ratingValue: value,
      };
      setMethod(newItems, id);
    },
    [giveFeedback]
  );

  const isValidFields = () => {
    const errorsData = cloneDeep(errors);
    const filteredFormData = Object.keys(formData)
      .filter((key) => key in initialValues)
      .reduce((acc, key) => {
        acc[key] = formData[key];
        return acc;
      }, {});
    for (const [key, value] of Object.entries(filteredFormData)) {
      if (!value) {
        errorsData[key] = t("form.errors.emptyField", {
          field: t(
            `performanceReview.form.${
              key.charAt(0).toLowerCase() + key.slice(1)
            }`
          ),
        });
      } else {
        delete errorsData[key];
      }
    }
    const questionsResponse = giveFeedback
      ? feedbackQuestionnaireResponses
      : questionnaireResponses;

    const sameLength =
      giveFeedback &&
      !questionsResponse?.some((item) =>
        item?.hasOwnProperty("textField" || "dateField")
      )
        ? questionsList?.length - 1 === questionsResponse?.length
        : questionsList?.length === questionsResponse?.length;

    const allHaveRating = questionsResponse
      ?.filter((item) => !("textField" in item || "dateField" in item))
      ?.every((item) => item?.hasOwnProperty("ratingValue"));
    if (!sameLength || !allHaveRating) {
      errorsData["ratingValue"] = t("performanceReview.pleaseSelect");
    } else {
      delete errorsData.ratingValue;
    }
    if (isViewForm && giveFeedback) {
      const isAnyTextFieldEmptyOrMissing = questionsResponse?.filter(
        (question) => "textField" in question && question.textField !== ""
      );
      if (isAnyTextFieldEmptyOrMissing.length === 0) {
        errorsData["textField"] = t("form.errors.emptyField", {
          field: t(`performanceReview.amount`),
        });
      } else {
        delete errorsData.textField;
      }
    }
    setErrors(errorsData);
    return !Object.values(errorsData).some(Boolean);
  };

  const submitParams = () => {
    const questionsResponse = giveFeedback
      ? feedbackQuestionnaireResponses
      : questionnaireResponses;
    const filteredFormData = Object.keys(formData)
      .filter((key) => key in initialValues)
      .reduce((acc, key) => {
        acc[key] = formData[key];
        return acc;
      }, {});

    const params = {
      ...filteredFormData,
      dateOfEmployment: moment(filteredFormData?.dateOfEmployment).isValid()
        ? moment(filteredFormData?.dateOfEmployment)
        : new Date(),
      LastRaiseDate: moment(filteredFormData?.lastRaiseDate).isValid()
        ? moment(filteredFormData?.lastRaiseDate)
        : new Date(),
      ProposedIncreaseDate: moment(
        filteredFormData?.proposedIncreaseDate
      ).isValid()
        ? moment(filteredFormData?.proposedIncreaseDate)
        : new Date(),
      ProposedIncrease: filteredFormData.proposedIncrease,
      AmountOfLastRaise: filteredFormData.amountOfLastRaise,
      formId: review?.id,
      questionnaireResponses: questionsResponse,
    };
    delete params?.lastRaiseDate;
    delete params?.proposedIncreaseDate;
    delete params?.proposedIncrease;
    delete params?.amountOfLastRaise;
    return params;
  };

  const handleSubmit = async (e) => {
    const typeOfSubmit =
      ReviewStatus?.submitted === formValue?.status
        ? "Complete"
        : ReviewStatus?.completed === formValue?.status
        ? "UpdateFeedbackByReviewer"
        : "Submit";
    e.preventDefault();
    if (isValidFields()) {
      try {
        setShowLoader(true);
        const params = submitParams();
        let res = await submitForm(params, typeOfSubmit);
        toast.success(res.message);
        if (res.status) goBack();
      } catch (err) {
        toast.error(err.message);
      } finally {
        setShowLoader(false);
      }
    }
  };

  const handleFinalize = async () => {
    try {
      setShowLoader(true);
      const params = {
        formId: review?.id,
      };
      let res = await finalizeForm(params);
      toast.success(res.message);
      if (res.status) goBack();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setShowLoader(false);
    }
  };

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== typeof obj2) return false;
    if (typeof obj1 === "object" && typeof obj2 === "object") {
      if (Array.isArray(obj1)) {
        if (obj1.length !== obj2.length) return false;
        return obj1.every((item, index) => deepEqual(item, obj2[index]));
      }
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
    }
    return obj1 === obj2;
  }

  const handleCancel = () => {
    const formDataChanged = !deepEqual(isValueChanged.formData, formData);
    const questionnaireChanged = !deepEqual(
      isValueChanged.questionnaire,
      questionnaireResponses
    );
    const feedbackChanged = !deepEqual(
      isValueChanged.feedbackQuestionnaire,
      feedbackQuestionnaireResponses
    );

    if (ReviewStatus?.completed === formValue?.status) {
      setFeedback(false);
    } else if (formDataChanged || questionnaireChanged || feedbackChanged) {
      setCancelModal(true);
    } else {
      goBack();
    }
  };

  const handleBack = () => {
    const formDataChanged = !deepEqual(isValueChanged.formData, formData);
    const questionnaireChanged = !deepEqual(
      isValueChanged.questionnaire,
      questionnaireResponses
    );
    const feedbackChanged = !deepEqual(
      isValueChanged.feedbackQuestionnaire,
      feedbackQuestionnaireResponses
    );
    if (formDataChanged || questionnaireChanged || feedbackChanged) {
      setCancelModal(true);
    } else {
      goBack();
    }
  };

  const existWithoutSaving = () => {
    closeCancelModal();
    goBack();
  };

  const saveDraft = async () => {
    try {
      setShowLoader(true);
      const params = submitParams();
      let res = await saveAsDraft(params, isMyPerformanceReview);
      toast.success(res.message);
      if (res.status) goBack();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setShowLoader(false);
    }
  };

  const getButtonClass = (status) => {
    const buttonClassMap = {
      submitted: styles.submitted,
      pending: styles.pending,
      completed: styles.completed,
      finalized: styles.finalized,
      general: styles.general,
    };
    return `${styles.notes_btn} ${buttonClassMap[status] || ""}`;
  };

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const cardStatus = PERFORMANCE_REVIEW_STATUS.find(
    (status) => status.id === formValue?.status
  );
  const cardType = PERFORMANCE_REVIEW_FORM_TYPES[formValue?.type];

  const displayedQuestions =
    !giveFeedback &&
    ReviewStatus?.submitted === formValue?.status &&
    !isMyPerformanceReview
      ? questionsList?.slice(0, -2)
      : questionsList;

  useEffect(() => {
    const staffResponse = displayedQuestions?.flatMap((question) =>
      question?.questionnaireFeedback?.flatMap((feedback) => {
        const data = {
          questionnaireId: question.id,
          ratingValue: feedback?.ratingValue || null,
          comment: feedback?.comment || "",
        };

        if (feedback?.isRespondentResponse) {
          return {
            ...data,
          };
        } else {
          return [];
        }
      })
    );
    const adminResponse = displayedQuestions?.flatMap((question) =>
      question?.questionnaireFeedback?.flatMap((feedback) => {
        const data = {
          questionnaireId: question.id,
          ratingValue: feedback?.ratingValue || null,
          comment: feedback?.comment || "",
        };
        if (feedback?.dateField) {
          data["dateField"] = feedback?.dateField;
        }
        if (feedback?.textField) {
          data["textField"] = feedback?.textField;
        }

        if (
          (giveFeedback ||
            ReviewStatus?.completed === formValue?.status ||
            ReviewStatus?.finalized === formValue?.status) &&
          !feedback?.isRespondentResponse
        ) {
          return {
            ...data,
          };
        } else {
          return [];
        }
      })
    );

    setquestionnaire(staffResponse);
    setFeedbackQuestionnaire(adminResponse);
    setValueChanged((prev) => {
      if (prev) {
        return {
          ...prev,
          questionnaire: staffResponse,
          feedbackQuestionnaire: adminResponse,
        };
      } else {
        return {
          questionnaire: staffResponse,
          feedbackQuestionnaire: adminResponse,
        };
      }
    });
  }, [giveFeedback, questionsList]);

  return (
    <Page onBack={handleBack} title={officeDetail?.name}>
      {showLoader && <Loader />}
      <Text
        size="14px"
        weight="300"
        color="#000000"
        lineHeight="21px"
        marginTop="-14px"
      >
        {t("performanceReview.performanceReview")}
      </Text>
      <Card
        className={styles.performance_review_wrapper_card}
        radius="10px"
        marginTop="30px"
      >
        <div className={styles.performance_review_wrapper}>
          <div className={styles.performance_review_left_section}>
            {isViewForm && !giveFeedback && (
              <div className={styles.btn_date_wrapper}>
                <button className={getButtonClass(cardStatus?.title)}>
                  {capitalizeFirstLetter(
                    t(`performanceReview.${cardStatus?.title}`)
                  )}
                </button>
                <button className={`${styles.notes_btn} ${styles.general}`}>
                  {capitalizeFirstLetter(t(`performanceReview.${cardType}`))}
                </button>
              </div>
            )}
            <Text
              size="20px"
              weight="500"
              lineHeight="24px"
              color="#111B45"
              marginTop="30px"
            >
              {/* {formValue?.respondentUser &&
                `${formValue?.respondentUser?.firstName} ${formValue?.respondentUser?.lastName}`} */}
              {formValue?.employeeName
                ? formValue?.employeeName
                : formValue?.respondentUser
                ? `${formValue?.respondentUser?.firstName} ${formValue?.respondentUser?.lastName}`
                : ""}
            </Text>
            {isViewForm && !giveFeedback ? (
              <ReviewDetailSection
                t={t}
                review={review}
                formData={formData}
                initialValues={initialValues}
                setFeedback={setFeedback}
                giveFeedback={giveFeedback}
                isMyPerformanceReview={isMyPerformanceReview}
                showGiveFeebackButton={
                  ReviewStatus?.submitted === formValue?.status
                }
                showFinalize={ReviewStatus?.completed === formValue?.status}
                handleFinalize={handleFinalize}
              />
            ) : (
              <Fragment>
                <div className={styles.review_details}>
                  <div className={styles.review_sent_tag}>
                    <Text
                      size="12px"
                      weight="400"
                      lineHeight="12px"
                      color="#6F7788"
                    >
                      {t("performanceReview.reviewSent")}
                    </Text>
                    <Text
                      size="14px"
                      weight="600"
                      lineHeight="14px"
                      color="#102C42"
                      marginTop="10px"
                    >
                      {review &&
                        moment(formValue?.createdAt).format("MMM D, YYYY")}
                    </Text>
                  </div>
                  {formValue?.submittedOn && !formValue?.completedOn && (
                    <div className={styles.review_sent_tag}>
                      <Text
                        size="12px"
                        weight="400"
                        lineHeight="12px"
                        color="#6F7788"
                      >
                        {t("performanceReview.sumbmittedOn")}
                      </Text>
                      <Text
                        size="14px"
                        weight="600"
                        lineHeight="14px"
                        color="#102C42"
                        marginTop="10px"
                      >
                        {review &&
                          moment(formValue?.submittedOn).format("MMM D, YYYY")}
                      </Text>
                    </div>
                  )}
                  {formValue?.completedOn && (
                    <div className={styles.review_sent_tag}>
                      <Text
                        size="12px"
                        weight="400"
                        lineHeight="12px"
                        color="#6F7788"
                      >
                        {t("performanceReview.completedOn")}
                      </Text>
                      <Text
                        size="14px"
                        weight="600"
                        lineHeight="14px"
                        color="#102C42"
                        marginTop="10px"
                      >
                        {review &&
                          moment(formValue?.completedOn).format("MMM D, YYYY")}
                      </Text>
                    </div>
                  )}
                </div>
                <Form
                  t={t}
                  handleSubmit={handleSubmit}
                  handleOnChange={handleOnChange}
                  errors={errors}
                  formData={formData}
                  updateDatesValues={updateDatesValues}
                  handleCancel={handleCancel}
                  isDisabled={
                    ReviewStatus?.completed === formValue?.status ||
                    ReviewStatus?.submitted === formValue?.status
                  }
                />
              </Fragment>
            )}
          </div>
          <div className={styles.performance_review_right_section}>
            <ReviewSection
              t={t}
              questionsList={displayedQuestions}
              handleCommentChange={handleCommentChange}
              handleRadioChange={handleRadioChange}
              questionnaireResponses={questionnaireResponses}
              feedbackQuestionnaireResponses={feedbackQuestionnaireResponses}
              errors={errors}
              isView={isViewForm}
              handleSetText={handleSetText}
              handleSetDate={handleSetDate}
              giveFeedback={giveFeedback}
              isMyPerformanceReview={isMyPerformanceReview}
              review={review}
            />
          </div>
        </div>
      </Card>
      {isCancelModalOpen && (
        <CancelFormModal
          t={t}
          isCancelModalOpen={isCancelModalOpen}
          closeCancelModal={closeCancelModal}
          existWithoutSaving={existWithoutSaving}
          showLoader={showLoader}
          saveDraft={saveDraft}
        />
      )}
    </Page>
  );
}

export default withTranslation()(ReviewForm);
