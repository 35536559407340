import React, { useState } from "react";
import styles from "../TaskManagement.module.scss";
import { motion } from "framer-motion";
import { withTranslation } from "react-i18next";
import TaskManagementCard from "../TaskManagementCard";
import useTaskModule from "../hooks/useTaskModule";
import { useAllTasks } from "repositories/task-management-repository";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";
import HeaderFilter from "../Filters/HeaderFilter";
import FilterTasks from "../Filters"
import SearchBar from "../Filters/Searchbar";
import AssigneesModal from "../Modals/AssigneesModal";
import Empty from "components/Empty";
import { cacheSideBarActive } from "utils";

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const getButtonClass = (status) => {
    const statusClasses = {
        Pending: styles.Pending,
        Cancelled: styles.Cancelled,
        Started: styles.Started,
        Accepted: styles.Accepted,
        Rejected: styles.Rejected,
        "In Progress": styles.InProgress,
        Completed: styles.Completed
    };

    return `${styles.task_management_card_btn} ${statusClasses[status] || ""}`;
};

const MyOwnTasks = ({ t, tasks = [],
    searchValue,
    handleSearchTerm,
    officeFilter,
    handleApplyFilters,
    setOfficeFilter,
    staffFilter,
    setStaffFilter,
    resetFilter,
    statusFilter,
    priorityFilter,
    setStatusFilter,
    setPriorityFilter,
    isLoading,
    totalItems,
    setPageNumber,
    handleAction,
    deleteAction,
    isArchive,
    isFilterApply,
    accountOwnerId,
    sortBy,
    setSortBy,
    priority,
    setPriority,
    status,
    setStatus,
    handleArchiveApi
}) => {

    const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());
    const {
        isAssigneeModal,
        assigneeList,
        handleToggleAssigne
    } = useTaskModule(useAllTasks, isArchive);

    const handleSidebarToggle = () => {
        setSidebarActive(!isSidebarActive);
        localStorage.setItem("isSidebarActive", !isSidebarActive);
    };


    let items = <div className={styles["task_management_card_grid"]}>
        {tasks?.map((task, index) => {
            return (
                <TaskManagementCard
                    key={index}
                    myTask={task}
                    className={!isSidebarActive ? styles.task_management_status_card_active : styles.task_management_status_card_not_active}
                    handleToggleAssigne={handleToggleAssigne}
                    getButtonClass={getButtonClass}
                    handleAction={handleAction}
                    deleteAction={deleteAction}
                    handleArchiveApi={handleArchiveApi}
                    isArchive={isArchive}
                />
            )
        })}
    </div>

    return (
        <>
            {isLoading && <Loader />}
            <div className={styles["dropdown_radio_search_wrapper"]}>
                <HeaderFilter
                 setSortBy={setSortBy}
                 sortBy={sortBy}
                 priority ={priority}
                 setPriority ={setPriority}
                 status={status}
                 setStatus ={setStatus}
                />
                <SearchBar searchValue={searchValue} handleSearchTerm={handleSearchTerm} t={t} />
            </div>
            <div className="scheduler-tabs-main-wrapper">
                <FilterTasks
                    t={t}
                    isSidebarActive={isSidebarActive}
                    handleSidebarToggle={handleSidebarToggle}
                    resetFilter={resetFilter}
                    officeFilter={officeFilter}
                    setOfficeFilter={setOfficeFilter}
                    handleApplyFilters={handleApplyFilters}
                    staffFilter={staffFilter}
                    setStaffFilter={setStaffFilter}
                    statusFilter={statusFilter}
                    priorityFilter={priorityFilter}
                    setStatusFilter={setStatusFilter}
                    setPriorityFilter={setPriorityFilter}
                    isFilterApply={isFilterApply}
                    accountOwnerId={accountOwnerId}
                />
                {tasks?.length === 0 ? (<div className={styles["not_found"]}>
                    <Empty Message={t('taskManagement.taskNotFound')} />
                </div>) :
                    <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                        <InfiniteScroll
                            className="w-100"
                            dataLength={tasks.length}
                            hasMore={tasks.length < totalItems}
                            next={() => setPageNumber((v) => v + 1)}
                        >
                            {items}
                        </InfiniteScroll>
                    </motion.div>
                }
            </div>
            {isAssigneeModal && (
                <AssigneesModal
                    isModalOpen={isAssigneeModal}
                    closeModal={handleToggleAssigne}
                    assignees={assigneeList}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
export default withTranslation()(MyOwnTasks);
