import React, { useState } from 'react'
import Page from 'components/Page'
import WaitingListTable from '../components/WaitingListTable'
import styles from "./WaitingList.module.scss"
import { withTranslation } from 'react-i18next'
import WaitingFilter from './filters/WaitingFilter'
import Card from 'components/Card'
import useWaitingListModule from '../hooks/useWaitingListModule'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { decodeId } from 'utils'
import Loader from 'components/Loader'
import { useOfficeDetail, useStaffByUserId } from 'repositories/office-repository'
import Empty from 'components/Empty'
import constants from '../../../../constants'
import { useSelector } from 'react-redux'


const WaitingList = ({ history, t }) => {
    const profile = useSelector(state => state.userProfile.profile);

    const [isSidebarActive, setSidebarActive] = useState(false);
    const handleSidebarToggle = () => {
        setSidebarActive(!isSidebarActive);
        localStorage.setItem("isSidebarActive", !isSidebarActive);
    };

    let { officeId } = useParams();
    const decodeOfficeId = parseInt(decodeId(officeId));
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);
    const { isLoading: isLoadingStaffDetail, data: staffDetail } = useStaffByUserId(decodeOfficeId, profile?.id);

    const {
        data: {
            waitingLists,
            laoding,
            totalItems,
            pageNumber,
            pageSize,
            doctorsFilterData,
            statusFilter,
            doctorFilter,
            isFilterApply
        },
        methods: {
            setPageNumber,
            setStatusFilter,
            setDoctorsFilter,
            resetFilter,
            handleApplyFilters,
            handleUpdateList,
            handleSort
        }
    } = useWaitingListModule(decodeOfficeId);

    let statuslistdata = [
        {
            id: 1,
            type: 'waitingList.pending',

        },
        {
            id: 2,
            type: 'waitingList.cancelled',

        },
        {
            id: 3,
            type: 'waitingList.converted',

        },
    ];

    const goBack = () => {
        if(profile?.role?.systemRole === constants.systemRoles.accountOwner) {
            history.push(constants.routes.accountOwner.officeOptions.replace(":officeId", officeId));
        }
        else if(staffDetail?.isAdmin) {
            history.push(constants.routes.staff.officeAdmin.replace(":officeId",officeId))
        } else {
            history.push(constants.routes.staff.officeOptions.replace(":officeId", officeId));
        }
    }


    return (
        <Page className="scheduler-page" title={officeDetail?.name} onBack={goBack}>
            {(laoding || isLoadingOfficeDetail) && <Loader />}
            <Card>
                <div className={styles.sidebar_table_wrapper}>
                    <WaitingFilter
                        isSidebarActive={isSidebarActive}
                        handleSidebarToggle={handleSidebarToggle}
                        statuslistdata={statuslistdata}
                        doctorsFilterData={doctorsFilterData}
                        statusFilter={statusFilter}
                        doctorFilter={doctorFilter}
                        setStatusFilter={setStatusFilter}
                        setDoctorsFilter={setDoctorsFilter}
                        resetFilter={resetFilter}
                        handleApplyFilters={handleApplyFilters}
                        isFilterApply={isFilterApply}
                    />
                    {waitingLists?.length === 0 ? <div className={styles["not_found"]}>
                        <Empty Message={t('waitingList.notFound')} />
                    </div> :
                        <div className={isSidebarActive ? styles.right_side_active : styles.right_side}>
                            <WaitingListTable
                                handleUpdateList={handleUpdateList}
                                officeId={officeId}
                                waitingLists={waitingLists || []}
                                totalItems={totalItems}
                                pageNumber={pageNumber}
                                pageSize={pageSize}
                                setPageNumber={setPageNumber}
                                handleSort={handleSort}
                            />
                        </div>}
                </div>
            </Card>
        </Page>
    )
}
export default withTranslation()(WaitingList);