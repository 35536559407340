import React from 'react';
import { Modal, ModalBody } from "reactstrap";
import styles from "../../Appointments/Appointments.module.scss"
import moment from 'moment';
import Text from 'components/Text';

const AppointmentDetailModal = ({ t, setApptDetails, apptDetails, appointmentDetail, menuOptions, handleToggle}) => {

    return (
        <Modal toggle={() => setApptDetails(!apptDetails)} isOpen={apptDetails} className="modal-dialog-centered deactivate-modal modal-md">
            <div className={styles['timeslot-modal']}>
                <ModalBody className={styles['modal-content-part'] + " " + "d-flex flex-column p-0 mb-3"}>
                    <div className={styles['blocking-content-box']}>
                        <span className="close-btn d-flex justify-content-end cursor-pointer" onClick={() => setApptDetails(!apptDetails)}>
                            <img src={require('assets/images/cross.svg').default} alt="close" />
                        </span>
                        <div className={styles.modal_flex}>
                            <Text size="16px" weight="600" color="#587E85">
                                {`${appointmentDetail?.patient?.firstName} ${appointmentDetail?.patient?.lastName}`}
                            </Text>
                            <Text size="12px" weight="400" color="#6F7788">
                                {`${appointmentDetail?.doctor?.firstName} ${appointmentDetail?.doctor?.lastName}`}
                            </Text>
                            <Text size="12px" weight="400" color="#6F7788">
                                {t("patientAppointmentMembers.dateOfAppointment")}
                            </Text>
                            <Text size="14px" weight="600" color="#102C42">
                                {`${moment(appointmentDetail?.appointmentDate).format("MMM DD, YYYY")}  ${appointmentDetail?.appointmentTime?.length && appointmentDetail?.appointmentTime[0]}`}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.detail_btn_container} >
                        {menuOptions.map((option, index) => (
                            <div key={index} className={styles["office-dropdown-item"]}>
                                <button className={index === 0 ? "button button-round button-border button-dark w-100" : "button button-round button-shadow text-nowrap w-100"} onClick={(e) => handleToggle(e, option)}>{option?.text}</button>
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default AppointmentDetailModal