import {
  useSendbirdStateContext,
  sendbirdSelectors,
} from "@sendbird/uikit-react";
import { GroupChannelHandler } from "@sendbird/chat/groupChannel";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import constants from "../../../constants";
import { handleError } from "utils";
import toast from "react-hot-toast";

const ALL_OFFICES = [{ value: 0, label: "All Offices" }];

const allowedImageTypes = constants.chat.allowedTypesForGroupImage;
const allowedDocTypes = constants.chat.allowedTypesForDocs;
//const APP_UNAVAILABLE = 403100;

const useConversation = (dependencies) => {
  const {
    t,
    currentUser,
    currentChannel,
    setCurrentChannel,
    selectedOwner,
    isInternalChat,
    isExternalChat,
  } = dependencies;
  const [fileMessage, setFileMessage] = useState(null);
  const [fileViewer, setFileViewer] = useState(false);
  const [searchText, setSearchText] = useState("");

  //Sendbird Context Data
  const context = useSendbirdStateContext();
  const isSdkLoading = context?.stores?.sdkStore?.loading;
  const isUserLoading = context?.stores?.userStore?.loading;
  const sdkError = context?.stores?.sdkStore?.error;
  const [isSdkError, setIsSdkError] = useState(false);
  const [filteredOffices, setFilteredOffices] = useState(
    isInternalChat ? [] : [constants.chat.ALL_OFFICES]
  );
  const [filteredOfficesForNew, setFilteredOfficesForNew] = useState([
    constants.chat.ALL_OFFICES,
  ]);
  const [selectedOption, setSelectedOption] = useState([]);

  const handleRequestStatusFilter = (incomingSelected, allOffices) => {
    const index = selectedOption.findIndex(
      (item) => item.value === incomingSelected.value
    );
    let selected = [...selectedOption];
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(incomingSelected);
    }

    let allSelected = false;
    if (selected.length > 0) {
      allSelected = selected[selected.length - 1].value === 0;
    }
    if (allSelected) {
      setSelectedOption(ALL_OFFICES);
      setFilteredOfficesForNew([]);
      return;
    }
    const isAllSelected = selected.some((item) => item.value === 0);
    if (isAllSelected) {
      const updated = selected.filter((item) => item.value);
      setSelectedOption([...updated]);
      setFilteredOfficesForNew(updated.map((item) => item.value));
    } else {
      setSelectedOption(selected);
      setFilteredOfficesForNew(selected.map((item) => item.value));
    }
  };

  useEffect(() => {
    if (sdkError) {
      setIsSdkError(true);
      handleError(new Error(t("messenger.sdkErrorMessage")));
    }
  }, [sdkError]);

  useEffect(() => {
    if (filteredOffices[0] === undefined) {
      setFilteredOffices([constants.chat.ALL_OFFICES]);
      localStorage.setItem(
        constants.localStorageKeys.persistOfficeFilter,
        JSON.stringify([constants.chat.ALL_OFFICES])
      );
    }
    if (selectedOption.length === 0) {
      setSelectedOption(ALL_OFFICES);
    }
  }, [filteredOffices, selectedOption]);

  useEffect(() => {
    let existingFilters = localStorage.getItem(
      constants.localStorageKeys.persistOfficeFilter
    );
    if (!existingFilters) {
      return;
    }

    if (existingFilters) {
      setFilteredOffices(JSON.parse(existingFilters));
    }
  }, []);

  const sdk = sendbirdSelectors.getSdk(context);
  const sendUserMessage = sendbirdSelectors.getSendUserMessage(context);
  const sendFileMessage = sendbirdSelectors.getSendFileMessage(context);

  const [profileContactInfo, setProfileContactInfo] = useState(false);
  const [channelListQuery, setChannelListQuery] = useState({
    channelListQuery: {
      limit: constants.chat.chatListLimit,
      hiddenChannelFilter: constants.chat.unArchived,
      includeEmpty: true,
      customTypesFilter: isInternalChat
        ? [constants.chat.internal]
        : isExternalChat
        ? [constants.chat.external]
        : [constants.chat.patient.open, constants.chat.patient.close],
      order: constants.chat.latestLastMessage,
    },
  });

  const [refresh, setRefresh] = useState(1);
  const updateChannelList = () => setRefresh((prev) => prev + 1);
  const uuidRef = useRef("");

  const onMessageReceived = useCallback(
    (groupChannel) => {
      if (groupChannel.url === currentChannel?.url) {
        setCurrentChannel(groupChannel);
        currentChannel.markAsRead();
        groupChannel.markAsRead();
      }
      const { customType, data } = groupChannel;
      if (isInternalChat && data) {
        let groupChannelOwner;
        try {
          groupChannelOwner = JSON.parse(data).accountOwner;
        } catch (error) {
          toast.error(error.message);
        }
        if (customType === constants.chat.internal) {
          if (
            currentUser?.isAccountOwner ||
            groupChannelOwner?.id?.toString() === selectedOwner?.id?.toString()
          ) {
            updateChannelList();
            return;
          }
        }
      } else {
        updateChannelList();
      }
    },
    [
      selectedOwner,
      currentChannel,
      isInternalChat,
      setCurrentChannel,
      currentUser,
    ]
  );

  const onChannelDeleted = (groupChannelUrl) => {
    if (groupChannelUrl === currentChannel?.url) {
      setCurrentChannel(null);
      setProfileContactInfo(false);
    }
  };


  useEffect(() => {
    try {
      if (sdk?.groupChannel?.addGroupChannelHandler) {
        uuidRef.current = uuidv4();
        const channelHandlerInstance = new GroupChannelHandler({
          onMessageReceived,
          onChannelDeleted,
        });
        sdk.groupChannel.addGroupChannelHandler(
          uuidRef.current,
          channelHandlerInstance
        );
      }
      return () => {
        if (uuidRef.current && sdk?.groupChannel?.removeGroupChannelHandler) {
          sdk?.groupChannel?.removeGroupChannelHandler(uuidRef.current);
        }
      };
    } catch (err) {
      setIsSdkError(true);
      handleError(new Error(t("messenger.sdkErrorMessage")));
    }
  }, [sdk, currentChannel]);

  //Methods
  const handleSort = useCallback((allChannels) => allChannels, [refresh]);

  const handleProfileBtn = () => {
    setProfileContactInfo(!profileContactInfo);
  };
  const handleProfileBtnClose = () => {
    setProfileContactInfo(false);
  };
  const handleChannelSelect = (channel) => {
    setCurrentChannel(channel);
  };
  const handleBackBtn = () => {
    setCurrentChannel(null);
  };

  const handleOnBeforeSendUserMessageOrFile = useCallback(
    (textOrFile) => {
      const channelMembers = JSON.parse(currentChannel.data)?.members;
      const myUserData = channelMembers?.filter(
        (m) => m?.id?.toString() === currentUser?.id?.toString()
      )?.[0];
      const officeName = myUserData?.officeName;
      const officeId = myUserData?.officeId;
      const userMessageParams = {};
      const data = {
        officeName,
        officeId,
      };
      userMessageParams.data = JSON.stringify(data);
      if (typeof textOrFile === "string") {
        userMessageParams.message = textOrFile;
      } else {
        const { type, size, name } = textOrFile;
        userMessageParams.file = textOrFile;
        const data = {
          officeName,
          officeId,
        };
        userMessageParams.data = JSON.stringify(data);
        if (allowedImageTypes.includes(type)) {
          userMessageParams.customType = "image";
          userMessageParams.thumbnailSizes = [
            { maxWidth: 200, maxHeight: 200 },
          ];
        }
        if (allowedDocTypes.includes(type)) {
          userMessageParams.customType = "document";
        }
        userMessageParams.fileName = name;
        userMessageParams.fileSize = size;
        userMessageParams.mimeType = type;
      }
      return userMessageParams;
    },
    [currentChannel, currentUser]
  );

  return {
    state: {
      currentChannel,
      profileContactInfo,
      channelListQuery,
      fileMessage,
      fileViewer,
      filteredOffices,
      filteredOfficesForNew,
      searchText,
      selectedOption,
    },
    otherData: {
      isSdkLoading,
      isUserLoading,
      isSdkError,
      sdk,
    },
    updateMethods: {
      setCurrentChannel,
      setProfileContactInfo,
      setChannelListQuery,
      setFileMessage,
      setFileViewer,
      setFilteredOffices,
      updateChannelList,
      setSearchText,
      setFilteredOfficesForNew,
      setSelectedOption,
    },
    otherMethods: {
      handleProfileBtn,
      handleProfileBtnClose,
      handleChannelSelect,
      handleBackBtn,
      sendUserMessage,
      handleSort,
      sendFileMessage,
      handleOnBeforeSendUserMessage: handleOnBeforeSendUserMessageOrFile,
      handleOnBeforeSendFileMessage: handleOnBeforeSendUserMessageOrFile,
      handleRequestStatusFilter,
    },
  };
};

export default useConversation;
