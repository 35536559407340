import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "../AddEvent.module.scss";
import userDefaultImage from "./../../../../assets/images/staff-default-rounded.png";

function ViewEmployeeModal({
  t,
  issaveEmployeeModalOpen,
  setIsSaveEmployeeModalOpen,
  allMembersList,
}) {
  return (
    <>
      <Modal
        isOpen={issaveEmployeeModalOpen}
        toggle={() => setIsSaveEmployeeModalOpen(false)}
        className={
          "modal-dialog-centered " + styles["add-employee-modal-dialog"]
        }
        modalClassName="custom-modal"
      >
        <span
          className="close-btn"
          onClick={() => setIsSaveEmployeeModalOpen(false)}
        >
          <img src={crossIcon} alt="close" />
        </span>
        <ModalBody>
          <Text size="25px" marginBottom="10px" weight="500" color="#111b45">
            <span className="modal-title-25">
              {" "}
              {t("accountOwner.notifyEmployees")}
            </span>
          </Text>

          <ul className={"modal-employee-list " + styles["employee-list"]}>
            {allMembersList.length > 0 &&
              allMembersList.map((val, key) => (
                <li key={key}>
                  <div>
                    <label>
                      <span>
                        {" "}
                        <img
                          src={val?.user?.profilePic || userDefaultImage}
                          width={40}
                          height={40}
                          className={styles.image_style}
                          alt="profile"
                        />{" "}
                        {val?.user?.firstName} {val?.user?.lastName}{" "}
                      </span>
                    </label>
                  </div>
                </li>
              ))}
            {allMembersList.length === 0 && (
              <li>
                <div className="ch-checkbox">
                  <label>{t("noRecordFound")}</label>
                </div>
              </li>
            )}
          </ul>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTranslation()(ViewEmployeeModal);
