import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Suspense } from "react";
import Loader from "components/Loader";
import { withTranslation } from "react-i18next";
import OpenInIcon from "../../../../assets/images/open_in_new.svg";
import styles from "../Dashboard.module.scss";
import ToggleButton from "../components/ToggleButton";
import NewGroupPopup from "Messenger/pages/TeamConversation/components/Modals/NewGroupPopup";
import useTeamConversationState from "Messenger/hooks/useTeamConversationState";
import useSelectedAccountOwner from "Messenger/hooks/useSelectedAccountOwner";
import constants from "../../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setStorage } from "utils";
import { useSelector } from "react-redux";
import Tooltip from "reactstrap/lib/Tooltip";
import DashboardCommonChat from "./CommonChat";
import NotAccessible from "../components/NotAccessible";
const Chat = ({ t, appliedOfficeFilters }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeToggle, setActiveToggle] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSubscriptionModel, setIsSubscriptionModel] = useState(false);
  const [tooltipEditOpen, setTooltipEditOpen] = useState(false);

  const modulesAccess = useSelector((prev) => prev);
  const history = useHistory();
  const [selectedStaff, setSelectedStaff] = useState([]);

  const isThisUserSelected = (staff) =>
    selectedStaff.some((selected) => selected.id === staff.id);

  const handleSelectStaff = (e, staff) => {
    if (e.target.checked) {
      setSelectedStaff((prev) => [...prev, staff]);
    } else {
      setSelectedStaff((prev) =>
        prev.filter((selected) => selected.id !== staff.id)
      );
    }
  };

  const closeStepOneModal = () => {
    console.log("Modal closed");
    setIsPopupOpen(false);
  };

  const handleStepOne = () => {
    console.log("Proceed to the next step with selected staff:", selectedStaff);
  };

  const disableOtherUsers = (staff) => false;

  const loadMoreStaff = () => {
    console.log("Load more staff");
  };

  const stateData = {
    state: {
      staffMembers: [],
      selectedStaff,
      searchText: "",
      isLoadingStaffList: false,
      actionInProgress: false,
      hasMoreStaff: false,
    },
    otherMethods: {
      isThisUserSelected,
      handleSelectStaff,
      closeStepOneModal,
      handleStepOne,
      disableOtherUsers,
      loadMoreStaff,
    },
  };

  const tabName = {
    teamChat: 1,
    patientChat: 2,
  };

  const handleTabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setCurrentChannel(null);
  };

  const handleToggleChange = (toggle) => {
    setActiveToggle(toggle);
    setCurrentChannel(null);
  };

  const [currentChannel, setCurrentChannel] = useState(null);
  const {
    state: { selectedOwner, isAccessible },
  } = useSelectedAccountOwner({
    localStorageKey: constants.localStorageKeys.selectedChatAccountOwner,
    setCurrentChannel,
    isFromDashBoard: true,
  });

  const { state } = useTeamConversationState({
    selectedOwner,
  });

  useEffect(() => {
    if (modulesAccess?.Subscription?.length) {
      const selectedOwnerSubscription = modulesAccess?.Subscription?.find(
        (subscription) => subscription.ownerId === selectedOwner?.id
      );

      const canAccessModule = selectedOwnerSubscription?.planFeature?.some(
        (plan) =>
          plan.id === constants.moduleNameWithId.scheduler && plan.isAvailable
      );
      if (!canAccessModule) {
        setIsSubscriptionModel(true);
      } else {
        setIsSubscriptionModel(false);
      }
    }
  }, [modulesAccess, selectedOwner]);
  const { profile } = state;
  const isOwner =
    profile?.role?.systemRole === constants.systemRoles.accountOwner;
  const notAccessible = (patientCheck = false) => {
    if (
      isSubscriptionModel ||
      !isAccessible ||
      (!isOwner && patientCheck && !profile?.isPatientChat) ||
      (!isOwner && !patientCheck && !profile?.isMessenger)
    ) {
      return (
        <div className="not-accessible center_property h-100">
          <NotAccessible Message={t("dashBoard.notAccessible")} />
        </div>
      );
    }

    return null;
  };
  const renderChatContent = () => {
    const render = notAccessible();
    if (render) {
      return render;
    }
    return (
      <DashboardCommonChat
        currentUser={profile}
        selectedOwner={selectedOwner}
        currentChannel={currentChannel}
        key={activeToggle}
        setCurrentChannel={setCurrentChannel}
        appliedOfficeFilters={appliedOfficeFilters}
        {...(activeTab === tabName.teamChat && activeToggle === 1
          ? { isInternalChat: true }
          : { externalTabActive: true })}
      />
    );
  };

  const renderPatientChatContent = () => {
    const render = notAccessible(true);
    if (render) {
      return render;
    }
    return (
      <DashboardCommonChat
        currentUser={profile}
        selectedOwner={selectedOwner}
        currentChannel={currentChannel}
        setCurrentChannel={setCurrentChannel}
        setViewAppointmentCred={() => {}}
        appliedOfficeFilters={appliedOfficeFilters}
        isFromPatient={activeTab === tabName.patientChat}
      />
    );
  };

  const handleRedirect = () => {
    if (activeTab === tabName.teamChat) {
      history.push(constants.routes.chatModule);
    } else {
      history.push(constants.routes.patientChatModule);
    }
    setStorage("isChatFromDashBoard", true);
  };

  return (
    <div className={styles.chat_tab_wrapper}>
      <div className={styles.header_wrapper}>
        <Nav tabs className={styles["nav-tabs"]}>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeTab === tabName.teamChat
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => handleTabChange(tabName.teamChat)}
            >
              {t("dashBoard.teamChat")}
            </NavLink>
          </NavItem>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeTab === tabName.patientChat
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => handleTabChange(tabName.patientChat)}
            >
              {t("dashBoard.patientChat")}
            </NavLink>
          </NavItem>
        </Nav>
        {((activeTab === tabName.teamChat && profile?.isMessenger) ||
          (activeTab !== tabName.teamChat && profile?.isPatientChat) ||
          isOwner) && (
          <>
            <div className="pr-3" onClick={handleRedirect}>
              <img src={OpenInIcon} alt="open-icon" id="chat_tooltip" />
            </div>
            <Tooltip
              isOpen={tooltipEditOpen}
              placement="top"
              target="chat_tooltip"
              toggle={() => {
                setTooltipEditOpen(!tooltipEditOpen);
              }}
            >
              {t("dashBoard.gotoMessenger")}
            </Tooltip>
          </>
        )}
      </div>

      {activeTab === tabName.teamChat && (
        <ToggleButton
          activeToggle={activeToggle}
          setActiveToggle={handleToggleChange}
          firstText="INTERNAL"
          secondText="EXTERNAL"
          isFromChat={true}
          isAccessible={profile.isAccountOwner || profile.isAdmin}
        />
      )}

      <TabContent
        activeTab={activeTab}
        className={`${styles.chatTabContent} ${
          activeTab === tabName.patientChat
            ? isAccessible
              ? styles.patientChat
              : styles.patientChat_new
            : isAccessible
            ? styles.otherChat
            : styles.otherChat_new
        }`}
      >
        <Suspense fallback={<Loader />}>
          <TabPane
            tabId={tabName.teamChat}
            className={`${styles.chatPane} ${
              isSubscriptionModel ? styles.subscriptionModel : ""
            } ${!isAccessible ? styles.notAccessible : ""}`}
          >
            {activeTab === tabName.teamChat && (
              <div className={styles.chatPane}>{renderChatContent()}</div>
            )}
          </TabPane>

          <TabPane tabId={tabName.patientChat} className={styles.chatPane}>
            {activeTab === tabName.patientChat && (
              <div
                className={`${!isAccessible ? styles.notAccessible : "h-100"}`}
              >
                {renderPatientChatContent()}
              </div>
            )}
          </TabPane>
        </Suspense>
      </TabContent>

      {isPopupOpen && <NewGroupPopup t={t} stateData={stateData} />}
    </div>
  );
};

export default withTranslation()(Chat);
