import React, { useState } from "react";
import PerformanceReviewCard from "../components/PerformanceReviewCard";
import Sidebar from "../components/Sidebar";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { cacheSideBarActive } from "utils";
import styles from "./../PerformanceReview.module.scss";
import usePerformanceListingModule from "../hooks/usePerformanceListingModule";
import {
  archieveByRespondent,
  archieveByReviewer,
  deleteByRespondent,
  deleteByReviewer,
  unArchieveByRespondent,
  unArchieveByReviewer,
  usePerformanceRespondList,
  usePerformanceReviewList,
} from "repositories/performance-review-repository";
import Loader from "components/Loader";
import Empty from "components/Empty";
import { withTranslation } from "react-i18next";
import "../PerformanceReview.scss";

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

function InitiatedByYou({
  t,
  officeId,
  isArchive,
  isMyPerformanceReview,
  officeData,
}) {
  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());
  const usePerformanceList = !isMyPerformanceReview
    ? usePerformanceReviewList
    : usePerformanceRespondList;
  const usePerformanceArchive = !isMyPerformanceReview
    ? archieveByReviewer
    : archieveByRespondent;
  const usePerformanceUnarchive = !isMyPerformanceReview
    ? unArchieveByReviewer
    : unArchieveByRespondent;
  const usePerformanceDelete = !isMyPerformanceReview
    ? deleteByReviewer
    : deleteByRespondent;

  const {
    data: { totalItems, performanceList, isLoading, dates, isFilterApply },
    methods: {
      setPageNumber,
      updateDatesValues,
      setStatusFilter,
      selectedStatus,
      handleApplyFilters,
      resetFilter,
      handleArchive,
      handleUnArchieve,
      handleDelete,
      handleCancel,
      handleFinalize,
    },
  } = usePerformanceListingModule(
    usePerformanceList,
    officeId,
    isArchive,
    usePerformanceArchive,
    usePerformanceUnarchive,
    usePerformanceDelete
  );

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  let items = (
    <div
      className={
        !isSidebarActive ? styles.flex_container : styles.flex_container_active
      }
    >
      {performanceList.length > 0 &&
        performanceList.map((item, index) => (
          <PerformanceReviewCard
            className={styles.employement_proof_card}
            t={t}
            key={index}
            review={item}
            isArchive={isArchive}
            handleArchive={handleArchive}
            handleUnArchieve={handleUnArchieve}
            handleDelete={handleDelete}
            isLoading={isLoading}
            handleCancel={handleCancel}
            isMyPerformanceReview={isMyPerformanceReview}
            handleFinalize={handleFinalize}
            officeId={officeId}
            officeData={officeData}
          />
        ))}
    </div>
  );

  return (
    <div className="scheduler-tabs-main-wrapper w-100">
      {isLoading && <Loader />}
      <Sidebar
        isSidebarActive={isSidebarActive}
        handleSidebarToggle={handleSidebarToggle}
        handleApplyFilters={handleApplyFilters}
        resetFilter={resetFilter}
        dates={dates}
        updateDatesValues={updateDatesValues}
        setStatusFilter={setStatusFilter}
        selectedStatus={selectedStatus}
        isFilterApply={isFilterApply}
        t={t}
      />
      {performanceList.length === 0 ? (
        <div className="div_center">
          <Empty
            Message={t("performanceReview.noPerformanceReviewAvailable")}
          />
        </div>
      ) : (
        <motion.div
          variants={animationVariants}
          initial="hidden"
          animate="show"
          className="w-100"
        >
          <InfiniteScroll
            className="w-100"
            dataLength={performanceList.length}
            hasMore={performanceList.length < totalItems}
            next={() => setPageNumber((v) => v + 1)}
          >
            {items}
          </InfiniteScroll>
        </motion.div>
      )}
    </div>
  );
}

export default withTranslation()(InitiatedByYou);
