import React, { Fragment, useEffect, useState } from 'react'
import Text from 'components/Text'
import styles from "../PatientMessageCenter.module.scss"
import EditIcon from "../../../../../assets/images/more_option_dot_copy.svg"
import PreviewModal from "pages/AccountPreferences/modals/PreviewModal";

let scheme = {
    templateName: "",
    notificationTitle: "",
    notificationContent: "",
    emailContent: "",
    attachmentLink: "",
};

export default function MessageDetails({ t, messageDetail, handlePreview, contentForPreview , appliedMessageTemplate}) {
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isPreviewForEmailContent, setIsPreviewForEmailContent] =
        useState(false);
      const [formFields, setformFields] = useState(scheme);
      const { notificationTitle, notificationContent, emailContent } =
    formFields;

    const handleClick = (e, messageDetails) => {
        e.stopPropagation();
        setIsPreviewModalOpen(true)
        if (messageDetails?.id == 3) {
            setIsPreviewForEmailContent(true);
            handlePreview(
                emailContent,
                t("accountPreferences.previewForEmail")
            );
        }
        else if (messageDetails?.id == 2) {
            handlePreview(
                notificationContent,
                t("accountPreferences.previewForNotification")
            );
        } else {
            handlePreview(
                notificationTitle,
                t("accountPreferences.previewTitleSubject")
            );
        }
    }

    useEffect(() => {
        setformFields(appliedMessageTemplate);
    }, [appliedMessageTemplate])

    return (
        <Fragment>
            <div className={styles.message_details_wrapper}>
                <Text size="13px" weight="400" color="#79869A">{messageDetail?.title}</Text>
                <div className={styles.edit_preview_wrapper}>
                    <img src={EditIcon} alt='EditIcon' />
                    <button className={styles.link + " " + "link-btn"}
                        onClick={(e) => handleClick(e, messageDetail)}
                    >
                        Preview
                    </button>
                </div>
                <Text size="14px" weight="600" color="#102C42">{messageDetail?.message}</Text>
            </div>
            {isPreviewModalOpen && (
                <PreviewModal
                    showPreview={isPreviewModalOpen}
                    setShowPreview={setIsPreviewModalOpen}
                    title={t("accountPreferences.previewModalTitle", {
                        previewFor: contentForPreview.title,
                    })}
                    messageTitle={notificationTitle}
                    content={contentForPreview.content}
                    isPreviewForEmailContent={isPreviewForEmailContent}
                    setIsPreviewForEmailContent={setIsPreviewForEmailContent}
                    btnText={t("accountPreferences.done")}
                />

            )}
        </Fragment>
    )
}
