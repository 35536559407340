import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { uniqBy } from 'lodash';
import { archieveStaffTasks, getAssigneeStaff, taskAssignmentAction } from 'repositories/task-management-repository';
import constants from "../../../../constants";
import toast from 'react-hot-toast';
import { getStorage, setStorage } from 'utils';

const pageSize = 10;
const useStaffTaskModule = (useAllTasks, isArchive = false) => {
  const cacheFilters = getStorage(constants.tasks.cache.staffTaskFilter);
  const isFilterApply = cacheFilters?.offices?.length > 0 || cacheFilters?.staff?.length > 0 || cacheFilters?.status?.length > 0 || cacheFilters?.priority?.length > 0;
  const [showLoader, setShowLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(cacheFilters?.offices || []);
  const [appliedAssignorFilter, setAppliedAssignorFilter] = useState(cacheFilters?.staff || []);
  const [appliedStatusFilters, setAppliedStatusFilters] = useState(cacheFilters?.status || []);
  const [appliedPriorityFilters, setAppliedPriorityFilters] = useState(cacheFilters?.status || []);
  const [searchValue, setSearchValue] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isAssigneeModal, setIsAssigneeModal] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const [officeFilter, setOfficeFilter] = useState(cacheFilters?.offices || []);
  const [staffFilter, setStaffFilter] = useState(cacheFilters?.staff || []);
  const [statusFilter, setStatusFilter] = useState(cacheFilters?.status || []);
  const [priorityFilter, setPriorityFilter] = useState(cacheFilters?.status || []);
  const [priority, setPriority] = useState({ id: 4, name: "All" });
  const [status, setStatus] = useState({ value: "", label: "All" });
  const [sortBy, setSortBy] = useState(2);
  const queryClient = useQueryClient();

  const { isLoading, data, refetch } = useAllTasks(
    pageNumber,
    pageSize,
    appliedOfficeFilters,
    appliedAssignorFilter,
    searchValue,
    isArchive,
    appliedPriorityFilters,
    appliedStatusFilters,
    sortBy
  );

  useEffect(() => {
    if (pageNumber === 1) {
      if (!isLoading && data && data.items) {
        setTasks(uniqBy([...data?.items], "id"));
        setTotalItems(data?.pagination?.totalItems);
        setTotalPages(data?.pagination?.totalPages);
      }
    }
    else if (!isLoading && data && data.items) {
      setTasks(prev => uniqBy([...prev, ...data?.items], "id"));
      setTotalItems(data?.pagination?.totalItems);
      setTotalPages(data?.pagination?.totalPages);
    }
  }, [isLoading, data]);

  useEffect(() => {
    setPageNumber(1);
    if (priority.id) {
      if (priority.id < 4) {
        setAppliedPriorityFilters([priority.id]);
        setPriorityFilter([priority.id]);
      } else {
        setAppliedPriorityFilters([]);
        setPriorityFilter([]);
      }
    }
    if (status.value) {
      setAppliedStatusFilters([status.value]);
      setStatusFilter([status.value]);
    } else {
      setAppliedStatusFilters([]);
      setStatusFilter([]);
    }
    queryClient.invalidateQueries(["/task/mytask", pageNumber, pageSize, appliedOfficeFilters, appliedAssignorFilter,
      searchValue, isArchive, appliedPriorityFilters, appliedStatusFilters, sortBy]);
    refetch();
  }, [isArchive, refetch, sortBy, priority, status])

  const handleSearchTerm = (e) => {
    setPageNumber(1);
    setSearchValue(e.target.value);
  };

  const StaffMembers = async (taskId) => {
    setShowLoader(true);
    try {
      const res = await getAssigneeStaff(taskId);
      if (res && res.length) {
        setAssigneeList(res);
        setShowLoader(false);
      }
    } catch (e) {
      setShowLoader(false);
    }
  };

  const handleToggleAssigne = (taskId = "") => {
    if (isAssigneeModal) {
      setIsAssigneeModal(false)
      setAssigneeList([]);
    } else {
      StaffMembers(taskId)
      setIsAssigneeModal(true)
    }
  }

  const resetFilter = () => {
    setOfficeFilter([]);
    setStaffFilter([]);
    setStatusFilter([]);
    setPriorityFilter([]);
    setAppliedOfficeFilters([]);
    setAppliedAssignorFilter([]);
    setAppliedStatusFilters([]);
    setAppliedPriorityFilters([]);
    setPriority({ id: 4, name: "All" });
    setStatus({ value: "", label: "All" });
    setStorage(constants.tasks.cache.staffTaskFilter, {
      offices: [],
      staff: [],
      status: [],
      priority: []
    });
    setPageNumber(1);
  };

  const handleApplyFilters = () => {
    setAppliedOfficeFilters(officeFilter);
    setAppliedAssignorFilter(staffFilter);
    setAppliedStatusFilters(statusFilter);
    setAppliedPriorityFilters(priorityFilter);
    setStorage(constants.tasks.cache.staffTaskFilter, {
      offices: officeFilter,
      staff: staffFilter,
      status: statusFilter,
      priority: priorityFilter
    });
    setPageNumber(1);
  };

  const handleActiontask = async (taskId, TaskAction, param) => {
    try {
      const res = await taskAssignmentAction(taskId, TaskAction, param);
      if (res?.status) {
        setShowLoader(false);
        setPageNumber(1);
        toast.success(res.message);
        if (TaskAction === "Accept") {
          setTasks(prevTasks => {
            return prevTasks.map(task =>
              task.id === taskId ? { ...task, status: 3 } : task
            );
          });
        } else if (TaskAction === "Start") {
          setTasks(prevTasks => {
            return prevTasks.map(task =>
              task.id === taskId ? { ...task, status: 4 } : task
            );
          });
        } else if (TaskAction === "Reject") {
          setTasks(prevTasks => {
            return prevTasks.map(task =>
              task.id === taskId ? { ...task, status: 6 } : task
            );
          });
        } else {
          setTasks(prevTasks => {
            return prevTasks.map(task =>
              task.id === taskId ? { ...task, status: 5 } : task
            );
          });
        }
        return res;
      }
    } catch (e) {
      toast.error(e.message);
      return { status: false, message: e.message };
    }
  }

  const handleArchiveApi = async (taskAssignmentId, action) => {
    try {
      const res = await archieveStaffTasks(taskAssignmentId, action);
      if (res?.status) {
        toast.success(res.message);
        setTasks(prevTask => {
          const updatetask = prevTask?.filter(task => task?.id !== taskAssignmentId)
          return updatetask
        });
      }
    } catch (e) {
      setShowLoader(false);
    }
  };


  const isAnyLoading = isLoading || showLoader
  return {
    isLoading: isAnyLoading,
    tasks,
    totalItems,
    searchValue,
    isAssigneeModal,
    assigneeList,
    showLoader,
    officeFilter,
    staffFilter,
    statusFilter,
    priorityFilter,
    isFilterApply,
    sortBy,
    priority,
    status,
    pageNumber,
    totalPages,
    setStatus,
    setPriority,
    setTasks,
    setPageNumber,
    handleSearchTerm,
    handleToggleAssigne,
    handleActiontask,
    setAppliedOfficeFilters,
    setAppliedAssignorFilter,
    setAppliedStatusFilters,
    setAppliedPriorityFilters,
    resetFilter,
    setOfficeFilter,
    setStaffFilter,
    setStatusFilter,
    setPriorityFilter,
    handleApplyFilters,
    resetFilter,
    handleArchiveApi,
    setSortBy
  };
};

export default useStaffTaskModule;