import React, { useContext, useEffect, useState } from 'react'
import { Card } from "reactstrap";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import CustomDropdown from 'components/Dropdown';
import Input from 'components/Input';
import Text from 'components/Text';
import "../Appointments.scss"
import SchedulerCalendar from 'accountOwner/pages/Scheduler/components/SchedulerCalendar';
import BookTimeslot from 'accountOwner/pages/Appointments/components/BookTimeslot';
import styles from "../Appointments.module.scss"
import useCreatePatientAppointmentModule from '../hooks/useCreatePatientAppointmentModule';
import moment from 'moment';
import { useSelector } from 'react-redux';
import constants from "../../../../constants";
import { encodeId, setStorage } from 'utils';
import { AppointmentsContext } from '../AppointmentsContext';
import appointmentStyles from "../Appointments.module.scss";
import Loader from 'components/Loader';
import OperatoryModal from '../modal/OperatoryModal';
import ToggleSwitch from 'components/ToggleSwitch';

const KEY_NAME = 'add-new-appointment';
function PatientNewAppointment({ t, history }) {
    const profile = useSelector(state => state.userProfile.profile);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [OfficeError ,setOfficeError] = useState("");
    const {
        data: {
            DoctorOfficeData,
            repeatOptions,
            isLoading,
            searchTerm,
            patientData,
            OfficeData,
            errors,
            selectedOfficeData
        },
        methods: {
            goToPreviousScreen,
            handleSubmit,
            setSearchterm,
            setSelectedOfficeData
        }
    } = useCreatePatientAppointmentModule(KEY_NAME);

    const {
        setSelectedOffice,
        selectedOffice,
        selectedAppointmentType,
        selectedSlotDate,
        reminderPlan,
        appointmentSlots,
        setSlotDate,
        setAppointmentSlots,
        setCreateDescription,
        createDescription,
        selectedPatient,
        setSelectedPatient,
        email,
        setEmail,
        options,
        selectedOperatory,
        setselectedOperatory,
        appointmentAccepted,
        setappointmentAccepted,
    } = useContext(AppointmentsContext);

    const [selectedOperatoryRadio, setselectedOperatoryRadio] = useState(selectedOperatory);

    const selectedDate = moment(selectedSlotDate).startOf('day');

    const onActiveStartDateChange = (value) => {
        if (constants.calanderActions.includes(value?.action) || (value?.action === constants.calanderActionKey.drillDown && (value?.view === 'month' || value?.view === 'year'))) {
            value?.activeStartDate && setSlotDate(value.activeStartDate);
        }
    }

    const redirectAppointmentType = (e) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        if(selectedOffice !== 1) {
            setOfficeError("");
            history.push({ pathname: constants.routes.appointment.appointmentType.replace(':officeId', encodeId(selectedOffice)).replace(':requestId', encodeId(selectedOffice)), state: { isFromCreatePage: true } });
        } else {
            setOfficeError(t("patientAppointmentMembers.selectOfficeError"));
        }
    };

    const redirectSelectReminder = (e) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        if(selectedOffice !== 1) {
          setOfficeError("");
          history.push({ pathname: constants.routes.appointment.appointmentReminder.replace(':officeId', encodeId(selectedOffice)).replace(':requestId', encodeId(selectedOffice)), state: { isFromCreatePage: true } });
        } else {
            setOfficeError(t("patientAppointmentMembers.selectOfficeError"));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const handleSelectOperatory = () => {
        setselectedOperatory(selectedOperatoryRadio);
        closeModal()
    }

    useEffect(() => {
        setStorage('createPage', {
            isFromCreatePage: true,
        });
        if (OfficeData?.id) {
            setSelectedOffice(OfficeData?.id)
        }
    }, [OfficeData])

    useEffect(() => {
        if(DoctorOfficeData?.items?.length) {
        let SelectedData = DoctorOfficeData?.items?.find(office => office?.id === selectedOffice);
        setSelectedOfficeData(SelectedData)
        }
    }, [selectedOffice, DoctorOfficeData])

    const OperatoryCardData = ({ typeOfAppointment, selectType, data, onClick }) => (
        <div className={styles['heading-data-Wrapper']}>
            <div className={styles['head-link-wrapper']}>
                <div className={styles['appointment-head']}>{typeOfAppointment}</div>
                <div  onClick={onClick} className={styles["appointment-link"]}>{selectType}</div>
            </div>
            <div className={styles['appointment-text']}> {data?.appointments_operatory || ""}</div>
        </div>
    )

    const handleReAssignSubmit = async (e) => {
        const startTimes = appointmentSlots?.filter(app => app.startTime)?.map(app => app.startTime);

        const params = {
            doctorId: profile?.id,
            officeId: selectedOffice,
            patientId: selectedPatient?.id,
            date: moment(selectedSlotDate).format("YYYY-MM-DD"),
            reminderDate: moment.utc(selectedSlotDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            slots: startTimes,
            Description: createDescription,
            appointmentReminderPlanId: reminderPlan?.[KEY_NAME]?.id,
            appointmentTypeId: selectedAppointmentType?.[KEY_NAME]?.appointmentTypeId,
            createdFromRecallId: null,
            recallDate: null,
            appointmentRequestId: null
        };

        if (selectedOfficeData?.isConnectedWithSikka) {
            params.MarkAppointmentStatusAsAccepted = appointmentAccepted;
            params.Operatory = selectedOperatory?.appointments_operatory || null;
        }

        await handleSubmit(e, params);
    }

    return (
        <Page onBack={goToPreviousScreen} className={styles["main-page"]}>
            {isLoading && <Loader />}
            <p className={styles["mobile-text-size"]}>{t("New Appointment")}</p>
            <Card className="form-wrapper">
                <div className={styles['content-part']}>
                    <div className={styles['patient-detail-box'] + " " + styles["create-new-appointment-left-part"]}>
                        <div className="c-field">
                            <label>{t("Office")}</label>
                            <div className="custom-dropdown-only">
                                <CustomDropdown
                                    options={repeatOptions || []}
                                    selectedOption={selectedOffice}
                                    selectOption={(id) => {
                                        setSelectedOffice(id);
                                        setOfficeError("");
                                    }}
                                    defaultValue={OfficeData?.name || "Select an Office"}
                                    disabled={OfficeData?.name}
                                />
                                {(errors?.OfficeError || OfficeError) && <span className="error-msg mt-1">{errors?.OfficeError || OfficeError}</span>}
                            </div>
                        </div>
                        <div className={styles['new-appointment-input-box']}>
                            <Input
                                Title='Patient Full Name'
                                Type="text"
                                Placeholder={t('form.placeholder1', { field: t('contracts.businessLegalName') })}
                                Name={"BusinessLegalName"}
                                Value={searchTerm || selectedPatient?.name}
                                HandleChange={(e) => {
                                    setSearchterm(e.target.value);
                                    setSelectedPatient(null);
                                }}
                            />
                            <div className={styles.suggestionList}>
                                {searchTerm && patientData?.items?.length > 0 && (
                                    <ul className={styles['suggestion-list']}>
                                        {patientData?.items?.map((patient) => {
                                            const fullName = `${patient?.firstName} ${patient?.lastName}`;
                                            return (
                                                <li key={patient.id}
                                                    onClick={() => { setSelectedPatient({ name: fullName, id: patient?.id, email: patient?.emailId }); setSearchterm("") }}
                                                    className={styles['suggestion-item']}
                                                >
                                                    <span className={styles['suggestion-name']}>{fullName}</span>
                                                    <span className={styles['suggestion-email']}>{patient.emailId}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </div>
                            {errors?.patientError && <span className="error-msg mt-1">{errors?.patientError}</span>}
                        </div>
                        {/* if patient name did not found in system then show this input block  */}
                        {(!patientData?.items?.length) && <div className={styles["new-appointment-input-box"] + " " + "c-field"}>
                            <div className="custom-dropdown-only mb-2">
                                <Input
                                    Title='Patient Email Address'
                                    Type="text"
                                    Placeholder={t('form.placeholder1', { field: t('contracts.businessLegalName') })}
                                    Name={"email"}
                                    Value={email}
                                    HandleChange={(e) => setEmail(e.target.value)}
                                />

                            </div>
                            <Text color='#EE4F4F' weight='500' size='11px' >{t("patientAppointmentMembers.errorMsg")}</Text>
                        </div>}
                        <div className={styles["new-appointment-input-box"] + " " + "c-field"}>
                            <label>{t("patientAppointmentMembers.description")}</label>
                            <textarea
                                className="c-form-control"
                                placeholder='Enter description here'
                                name="title"
                                maxLength="800"
                                value={createDescription}
                                onChange={(e) => setCreateDescription(e.target.value)}
                            ></textarea>
                            {errors?.desCriptionError && <span className="error-msg mt-1">{errors?.desCriptionError}</span>}
                        </div>

                        <div className={appointmentStyles['heading-data-Wrapper']}>
                            <div className={appointmentStyles['head-link-wrapper']}>
                                <div className={appointmentStyles['appointment-head']}>{t('waitingList.typeOfAppointment')}</div>
                                <div className={appointmentStyles["appointment-link"]} onClick={redirectAppointmentType}>
                                    <Text color='#587E85' size='12px' weight='500'>{t("patientAppointmentMembers.selectType")}</Text>
                                </div>
                            </div>
                            <Text size="14px" weight="600" color="#102C42" marginTop="6px">{selectedAppointmentType?.[KEY_NAME]?.title || ''}</Text>

                        </div>

                        {selectedOfficeData?.isConnectedWithSikka && <OperatoryCardData
                            typeOfAppointment="Operatory*"
                            selectType="Select Operatory"
                            data={selectedOperatory}
                            onClick={() => setIsModalOpen(!isModalOpen)}
                        />}
                        {errors?.appointmentTypeError && <span className="error-msg mt-1">{errors?.appointmentTypeErro}</span>}
                        <div className={appointmentStyles['heading-data-Wrapper']}>
                            <div className={appointmentStyles['head-link-wrapper']}>
                                <div className={appointmentStyles['appointment-head']}>{t('waitingList.ReminderPlan')}</div>
                                <div className={appointmentStyles["appointment-link"]} onClick={redirectSelectReminder}>
                                    <Text color='#587E85' size='12px' weight='500'>{t("patientAppointmentMembers.selectReminderPlan")}</Text>
                                </div>
                            </div>
                            {reminderPlan?.[KEY_NAME] && <div className={appointmentStyles['appointment-modal-card']}>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{reminderPlan?.[KEY_NAME]?.title || ''}</Text>
                                {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.map(plan =>
                                    <div className='mt-2' key={plan?.id}>
                                        <Text size="14px" weight="600" color="#102C42" >{plan?.reminderBefore + ' ' +
                                            options.find(
                                                m => m.id == plan?.reminderBeforeType,
                                            )?.name +
                                            ' ' +
                                            t("patientAppointmentMembers.before")}
                                        </Text>
                                        <Text size="14px" weight="600" color="#102C42" >
                                            {[plan.isEmail && t("patientAppointmentMembers.isEmail"), plan.isSms && t("patientAppointmentMembers.isSms"), plan.isPushNotification && t("patientAppointmentMembers.isPushNotification")]
                                                .filter(Boolean)
                                                .join(', ')}
                                        </Text>
                                    </div>
                                )}
                            </div>}
                        </div>
                        {selectedOfficeData?.isConnectedWithSikka && <div className="d-flex align-items-center mt-4">
                            <ToggleSwitch
                                className="mt-0"
                                label="bill-me-later-toggle"
                                value={appointmentAccepted}
                                onChange={(e) => setappointmentAccepted(e.target.checked)}
                            />
                            <Text
                                size="13px"
                                marginBottom="0px"
                                weight="400"
                                className="mr-2 mt-2"
                                color="#79869A"
                            >
                                {t("patientAppointmentMembers.appointmentAsAccepted")}
                            </Text>
                        </div>}
                    </div>
                    <div className='w-100'>
                        <div className={styles['book-slot'] + " " + 'd-flex flex-column'}>
                            <div className='schedular-calendar-box d-flex justify-content-center'>
                                <SchedulerCalendar value={selectedSlotDate} onChange={setSlotDate} onActiveStartDateChange={onActiveStartDateChange} />
                            </div>
                            <div>
                                <Text size="12px" weight="400" color="#6F7788">{t("patientAppointmentMembers.reassignTimezone")}</Text>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px">{t("patientAppointmentMembers.reassignPst")}</Text>
                            </div>
                            <div className={styles['appointment-calendar-timezone-separator']}></div>
                            <div className={styles["book-slot-new-appointment"]}>
                                <div>
                                    <Text color='#587E85' weight='600' size='16px' className={styles['appointment-bookslot-heading']}>{t("patientAppointmentMembers.reassignBookingTimeslot")}</Text>
                                </div>
                                <div className={styles['notify-checkbox'] + " " + " d-flex "}>
                                    <div className={styles['checkbox-container'] + " " + " d-flex "}>
                                        <div className={styles['show-checkedbox'] + " " + styles["box1"]}></div>
                                        {t("patientAppointmentMembers.reassignBooked")}
                                    </div>
                                    <div className={styles['checkbox-container'] + " " + "d-flex "}>
                                        <div className={styles['show-checkedbox'] + " " + styles['box2']}></div>
                                        {t("patientAppointmentMembers.reassignAvailable")}
                                    </div>
                                    <div className={styles['checkbox-container'] + " " + "d-flex "}>
                                        <div className={styles['show-checkedbox'] + " " + styles["box3"]}></div>
                                        {t("patientAppointmentMembers.reassignBlocked")}
                                    </div>
                                </div>
                            </div>
                            <BookTimeslot
                                t={t}
                                officeId={selectedOffice}
                                date={moment(selectedDate).format('YYYY-MM-DD')}
                                doctorId={profile?.id}
                                setSelectedSlots={setAppointmentSlots}
                                selectedSlots={appointmentSlots}
                                errors={errors}
                            />
                        </div>
                        <div className={styles['common-appointment-btn-container']}>
                            <button className='button button-round button-shadow mr-md-4 mb-3 w-sm-100' onClick={handleReAssignSubmit}>
                                {t("patientAppointmentMembers.reassigncreateAppointment")}
                            </button>
                            <button className='button button-round button-border button-dark btn-mobile-link ' onClick={goToPreviousScreen} >
                                {t("patientAppointmentMembers.reassignCancelBtn")}
                            </button>

                        </div>
                    </div>
                </div>
            </Card>
            {isModalOpen && (
                <OperatoryModal
                    t={t}
                    closeDeleteModal={closeModal}
                    isDeleteModalOpen={isModalOpen}
                    officeId={selectedOfficeData?.id}
                    setselectedOperatoryRadio={setselectedOperatoryRadio}
                    selectedOperatoryRadio={selectedOperatoryRadio}
                    handleSelectOperatory={handleSelectOperatory}
                />
            )}
        </Page>
    )
}
export default withTranslation()(PatientNewAppointment);