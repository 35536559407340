import Page from 'components/Page';
import React from 'react';
import DashboardCard from './components/DashboardCard';
import Text from 'components/Text';
import upcomingIcon from '../../../assets/images/ico_upcoming.svg';
import confirmedIcon from "../../../assets/images/confirmed_appointments.svg"
import unconfirmedIcon from "../../../assets/images/unconfirmed_icon.svg"
import totalCompletedAppointmentsIcon from "../../../assets/images/ico_total_completed.svg"
import upcomingRecallsIcon from "../../../assets/images/ico_upcoming_recalls.svg"
import overdueRecallsIcon from "../../../assets/images/ico_overdue_recalls.svg"
import convertedRecallsIcon from "../../../assets/images/ico_unbooked_recalls.svg"
import newPatientIcon from "../../../assets/images/ico_patient.svg"
import styles from './AppointmentDashboard.module.scss';
import useDashBoardModule from './hooks/useDashBoardModule';
import { useDashboard } from 'repositories/patient-appointment-repository';
import Loader from 'components/Loader';
import { withTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import useReadOnlyDateTextInput from 'hooks/useReadOnlyDateTextInput';
import { Portal } from 'react-overlays';

function AppointmentDashboard({ t, history }) {

    const {
        data: {
            isLoading,
            dashBoardData,
            dates,
            officeDetail
        },
        methods: {
            onBack,
            handleRedirect,
            handleRedirecttoPaitents,
            updateDatesValues
        }
    } = useDashBoardModule(useDashboard, history);


    const dashboardData = [
        { id: 1, onClick: handleRedirecttoPaitents, icon: newPatientIcon, title: t("patientAppointmentMembers.newPatient"), numberOfAppointments: dashBoardData?.numberOfNewPatients },
        { id: 1, onClick: () => { }, icon: newPatientIcon, title: t("patientAppointmentMembers.pmsNewPaitensMtd"), numberOfAppointments: dashBoardData?.newPatientsMtd || 0 },
        { id: 1, onClick: () => { }, icon: newPatientIcon, title: t("patientAppointmentMembers.pmsNewPaitensYtd"), numberOfAppointments: dashBoardData?.newPatientsYtd || 0 },
    ];

    const dashboardAppointmentData = [
        { id: 1, onClick: () => handleRedirect(1, []), icon: upcomingIcon, title: t("patientAppointmentMembers.upComingAppointments"), numberOfAppointments: dashBoardData?.numberOfUpcommingAppointments },
        { id: 2, onClick: () => handleRedirect(1, [7]), icon: confirmedIcon, title: t("patientAppointmentMembers.upComingConfirmedAppointments"), numberOfAppointments: dashBoardData?.numberOfConfirmedAppointments },
        { id: 3, onClick: () => handleRedirect(1, [1, 2]), icon: unconfirmedIcon, title: t("patientAppointmentMembers.upComingUnConfirmedAppointments"), numberOfAppointments: dashBoardData?.numberOfUnconfirmedAppointments },
        { id: 4, onClick: () => handleRedirect(3, [5, 6]), icon: totalCompletedAppointmentsIcon, title: t("patientAppointmentMembers.upComingCompletedAppointments"), numberOfAppointments: dashBoardData?.numberOfCompletedAppointments },
    ];

    const dashboardRecallData = [
        { id: 1, onClick: () => handleRedirect(2.[9]), icon: upcomingRecallsIcon, title: t("patientAppointmentMembers.upComingRecallAppointments"), numberOfAppointments: dashBoardData?.numberOfUpcommingRecalls },
        { id: 2, onClick: () => handleRedirect(2, [8]), icon: overdueRecallsIcon, title: t("patientAppointmentMembers.overDueRecallAppointments"), numberOfAppointments: dashBoardData?.numberOfOverdueRecalls },
        {
            id: 3, onClick: () => handleRedirect(2, [10]), icon: convertedRecallsIcon, title: t("patientAppointmentMembers.convertedeRecallAppointments"), numberOfAppointments: dashBoardData?.numberOfConvertedRecalls
        },
    ];

    const startDatePickerRef = useReadOnlyDateTextInput();

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };

    return (
        <Page onBack={onBack}>
            {isLoading && <Loader />}
            <div className={styles.dashboard_main_wrapper}>
                <div className={styles.title_date_wrapper}>
                    <div>
                        <h2 className="page-title m-0 mb-2">{officeDetail?.name}</h2>
                        <h3 className={styles.sub_title}>{t("patientAppointmentMembers.appointmentDashboard")}</h3>
                    </div>
                    <div className={styles["date_wrapper"] + " " + "c-field mb-0"}>
                        <div className="inputdate w-max-50">
                            <Text size="13px" weight="400" color="#79869A">
                                {t("taskManagement.from")}
                            </Text>
                            <ReactDatePicker
                                dateFormat="dd-MM-yyyy"
                                className="c-form-control"
                                selected={dates?.from ? dates.from : null}
                                onChange={(e) => updateDatesValues(e, "from")}
                                maxDate={dates.to ? dates.to : null}
                                popperPlacement="bottom-start"
                                popperContainer={CalendarContainer}
                                ref={startDatePickerRef}
                                openToDate={moment().startOf('year').toDate()}
                            />
                        </div>
                        <div className="inputdate w-max-50">
                            <Text size="13px" weight="400" color="#79869A">
                                {t("taskManagement.to")}
                            </Text>
                            <ReactDatePicker
                                dateFormat="dd-MM-yyyy"
                                className="c-form-control"
                                selected={dates?.to || null}
                                minDate={dates.from || null}
                                onChange={(e) => updateDatesValues(e, "to")}
                                popperPlacement="bottom-start"
                                popperContainer={CalendarContainer}
                                ref={startDatePickerRef}
                                openToDate={moment().toDate()}
                            />
                        </div>
                    </div>
                </div>
                {/* Appointments Dashboard UI */}
                <div className={styles.appointment_section_wrapper}>
                    <div className={styles.dashboard_grid_container}>
                        {dashboardData.map((item) => (
                            <DashboardCard
                                key={item.id}
                                onclick={item.onClick}
                                icon={item.icon}
                                title={item.title}
                                numberOfAppointments={item.numberOfAppointments}
                            />
                        ))}
                    </div>
                </div>
                {/* Appointments UI */}
                <div className={styles.appointment_section_wrapper}>
                    <Text size="16px" weight="600" color="#111B45">Appointments</Text>
                    <div className={styles.dashboard_grid_container}>
                        {dashboardAppointmentData.map((item) => (
                            <DashboardCard
                                key={item.id}
                                onclick={item.onClick}
                                icon={item.icon}
                                title={item.title}
                                numberOfAppointments={item.numberOfAppointments}
                            />
                        ))}
                    </div>
                </div>
                {/* Recall UI */}
                <div className={styles.appointment_section_wrapper}>
                    <Text size="16px" weight="600" color="#111B45">Recall</Text>
                    <div className={styles.dashboard_grid_container}>
                        {dashboardRecallData.map((item) => (
                            <DashboardCard
                                key={item.id}
                                onclick={item.onClick}
                                icon={item.icon}
                                title={item.title}
                                numberOfAppointments={item.numberOfAppointments}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default withTranslation()(AppointmentDashboard);
