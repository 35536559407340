import React, { Fragment, useContext, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Card } from 'reactstrap';
import deleteImg from "../../../../assets/images/Combined Shape.svg";
import plusImg from "../../../../assets/images/Group 6.svg"
import Input from 'components/Input';
import Page from 'components/Page';
import constants from '../../../../constants';
import { addAppointmentType, deleteAppointmentType, useAppointmentType } from 'repositories/patient-appointment-repository';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { decodeId, getStorage, handleSuccess } from 'utils';
import Empty from 'components/Empty';
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";
import { uniqBy } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { AppointmentsContext } from '../AppointmentsContext';
import styles from "../Appointments.module.scss"
import ConfirmationModal from '../modal/ConfirmationModal';

const pageSize = 10;

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const KEY_NAME_ONE = 'waiting-reminder';
const KEY_NAME_TWO = 'acceptance-reminder';
const KEY_NAME_THREE = 'complete-reminder';
const KEY_NAME_FOUR = 'add-new-appointment';

function TypeOfAppointment({ t, history }) {
    let { officeId, requestId } = useParams();
    const decodeOfficeId = parseInt(decodeId(officeId));
    const [isAddType, setIsAddType] = useState(false);
    const [type, setType] = useState('');
    const [appointmentTypeList, setAppointmenTypetList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const queryClient = useQueryClient();
    const [selectType, setSelectedType] = useState(null);
    const [deleteTypeModal, setDeleteTypeModal] = useState(null);
    const location = useLocation();
    const createRecall = getStorage("createRecall");
    const { state } = location;
    const KEY_NAME = (() => {
        if (state?.isFromGloabList) return KEY_NAME_TWO;
        if (state?.isFromCompletePage) return KEY_NAME_THREE;
        if (state?.isFromCreatePage) return KEY_NAME_FOUR;
        return KEY_NAME_ONE;
    })()

    const {
        setSelectAppointmentType,
        selectedAppointmentType
    } = useContext(AppointmentsContext);

    const { data, isLoading, refetch } = useAppointmentType(pageNumber, pageSize, decodeOfficeId);

    const { mutate: addAppointmentTypeMutate, isLoading: addAppointmentTypeLoading } = useMutation((payload) => addAppointmentType(payload));

    const { mutate: deleteAppointmentTypeMutate, isLoading: deleteAppointmentTypeLoading } = useMutation((appointmentTypeId) => deleteAppointmentType(appointmentTypeId));

    useEffect(() => {
        if (!isLoading && data && data.items) {
            setAppointmenTypetList(prev => uniqBy([...prev, ...data.items], "id"));
            setTotalItems(data.pagination.totalItems);
        }
        if(selectedAppointmentType?.[KEY_NAME]) {
            setSelectedType(selectedAppointmentType?.[KEY_NAME])
        }
    }, [isLoading, data]);

    const closeDeleteModal = () => setDeleteTypeModal(null);


    const handleAddType = async () => {
        const payload = {
            title: type,
            officeId: decodeOfficeId
        }
        addAppointmentTypeMutate(payload, {
            onSuccess: (res) => {
                setPageNumber(1);
                queryClient.invalidateQueries(["/appointment-type/list", pageNumber, pageSize, decodeOfficeId]);
                setType('');
                setIsAddType(false);
                handleSuccess(res.message);
                refetch();
            }
        })
    };

    const handleSave = () => {
        setSelectAppointmentType(prev  => ({
            ...prev,
            [KEY_NAME]: selectType
        }));
        goBack();
    }

    const handleDeleteType = async (typeId) => {
        deleteAppointmentTypeMutate(typeId, {
            onSuccess: (res) => {
                setAppointmenTypetList((prevTypes) => prevTypes.filter(type => type.id !== typeId));
                setDeleteTypeModal(null);
                handleSuccess(res.message);
                refetch();
            }
        })
    }

    const goBack = () => {
        if (createRecall?.createRecall) {
            history.push(constants.routes.appointment.createRecallPatientAppointment.replace(':appointmentId', requestId))
        }
        else if (state?.isFromGloabList) {
            history.push(constants.routes.appointment.acceptPatientAppointment.replace(':appointmentId', requestId))
        }
        else if (state?.isFromCompletePage) {
            history.push(constants.routes.appointment.feedbackConfirmation.replace(':officeId', officeId).replace(':appointmentId', requestId))
        }
        else if (state?.isFromCreatePage) {
            history.push(constants.routes.appointment.patientNewAppointment.replace( ":officeId?", officeId ? officeId : ""))
        }
        else {
            history.push({pathname: constants.routes.appointment.createAppointment.replace(':officeId', officeId).replace(':requestId', requestId), state: state})
        }
    }

    const items = <Fragment>
        {appointmentTypeList?.length > 0 && appointmentTypeList?.map((type, index) =>
            <div className={`d-flex justify-content-between ${(index !== appointmentTypeList.length - 1) && styles["HygieneClass"]}`} key={type?.id}>
                <div className="ch-radio">
                    <label className="mr-5">
                        <input type="radio" name="blockTimeslotAallDoctors" checked={selectType?.appointmentTypeId === type?.id} onChange={() => setSelectedType({ appointmentTypeId: type?.id, title: type?.title })} />
                        <span> {type?.title} </span>
                    </label>
                </div>
                <div className={styles.min_width_14 + " " + "delete-icon d-flex align-items-center"}>
                    <img src={deleteImg} className='cursor-pointer' alt="deleteImage" onClick={() => setDeleteTypeModal(type?.id)} />
                </div>
            </div>
        )}
    </Fragment>

    return (
        <Page title={t('waitingList.typeOfAppointment')} onBack={goBack}>
            {(isLoading || addAppointmentTypeLoading || deleteAppointmentTypeLoading) && <Loader />}
            <Card className={styles['patient-appointment-type']} >
                <div className={styles['add-new-type-btn']}>
                    <button onClick={() => setIsAddType(true)} className={styles['illness-type-save-cancel-btn']}>
                        <span>
                            <img src={plusImg} alt="ds" className="mr-1" />
                        </span>
                        {t('patientAppointmentMembers.addNewType')}
                    </button>
                    {isAddType &&
                        <div id="results" className={styles["search-results"]}>
                            <div className={styles["patient-appointment-type-input-box"]}>
                                <Input
                                    Type="text"
                                    Placeholder={t('form.placeholder1', { field: t('patientAppointmentMembers.newTypeHere') })}
                                    Name={"NewTypeHere"}
                                    HandleChange={(e) => setType(e.target.value)}
                                    MaxLength="120"
                                />
                            </div>
                            <div className={styles['illness-type-btn-container']}>
                                <button className={styles['illness-type-save-cancel-btn']} onClick={handleAddType}>{t('patientAppointmentMembers.save')}</button>
                                <button className={styles['illness-type-save-cancel-btn']} onClick={() => setIsAddType(false)}>{t('patientAppointmentMembers.cancel')}</button>
                            </div>
                        </div>
                    }
                </div>
                {appointmentTypeList.length === 0 ? <div className={styles["not_found"]}>
                    <Empty Message={t('waitingList.notFoundType')} />
                </div> : <Fragment>
                    <div className={styles['HygieneClassWrapper']}>
                        <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                            <InfiniteScroll
                                className="w-100"
                                dataLength={appointmentTypeList.length}
                                hasMore={appointmentTypeList.length < totalItems}
                                next={() => setPageNumber((v) => v + 1)}
                            >
                                {items}
                            </InfiniteScroll>
                        </motion.div>
                    </div>
                    <div className={styles['common-appointment-btn-container']}>
                        <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={handleSave}>{t('patientAppointmentMembers.save')}</button>
                        <button className="button button-round button-border button-dark btn-mobile-link" onClick={goBack}>{t('patientAppointmentMembers.cancel')}</button>
                    </div>
                </Fragment>}
            </Card>
            {deleteTypeModal &&
                <ConfirmationModal t={t}  isModalOpen={deleteTypeModal} closeModal={closeDeleteModal} handleApply={() => handleDeleteType(deleteTypeModal)} message={t('patientAppointmentMembers.appointmentTypeDeleteMsg')} />
            }
        </Page>
    )
}
export default withTranslation()(TypeOfAppointment);