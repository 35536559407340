import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import { TabContent, TabPane } from "reactstrap";
import { connect } from "react-redux";
import {
  BlobServiceClient,
  AnonymousCredential,
  newPipeline,
} from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";

/*components*/
import Input from "components/Input";
import CustomSelect from "components/CustomSelect";
import Helper from "utils/helper";
import { addOffice, editOffice } from "actions/index";
import Toast from "components/Toast";
import {
  getSastoken,
  officeFieldData,
  getOfficesProvience,
  getOfficesCountry,
  getOfficesCity,
  getOfficesDetail,
} from "actions/index";
import _isLoading from "hoc/isLoading";
import PaymentDetails from "pages/Subscription/PaymentDetails";
import { withTranslation } from "react-i18next";
import MapViewModal from "../components/MapViewModal";
import constants from "../../../constants";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styles from "../Office.module.scss";
import {
  getCityList,
  getSyncedDoctorAndPatient,
} from "repositories/office-repository";
import toast from "react-hot-toast";
import CustomInput from "../components/CustomInput";
import {
  generateMapImageWithMarker,
  testRegexCheck,
  validateEmail,
} from "utils";
import Alert from "reactstrap/lib/Alert";
import ToggleSwitch from "components/ToggleSwitch";
import Text from "components/Text";
import Tooltip from "reactstrap/lib/Tooltip";
import { getPraticeList } from "repositories/pms-repository";
import FamilyModal from "patient-scheduling/pages/FamilyMembers/components/AddedMembers/FamilyModal";
import VerifiedIcon from "../../../assets/images/pms-icons/verified_btn.svg";
import InfoModal from "components/CustomModal/InfoModal";
import Branding from "./Branding";

export function withGoogleAutoSuggestion(Wrapcomponent) {
  return (props) => {
    const googleServices = usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      debounce: 700,
    });
    return <Wrapcomponent googleServices={googleServices} {...props} />;
  };
}

class AddOffice extends Component {
  state = {
    officeName: "",
    officeAddress: "",
    contactNumber: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",
    errors: {},
    isToastView: false,
    isProps: true,
    accountLogo: "",
    officeDetailTab: true,
    brandTab: false,
    paymentDetailsTab: false,
    cardId: "",
    officeImage: "",
    officeLogo: "",
    cityList: [],
    showMapPredictions: false,
    showCountryToaster: false,
    googleSearchListNearBy: [],
    markerPosition: "",
    lat: 0,
    long: 0,
    selectedPlacedId: "",
    placeId: null,
    isConnectedWithSikka: false,
    tooltipMasterCustomerId: false,
    tooltipWhatsThis: false,
    showPraticeField: false,
    practiceKeyList: [],
    sikkaOfficeId: "",
    sikkaPracticeId: "",
    sikkaPracticeName: "",
    practiceManagementSystemName: "",
    sikkaOfficeSecretKey: "",
    verifyModal: false,
    infoModal: false,
    isSubmitConfirmed: false,
    canDisablePmsDetails: false,
    branding: {
      emailBrandingOfficeName: "",
      emailBrandingOfficeLatitude: "",
      emailBrandingOfficeLongitude: "",
      emailBrandingOfficeAddress: "",
      emailBrandingOfficeEmail: "",
      emailBrandingOfficeContactNumber: "",
      officeWebSiteLink: "",
      officeInstagramLink: "",
      officeLinkedinLink: "",
      officeFacebookLink: "",
      isLocationEnabledForPatientAppointmentEmailReminder: "",
      isCalendarEventEnabledForPatientAppointmentEmailReminder: "",
      isLocationEnabledForPatientAppointmentConfirmationEmail: "",
      isCalendarEventEnabledForPatientAppointmentConfirmationEmail: "",
      isLocationEnableForPatientEmailRecallRemider: "",
      isLocationEnableForSystemEmailToPatientOwnerAndStaff: "",
    },
    canSetBrandingValue: true,

    currentFormDetail: {
      officeName: "",
      officeAddress: "",
      contactNumber: "",
      country: "",
      province: "",
      city: "",
      postalCode: "",
      accountLogo: "",
      cardId: "",
      officeAddressEdit: "",
      sikkaOfficeId: "",
      sikkaPracticeId: "",
      isConnectedWithSikka: false,
    },
    currentBrandingFormDetail: {
      emailBrandingOfficeName: "",
      emailBrandingOfficeLatitude: "",
      emailBrandingOfficeLongitude: "",
      emailBrandingOfficeAddress: "",
      emailBrandingOfficeEmail: "",
      emailBrandingOfficeContactNumber: "",
      officeWebSiteLink: "",
      officeInstagramLink: "",
      officeLinkedinLink: "",
      officeFacebookLink: "",
      isLocationEnabledForPatientAppointmentEmailReminder: "",
      isCalendarEventEnabledForPatientAppointmentEmailReminder: "",
      isLocationEnabledForPatientAppointmentConfirmationEmail: "",
      isCalendarEventEnabledForPatientAppointmentConfirmationEmail: "",
      isLocationEnableForPatientEmailRecallRemider: "",
      isLocationEnableForSystemEmailToPatientOwnerAndStaff: "",
    },
    canShowConfirmationOnToggleOffPms: false,
    canShowPmsConfirmationModal: false,
    activeTab: "1",
    isMapViewModalOpen: false,
    pmsToggleOffConfirmationCounter: 0,
  };

  toggle(key) {
    this.state.activeTab = key;
    this.setState(this.state);
  }

  openModal = () => this.setState({ isMapViewModalOpen: true });
  closeModal = () => this.setState({ isMapViewModalOpen: false });
  closeVerifyModal = () => this.setState({ verifyModal: false });
  closeInfoModal = () => this.setState({ infoModal: false });
  closeSubmitModal = () => this.setState({ isSubmitConfirmed: false });

  goBack() {
    if (this.props?.location?.state?.from === "offices-list") {
      this.props.history.push(constants.routes.accountOwner.officesList);
    } else {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    this.props.getOfficesCountry();
    this.props.getSastoken();

    if (
      this.props.profile &&
      this.props.location.pathname === "/AddOffice" &&
      (this.props.profile.profileSetupStep === "packageExpired" ||
        this.props.profile.profileSetupStep === "subscriptionTerminated" ||
        this.props.profile.officesAvailableToAdd === 0)
    ) {
      this.props.history.push("/");
    }

    if (this.props.location.state) {
      this.props.getOfficesDetail({ Id: this.props.location.state.officeId });
    }
  }

  async getPracticeListData(masterId) {
    let res = await getPraticeList(masterId);

    if (res?.data?.practices?.length > 0) {
      this.setState({
        practiceKeyList: res?.data?.practices,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== "/AddOffice" && state.isProps) {
      return {
        officeName: props.officeDetail.name,
        officeAddress: props.officeDetail.address,
        officeAddressEdit: props.officeDetail.address,
        contactNumber: props.officeDetail.contactNumber,
        country: props.officeDetail.countryId || props?.profile?.countryId,
        province: props.officeDetail.provinceId,
        city: props.officeDetail.cityId,
        postalCode: props.officeDetail.postCode,
        cardId: props.officeDetail.cardId,
        officeImage: props.officeDetail.officeImage,
        officeLogo: props.officeDetail?.emailBrandingOfficeLogo,
        placeId: props.officeDetail.placeId,
        lat: props.officeDetail.latitude,
        long: props.officeDetail.longitude,
        selectedPlacedId: props.officeDetail.placeId,
        sikkaOfficeId: props.officeDetail.sikkaOfficeId,
        sikkaPracticeId: props.officeDetail.sikkaPracticeId,
        sikkaPracticeName: props.officeDetail.sikkaPracticeName,
        practiceManagementSystemName:
          props.officeDetail.practiceManagementSystemName,
        sikkaOfficeSecretKey: props.officeDetail.sikkaOfficeSecretKey,
        showPraticeField: !!props.officeDetail.sikkaOfficeId,
        branding: {
          emailBrandingOfficeName: props.officeDetail.officeNameForEmailBrading,
          emailBrandingOfficeLatitude:
            props.officeDetail.officeLatitudeForEmailBranding,
          emailBrandingOfficeLongitude:
            props.officeDetail.officeLongitutdeForEmailBranding,
          emailBrandingOfficeAddress:
            props.officeDetail.officeAddressForEmailBranding,
          emailBrandingOfficeEmail:
            props.officeDetail.officeEmailForEmailBranding,
          emailBrandingOfficeContactNumber:
            props.officeDetail.officeContactNumberForEmailBranding,
          officeWebSiteLink: props.officeDetail.officeWebSiteLink,
          officeInstagramLink: props.officeDetail.officeInstagramLink,
          officeLinkedinLink: props.officeDetail.officeLinkedinLink,
          officeFacebookLink: props.officeDetail.officeFaceBookLink,
          isLocationEnabledForPatientAppointmentEmailReminder:
            props.officeDetail
              .isLocationEnabledForPatientAppointmentEmailReminder,
          isCalendarEventEnabledForPatientAppointmentEmailReminder:
            props.officeDetail
              .isCalendarEventEnabledForPatientAppointmentEmailReminder,
          isLocationEnabledForPatientAppointmentConfirmationEmail:
            props.officeDetail
              .isLocationEnabledForPatientAppointmentConfirmationEmail,
          isCalendarEventEnabledForPatientAppointmentConfirmationEmail:
            props.officeDetail
              .isCalendarEventEnabledForPatientAppointmentConfirmationEmail,
          isLocationEnableForPatientEmailRecallRemider:
            props.officeDetail.isLocationEnableForPatientEmailRecallRemider,
          isLocationEnableForSystemEmailToPatientOwnerAndStaff:
            props.officeDetail
              .isLocationEnableForSystemEmailToPatientOwnerAndStaff,
        },
        currentBrandingFormDetail: {
          emailBrandingOfficeName: props.officeDetail.officeNameForEmailBrading,
          emailBrandingOfficeLatitude:
            props.officeDetail.officeLatitudeForEmailBranding,
          emailBrandingOfficeLongitude:
            props.officeDetail.officeLongitutdeForEmailBranding,
          emailBrandingOfficeAddress:
            props.officeDetail.officeAddressForEmailBranding,
          emailBrandingOfficeEmail:
            props.officeDetail.officeEmailForEmailBranding,
          emailBrandingOfficeContactNumber:
            props.officeDetail.officeContactNumberForEmailBranding,
          officeWebSiteLink: props.officeDetail.officeWebSiteLink,
          officeInstagramLink: props.officeDetail.officeInstagramLink,
          officeLinkedinLink: props.officeDetail.officeLinkedinLink,
          officeFacebookLink: props.officeDetail.officeFaceBookLink,
          isLocationEnabledForPatientAppointmentEmailReminder:
            props.officeDetail
              .isLocationEnabledForPatientAppointmentEmailReminder,
          isCalendarEventEnabledForPatientAppointmentEmailReminder:
            props.officeDetail
              .isCalendarEventEnabledForPatientAppointmentEmailReminder,
          isLocationEnabledForPatientAppointmentConfirmationEmail:
            props.officeDetail
              .isLocationEnabledForPatientAppointmentConfirmationEmail,
          isCalendarEventEnabledForPatientAppointmentConfirmationEmail:
            props.officeDetail
              .isCalendarEventEnabledForPatientAppointmentConfirmationEmail,
          isLocationEnableForPatientEmailRecallRemider:
            props.officeDetail.isLocationEnableForPatientEmailRecallRemider,
          isLocationEnableForSystemEmailToPatientOwnerAndStaff:
            props.officeDetail
              .isLocationEnableForSystemEmailToPatientOwnerAndStaff,
        },

        markerPosition: {
          lat: props.officeDetail.latitude,
          lng: props.officeDetail.longitude,
        },
        currentFormDetail: {
          officeName: props.officeDetail.name,
          officeAddress: props.officeDetail.address,
          selectedPlacedId: props.officeDetail.placeId,
          contactNumber: props.officeDetail.contactNumber,
          country: props.officeDetail.countryId,
          province: props.officeDetail.provinceId,
          city: props.officeDetail.cityId,
          postalCode: props.officeDetail.postCode,
          accountLogo: "",
          cardId: props.officeDetail.cardId,
          officeImage: props.officeDetail.officeImage,
          officeLogo: props.officeDetail?.emailBrandingOfficeLogo,
          lat: props.officeDetail.latitude,
          long: props.officeDetail.longitude,
          sikkaOfficeId: props.officeDetail.sikkaOfficeId,
          sikkaPracticeId: props.officeDetail.sikkaPracticeId,
          sikkaPracticeName: props.officeDetail.sikkaPracticeName,
          practiceManagementSystemName:
            props.officeDetail.practiceManagementSystemName,
          sikkaOfficeSecretKey: props.officeDetail.sikkaOfficeSecretKey,
          isConnectedWithSikka: props.officeDetail.isConnectedWithSikka,
        },
        canShowConfirmationOnToggleOffPms:
          props.officeDetail.isConnectedWithSikka,
      };
    }

    if (props.location.pathname == "/AddOffice" && state.isProps) {
      return {
        country: props?.profile?.countryId,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isOfficeAdd !== this.props.isOfficeAdd ||
      prevProps.isAddedError !== this.props.isAddedError ||
      prevProps.isPaymentDetailError !== this.props.isPaymentDetailError ||
      prevProps.cardstatusMessage !== this.props.cardstatusMessage
    ) {
      window.scrollTo(0, 0);
      this.setState({ isToastView: true });
      setTimeout(() => {
        if (this.props.cardstatusMessage && this.props.cardSaved) {
          this.setState({ isToastView: false });
        }

        if (this.props.isOfficeAdd && !this.state.isConnectedWithSikka) {
          this.props.history.push("/Offices");
        }

        if (this.state.isConnectedWithSikka) {
          this.setState({ isSubmitConfirmed: true });
        }
      }, 2000);
    }

    if (
      this.props.location.state &&
      prevProps.officeDetail !== this.props.officeDetail &&
      this.props.officeDetail.provinceId
    ) {
      this.getListCity(this.props.officeDetail.provinceId);
    }
    if (this.state.country && !this.props?.provienceList?.length) {
      this.props.getOfficesProvience({ id: this.state.country });
    }

    if (
      !this.props.provienceList?.length &&
      this.state.country !== prevState.country
    ) {
      this.state.country &&
        this.props.getOfficesProvience({ id: this.state.country });
      if (this.state.placeId) {
        let location = {
          lat: this.state.lat,
          long: this.state.long,
        };
        this.handlePlaceSelect(
          this.state.placeId,
          this.state.officeAddress,
          this.state.placeId,
          location
        );
      }
    }

    if (prevProps.officeDetail !== this.props.officeDetail) {
      if (this.props.officeDetail.sikkaOfficeId) {
        this.getPracticeListData(this.props.officeDetail.sikkaOfficeId);
        this.setState({
          canDisablePmsDetails: true,
          isConnectedWithSikka: this.props?.officeDetail?.isConnectedWithSikka,
        });
      }
    }
  }

  InputChange = (event) => {
    this.setState({ isProps: false });
    const { name, value } = event.target;
    if (name === "officeName" && !testRegexCheck(value)) return;
    if (name === "sikkaOfficeId" && !testRegexCheck(value)) return;
    this.setState({ [name]: value });
  };

  handleCheckboxChange = (event) => {
    this.setState({ isProps: false });
    const { name, checked } = event.target;
    this.setState({
      branding: {
        ...this.state.branding,
        [name]: checked,
      },
    });
  };

  HandleBrandingInputChange = (event) => {
    this.setState({ isProps: false });
    const { name, value } = event.target;
    if (name === "officeName" && !testRegexCheck(value)) return;
    if (name === "sikkaOfficeId" && !testRegexCheck(value)) return;
    this.setState({
      branding: {
        ...this.state.branding,
        [name]: value,
      },
    });
  };

  async getListCity(id) {
    if (!id) return;
    try {
      let res = await getCityList(id);
      this.setState({ cityList: res });
    } catch (error) {
      toast.error(error.message);
    }
  }

  getPraticeListing(id) {
    if (!id?.trim()?.length) return;
    this.setState({ verifyModal: true });
  }

  async confirmVerifyRequest() {
    try {
      let res = await getPraticeList(this.state.sikkaOfficeId);

      if (res?.statusCode === 400) {
        this.setState({ infoModal: true });
      }

      if (res?.data?.practices?.length > 0) {
        this.setState({
          showPraticeField: true,
          practiceKeyList: res?.data?.practices,
          practiceManagementSystemName: res?.data?.practice_management_system,
          sikkaOfficeSecretKey: res?.data?.secret_key,
          verifyModal: false,
          errors: {
            ...this.state.errors,
            sikkaPracticeId: null,
          },
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.setState({ verifyModal: false });
    }
  }

  isCardValid = () => {
    let isCardValid = true;

    const { cardId } = this.state;
    const errors = {};
    const { t } = this.props;

    if (!cardId) {
      errors.cardId = t("accountOwner.selectACard");
      isCardValid = false;
    }

    this.setState({ errors });

    return isCardValid;
  };

  handleCountry = (event) => {
    this.setState({
      isProps: false,
      showCountryToaster:
        parseFloat(this.props.profile.countryId) !==
        parseFloat(event.target.value),
    });
  };

  handleProvience = async (event) => {
    try {
      this.setState({ isProps: false });
      const { name, value } = event.target;
      this.setState({ [name]: value, city: "" });
      let res = await getCityList(value);
      this.setState({ cityList: res });
    } catch (error) {
      toast.error(error.message);
      this.setState({ cityList: [] });
    }
  };

  handlePracticeKey = (event) => {
    this.setState({ isProps: false });
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  createMapLink(latitude, longitude) {
    return `https://www.google.com/maps?q=${latitude},${longitude}`;
  }

  async createMapImage(brandAddress, brandLatitude, brandLongitude) {
    try {
      const imageUrl = await generateMapImageWithMarker({
        address: brandAddress,
        latitude: brandLatitude,
        longitude: brandLongitude,
        sasToken: this.props.sasToken.token,
      });

      if (imageUrl) {
        return imageUrl; // Return the direct image URL
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  handleAddofficeContinue = async (action) => {
    try {
      const isValid = this.isValid();
      const {
        officeName,
        officeAddress,
        contactNumber,
        country,
        province,
        city,
        postalCode,
        cardId,
        officeImage,
        officeLogo,
        placeId,
        lat,
        long,
        sikkaOfficeId,
        sikkaPracticeId,
        sikkaPracticeName,
        practiceManagementSystemName,
        sikkaOfficeSecretKey,
        isConnectedWithSikka,
        branding,
      } = this.state;

      const {
        emailBrandingOfficeName,
        emailBrandingOfficeLatitude,
        emailBrandingOfficeLongitude,
        emailBrandingOfficeAddress,
        emailBrandingOfficeEmail,
        emailBrandingOfficeContactNumber,
        officeWebSiteLink,
        officeInstagramLink,
        officeLinkedinLink,
        officeFacebookLink,
        isLocationEnabledForPatientAppointmentEmailReminder,
        isCalendarEventEnabledForPatientAppointmentEmailReminder,
        isLocationEnabledForPatientAppointmentConfirmationEmail,
        isCalendarEventEnabledForPatientAppointmentConfirmationEmail,
        isLocationEnableForPatientEmailRecallRemider,
        isLocationEnableForSystemEmailToPatientOwnerAndStaff,
      } = branding;

      const {
        officeDetail: { id },
      } = this.props;

      if (isValid) {
        if (action === "officeTab") {
          this.brandTabShow();
        }

        if (action === "brandingTab") {
          this.paymentTabShow();
        }

        if (action === "paymentTab") {
          const isCardValid = this.isCardValid();
          if (!isCardValid) return;

          if (isCardValid) {
            const payload = {
              userId: this.props.profile.id,
              officeName,
              officeAddress,
              contactNumber,
              countryId: parseInt(country),
              provinceId: parseInt(province),
              cityId: parseInt(city),
              postalCode,
              isOwnerAccount: false,
              accountLogo: "",
              officeImage,
              emailBrandingOfficeLogo: officeLogo,
              cardId,
              placeId: placeId,
              latitude: lat,
              longitude: long,
              IsConnectedWithSikka: isConnectedWithSikka
                ? isConnectedWithSikka
                : false,
              SikkaOfficeId:
                sikkaOfficeId && isConnectedWithSikka ? sikkaOfficeId : null,
              SikkaPracticeId:
                sikkaPracticeId && isConnectedWithSikka
                  ? sikkaPracticeId
                  : null,
              SikkaPracticeName:
                sikkaPracticeName && isConnectedWithSikka
                  ? sikkaPracticeName
                  : null,
              PracticeManagementSystemName:
                practiceManagementSystemName && isConnectedWithSikka
                  ? practiceManagementSystemName
                  : null,
              SikkaOfficeSecretKey:
                sikkaOfficeSecretKey && isConnectedWithSikka
                  ? sikkaOfficeSecretKey
                  : null,
              emailBrandingOfficeName,
              emailBrandingOfficeAddress,
              emailBrandingOfficeEmail,
              emailBrandingOfficeContactNumber,
              emailBrandingOfficeLatitude: emailBrandingOfficeLatitude
                ? emailBrandingOfficeLatitude
                : null,
              emailBrandingOfficeLongitude: emailBrandingOfficeLongitude
                ? emailBrandingOfficeLongitude
                : null,
              officeWebSiteLink: officeWebSiteLink ? officeWebSiteLink : null,
              officeInstagramLink: officeInstagramLink
                ? officeInstagramLink
                : null,
              officeLinkedinLink: officeLinkedinLink
                ? officeLinkedinLink
                : null,
              officeFacebookLink: officeFacebookLink
                ? officeFacebookLink
                : null,
              isLocationEnabledForPatientAppointmentEmailReminder:
                isLocationEnabledForPatientAppointmentEmailReminder
                  ? isLocationEnabledForPatientAppointmentEmailReminder
                  : false,
              isCalendarEventEnabledForPatientAppointmentEmailReminder:
                isCalendarEventEnabledForPatientAppointmentEmailReminder
                  ? isCalendarEventEnabledForPatientAppointmentEmailReminder
                  : false,
              isLocationEnabledForPatientAppointmentConfirmationEmail:
                isLocationEnabledForPatientAppointmentConfirmationEmail
                  ? isLocationEnabledForPatientAppointmentConfirmationEmail
                  : false,
              isCalendarEventEnabledForPatientAppointmentConfirmationEmail:
                isCalendarEventEnabledForPatientAppointmentConfirmationEmail
                  ? isCalendarEventEnabledForPatientAppointmentConfirmationEmail
                  : false,
              isLocationEnableForPatientEmailRecallRemider:
                isLocationEnableForPatientEmailRecallRemider
                  ? isLocationEnableForPatientEmailRecallRemider
                  : false,
              isLocationEnableForSystemEmailToPatientOwnerAndStaff:
                isLocationEnableForSystemEmailToPatientOwnerAndStaff
                  ? isLocationEnableForSystemEmailToPatientOwnerAndStaff
                  : false,
            };

            if (emailBrandingOfficeLatitude && emailBrandingOfficeLongitude) {
              payload.OfficeLocationRedirectionUrl = this.createMapLink(
                emailBrandingOfficeLatitude,
                emailBrandingOfficeLongitude
              );
            }

            if (
              emailBrandingOfficeLatitude &&
              emailBrandingOfficeLongitude &&
              emailBrandingOfficeAddress
            ) {
              payload.emailBrandingMapImage =
                (await this.createMapImage(
                  emailBrandingOfficeAddress,
                  emailBrandingOfficeLatitude,
                  emailBrandingOfficeLongitude
                )) || "";
            }

            if (this.props.location.pathname !== "/AddOffice") {
              this.props.editOffice({ ...payload, officeId: id });
            } else {
              this.props.addOffice({ ...payload });
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  toastHide = () => {
    this.setState({ isToastView: false });
  };

  officeTabShow = () => {
    this.setState({
      officeDetailTab: true,
      paymentDetailsTab: false,
      brandTab: false,
    });
  };

  brandTabShow = (isFromPaymentTab) => {
    const isValid = this.isValid(() => {
      if (isFromPaymentTab) {
        this.isValidBandingFields();
      }
    });

    if (!isValid) return;

    let data = {
      officeDetailTab: false,
      paymentDetailsTab: false,
      brandTab: true,
    };

    if (this.state.canSetBrandingValue) {
      data = {
        ...data,
        canSetBrandingValue: false,
        branding: {
          ...this.state.branding,
          emailBrandingOfficeLatitude: this.state.lat,
          emailBrandingOfficeLongitude: this.state.long,
          emailBrandingOfficeName: this.state.officeName,
          emailBrandingOfficeAddress: this.state.officeAddress,
          emailBrandingOfficeContactNumber: this.state.contactNumber,
        },
      };
    }

    this.setState(data);
  };

  paymentTabShow = () => {
    const isValid = this.isValid(() => {
      const isBrandingValid = this.isValidBandingFields();
      if (isValid && isBrandingValid) {
        this.setState({
          officeDetailTab: false,
          paymentDetailsTab: true,
          brandTab: false,
        });
      }

      if (!isBrandingValid) {
        this.brandTabShow(true);
      }
    });
  };

  fileChange = (event, imageType) => {
    this.setState({ isProps: false });
    event.preventDefault();
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    const fsize = files[0]?.size;
    const fileLimit = Math.round(fsize / 1024);
    let fileSizeLimit = true;
    const extFile = files.length ? files[0]?.type : "";

    if (fileLimit >= 1024 * 5) {
      const errors = this.state.errors;
      this.setState({
        errors: {
          ...errors,
          [imageType]: "File size should be less than 5 MB.",
        },
      });
      fileSizeLimit = false;
    }

    if (extFile !== "" && fileSizeLimit) {
      if (
        extFile === "image/jpeg" ||
        extFile === "image/jpg" ||
        extFile === "image/png"
      ) {
        this.upload(imageType);
        this.setState({ blobLoader: true });
        const reader = new FileReader();
        reader.onload = () => {
          const errors = this.state.errors;
          this.setState({
            [imageType]: reader.result,
            error: { ...errors, [imageType]: "" },
          });
        };
        reader.readAsDataURL(files[0]);
        this.setState({ errors: { ...this.state.errors, [imageType]: "" } });
      } else {
        const errors = this.state.errors;
        this.setState({
          errors: { ...errors, [imageType]: "Please select valid file" },
        });
      }
    } else {
      this.setState({ blobLoader: false });
    }
  };

  upload = async (imageType) => {
    try {
      const accountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT;
      const sasString = this.props.sasToken.token;
      const file = document.getElementById(`fileUpload-${imageType}`)?.files[0];
      const pipeline = newPipeline(new AnonymousCredential());
      const containerName = process.env.REACT_APP_AZURE_STORAGE_CONTAINER;
      const fileExtension = file.name.split(".").pop();
      const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net?${sasString}`,
        pipeline
      );

      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobName = `${uuidv4()}.${fileExtension}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const blobOptions = {
        blobHTTPHeaders: { blobContentType: file.type },
      };
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
        file,
        blobOptions
      );

      if (uploadBlobResponse._response.status === 201 || uploadBlobResponse._response.status === 200) {
        this.setState({ blobLoader: false });
        const fileName = uploadBlobResponse._response.request.url.split("?");
        this.setState({ [imageType]: fileName[0] });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      this.setState({ blobLoader: false });
    }
  };

  deleteBlob = () => {
    //const accountName = 'mxhhstagingstorageacc';
    const accountName = `${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}`;
    const sasString = this.props.sasToken.token;
    const pipeline = newPipeline(new AnonymousCredential());
    //const containerName = 'accountpictures';
    const containerName = `${process.env.REACT_APP_AZURE_STORAGE_CONTAINER}`;
    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net?${sasString}`,
      pipeline
    );
    const fileName1 = this.state.blobFileName[1];
    async function main() {
      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const item = await containerClient.getBlockBlobClient(fileName1).exists();
      return item;
    }
    async function mainDeleteBlob() {
      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(fileName1);
      blockBlobClient.delete();
    }
    const Result = main();
    Result.then((data) => {
      if (data) {
        this.setState({ blobFileName: null });
        mainDeleteBlob();
      }
    });
  };

  handleCardSelect = (id) => {
    this.setState({ cardId: id, isProps: false });
  };

  shouldDisableUpdateButton = () => {
    const billingPreferenceType2 =
      this.props.profile &&
      this.props.profile.billingPreferenceType &&
      this.props.profile.billingPreferenceType === 2;

    if (this.state.currentFormDetail.officeName !== this.state.officeName)
      return false;

    if (this.state.currentFormDetail.officeAddress !== this.state.officeAddress)
      return false;

    if (this.state.currentFormDetail.contactNumber !== this.state.contactNumber)
      return false;

    if (this.state.currentFormDetail.country !== this.state.country)
      return false;

    if (this.state.currentFormDetail.province !== this.state.province)
      return false;

    if (this.state.currentFormDetail.city !== this.state.city) return false;

    if (this.state.currentFormDetail.postalCode !== this.state.postalCode)
      return false;

    if (
      billingPreferenceType2 &&
      this.state.currentFormDetail.cardId !== this.state.cardId
    )
      return false;

    if (this.state.currentFormDetail.officeImage !== this.state.officeImage)
      return false;

    if (this.state.currentFormDetail.officeLogo !== this.state.officeLogo)
      return false;

    if (
      this.state.currentFormDetail.isConnectedWithSikka !==
      this.state.isConnectedWithSikka
    )
      return false;

    if (
      this.state.currentFormDetail.selectedPlacedId !==
      this.state.selectedPlacedId
    )
      return false;

    if (this.state.currentFormDetail.lat !== this.state.lat) return false;

    if (this.state.currentFormDetail.long !== this.state.long) return false;

    if (
      this.state.currentBrandingFormDetail.emailBrandingOfficeName !==
      this.state.branding.emailBrandingOfficeName
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.emailBrandingOfficeLatitude !==
      this.state.branding.emailBrandingOfficeLatitude
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.emailBrandingOfficeLongitude !==
      this.state.branding.emailBrandingOfficeLongitude
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.emailBrandingOfficeAddress !==
      this.state.branding.emailBrandingOfficeAddress
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.emailBrandingOfficeEmail !==
      this.state.branding.emailBrandingOfficeEmail
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.emailBrandingOfficeContactNumber !==
      this.state.branding.emailBrandingOfficeContactNumber
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.officeWebSiteLink !==
      this.state.branding.officeWebSiteLink
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.officeInstagramLink !==
      this.state.branding.officeInstagramLink
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.officeLinkedinLink !==
      this.state.branding.officeLinkedinLink
    )
      return false;

    if (
      this.state.currentBrandingFormDetail.officeFacebookLink !==
      this.state.branding.officeFacebookLink
    )
      return false;

    if (
      this.state.currentBrandingFormDetail
        .isLocationEnabledForPatientAppointmentEmailReminder !==
      this.state.branding.isLocationEnabledForPatientAppointmentEmailReminder
    )
      return false;

    if (
      this.state.currentBrandingFormDetail
        .isCalendarEventEnabledForPatientAppointmentEmailReminder !==
      this.state.branding
        .isCalendarEventEnabledForPatientAppointmentEmailReminder
    )
      return false;

    if (
      this.state.currentBrandingFormDetail
        .isLocationEnabledForPatientAppointmentConfirmationEmail !==
      this.state.branding
        .isLocationEnabledForPatientAppointmentConfirmationEmail
    )
      return false;

    if (
      this.state.currentBrandingFormDetail
        .isCalendarEventEnabledForPatientAppointmentConfirmationEmail !==
      this.state.branding
        .isCalendarEventEnabledForPatientAppointmentConfirmationEmail
    )
      return false;

    if (
      this.state.currentBrandingFormDetail
        .isLocationEnableForPatientEmailRecallRemider !==
      this.state.branding.isLocationEnableForPatientEmailRecallRemider
    )
      return false;

    if (
      this.state.currentBrandingFormDetail
        .isLocationEnableForSystemEmailToPatientOwnerAndStaff !==
      this.state.branding.isLocationEnableForSystemEmailToPatientOwnerAndStaff
    )
      return false;

    return true;
  };

  getCountry() {
    let name = this.props.countryList?.find(
      (e) => e.id === parseInt(this.state.country)
    )?.name;
    if (name) {
      name = name.slice(0, 2).toLowerCase();
    }
    return name ? [name.slice(0, 2)] : [];
  }

  placePredictionContent = () => {
    return this.props.googleServices.placePredictions.map((place) => {
      const { description, place_id } = place;
      return (
        <li
          key={place_id}
          onClick={() => {
            setTimeout(() => {
              this.setState({ showMapPredictions: false });
              this.handlePlaceSelect(place_id, description);
            }, 300);
          }}
        >
          {description}
        </li>
      );
    });
  };

  getLocationByAddress = (address) => {
    let locationInfo = {};
    for (const element of address) {
      let component = element.types[0];
      switch (component) {
        case constants.MAPKEY.COUNTRY:
          locationInfo.countryShortName = element["short_name"];
          locationInfo.country = element["long_name"];
          break;
        case constants.MAPKEY.ADMINISTRATIVE:
          locationInfo.state = element["long_name"];
          break;
        case constants.MAPKEY.LOCALITY:
          locationInfo.city = element["long_name"];
          break;
        case constants.MAPKEY.POSTALCODE:
          locationInfo.postalCode = element["long_name"];
          break;
        default:
          break;
      }
    }

    return locationInfo;
  };

  nearByLocationList = () => {
    return (
      <>
        <li>
          <div className="ch-radio">
            <label className="mr-5">
              <input
                type="radio"
                name="selectTime"
                checked={this.state.selectedPlacedId == constants.NOTINGOOGLE}
                onChange={() => {
                  this.setState({
                    isProps: false,
                    selectedPlacedId: constants.NOTINGOOGLE,
                    placeId: null,
                  });
                }}
              />
              <span> {this.props.t("accountOwner.notRegisterInGoogle")}</span>
            </label>
          </div>
        </li>
        {this.state.googleSearchListNearBy?.map((item, index) => (
          <li key={index}>
            <div className="ch-radio">
              <label className="mr-5">
                <input
                  type="radio"
                  name="selectTime"
                  value={item.place_id}
                  checked={item.place_id == this.state.selectedPlacedId}
                  onChange={() => {
                    this.setState({
                      isProps: false,
                      selectedPlacedId: item.place_id,
                      placeId: item.place_id,
                    });
                  }}
                />
                <span>{item.name} </span>
              </label>
            </div>
          </li>
        ))}
      </>
    );
  };

  handlePlaceSelect = (placeId, description, selectedPlacedId, location) => {
    try {
      this.props.googleServices?.placesService?.getDetails(
        { placeId },
        async (details) => {
          this.setState({
            isProps: false,
            officeAddress: description || details.formatted_address,
            selectedPlacedId: selectedPlacedId
              ? selectedPlacedId
              : constants.NOTINGOOGLE,
            lat: location?.lat
              ? location?.lat
              : details.geometry.location.lat(),
            long: location?.long
              ? location?.long
              : details.geometry.location.lng(),
            placeId: placeId,
            markerPosition: {
              lat: location?.lat
                ? location?.lat
                : details.geometry.location.lat(),
              lng: location?.long
                ? location?.long
                : details.geometry.location.lng(),
            },
          });

          this.props.googleServices?.placesService?.nearbySearch(
            {
              location: details.geometry.location,
              rankBy: window.google.maps.places.RankBy.DISTANCE,
              types: constants.GOOGLEPLACETYPE,
            },
            (list) => {
              this.setState({
                googleSearchListNearBy: list,
                showMapPredictions: false,
              });
            }
          );

          let locationInfo = this.getLocationByAddress(
            details.address_components
          );
          if (locationInfo?.state && this.state.country) {
            let res;
            let googleState = this.props.provienceList?.find(
              (item) =>
                item.name.toLowerCase() === locationInfo.state.toLowerCase()
            );
            if (googleState) {
              res = await getCityList(googleState.id);
              this.setState({ province: googleState.id });
            }
            if (res) {
              this.setState({
                cityList: res,
                postalCode: locationInfo.postalCode ?? "",
              });
              let googleCity = res?.find(
                (item) =>
                  item.name.toLowerCase() === locationInfo?.city?.toLowerCase()
              );
              if (googleCity) {
                // selected city
                this.setState({ city: googleCity.id });
              } else {
                if (locationInfo.city) {
                  this.setState({
                    cityList: [
                      { name: locationInfo.city, id: locationInfo.city },
                      ...res,
                    ],
                    city: locationInfo.city,
                  });
                } else {
                  this.setState({
                    cityList: [...res],
                    city: "",
                  });
                }
              }
            }
          }
        }
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  handleBrandingAddrees = (
    placeId,
    description,
    selectedPlacedId,
    location
  ) => {
    try {
      this.props.googleServices?.placesService?.getDetails(
        { placeId },
        async (details) => {
          this.setState({
            branding: {
              ...this.state.branding,
              emailBrandingOfficeAddress:
                description || details.formatted_address,
              emailBrandingOfficeLatitude: location?.lat
                ? location?.lat
                : details.geometry.location.lat(),
              emailBrandingOfficeLongitude: location?.long
                ? location?.long
                : details.geometry.location.lng(),
            },
          });
        }
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  isValid = (onValidDone) => {
    const {
      officeName,
      officeAddress,
      contactNumber,
      country,
      province,
      city,
      postalCode,
      placeId,
      selectedPlacedId,
      sikkaOfficeId,
      sikkaPracticeId,
      isConnectedWithSikka,
      showPraticeField,
    } = this.state;
    const errors = {};
    let isValid = true;

    const { t } = this.props;

    if (!officeName) {
      errors.officeName = t("form.errors.emptyField", {
        field: t("form.fields.officeName"),
      });
      isValid = false;
    }
    if (!officeAddress) {
      errors.officeAddress = t("form.errors.emptyField", {
        field: t("form.fields.officeAddress"),
      });
      isValid = false;
    }

    if (officeAddress?.length > constants.wordLimits.ADDOFFICEADDRESS) {
      errors.officeAddress = t("form.errors.officeAddressLimit");
      isValid = false;
    }

    if (!contactNumber) {
      errors.contactNumber = t("form.errors.emptyField", {
        field: t("form.fields.contactNumber"),
      });
      isValid = false;
    }

    if (!country) {
      errors.country = t("form.errors.emptySelection", {
        field: t("form.fields.country"),
      });
      isValid = false;
    }

    if (!province) {
      errors.province = t("form.errors.emptySelection", {
        field: t("form.fields.provinceOrState"),
      });
      isValid = false;
    }

    if (!city) {
      errors.city = t("form.errors.emptySelection", {
        field: t("form.fields.city"),
      });
      isValid = false;
    }

    if (!postalCode) {
      errors.postalCode = t("form.errors.emptyField", {
        field: t("form.fields.postalCode"),
      });
      isValid = false;
    }
    if (!postalCode) {
      errors.postalCode = t("form.errors.emptyField", {
        field: t("form.fields.postalCode"),
      });
      isValid = false;
    }

    if (!selectedPlacedId && placeId) {
      errors.selectGooglePredection = t("form.fields.selectGooglePredection");
      isValid = false;
    }

    if (isConnectedWithSikka && !sikkaOfficeId?.trim()?.length) {
      errors.sikkaOfficeId = t("form.errors.emptyField", {
        field: t("pmsMembers.sikkaOfficeId"),
      });
      isValid = false;
    }

    if (isConnectedWithSikka && sikkaOfficeId && !sikkaPracticeId) {
      if (showPraticeField) {
        errors.sikkaPracticeId = t("form.errors.emptySelection", {
          field: t("pmsMembers.sikkaPracticeId"),
        });
      } else {
        errors.sikkaPracticeId = t("pmsMembers.verifyMasterId");
      }
      isValid = false;
    }

    this.setState({ errors }, onValidDone);

    return isValid;
  };

  isValidBandingFields = () => {
    const { branding } = this.state;
    const {
      emailBrandingOfficeName,
      emailBrandingOfficeAddress,
      emailBrandingOfficeEmail,
      emailBrandingOfficeContactNumber,
    } = branding;

    const errors = {};
    let isValid = true;
    const { t } = this.props;

    if (!emailBrandingOfficeName) {
      errors.emailBrandingOfficeName = t("form.errors.emptyField", {
        field: t("form.fields.officeName"),
      });
      isValid = false;
    }

    if (!emailBrandingOfficeAddress) {
      errors.emailBrandingOfficeAddress = t("form.errors.emptyField", {
        field: t("form.fields.officeAddress"),
      });
      isValid = false;
    }

    if (
      emailBrandingOfficeAddress?.length > constants.wordLimits.ADDOFFICEADDRESS
    ) {
      errors.emailBrandingOfficeAddress = t("form.errors.officeAddressLimit");
      isValid = false;
    }

    if (!emailBrandingOfficeContactNumber) {
      errors.emailBrandingOfficeContactNumber = t("form.errors.emptyField", {
        field: t("officeBranding.officePhoneNumber"),
      });
      isValid = false;
    }

    if (
      emailBrandingOfficeContactNumber &&
      !Helper.validateNumber(emailBrandingOfficeContactNumber)
    ) {
      errors.emailBrandingOfficeContactNumber = t("form.errors.invalidValue", {
        field: t("officeBranding.officePhoneNumber"),
      });
      isValid = false;
    }

    if (!emailBrandingOfficeEmail?.trim().length) {
      errors.emailBrandingOfficeEmail = t("form.errors.emptyField", {
        field: t("officeBranding.officeEmail"),
      });
      isValid = false;
    }

    if (emailBrandingOfficeEmail && !validateEmail(emailBrandingOfficeEmail)) {
      errors.emailBrandingOfficeEmail = t("form.errors.invalidValue", {
        field: t("officeBranding.officeEmail"),
      });
      isValid = false;
    }

    this.setState({ errors: { ...this.state.errors, ...errors } });

    return isValid;
  };

  handleAddoffice = async () => {
    const isValid = this.isValidBandingFields();
    if (!isValid) return;

    let {
      officeName,
      officeAddress,
      contactNumber,
      country,
      province,
      city,
      postalCode,
      officeImage,
      officeLogo,
      placeId,
      lat,
      long,
      cityList,
      sikkaOfficeId,
      sikkaPracticeId,
      sikkaPracticeName,
      practiceManagementSystemName,
      sikkaOfficeSecretKey,
      isConnectedWithSikka,
      branding,
    } = this.state;

    const {
      emailBrandingOfficeName,
      emailBrandingOfficeLatitude,
      emailBrandingOfficeLongitude,
      emailBrandingOfficeAddress,
      emailBrandingOfficeEmail,
      emailBrandingOfficeContactNumber,
      officeWebSiteLink,
      officeInstagramLink,
      officeLinkedinLink,
      officeFacebookLink,
      isLocationEnabledForPatientAppointmentEmailReminder,
      isCalendarEventEnabledForPatientAppointmentEmailReminder,
      isLocationEnabledForPatientAppointmentConfirmationEmail,
      isCalendarEventEnabledForPatientAppointmentConfirmationEmail,
      isLocationEnableForPatientEmailRecallRemider,
      isLocationEnableForSystemEmailToPatientOwnerAndStaff,
    } = branding;
    const {
      officeDetail: { id },
    } = this.props;

    let selectedCity;
    if (isNaN(parseInt(city))) {
      selectedCity = cityList?.find((e) => e.id === city);
      if (!selectedCity) {
        return;
      }
      city = 0;
    }

    this.createMapImage(
      emailBrandingOfficeAddress,
      emailBrandingOfficeLatitude,
      emailBrandingOfficeLongitude
    );

    const payload = {
      userId: this.props.profile.id,
      officeName,
      officeAddress,
      contactNumber,
      countryId: parseInt(country),
      provinceId: parseInt(province),
      cityId: parseInt(city),
      city: selectedCity ? selectedCity.name : null,
      postalCode,
      isOwnerAccount: false,
      accountLogo: "",
      officeImage,
      emailBrandingOfficeLogo: officeLogo,
      placeId: placeId,
      latitude: lat,
      longitude: long,
      SikkaOfficeId:
        sikkaOfficeId && isConnectedWithSikka ? sikkaOfficeId : null,
      SikkaPracticeId:
        sikkaPracticeId && isConnectedWithSikka ? sikkaPracticeId : null,
      SikkaPracticeName:
        sikkaPracticeName && isConnectedWithSikka ? sikkaPracticeName : null,
      PracticeManagementSystemName:
        practiceManagementSystemName && isConnectedWithSikka
          ? practiceManagementSystemName
          : null,
      SikkaOfficeSecretKey:
        sikkaOfficeSecretKey && isConnectedWithSikka
          ? sikkaOfficeSecretKey
          : null,
      IsConnectedWithSikka: isConnectedWithSikka ? isConnectedWithSikka : false,
      emailBrandingOfficeName,
      emailBrandingOfficeAddress,
      emailBrandingOfficeEmail,
      emailBrandingOfficeContactNumber,
      emailBrandingOfficeLatitude: emailBrandingOfficeLatitude
        ? emailBrandingOfficeLatitude
        : null,
      emailBrandingOfficeLongitude: emailBrandingOfficeLongitude
        ? emailBrandingOfficeLongitude
        : null,
      officeWebSiteLink: officeWebSiteLink ? officeWebSiteLink : null,
      officeInstagramLink: officeInstagramLink ? officeInstagramLink : null,
      officeLinkedinLink: officeLinkedinLink ? officeLinkedinLink : null,
      officeFacebookLink: officeFacebookLink ? officeFacebookLink : null,
      isLocationEnabledForPatientAppointmentEmailReminder:
        isLocationEnabledForPatientAppointmentEmailReminder
          ? isLocationEnabledForPatientAppointmentEmailReminder
          : false,
      isCalendarEventEnabledForPatientAppointmentEmailReminder:
        isCalendarEventEnabledForPatientAppointmentEmailReminder
          ? isCalendarEventEnabledForPatientAppointmentEmailReminder
          : false,
      isLocationEnabledForPatientAppointmentConfirmationEmail:
        isLocationEnabledForPatientAppointmentConfirmationEmail
          ? isLocationEnabledForPatientAppointmentConfirmationEmail
          : false,
      isCalendarEventEnabledForPatientAppointmentConfirmationEmail:
        isCalendarEventEnabledForPatientAppointmentConfirmationEmail
          ? isCalendarEventEnabledForPatientAppointmentConfirmationEmail
          : false,
      isLocationEnableForPatientEmailRecallRemider:
        isLocationEnableForPatientEmailRecallRemider
          ? isLocationEnableForPatientEmailRecallRemider
          : false,
      isLocationEnableForSystemEmailToPatientOwnerAndStaff:
        isLocationEnableForSystemEmailToPatientOwnerAndStaff
          ? isLocationEnableForSystemEmailToPatientOwnerAndStaff
          : false,
    };

    if (emailBrandingOfficeLatitude && emailBrandingOfficeLongitude) {
      payload.OfficeLocationRedirectionUrl = this.createMapLink(
        emailBrandingOfficeLatitude,
        emailBrandingOfficeLongitude
      );
    }

    if (
      emailBrandingOfficeLatitude &&
      emailBrandingOfficeLongitude &&
      emailBrandingOfficeAddress
    ) {
      payload.emailBrandingMapImage =
        (await this.createMapImage(
          emailBrandingOfficeAddress,
          emailBrandingOfficeLatitude,
          emailBrandingOfficeLongitude
        )) || "";
    }

    if (this.props.location.pathname !== "/AddOffice") {
      this.props.editOffice({ ...payload, officeId: id });
    } else {
      this.props.addOffice({ ...payload });
    }
  };

  getSelectedOption = (list, value) => {
    const selectedData =
      list?.find((val) => val.id.toString() === value?.toString()) || {};
    return selectedData.name;
  };

  getSelectedPraticeKey = (list, value) => {
    const selectedData =
      list?.find((val) => val.practice_id.toString() === value?.toString()) ||
      {};

    return selectedData?.name;
  };

  handleCustomDropDownForPraticeKey = (value, name) => {
    const eventObject = {
      target: {
        value: value.practice_id.toString(),
        name: name,
      },
    };
    this.setState({ sikkaPracticeName: value?.name });

    return eventObject;
  };

  handleCustomDropDown = (value, name) => {
    const eventObject = {
      target: {
        value: value.id.toString(),
        name: name,
      },
    };

    return eventObject;
  };

  handlePracticeKeyTooltip = (masterTooltip, clickableText) => {
    const translatedText = masterTooltip
      .split("Click here")
      .map((part, index, array) =>
        index === array.length - 1 ? (
          part
        ) : (
          <React.Fragment key={index}>
            {part}
            <a
              href={constants?.sikkaMiraxisUrl}
              target="_blank"
              rel="noreferrer"
              className={`${styles["practice_tooltip_redirection"]}`}
            >
              {clickableText}
            </a>
          </React.Fragment>
        )
      );
    return translatedText;
  };

  handlePracticeKeyTooltipforAdd = (addMasterTooltip, clickableText) => {
    const translatedText = addMasterTooltip
      .split("linkUrl")
      .map((part, index, array) =>
        index === array.length - 1 ? (
          part
        ) : (
          <React.Fragment key={index}>
            {part}
            <a
              href={constants?.sikkaMiraxisUrl}
              target="_blank"
              rel="noreferrer"
              className={`${styles["practice_add_tooltip_redirection"]}`}
            >
              {clickableText}
            </a>
          </React.Fragment>
        )
      );
    return translatedText;
  };

  async syncPatientsAndDoctors(officeId) {
    try {
      await getSyncedDoctorAndPatient(officeId);
      this.setState({ isSubmitConfirmed: false });
      this.props.history.push("/Offices");
    } catch (err) {
      console.error(err?.message);
    }
  }

  handleSaveOffice = (syncLater) => {
    if (syncLater) {
      this.setState({ isSubmitConfirmed: false });
      this.props.history.push("/Offices");
    } else {
      const {
        officeDetail: { id },
        statusMessage,
      } = this.props;
      this.syncPatientsAndDoctors(id || statusMessage?.data);
    }

    this.setState({ isSubmitConfirmed: false });
  };
  render() {
    const {
      officeName,
      officeAddress,
      contactNumber,
      country,
      province,
      city,
      postalCode,
      errors,
      cityList,
      isToastView,
      officeImage,
      showMapPredictions,
      googleSearchListNearBy,
      showCountryToaster,
      isConnectedWithSikka,
      tooltipMasterCustomerId,
      tooltipWhatsThis,
      sikkaOfficeId,
      sikkaPracticeId,
      showPraticeField,
      practiceKeyList,
      canDisablePmsDetails,
      canShowConfirmationOnToggleOffPms,
      pmsToggleOffConfirmationCounter,
    } = this.state;
    const {
      statusMessage,
      provienceList,
      isAddedError,
      countryList,
      PaymentstatusMessage,
      isPaymentDetailError,
      cardstatusMessage,
      isLoadError,
      t,
    } = this.props;

    const isBillingPreferenceType2 =
      this.props.profile &&
      this.props.profile.billingPreferenceType &&
      this.props.profile.billingPreferenceType === 2;

    let selectedCountry = countryList
      ?.find((e) => e.id == country)
      ?.name?.slice(0, 2);

    return (
      <>
        <div className="add-office-block add-staff-block">
          {this.state.blobLoader && <Loader />}
          {isToastView && statusMessage?.message && (
            <Toast
              message={statusMessage?.message}
              handleClose={this.toastHide}
              errorToast={isAddedError ? true : false}
            />
          )}
          {isToastView && PaymentstatusMessage && (
            <Toast
              message={PaymentstatusMessage}
              handleClose={this.toastHide}
              errorToast={isPaymentDetailError ? true : false}
            />
          )}

          {isToastView &&
            !this.props.statusMessage?.message &&
            cardstatusMessage && (
              <Toast
                message={cardstatusMessage}
                handleClose={this.toastHide}
                errorToast={isLoadError ? true : false}
              />
            )}

          <div className="container">
            <button className="back-btn" onClick={() => this.goBack()}>
              <Link to="">
                <span className="ico">
                  <img
                    src={require("assets/images/arrow-back-icon.svg").default}
                    alt="arrow"
                  />
                </span>
                {t("back")}
              </Link>
            </button>
          </div>

          <div className="mx-auto container container-smd">
            <div className="row no-gutters align-items-center mt-2">
              <div className="col-md-12">
                <h2 className="title">
                  {this.props.location.pathname !== "/AddOffice"
                    ? this.state.paymentDetailsTab
                      ? t("accountOwner.editCardDetail", {
                        officeName: officeName,
                      })
                      : t("accountOwner.editOffice")
                    : t("accountOwner.addOffice")}
                </h2>
                {this.props.location?.state?.showSubHeading && (
                  <p className="sub-title">
                    {t("pmsMembers.editOfficeSubHeading")}
                  </p>
                )}
              </div>
            </div>
            <div className="form-wrapper add-office-wrapper">
              <div className="common-tabs">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div
                      className={
                        this.props.profile &&
                          this.props.profile.billingPreferenceType === 2
                          ? "tabs-payment-block"
                          : "tabs-block"
                      }
                    >
                      <h4
                        className={`${this.state.officeDetailTab ? "active" : ""
                          }`}
                        onClick={this.officeTabShow}
                      >
                        {t("accountOwner.officeDetails")}
                      </h4>
                      <h4
                        className={`${this.state.brandTab ? "active" : ""}`}
                        onClick={this.brandTabShow}
                      >
                        {t("officeBranding.branding")}
                      </h4>
                      {this.props.profile &&
                        this.props.profile.billingPreferenceType === 2 && (
                          <h4
                            className={`${this.state.paymentDetailsTab ? "active" : ""
                              }`}
                            onClick={this.paymentTabShow}
                          >
                            {t("accountOwner.paymentDetails")}
                          </h4>
                        )}
                    </div>

                    <div className="row no-gutters">
                      {/* Images */}
                      {this.state.officeDetailTab && (
                        <div className="col-lg-4 order-lg-last mx-auto mt-5 ">
                          <div className="file-upload-container">
                            <div className="file-upload-field">
                              <div className="office-image">
                                {officeImage ? (
                                  <img src={officeImage} alt="upload" />
                                ) : (
                                  <img
                                    src={
                                      require("assets/images/default-image.svg")
                                        .default
                                    }
                                    alt="upload"
                                  />
                                )}
                              </div>
                              <div className="ch-upload-button">
                                <input
                                  id="fileUpload-officeImage"
                                  type="file"
                                  onChange={(e) =>
                                    this.fileChange(e, "officeImage")
                                  }
                                />
                                <span>
                                  <img
                                    src={
                                      require("assets/images/upload-image.svg")
                                        .default
                                    }
                                    alt="upload"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="upload-help-text">
                              {t("accountOwner.uploadOfficePictureDescription")}
                            </div>
                            {Object.keys(errors).length > 0 && (
                              <span className="error-msg text-center">
                                {errors.officeImage}{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {/* Images */}

                      <div
                        className={
                          this.state.officeDetailTab ? "w-100 w-md-50" : "col-lg-12"
                        }
                      >
                        <div className={"add-office-form"}
                          style={
                            this.state.paymentDetailsTab || this.state.brandTab
                              ? { borderRight: "none", marginRight: 0 }
                              : {}
                          }
                        >
                          {this.state.officeDetailTab && (
                            <div className="office-tab">
                              <Input
                                Title={t("form.fields.officeName")}
                                Type="text"
                                Placeholder={t("form.placeholder1", {
                                  field: t("form.fields.officeName"),
                                })}
                                Name={"officeName"}
                                HandleChange={this.InputChange}
                                Error={errors.officeName}
                                Value={officeName}
                              />
                              {countryList && countryList.length > 0 && (
                                <div className="custom-dropdown-only mt-4">
                                  <CustomSelect
                                    Title={t("form.fields.country")}
                                    options={countryList}
                                    id={"country"}
                                    dropdownClasses={"custom-select-scroll"}
                                    selectedOption={{
                                      name: this.getSelectedOption(
                                        countryList,
                                        country
                                      ),
                                    }}
                                    selectOption={(value) => {
                                      const eventObj =
                                        this.handleCustomDropDown(
                                          value,
                                          "country"
                                        );
                                      this.handleCountry(eventObj);
                                    }}
                                  />
                                </div>
                              )}
                              {errors.country && (
                                <span className="error-msg">
                                  {errors.country}
                                </span>
                              )}
                              {showCountryToaster && (
                                <Alert
                                  color="warning"
                                  className="event-alert-box"
                                >
                                  {constants.curreny.CAD ===
                                    this.props?.profile?.countryId
                                    ? t("accountOwner.toastSectionOfUs")
                                    : t("accountOwner.toastSectionOfCad")}
                                </Alert>
                              )}
                              <div
                                className={
                                  "text-right " + styles["show-on-map"]
                                }
                                onClick={this.openModal}
                              >
                                <span className="link-btn">
                                  {t("accountOwner.showOnMap")}
                                </span>
                              </div>
                              <div className={styles["location-input-wrapper"]}>
                                <CustomInput
                                  Title={t("form.fields.officeAddress")}
                                  Type="text"
                                  Placeholder={t("form.placeholder1", {
                                    field: t("form.fields.officeAddress"),
                                  })}
                                  Name={"officeAddress"}
                                  autoComplete={"off"}
                                  onBlurChange={() => {
                                    if (
                                      this.state.officeAddressEdit !==
                                      this.state.officeAddress
                                    ) {
                                      setTimeout(() => {
                                        this.setState({
                                          placeId: null,
                                          selectedPlacedId: "",
                                          markerPosition: "",
                                          lat: 0,
                                          long: 0,
                                        });
                                      }, 200);
                                    }
                                  }}
                                  HandleChange={(e) => {
                                    this.setState({
                                      isProps: false,
                                      officeAddress: e.target.value,
                                    });

                                    if (!showMapPredictions) {
                                      this.setState({
                                        showMapPredictions: true,
                                      });
                                    }
                                    this.props.googleServices.getPlacePredictions(
                                      {
                                        input: e.target.value,
                                        componentRestrictions: {
                                          country: selectedCountry ?? "",
                                        },
                                      }
                                    );
                                  }
                                  }
                                  Error={errors?.officeAddress}
                                  Value={officeAddress}
                                  Classes="mt-4"
                                />
                                <ul
                                  className={
                                    styles["location-list"] +
                                    `${!showMapPredictions ? "d-none" : ""}`
                                  }
                                >
                                  {showMapPredictions &&
                                    this.placePredictionContent()}
                                </ul>
                              </div>

                              {provienceList && provienceList.length > 0 && (
                                <div className="custom-dropdown-only">
                                  <CustomSelect
                                    Title={t("form.fields.provinceOrState")}
                                    options={provienceList}
                                    id={"province"}
                                    dropdownClasses={"custom-select-scroll"}
                                    selectedOption={{
                                      name: this.getSelectedOption(
                                        provienceList,
                                        province
                                      ),
                                    }}
                                    selectOption={(value) => {
                                      const eventObj =
                                        this.handleCustomDropDown(
                                          value,
                                          "province"
                                        );
                                      this.handleProvience(eventObj);
                                    }}
                                  />
                                </div>
                              )}
                              {errors.province && (
                                <span className="error-msg">
                                  {errors.province}
                                </span>
                              )}

                              <div className="row">
                                <div className="col-md-6 custom-dropdown-only">
                                  <CustomSelect
                                    Title={t("form.fields.city")}
                                    options={cityList}
                                    id={"city"}
                                    dropdownClasses={"custom-select-scroll"}
                                    selectedOption={{
                                      name: this.getSelectedOption(
                                        cityList,
                                        city
                                      ),
                                    }}
                                    selectOption={(value) => {
                                      const eventObj =
                                        this.handleCustomDropDown(
                                          value,
                                          "city"
                                        );
                                      this.InputChange(eventObj);
                                    }}
                                  />
                                  {errors.city && (
                                    <span className="error-msg">
                                      {errors.city}
                                    </span>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <Input
                                    Title={t("form.fields.postalCode")}
                                    Classes="c-field-lg-none"
                                    Type="text"
                                    Placeholder={t("form.placeholder1", {
                                      field: t("form.fields.postalCode"),
                                    })}
                                    Name={"postalCode"}
                                    HandleChange={this.InputChange}
                                    Error={errors.postalCode}
                                    Value={postalCode}
                                  />
                                </div>

                                <div className="col-md-12">
                                  <Input
                                    Title={t("form.fields.contactNumber")}
                                    MaxLength={15}
                                    Type="text"
                                    Placeholder={t("form.placeholder1", {
                                      field: t("form.fields.contactNumber"),
                                    })}
                                    Name={"contactNumber"}
                                    HandleChange={this.InputChange}
                                    Error={errors.contactNumber}
                                    Value={contactNumber}
                                  />
                                </div>

                                {((googleSearchListNearBy?.length > 0 &&
                                  this.state.placeId) ||
                                  (googleSearchListNearBy?.length > 0 &&
                                    this.state.selectedPlacedId)) && (
                                    <div className="col-md-12">
                                      <div className="c-field">
                                        <Fragment>
                                          <label className="mb-2">
                                            {t(
                                              "accountOwner.addOfficeBottomDesc"
                                            )}
                                          </label>
                                          <ul
                                            className={
                                              styles["google-radio-list"]
                                            }
                                          >
                                            {this.nearByLocationList()}
                                          </ul>
                                        </Fragment>
                                      </div>
                                    </div>
                                  )}

                                {errors?.selectGooglePredection && (
                                  <span className="error-msg">
                                    {errors.selectGooglePredection}
                                  </span>
                                )}

                                <div className="d-flex align-items-center">
                                  <ToggleSwitch
                                    className="mt-0"
                                    label="bill-me-later-toggle"
                                    value={isConnectedWithSikka}
                                    onChange={() => {
                                      if (
                                        canShowConfirmationOnToggleOffPms &&
                                        isConnectedWithSikka &&
                                        pmsToggleOffConfirmationCounter === 0
                                      ) {
                                        this.setState({
                                          canShowPmsConfirmationModal: true,
                                        });
                                        return;
                                      }

                                      this.setState({
                                        isConnectedWithSikka:
                                          !isConnectedWithSikka,
                                        errors: {
                                          ...errors,
                                          sikkaPracticeId: null,
                                        },
                                      });
                                    }}
                                  />
                                  <Text
                                    size="13px"
                                    marginBottom="0px"
                                    weight="400"
                                    className="mr-2 mt-2"
                                    color="#79869A"
                                  >
                                    {t("pmsMembers.connectPms")}
                                  </Text>
                                  <Tooltip
                                    className="new-item-card-catalogue-tooltip"
                                    isOpen={tooltipWhatsThis}
                                    placement="top"
                                    target="PmsConnectWhatsThisTooltip"
                                    toggle={() => {
                                      this.setState({
                                        tooltipWhatsThis: !tooltipWhatsThis,
                                      });
                                    }}
                                  >
                                    {t("pmsMembers.whatsThisTooltip")}
                                  </Tooltip>
                                  <span
                                    id="PmsConnectWhatsThisTooltip"
                                    className={`mt-2 ${styles["staff_whats_this_text"]}`}
                                  >
                                    <u>{t("pmsMembers.whatsThis")}</u>
                                  </span>
                                </div>

                                {isConnectedWithSikka && (
                                  <>
                                    <div
                                      className={`col-md-12 mt-4 ${styles["staff_yellow_heading"]}`}
                                    >
                                      <span
                                        className={`${styles["staff_alert_text"]}`}
                                      >
                                        {t("pmsMembers.verifyIndicator")}
                                      </span>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                      <div>
                                        <Input
                                          Title={t("pmsMembers.sikkaOfficeId")}
                                          MaxLength={15}
                                          Type="text"
                                          Placeholder={t("form.placeholder1", {
                                            field: t(
                                              "pmsMembers.sikkaOfficeId"
                                            ),
                                          })}
                                          Disabled={
                                            showPraticeField &&
                                            isConnectedWithSikka
                                          }
                                          Name={"sikkaOfficeId"}
                                          HandleChange={this.InputChange}
                                          Error={errors.sikkaOfficeId}
                                          Value={sikkaOfficeId || ""}
                                        />
                                        <img
                                          className={`${styles["staff_tooltip"]}`}
                                          onClick={() => {
                                            this.setState({
                                              tooltipMasterCustomerId:
                                                !tooltipMasterCustomerId,
                                            });
                                          }}
                                          id="TooltipMasterCustomerId"
                                          src={
                                            require("assets/images/info_black-tooltip.svg")
                                              .default
                                          }
                                          alt="icon"
                                        />
                                      </div>
                                      <Tooltip
                                        className="new-item-card-catalogue-tooltip"
                                        isOpen={tooltipMasterCustomerId}
                                        placement="top"
                                        autohide={false}
                                        target="TooltipMasterCustomerId"
                                        toggle={() => {
                                          this.setState({
                                            tooltipMasterCustomerId:
                                              !tooltipMasterCustomerId,
                                          });
                                        }}
                                      >
                                        {this.props.location.pathname !==
                                          "/AddOffice" && this.state.isProps
                                          ? this.handlePracticeKeyTooltip(
                                            t(
                                              "pmsMembers.masterCustomerIdTooltip"
                                            ),
                                            t("clickHere")
                                          )
                                          : this.handlePracticeKeyTooltipforAdd(
                                            t(
                                              "pmsMembers.masterTooltipForAdd"
                                            ),
                                            t(
                                              "pmsMembers.sikkaMiraxisTooltipUrl"
                                            )
                                          )}
                                      </Tooltip>
                                      {showPraticeField &&
                                        isConnectedWithSikka ? (
                                        <div
                                          className={`${styles["staff_verified_btn"]}`}
                                        >
                                          <img
                                            src={VerifiedIcon}
                                            alt="VerifiedIcon"
                                          />
                                          <span
                                            className={`${styles["verified_text"]}`}
                                          >
                                            {t("pmsMembers.verified")}
                                          </span>
                                        </div>
                                      ) : (
                                        <span
                                          className={`${!sikkaOfficeId?.trim()?.length
                                            ? "disable-btns"
                                            : "cursor-pointer"
                                            } ${styles["staff_verify_btn"]}`}
                                          onClick={() => {
                                            this.getPraticeListing(
                                              sikkaOfficeId
                                            );
                                          }}
                                        >
                                          {t("pmsMembers.verify")}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                )}

                                {showPraticeField && isConnectedWithSikka && (
                                  <div
                                    className={`custom-dropdown-only col-md-12 ${canDisablePmsDetails ? "disable-btns" : ""
                                      } ${styles["staff_custom_dropdown_only"]}`}
                                  >
                                    <CustomSelect
                                      Title={t("pmsMembers.sikkaPracticeId")}
                                      options={practiceKeyList}
                                      id={"sikkaPracticeId"}
                                      dropdownClasses={"custom-select-scroll"}
                                      showAddress={true}
                                      selectedOption={{
                                        name: this.getSelectedPraticeKey(
                                          practiceKeyList,
                                          sikkaPracticeId
                                        ),
                                      }}
                                      selectOption={(value) => {
                                        const eventObj =
                                          this.handleCustomDropDownForPraticeKey(
                                            value,
                                            "sikkaPracticeId"
                                          );
                                        this.handlePracticeKey(eventObj);
                                      }}
                                    />
                                  </div>
                                )}
                                {errors.sikkaPracticeId && (
                                  <span className="error-msg">
                                    {errors.sikkaPracticeId}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          {this.state.paymentDetailsTab && (
                            <div className="payment-detail-tab">
                              <PaymentDetails
                                Errors={errors}
                                CardSelect={this.handleCardSelect}
                                CardId={this.state.cardId}
                              />
                            </div>
                          )}

                          {this.state.brandTab && (
                            <div className="payment-detail-tab">
                              <Branding
                                brandingValues={this.state.branding}
                                officeLogo={this.state.officeLogo}
                                errors={errors}
                                InputChange={this.HandleBrandingInputChange}
                                handleLogoChange={(e) => {
                                  this.fileChange(e, "officeLogo");
                                }}
                                handleCheckboxChange={this.handleCheckboxChange}
                                handlePlaceSelect={this.handleBrandingAddrees}
                                selectedCountry={selectedCountry}
                              />
                            </div>
                          )}

                          <div
                            className={`${isConnectedWithSikka ? "mt-4" : "btn-field mt-4"
                              }`}
                          >
                            <div className="row gutters-12">
                              <div className="col-md-auto">
                                {this.state.officeDetailTab && (
                                  <button
                                    className="button button-round button-shadow"
                                    title={t("continue")}
                                    onClick={() =>
                                      this.handleAddofficeContinue("officeTab")
                                    }
                                  >
                                    {t("continue")}
                                  </button>
                                )}
                                {this.state.brandTab && (
                                  <button
                                    className="button button-round button-shadow"
                                    disabled={
                                      (!isBillingPreferenceType2 &&
                                        this.props.location.pathname !==
                                        "/AddOffice" &&
                                        this.shouldDisableUpdateButton()) ||
                                      isToastView
                                    }
                                    title={t("accountOwner.saveOffice")}
                                    onClick={() =>
                                      isBillingPreferenceType2
                                        ? this.handleAddofficeContinue(
                                          "brandingTab"
                                        )
                                        : this.handleAddoffice()
                                    }
                                  >
                                    {isBillingPreferenceType2
                                      ? t("continue")
                                      : t("accountOwner.saveOffice")}
                                  </button>
                                )}
                                {isBillingPreferenceType2 &&
                                  this.state.paymentDetailsTab && (
                                    <button
                                      className="button button-round button-shadow"
                                      disabled={
                                        this.props.location.pathname !==
                                        "/AddOffice" &&
                                        this.shouldDisableUpdateButton()
                                      }
                                      title={t("accountOwner.createOffice")}
                                      onClick={() =>
                                        this.handleAddofficeContinue(
                                          "paymentTab"
                                        )
                                      }
                                    >
                                      {this.props.location.pathname !==
                                        "/AddOffice"
                                        ? t("save")
                                        : t("accountOwner.createOffice")}
                                    </button>
                                  )}
                              </div>
                              <div className="col-md-auto">
                                <button
                                  onClick={() => this.goBack()}
                                  className="button button-round button-border button-dark"
                                  title={t("cancel")}
                                >
                                  {t("cancel")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tabId="2"> Payment tab</TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>

        {this.state.isMapViewModalOpen && (
          <MapViewModal
            isOpen={this.state.isMapViewModalOpen}
            setIsMapViewModalOpen={this.closeModal}
            markerPosition={this.state.markerPosition}
            updateMarkerPosition={(position) => {
              this.setState({
                isProps: false,
                markerPosition: {
                  lat: position.lat(),
                  lng: position.lng(),
                },
              });
            }}
            handlePlaceSelect={this.handlePlaceSelect}
            getLocationByAddress={this.getLocationByAddress}
            selectedCountry={countryList
              ?.find((e) => e.id == country)
              ?.name?.slice(0, 2)}
            googleServices={this.props.googleServices}
            officeAddress={this.state.officeAddress}
            placeId={this.state.placeId}
            showMapPredictions={(e) =>
              this.setState({ showMapPredictions: false })
            }
          />
        )}

        {this.state.verifyModal && (
          <FamilyModal
            isFamilyModalOpen={this.state.verifyModal}
            setIsFamilyModalOpen={this.closeVerifyModal}
            title={t("pmsMembers.verifyDetail")}
            subTitle1={t("pmsMembers.verifyModalMsg", {
              officeName: this.state.officeName,
            })}
            leftBtnText={t("confirm")}
            rightBtnText={t("cancel")}
            onConfirm={() => this.confirmVerifyRequest()}
          />
        )}

        {this.state.isSubmitConfirmed && (
          <FamilyModal
            isFamilyModalOpen={this.state.isSubmitConfirmed}
            closeOnCross={() => {
              this.setState({ isSubmitConfirmed: true });
            }}
            setIsFamilyModalOpen={() => {
              this.handleSaveOffice(true);
            }}
            title={t("pmsMembers.successfulIntegrated", {
              pmsName: this.state.practiceManagementSystemName,
            })}
            subTitle1={t("pmsMembers.modalContent", {
              pmsName: this.state.practiceManagementSystemName,
            })}
            subTitle2={t("pmsMembers.modalSubContent", {
              pmsName: this.state.practiceManagementSystemName,
            })}
            hideCrossIcon={true}
            note={t("pmsMembers.pmsNote")}
            leftBtnText={t("pmsMembers.syncNow")}
            rightBtnText={t("pmsMembers.skipNowForSync")}
            onConfirm={() => {
              this.handleSaveOffice(false);
            }}
          />
        )}

        {this.state.canShowPmsConfirmationModal && (
          <FamilyModal
            isFamilyModalOpen={this.state.canShowPmsConfirmationModal}
            setIsFamilyModalOpen={() => {
              this.setState({ canShowPmsConfirmationModal: false });
            }}
            title={t("pmsMembers.disableModalHeading")}
            subTitle1={t("pmsMembers.disableModalContent")}
            leftBtnText={t("confirm")}
            rightBtnText={t("cancel")}
            onConfirm={() => {
              this.setState({
                canShowPmsConfirmationModal: false,
                isConnectedWithSikka: false,
                canShowConfirmationOnToggleOffPms: false,
                pmsToggleOffConfirmationCounter: 1,
              });
            }}
          />
        )}

        {this.state.infoModal && (
          <InfoModal
            isOpen={this.state.infoModal}
            setIsOpen={this.closeInfoModal}
            title={t("pmsMembers.contactSupport")}
            content={t("pmsMembers.infoModalContent")}
            subContent={t("pmsMembers.infoModalSubContent")}
            contactEmail={"info@miraxis.com"}
            contactNumber={"1 (877) 747-3615"}
            btnText={t("accountOwner.okayGotIt")}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  userProfile: { profile, sasToken },
  offices: {
    statusMessage,
    isLoading,
    isOfficeAdd,
    officeDetail,
    provienceList,
    isAddedError,
    countryList,
    cityList,
  },
  sub: {
    PaymentstatusMessage,
    isPaymentDetailError,
    cardstatusMessage,
    isLoadError,
    cardSaved,
  },
  errors: { isError },
}) => ({
  statusMessage,
  isLoading,
  isError,
  isOfficeAdd,
  officeDetail,
  provienceList,
  isAddedError,
  profile,
  countryList,
  cityList,
  sasToken,
  PaymentstatusMessage,
  isPaymentDetailError,
  cardstatusMessage,
  isLoadError,
  cardSaved,
});

export default connect(mapStateToProps, {
  addOffice,
  officeFieldData,
  getOfficesProvience,
  editOffice,
  getSastoken,
  getOfficesCountry,
  getOfficesCity,
  getOfficesDetail,
})(_isLoading(withTranslation()(withGoogleAutoSuggestion(AddOffice))));
