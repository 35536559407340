import Page from 'components/Page'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom/cjs/react-router-dom";
import Text from 'components/Text'
import { Button, Card, Modal, ModalBody } from 'reactstrap';
import crossImg from "../../../../../assets/images/cross-mark-button.svg"
import styles from "../../Appointments.module.scss"
import ReactDatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';

const EditRecall = ({ t }) => {

    const history = useHistory();
    const goBack = () => {
        history.push(
            '/appointments'
        );
    };

    const [modalOpen, setModalOpen] = useState(false);

    const ShowDate = () => {
        const [startDate, setStartDate] = useState(new Date());
        return (
            <ReactDatePicker selected={startDate} dateFormat="MMM d, yyyy" onChange={(date) => setStartDate(date)} className="c-form-control" popperPlacement="bottom" />
        );
    };



    const RadioBtn = ({ name, customClass }) => (
        <div className={` ${customClass}`}>
            <div className='d-flex mb-4'>
                <div className="ch-radio">
                    <label className="mr-5 pb-0 pt-1">
                        <input type="radio" name="blockTimeslotAallDoctors" />
                        <span> {name} </span>
                    </label>
                </div>
            </div>
        </div>
    )

    return (
        <Page onBack={goBack}>
            <Text size="25px" weight="500" color="#111B45" marginBottom="30px"> Edit Recall</Text>
            <Card className='form-wrapper'>
                <div className={styles['heading-data-Wrapper'] + " " + "w-50 mt-0"}>
                    <div className={styles['head-link-wrapper']}>
                        <div className={styles['appointment-head']}>Next Recall</div>
                        <button className={styles["appointment-link"]} onClick={() => setModalOpen(!modalOpen)}>Edit</button>
                    </div>
                    <div className={styles['appointment-text']}> Recurring - 1 Week After Completed Appt.</div>
                </div>

                <div className={styles['heading-data-Wrapper'] + " " + "w-50"}>
                    <div className={styles['head-link-wrapper']}>
                        <div className={styles['appointment-head']}>Reminder Plan</div>
                        <Link className={styles["appointment-link"]} to="/past-patient-reminder-plan">Select Reminder Plan</Link>
                    </div>
                    <div className={styles['appointment-text']}> No type of reminder selected</div>
                </div>
            </Card>

            <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} centered={true} className={"modal-dialog-centered modal-width-660 "} modalClassName='custom-modal'>
                <Button className={styles['PatientAppointmentDetails-cross-btn']} aria-label="Close" type="button" onClick={() => setModalOpen(!modalOpen)}>
                    <img src={crossImg} alt="cross button" />
                </Button>
                <Text size="25px" weight="500" color="#111B45" marginBottom="30px">Next Recall</Text>
                <ModalBody>
                    <RadioBtn name="None" />
                    <RadioBtn name="1 Week After Completed Appt." />
                    <RadioBtn name="2 Weeks After" />
                    <RadioBtn name="3 Weeks After" />
                    <RadioBtn name="1 Month After" />
                    <RadioBtn name="2 Months After" />
                    <RadioBtn name="3 Months After" />
                    <RadioBtn name="Custom Date" />
                    <div className="c-field mb-0 ml-4">
                        <label>Date</label>
                        <div className="d-flex inputdate">
                            <ShowDate />
                        </div>
                    </div>
                    <div className="ch-checkbox c-field all-event-checkbox mt-4">
                        <label>
                            <input type="checkbox" />
                            <span>Make it Recurring</span>
                        </label>
                    </div>
                </ModalBody>
                <div className={styles["common-appointment-btn-container"]}>
                    <Button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100">Apply</Button>
                    <Button className="button button-round button-border button-dark btn-mobile-link " onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
                </div>
            </Modal>
        </Page>
    )
}
export default withTranslation()(EditRecall)