import React, { useState } from "react";
import styles from "../TaskManagement.module.scss";
import { motion } from "framer-motion";
import { withTranslation } from "react-i18next";
import { useStaffTaskList } from "repositories/task-management-repository";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";
import HeaderFilter from "../Filters/HeaderFilter";
import FilterTasks from "../Filters"
import SearchBar from "../Filters/Searchbar";
import useStaffTaskModule from "../hooks/useStaffTaskModule";
import TaskManagementStaffCard from "./TaskManagementStaffCard";
import Empty from "components/Empty";
import { cacheSideBarActive } from "utils";

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const getButtonClass = (status) => {
    const statusClasses = {
        Pending: styles.Pending,
        Cancelled: styles.Cancelled,
        Started: styles.Started,
        Accepted: styles.Accepted,
        Rejected: styles.Rejected,
        "In Progress": styles.InProgress,
        Completed: styles.Completed
    };

    return `${styles.task_management_card_btn} ${statusClasses[status] || ""}`;
};

const StaffTasks = ({ t, isArchive, isStaff, accountOwnerId }) => {
    const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

    const handleSidebarToggle = () => {
        setSidebarActive(!isSidebarActive);
        localStorage.setItem("isSidebarActive", !isSidebarActive);
    };

    const {
        isLoading,
        tasks,
        totalItems,
        searchValue,
        officeFilter,
        staffFilter,
        statusFilter,
        priorityFilter,
        isFilterApply,
        setPageNumber,
        handleSearchTerm,
        handleToggleAssigne,
        handleActiontask,
        setOfficeFilter,
        setStaffFilter,
        setStatusFilter,
        setPriorityFilter,
        resetFilter,
        handleApplyFilters,
        handleArchiveApi,
        setSortBy,
        sortBy,
        priority,
        status,
        setStatus,
        setPriority,
    } = useStaffTaskModule(useStaffTaskList, isArchive);

    let items = <div className={styles["task_management_card_grid"]}>
        {tasks?.map((task, index) => {
            return (
                <TaskManagementStaffCard
                    className={!isSidebarActive ? styles.task_management_status_card_active : styles.task_management_status_card_not_active}
                    key={index}
                    myTask={task}
                    handleToggleAssigne={handleToggleAssigne}
                    getButtonClass={getButtonClass}
                    handleActiontask={handleActiontask}
                    handleArchiveApi={handleArchiveApi}
                    isArchive={isArchive}
                    accountOwnerId={accountOwnerId}
                />
            )
        })}
    </div>
    return (
        <>
            {isLoading && <Loader />}
            <div className={styles["dropdown_radio_search_wrapper"]}>
                <HeaderFilter
                 setSortBy={setSortBy}
                 sortBy={sortBy}
                 priority ={priority}
                 setPriority ={setPriority}
                 status={status}
                 setStatus ={setStatus}
                />
                <SearchBar searchValue={searchValue} handleSearchTerm={handleSearchTerm} t={t} />
            </div>
            <div className="scheduler-tabs-main-wrapper">
                <FilterTasks
                    t={t}
                    isSidebarActive={isSidebarActive}
                    handleSidebarToggle={handleSidebarToggle}
                    resetFilter={resetFilter}
                    officeFilter={officeFilter}
                    setOfficeFilter={setOfficeFilter}
                    handleApplyFilters={handleApplyFilters}
                    staffFilter={staffFilter}
                    setStaffFilter={setStaffFilter}
                    statusFilter={statusFilter}
                    priorityFilter={priorityFilter}
                    setStatusFilter={setStatusFilter}
                    setPriorityFilter={setPriorityFilter}
                    isFilterApply={isFilterApply}
                    isStaff={isStaff}
                />
                {tasks?.length === 0 ? (<div className={styles["not_found"]}>
                    <Empty Message={t('taskManagement.taskNotFound')} />
                </div>) :
                    (<motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                        <InfiniteScroll
                            className="w-100"
                            dataLength={tasks.length}
                            hasMore={tasks.length < totalItems}
                            next={() => setPageNumber((v) => v + 1)}
                        >
                            {items}
                        </InfiniteScroll>
                    </motion.div>)
                }
            </div>
        </>
    );
};
export default withTranslation()(StaffTasks);
