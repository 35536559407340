import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import { Card } from "reactstrap";
import Table from "components/table";
import constants from "./../../../../../../constants";
import Text from 'components/Text';
import ReactDatePicker from 'react-datepicker';
import moment from "moment";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import styles from "../../../Appointments.module.scss";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { updateLinkToNextRecall, useAppointmentDetail, useAppointmentRecallList } from 'repositories/patient-appointment-repository';
import { decodeId, formatUTCDateTime } from 'utils';
import Loader from 'components/Loader';
import {AppointmentsContext} from 'accountOwner/pages/Appointments/AppointmentsContext';
import Empty from 'components/Empty';
import toast from 'react-hot-toast';

let pageSize = 60;
const defaultDate = {
    from: moment().toDate(),
    to: moment().endOf("year").toDate(),
}

const defaultUtcDate = {
    from: moment().utc().format('YYYY-MM-DD'),
    to: moment().endOf("year").utc().format('YYYY-MM-DD')
}
const NextRecallTime = ({ t }) => {
	const history = useHistory();
	let { appointmentId } = useParams();
	const decodeAppointmentId = parseInt(decodeId(appointmentId));
	const [pageNumber, setPageNumber] = useState(1);
	const [dates, setdates] = useState(defaultDate);
    const [utcdates, setUtcdates] = useState(defaultUtcDate);
	const [showLoader, setShowLoader] = useState(false);
	const location = useLocation();
	const {state} = location
	const {
		selectedNextRecall,
        setSelectedNextRecall
    } = useContext(AppointmentsContext);

	const [selectedRadio, setSelectedRadio] = useState(selectedNextRecall);
	
	const { data : RecallData, isLoading } = useAppointmentRecallList(utcdates.from, utcdates.to, decodeAppointmentId, pageNumber, pageSize);
	const { isLoading: appointmentLoading, data: appointmentDetail } = useAppointmentDetail(
        decodeAppointmentId
    );
	const goBack = () => {
		if(state?.isFromRecall) {
			history.push(constants.routes.appointment.recall.replace(':appointmentId', appointmentId))
		} else {
            history.push(constants.routes.appointment.createRecallPatientAppointment.replace(':appointmentId', appointmentId))
		}	
};
	
	const updateDatesValues = (e, type) => {
		setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }))
        setUtcdates(prevState => ({
            ...prevState,
            from: type === 'from'
                ? moment.utc(e).local().format("YYYY-MM-DD")
                : prevState.from,
            to: type === 'to'
                ? moment.utc(e).local().format("YYYY-MM-DD")
                : prevState.to ,
        }));
	};

	useEffect(() => {
		if(state?.isFromRecall) {
			setSelectedRadio(appointmentDetail);
		}
	}, [appointmentLoading])


	const CalendarContainer = ({ children }) => {
		const el = document.getElementById("calendar-portal");

		return <Portal container={el}>{children}</Portal>;
	};

	const startDatePickerRef = useReadOnlyDateTextInput();
	const endDatePickerRef = useReadOnlyDateTextInput();

	const StartDate = () => {
		return (
			<ReactDatePicker dateFormat="dd-MM-yyyy"
				className="c-form-control"
				selected={dates.from}
				onChange={(e) => updateDatesValues(e, "from")}
				maxDate={dates.to}
				popperPlacement="bottom-start"
				popperContainer={CalendarContainer}
				ref={startDatePickerRef}
			/>
		);
	};
	const EndDate = () => {
		return (
			<ReactDatePicker dateFormat="dd-MM-yyyy"
				className="c-form-control"
				selected={dates.to}
				minDate={dates.from}
				onChange={(e) => updateDatesValues(e, "to")}
				popperPlacement="bottom-end"
				popperContainer={CalendarContainer}
				ref={endDatePickerRef} />
		);
	};

	const columns = [
		{
			attrs: { datatitle: t('patientAppointmentMembers.nextRecallHeading.originalAppointmentDate') },
			dataField: 'originalAppointmentDate',
			text: t('patientAppointmentMembers.nextRecallHeading.originalAppointmentDate'),
			formatter: (cellContent, row, rowIndex) => {
				const isSelected = row?.createdFromRecallId === selectedRadio?.createdFromRecallId;
				return (
				<div className='select-reason ch-radio d-flex flex-column'>
					<label>
						<input type="radio" name="blockTimeslotAallDoctors" checked={isSelected || false} onChange={() => setSelectedRadio(row)} />
						<span> <Text color='#535B5F' size='14px' weight='400'>{formatUTCDateTime(row?.originalAppointmentDate)}</Text> </span>
					</label>
				</div>

			)},
		},
		{
			attrs: { datatitle: t('patientAppointmentMembers.nextRecallHeading.recallDueDate') },
			dataField: 'recallDueDate',
			text: t('patientAppointmentMembers.nextRecallHeading.recallDueDate'),
			formatter: (cellContent, row, rowIndex) => (
				<span className={styles["appointment-table-patient-name"]}>{formatUTCDateTime(row?.recallDueDate)} </span>
			),
		},
		{
			attrs: { datatitle: t('patientAppointmentMembers.nextRecallHeading.recallType') },
			dataField: 'recallType',
			text: t('patientAppointmentMembers.nextRecallHeading.recallType'),
			formatter: (cellContent, row, rowIndex) => (
				<span className={styles["appointment-table-patient-name"]}>{row?.recallType?.title} </span>
			),
		},
	]

	  const processedData = RecallData?.items.map((item) => ({
		...item,
		isSelected: item?.createdFromRecallId === selectedRadio?.createdFromRecallId,
	  }));

	const appointmenLinkToNextRecall = async (params) => {
        setShowLoader(true);
        try {
			let params = {
					AppointmentId: decodeAppointmentId,
					RecallDate: selectedRadio?.recallDueDate || selectedRadio?.recallDate
			}
            let res = await updateLinkToNextRecall(params);
            if (res?.statusCode === 200) {
                setShowLoader(false);
                toast.success(res.message);
            }
            return res
        } catch (err) {
            setShowLoader(false);
            toast.error(err.message);
        }
    }

	const handleApply = () => {
		if(state?.isFromRecall) {
               appointmenLinkToNextRecall()
		} else {
			setSelectedNextRecall(selectedRadio);
		}
		goBack()
	}
	return (
		<Page onBack={goBack} className="main-page">
			 { showLoader || isLoading  && <Loader />}
			<div className='d-flex flex-column manage-schedule-heading'>
				<Text color='#111B45' weight='500' size='25px'>{t("patientAppointmentMembers.LinkToNextRecall")}</Text>
			</div>
			<Card className="form-wrapper p-3">
				<div className={styles["date-picker-container"]}>
					<div className="c-field mb-0">
						<label>From</label>
						<div className="d-flex inputdate">
							<StartDate />
						</div>
					</div>
					<div className="c-field mb-0">
						<label>To</label>
						<div className="d-flex inputdate">
							<EndDate />
						</div>
					</div>
				</div>
				<div className="test-table-box">
					{processedData?.length ? <Table
						keyField={selectedRadio?.id ? `table-${selectedRadio?.id}` : 'id'}
						data={processedData || []}
						columns={columns}
						bordered={false}
						handlePagination={setPageNumber}
						pageNumber={pageNumber}
						totalItems={RecallData?.items?.length || 100}
						pageSize={pageSize}
					/> : <Empty Message={"Not Availabel"} />}
				</div>
			</Card>
			<div className={styles["common-appointment-btn-container"]}>
				<button className='button button-round button-shadow mr-md-4 mb-3 w-sm-100'  onClick={handleApply}>
				{t("Save")}
				</button>
				<button className='button button-round button-border button-dark btn-mobile-link' onClick={goBack}>
				{t("Cancel")}
				</button>
			</div>

		</Page>
	);
};
export default withTranslation()(NextRecallTime);