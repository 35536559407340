import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../Dashboard.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CardLoader from "../components/CardLoader";
import TaskCard from "./TaskCard";
import useTaskModule from "accountOwner/pages/TaskManagement/hooks/useTaskModule";
import { useAllTasks } from "repositories/task-management-repository";
import AssigneesModal from "accountOwner/pages/TaskManagement/Modals/AssigneesModal";
import NotAccessible from "../components/NotAccessible";
import { LoaderIcon } from "react-hot-toast";

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const MyTasks = ({
  t,
  isArchive,
  appliedOfficeFilters,
  appliedStaffFilters,
  selectedOwner,
  handleRedirect,
  isSchedulerOnly,
}) => {
  const {
    tasks,
    isLoading,
    pageNumber,
    isAssigneeModal,
    assigneeList,
    isAccessible,
    totalPages,
    setPageNumber,
    setAppliedOfficeFilters,
    setAppliedStaffFilters,
    handleAction,
    deleteAction,
    handleArchiveApi,
    handleToggleAssigne,
  } = useTaskModule(useAllTasks, isArchive, true);

  let items = (
    <div>
      {tasks?.map((task) => {
        return (
          <TaskCard
            myTask={task}
            key={task.id}
            handleRedirect={handleRedirect}
            handleAction={handleAction}
            deleteAction={deleteAction}
            handleArchiveApi={handleArchiveApi}
            handleToggleAssigne={handleToggleAssigne}
            isArchive={isArchive}
          />
        );
      })}
    </div>
  );

  useEffect(() => {
    if (!isSchedulerOnly) {
      setAppliedOfficeFilters(appliedOfficeFilters);
      setAppliedStaffFilters(appliedStaffFilters);
      setPageNumber(1);
    }
  }, [
    appliedOfficeFilters,
    appliedStaffFilters,
    selectedOwner,
    isSchedulerOnly,
  ]);

  if (!isAccessible) {
    return (
      <div className={styles["not_found"]}>
        <NotAccessible Message={t("dashBoard.notAccessible")} />
      </div>
    );
  }

  return (
    <div
      className={`${styles.my_task_container} 
      ${isLoading ? styles.my_task_loading : ""} 
      ${!tasks.length && isAccessible ? styles["no_tasks"] : ""}`}
    >
      {isLoading && pageNumber === 1 ? (
        <LoaderIcon className={styles.loader_style} />
      ) : (
        <>
          {!tasks.length && isAccessible ? (
            <div className={styles["not_found"]}>
              <NotAccessible
                isNotFound={true}
                Message={t("taskManagement.taskNotFound")}
              />
            </div>
          ) : (
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
              className="w-100"
            >
              <InfiniteScroll
                dataLength={tasks.length}
                hasMore={pageNumber < totalPages}
                loader={<LoaderIcon className={styles.loader_style} />}
                next={() => {
                  pageNumber < totalPages && setPageNumber((prev) => prev + 1);
                }}
                scrollableTarget="scrollableDivTwo"
              >
                {items}
              </InfiniteScroll>
            </motion.div>
          )}
        </>
      )}
      {isAssigneeModal && (
        <AssigneesModal
          isModalOpen={isAssigneeModal}
          closeModal={handleToggleAssigne}
          assignees={assigneeList}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
export default withTranslation()(MyTasks);
