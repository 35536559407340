import React from 'react'
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../Notices.module.scss"
import Button from 'components/Button';
import { withTranslation } from 'react-i18next';
import Text from 'components/Text';

const NoticeAlert = ({ t, isModalOpen, closeModal, handleSubmit }) => {
    return (
        <Modal
            isOpen={isModalOpen}
            toggle={closeModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            <span className="close-btn" onClick={closeModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles.alert_popup_content_wrapper}>
                    <h2 className="page-title">{t("notices.noticeAlert")}</h2>
                    <Text size="16px" weight="300" color="#535B5F" marginTop="6px">{t("notices.confirmationQuestion")}</Text>
                    <Text size="16px" weight="300" color="#535B5F" marginTop="6px">{t("notices.confirmationMsg")}</Text>
                    <div className={styles.btn_wrapper}>
                        <Button className='w-100' onClick={handleSubmit}>{t("notices.sendNotice")}</Button>
                        <Button borderedSecondaryNew className='w-100' onClick={closeModal}>
                            <span>{t("notices.cancel")}</span>
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
export default withTranslation()(NoticeAlert);