import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import constants from "../../constants";

const MessageDot = ({ type }) => {
  const {
    role,
    profileSetupStep,
    subscription,
    sendbirdMessageCount,
    isAdmin,
    isMessenger,
  } = useSelector((state) => state?.userProfile?.profile) || {};

  const { bothEmpty, patientCountCheck, liveCountCheck } = useMemo(() => {
    return {
      patientCountCheck: !sendbirdMessageCount?.patient,
      liveCountCheck: !sendbirdMessageCount?.live,
      bothEmpty:
        !sendbirdMessageCount ||
        (sendbirdMessageCount?.live === 0 &&
          sendbirdMessageCount?.patient === 0),
    };
  }, [sendbirdMessageCount]);
  const { isPatient, isLive, emptyType } = useMemo(() => {
    return {
      isPatient: type === constants.chat.patientChat,
      isLive: type === constants.chat.teamLiveChat,
      emptyType: !type,
    };
  }, []);

  if (
    (emptyType && bothEmpty) ||
    (!emptyType && isPatient && patientCountCheck) ||
    (!emptyType && isLive && liveCountCheck)
  )
    return null;

  const redDot = (
    <span className="notification-red-dot">
      <img alt="red-dot" src={require("assets/images/red-dot.svg").default} />
    </span>
  );

  if (role.systemRole === constants.systemRoles.accountOwner) {
    if (
      profileSetupStep === "completed" &&
      subscription?.packageType !== constants.packageTypes.free
    ) {
      return redDot;
    }
  } else if (role.systemRole === constants.systemRoles.staff) {
    if (isAdmin || !!isMessenger) {
      return redDot;
    }
  } else if (role.systemRole === constants.systemRoles.patient) {
    return redDot;
  }

  return null;
};

export default MessageDot;
