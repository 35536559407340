import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Table from "components/table";
import SortDown from "../../../../assets/images/pms-icons/sort_down.svg";
import SortUp from "../../../../assets/images/pms-icons/sort_up.svg";
import constants from "../../../../constants";
import styles from "../Appointments.module.scss";
import moment from "moment";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { encodeId } from "utils";
import CancelRequestModal from "../modal/CancelRequestModal";
import { useWaitingRequestModal } from "../hooks/useRequestModal";
import DeleteRequestModal from "../modal/DeleteRequestModal";
import { ThreeDotsVertical } from "react-bootstrap-icons";

function WaitingListTable({
  t,
  officeId,
  handleUpdateList,
  waitingLists,
  totalItems,
  pageNumber,
  pageSize,
  setPageNumber,
  handleSort,
}) {
  const {
    data: {
      cancelAppointmentModal,
      cancelReason,
      errorReasonMsg,
      cancelRequsetLoading,
      deleteRequsetLoading,
      deleteAppointmModal,
    },
    methods: {
      closeCancelModal,
      handleCancelRason,
      handleCancelRequest,
      setCancelAppointmentMoal,
      setDeleteAppointment,
      closeDeleteModal,
      handleDeleteRequest,
    },
  } = useWaitingRequestModal(t, handleUpdateList);

  const getStatus = (overallStatus) => {
    const statusMap = {
      1: "Pending",
      2: "Cancelled",
      3: "Converted",
    };
    return statusMap[overallStatus] || "";
  };

  const getStatusClass = (status) => {
    switch (getStatus(status)) {
      case "Pending":
        return "blue-accepted-btn";
      case "Cancelled":
        return "red-pending-btn";
      case "Converted":
        return "green-confirmed-btn";
      default:
        return "";
    }
  };

  const columns = [
    {
      attrs: { datatitle: t("waitingList.patientName") },
      dataField: "patientName",
      text: t("waitingList.patientName"),
      formatter: (cellContent, row, rowIndex) => (
        <Link
          className={styles["appointment-table-row-main-link"]}
          to={constants.routes.waitingAppoimentDetail
            .replace(":officeId", officeId)
            .replace(":requestId", encodeId(row?.id))}
        >
          <span className={styles["appointment-table-patient-name"]}>
            {row.patientName}{" "}
          </span>
        </Link>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span onClick={() => handleSort("patientNameAscending", true)}>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span onClick={() => handleSort("patientNameAscending", false)}>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("waitingList.appointmentDate") },
      dataField: "appointmentDate",
      text: t("waitingList.appointmentDate"),
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {moment(row.appointmentDate).format("MMM Do, YYYY")}
        </span>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span onClick={() => handleSort("appointmentDateAscending", true)}>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span onClick={() => handleSort("appointmentDateAscending", false)}>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("waitingList.doctorName") },
      dataField: "doctorName",
      text: t("waitingList.doctorName"),
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row.doctorName}
        </span>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span onClick={() => handleSort("doctorNameAscending", true)}>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span onClick={() => handleSort("doctorNameAscending", false)}>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("waitingList.requestedFor") },
      dataField: "requestedFor",
      text: t("waitingList.requestedFor"),
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row.requestedFor}
        </span>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span onClick={() => handleSort("requestedByAscending", true)}>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span onClick={() => handleSort("requestedByAscending", false)}>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },

    {
      attrs: { datatitle: t("waitingList.status") },
      dataField: "status",
      text: t("waitingList.status"),
      formatter: (cellContent, row, rowIndex) => (
        <Button
          className={`appointment-table-custom-btn ${getStatusClass(
            row.status
          )}`}
        >
          <span>{getStatus(row.status)}</span>
        </Button>
      ),
    },

    {
      attrs: { datatitle: t("waitingList.action") },
      dataField: "action",
      text: t("waitingList.action"),
      formatter: (cellContent, row, rowIndex) => (
        <UncontrolledDropdown>
          <DropdownToggle caret={false} tag="div" className="cursor-pointer">
            <ThreeDotsVertical />
          </DropdownToggle>
          <DropdownMenu right className={styles["office-dropdown-menu"]}>
            <DropdownItem className={styles["office-dropdown-item"]}>
              <Link
                to={constants.routes.waitingAppoimentDetail
                  .replace(":officeId", officeId)
                  .replace(":requestId", encodeId(row?.id))}
              >
                {t("waitingList.view")}
              </Link>
            </DropdownItem>
            {row?.status === 1 && (
              <DropdownItem className={styles["office-dropdown-item"]}>
                <Link
                  to={constants.routes.appointment.createAppointment
                    .replace(":officeId", officeId)
                    .replace(":requestId", encodeId(row?.id))}
                >
                  {t("waitingList.createAppointment")}
                </Link>
              </DropdownItem>
            )}
            {row?.status === 1 && (
              <DropdownItem className={styles["office-dropdown-item"]}>
                <div onClick={() => setCancelAppointmentMoal(row?.id)}>
                  {t("waitingList.cancelRequest")}
                </div>
              </DropdownItem>
            )}
            {row?.status !== 1 && (
              <DropdownItem className={styles["office-dropdown-item"]}>
                <div onClick={() => setDeleteAppointment(row?.id)}>
                  {t("waitingList.delete")}
                </div>
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <div className={styles["appointment-table-box"] + " " + "w-100"}>
      <div className="test-table-box">
        <Table
          keyField="id"
          data={waitingLists}
          columns={columns}
          bordered={false}
          handlePagination={setPageNumber}
          pageNumber={pageNumber}
          totalItems={totalItems}
          pageSize={pageSize}
        />
      </div>
      {cancelAppointmentModal && (
        <CancelRequestModal
          t={t}
          showLoader={cancelRequsetLoading}
          openModal={!!cancelAppointmentModal}
          closeModal={closeCancelModal}
          isCancel={closeCancelModal}
          title={t("patientAppointmentMembers.cancelRequestTitle")}
          inputLabel={t("patientAppointmentMembers.cancelRequestLabel")}
          handleChange={handleCancelRason}
          reason={cancelReason}
          submitReason={handleCancelRequest}
          errorMsg={errorReasonMsg}
        />
      )}
      {deleteAppointmModal && (
        <DeleteRequestModal
          t={t}
          showLoader={deleteRequsetLoading}
          isModalOpen={deleteAppointmModal}
          closeModal={closeDeleteModal}
          deleteRequest={handleDeleteRequest}
          title={t("patientAppointmentMembers.deleteRequestTitle")}
        />
      )}
    </div>
  );
}
export default withTranslation()(WaitingListTable);
