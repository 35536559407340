import { useEffect, useState } from "react";
import moment from "moment";

import toast from "react-hot-toast";
import constants from "../../../../constants";
import { cloneDeep } from "lodash";
import useUploadService from "hooks/useUploadService";
import { createDateStringForAPIRequest, createDateStringForAPIRequestDueDate, decodeId, getBlobnameFromUrl, getStorage } from "utils";
import { EditTask, addNewTask, filterOptionsStaff, useAllTasks, useMyTaskById } from "repositories/task-management-repository";
import useTaskModule from "./useTaskModule";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const pageSize = 10;
const containerName = "globalnotesattachments";
export const useAddTaskController = (t, history, addTask,assigneeTaskId, handleActiontask = () => {}) => {

    const allowedTypes = constants.chat.allowedTypesForMessage;
    const { upload: uploadImage } = useUploadService();
    const { accountOwnerId } = useTaskModule(useAllTasks);
    const prioritiesOptions = [
        { id: 2, name: "Medium" },
        { id: 1, name: "High" },
        { id: 3, name: "Low" },
    ];

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        startDate: null,
        DueDate: null,
        Assignies: [],
        notifyAssignees: false,
        notifyAssignor: false
    });
    const [priority, setPriority] = useState(2);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({});
    const [confirmedStaff, setConfirmedStaff] = useState([]);
    const [fileName, setFileName] = useState({ path: "", file: null });
    const [selectedOption, setSelectedOption] = useState({ value: 'all_offices', label: 'All Offices' });
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [OptionsStaff, setOptionsStaff] = useState([]);
    const [staffSelectedCount, setStaffSelectedCounts] = useState(0);
    const { pageNumber } = useState(1);
    const isChatFromDashBoard = getStorage("isChatFromDashBoard");
    let { taskId } = useParams();
    taskId = decodeId(taskId);

    const queries  = useMyTaskById(taskId, pageSize, pageNumber, {enabled : !!taskId && addTask});
    const [taskDetail, assigneeData] = queries.map(query => query?.data);
    const detailsLoading = queries.some(query => query?.isLoading);

    const [dates, setdates] = useState({
        startDate: moment().toDate(),
        DueDate: null
    });
    const [utcDates, setUtcdates] = useState({
        startDate: createDateStringForAPIRequest(moment().toDate()),
        DueDate: null
    })



    useEffect(() => {

        if (taskId) {
            setFormData({
                title: taskDetail?.title,
                description: taskDetail?.description,
                startDate: taskDetail?.startDate,
                DueDate: taskDetail?.dueDate,
                Assignies: [],
                notifyAssignees: taskDetail?.notifyAssignees,
                notifyAssignor: taskDetail?.notifyAssignor
            })
            setPriority(taskDetail?.priority);
            setStaffSelectedCounts(taskDetail?.totalAssignees || 0);
            setFileName(taskDetail?.filePath);
            setdates({
                startDate: moment.utc(taskDetail?.startDate).toDate(),
                DueDate: moment.utc(taskDetail?.dueDate).toDate()
            })
            setUtcdates({
                startDate: taskDetail?.startDate,
                DueDate: taskDetail?.dueDate
            })

        }
    }, [taskDetail, assigneeData]);


    const handleOnChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue
        })
    }

    const updateDatesValues = (e, type) => {
        dates[type] = e;
        setdates({ ...dates });
        setUtcdates(prevState => ({
            ...prevState,
            startDate: type === 'startDate'
                ? createDateStringForAPIRequest(e)
                : prevState.startDate,
            DueDate: type === 'DueDate'
                ? createDateStringForAPIRequestDueDate(e)
                : prevState.DueDate,
        }));
    };


    const handleCheckboxChange = (id, event) => {
        const { checked } = event.target;
        setSelectedStaff((prev) => ({
            ...prev,
            [id]: checked
        }));
    };

    const handleToggle = () => {
        if (isModalOpen) {
            setIsModalOpen(false);
            setSelectedOption({ value: 'all_offices', label: 'All Offices' })
        } else {
            setIsModalOpen(true);
            getOfficeMembers();
        }
    }


    const handleApplyCheck = () => {
        const selected = Object.keys(selectedStaff).filter(key => selectedStaff[key]);
        setConfirmedStaff(selected);
        setStaffSelectedCounts(() => {
            if (taskId) {
                return (taskDetail?.totalAssignees + selected?.length);
            } else {
                return selected?.length;
            }
        });
        if (selected?.length) {
            if(!addTask) { 
                const params = {
                    assignees: selected,
                    ...(accountOwnerId && { accountOwnerId: accountOwnerId })
                };
                handleActiontask(assigneeTaskId, "Reassign", params);
            }
            handleToggle();
        }
    };

    const handleFileChange = (event) => {
        if (!event || !event.target) return;

        const files = event.target.files;
        if (!files.length) return;

        if (!allowedTypes.includes(files[0]?.type)) {
            toast.error(t("fileNotSupported"));
            return;
        }
        setFileName({
            path: URL.createObjectURL(files[0]),
            file: files[0],
        });
    };

    const handleCancelCheck = () => {
        handleToggle();
    };

    const onBack = () => {
        if(isChatFromDashBoard) {
            history.push(constants.routes.dashboard.managerDashboard);
        } else {
            history.push(constants.routes.taskManagements.taskManagement);
        }
    }

    const isValidEvent = () => {
        const errorsData = cloneDeep(errors);
        //Title
        if (!formData.title.trim().length) {
            errorsData.title = t("form.errors.emptyField", {
                field: t("title"),
            });
        } else {
            delete errorsData.title;
        }
        //Description
        if (!formData.description.trim().length) {
            errorsData.description = t("form.errors.emptyField", {
                field: t("description"),
            });
        } else {
            delete errorsData.description;
        }
        //dates
        if (!dates.startDate) {
            errorsData.startDate = t('form.errors.emptyField', { field: t('end date') })
        } else {
            delete errorsData.startDate;
        }
        if (!dates.DueDate) {
            errorsData.DueDate = t('form.errors.emptyField', { field: t('dueDate') })
        } else {
            delete errorsData.DueDate;
        }
        setErrors(errorsData);
        return !Object.values(errorsData).some((err) => !!err);
    };

    const getOfficeMembers = async () => {
        setShowLoader(true);
        try {
            const res = await filterOptionsStaff(accountOwnerId, taskId);

            if (res && res.length) {
                setOptionsStaff(res);
            }
            setShowLoader(false);
        } catch (e) {
            setShowLoader(false);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isValidEvent()) {
            setShowLoader(true);
            const utcStartDate = utcDates.startDate;
            const utcDueDate = utcDates.DueDate;
            try {
                let imageBlobName;
                if (fileName && fileName.file && fileName.path) {
                    const [err, blobData] = await uploadImage(
                        fileName.file,
                        containerName
                    );

                    let file = blobData.blobUrl;

                    imageBlobName = `${containerName}/${blobData.blobName}`;

                    if (err) {
                        throw new Error(err);
                    }

                    setFileName({
                        path: file,
                        file: null,
                    });
                }

                if (fileName && fileName.path && !fileName.file) {
                    imageBlobName = `${containerName}/${getBlobnameFromUrl(
                        fileName.path,
                        containerName
                    )}`;
                }
                let imageEditblobName = imageBlobName || taskDetail?.filePath
                const params = {
                    title: formData?.title,
                    description: formData?.description,
                    startDate: utcStartDate,
                    dueDate: utcDueDate,
                    priority: priority,
                    notifyAssignees: formData?.notifyAssignees,
                    notifyAssignor: formData?.notifyAssignor,
                    assignees: confirmedStaff,
                    filePath: imageEditblobName || "",
                    accountOwnerId: accountOwnerId || null
                };
                if (taskId) {
                    params.taskId = taskId;
                    let res = await EditTask(params);
                    toast.success(res.message);
                    if (res.status) onBack();
                } else {
                    let res = await addNewTask(params);
                    toast.success(res.message);
                    if (res.status) onBack();
                }

            } catch (err) {
                toast.error(err.message);
            } finally {
                setShowLoader(false);
            }
        }
    }
    const isAnyLoading = detailsLoading || showLoader

    return {
        data: {
            isAnyLoading,
            dates,
            formData,
            priority,
            prioritiesOptions,
            errors,
            isModalOpen,
            OptionsStaff,
            fileName,
            staffSelectedCount,
            selectedOption,
            selectedStaff,
            taskId,
            taskDetail
        },
        methods: {
            setFormData,
            handleOnChange,
            setPriority,
            updateDatesValues,
            handleSubmit,
            handleToggle,
            handleCheckboxChange,
            handleApplyCheck,
            handleFileChange,
            handleCancelCheck,
            onBack,
            setSelectedOption,
            setFileName,
            setSelectedStaff
        }
    };
}
