import React, { useEffect, useState } from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useAllActiveAndTempOffices } from "repositories/notes-repository";
import { withTranslation } from "react-i18next";
import { useAllActiveOffices } from "repositories/scheduler-repository";

function OfficeFilter({
  setOfficeFilter,
  selectedOffice,
  t,
  setStaffFilter,
  setExpandedItems,
  ownerId,
  setIsAdminData,
}) {
  const { data, isLoading } = useAllActiveOffices(1, 100, ownerId, {
    enabled: !!ownerId,
  });
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectedOffice?.length === data?.data?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedOffice, data]);

  useEffect(() => {
    if (!isLoading && data) {
      let isAdminfilter = data?.data?.find((officeList) => officeList?.isAdmin);
      setIsAdminData(isAdminfilter);
    }
  }, [isLoading, data]);

  const handleChange = (e, item) => {
    const offices = [...selectedOffice];
    const officeIndex = offices.findIndex((v) => +item.id === +v);
    if (e.target.checked) {
      offices.push(item.id);
    } else {
      if (officeIndex > -1) offices.splice(officeIndex, 1);
    }
    setOfficeFilter(offices);
    setStaffFilter([]);
    setExpandedItems(["a", "c"]);
    setSelectAll(false);
  };

  const handleAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setOfficeFilter(data ? data.data.map((item) => item.id) : []);
      setStaffFilter([]);
    } else {
      setOfficeFilter([]);
    }
  };

  return (
    <ul className={["filter-list"]}>
      {data && data.data && (
        <ul>
          <li>
            <div className="ch-checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={handleAllChange}
                  checked={selectAll}
                />
                <span>{t("notes.allFilters")}</span>
              </label>
            </div>
          </li>
          <hr className="mr-4 spacing_hr" />
        </ul>
      )}
      {data &&
        data.data.map((item, key) => (
          <li key={key}>
            <div className="ch-checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => handleChange(e, item)}
                  checked={selectedOffice.includes(item.id)}
                />{" "}
                <span> {item.name}</span>
              </label>
            </div>
          </li>
        ))}
    </ul>
  );
}

export default withTranslation()(OfficeFilter);
