import React, { useEffect } from "react";
import Page from "components/Page";
import { Card } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import styles from "../../../../pages/AccountPreferences/accountpreferences.module.scss";
import ChatIcon from "../../../../assets/images/MulticolorChatIcon.svg";
import constants from "../../../../constants";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { setStorage } from "utils";

const TimeLinePrefences = ({ history, t, location }) => {
  const { officeId } = useParams();

  const handleReminderClick = () => {
    history.push({
      pathname:
        constants.routes.appointment.AccountGlobalOfficeReminder.replace(
          ":officeId",
          officeId
        ),
      state: { officeName: location?.state?.officeName },
    });
    setStorage("isRecallReminderPlan", {
      isRecallReminderPlan: false,
    });
  };

  const handleRecallClick = () => {
    history.push({
      pathname:
        constants.routes.appointment.AccountGlobalOfficeReminder.replace(
          ":officeId",
          officeId
        ),
      state: { officeName: location?.state?.officeName },
    });
    setStorage("isRecallReminderPlan", {
      isRecallReminderPlan: true,
    });
  };

  const accountList = [
    {
      id: 2,
      chatImg: ChatIcon,
      accountName: t("accountPreferences.appointmentReminderPlan"),
      onclick: handleReminderClick,
    },
    {
      id: 3,
      chatImg: ChatIcon,
      accountName: t("accountPreferences.appointmentRecallPlan"),
      onclick: handleRecallClick,
    },
  ];

  useEffect(() => {
    setStorage("isRecallReminderPlan", {
      isRecallReminderPlan: false,
    });
  }, []);

  const goBack = () =>
    history.push({
      pathname: constants.routes.accountOwner.officeOptions.replace(
        ":officeId",
        officeId
      ),
      state: { officeName: location?.state?.officeName },
    });

  return (
    <>
      <Page onBack={goBack} className={styles["global-main-page"]}>
        <div>
          <div className={styles["account-preferences-heading"]}>
            <p className={styles["mobile-text-size"] + " " + "mb-0"}>
              {location?.state?.officeName}
            </p>
            <p className="pms_subtitle">
              {t("accountPreferences.patientSchedulingTemplates")}
            </p>
          </div>

          <div className={styles["manage-card-container"] + " " + "d-flex"}>
            {accountList.map((item) => (
              <div
                className={
                  styles["card-con"] + " " + "form-wrapper cursor-pointer"
                }
                key={item.id}
                onClick={item?.onclick}
              >
                <Card
                  className={
                    styles["account-preference-card"] +
                    " " +
                    "d-flex align-items-center flex-row w-100 cursor-pointer"
                  }
                >
                  <div className={styles["account-preference-logo"]}>
                    <img
                      key={item.id}
                      src={item.chatImg}
                      alt="message template icon"
                    />
                  </div>
                  <div
                    className={styles["account-preference-text"]}
                    key={item.id}
                  >
                    {item.accountName}
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Page>
    </>
  );
};

export default withTranslation()(TimeLinePrefences);
