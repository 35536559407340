import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import styles from "../../WaitingList/WaitingList.module.scss";
import { withTranslation } from "react-i18next";

const PatientsFilter = ({
  t,
  isSidebarActive,
  handleSidebarToggle,
  statuslistdata,
  statusFilter,
  setStatusFilter,
  resetFilter,
  handleApplyFilters,
  isFilterApply,
}) => {
  const handleStatusChange = (e, item) => {
    const statuses = [...statusFilter];
    const statusIndex = statuses.findIndex((v) => +item.id === +v);
    if (e.target.checked) {
      statuses.push(item.id);
    } else {
      if (statusIndex > -1) statuses.splice(statusIndex, 1);
    }
    setStatusFilter(statuses);
  };

  const handleApplyFilter = () => {
    handleApplyFilters();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleResetFliter = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleClose = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleResetFliter}
    >
      <span className={styles.waiting_filter}>
        {t("accountOwner.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>
      <Accordion
        preExpanded={["a"]}
        className="filter-accordion"
        allowZeroExpanded
        allowMultipleExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>{t("waitingList.status")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={["filter-list"]}>
              {statuslistdata.map((v, key) => (
                <li key={key}>
                  <div className="ch-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="status"
                        onChange={(e) => handleStatusChange(e, v)}
                        checked={statusFilter.includes(v.id)}
                      />
                      <span> {t(v.type)} </span>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className={"button button-round button-shadow mr-3"}
          title={t("apply")}
          onClick={handleApplyFilter}
        >
          {t("apply")}
        </button>
        <button
          className={"button button-round button-border button-dark reset-btn"}
          title={t("reset")}
          onClick={handleResetFliter}
        >
          {t("reset")}
        </button>
        <button
          className={"button button-round button-border button-dark cancel-btn"}
          onClick={handleClose}
          title={t("close")}
        >
          {t("close")}
        </button>
      </div>
    </StickySidebar>
  );
};
export default withTranslation()(PatientsFilter);
