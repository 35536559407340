import React, { memo, useState } from "react";
import Text from "components/Text";
import { Button } from "reactstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import styles from "../PerformanceReview.module.scss";
import StaffRating from "./StaffRating";
import AdminRating from "./AdminRating";
import { ReviewStatus } from "../../../../constants";

function ReviewsSection({
  t,
  questionsList,
  handleCommentChange,
  handleRadioChange,
  questionnaireResponses,
  feedbackQuestionnaireResponses,
  errors,
  isView,
  handleSetText,
  handleSetDate,
  giveFeedback,
  review,
}) {
  const [expandArray, setExpandArray] = useState([]);
  const toggleItem = (id) => {
    let tempArr = [...expandArray];
    const index = tempArr.indexOf(id);
    if (index > -1) {
      tempArr.splice(index, 1);
    } else {
      tempArr.push(id);
    }
    setExpandArray(tempArr);
  };

  const collapseAll = (collapse) => {
    if (collapse) {
      const listOfIds = questionsList?.map((v) => v.id) ?? [];
      setExpandArray([...listOfIds]);
    } else {
      setExpandArray([]);
    }
  };

  function shiftLastTwoToTop(arr) {
    const tempArr = [...arr];
    if (
      !(
        !giveFeedback &&
        (ReviewStatus?.completed === review?.status ||
          ReviewStatus?.finalized === review?.status)
      )
    )
      return tempArr;
    let lastTwo = tempArr.slice(-2);
    tempArr.splice(-2);
    return lastTwo.concat(tempArr);
  }

  return (
    <div>
      <div className={styles.link_btn_wrapper}>
        <Button
          className={styles.link_btn + " " + "link-btn"}
          onClick={() => collapseAll(true)}
        >
          {t("performanceReview.expandAll")}
        </Button>
        <Button
          className={styles.link_btn + " " + "link-btn"}
          onClick={() => collapseAll(false)}
        >
          {t("performanceReview.collapseAll")}
        </Button>
      </div>
      <Accordion
        preExpanded={expandArray}
        className={styles.accordion_wrapper + " " + "filter-accordion"}
        allowZeroExpanded
        allowMultipleExpanded
      >
        {questionsList?.length > 0 &&
          shiftLastTwoToTop(questionsList).map(
            ({ id, question, ratingCriteria }) => (
              <AccordionItem
                key={id}
                uuid={id}
                dangerouslySetExpanded={expandArray.includes(id)}
                className={styles.AccordionItem}
              >
                <AccordionItemHeading
                  className={styles.AccordionItemHeading}
                  onClick={() => toggleItem(id)}
                >
                  <AccordionItemButton className={styles.AccordionItemButton}>
                    <Text size="16px" weight="600" color="#587E85">
                      {question?.questionTitle}
                    </Text>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {question?.typeOfResponse === 3 && (
                    <StaffRating
                      t={t}
                      questionId={id}
                      ratingCriteria={ratingCriteria}
                      handleCommentChange={handleCommentChange}
                      handleRadioChange={handleRadioChange}
                      isView={isView}
                      title={t("performanceReview.staffReview")}
                      giveFeedback={giveFeedback}
                      questionnaireResponses={questionnaireResponses}
                      feedbackQuestionnaireResponses={
                        feedbackQuestionnaireResponses
                      }
                      review={review}
                    />
                  )}
                  {(giveFeedback ||
                    ReviewStatus?.completed === review?.status ||
                    ReviewStatus?.finalized === review?.status) && (
                    <AdminRating
                      t={t}
                      questionId={id}
                      handleCommentChange={handleCommentChange}
                      ratingCriteria={ratingCriteria}
                      handleRadioChange={handleRadioChange}
                      isView={!giveFeedback}
                      title={
                        giveFeedback
                          ? t("performanceReview.yourFeedback")
                          : t("performanceReview.employerFeedback")
                      }
                      giveFeedback={giveFeedback}
                      questionnaireResponses={feedbackQuestionnaireResponses}
                      review={review}
                      handleSetDate={handleSetDate}
                      handleSetText={handleSetText}
                      question={question}
                      id={id}
                    />
                  )}
                </AccordionItemPanel>
              </AccordionItem>
            )
          )}
        {errors?.textField ? (
          <span className="error-msg">{errors.textField}</span>
        ) : (
          ""
        )}
      </Accordion>
    </div>
  );
}

export default memo(ReviewsSection);
