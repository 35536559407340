import React, { useEffect, useState } from "react";
import Card from "components/Card";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import Exlamatory_Icon from "../../../../../assets/images/red_exclamatory_icon.svg";
import CommentIcon from "../../../../../assets/images/comment_icon.svg";
import styles from "../../TaskManagement.module.scss";
import "../../TaskManagement.scss";
import CalcelModal from "../../Modals/CancelModal";
import { calculateCompleteionTime, formatUTCDateTime } from "utils";
import useCardModule from "../../hooks/useCardModule";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AssignStaffMembersModal from "../../Modals/AssignStaffMembersModal";
import { useAddTaskController } from "../../hooks/useAddTaskController";

const TaskDetailsCard = ({ t, assignee, getButtonClass, checkOverdueTasks, handleActiontask }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [OfficeOptions, setOfficeOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const history = useHistory();
  const {
    data: {
      isCancelModalOpen,
    },
    methods: {
      handleCancelModal,
      handleCardClick
    }
  } = useCardModule()
  let addTask = false;
  const {
    data: {
      isModalOpen,
      OptionsStaff,
      selectedOption,
      selectedStaff,
    },
    methods: {
      handleToggle,
      handleApplyCheck,
      handleCheckboxChange,
      handleCancelCheck,
      setSelectedOption,
    },
  } = useAddTaskController(t, history, addTask, assignee?.id, handleActiontask);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getStatus = (overallStatus) => {
    const statusMap = {
      1: "In Progress",
      2: "Pending",
      3: "Accepted",
      4: "Started",
      5: "Completed",
      6: "Rejected",
      7: "Cancelled"
    };
    return statusMap[overallStatus] || "";
  };

  const ConverOptions = (staffOptions) => {
    let newOtion = { value: 'all_offices', label: 'All Offices' }
    const uniqueLabels = new Set();
    let uniqueOffice = staffOptions.filter(office => {
      if (uniqueLabels.has(office?.officeId)) {
        return false;
      }
      uniqueLabels.add(office?.officeId);
      return true;
    }).map(office => ({ value: office?.officeId, label: office.officeName }));
    let updateUniqueOffice = [newOtion, ...uniqueOffice];
    setOfficeOptions(updateUniqueOffice);
  }

  useEffect(() => {
    setStaffOptions(OptionsStaff);
  }, [OptionsStaff])

  useEffect(() => {
    ConverOptions(OptionsStaff);
  }, [isModalOpen, OptionsStaff])

  const handleRequestStatusFilter = selectedOffice => {
    setSelectedOption(selectedOffice);
    if (selectedOffice?.value === 'all_offices') {
      let FilterData = OptionsStaff?.filter(office => office);
      setStaffOptions(FilterData);
    } else {
      let FilterData = OptionsStaff?.filter(office => office?.officeId === selectedOffice.value);
      setStaffOptions(FilterData);
    }
  };


  return (
    <Card className={styles["task_details_card_wrapper"]} onClick={(e) => handleCardClick(e, assignee, history)}>
      {checkOverdueTasks(assignee?.dueDate, assignee?.status) && (<div className="d-flex align-items-center gap-3">
        <img src={Exlamatory_Icon} alt="Exlamatory_Icon" className="mr-2" />
        <p className={styles["overdue_warning"] + " " + "m-0"}>
          {t("taskManagement.taskOverdue")}
        </p>
      </div>)}
      <div className={styles["btn_dropdown_wrapper"]}>
        <div className={styles["status_priority_date_wrapper"]}>
          <button className={getButtonClass(getStatus(assignee?.status))}>{getStatus(assignee?.status)}</button>
        </div>
        <div>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} className="interactive-element">
            <DropdownToggle
              caret={false}
              tag="div"
              className="dropdown-btn px-3"
            >
              {(((assignee?.status === 6) || (assignee?.status ===7) ||(assignee?.status === 2)) && (!assignee?.isReassigned)) && <img
                src={require("assets/images/dots-icon.svg").default}
                alt="icon"
              />}
            </DropdownToggle>
            <DropdownMenu right className={styles["custom_dropdown_wrapper"]}>
              {(assignee?.status === 2) && <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                <p
                  className={styles["dropdown_link"] + " " + "mb-0"}
                  onClick={handleCancelModal}
                >
                  {t("taskManagement.cancel")}
                </p>
              </DropdownItem>}
              {(((assignee?.status === 6) || (assignee?.status === 7)) && (!assignee?.isReassigned)) && <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                <p 
                className={styles["dropdown_link"]}
                onClick={() => { handleToggle(assignee?.taskId)}}
                >
                  {t("taskManagement.reassignTask")}
                </p>
              </DropdownItem>}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <p className={styles["card_main_heading"]}>
        {`${assignee?.user?.firstName} ${assignee?.user?.lastName}`}
      </p>
      <div className={styles.dental_name_comment}>
        <div>
          <Text size="12px" weight="400" color="#6F7788">
            {assignee?.office?.name}
          </Text>
        </div>
        <div className="d-flex align-items-center gap-3">
          <img
            style={{ display: "inline-block" }}
            src={CommentIcon}
            alt="download-icon"
            className="mr-1"
          />
          <Text size="12px" weight="400" color="#6F7788">
            {assignee?.totalComments}
          </Text>
        </div>
      </div>

      {assignee?.status === 5 && (
        <div className={styles["office_assignby_wrapper"]}>
          <div className={styles["office_name"]}>
            <Text size="12px" weight="400" color="#6F7788">
              {t("taskManagement.completedOn")}
            </Text>
            <Text size="14px" weight="600" color="#102C42">
              {formatUTCDateTime(assignee?.completedOn)}
            </Text>
          </div>
          <div className={styles["office_name"]}>
            <Text size="12px" weight="400" color="#6F7788">
              {t("taskManagement.completedtime")}
            </Text>
            <Text size="14px" weight="600" color="#102C42">
              {calculateCompleteionTime(assignee?.completionTimeInMinutes)}
            </Text>
          </div>
        </div>
      )}

      {assignee?.status === 6 && (
        <div className={styles.reason_for_rejection}>
          <Text size="12px" weight="400" color="#6F7788">
            {t("taskManagement.reasonOfRejection")}
          </Text>
          <Text size="14px" weight="600" color="#102C42" marginTop="4px">
            {assignee?.rejectionReason}
          </Text>
        </div>
      )}
      {isCancelModalOpen && (
        <CalcelModal
          toggleModal={handleCancelModal}
          isModalOpen={isCancelModalOpen}
          handleAction={handleActiontask}
          taskId={assignee?.id}
        />
      )}
      {isModalOpen && (
        <AssignStaffMembersModal
          isModalOpen={isModalOpen}
          closeModal={handleToggle}
          options={OfficeOptions}
          selectedOption={selectedOption}
          handleRequestStatusFilter={handleRequestStatusFilter}
          OptionsStaff={staffOptions}
          handleCheckboxChange={handleCheckboxChange}
          handleApplyCheck={handleApplyCheck}
          handleCancelCheck={handleCancelCheck}
          selectedStaff={selectedStaff}
        />
      )}
    </Card>
  );
};
export default withTranslation()(TaskDetailsCard);
