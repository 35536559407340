import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../../Notes/Notes.module.scss"
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import Loader from "components/Loader";

const ConfirmationModal = ({ t, isModalOpen, closeModal, message, handleApply, showLoader }) => {

  return (
    <Modal
      isOpen={!!isModalOpen}
      toggle={closeModal}
      className="modal-lg modal-dialog-centered"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModal}><img src={crossIcon} alt="close" /></span>
      <ModalBody>
      {showLoader && <Loader />}
        <Text size="25px" weight="500" color="#111B45">{t("patientAppointmentMembers.confirm")}</Text>
        <Text size="16px" weight="300" color="#535B5F" marginTop="11px">{message}</Text>
        <div className={styles.main_btn_container}>
          <Button onClick={handleApply} title="save"> {t("patientAppointmentMembers.yesConfirm")}</Button>
          <Button borderedSecondaryNew title="cancel" onClick={closeModal}>
            <span>  {t("notes.cancel")}</span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(ConfirmationModal);
