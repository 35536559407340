import React, { useState, Suspense } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Loader from "components/Loader";
import Page from "components/Page";
import constants from "./../../../../constants";
import Card from "components/Card";
import styles from "../Contracts/Contracts.module.scss";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId, getStorage, setStorage } from "utils";
import { useOfficeDetail } from "repositories/office-repository";
import EmploymentProofModule from './components/EploymentProofModule';
import RequestModal from "./modals/RequestModal";
import { useSelector } from "react-redux";

function EmploymentProof({ t, history, location }) {
  const profile = useSelector((state) => state.userProfile.profile);
  let { officeId } = useParams();
  const decodeOfficeId = parseInt(decodeId(officeId));
  const isAdmin = !location?.pathname.includes('my-employment-proof');
  const cachedActiveTab = getStorage(constants.notes.cache.employementProof);

  const [activeTab, setActiveTab] = useState(
    cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1
  );
  const [isRequestOpen, setRequestOpen] = useState(false);
  const closeRequestModal = () => setRequestOpen(false);

  const tabName = constants.employementProofTab;

  const manageTab = (tab) => {
    window.scrollTo(0, 0);
    setActiveTab(tab);
    setStorage(constants.notes.cache.employementProof, {
      activeTab: tab,
    });
  };



  const goBack = () => {
    history.push(
      constants.routes.accountOwner.forms.replace(
        ":officeId",
        officeId
      )
    );
  };

  const { data: officeDetail } = useOfficeDetail(decodeOfficeId);

  return (
    <Page onBack={goBack} title={officeDetail?.name}>
      <div className={styles.title_btn_container}>
        <p className={styles.sub_title}>{t("employmentProof.employmentProof")}</p>
        {(!isAdmin) && <button
          className="button button-round button-shadow w-sm-100"
          onClick={() => setRequestOpen(true)}
        >
          {t("employmentProof.requestEmploymentProof")}
        </button>}
      </div>
      <Card className={styles.employment_proof_main_wrapper_card}>
        <div className="common-tabs scheduler-tabs">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={(activeTab === tabName.ongoing) ? "active" : ""}
                onClick={() => manageTab(tabName.ongoing)}
              >
                {t("employmentProof.ongoing")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={(activeTab === tabName.archived) ? "active" : ""}
                onClick={() => manageTab(tabName.archived)}
              >
                {t("employmentProof.archived")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <Suspense fallback={<Loader />}>
              <TabPane tabId={tabName.ongoing}>
                {activeTab === tabName.ongoing && <EmploymentProofModule isArchive={false} officeId={decodeOfficeId} />}
              </TabPane>
              <TabPane tabId={tabName.archived}>
                {activeTab === tabName.archived && <EmploymentProofModule isArchive={true} officeId={decodeOfficeId} />}
              </TabPane>
            </Suspense>
          </TabContent>
        </div>
      </Card>
      {isRequestOpen &&
        <RequestModal t={t} profile={profile} isRequestOpen={isRequestOpen} closeRequestModal={closeRequestModal} officeId={decodeOfficeId} />
      }
    </Page>
  );
}

export default withTranslation()(EmploymentProof);