import React from "react";
import Text from "components/Text";
import Input from "components/Input";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import Button from "components/Button";
import styles from "../PerformanceReview.module.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Form({
  t,
  handleSubmit,
  errors,
  formData,
  handleOnChange,
  updateDatesValues,
  handleCancel,
  isDisabled,
}) {
  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };
  const startDatePickerRef = useReadOnlyDateTextInput();

  const location = useLocation();

  const previewForm = location?.pathname.includes(
    "preview-performance-review-form"
  );

  const isInvalidDate = (date) => isNaN(new Date(date).getTime());

  return (
    <form
      onSubmit={handleSubmit}
      className={styles["date_wrapper"] + " " + "c-field mb-0 mt-4"}
    >
      <Input
        Title={t("performanceReview.form.employeeName")}
        Type="text"
        Placeholder={t("performanceReview.form.employeeName")}
        Name={"employeeName"}
        HandleChange={handleOnChange}
        Error={errors?.employeeName}
        Value={formData?.employeeName}
        Disabled={isDisabled || previewForm}
        MaxLength="55"
      />
      <Input
        Title={t("performanceReview.form.position")}
        Type="text"
        Placeholder={t("performanceReview.form.position")}
        Name={"position"}
        HandleChange={handleOnChange}
        Error={errors?.position}
        Value={formData?.position}
        Disabled={previewForm}
        MaxLength="55"
      />
      <div className={styles.new_inputdate + " " + "inputdate"}>
        <Text size="13px" weight="400" color="#79869A">
          {t("performanceReview.form.dateOfEmployment")}
        </Text>
        <ReactDatePicker
          dateFormat="dd-MM-yyyy"
          className="c-form-control"
          selected={
            isInvalidDate(formData?.dateOfEmployment)
              ? moment().toDate()
              : formData?.dateOfEmployment
          }
          onChange={(e) => updateDatesValues(e, "dateOfEmployment")}
          popperPlacement="bottom-start"
          popperContainer={CalendarContainer}
          disabled={previewForm}
          ref={startDatePickerRef}
        />
      </div>
      <div className="mt-4">
        <Input
          Title={t("performanceReview.form.beginningSalary")}
          Type="text"
          Placeholder={t("performanceReview.form.beginningSalary")}
          Name={"beginningSalary"}
          HandleChange={handleOnChange}
          Error={errors?.beginningSalary}
          Value={formData?.beginningSalary}
          Disabled={previewForm}
          MaxLength="55"
        />
      </div>
      <Input
        Title={t("performanceReview.form.presentSalary")}
        Type="text"
        Placeholder={t("performanceReview.form.presentSalary")}
        Name={"presentSalary"}
        HandleChange={handleOnChange}
        Error={errors?.presentSalary}
        Value={formData?.presentSalary}
        Disabled={previewForm}
        MaxLength="55"
      />
      <Input
        Title={t("performanceReview.form.amountOfLastRaise")}
        Type="text"
        Placeholder={t("performanceReview.form.amountOfLastRaise")}
        Name={"amountOfLastRaise"}
        HandleChange={handleOnChange}
        Error={errors?.amountOfLastRaise}
        Value={formData?.amountOfLastRaise}
        Disabled={previewForm}
        MaxLength="55"
      />
      <div className={styles.new_inputdate + " " + "inputdate"}>
        <Text size="13px" weight="400" color="#79869A">
          {t("performanceReview.form.lastRaiseDate")}
        </Text>
        <ReactDatePicker
          dateFormat="dd-MM-yyyy"
          className="c-form-control"
          selected={
            isInvalidDate(formData?.lastRaiseDate)
              ? moment().toDate()
              : formData?.lastRaiseDate
          }
          onChange={(e) => updateDatesValues(e, "lastRaiseDate")}
          popperPlacement="bottom-start"
          popperContainer={CalendarContainer}
          ref={startDatePickerRef}
          disabled={previewForm}
          maxDate={moment().toDate()}
        />
      </div>
      <div className="mt-4">
        <Input
          Title={t("performanceReview.form.proposedIncrease")}
          Type="text"
          Placeholder={t("performanceReview.form.proposedIncrease")}
          Name={"proposedIncrease"}
          HandleChange={handleOnChange}
          Error={errors?.proposedIncrease}
          Value={formData?.proposedIncrease}
          Disabled={isDisabled || previewForm}
          MaxLength="55"
        />
      </div>
      <div className={styles.new_inputdate + " " + "inputdate"}>
        <Text size="13px" weight="400" color="#79869A">
          {t("performanceReview.form.proposedIncreaseDate")}
        </Text>
        <ReactDatePicker
          dateFormat="dd-MM-yyyy"
          className="c-form-control"
          selected={
            isInvalidDate(formData?.proposedIncreaseDate)
              ? moment().toDate()
              : formData?.proposedIncreaseDate
          }
          onChange={(e) => updateDatesValues(e, "proposedIncreaseDate")}
          popperPlacement="bottom-start"
          popperContainer={CalendarContainer}
          disabled={isDisabled || previewForm}
          ref={startDatePickerRef}
        />
      </div>
      {errors?.ratingValue && (
        <span className="error-msg mt-3">{errors.ratingValue}</span>
      )}
      {!previewForm && (
        <div className={styles.btn_wrapper + " " + styles.margin40}>
          <Button className="w-sm-100" type="submit">
            {t("performanceReview.submit")}
          </Button>
          <Button
            className="w-sm-100"
            borderedSecondary
            type="button"
            onClick={handleCancel}
          >
            {t("performanceReview.cancel")}
          </Button>
        </div>
      )}
    </form>
  );
}
