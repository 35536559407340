import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import Text from "components/Text";
import WhiteLogo from "assets/images/mir_white_logo.svg";
import audioMikeImage from "assets/images/audio_mike.svg";
import callDisconnectImage from "assets/images/call_disconnect.svg";
import { useSbCalls } from "Messenger/calling/context";
import muteIcon from "assets/images/mute_icon.svg";
import videoIcon from "assets/images/video_icon.svg";
import muteWhiteIcon from "assets/images/mute_white_icon.svg";
import videoOffWhiteIcon from "assets/images/video_off_white_icon.svg";
import userImg from "assets/images/dp.svg";
import CallCountDown from "./callCountDown";
import userCircleImage from "assets/images/staff-default.svg";

export default function CallViewNew({ call }) {
  const { clearCalls, profile } = useSbCalls();
  const {
    callState,
    // caller,
    localUser,
    remoteUser,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    isRemoteAudioEnabled,
    isRemoteVideoEnabled,
  } = call;

  const localMediaViewRef = useCallback((node) => {
    call.setLocalMediaView(node);
  }, []);

  const remoteMediaViewRef = useCallback((node) => {
    call.setRemoteMediaView(node);
  }, []);

  const { localMediaViewSize, remoteMediaViewSize } = useMemo(() => {
    switch (callState) {
      case "dialing":
        return {
          localMediaViewSize: "hidden",
          remoteMediaViewSize: "full",
        };
      case "ringing":
        return {
          localMediaViewSize: "hidden",
          remoteMediaViewSize: "full",
        };
      case "connected":
      case "reconnecting":
        return {
          localMediaViewSize: "small",
          remoteMediaViewSize: "full",
        };
      case "ended":
      default:
        return {
          localMediaViewSize: "hidden",
          remoteMediaViewSize: "hidden",
        };
    }
  }, [callState]);

  const is = useCallback(
    (...states) => states.some((state) => state === callState),
    [callState]
  );
  // const isNot = useCallback(
  //   (...states) => !states.some((state) => state === callState),
  //   [callState]
  // );
  // const connectedStates = [
  //   "established",
  //   "connected",
  //   "reconnecting",
  //   "reconnected",
  // ];

  const localUpdates = useMemo(() => {
    const values = {
      muteIcon: muteWhiteIcon,
      userImage: localUser.profileUrl || userImg,
      muteUnmuteFunction: call.unmuteMicrophone,
      videoOnOffFunction: call.startVideo,
      muteUnmuteIcon: muteWhiteIcon,
      videoIconDisplay: videoOffWhiteIcon,
    };
    if (isLocalAudioEnabled) {
      values.muteIcon = null;
      values.muteUnmuteFunction = call.muteMicrophone;
      values.muteUnmuteIcon = audioMikeImage;
    }
    if (call.isVideoCall && isLocalVideoEnabled) {
      values.userImage = null;
      values.videoOnOffFunction = call.stopVideo;
      values.videoIconDisplay = videoIcon;
    }
    return values;
  }, [call, isLocalAudioEnabled, isLocalVideoEnabled, localUser]);

  const remoteUpdates = useMemo(() => {
    const values = {
      muteIcon: muteIcon,
      userImage:
        remoteUser.profileUrl || (call.isVideoCall ? userImg : userCircleImage),
    };
    if (isRemoteAudioEnabled) {
      values.muteIcon = null;
    }
    if (call.isVideoCall && isRemoteVideoEnabled) {
      values.userImage = null;
    }
    return values;
  }, [isRemoteAudioEnabled, isRemoteVideoEnabled, remoteUser]);

  return (
    <VideoCallWrapper>
      <NameProjectWrapper>
        <div>
          <Text size="25px" weight="600" color="#ffffff">
            {call.caller.userId !== profile.id.toString()
              ? "Dr. " + call.caller.nickname
              : call.callee.nickname}
          </Text>
          <Text size="14px" weight="300" color="#ffffff">
            {call.isVideoCall ? "Video" : "Audio"} Call
          </Text>
        </div>
        {is("connected", "reconnecting") && <CallCountDown />}
        <MiraxisWhiteLogo src={WhiteLogo} alt="mir-white-logo" />
      </NameProjectWrapper>
      {/* {call.isVideoCall ? ( */}
      <CallViewImageContainer>
        <VideoViewDiv size={remoteMediaViewSize}>
          {remoteUpdates.muteIcon && !is("dialing", "ringing") && (
            <MuteIcon src={remoteUpdates.muteIcon} alt="mir-white-logo" />
          )}
          {remoteUpdates.userImage && (
            <VideoElem1Image
              src={remoteUpdates.userImage}
              isVideoCall={call.isVideoCall}
              alt="mir-white-logo"
              onError={(e) => {
                e.currentTarget.src = call.isVideoCall
                  ? userImg
                  : userCircleImage;
              }}
            />
          )}
          {call.isVideoCall ? (
            <VideoElem1
              ref={remoteMediaViewRef}
              playsInline
              autoPlay
              muted={false}
              style={remoteUpdates.userImage ? { display: "none" } : {}}
            />
          ) : (
            <audio
              ref={remoteMediaViewRef}
              playsInline
              autoPlay
              muted={false}
              style={remoteUpdates.userImage ? { display: "none" } : {}}
            />
          )}
        </VideoViewDiv>
        <VideoViewDiv size={localMediaViewSize}>
          {call.isVideoCall && (
            <>
              {is("connected", "reconnecting") && <YouText>You</YouText>}
              {localUpdates.userImage && (
                <VideoElem2Image
                  src={localUpdates.userImage}
                  alt="mir-white-logo"
                  onError={(e) => {
                    e.currentTarget.src = userImg;
                  }}
                />
              )}
            </>
          )}
          {call.isVideoCall ? (
            <VideoElem2
              ref={localMediaViewRef}
              playsInline
              autoPlay
              muted
              style={{ backgroundColor: "var(--white)" }}
            />
          ) : (
            <audio ref={localMediaViewRef} playsInline autoPlay muted />
          )}
          {call.isVideoCall && localUpdates.muteIcon && (
            <MuteWhiteIcon src={localUpdates.muteIcon} alt="mir-white-logo" />
          )}
        </VideoViewDiv>
      </CallViewImageContainer>

      <ButtonContainer>
        {call.isVideoCall && (
          <img
            src={localUpdates.videoIconDisplay}
            alt="videoOnOff"
            onClick={() => {
              localUpdates.videoOnOffFunction();
            }}
          />
        )}
        <img
          src={localUpdates.muteUnmuteIcon}
          alt="microphone"
          onClick={() => {
            localUpdates.muteUnmuteFunction();
          }}
        />
        <img
          src={callDisconnectImage}
          alt="disconnect"
          onClick={() => {
            call.end();
            clearCalls();
          }}
        />
      </ButtonContainer>
    </VideoCallWrapper>
  );
}
const getVideoStyleFromSize = (size) => {
  switch (size) {
    case "hidden":
      return css`
        opacity: 0;
        transition: all 0.5s;
        display: none;
      `;
    case "small":
      return css`
        position: absolute;
        top: 16px;
        right: 16px;
        width: 178px;
        height: 100px;
        border-radius: 8px;
        background-color: var(--navy-300);
      `;
    case "full":
      return css`
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 1s;
        video {
          width: 100%;
          height: 100%;
        }
      `;
    default:
      return "";
  }
};
const VideoViewDiv = styled.div`
  position: absolute;
  overflow: hidden;
  display: grid;
  place-items: center;
  ${(props) => getVideoStyleFromSize(props.size)}
`;
// Styled Components
const VideoCallWrapper = styled.div`
  background-color: #2d3245;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  padding: 70px 150px;

  @media screen and (max-width: 991px) {
    padding: 30px;
  }
`;

const NameProjectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

const MiraxisWhiteLogo = styled.img`
  max-width: 120px;
  max-height: 20px;

  @media screen and (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const MuteIcon = styled.img`
  position: absolute;
  top: 10px;
  height: 32px;
  width: 32px;
  z-index: 99999;
  left: 10px;
`;

const MuteWhiteIcon = styled.img`
  position: absolute;
  bottom: 6px;
  height: 24px;
  width: 24px;
  z-index: 99999;
  right: 6px;
`;

const CallViewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  max-height: calc(100% - 120px);
  height: 100%;
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const VideoElem1 = styled.video`
  border-radius: 12px;
  height: 100%;
  width: 100%;
  object-fit: fill;
`;

const VideoElem1Image = styled.img`
  ${({ isVideoCall }) =>
    isVideoCall
      ? css`
          height: 100%;
          width: 100%;
          border-radius: 10px;
        `
      : css`
          height: 200px;
          width: 200px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid #ffffff;
        `}
`;
const VideoElem2Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const VideoElem2 = styled.video`
  border-radius: 12px;
  width: 178px;
  height: 100px;
`;

const YouText = styled.p`
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 18px;
  img {
    cursor: pointer;
  }
`;
