import React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import PatientCard from "../cards/PatientCard";
import Button from "components/Button";
import styles from "../Notices.module.scss"
import SearchBar from "accountOwner/pages/Notes/component/Filters/SearchBar";
import Loader from "components/Loader";

const SelectStaff = ({
    t,
    isModalOpen,
    showLoader,
    closeModal,
    staffMembers,
    staff,
    handleStaffRadio,
    handleApply,
    searchValue,
    setSearchValue,
    handleCancel
}) => {

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    }

    return (
        <Modal
            isOpen={isModalOpen}
            toggle={closeModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            {showLoader && <Loader />}
            <span className="close-btn" onClick={closeModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <h2 className="title">{t("notes.selectStaffMember")}</h2>
                <SearchBar searchValue={searchValue} t={t} handleSearchTerm={handleSearch} isModal={true} />
                <div className="mt-4">
                    {staffMembers?.map((staffMember) => (
                        <PatientCard
                            key={staffMember?.id}
                            staff={staffMember}
                            selectedStaff={staff}
                            handleStaffRadio={handleStaffRadio}
                        />
                    ))}
                </div>
                <div className={styles.btn_wrapper}>
                    <Button onClick={handleApply}>
                        {t("notices.apply")}
                    </Button>
                    <Button borderedSecondaryNew onClick={handleCancel}>
                        <span>{t("notices.cancel")}</span>
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default withTranslation()(SelectStaff);









