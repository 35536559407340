import SearchIcon from "../../../../assets/images/search.svg";

const PerformanceReviewSearchBar = ({
  t,
  handleSearchTerm,
  searchValue,
  placeholderText,
}) => {
  return (
    <div className="search-box from-patient-chat custom-search order-1 order-lg-2 mb-3 mb-lg-0 mt-0 w-100">
      <input
        type="text"
        // placeholder={t("performanceReview.searchByStaffName")}
        placeholder={placeholderText}
        onChange={handleSearchTerm}
        value={searchValue}
      />
      <span className="ico">
        <img src={SearchIcon} alt="icon" />
      </span>
    </div>
  );
};

export default PerformanceReviewSearchBar;
