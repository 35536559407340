import React, { useState } from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { addDiscussionNote, updateDiscussionNote } from "repositories/discussion-notes-repository";
import { handleError, handleSuccess } from "utils";
import { cloneDeep } from "lodash";
import Loader from "components/Loader";
import styles from "../DiscussionNotes.module.scss"

const DiscussionNoteModal = ({ t, staffId, closeModal, isModalOpen, selectNote, isEditing, handleUpdateNotes, refetchNote, handleUpdateList }) => {
	const [description, setDescription] = useState(selectNote?.description || '');
	const [error, seterror] = useState('')

	const { mutate: addDiscussionMutate, isLoading: loading } = useMutation(
		({ id, descriptionValue }) => addDiscussionNote(id, descriptionValue), {
		onSuccess: (data) => {
			handleSuccess(data.message);
			if (refetchNote) refetchNote();
			if (handleUpdateList) handleUpdateList(staffId)
			closeModal();
		},
		onError: (err) => {
			handleError(err);
		}
	});

	const { mutate: updateDiscussionMutate, isLoading: updateLoading } = useMutation(
		({ id, descriptionValue }) => updateDiscussionNote(id, descriptionValue), {
		onSuccess: (data) => {
			handleSuccess(data.message);
			if (handleUpdateNotes) handleUpdateNotes(selectNote?.id, { description: description })
			closeModal();
		},
		onError: (err) => {
			handleError(err);
		}
	});

	const isValidEvent = () => {
		let errorsData = cloneDeep(error);
		if (!description.trim().length) {
			errorsData = t("form.errors.emptyField", {
				field: t("description"),
			});
		} else {
			errorsData = '';
		}
		seterror(errorsData);
		return !errorsData;
	}

	const handleAddNote = async () => {
		if (isValidEvent()) {
			if (isEditing) {
				updateDiscussionMutate({ id: selectNote?.id, descriptionValue: description })
			} else {
				addDiscussionMutate({ id: staffId, descriptionValue: description })
			}
		}
	}
	return (
		<Modal
			isOpen={isModalOpen}
			toggle={closeModal}
			className="modal-dialog-centered modal-lg"
			modalClassName="custom-modal"
		>
			{(loading || updateLoading) && <Loader />}
			<span className="close-btn" onClick={closeModal}>
				<img src={crossIcon} alt="close" />
			</span>
			<ModalBody>
				<div>
					<Text size="25px" weight="500" color="#111B45" marginBottom="40px">
						{isEditing ? 'Edit Note' : 'New Discussion Note'}
					</Text>

					<div className="c-field">
						<label>{t('discussionNote.description')}</label>
						<textarea
							className="c-form-control"
							placeholder="Enter"
							name="Description"
							maxLength="2500"
							value={description}
							onChange={e => setDescription(e.target.value)}
						></textarea>
						{error && <span className="error-msg">{error}</span>}
					</div>

					<div className={"btn-box d-md-flex"}>
						<button
							className={"button button-round button-shadow mr-md-4 w-sm-100"}
							title="save"
							onClick={handleAddNote}
						>
							{t('discussionNote.save')}
						</button>
						<button
							className={
								" button button-round button-border btn-mobile-link button-dark"
							}
							title="cancel"
							onClick={closeModal}
						>
							{t("notes.cancel")}
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>

	);
};
export default withTranslation()(DiscussionNoteModal);
