import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import ToggleSwitch from "components/ToggleSwitch";
import styles from "../../TaskManagement.module.scss";
import Exlamatory_Icon from "../../../../../assets/images/red_exclamatory_icon.svg";
import { calculateCompleteionTime, formatUTCDateTime } from "utils";
import Input from 'components/Input'
import { useSelector } from "react-redux";

const LeftSection = ({ t, taskDetail, checkOverdueTasks, getButtonClass, getStatus, handleActiontask, activeTab }) => {
    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState('');
    const profile = useSelector(state => state.userProfile.profile);
    const [completiondata, setComplitionData] = useState({ hours: 0, minutes: 0 });

    const handleCompletionChange = (event) => {
        const { name, value } = event.target;

        if (name === 'minutes' && (value < 0 || value > 59)) {
            return;
        }

        if (name === 'hours' && value !== '' && isNaN(value)) {
            return;
        }

        setComplitionData({ ...completiondata, [name]: value });
    }
    const handleChange = (val) => {
        if (val && val.trim().length && val.trim().length > 400) {
            val = val.trim().substring(0, 400);
        }
        setReason(val);
        setReasonError('');
    }

    const submitCompleteTask = () => {
        let totalMinutes = (+completiondata.hours) * 60 + (+completiondata.minutes);
        let params = {
            completionTimeInMinutes: totalMinutes,
        }
        handleActiontask(taskDetail?.id, "Complete", params);
    }

    const submitReason = () => {
        if (reason && reason.trim().length > 1) {
            let param = {
                rejectionReason: reason
            };
            handleActiontask(taskDetail?.id, "Reject", param);
        } else {
            setReasonError(t('taskManagement.errorMsgOfRejection'));
        }
    }

    const getPriorityClass = (taskPriority) => {
        switch (taskPriority) {
            case "HIGH":
                return {
                    wrapperClass: styles["high-priority-wrapper"],
                    dotClass: styles["high-priority-dot"],
                    textClass: styles["high-priority-text"],
                };
            case "MEDIUM":
                return {
                    wrapperClass: styles["medium-priority-wrapper"],
                    dotClass: styles["medium-priority-dot"],
                    textClass: styles["medium-priority-text"],
                };
            case "LOW":
                return {
                    wrapperClass: styles["low-priority-wrapper"],
                    dotClass: styles["low-priority-dot"],
                    textClass: styles["low-priority-text"],
                };
            default:
                return {
                    wrapperClass: "",
                    dotClass: "",
                    textClass: "",
                };
        }
    };

    const getPriority = (priority) => {
        const priorityMap = {
            1: "HIGH",
            2: "MEDIUM",
            3: "LOW"
        };
        return priorityMap[priority] || "All";
    };

    const { wrapperClass, dotClass, textClass } = getPriorityClass(getPriority(taskDetail?.priority));

    return (
        <div className={styles["left_section"]}>
            {checkOverdueTasks(taskDetail?.dueDate, taskDetail?.status) && <div className="d-flex align-items-center gap-3">
                <img
                    src={Exlamatory_Icon}
                    alt="Exlamatory_Icon"
                    className="mr-2"
                />
                <p className={styles["overdue_warning"] + " " + "m-0"}>
                    {t("taskManagement.taskOverdue")}
                </p>
            </div>}
            <div className={styles["status_priority_date_wrapper"]}>
                <div className={styles["priority_status"]}>
                    <button className={getButtonClass(getStatus(taskDetail?.status))}>{getStatus(taskDetail?.status)}</button>
                    <div
                        className={`${styles["circle_priority_text_wrapper"]} ${wrapperClass}`}
                    >
                        <span
                            className={`${styles["circular_dot"]} ${dotClass}`}
                        ></span>
                        <p className={`${styles["priority_text"]} ${textClass}`}>
                            {getPriority(taskDetail?.priority)}
                        </p>
                    </div>
                </div>
                <div className={styles["date"]}> {formatUTCDateTime(taskDetail?.createdAt)}</div>
            </div>
            <Text size="20px" weight="500" color="#111B45" marginTop="30px">
                {taskDetail?.title}
            </Text>
            <div className={styles["office_assignby_wrapper"]}>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.startDate")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {formatUTCDateTime(taskDetail?.startDate)}
                    </Text>
                </div>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.dueDate")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {formatUTCDateTime(taskDetail?.dueDate)}
                    </Text>
                </div>
            </div>
            <div className={styles["office_assignby_wrapper"]}>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.assignedBy")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {`${taskDetail?.assignor?.firstName} ${taskDetail?.assignor?.lastName}`}
                    </Text>
                </div>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.assignedTo")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {`${taskDetail?.user?.firstName} ${taskDetail?.user?.lastName}`}
                    </Text>
                </div>
            </div>

            <div className={styles["description"]}>
                <Text size="12px" weight="400" color="#6F7788">
                    {t("taskManagement.description")}
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                    {taskDetail?.description}
                </Text>
            </div>
            {taskDetail?.status === 5 && (
                <div className={styles["office_assignby_wrapper"]}>
                    <div className={styles["office_name"]}>
                        <Text size="12px" weight="400" color="#6F7788">
                            {t("taskManagement.completedOn")}
                        </Text>
                        <Text size="14px" weight="600" color="#102C42">
                            {formatUTCDateTime(taskDetail?.completedOn)}
                        </Text>
                    </div>
                    <div className={styles["office_name"]}>
                        <Text size="12px" weight="400" color="#6F7788">
                            {t("taskManagement.completedtime")}
                        </Text>
                        <Text size="14px" weight="600" color="#102C42">
                            {calculateCompleteionTime(taskDetail?.completionTimeInMinutes)}
                        </Text>
                    </div>
                </div>
            )}

            {taskDetail?.status === 6 && (
                <div className={styles.reason_for_rejection}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.reasonOfRejection")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42" marginTop="4px">
                        {taskDetail?.rejectionReason}
                    </Text>
                </div>
            )}
            {((profile?.role?.systemRole === 3 && activeTab === 1)) && (<div className="mt-4">
                {(getStatus(taskDetail?.status) === "Pending") ? <div className={styles["dropdown_list"] + " " + "p-0"}>
                    <div className="c-field mt-4">
                        <label>{t('taskManagement.reasonOfRejection')}</label>
                        <textarea
                            placeholder={t('taskManagement.reasonOfRejection')}
                            className={"c-form-control " + styles["custom-textarea-control"]}
                            name="reason"
                            maxLength="400"
                            onChange={(e) => handleChange(e.currentTarget.value)}
                            value={reason}
                        ></textarea>
                        {(!reason || !reason.trim().length) && reasonError && <span className="error-msg">{reasonError}</span>}
                    </div>
                    <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                        onClick={() => handleActiontask(taskDetail?.id, "Accept")}>
                        {t("taskManagement.accept")}
                    </button>
                    <button className="button button-round button-border button-dark btn-mobile-link"
                        onClick={() => submitReason()}>
                        {t("taskManagement.reject")}
                    </button>
                </div> : ""}
                {(getStatus(taskDetail?.status) === "Accepted") ?
                    <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                        onClick={() => handleActiontask(taskDetail?.id, "Start")}
                    >
                        {t("taskManagement.start")}
                    </button>
                    : ""}
                {(taskDetail?.status === 4) ? <div>
                    <div className="task_completion_wrapper">
                        <Text size="14px" weight="600" color="#102C42">{t("taskManagement.completionTime")}</Text>
                        <div className="task_completion">
                            <Input
                                Title="Hours"
                                Type="Number"
                                Placeholder="Hours"
                                Name="hours"
                                HandleChange={(e) => handleCompletionChange(e)}
                                value={completiondata.hours}
                                Classes="task_completion_input"
                            />
                            <Input
                                Title="Minutes"
                                Type="Number"
                                Placeholder="Minutes"
                                Name="minutes"
                                HandleChange={(e) => handleCompletionChange(e)}
                                maxLength={60}
                                value={completiondata.minutes}
                                Classes="task_completion_input"
                            />
                        </div>
                    </div>
                    <button className="button button-round button-shadow mr-md-4 w-sm-100"
                        title={t("accountOwner.cancelSchedule")}
                        onClick={submitCompleteTask}
                    >
                        {t("taskManagement.markComplete")}
                    </button>
                </div>
                    : ""}
            </div>)}
        </div>
    );
};
export default withTranslation()(LeftSection);
