import React, { useState } from 'react'
import { Card } from "reactstrap";
import { useHistory } from 'react-router-dom';
import Page from "components/Page";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Text from 'components/Text';
import { Modal, ModalBody } from "reactstrap";
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import styles from "../../Appointments.module.scss";

function SelectDateTime({t}) {
  
  const [modalOpen, setModalOpen] = React.useState(false);
  const history = useHistory();
  const numberOfDivs = 49;
  const startTime = new Date();
  startTime.setHours(9, 30, 0);

  const goBack = () => {
    history.push(
      '/create-recall-appointment'
    );
  };

  const renderDivs = () => {
    const divs = [];
    for (let i = 0; i < numberOfDivs; i++) {
      const time = new Date(startTime.getTime() + i * 15 * 60000); // Calculate time with a difference of 15 minutes
      const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time as HH:mm
      divs.push(
        <Card className='common-time-box' key={i}>
          {formattedTime}
        </Card>
      );
    }
    return divs;
  };

  const ShowDate = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
      <ReactDatePicker selected={startDate} dateFormat="MMM d, yyyy" onChange={(date) => setStartDate(date)} className="c-form-control" />
    );
  };

  return (
    <Page onBack={goBack} className="main-page">
      <div className='manage-schedule-heading d-flex justify-content-between'>
        <div>
          <h3>{t("patientAppointmentMembers.newAppointment")}</h3>
        </div>
      </div>
      

      <Card className='form-wrapper'>
        <div className='content-part d-flex flex-column'>
          <div className=' recall-date-box d-flex flex-column'>
            <div>
              <Text color='#6F7788' weight='400' size='12px' className='selected-date'>{t("patientAppointmentMembers.doctorName")}</Text>
              <Text color='#102C42' weight='600' size='14px' className='selected-date'>Florian Smith </Text>
            </div>
            <div className="c-field">
              <label>{t("patientAppointmentMembers.changeDate")}</label>
              <div className="d-flex inputdate">
                <ShowDate />
              </div>
            </div>
          </div>
          <div className='book-slot d-flex flex-column'>
            <Text color='#587E85' weight='600' size='16px' className='book-slot-heading'>{t("patientAppointmentMembers.bookingTimeslot")}</Text>
            <div className='d-flex flex-column'>
              <Text color='#87928D' weight='400' size='10px' className='time-zone'>{t("patientAppointmentMembers.timezone")}</Text>
              <Text color='#2F3245' weight='500' size='12px' className='pst'>{t("patientAppointmentMembers.pst")}</Text>
            </div>
            <div>
              <Text color='#6F7788' weight='400' size='12px' className='timeslot-text'> {t("patientAppointmentMembers.SelectTimeslotsAndThenChooseIfYouWantToBlockIt")}</Text>
            </div>
            <div className={styles['notify-checkbox'] +" "+" d-flex "}>
                <div className={styles['checkbox-container']+" "+" d-flex flex-row "}>
                  <div className={styles['show-checkedbox'] +" "+ styles["box1"]}></div>
                  Booked
                </div>
                <div className={styles['checkbox-container']+" "+" d-flex flex-row"}>
                  <div className={styles['show-checkedbox'] +" "+  styles['box2']}></div>
                  Available
                </div>
                <div className={styles['checkbox-container'] +" "+"d-flex flex-row"}>
                  <div className={styles['show-checkedbox'] +" "+ styles["box3"]}></div>
                  Blocked
                </div>
              </div>
            <div className='show-time-box d-flex flex-wrap'>
              {renderDivs()}
            </div>
          </div>
          <div className='btn-container d-flex'>
            <Link>
              <button className='button button-round button-shadow w-100'>
                {t("patientAppointmentMembers.createAppointment")}
              </button>
            </Link>
            <button className='button button-round button-border button-dark' onClick={() => setModalOpen(!modalOpen)}>
              {t("patientAppointmentMembers.cancelAppointment")}
            </button>
          </div>
        </div>
      </Card>
      {/* Modal for selecting the reason of block timeslot  */}
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} className="modal-dialog-centered deactivate-modal modal-width-660">
        <div className='timeslot-modal'>
          <ModalBody className='d-flex flex-column modal-content-part p-0'>
            <div>
              <span className="close-btn d-flex justify-content-end" onClick={() => setModalOpen(!modalOpen)}>
                <img src={require('assets/images/cross.svg').default} alt="close" />
              </span>
              <div className='text-center'>
                <Text color='#111B45' size='25px' weight='500' marginBottom='11px'>{t("patientAppointmentMembers.confirmation")}</Text>
                <Text color='#535B5F' weight='300' size='16px'>{t("patientAppointmentMembers.areYouSureYouWantToCancelNextVisit")}</Text>
              </div>
            </div>
            <div className='btn-container w-100 d-flex modal-btn-container justify-content-center'>
              <button className='button button-round button-shadow'>{t("patientAppointmentMembers.yesConfirm")} </button>
              <button className='button button-round button-border button-dark'>  {t("patientAppointmentMembers.cancel")} </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </Page>
  )
}
export default withTranslation()(SelectDateTime);