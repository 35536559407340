import React from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import  {
    prioritiesOptions
  } from "../../../../constants";

function PriorityCheckFitler({ setPriorityFilter, seletedPriorty}) {

    const handleChange = (e, item) => {
        const priority = [...seletedPriorty];

        const staffIndex = priority?.findIndex((v) => +item.id === +v);
        if (e.target.checked) {
            priority.push(item.id);
        } else {
            if (staffIndex > -1) priority.splice(staffIndex, 1);
        }
        setPriorityFilter(priority);
    };

    return (
        <ul className={["filter-list"]}>
            {prioritiesOptions.map((item, key) => (
                        <li key={key}>
                            <div className="ch-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChange(e, item)}
                                        checked={seletedPriorty?.includes(item.id)}
                                    />{" "}
                                    <span> {`${item.name}`}</span>
                                </label>
                            </div>
                        </li>
                    ))}
        </ul>
    );
}

export default PriorityCheckFitler;
