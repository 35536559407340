import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Page from 'components/Page';
import Text from 'components/Text';
import { Card } from 'reactstrap';
import plusImg from "../../../../../../../assets/images/Group 6.svg"
import Empty from 'components/Empty';
import CustomDropdown from 'components/Dropdown';
import { useHistory } from 'react-router-dom';
import styles from "../../../../Appointments.module.scss"
import { Link } from 'react-router-dom/cjs/react-router-dom';
import AppointmentReminder from 'accountOwner/pages/Appointments/components/AppointmentReminder';

function SelectReminderPlan({t}) {
    const history = useHistory();

    const RepeatOptions = [
        { id: 1, name: `${t("Reminder 1")}` },
        { id: 2, name: `${t("Reminder 2")}` },
        { id: 3, name: `${t("Reminder 3")}` },
    ];
  
    const [repeatedType, setRepeatedType] = useState(1);
  return (
    <Page
        onBack={() => { history.push(`/create-appointment`) }}
        >
            <div className={styles["reminder-plan-modal"]}>
                <p className={styles["mobile-text-size"]} >{t('patientAppointmentMembers.selectAppointmentReminderPlanFor')} Elizabeth Becker</p>
                <Link to="/add-appointment-reminder-plan" className="w-sm-100" >
                    <button className="button button-round button-shadow mr-md-4 w-sm-100">
                        {t('patientAppointmentMembers.addNewReminderPlan')}
                    </button>
                </Link>
            </div>
            <Card className="form-wrapper">
                <div className={styles["select-appointment-reminder-dropdown"] + " " + "c-field appointment-custom-dropdown"}>
                    <div className={styles["custom-dropdown-only"] + " " + "custom-dropdown-only w-100"}>
                        <Text size="13px" weight="400" color="#79869A">{t('patientAppointmentMembers.selectReminderPlan')}</Text>
                        <CustomDropdown
                            options={RepeatOptions}
                            selectedOption={repeatedType}
                            selectOption={(id) => {
                                setRepeatedType(id);
                            }}
                        />
                    </div>
                    <div className={styles['reject-yellow-warning-box']}>
                        <Text size="10px" weight="500" color="#2F3245">{t('patientAppointmentMembers.ReminderPlanText')}</Text>
                    </div>
                </div>
                <div className={styles['select-appointment-reminder-plan']}>
              
                    <div className={styles['appointment-reminder-modal-card-Wrapper']}>
                          <AppointmentReminder/>
                    </div>
                    <button className={styles['illness-type-save-cancel-btn'] + " " + styles["add-new-member-btn"]}>
                        <span>
                            <img src={plusImg} alt="ds" className="mr-1" />
                        </span>
                        {t('patientAppointmentMembers.addNewReminder')}
                    </button>
                    <div className={styles['common-appointment-btn-container']}>
                    <button className='button button-round button-shadow mr-md-4 mb-3 w-sm-100'>{t('patientAppointmentMembers.apply')}</button>
                    <button className='button button-round button-border button-dark btn-mobile-link'>{t('patientAppointmentMembers.cancel')}</button>
                    </div>
                </div>
                {/* { <Empty
              Message="No reminders available for your selection"
            />} */}
            </Card>
        </Page>
  )
}
export default withTranslation()(SelectReminderPlan)