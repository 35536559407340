import { castDraft, produce } from "immer";
import { initialState } from "./state";

/** Reducer */
export const reducer = (prevState, action) => {
  switch (action.type) {
    case "AUTH":
      return produce(prevState, (draft) => {
        draft.user = action.payload;
      });
    case "DEAUTH":
      return initialState;
    case "ADD_CALL":
      return produce(prevState, (draft) => {
        draft.calls.push(castDraft(action.payload));
      });
    case "AppointMent_User":
      return produce(prevState, (draft) => {
        draft.appointmentUser = action.payload.appointmentUser;
        draft.appointmentDetail = action.payload.appointmentDetail;
      });

    case "APP_INITIALIZED":
      return produce(prevState, (draft) => {
        draft.appIntialized = true;
      });
    case "RINGING": {
      const isBusy = prevState.calls.some((call) => !call.isEnded);
      const call = action.payload;
      if (isBusy) {
        call.end();
      }
      return produce(prevState, (draft) => {
        draft.calls.push(castDraft(call));
      });
    }
    case "UPDATE_CALL":
      return produce(prevState, (draft) => {
        const index = draft.calls.findIndex(
          (c) => c.callId === action.payload.callId
        );
        if (index === -1) return;
        Object.assign(draft.calls[index], castDraft(action.payload));
      });
    case "CLEAR_CALLS":
      return produce(prevState, (draft) => {
        draft.calls = castDraft(initialState.calls);
      });
    case "UPDATE_ROOM":
      return produce(prevState, (draft) => {
        const index = draft.rooms.findIndex(
          (c) => c.roomId === action.payload.roomId
        );
        if (index === -1) return;
        Object.assign(draft.rooms[index], castDraft(action.payload));
      });
    case "CLEAR_ROOMS":
      return produce(prevState, (draft) => {
        draft.rooms = castDraft(initialState.rooms);
      });
    case "UPDATE_AUDIO_INPUT_DEVICE_INFO":
      return produce(prevState, (draft) => {
        draft.audioInputDeviceInfo = {
          ...prevState.audioInputDeviceInfo,
          ...action.payload,
        };
      });
    case "UPDATE_AUDIO_OUTPUT_DEVICE_INFO":
      return produce(prevState, (draft) => {
        draft.audioOutputDeviceInfo = {
          ...prevState.audioOutputDeviceInfo,
          ...action.payload,
        };
      });
    case "UPDATE_VIDEO_INPUT_DEVICE_INFO":
      return produce(prevState, (draft) => {
        draft.videoInputDeviceInfo = {
          ...prevState.videoInputDeviceInfo,
          ...action.payload,
        };
      });
    default:
      return prevState;
  }
};
