/* eslint-disable no-useless-concat */
import Page from 'components/Page'
import Text from 'components/Text'
import React, { useContext, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Card } from 'reactstrap'
import CustomDropdown from 'components/Dropdown'
import styles from "../../Appointments.module.scss"
import constants from "../../../../../constants";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { decodeId, encodeId, getStorage, setStorage } from 'utils'
import { AppointmentsContext } from 'accountOwner/pages/Appointments/AppointmentsContext';
import appointmentStyles from "../../Appointments.module.scss";
import usePatientAppointmentCompletedModule from '../../hooks/usePatientAppointmentCompletedModule'
import Loader from 'components/Loader'
import ReactDatePicker from 'react-datepicker'
import useReadOnlyDateTextInput from 'hooks/useReadOnlyDateTextInput'
import { Portal } from 'react-overlays'
import moment from 'moment'

let didPatientAttent = [{ label: "Yes", id: 1 }, { label: "No", id: 2 }]
const KEY_NAME = 'complete-reminder';
const FeedbackConfirmation = ({ t, history }) => {
    let { officeId, appointmentId } = useParams();
    appointmentId = decodeId(appointmentId);
    officeId = decodeId(officeId);
    const officeState = getStorage('officeState')

    const {
        patientRadioCheck,
        nextRecallRadioCheck,
        handleChangePatientRadioCheck,
        handleChangeNextRecallRadioCheck,
        reminderPlan,
        options,
        selectedAppointmentType,
        selectedReminderPlan,
        repeatedType,
        handleSelectRecallDropDown,
        reoccuringcheck,
        handleReoccuringcheck,
        dates,
        utcDates,
        updateDatesValues,
    } = useContext(AppointmentsContext);

    const {
        data: {
            showLoader,
            errors
        },
        methods: {
            handleCompleted,
            goToPreviousScreen
        }
    } = usePatientAppointmentCompletedModule(t, officeState.officeState, KEY_NAME);




    const redirectAppointmentType = (e) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        history.push({ pathname: constants.routes.appointment.appointmentType.replace(':officeId', encodeId(officeId)).replace(':requestId', encodeId(appointmentId)), state: { isFromCompletePage: true } });
    };

    const redirectSelectReminder = (e) => {
        if (e.target.closest('.interactive-element')) {
            return;
        }
        history.push({ pathname: constants.routes.appointment.appointmentReminder.replace(':officeId', encodeId(officeId)).replace(':requestId', encodeId(appointmentId)), state: { isFromCompletePage: true } });
    };

    useEffect(() => {
        setStorage('complete', {
            isFromCompletePage :true,
        });
    }, [])

    let handleSubmit = async (e) => {
        let isPatientVisited = patientRadioCheck?.id === 1 ? true : false;
        let isNextRecall = nextRecallRadioCheck?.id === 1 ? true : false
        const params = {
            appointmentId: appointmentId,
            didPatientAttended: isPatientVisited,
            AddNextRecall: isNextRecall,
            AppointmentTypeId: isNextRecall ? selectedAppointmentType?.[KEY_NAME]?.appointmentTypeId : null,
            AppointmentRecallRequest: {
                AppointmentReminderPlanId: selectedReminderPlan ? selectedReminderPlan?.[KEY_NAME]?.appointmentReminderPlanId : null,
                RecallInDay:
                    repeatedType != 1 && isNextRecall ? repeatedType : 0,
                IsRecurring:
                    repeatedType != 1 && isNextRecall ? reoccuringcheck : false,
                RecallDate:
                    repeatedType == 1 && isNextRecall ? utcDates.startDate : null,
            },
        };
        await handleCompleted(e, params);
    }

    const startDatePickerRef = useReadOnlyDateTextInput();

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };

    return (
        <Page onBack={goToPreviousScreen}>
            {showLoader && <Loader />}
            <Text size="25px" weight="500" color="#111B45" marginBottom="30px">{t("patientAppointmentMembers.confirmation")}</Text>
            <Card className="form-wrapper">
                <div className={``}>
                    <Text size="13px" weight="400" color="#79869A" marginBottom="10px">{t("patientAppointmentMembers.didPatientAttend")}</Text>
                    <div className='d-flex mb-4'>
                        {didPatientAttent?.map(item => {
                            return (
                                <div key = {item?.id} className="ch-radio">
                                    <label className="mr-5">
                                        <input
                                            type="radio"
                                            name="blockTimeslotAllDoctors"
                                            onChange={() => handleChangePatientRadioCheck(item)}
                                            checked={patientRadioCheck?.id === item?.id}
                                        />
                                        <span> {item.label} </span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>

                    <Text size="13px" weight="400" color="#79869A" marginBottom="10px">{t("patientAppointmentMembers.doYouWantAddNextRecall")}</Text>
                    <div className='d-flex mb-4'>
                        {didPatientAttent?.map(item => {
                            return (
                                <div key = {item?.id} className="ch-radio">
                                    <label className="mr-5">
                                        <input
                                            type="radio"
                                            name="blockTimes"
                                            onChange={() => handleChangeNextRecallRadioCheck(item)}
                                            checked={nextRecallRadioCheck?.id === item?.id}
                                        />
                                        <span> {item.label} </span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {nextRecallRadioCheck?.id === 1 && (
                    <>
                        <div className="c-field w-50">
                            <label>{t("patientAppointmentMembers.SelectRecallTime")}</label>
                            <div className="custom-dropdown-only">
                                <CustomDropdown
                                    options={constants.completedAppointmentDateTime}
                                    selectedOption={repeatedType}
                                    selectOption={(id, name) => {
                                        handleSelectRecallDropDown(id);
                                    }}
                                />
                            </div>
                        </div>
                        {repeatedType === 1 && (
                            <div className={styles["date_wrapper"] + " " + "c-field mt-3 w-50"}>
                                <div className="inputdate">
                                    <ReactDatePicker
                                        dateFormat="dd-MM-yyyy"
                                        className="c-form-control"
                                        selected={dates.startDate}
                                        onChange={(e) => updateDatesValues(e, "startDate")}
                                        popperPlacement="bottom-start"
                                        popperContainer={CalendarContainer}
                                        ref={startDatePickerRef}
                                        openToDate={moment().toDate()}
                                        minDate={moment().toDate()}
                                    />
                                </div>
                            </div>)}
                        {repeatedType != 1 && (<div className="ch-checkbox c-field all-event-checkbox mt-4">
                            <label>
                                <input type="checkbox" onChange={handleReoccuringcheck} checked={reoccuringcheck} />
                                <span>{t("patientAppointmentMembers.makeItRecurring")}</span>
                            </label>
                        </div>)}
                        
                        <div className={styles['heading-data-Wrapper'] + " " + "w-50"}>
                            <div className={styles['head-link-wrapper']}>
                                <div className={styles['appointment-head']}>{t("patientAppointmentMembers.typeOfNextVisit")}</div>
                                <div className={styles["appointment-link"]} onClick={redirectAppointmentType}>{t("patientAppointmentMembers.selectType")}</div>
                            </div>
                            <div className={styles['appointment-text']}> {selectedAppointmentType?.[KEY_NAME]?.title || ""}</div>
                        </div>
                        {errors?.TypeError && <span className="error-msg">{errors?.TypeError}</span>}
                        <div className={styles['heading-data-Wrapper'] + " " + "w-50"}>
                            <div className={appointmentStyles['head-link-wrapper']}>
                                <div className={appointmentStyles['appointment-head']}>{t("patientAppointmentMembers.reminderPlan")}</div>
                                <div onClick={redirectSelectReminder} className={styles["appointment-link"]}>
                                    {reminderPlan?.[KEY_NAME]?.title ? t("patientAppointmentMembers.changeReminderPlan") : t("patientAppointmentMembers.selectReminderPlan")}
                                </div>
                            </div>
                            {reminderPlan?.[KEY_NAME] &&  <div className={appointmentStyles['appointment-modal-card']}>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{reminderPlan?.[KEY_NAME]?.title || ''}</Text>
                                {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.map(plan =>
                                    <div className='mt-2' key={plan?.id}>
                                        <Text size="14px" weight="600" color="#102C42" >{plan?.reminderBefore + ' ' +
                                            options.find(
                                                m => m.id == plan?.reminderBeforeType,
                                            )?.name +
                                            ' ' +
                                            t("patientAppointmentMembers.before")}
                                        </Text>
                                        <Text size="14px" weight="600" color="#102C42" >
                                            {[plan.isEmail && t("patientAppointmentMembers.isEmail"), plan.isSms && t("patientAppointmentMembers.isSms"), plan.isPushNotification && t("patientAppointmentMembers.isPushNotification")]
                                                .filter(Boolean)
                                                .join(', ')}
                                        </Text>
                                    </div>
                                )}
                            </div>}
                        </div>
                    </>)
                }
                <div className={styles['common-appointment-btn-container']}>
                    <Button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={(e) => handleSubmit(e)}>{t("patientAppointmentMembers.markAsComplete")}</Button>
                    <Button className="button button-round button-border button-dark btn-mobile-link" onClick={goToPreviousScreen}>{t("patientAppointmentMembers.cancel")}</Button>
                </div>
            </Card>
        </Page>
    )
}
export default withTranslation()(FeedbackConfirmation);
