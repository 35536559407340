import React, { useEffect, useState } from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { uniqBy } from "lodash";
import { useAppointmentType } from "repositories/patient-appointment-repository";

function RecallTypeList({t, recallTypeFilter, setRecallTypeFilter, state}) {
    const [recallType ,setRecallType] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const {data, isLoading } = useAppointmentType( pageNumber, 100, state?.officeId);

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setRecallType(uniqBy([...data?.items], "id"));
                setTotalItems(data?.pagination?.totalItems);
            }
        }
        else if (!isLoading && data && data.items) {
            setRecallType(prev => uniqBy([...prev, ...data?.items], "id"));
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);


    const handleChange = (e, item) => {
        const offices = [...recallTypeFilter];
        const officeIndex = offices.findIndex((v) => +item.id === +v);
        if (e.target.checked) {
            offices.push(item.id);
        } else {
            if (officeIndex > -1) offices.splice(officeIndex, 1);
        }
        setRecallTypeFilter(offices);
    };

    return (
        <ul className={["filter-list"]}>
            {recallType?.length &&
                recallType?.map((item, key) => (
                    <li key={key}>
                        <div className="ch-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleChange(e, item)}
                                    checked={recallTypeFilter?.includes(item.id)}
                                />{" "}
                                <span> {item.title}</span>
                            </label>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default RecallTypeList;