import React, { Fragment, useEffect, useState } from "react";
import Text from "components/Text";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import Card from "components/Card";
import { withTranslation } from "react-i18next";
import styles from "./TaskManagement.module.scss";
import CommentIcon from "./../../../assets/images/comment_icon.svg";
import alertIcon from "../../../assets/images/redAlert.svg";
import DeleteModal from "./Modals/DeleteModal";
import CalcelModal from "./Modals/CancelModal";
import moment from "moment";
import { encodeId, formatUTCDateTime } from "utils";
import useCardModule from "./hooks/useCardModule";
import constants from "../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TaskManagementCard = ({ t, myTask, handleToggleAssigne, getButtonClass, className, handleAction, deleteAction, handleArchiveApi, isArchive }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isOverdue, setIsOverdue] = useState(false);
	const history = useHistory();
	const {
		data: {
			isCancelModalOpen,
			isDeleteModalOpen
		},
		methods: {
			handleCancelModal,
			handleDeleteModal,
			handleOwnerCardClick
		}
	} = useCardModule()

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const getStatus = (overallStatus) => {
		const statusMap = {
			1: "In Progress",
			2: "Pending",
			3: "Accepted",
			4: "Started",
			5: "Completed",
			6: "Rejected",
			7: "Cancelled"
		};
		return statusMap[overallStatus] || "";
	};


	const getPriority = (priority) => {
		const priorityMap = {
			1: "HIGH",
			2: "MEDIUM",
			3: "LOW"
		};
		return priorityMap[priority] || "All";
	};

	function checkOverdueTasks() {
		const now = moment.utc();
		const dueDate = moment.utc(myTask?.dueDate);
		const Overdue = dueDate?.isBefore(now);

		let status = getStatus(myTask?.overallStatus);
		if (Overdue) {
			if (status === "Pending" || status === "Accepted" || status === "Started")
				setIsOverdue(true);
		} else {
			setIsOverdue(false);
		}
	}

	useEffect(() => {
		checkOverdueTasks();
	}, [getPriority]);

	const getPriorityClass = (priority) => {
		switch (priority) {
			case "HIGH":
				return {
					wrapperClass: styles["high-priority-wrapper"],
					dotClass: styles["high-priority-dot"],
					textClass: styles["high-priority-text"],
				};
			case "MEDIUM":
				return {
					wrapperClass: styles["medium-priority-wrapper"],
					dotClass: styles["medium-priority-dot"],
					textClass: styles["medium-priority-text"],
				};
			case "LOW":
				return {
					wrapperClass: styles["low-priority-wrapper"],
					dotClass: styles["low-priority-dot"],
					textClass: styles["low-priority-text"],
				};
			default:
				return {
					wrapperClass: "",
					dotClass: "",
					textClass: "",
				};
		}
	};

	const editTask = (taskId) => {
		history.push(
			constants.routes.taskManagements.taskManagementEditNote.replace(":taskId", encodeId(taskId))
		);
	  };

	const { wrapperClass, dotClass, textClass } = getPriorityClass(getPriority(myTask?.priority));
	return (
		<Fragment>
			<Card className={className} onClick={(e) => handleOwnerCardClick(e, myTask?.id, history)}>
				{isOverdue && <div className={styles["alert_text_wrapper"]}>
					<img src={alertIcon} alt="close" />
					<Text size="11px" weight="400" color="#EE4F4F">
						{t("taskManagement.taskOverdue")}
					</Text>
				</div>}
				<div className={styles["card_top_wrapper"]}>
					<div className={styles["status_priority_date_wrapper"]}>
						<button className={getButtonClass(getStatus(myTask?.overallStatus))}>{getStatus(myTask?.overallStatus)}</button>
						<div
							className={`${styles["circle_priority_text_wrapper"]} ${wrapperClass}`}
						>
							<span className={`${styles["circular_dot"]} ${dotClass}`}></span>
							<p className={`${styles["priority_text"]} ${textClass}`}>
								{getPriority(myTask?.priority)}
							</p>
						</div>
						<div className={styles["date"]}>
							{formatUTCDateTime(myTask?.startDate)}
						</div>
					</div>

					<Dropdown isOpen={dropdownOpen} toggle={toggle} className="interactive-element">
						<DropdownToggle caret={false} tag="div" className="dropdown-btn px-3">
							<img src={require("assets/images/dots-icon.svg").default} alt="icon" />
						</DropdownToggle>
						<DropdownMenu right className={styles["custom_dropdown_wrapper"]}>
							{((myTask?.overallStatus === 2) || (myTask?.overallStatus === 6) || (myTask?.overallStatus === 7)) && <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
								<p
									className={styles["dropdown_link"] + " " + "mb-0"}
									onClick={handleDeleteModal}
								>
									{t("taskManagement.delete")}
								</p>
							</DropdownItem>}
							{!isArchive ? (<DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
								<p className={styles["dropdown_link"]} onClick={() => handleArchiveApi(myTask?.id, "Archive")}>
									{t("taskManagement.archive")}
								</p>
							</DropdownItem>) : (
								<DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
									<p className={styles["dropdown_link"]} onClick={() => handleArchiveApi(myTask?.id, "Unarchive")}>
										{t("taskManagement.unarchive")}
									</p>
								</DropdownItem>
							)
							}
							{(myTask?.overallStatus === 2) && (
								<DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
									<p className={styles["dropdown_link"]}
										onClick={handleCancelModal}
									>
										{t("taskManagement.cancel")}
									</p>
								</DropdownItem>
							)}
							<DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
								<p className={styles["dropdown_link"]} onClick={() => editTask(myTask?.id)}>
									{t("taskManagement.edit")}
								</p>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>

				</div>
				<p className={styles["card_main_heading"]}>
					{myTask?.title}
				</p>
				<div className={styles["office_assignby_wrapper"]}>
					{myTask?.totalAssignees === 1 ? (<div>
						<Text size="12px" weight="400" color="#6F7788">
							{t("taskManagement.assignedTo")}
						</Text>
						<Text size="14px" weight="600" color="#102C42" className={styles.break_word} >
							{`${myTask?.oneAssignee?.firstName} ${myTask?.oneAssignee?.lastName}`}
						</Text>
					</div>) :
						(<div className="interactive-element">
							<Text size="12px" weight="400" color="#6F7788">
								{t("taskManagement.assignedTo")}
							</Text>
							<p
								className={styles["link_btn"] + " " + "m-0"}
								onClick={() => handleToggleAssigne(myTask?.id, myTask?.totalAssignees)}
							>
								{myTask?.totalAssignees} {t("messenger.members")}
							</p>
						</div>)}
				</div>
				<hr></hr>
				<div className={styles["comment_date_wrapper"]}>
					<div>
						<img
							style={{ display: "inline-block" }}
							src={CommentIcon}
							alt="download-icon"
							className="mr-1"
						/>
						<span className={styles["comment_text"]}>
							{myTask?.totalComments} {t("taskManagement.comments")}
						</span>
					</div>
					<div className={styles["comment_text"]}>
						{t("taskManagement.due")} {formatUTCDateTime(myTask?.dueDate)}
					</div>
				</div>
			</Card>
			{isDeleteModalOpen && (
				<DeleteModal
					closeDeleteModal={handleDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					deleteAction={deleteAction}
					taskId={myTask?.id}
				/>
			)}
			{isCancelModalOpen && (
				<CalcelModal
					toggleModal={handleCancelModal}
					isModalOpen={isCancelModalOpen}
					handleAction={handleAction}
					taskId={myTask?.id}
				/>
			)}
		</Fragment>
	);
};
export default withTranslation()(TaskManagementCard);
