import AgendaTab from "accountOwner/pages/Scheduler/YourScheduleTab/SubTabs/AgendaTab";
import React from "react";
import { withTranslation } from "react-i18next";

const MySchedule = ({
  t,
  isFromDashBoard,
  agendaFiltersData,
  staffAvailabilityViewSelectedFromDashBoard,
  currentDateFromDashBoard,
  setCurrentDateFromDashBoard,
}) => {
  return (
    <>
      <AgendaTab
        agendaFiltersData={agendaFiltersData}
        isFromDashBoard={true}
        staffAvailabilityViewSelectedFromDashBoard={
          staffAvailabilityViewSelectedFromDashBoard
        }
        currentDateFromDashBoard={currentDateFromDashBoard}
        setCurrentDateFromDashBoard={setCurrentDateFromDashBoard}
      />
    </>
  );
};

export default withTranslation()(MySchedule);
