import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaffMembers } from "repositories/scheduler-repository";
import { encodeId } from "utils";
import constants from "../../../../constants";
import styles from "../Notes.module.scss";
import { updateProfile } from "repositories/notes-repository";
import { useMutation } from "react-query";

const useCardModule = (myNote, transferredNote, handleTransferNote, history) => {
    const profile = useSelector((state) => state.userProfile.profile);
    const dispatch = useDispatch();

    const { mutate: updateProfileMutate } = useMutation(updateProfile, {
        onSuccess: () => {
            dispatch({
                type: 'PROFILE',
                payload: { ...profile, neverShowTransferGlobalNoteMessage: true },
            });
        },
        onError: (error) => {
            console.error("Update profile failed: ", error);
        }
    });

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [status, setStatus] = useState("new");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModel] = useState(false);
    const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
    const [allMembersList, setAllMembersList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState({ id: '' });
    const [isStaffLoading, setIsStaffLoading] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isConfirmMessageShow, setIsConfirmMessageShow] = useState(false);

    const closeAlertModal = () => setIsConfirmModalOpen(false);

    const closeModal = () => setIsModalOpen(false);
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        const isSeen = myNote?.globalNoteTransferHistory[0]?.isSeen || transferredNote?.globalNote?.globalNoteTransferHistory[0]?.isSeen;
        if (isSeen) {
            setStatus("seen");
        } else {
            if (transferredNote) {
                setStatus("delivered");
            } else {
                setStatus("new");
            }
        }
    }, [status]);

    const getOfficeMembers = async (officeId) => {
        try {
            setIsStaffLoading(true);
            const res = await getStaffMembers(officeId);
            if (res && res.length) {
                setAllMembersList(res);
                setIsStaffLoading(false);
            }
        } catch (e) {
            setIsStaffLoading(false);
            console.log(e.message);
        }
    };

    const handleTransfer = (officeId) => {
        getOfficeMembers(officeId);
        setIsStaffModalOpen(true);
    };

    const closeStaffModal = () => setIsStaffModalOpen(false);

    const handleStaffSelection = (staff) => {
        setSelectedStaff({ id: staff?.id });
    };

    const handleApply = () => {
        if (!profile?.neverShowTransferGlobalNoteMessage) {
            setIsConfirmModalOpen(true);
        }
        if (profile?.neverShowTransferGlobalNoteMessage) {
            handleTransferNote(myNote?.id, selectedStaff?.id);
            setSelectedStaff('');
        }
        closeStaffModal();
    };

    const handleCancelConfirmMessage = () => {
        setSelectedStaff('');
        closeAlertModal();
    }

    const handleConfirmCheck = (e) => {
        setIsConfirmMessageShow(e.target.checked)
    }

    const handleConfirm = () => {
        if (isConfirmMessageShow) {
            updateProfileMutate()
        }
        handleTransferNote(myNote?.id, selectedStaff?.id);
        setSelectedStaff('');
    }

    const handleCancel = () => {
        setSelectedStaff('');
        closeStaffModal();
    };


    const getBackgroundColor = () => {
        const backgroundColors = {
            new: "#f9fcee",
            seen: "#F2F3EF",
            delivered: "#F9FCEE",
        };
        return backgroundColors[status] || backgroundColors.new;
    };

    const getButtonClass = () => {
        const buttonClassMap = {
            new: styles.new,
            seen: styles.seen,
            delivered: styles.delivered,
        };
        return `${styles.notes_btn} ${buttonClassMap[status] || ""}`;
    };

    function capitalizeAllLetters(string) {
        return string.toUpperCase();
    }

    const noteCardDetail = {
        note_date:
            myNote?.transferredAt || myNote?.createdAt || transferredNote?.assignedOn,
        detail_page: myNote ? constants.routes.noteDetails : constants.routes.transferredNoteDetails,
        reminderDate: myNote?.reminderDate || transferredNote?.reminderDate,
        title: myNote?.title || transferredNote?.globalNote?.title,
        officeName:
            myNote?.office?.name || transferredNote?.globalNote?.office?.name,
        name: myNote?.assigner?.fullName || transferredNote?.assignee?.fullName,
        fileLink : myNote?.fileLink || transferredNote?.globalNote?.fileLink
    };

    const handleCardClick = (e) => {
        if (e.target.closest(".interactive-element")) {
            return;
        }
        const incodeId = transferredNote ? transferredNote?.globalNoteId : myNote?.id;
        history.push({
            pathname: noteCardDetail?.detail_page.replace(":noteId", encodeId(incodeId)),
            state: { status }
        });
    };

    return {
        data: {
            noteCardDetail,
            profile,
            dropdownOpen,
            isModalOpen,
            isDeleteModelOpen,
            isStaffModalOpen,
            allMembersList,
            selectedStaff,
            isStaffLoading,
            status,
            isConfirmModalOpen
        },
        methods: {
            handleCardClick,
            capitalizeAllLetters,
            getButtonClass,
            getBackgroundColor,
            handleCancel,
            handleApply,
            handleStaffSelection,
            closeStaffModal,
            handleTransfer,
            toggleDropdown,
            closeModal,
            setIsDeleteModel,
            setIsModalOpen,
            closeAlertModal,
            handleConfirm,
            handleConfirmCheck,
            handleCancelConfirmMessage
        }
    }
}

export default useCardModule;
