import React, { useEffect, useState } from 'react';
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../../assets/images/cross.svg";
import styles from "../PatientMessageCenter.module.scss"
import PatientSearchBar from '../components/PatientSearchBar';
import { useAllMarketingPatient } from 'repositories/patient-appointment-repository';
import Loader from 'components/Loader';
import { uniqBy } from 'lodash';

const patients = [
    { id: 1, name: "Craig Siphron" },
    { id: 2, name: "Emery Vaccaro" },
    { id: 3, name: "Craig Siphron" },
    { id: 4, name: "Emery Vaccaro" },
    { id: 5, name: "Emery Vaccaro" },
    { id: 6, name: "Craig Siphron" },
    { id: 7, name: "Emery Vaccaro" },
];
let pageSize = 10;
export default function SelectPatientModal({ isModalOpen, closeModal, officeId, selectedPatients,  setSelectedPatients, handleSelectedPatients}) {
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [patients, setPatients] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const { isLoading: isLoadingPatientList, data: PatientList } = useAllMarketingPatient(pageNumber, pageSize, officeId, searchTerm);

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoadingPatientList && PatientList && PatientList.items) {
                setPatients(uniqBy([...PatientList?.items], "id"));
                setTotalItems(PatientList?.pagination?.totalItems);
            }
        }
        else if (!isLoadingPatientList && PatientList && PatientList.items) {
            setPatients(prev => uniqBy([...prev, ...PatientList?.items], "id"));
            setTotalItems(PatientList?.pagination?.totalItems);
        }
    }, [isLoadingPatientList, PatientList]);

    const handleChange = (e, item) => {
        const patients = [...selectedPatients];
        const patientIndex = patients.findIndex((v) => +item?.id === +v?.id);
        if (e.target.checked) {
            patients.push(item);
        } else {
            if (patientIndex > -1) patients?.splice(patientIndex, 1);
        }
        setSelectedPatients(patients);
        setSelectAll(false);
    };

    const handleAllChange = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setSelectedPatients(patients?.length ? patients?.map((item) => item) : []);
        } else {
            setSelectedPatients([]);
        }
    };
 
    return (
        <Modal
            isOpen={isModalOpen}
            toggle={closeModal}
            className="modal-dialog-centered"
            modalClassName="custom-modal"
        >
            <span className="close-btn" onClick={closeModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                {isLoadingPatientList && <Loader />}
                <h2 className="title">Select Patient</h2>
                <PatientSearchBar
                    handleSearchTerm={(e) => setSearchTerm(e.target.value)}
                    searchValue={searchTerm}
                />
                <div className={styles.patient_modal_data}>
                    <div className="ch-checkbox c-field all-event-checkbox m-0">
                        <label>
                            <input type="checkbox"
                              onChange={handleAllChange}
                              checked={selectAll}
                            />
                            <span>Select All</span>
                        </label>
                    </div>
                    {patients.map(patient => (
                        <div key={patient.id} className={styles.modal_checkbox_border + " " + "ch-checkbox c-field all-event-checkbox m-0"}>
                            <label>
                                <input
                                 type="checkbox"
                                 onChange={(e) => handleChange(e, patient)}
                                 checked={selectedPatients?.some((p) => p.id === patient.id)}
                                 />
                                {/* <img  src={patient?.profilePic}/> */}
                                <span>{`${patient?.firstName} ${patient?.lastName}`}</span>
                            </label>
                        </div>
                    ))}
                </div>
                
                <div className="btn-box d-md-flex mt-4">
                    <button
                        className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                        title="Select"
                        onClick={handleSelectedPatients}
                    >
                        Select
                    </button>
                    <button
                        className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
                        title="cancel"
                        onClick={closeModal}
                    >
                        cancel
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}
