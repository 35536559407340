import React from "react";
import Text from "components/Text";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import DateRangeFilter from "./DateRangeFilter";
import StatusFilter from "./StatusFilter";
import styles from "../../Notes/Notes.module.scss";

export default function Sidebar({
  isSidebarActive,
  handleSidebarToggle,
  resetFilter,
  dates,
  updateDatesValues,
  setStatusFilter,
  selectedStatus,
  handleApplyFilters,
  isFilterApply,
  t,
}) {
  const handleApply = () => {
    handleApplyFilters();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleReset = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleReset}
    >
      <span className={styles.notes_filter}>
        {t("notes.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>
      <Accordion
        preExpanded={["a"]}
        className="filter-accordion"
        allowZeroExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>{t("status")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StatusFilter
              setStatusFilter={setStatusFilter}
              selectedStatus={selectedStatus}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>
              {t("accountOwner.dateRange")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <DateRangeFilter
            t={t}
            dates={dates}
            updateDatesValues={updateDatesValues}
          />
        </AccordionItem>
      </Accordion>
      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className={"button button-round button-shadow mr-3"}
          title="apply"
          onClick={handleApply}
        >
          {t("performanceReview.apply")}
        </button>
        <button
          className={"button button-round button-border button-dark reset-btn"}
          title="reset"
          onClick={handleReset}
        >
          {t("performanceReview.reset")}
        </button>
        <button
          className={"button button-round button-border button-dark cancel-btn"}
          title="close"
          onClick={handleSidebarToggle}
        >
          {t("performanceReview.close")}
        </button>
      </div>
    </StickySidebar>
  );
}
