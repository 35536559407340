import { Portal } from "react-overlays";
import DatePicker from "react-datepicker";

import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";

const DateRangeFilter = ({ t, dates, updateDatesValues }) => {
  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");
    return <Portal container={el}>{children}</Portal>;
  };
  const startDatePickerRef = useReadOnlyDateTextInput();
  const endDatePickerRef = useReadOnlyDateTextInput();

  return (
    <div className="invitation_date_picker">
      <div className="invitation_date">
        <div className="c-field">
          <label>{t("from")}</label>
          <div className="d-flex inputdate">
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="c-form-control"
              selected={dates?.from}
              onChange={(e) => updateDatesValues(e, "from")}
              maxDate={dates?.to}
              popperPlacement="bottom-start"
              popperContainer={CalendarContainer}
              ref={startDatePickerRef}
            />
          </div>
        </div>
      </div>
      <div className="invitation_date">
        <div className="c-field">
          <label>{t("to")}</label>
          <div className="d-flex inputdate">
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="c-form-control"
              selected={dates?.to}
              minDate={dates?.from}
              onChange={(e) => updateDatesValues(e, "to")}
              popperPlacement="bottom-end"
              popperContainer={CalendarContainer}
              ref={endDatePickerRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
