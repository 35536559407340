import React, { useEffect, useMemo } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { withTranslation } from "react-i18next";
import useConversationHeaderState from "../../../../hooks/useConversationHeaderState";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import PatientConfirmationModal from "Messenger/pages/PatientConversation/component/PatientConfirmationModal";

const CustomConversationHeader = (props) => {
  const {
    t,
    channel,
    currentUser,
    handleProfileBtn,
    externalTabActive,
    handleBackBtn,
    isFromDashBoard = false,
    isFromPatientChat = false,
    sdk,
    setViewAppointmentCred,
    doctorChatCheck,
    setCurrentChannel
  } = props;

  const { otherMethods, state } = useConversationHeaderState({
    channel,
    currentUser,
    externalTabActive,
    sdk,
    t,
    setCurrentChannel
  });
  const { isConversationClosed, isPopOpen, isConversationStateLoading } = state;
  const { getChannelData, toggleChatOpenState, setIsPopOpen } = otherMethods;
  const menuOptions = useMemo(() => {
    const values = [
      {
        text: t("messenger.viewDetails"),
        onclick: () => handleProfileBtn(),
      },
      {
        text: t("messenger.viewAppointment"),
        onclick: () =>
          setViewAppointmentCred({
            officeId: getChannelData().officeId,
            patientId: getChannelData().id,
          }),
      },
    ];
    if (!doctorChatCheck) {
      values.push({
        text: isConversationClosed
          ? t("messenger.openChat")
          : t("messenger.closeChat"),
        onclick: () => setIsPopOpen(true),
      });
    }
    return values;
  }, [doctorChatCheck, isConversationClosed, getChannelData()]);

  return (
    <>
      {isPopOpen && (
        <PatientConfirmationModal
          title={
            isConversationClosed
              ? t("messenger.openChat")
              : t("messenger.closeChat")
          }
          description={
            isConversationClosed
              ? t("messenger.openChatDescription")
              : t("messenger.closeChatDescription")
          }
          confirmLabel={
            isConversationClosed
              ? isConversationStateLoading
                ? t("messenger.loading")
                : t("messenger.confirmOpenChatLabel")
              : t("messenger.confirmCloseChatLabel")
          }
          action={toggleChatOpenState}
          close={() => setIsPopOpen(false)}
        />
      )}
      <div className="sendbird-conversation-header">
        <div className="img-content-box">
          {isFromDashBoard && (
            <div className="user-image">
              <span className=" link-btn" onClick={handleBackBtn}>
                <img
                  src={require("assets/images/arrow-back-icon.svg").default}
                  alt="arrow"
                />
              </span>
            </div>
          )}
          <div className="user-image">
            <img
              className="img-cover"
              src={getChannelData().channelImage}
              alt={getChannelData().channelName}
              onError={(e) => {
                e.currentTarget.src =
                  require("assets/images/staff-default-rounded.png").default;
              }}
            />
          </div>
          <div className="user-name">
            {getChannelData().channelName}
            {!!getChannelData().subTitle && (
              <div style={{ color: "grey", fontSize: "13px" }}>
                {getChannelData().subTitle}
              </div>
            )}
          </div>
        </div>
        {!isFromDashBoard && !isFromPatientChat && (
          <div className="action-icon" onClick={handleProfileBtn}>
            <ThreeDotsVertical />
          </div>
        )}

        {isFromPatientChat && (
          <UncontrolledDropdown>
            <DropdownToggle
              caret={false}
              tag="div"
              className="cursor-pointer action-icon"
            >
              <div className="ellipsis-icon">
                <ThreeDotsVertical />
              </div>
            </DropdownToggle>
            <DropdownMenu right className="office-dropdown-menu">
              {menuOptions.map((option, index) => (
                <DropdownItem
                  key={index}
                  className="office-dropdown-item"
                  onClick={(e) => option?.onclick?.(e)}
                >
                  <p className="m-0">{option?.text}</p>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </div>
    </>
  );
};

export default withTranslation()(CustomConversationHeader);
