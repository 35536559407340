import React from "react";
import SearchIcon from "../../../../../assets/images/search.svg";
import styles from "../../Notes.module.scss";

const SearchBar = ({ handleSearchTerm, searchValue, t, isModal, placeholder }) => {
	return (
		<div className={`${isModal ? styles.modal_search_bar : styles.new_search_bar}`}>
			<span className={styles.search_icon}>
				<img src={SearchIcon} alt="search icon" />
			</span>
			<input
				type="text"
				className={styles.search_input}
				placeholder={placeholder}
				onChange={handleSearchTerm}
				value={searchValue}
			/>
		</div>
	);
};

export default SearchBar;
