import Page from "components/Page";
import { withTranslation } from "react-i18next";
import styles from "../../../../../pages/AccountPreferences/accountpreferences.module.scss";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import constants from "../../../../../constants";
import { useOfficeDetail } from "repositories/office-repository";
import { decodeId, encodeId } from "utils";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Text from "components/Text";
import Loader from "components/Loader";
import { usePatientNotes } from "repositories/patient-appointment-repository";
import { useSelector } from "react-redux";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import { uniqBy } from "lodash";
import Card from "components/Card";
import Empty from "components/Empty";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";


const pageSize = 4;

function PatientNotes({ t, history }) {

    let { officeId, patientId } = useParams();
    const location = useLocation();
    const profile = useSelector(state => state.userProfile.profile);
    const decodeOfficeId = parseInt(decodeId(officeId));
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);
    const decodePatientId = decodeId(patientId);

    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [notes, setNotes] = useState([]);


    const params = {
        patientId: decodePatientId,
        officeId: decodeOfficeId,
        doctorId: profile?.id,
        pageNumber: pageNumber,
        pageSize: pageSize
    }

    const { data, isLoading } = usePatientNotes(params);

    useEffect(() => {
        if (!isLoading && data && data?.items?.patientNotes) {
            setNotes(uniqBy([...data?.items?.patientNotes], "id"));
            setTotalItems(data.pagination.totalItems);
        }
    }, [isLoading, data]);

    const renderPagination = () => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                sizePerPage: pageSize,
                totalSize: totalItems,
                page: pageNumber,
                onPageChange: setPageNumber,
            })}
        >
            {({ paginationProps, paginationTableProps }) => {
                return (
                    <div className="data-table-block">
                        {/* Paginator component needs table to work, this is why we have used it.  */}
                        <div style={{ display: "none" }}>
                            <BootstrapTable
                                keyField="id"
                                data={[]}
                                columns={[{ text: "sometext" }]}
                                {...paginationTableProps}
                            />
                        </div>
                        <div className={"pagnation-block "}>
                            {totalItems > pageSize && (
                                <PaginationListStandalone {...paginationProps} />
                            )}
                        </div>
                    </div>
                );
            }}
        </PaginationProvider>
    );

    const goBack = () => {
        if (location?.state?.isFrompatientDetail) {
            history.push({
                pathname: constants.routes.appointment.patientDetail.replace(':officeId', officeId).replace(":patientId", patientId),
            });
        } else {
            history.push(constants.routes.appointment.patients.replace(':officeId', officeId))
        }

    }


    return (
        <Page onBack={goBack} title={officeDetail?.name} className={styles["global-main-page"]}>
            {(isLoading || isLoadingOfficeDetail) && <Loader />}
            <div className={styles["message-templates"]}>
                <div
                    className={
                        styles["message-templates-heading"] +
                        " " +
                        "d-flex justify-content-between"
                    }
                >
                    <Text size="14px" weight="300" color="#000000" className='mb-2'>
                        {t("patients.patientNotes")}
                    </Text>
                    <Link to={{ pathname: constants.routes.appointment.addPatientNotes.replace(':officeId', officeId).replace(':patientId', patientId), state: { ...location?.state } }}>
                        <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" >
                            {t("patients.addNewNote")}
                        </button>
                    </Link>
                </div>

                <Fragment>
                    {notes?.length > 0 ? (
                        notes.map((item) => (
                            <Card className={styles["message-templates-card"] + " " + "form-wrapper cursor-pointer"} key={item.id} onClick={() => history.push(constants.routes.appointment.patientNoteDetail.replace(':officeId', officeId).replace(':patientId', patientId).replace(':patientNoteId', encodeId(item?.id)))}>
                                <div className={styles["schedule-update"]}>
                                    <Text color="#587E85" weight="600" size="16px" marginBottom="20px" className={styles["schedule-update-text"]}>
                                        {item?.title}
                                    </Text>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-between w-75">
                                        <div>
                                            <Text color="#6F7788" weight="400" size="12px">
                                                {t("patients.date")}
                                            </Text>

                                            <Text color="#102C42" weight="600" size="14px">
                                                {moment(item?.createdOn).format('MMM DD, YYYY')}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text color="#6F7788" weight="400" size="12px">
                                                {t("patients.time")}
                                            </Text>
                                            <Text color="#102C42" weight="600" size="14px">
                                                {moment(item?.createdOn).format('h:mm A')}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text color="#6F7788" weight="400" size="12px">
                                                {t("patients.createdBy")}
                                            </Text>
                                            <Text color="#102C42" weight="600" size="14px">
                                                {item?.creator?.id === profile?.id
                                                    ? t("accountPreferences.you")
                                                    : `${item?.creator?.firstName} ${item?.creator?.lastName}`}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        ))
                    ) : (
                        <Empty Message={t("patients.notesNotFound")} />
                    )}
                </Fragment>
            </div>
            {renderPagination()}
        </Page>
    )
}

export default withTranslation()(PatientNotes);