import React from "react";
import "../../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useSelector } from "react-redux";

function StaffFilter({ setStaffFilter, seletedStaff, t, getStaffList }) {
    const { data } = getStaffList();
    const profile = useSelector((state) => state.userProfile.profile);

    const handleChange = (e, item) => {
        const staffs = [...seletedStaff];

        const staffIndex = staffs.findIndex((v) => +item.id === +v);
        if (e.target.checked) {
            staffs.push(item.id);
        } else {
            if (staffIndex > -1) staffs.splice(staffIndex, 1);
        }
        setStaffFilter(staffs);
    };

    const mySelfData =
        data &&
        data.data.find((item, key) => {
            if (item?.id === profile?.id) return item;
        });
    return (
        <ul className={["filter-list"]}>
            {mySelfData && (
                <li>
                    <div className="ch-checkbox">
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleChange(e, mySelfData)}
                                checked={seletedStaff.includes(mySelfData.id)}
                            />{" "}
                            <span> {t(`notes.mySelf`)}</span>
                        </label>
                    </div>
                    {data.data.length > 1 && <hr className="mr-4" />}
                </li>
            )}
            {data &&
                data.data
                    .filter((d) => profile?.id !== d?.id)
                    .map((item, key) => (
                        <li key={key}>
                            <div className="ch-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChange(e, item)}
                                        checked={seletedStaff.includes(item.id)}
                                    />{" "}
                                    <span> {`${item.firstName} ${item.lastName}`}</span>
                                </label>
                            </div>
                        </li>
                    ))}
        </ul>
    );
}

export default StaffFilter;
