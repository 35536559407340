import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { cacheSideBarActive } from "utils";
import AppointmentsTable from "../AppointmentsTable/AppointmentsTable";
import useUpCommingModule from "../hooks/useUpCommingModule";
import { useAllUpCommingAppointments } from "repositories/patient-appointment-repository";
import Loader from "components/Loader";
import GlobalListFilter from '../filter/index'
import constants from "../../../../constants";
import "../UpcomingTabs/UpcomingTabs.scss";
import styles from "../../../pages/Scheduler/OfficeSchedulerTab/SubTabs/EventsShiftsTab/EventsShiftsTab.module.scss";

const UpcomingTabs = ({ state, isFrompatientDetail = false, manageTab }) => {
	const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

	const {
		data: {
			isLoading,
			totalItems,
			pageNumber,
			appointments,
			showLoader,
			searchValue,
			officeFilter,
			statusFilter,
			dates,
			isFilterApply,
			doctorFilter
		},
		methods: {
			setPageNumber,
			appointmentDetailAction,
			handleInputChange,
			setOfficeFilter,
			handleApplyFilters,
			resetFilter,
			setStatusFilter,
			updateDatesValues,
			setDocotrFilter
		}
	} = useUpCommingModule(useAllUpCommingAppointments, state, "upcoming");


	const handleSidebarToggle = () => {
		setSidebarActive(!isSidebarActive);
		localStorage.setItem("isSidebarActive", !isSidebarActive);
	};


	return (
		<div className="scheduler-tabs-main-wrapper appointment-main-wrapper-one">
			{isLoading && <Loader />}
			{!isFrompatientDetail && <div >
				<GlobalListFilter
					isSidebarActive={isSidebarActive}
					handleSidebarToggle={handleSidebarToggle}
					resetFilter={resetFilter}
					applyFilter={handleApplyFilters}
					officeFilter={officeFilter}
					setOfficeFilter={setOfficeFilter}
					statusFilter={statusFilter}
					setStatusFilter={setStatusFilter}
					dates={dates}
					updateDatesValues={updateDatesValues}
					appointmentStatusList={constants.upComingAppointmentStatus}
					state={state}
					isFilterApply={isFilterApply}
					doctorFilter={doctorFilter}
					setDocotrFilter={setDocotrFilter}
				/>
			</div>}
			<div className="tabs-right-col appointment-tab-right-col">
				<div className={styles["event-shift-header"]}>
					<AppointmentsTable
						appointments={appointments}
						totalItems={totalItems}
						setPageNumber={setPageNumber}
						pageNumber={pageNumber}
						appointmentDetailAction={appointmentDetailAction}
						showLoader={showLoader}
						searchValue={searchValue}
						handleInputChange={handleInputChange}
						state={state}
						isFrompatientDetail={isFrompatientDetail}
						manageTab={manageTab}
					/>
				</div>
			</div>
		</div>

	);
};
export default withTranslation()(UpcomingTabs);
