import React, { useEffect } from "react";
import Page from "components/Page";
import { Col, Row } from "reactstrap";
import { decodeId, setStorage } from "utils";
import useSubscriptionAccess from "hooks/useSubscriptionAccess";
import { useOfficeDetail } from "repositories/office-repository";
import { withTranslation } from "react-i18next";
import styles from "./OfficeOptions.module.scss";
import timesheetIcon from "./../../../assets/images/timesheet-icon.svg";
import noticesIcon from "./../../../assets/images/notices.svg";
import constants from "./../../../constants";
import notesIcon from "./../../../assets/images/notes.svg";
import preferenceIcon from "./../../../assets/images/preferences.svg";
import contractsIcon from "./../../../assets/images/contracts.svg";
import staffMembersIcon from "./../../../assets/images/staff-members.svg";
import performanceReviewIcon from "./../../../assets/images/performance_review.svg";
import waitingListIcon from "./../../../assets/images/waiting_list.svg";
import DashboardIcon from "./../../../assets/images/Dashboard.svg";
import PatientsIcon from "./../../../assets/images/patients.svg";
import patientAppointmentIcon from "./../../../assets/images/patient_apppointment.svg";
import MessageCenter from "./../../../assets/images/patient_message_center.svg";
import timesheetReportIcon from "../../../assets/images/timesheetReport.svg";
import Card from "./Card";

function OfficeOptions({ history, location, match, t }) {
  const { redirectWithCheck, isModuleDisabledClass } = useSubscriptionAccess();
  const goBack = () => history.push(constants.routes.accountOwner.offices);
  let officeName = null;
  if (location?.state?.officeName) {
    officeName = location.state.officeName;
  }

  const { isLoading, data } = useOfficeDetail(decodeId(match.params.officeId));

  const handleRedirect = (officeId) => {
    history.push({
      pathname: constants.routes.appointment.appointmentScreen,
      state: {
        officeId: officeId,
        activetabs: 1,
        statusFilter: [],
        isfromPaitentDetail: true,
      },
    });
  };

  const handlePatientRedirect = (officeId) => {
    history.push(
      constants.routes.appointment.patients.replace(":officeId", officeId)
    );
    setStorage("officeState", {
      officeState: {},
    });
  };

  useEffect(() => {
    setStorage("isRecallReminderPlan", {
      isRecallReminderPlan: false,
    });
  }, []);

  return (
    <Page
      onBack={goBack}
      isTitleLoading={!officeName && isLoading}
      title={officeName || (data && data.name)}
      className={styles["page"]}
    >
      <>
        {/* Office Management */}
        <div>
          <p className={styles["mobile-text-size"]}>
            {t("officeMangementCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.staffGrid.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={staffMembersIcon}
                title={t("accountOwner.staffMembers")}
                officeName={officeName}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.timelinePreferences.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={preferenceIcon}
                title={t("accountOwner.preferences")}
                officeName={officeName}
              />
            </Col>
          </Row>
        </div>

        {/* Staff Management */}
        <div>
          <p className={styles["mobile-text-size"] + " " + "mt-4"}>
            {t("staffManagementCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.timesheet.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timesheetIcon}
                title={t("contracts.timesheet")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.leaves.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timesheetIcon}
                title={t("contracts.leaves")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.timesheetReport.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timesheetReportIcon}
                title={t("staffTimesheet.timesheetReport")}
              />
            </Col>
            <Col
              lg="4"
              className={isModuleDisabledClass(
                constants.moduleNameWithId.formAndContracts
              )}
            >
              <Card
                to={constants.routes.accountOwner.forms.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={contractsIcon}
                title={t("contracts.formsAndContract")}
                officeName={officeName}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.discussionNotes.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={notesIcon}
                title={t("discussionNote.discussionNote")}
                officeName={officeName}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.notices.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={noticesIcon}
                title={t("notices.notice")}
                officeName={officeName}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.performanceReview.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={performanceReviewIcon}
                title={t("performanceReview.performanceReview")}
                officeName={officeName}
                designation={location?.state?.designation}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.preferences.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={preferenceIcon}
                title={t("accountOwner.timeLinePreferences")}
                officeName={officeName}
              />
            </Col>
          </Row>
        </div>

        {/* Patient Management */}
        <div>
          <p className={styles["mobile-text-size"] + " " + "mt-4"}>
            {t("pateintManagementCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                redirectTo={() => handlePatientRedirect(match.params.officeId)}
                icon={PatientsIcon}
                title={t("patients.patients")}
              />
            </Col>
            <Col lg="4">
              <Card
                redirectTo={() =>
                  handleRedirect(decodeId(match.params.officeId))
                }
                icon={patientAppointmentIcon}
                title="Patient Appointments"
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.waitingList.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={waitingListIcon}
                title={t("waitingList.waitingList")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.superAdmin.appointmentDashboard.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={DashboardIcon}
                title="Patient Dashboard"
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.appointment.patientMessageCenter
                  .replace(":officeId", match.params.officeId)
                  .replace(
                    "/:patientId?",
                    match?.params?.patientId
                      ? `/${match?.params?.patientId}`
                      : ""
                  )
                  .replace(
                    "/:appointmentId?",
                    match?.params?.appointmentId
                      ? `/${match?.params?.appointmentId}`
                      : ""
                  )}
                icon={MessageCenter}
                title="Patient Message Center"
              />
            </Col>
          </Row>
        </div>
      </>
    </Page>
  );
}

export default withTranslation()(OfficeOptions);
