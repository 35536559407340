import React from "react";

import Select from "react-select";
import Text from "components/Text";
import styles from "../../TaskManagement.module.scss";
import TaskDetailsCard from "./TaskDetailsCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";
import "../../TaskManagement.scss";
import Empty from "components/Empty";

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const RightSection = ({ t,
    filteredTasks,
    OfficeList,
    selectedOffice,
    handleOfficeChange,
    selectedStatus,
    StatuList,
    handleStatusChange,
    Assignee,
    totalItems,
    setPageNumber,
    checkOverdueTasks,
    getButtonClass,
    handleActiontask
}) => {

    return (
        <div className={styles["right_section"]}>
            <Text size="20px" weight="500" color="#111B45">
                {t("taskManagement.assignees")} {(totalItems)}
            </Text>
            <div className={styles["dropdown_wrapper"]}>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={OfficeList}
                    value={selectedOffice}
                    onChange={handleOfficeChange}
                />
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={selectedStatus}
                    options={StatuList}
                    onChange={handleStatusChange}
                />
            </div>
            <div className={`${styles["task-list-container"]}`}>
                {filteredTasks?.length === 0 ? (<div className={styles["not_found"]}>
                    <Empty Message={t('taskManagement.assigneNotFound')} />
                </div>) :
                    <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                        <InfiniteScroll
                            className="task_assignee w-100"
                            dataLength={Assignee?.length}
                            hasMore={Assignee?.length < totalItems}
                            next={() => setPageNumber((v) => v + 1)}
                        >
                            {filteredTasks?.map((assignee) => {
                                return (
                                    <TaskDetailsCard
                                        assignee={assignee}
                                        getButtonClass={getButtonClass}
                                        checkOverdueTasks={checkOverdueTasks}
                                        handleActiontask={handleActiontask}
                                    />
                                )
                            })}
                        </InfiniteScroll>
                    </motion.div>
                }
            </div>
        </div>
    )
}

export default RightSection