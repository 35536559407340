import React from "react";
import Card from "components/Card";
import Page from "components/Page";
import Text from "components/Text";
import Button from "components/Button";
import { withTranslation } from "react-i18next";
import { reasonsList } from "../../../../constants";
import SelectStaff from "../modals/SelectStaff";
import styles from "./../Notices.module.scss";
import NoticeAlert from "../modals/NoticeAlert";
import useAddNoticeController from "../hook/useAddNoticeController";
import Input from "components/Input";
import Loader from "components/Loader";

const AddNotice = ({ history, t }) => {
  const {
    data: {
      formData,
      reasonsOfCorrectiveAction,
      isModalOpen,
      staffMembers,
      staff,
      showLoader,
      confirmedSelectedStaff,
      errors,
      isAlertModalOpen,
      searchValue,
    },
    methods: {
      handleOnChange,
      handleCheckboxChange,
      handleModal,
      handleStaffRadio,
      handleApply,
      goToPreviousScreen,
      handleSubmit,
      handleConfirmationModal,
      setSearchValue,
      handleCancel,
    },
  } = useAddNoticeController(history, t);

  return (
    <Page onBack={goToPreviousScreen} title="Add Notice">
      {showLoader && <Loader />}
      <Card className={styles.add_notice_card}>
        <div className={styles.add_new_notice}>
          <div className={styles.border_bottom}>
            <div className="d-flex justify-content-between">
              <Text size="13px" weight="400" lineHeight="13px" color="#79869A">
                {t("notices.nameTitle")}
              </Text>
              <p className="link-btn m-0" onClick={() => handleModal()}>
                {t("notices.selectStaff")}
              </p>
            </div>
            <Text
              size="14px"
              weight="600"
              lineHeight="14px"
              color="#102C42"
              marginTop="4px"
            >
              {confirmedSelectedStaff?.firstName
                ? `${confirmedSelectedStaff?.firstName} ${confirmedSelectedStaff?.lastName}, ${confirmedSelectedStaff?.designation?.title}`
                : "No Staff Selected"}
            </Text>
          </div>
          {errors.confirmedSelectedStaff && (
            <span className="error-msg">{errors.confirmedSelectedStaff}</span>
          )}
          <div className={styles.checkbox_wrapper_new}>
            <Text
              size="13px"
              weight="400"
              lineHeight="13px"
              color="#79869A"
              marginBottom="10px"
            >
              {t("notices.reasonOfCorrectivection")}
            </Text>
            {reasonsList.map((option, index) => (
              <div
                key={index}
                className="ch-checkbox c-field all-event-checkbox m-0 mt-2"
              >
                <label>
                  <input
                    type="checkbox"
                    checked={reasonsOfCorrectiveAction[option.value] || false}
                    onChange={(e) => handleCheckboxChange(option.value, e)}
                  />
                  <span>{option?.label}</span>
                </label>
              </div>
            ))}
            {reasonsOfCorrectiveAction?.others && (
              <Input
                Type="text"
                Placeholder="others"
                Name="otherReason"
                Value={formData.otherReason}
                HandleChange={handleOnChange}
              />
            )}
            {(errors.reasonsOfCorrectiveAction || errors.otherReason) && (
              <span className="error-msg">
                {errors.reasonsOfCorrectiveAction || errors.otherReason}
              </span>
            )}
          </div>
          <div className={styles.textarea_wrapper}>
            <div className="c-field">
              <label> {t("notices.DescriptionProblem")}</label>
              <textarea
                className="c-form-control"
                placeholder="Notice Description"
                name="problemDescription"
                maxLength="2500"
                value={formData.problemDescription}
                onChange={handleOnChange}
              ></textarea>
              {errors.problemDescription && (
                <span className="error-msg">{errors.problemDescription}</span>
              )}
            </div>
            <div className="c-field">
              <label> {t("notices.companyExpectation")}</label>
              <textarea
                className="c-form-control"
                placeholder="Enter"
                name="companyExpectation"
                maxLength="2500"
                value={formData.companyExpectation}
                onChange={handleOnChange}
              ></textarea>
              {errors.companyExpectation && (
                <span className="error-msg">{errors.companyExpectation}</span>
              )}
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <Button onClick={handleConfirmationModal}>
              {t("notices.sendNotice")}
            </Button>
            <Button borderedSecondaryNew onClick={goToPreviousScreen}>
              <span>{t("notices.cancel")}</span>
            </Button>
          </div>
        </div>
      </Card>
      {isModalOpen && (
        <SelectStaff
          isModalOpen={isModalOpen}
          showLoader={showLoader}
          closeModal={handleModal}
          staffMembers={staffMembers}
          staff={staff}
          handleStaffRadio={handleStaffRadio}
          handleApply={handleApply}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleCancel={handleCancel}
        />
      )}
      {isAlertModalOpen && (
        <NoticeAlert
          isModalOpen={isAlertModalOpen}
          closeModal={handleConfirmationModal}
          handleSubmit={handleSubmit}
        />
      )}
    </Page>
  );
};

export default withTranslation()(AddNotice);
