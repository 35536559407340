import React, { useCallback, useEffect, useState } from "react";
import Card from "components/Card";
import Page from "components/Page";
import constants from "../../../../../../constants";
import styles from "../../../Appointments.module.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactDatePicker from "react-datepicker";
import { createDateStringForAPIRequest, decodeId, handleSuccess } from "utils";
import moment from "moment";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import { Portal } from "react-overlays";
import { withTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  updateRecallReminder,
  useRecallReminder,
} from "repositories/patient-appointment-repository";
import Loader from "components/Loader";

const dropdownList = [
  { label: "None", value: 0 },
  { label: "1 Week After Completed Appointment", value: 7 },
  { label: "2 Week After Completed Appointment", value: 14 },
  { label: "3 Week After Completed Appointment", value: 21 },
  { label: "1 Month After Completed Appointment", value: 30 },
  { label: "2 Month After Completed Appointment", value: 60 },
  { label: "3 Month After Completed Appointment", value: 90 },
  { label: "1 Year After Completed Appointment", value: 365 },
  { label: "Custom Date", value: 1 },
];

const NextRecallEdit = ({ t }) => {
  const history = useHistory();
  let { appointmentId } = useParams();
  let { officeId } = useParams();
  const decodeAppointmentId = parseInt(decodeId(appointmentId));
  const [selectedOption, setSelectedOption] = useState(null);
  const [dates, setDates] = useState({ startDate: moment().toDate() });
  const [reoccuringcheck, setReoccuringcheck] = useState(false);
  const [utcDates, setUtcDates] = useState({
    startDate: createDateStringForAPIRequest(moment().toDate()),
  });
  const goBack = () => {
    history.push(
      constants.routes.appointment.recallReminderEdit
        .replace(":appointmentId", appointmentId)
        .replace(":officeId", officeId)
    );
  };
  const {
    mutate: updateRecallReminderMutate,
    isLoading: updateRecallReminderLoading,
  } = useMutation((data) => updateRecallReminder(data));
  const { isLoading: appointmentLoading, data: appointmentDetail } =
    useRecallReminder(decodeAppointmentId);

  const getLabelByValue = (value) => {
    const item = dropdownList.find((option) => option.value === value);
    setSelectedOption(item?.value);
  };
  const startDatePickerRef = useReadOnlyDateTextInput();

  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };

  const updateDatesValues = useCallback((e, type) => {
    setDates((prev) => ({ ...prev, [type]: e }));
    setUtcDates((prev) => ({
      ...prev,
      startDate:
        type === "startDate"
          ? createDateStringForAPIRequest(e)
          : prev.startDate,
    }));
  }, []);

  useEffect(() => {
    if (!appointmentLoading) {
      if (appointmentDetail?.recallDate) {
        setSelectedOption(1);
      }
      setReoccuringcheck(appointmentDetail?.isRecurring);
      getLabelByValue(appointmentDetail?.recallInDay);
    }
  }, [appointmentDetail, appointmentLoading]);

  const handleUpdateRecall = (data) => {
    updateRecallReminderMutate(data, {
      onSuccess: async (res) => {
        handleSuccess(t("patientAppointmentMembers.nextRecallMsg"));
        goBack();
      },
    });
  };

  const handleSave = () => {
    let params = {
      appointmentId: decodeAppointmentId,
      RecallInDay: selectedOption,
      IsRecurring: reoccuringcheck,
      RecallDate: null,
    };
    if(selectedOption === 1) {
      params.RecallDate = utcDates?.startDate
    }
    if(appointmentDetail?.id) {
      params.appointmentRecallId = appointmentDetail?.id
    }
    handleUpdateRecall(params);
  };

  return (
    <Page onBack={goBack} title={t("patientAppointmentMembers.nextRecall")}>
      {updateRecallReminderLoading && <Loader />}
      <Card>
        <div className={`ch-radio ${styles.flex_col_style}`}>
          {dropdownList.map((item) => (
            <label key={item.value}>
              <input
                type="radio"
                name="recallOptions"
                value={item.value}
                checked={selectedOption === item.value}
                onChange={() => setSelectedOption(item.value)}
              />
              <span className="mb-2">{item.label}</span>
            </label>
          ))}
          {selectedOption === 1 && (
            <div className={styles["date_wrapper"] + " " + "c-field mt-3 w-50"}>
              <div className="inputdate">
                <ReactDatePicker
                  dateFormat="dd-MM-yyyy"
                  className="c-form-control"
                  selected={dates.startDate}
                  onChange={(e) => updateDatesValues(e, "startDate")}
                  popperPlacement="bottom-start"
                  popperContainer={CalendarContainer}
                  ref={startDatePickerRef}
                  openToDate={moment().toDate()}
                  minDate={moment().toDate()}
                />
              </div>
            </div>
          )}
        </div>
        {selectedOption != 1 && (
          <div className="ch-checkbox c-field all-event-checkbox mt-2">
            <label>
              <input
                type="checkbox"
                onChange={(e) => setReoccuringcheck(e.target.checked)}
                checked={reoccuringcheck}
              />
              <span>{t("patientAppointmentMembers.makeItRecurring")}</span>
            </label>
          </div>
        )}
      </Card>
      <div className={styles["common-appointment-btn-container"]}>
        <button
          className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
          onClick={handleSave}
        >
          {t("Save")}
        </button>
        <button
          className="button button-round button-border button-dark btn-mobile-link"
          onClick={goBack}
        >
          {t("Cancel")}
        </button>
      </div>
    </Page>
  );
};

export default withTranslation()(NextRecallEdit);
