import React, { Fragment, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../../Dashboard.module.scss";
import {
  archieveTransferredNote,
  unArchieveTransferredNote,
  useTransferredNotes,
} from "repositories/notes-repository";
import NotesCard from "../NotesCard";
import useNoteModule from "accountOwner/pages/Notes/hooks/useNoteModule";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CardLoader from "../../components/CardLoader";
import NotAccessible from "../../components/NotAccessible";
import { LoaderIcon } from "react-hot-toast";

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const isMyNote = false;

const TransferredNotes = ({
  t,
  manageTab,
  tabName,
  isArchive,
  appliedOfficeFilters,
  appliedStaffFilters,
  handleRedirect,
  isSchedulerOnly,
}) => {
  const history = useHistory();
  const {
    handleArchive,
    totalItems,
    pageNumber,
    setPageNumber,
    notes,
    isModalOpen,
    closeModal,
    setIsModalOpen,
    updateStateAfterDelete,
    handleUpdateReminder,
    handleUnArchieveNote,
    isLoading,
    setAppliedOfficeFilters,
    setAppliedStaffFilters,
    totalPages,
  } = useNoteModule(
    useTransferredNotes,
    isArchive,
    archieveTransferredNote,
    unArchieveTransferredNote,
    isMyNote,
    manageTab,
    tabName
  );

  let items = (
    <div>
      {notes.map((note) => (
        <NotesCard
          transferredNote={note}
          key={note?.id}
          handleArchive={handleArchive}
          isArchive={isArchive}
          setIsModalOpen={setIsModalOpen}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          updateStateAfterDelete={updateStateAfterDelete}
          handleUpdateReminder={handleUpdateReminder}
          handleUnArchieveNote={handleUnArchieveNote}
          history={history}
          handleRedirect={handleRedirect}
        />
      ))}
    </div>
  );

  useEffect(() => {
    if (!isSchedulerOnly) setAppliedOfficeFilters(appliedOfficeFilters);
    setAppliedStaffFilters(appliedStaffFilters);
    setPageNumber(1);
  }, [appliedOfficeFilters, appliedStaffFilters, isSchedulerOnly]);

  return (
    <div
      className={`${styles.transferred_notes_container} ${
        isLoading ? styles.transferred_notes_loading : ""
      } ${!notes?.length ? styles.transferred_notes_empty : ""}`}
    >
      {isLoading && pageNumber === 1 ? (
        <LoaderIcon className={styles.loader_style} />
      ) : (
        <>
          {!notes?.length ? (
            <div className={styles["not_found"]}>
              <NotAccessible
                isNotFound={true}
                Message={t("notes.notesNotFound")}
              />
            </div>
          ) : (
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
              className="w-100"
            >
              <InfiniteScroll
                dataLength={notes.length}
                hasMore={pageNumber < totalPages}
                loader={<LoaderIcon className={styles.loader_style} />}
                next={() => {
                  pageNumber < totalPages && setPageNumber((prev) => prev + 1);
                }}
                scrollableTarget="scrollableDiv"
              >
                {items}
              </InfiniteScroll>
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};
export default withTranslation()(TransferredNotes);
