import StaffAvailabilityTab from 'accountOwner/pages/Scheduler/OfficeSchedulerTab/SubTabs/StaffAvailabilityTab'
import React from 'react'
import { withTranslation } from 'react-i18next'

const OfficeSchedule = ({ t, staffAvailFiltersData, staffAvailabilityViewSelectedFromDashBoard, currentDateFromDashBoard, setCurrentDateFromDashBoard, appliedAgendaTypes, appliedViewSettig, appliedOfficeFilters }) => {
  return (
    <>
      <StaffAvailabilityTab
        staffAvailFiltersData={staffAvailFiltersData}
        isFromDashBoard={true}
        staffAvailabilityViewSelectedFromDashBoard={staffAvailabilityViewSelectedFromDashBoard}
        currentDateFromDashBoard={currentDateFromDashBoard}
        setCurrentDateFromDashBoard={setCurrentDateFromDashBoard}
        appliedAgendaTypes={appliedAgendaTypes}
        appliedViewSettig={appliedViewSettig}
        appliedOfficeFilters={appliedOfficeFilters}
      />
    </>
  )
}

export default withTranslation()(OfficeSchedule)