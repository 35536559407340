
import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { OfficeInvitationsActions, useOfficeInvitations } from "repositories/office-repository";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { handleSuccess } from "utils";

const pageSize = 10;
const useRequestInvitationModule = () => {
    const profile = useSelector((state) => state.userProfile.profile);
    const [pageNumber, setPageNumber] = useState(1);
    const [invitations, setInvitations] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    let officeId = null;
    let userId = profile?.id;
    let inviteStatus = 1;
    let searchTerm = "";
    let sortBy = null;
    let sortOrder = null;


    const { isLoading, data } = useOfficeInvitations(
        officeId,
        userId,
        inviteStatus,
        searchTerm,
        pageNumber,
        pageSize,
        sortBy,
        sortOrder
    );

    const { mutate: OfficeInvitationsActionsMutate, isLoading: officeInvitationsActionsLoading } = useMutation((params) => OfficeInvitationsActions(params));

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setInvitations(uniqBy([...data?.items], "inviteId"));
            }
        }
        else if (!isLoading && data && data.items) {
            setInvitations(prev => uniqBy([...prev, ...data?.items], "inviteId"));
        }
    }, [isLoading, data]);

     const handleInvitationActions = async (inviteId, isAccepted) => {
        let params = {
            inviteId: inviteId,
            isAccepted: isAccepted
        }
        OfficeInvitationsActionsMutate(params, {
                onSuccess: (res) => {
                    setInvitations((prevInvitations) => prevInvitations.filter(invitation => invitation.inviteId !== inviteId));
                    handleSuccess(res.message);
                }
            })
        };


    return {
        data: {
            isLoading,
            invitations,
            totalItems
        },
        methods: {
            setPageNumber,
            handleInvitationActions
        }
    }
}

export default useRequestInvitationModule;
