import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import constants from "../../../../constants";
import { getStorage, handleSuccess, setStorage } from 'utils';
import { transferNote } from 'repositories/notes-repository';
import { uniqBy } from 'lodash';

const pageSize = 12;

const calculateStatus = (filters, isMyNote) => {
    if (filters?.length >= 2) return null;
    if (filters?.includes('seen')) return true;
    if (isMyNote) {
        if (filters?.includes('new')) return false;
    } else {
        if (filters?.includes('delivered')) return false;
    }

    return null;
};

const useNoteModule = (useAllNotes, isArchive, archieveNote, unArchieveNote, isMyNote, manageTab, tabName) => {
    const cacheNotesFilter = isMyNote ? constants.notes.cache.myNotesFilter : constants.notes.cache.transferedNoteFilter;
    const cacheFilters = getStorage(cacheNotesFilter);
    const cacheStatus = calculateStatus(cacheFilters?.status, isMyNote);
    const isFilterApply = cacheFilters?.offices?.length > 0 || cacheFilters?.staff?.length > 0 || cacheFilters?.status?.length > 0;

    const [officeFilter, setOfficeFilter] = useState(cacheFilters?.offices || []);
    const [staffFilter, setStaffFilter] = useState(cacheFilters?.staff || []);
    const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(cacheFilters?.offices || []);
    const [appliedStaffFilters, setAppliedStaffFilters] = useState(cacheFilters?.staff || []);
    const [appliedStatusFilters, setAppliedStatusFilters] = useState(cacheStatus);
    const [searchValue, setSearchValue] = useState("");
    const [notes, setNotes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedStatus, setStatusFilter] = useState(cacheFilters?.status);

    const { isLoading, data, error, refetch } = useAllNotes(
        pageNumber,
        pageSize,
        appliedOfficeFilters,
        appliedStaffFilters,
        searchValue,
        isArchive,
        appliedStatusFilters
    );

    const { mutate: archieveNoteMutate, isLoading: archieveLoading } = useMutation((noteId) => archieveNote(noteId));

    const { mutate: unArchieveNoteMutate, isLoading: unarchiveLoading } = useMutation((noteId) => unArchieveNote(noteId));

    const { mutate: transferMutate, isLoading: transferLoading } = useMutation(({ noteId, assigneeUserId }) => transferNote(noteId, assigneeUserId));

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const resetFilter = () => {
        setOfficeFilter([]);
        setStaffFilter([]);
        setAppliedOfficeFilters([]);
        setAppliedStaffFilters([]);
        setStatusFilter(null);
        setAppliedStatusFilters(null);
        setStorage(cacheNotesFilter, {
            offices: [],
            staff: [],
            status: null
        });
        setPageNumber(1);
    };

    const handleApplyFilters = () => {
        const status = calculateStatus(selectedStatus, isMyNote);
        setAppliedOfficeFilters(officeFilter);
        setAppliedStaffFilters(staffFilter);
        setAppliedStatusFilters(status);
        setStorage(cacheNotesFilter, {
            offices: officeFilter,
            staff: staffFilter,
            status: selectedStatus
        });
        setPageNumber(1);
    };

    const handleSearchTerm = (e) => {
        setPageNumber(1);
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setNotes(uniqBy([...data.items], "id"));
                setTotalItems(data.pagination.totalItems);
                setTotalPages(data.pagination.totalPages);
            }
        }
        else if (!isLoading && data && data.items) {
            setNotes(prev => uniqBy([...prev, ...data.items], "id"));
            setTotalItems(data.pagination.totalItems);
            setTotalPages(data.pagination.totalPages);
        }
    }, [isLoading, data]);

    useEffect(() => {
        setPageNumber(1);
        refetch();
    }, [isArchive, refetch])

    const handleUpdateReminder = (noteId, updatedNote) => {
        setNotes((prevNotes) =>
            prevNotes.map((note) => (note.id === noteId ? { ...note, ...updatedNote } : note))
        );
    };

    const handleArchive = async (noteId) => {
        archieveNoteMutate(noteId, {
            onSuccess: (res) => {
                setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
                handleSuccess(res.message);
                refetch();
            }
        })
    };

    const handleUnArchieveNote = async (noteId) => {
        unArchieveNoteMutate(noteId, {
            onSuccess: (res) => {
                setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
                handleSuccess(res.message);
                refetch();
            }
        });
    }

    const handleTransferNote = async (noteId, assigneeUserId) => {
        transferMutate({ noteId, assigneeUserId }, {
            onSuccess: (res) => {
                setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
                handleSuccess(res.message);
                manageTab(tabName.transfferedNote);
                refetch();
            }
        });
    }

    const updateStateAfterDelete = (noteId) => {
        setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
    };

    const isAnyLoading = isLoading || archieveLoading || unarchiveLoading || transferLoading;

    return {
        officeFilter,
        staffFilter,
        searchValue,
        notes,
        pageNumber,
        totalItems,
        isLoading: isAnyLoading,
        error,
        setOfficeFilter,
        setStaffFilter,
        handleApplyFilters,
        handleSearchTerm,
        resetFilter,
        setPageNumber,
        handleArchive,
        isModalOpen,
        closeModal,
        setIsModalOpen,
        updateStateAfterDelete,
        handleUpdateReminder,
        setStatusFilter,
        selectedStatus,
        handleUnArchieveNote,
        handleTransferNote,
        isFilterApply,
        setAppliedOfficeFilters,
        setAppliedStaffFilters,
        totalPages
    };
};

export default useNoteModule;