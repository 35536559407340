import React from "react";
import Text from "components/Text";
import Input from "components/Input";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import Button from "components/Button";
import styles from "../../Contracts/Contracts.module.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomDropdown from "components/Dropdown";

export default function Form({ t, handleSubmit, errors, formData, handleOnChange, updateDatesValues, handleCancel, selectType, setType, TypeOfEmployment }) {

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };
    const startDatePickerRef = useReadOnlyDateTextInput();

    const location = useLocation();

    const previewForm = location?.pathname.includes('preview-performance-review-form');

    const isInvalidDate = (date) => isNaN(new Date(date).getTime());

    return (
        <form onSubmit={handleSubmit} className={styles["date_wrapper"] + " " + "c-field mb-0 mt-4"}>
            <Input
                Title={t('employmentProof.form.salutation')}
                Type="text"
                Placeholder={t('employmentProof.form.salutation')}
                Name={"Salutation"}
                HandleChange={handleOnChange}
                Error={errors?.Salutation}
                Value={formData?.Salutation}
                MaxLength="200"
            />
            <Input
                Title={t('employmentProof.form.subject')}
                Type="text"
                Placeholder={t('employmentProof.form.subject')}
                Name={"Subject"}
                HandleChange={handleOnChange}
                s={errors?.Subject}
                Value={formData?.Subject}
                MaxLength="200"
            />
            <Input
                Title={t('employmentProof.form.heading')}
                Type="text"
                Placeholder={t('employmentProof.form.heading')}
                Name={"Heading"}
                Id={"heading"}
                HandleChange={handleOnChange}
                Error={errors?.Heading}
                Value={formData?.Heading}
                MaxLength="1000"
            />
            <div className={styles.new_inputdate + " " + "inputdate"}>
                <Text size="13px" weight="400" color="#79869A">
                    {t('employmentProof.form.date')}
                </Text>
                <ReactDatePicker
                    dateFormat="dd-MM-yyyy"
                    className="c-form-control"
                    selected={isInvalidDate(formData?.Date) ? moment().toDate() : formData?.Date}
                    onChange={(e) => updateDatesValues(e, "Date")}
                    popperPlacement="bottom-start"
                    popperContainer={CalendarContainer}
                    ref={startDatePickerRef}
                    minDate={moment().toDate()}
                />
            </div>
            <div className="mt-4">
                <Input
                    Title={t('employmentProof.form.name')}
                    Type="text"
                    Placeholder={t('employmentProof.form.name')}
                    Name={"EmployeeName"}
                    HandleChange={handleOnChange}
                    Error={errors?.EmployeeName}
                    Value={formData?.EmployeeName}
                    MaxLength="120"
                />
            </div>
            <Input
                Title={t('employmentProof.form.position')}
                Type="text"
                Placeholder={t('employmentProof.form.position')}
                Name={"Position"}
                Id={"position"}
                HandleChange={handleOnChange}
                Error={errors?.Position}
                Value={formData?.Position}
                Disabled={previewForm}
                MaxLength="120"
            />
            <div className={styles.new_inputdate + " " + "inputdate"}>
                <Text size="13px" weight="400" color="#79869A">
                    {t('employmentProof.form.dateHired')}
                </Text>
                <ReactDatePicker
                    dateFormat="dd-MM-yyyy"
                    className="c-form-control"
                    selected={isInvalidDate(formData?.HiredDate) ? moment().toDate() : formData?.HiredDate}
                    onChange={(e) => updateDatesValues(e, "HiredDate")}
                    popperPlacement="bottom-start"
                    popperContainer={CalendarContainer}
                    ref={startDatePickerRef}
                    maxDate={moment().toDate()}
                />
            </div>
            <div className="mt-4">
                <Text size="13px" weight="400" color="#79869A" marginTop="30px">
                    {t('employmentProof.form.typeOfEmployment')}
                </Text>
                <div className="custom-dropdown-only">
                    <CustomDropdown
                        options={TypeOfEmployment}
                        selectedOption={selectType}
                        selectOption={(id) => {
                            setType(id);
                        }}
                    />
                </div>
                <div className="mt-4">
                    <Input
                        Title={t('employmentProof.form.rateOfPay')}
                        Type="text"
                        Placeholder={t('employmentProof.form.rateOfPay')}
                        Name={"RateOfPay"}
                        Id={"rateOfPay"}
                        HandleChange={handleOnChange}
                        Error={errors?.RateOfPay}
                        Value={formData?.RateOfPay}
                        MaxLength="36"
                    />
                </div>
                <Input
                    Title={t('employmentProof.form.annualSalary')}
                    Type="text"
                    Placeholder={t('employmentProof.form.annualSalary')}
                    Name={"AnnualSalary"}
                    HandleChange={handleOnChange}
                    Error={errors?.AnnualSalary}
                    Value={formData?.AnnualSalary}
                    MaxLength="36"
                />
                <div className="c-field">
                    <textarea
                        className={styles["height60"] + " " + "c-form-control"}
                        placeholder="Enter"
                        name="Declaration"
                        maxLength="1000"
                        onChange={handleOnChange}
                        value={formData?.Declaration}
                    />
                    {errors.Declaration && (
                        <span className="error-msg">{errors.Declaration}</span>
                    )}
                </div>
                <Input
                    Title={t('employmentProof.form.officePhoneNumber')}
                    Type="Number"
                    Placeholder={t('employmentProof.form.officePhoneNumber')}
                    Name={"Office Phone Number"}
                    HandleChange={handleOnChange}
                    Error={errors?.['Office Phone Number']}
                    Value={formData?.['Office Phone Number']}
                    MaxLength="36"
                />
                <Input
                    Title={t('employmentProof.form.accountOWnerEmail')}
                    Type="email"
                    Placeholder={t('employmentProof.form.accountOWnerEmail')}
                    Name={"Account Owner’s Email Address"}
                    HandleChange={handleOnChange}
                    Error={errors?.['Account Owner’s Email Address']}
                    Value={formData?.['Account Owner’s Email Address']}
                    MaxLength="36"
                />
                <Input
                    Title={t('employmentProof.form.sincerely')}
                    Type="text"
                    Placeholder={t('employmentProof.form.sincerely')}
                    Name={"Sincerely"}
                    HandleChange={handleOnChange}
                    Error={errors?.Sincerely}
                    Value={formData?.Sincerely}
                    MaxLength="32"
                />
                <Input
                    Title={t('employmentProof.form.accountOwnerAdminName')}
                    Type="text"
                    Placeholder={t('employmentProof.form.accountOwnerAdminName')}
                    Name={'Account Owner/Account Admin Name'}
                    HandleChange={handleOnChange}
                    Error={errors?.['Account Owner/Account Admin Name']}
                    Value={formData?.['Account Owner/Account Admin Name']}
                    MaxLength="120"
                />
                <Input
                    Title={t('employmentProof.form.officeName')}
                    Type="text"
                    Placeholder={t('employmentProof.form.officeName')}
                    Name={"Office Name"}
                    HandleChange={handleOnChange}
                    Error={errors?.['Office Name']}
                    Value={formData?.['Office Name']}
                    MaxLength="120"
                />
            </div>
            <div className={styles.margin+' '+ 'd-flex'}>
                <Button className="w-sm-100" type='submit'>{t('employmentProof.form.review')}</Button>
                <Button className="w-sm-100 ml-4" borderedSecondary type="button" onClick={handleCancel}>
                    {t('employmentProof.form.cancel')}
                </Button>
            </div>
        </form>
    );
}
