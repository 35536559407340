import React from "react";
import Page from "components/Page";
import styles from "../../Notes.module.scss";
import { withTranslation } from "react-i18next";
import StaffModel from "../../modal/StaffModel";
import AddNoteForm from "./AddNoteForm";
import { useAddNoteController } from "../../hooks/useAddNoteController";
import Loader from "components/Loader";

const AddNote = ({ t, history }) => {
  const {
    data: {
      formData,
      toggleOn,
      dates,
      startDatePickerRef,
      RepeatOptionsNew,
      selectOffice,
      officeList,
      isModalOpen,
      selectedStaff,
      confirmedStaff,
      fileName,
      showLoader,
      allMembersList,
      isVirtualOffice
    },
    methods: {
      handleSubmit,
      handleOnChange,
      handleToggle,
      updateDatesValues,
      setOffice,
      setIsModalOpen,
      handleFileChange,
      closeModal,
      handleApply,
      handleCancel,
      handleStaffSelection,
      handleTimer,
      errors,
      onBack,
      setFileName,
    },
  } = useAddNoteController(t, history);

  return (
    <Page onBack={onBack}>
      {showLoader && <Loader />}
      <div className={styles["notes_heading_btn_wrapper"]}>
        <h1 className={styles["modal_heading"] + " " + "m-0"}>{t('notes.addNote')}</h1>
      </div>
      <AddNoteForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        confirmedStaff={confirmedStaff}
        formData={formData}
        toggleOn={toggleOn}
        dates={dates}
        startDatePickerRef={startDatePickerRef}
        RepeatOptionsNew={RepeatOptionsNew}
        selectOffice={selectOffice}
        officeList={officeList}
        fileName={fileName}
        handleFileChange={handleFileChange}
        handleSubmit={handleSubmit}
        handleOnChange={handleOnChange}
        handleToggle={handleToggle}
        updateDatesValues={updateDatesValues}
        setOffice={setOffice}
        handleTimer={handleTimer}
        errors={errors}
        onBack={onBack}
        setFileName={setFileName}
        isVirtualOffice={isVirtualOffice}
      />
      {isModalOpen && (
        <StaffModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          handleApply={handleApply}
          handleCancel={handleCancel}
          handleStaffSelection={handleStaffSelection}
          allMembersList={allMembersList}
          selectedStaff={selectedStaff}
        />
      )}
    </Page>
  );
};
export default withTranslation()(AddNote);
