import Card from "components/Card";
import Page from "components/Page";
import Text from "components/Text";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./../../../Appointments.module.scss";
import constants from "../../../../../../constants";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { decodeId, formatUTCDateTime, setStorage } from "utils";
import { useRecallReminder } from "repositories/patient-appointment-repository";
import Loader from "components/Loader";
import appointmentStyles from "../../../Appointments.module.scss";
import { AppointmentsContext } from "accountOwner/pages/Appointments/AppointmentsContext";
import { withTranslation } from "react-i18next";
const dropdownList = [
  { label: "1 Week After", value: 7 },
  { label: "2 Week After", value: 14 },
  { label: "3 Week After", value: 21 },
  { label: "1 Month After", value: 30 },
  { label: "2 Month After", value: 60 },
  { label: "3 Month After", value: 90 },
  { label: "1 Year After", value: 365 },
];
const KEY_NAME = "appointment-reminder";
const RecallReminderEdit = ({ t }) => {
  const history = useHistory();
  let { appointmentId } = useParams();
  let { officeId } = useParams();
  const [error, setError] = useState("");
  const decodeAppointmentId = parseInt(decodeId(appointmentId));
  const goBack = () => {
    history.push(constants.routes.appointment.appointmentScreen);
  };

  const { reminderPlan, options, setReminderPlan } =
    useContext(AppointmentsContext);

  const { isLoading: appointmentLoading, data: appointmentDetail } =
    useRecallReminder(decodeAppointmentId);

  const getLabelByValue = (value) => {
    const item = dropdownList.find((option) => option.value === value);
    return item ? item.label : null;
  };

  useEffect(() => {
    setStorage("appointmentReminder", {
      appointmentReminder: true,
    });
  }, []);

  const fetchDefaultData = async () => {
    if (
      appointmentDetail?.recallReminder?.appointmentReminderPlanSetting?.length
    ) {
      setReminderPlan((prevReminderPlan) => ({
        ...prevReminderPlan,
        [KEY_NAME]: {
          title: appointmentDetail?.recallReminder?.title,
          id: appointmentDetail?.recallReminder?.id,
          appointmentRecallId: appointmentDetail?.id,
          appointmentReminderPlanSetting: [
            ...appointmentDetail?.appointmentReminderPlanMapping,
          ],
        },
      }));
    }
  };

  useEffect(() => {
    if (!appointmentLoading) {
      fetchDefaultData();
    }
  }, [appointmentDetail, appointmentLoading]);

  const redirectSelectReminder = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    if (!appointmentDetail || !appointmentDetail?.recallInDay) {
      setError("Please Select Recall First");
    } else {
      setError("");
      history.push({
        pathname: constants.routes.appointment.appointmentReminder
          .replace(":officeId", officeId)
          .replace(":requestId", appointmentId),
        state: { isFromGloabList: true },
      });
    }
  };

  return (
    <Page onBack={goBack} title={t("patientAppointmentMembers.editRecall")}>
      {appointmentLoading && <Loader />}
      <Card className={styles.appointment_modal_card_new}>
        <div className={styles.appointment_modal_card_new1}>
          <div className={styles.edit_reacll_container}>
            <Text size="16px" weight="500" color="#587E85">
              {t("patientAppointmentMembers.nextRecall")}
            </Text>
            {appointmentDetail ? (
              <Text size="14px" weight="500" color="#111b45">
                {appointmentDetail?.recallDate
                  ? formatUTCDateTime(appointmentDetail.recallDate)
                  : appointmentDetail?.recallInDay
                  ? `${
                      appointmentDetail.isRecurring ? "Recurring - " : ""
                    }${getLabelByValue(appointmentDetail.recallInDay)}`
                  : "None"}
              </Text>
            ) : (
              <div className={appointmentStyles["appointment-head"]}>
                No Recall Plan Selected
              </div>
            )}
            <Link
              to={constants.routes.appointment.nextEditRecall
                .replace(":appointmentId", appointmentId)
                .replace(":officeId", officeId)}
              className="link-btn"
            >
              {t("discussionNote.edit")}
            </Link>
          </div>
          <div className={appointmentStyles["heading-data-Wrapper"]}>
            <div className={appointmentStyles["head-link-wrapper"]}>
              <div className={appointmentStyles["appointment-head"]}>
                {t("patientAppointmentMembers.reminderPlan")}
              </div>
            </div>
            <button
              className="link-btn border-0 bg-transparent"
              onClick={redirectSelectReminder}
            >
              {t("discussionNote.edit")}
            </button>
            {appointmentDetail?.recallReminder?.title ? (
              <>
                <Text size="14px" weight="600" color="#102C42">
                  {reminderPlan?.[KEY_NAME]?.title || ""}
                </Text>
                {(reminderPlan?.[KEY_NAME] && reminderPlan?.[
                      KEY_NAME
                    ]?.appointmentReminderPlanSetting?.length)  ? (
                  <div className={styles.recall_small_card}>
                    {reminderPlan?.[
                      KEY_NAME
                    ]?.appointmentReminderPlanSetting?.map((plan, index) => (
                      <div className={styles.recall_small_card} key={plan?.id}>
                        <Text
                          size="16px"
                          weight="600"
                          color="#111B45"
                        >{`Reminder ${index + 1}`}</Text>
                        <Text
                          size="14px"
                          weight="600"
                          color="#102C42"
                          marginTop="4px"
                        >
                          {plan?.reminderBefore +
                            " " +
                            options.find(
                              (m) => m.id == plan?.reminderBeforeType
                            )?.name +
                            " " +
                            t("patientAppointmentMembers.before")}
                        </Text>
                        <Text
                          size="14px"
                          weight="500"
                          color="#102C42"
                          marginTop="4px"
                        >
                          {[
                            plan.isEmail &&
                              t("patientAppointmentMembers.isEmail"),
                            plan.isSms && t("patientAppointmentMembers.isSms"),
                            plan.isPushNotification &&
                              t("patientAppointmentMembers.isPushNotification"),
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </Text>
                        <Text
                          size="12px"
                          weight="400"
                          color="#6F7788"
                          marginTop="6px"
                        >
                          {t("patientAppointmentMembers.messageTemPlate")}
                        </Text>
                        <Text size="14px" weight="500" color="#102C42">
                          {plan?.messageTemplateDetail?.templateName}
                        </Text>
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            ) : (
              <div className={appointmentStyles["appointment-head"]}>
                No Reminder Plan Selected
              </div>
            )}
          </div>
          {error && <span className="error-msg">{error}</span>}
        </div>
      </Card>
    </Page>
  );
};

export default withTranslation()(RecallReminderEdit);
