import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { withTranslation } from "react-i18next";
import OfficeFilter from "./OfficeFilter";
import StaffFilter from "./StaffFilter";
import styles from "../../Notes.module.scss";
import StatusFilter from "./StatusFilter";

const FilterNotes = ({
  t,
  resetFilter,
  setOfficeFilter,
  setStaffFilter,
  staffFilter,
  officeFilter,
  setStatusFilter,
  selectedStatus,
  handleApplyFilters,
  isFilterApply,
  getStaffList,
  statuses,
  isSidebarActive,
  handleSidebarToggle,
}) => {
  const handleApply = () => {
    handleApplyFilters();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleReset = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleReset}
    >
      <span className={styles.notes_filter}>
        {t("notes.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>

      <Accordion
        preExpanded={["a"]}
        className="filter-accordion"
        allowZeroExpanded
        allowMultipleExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>{t("notes.offices")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <OfficeFilter
              setOfficeFilter={setOfficeFilter}
              selectedOffice={officeFilter}
              t={t}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="b">
          <AccordionItemHeading>
            <AccordionItemButton>{t("notes.staffMembers")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StaffFilter
              setStaffFilter={setStaffFilter}
              seletedStaff={staffFilter}
              t={t}
              getStaffList={getStaffList}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>{t("notes.status")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StatusFilter
              setStatusFilter={setStatusFilter}
              selectedStatus={selectedStatus}
              statuses={statuses}
            />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className={"button button-round button-shadow mr-3"}
          title="apply"
          onClick={() => handleApply()}
        >
          {t("notes.apply")}
        </button>
        <button
          className={"button button-round button-border button-dark reset-btn"}
          title="reset"
          onClick={resetFilter}
        >
          {t("notes.reset")}
        </button>
        <button
          className={"button button-round button-border button-dark cancel-btn"}
          title="close"
          onClick={() => handleSidebarToggle()}
        >
          {t("notes.close")}
        </button>
      </div>
    </StickySidebar>
  );
};

export default withTranslation()(FilterNotes);
