import React, { Fragment, useState } from "react";
import NotesCard from "../NoteCard";
import styles from "../../Notes.module.scss";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";
import { archieveNote, unArchieveNote, useAllNotes, useAllStaff } from "repositories/notes-repository";
import FilterNotes from "../Filters";
import SearchBar from "../Filters/SearchBar";
import useNoteModule from "../../hooks/useNoteModule";
import Empty from "components/Empty";
import { cacheSideBarActive } from "utils";

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const isMyNote = true;

const MyNotes = ({ t, history, isArchive, manageTab, tabName }) => {
    const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

    const handleSidebarToggle = () => {
        setSidebarActive(!isSidebarActive);
        localStorage.setItem("isSidebarActive", !isSidebarActive);
    };

    const {
        officeFilter,
        staffFilter,
        searchValue,
        notes,
        isLoading,
        setOfficeFilter,
        setStaffFilter,
        handleApplyFilters,
        handleSearchTerm,
        resetFilter,
        handleArchive,
        totalItems,
        setPageNumber,
        isModalOpen,
        closeModal,
        setIsModalOpen,
        updateStateAfterDelete,
        handleUpdateReminder,
        setStatusFilter,
        selectedStatus,
        handleUnArchieveNote,
        handleTransferNote,
        isFilterApply
    } = useNoteModule(
        useAllNotes, 
        isArchive, 
        archieveNote, 
        unArchieveNote, 
        isMyNote,
        manageTab, 
        tabName
        );

    const statuses = [t('notes.new'), t('notes.seen')];

    let items = <div className={styles["notes_card_grid"]}>
        {notes.map((note) => (
            <NotesCard
                className={isSidebarActive ? styles.notes_card_wrapper_active : styles.notes_card_wrapper_not_active}
                myNote={note}
                key={note?.id}
                handleArchive={handleArchive}
                isArchive={isArchive}
                setIsModalOpen={setIsModalOpen}
                closeModal={closeModal}
                isModalOpen={isModalOpen}
                updateStateAfterDelete={updateStateAfterDelete}
                handleUpdateReminder={handleUpdateReminder}
                handleUnArchieveNote={handleUnArchieveNote}
                handleTransferNote={handleTransferNote}
                history={history}
            />
        ))}
    </div>;

    return (
        <Fragment>
            {isLoading && <Loader />}
            <SearchBar searchValue={searchValue} handleSearchTerm={handleSearchTerm} t={t} placeholder={t("notes.searchPlaceHolder")}/>
            <div className="scheduler-tabs-main-wrapper w-100">
                <FilterNotes
                    setStaffFilter={setStaffFilter}
                    officeFilter={officeFilter}
                    setOfficeFilter={setOfficeFilter}
                    staffFilter={staffFilter}
                    setStatusFilter={setStatusFilter}
                    selectedStatus={selectedStatus}
                    handleApplyFilters={handleApplyFilters}
                    resetFilter={resetFilter}
                    isFilterApply={isFilterApply}
                    getStaffList={useAllStaff}
                    statuses={statuses}
                    isSidebarActive={isSidebarActive}
                    handleSidebarToggle={handleSidebarToggle}

                />
                {notes.length === 0 ? <div className={styles["not_found"]}>
                    <Empty Message={t('notes.notesNotFound')} />
                </div>
                    :
                    <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                        <InfiniteScroll
                            className="w-100"
                            dataLength={notes.length}
                            hasMore={notes.length < totalItems}
                            next={() => setPageNumber((v) => v + 1)}
                        >
                            {items}
                        </InfiniteScroll>
                    </motion.div>
                }
            </div>
        </Fragment>
    );
};
export default withTranslation()(MyNotes);
