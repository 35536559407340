import React, { useEffect, useState, useMemo } from "react";
import { encodeId, generateCalanderMonthView } from "utils";
import moment from "moment";
import constants from "../../../../../../../constants";
import styles from "../../../../Scheduler.module.scss";
import { withTranslation } from "react-i18next";
import { useGetAgenda } from "repositories/scheduler-repository";
import toast from "react-hot-toast";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import { getWeekDay } from "utils";
import MoveToApp from "accountOwner/pages/Scheduler/components/Modals/MoveToApp";
import HolidayImage from "../../../../../../../assets/images/early_on.svg";
import AccountImage from "../../../../../../../assets/images/account_circle.svg";

function MonthlyViewNew({
  t,
  currentDate,
  currentUserId,
  viewSettingFilter,
  setDateRange,
  agenda,
  isLoading,
  isFetching,
  error
}) {
  const history = useHistory();
  const weekDays = constants.weekday;
  const [, setmonthDates] = useState([]);
  const startDate = moment(currentDate)
    .startOf("month")
    .startOf("isoweek")
    .format("YYYY-MM-DD");
  const [popupList, setPopupList] = useState({});
  const [isModal, setIsModal] = useState({ open: false });

  useEffect(() => {
    if (!isLoading && !isFetching && error?.message) {
      toast.error(error.message);
    }
    //eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    calanderDates(currentDate);
  }, [startDate]);

  const calanderDates = (dateCurrent) => {
    if (dateCurrent) {
      let dates = generateCalanderMonthView(dateCurrent);
      setDateRange({
        startDate: moment(dateCurrent)
          .startOf("month")
          .startOf("isoweek")
          .format("YYYY-MM-DD"),
        endDate: moment(dateCurrent).endOf("month").format("YYYY-MM-DD"),
      });
      setmonthDates([...dates]);
    }
  };

  const getDifferentEvents = (events) => {
    return events.reduce(
      (acc, item) => {
        const { isAllDayEvent } = item;
        if (isAllDayEvent) {
          acc[0].push(item);
        } else {
          acc[1].push(item);
        }
        return acc;
      },
      [[], []]
    );
  };

  const handleEventList = (item, list, index, childIndex, date) => {
    setPopupList({
      id: list.id + index + childIndex,
      index: index,
      item: item,
      date: date,
    });
  };

  const getUsefulInfo = (item) => {
    const id = item?.id;
    const officeName = item?.officeName || null;
    let title = item?.title || item?.reasonForBlock || null;
    title =
      title && title.length > constants.agendaTitleLength
        ? title.slice(0, constants.agendaTitleLength).concat(" ...")
        : title;
    const timezoneCode = item?.timezoneCode || null;
    const isAllDayEvent = item?.isAllDayEvent;
    const timeString = item?.isAllDayEvent
      ? t("scheduler.agenda.allDayEvent")
      : moment(item?.startTime).format("h:mm A") +
        " - " +
        moment(item?.endTime).format("h:mm A");
    //sepecific to Schedular events
    const createdByMe = item.createdById === currentUserId;
    const isInvite = !!item.eventEmployeeStatus;
    const status = item.eventEmployeeStatus || item.eventRequestToJoinStatus;
    const patientName = item?.patientFullName;
    let backgroudColorClass;
    backgroudColorClass = createdByMe
      ? ""
      : status === constants.SCHEDULERSTATUS.PENDING
      ? "color-blue"
      : "color-grey";
    return {
      officeName,
      title,
      timezoneCode,
      timeString,
      id,
      isAllDayEvent,
      createdByMe,
      isInvite,
      status,
      backgroudColorClass,
      patientName,
    };
  };

  const boxBgColor = (type, item) => {
    switch (type) {
      case constants.agendaType.EVENT: {
        return "theme-green-bg";
      }
      case constants.agendaType.BUSY_SLOT:
      case constants.agendaType.BLOCKED: {
        return "light-orange-bg";
      }
      case constants.agendaType.APPOINTMENT: {
        return "light-purple-bg";
      }
      case constants.agendaType.LEAVE: {
        return "light-red-bg";
      }
      default: {
        return null;
      }
    }
  };

  const goToDetails = (id, type, createdByMe, isInvite, requestedDate) => {
    if (!id || !type) return;
    switch (constants.agendaType[type]) {
      case constants.agendaType.EVENT: {
        let pathname = "/";
        pathname = createdByMe
          ? constants.routes.scheduler.eventDetails.replace(
              ":eventId",
              encodeId(id)
            )
          : isInvite
          ? constants.routes.scheduler.eventRequestDetails.replace(
              ":eventId",
              encodeId(id)
            )
          : constants.routes.scheduler.EventWorkingDetails.replace(
              ":eventId",
              encodeId(id)
            );
        history.push({ pathname, state: { requestedDate } });
        break;
      }
      case constants.agendaType.BUSY_SLOT: {
        const pathname = constants.routes.scheduler.busySlotDetail.replace(
          ":busySlotId",
          encodeId(id)
        );
        history.push(pathname);
        break;
      }
      default: {
        return;
      }
    }
  };

  const eventCardView = (type, item, requestedDate, name) => {
    let data = getUsefulInfo(item);
    let colorType = boxBgColor(type, item);
    const colorCode =
      item?.user?.officeColourCodes?.[item?.officeId] || "#8C0346";
    const goToDetailPage = (e) => {
      let cardType = Object.keys(constants.agendaType)?.find(
        (key) => constants.agendaType[key] === type
      );
      if (
        type === constants.agendaType.APPOINTMENT ||
        type === constants.agendaType.LEAVE ||
        type === constants.agendaType.BLOCKED
      ) {
        setIsModal({ open: true, type: type });
        return;
      }

      goToDetails(
        data.id,
        cardType,
        data?.createdByMe,
        data.isInvite,
        requestedDate
      );
    };

    if (type === constants.agendaType.EVENT) {
      return (
        <div
          className={` ${styles["scheduler-event-box"]}  ${
            styles[data?.backgroudColorClass]
          }`}
          onClick={goToDetailPage}
        >
          {/* <div className={styles["event-date"]}> {data.timeString} {data.timezoneCode ? ` (${data.timezoneCode})` : null}</div>
                    <div className={styles["event-office"]}>{data?.officeName} </div>
                    <div className={styles["event-user"]}>{data?.title}</div> */}

          {(!viewSettingFilter.length ||
            viewSettingFilter.includes("timeString")) && (
            <div className={styles["event-date"]}>
              {data.timeString}{" "}
              {data.timezoneCode ? `(${data.timezoneCode})` : null}
            </div>
          )}

          {(!viewSettingFilter.length ||
            viewSettingFilter.includes("officeName")) && (
            <div className={styles["event-office"]}>{data?.officeName}</div>
          )}

          {(!viewSettingFilter.length ||
            viewSettingFilter.includes("title")) && (
            <div className={styles["event-user"]}>{data?.title}</div>
          )}

          {name &&
            (!viewSettingFilter.length ||
              viewSettingFilter.includes("staffMembers")) && (
              <div
                className={styles["event-user-container"]}
                style={{ backgroundColor: colorCode }}
              >
                <img
                  src={AccountImage}
                  alt="account"
                  className="account_owner_image"
                />
                <p className={styles.account_owner_name}>{name}</p>
              </div>
            )}
        </div>
      );
    }

    return (
      <div
        className={` ${styles["scheduler-event-box"]}  ${styles[colorType]} ${
          styles[data?.backgroudColorClass]
        }`}
        onClick={goToDetailPage}
      >
        {type === constants.agendaType.APPOINTMENT && data?.patientName && (
          <div className={styles["event-office"]}>{data?.patientName}</div>
        )}

        {/* {type === constants.agendaType.LEAVE ? (
          <div className={styles["event-date"]}>
            {t("scheduler.agenda.leaveTitle")}
          </div>
        ) : (
          <div className={styles["event-date"]}>
            {" "}
            {data.timeString}{" "}
            {data.timezoneCode ? ` (${data.timezoneCode})` : null}
          </div>
        )} */}

        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("timeString")) &&
          (type === constants.agendaType.LEAVE ? (
            <div className={styles["event-date"]}>
              {t("scheduler.agenda.leaveTitle")}
            </div>
          ) : (
            <div className={styles["event-date"]}>
              {data.timeString}{" "}
              {data.timezoneCode ? `(${data.timezoneCode})` : null}
            </div>
          ))}

        {/* <div className={styles["event-office"]}>{data?.officeName} </div> */}
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("officeName")) && (
          <div className={styles["event-office"]}>{data?.officeName}</div>
        )}
        {/* <div className={styles["event-user"]}>{data?.title}</div> */}
        {(!viewSettingFilter.length || viewSettingFilter.includes("title")) &&
          (type === constants.agendaType.LEAVE ? (
            <div className={styles["event-user"]}>
              {t("scheduler.agenda.onLeave")}
            </div>
          ) : (
            <div className={styles["event-user"]}>{data?.title}</div>
          ))}
        {/* {type === constants.agendaType.APPOINTMENT && (
          <div className={styles["event-type"]}>
            {t("scheduler.agenda.appointmentTitle")}
          </div>
        )} */}
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("appointment")) &&
          type === constants.agendaType.APPOINTMENT && (
            <div className={styles["event-type"]}>
              {t("scheduler.agenda.appointmentTitle")}
            </div>
          )}
        {name &&
          (!viewSettingFilter.length || viewSettingFilter.includes("name")) &&
          type !== constants.agendaType.BLOCKED &&
          type !== constants.agendaType.BUSY_SLOT && (
            <div
              className={styles["event-user-container"]}
              style={{ backgroundColor: colorCode }}
            >
              <img
                src={AccountImage}
                alt="account"
                className="account_owner_image"
              />
              <p className={styles.account_owner_name}>{name}</p>
            </div>
          )}
      </div>
    );
  };

  const tableData = useMemo(() => {
    if (agenda?.length) {
      return agenda.map((_item, index) => {
        let {
          requestedDate,
          appointments,
          leaves,
          schedulerBusySlots,
          schedulerEvents,
          appointmentSlots,
          holidayList,
        } = _item;
        appointments = appointments || [];
        leaves = leaves || [];
        schedulerBusySlots = schedulerBusySlots || [];
        schedulerEvents = schedulerEvents || [];
        appointmentSlots = appointmentSlots || [];
        const [allDayEvents, allOtherEvents] =
          getDifferentEvents(schedulerEvents);
        let allItems = [
          appointments,
          schedulerBusySlots,
          allOtherEvents,
          appointmentSlots,
        ]
          .flat()
          .sort((a, b) => {
            if (
              moment(moment(b.startTime).format("HH:mm"), "HH:mm").isAfter(
                moment(moment(a.startTime).format("HH:mm"), "HH:mm")
              )
            ) {
              return -1;
            } else {
              return 1;
            }
          });
        allItems = [...leaves, ...allDayEvents, ...allItems];
        let allItemsCopy = JSON.parse(JSON.stringify(allItems));
        if (!allItems || !allItems?.length) {
          return (
            <div className={styles["monthly-col"] + " " + styles["monthly-td"]}>
              <div className={styles["date-box"]}>
                {moment(requestedDate).format("DD")}
              </div>
              <div className={styles["empty-event"]}>
                {" "}
                {t("scheduler.noEvent")}{" "}
              </div>
            </div>
          );
        }
        return (
          <>
            <div
              className={`${styles["monthly-col"]} ${
                holidayList?.length > 0 ? styles["light-red-bg"] : ""
              } ${styles["monthly-td"]}  ${
                popupList?.date === requestedDate && styles["active-date"]
              }`}
            >
              <div className={styles["more-event-box"]}>
                {allItems.splice(0, 2).map((list, childIndex) => {
                  const name = `${
                    list?.user
                      ? (list?.user?.firstName || "") +
                        " " +
                        (list?.user?.lastName || "")
                      : ""
                  }`.trim();
                  return (
                    <>
                      {!childIndex && (
                        <div className={styles["date-box"]}>
                          {moment(requestedDate).format("DD")}
                          <span className={styles["day-box"]}>
                            ({getWeekDay(requestedDate)})
                          </span>
                        </div>
                      )}
                      {!childIndex && holidayList?.length > 0 && (
                        <div className={styles["holiday_title"]}>
                          <img
                            src={HolidayImage}
                            alt="holiday"
                            className="account_owner_image mr-2"
                          />
                          {holidayList?.[0]?.title}
                        </div>
                      )}
                      {list?.agendaType &&
                        eventCardView(
                          list?.agendaType,
                          list,
                          requestedDate,
                          name
                        )}

                      {allItemsCopy?.length > 2 && childIndex === 1 && (
                        <span
                          className={"link-btn"}
                          onClick={() =>
                            handleEventList(
                              allItems,
                              list,
                              index,
                              childIndex,
                              requestedDate
                            )
                          }
                        >
                          {" "}
                          See more{" "}
                        </span>
                      )}

                      {popupList?.id ===
                        parseInt(list.id) + index + childIndex &&
                        index === popupList?.index &&
                        popupList?.item?.length > 0 && (
                          <div className={styles["monthly-event-list"]}>
                            <span
                              className={styles["close-icon-list"]}
                              onClick={() => setPopupList({})}
                            >
                              <img
                                src={
                                  require("assets/images/close-grey.svg")
                                    .default
                                }
                                alt="icon"
                              />
                            </span>
                            <div className={styles["day-date-box"]}>
                              <div className={styles["day-name"]}>
                                {" "}
                                {getWeekDay(requestedDate)}
                              </div>
                              <div className={styles["date-name"]}>
                                {" "}
                                {moment(requestedDate).format("D")}
                              </div>
                            </div>
                            <div className={styles["event-list-ul"]}>
                              {popupList?.item?.map((data) => {
                                const name = `${
                                  list?.user
                                    ? (list?.user?.firstName || "") +
                                      " " +
                                      (list?.user?.lastName || "")
                                    : ""
                                }`.trim();
                                return (
                                  <>
                                    {data?.agendaType &&
                                      eventCardView(
                                        data?.agendaType,
                                        data,
                                        name
                                      )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        )}
                    </>
                  );
                })}
              </div>
            </div>
          </>
        );
      });
    }
    return null;
  }, [agenda, popupList, viewSettingFilter]);

  return (
    <div className={styles["montly-calendar-wrapper"]}>
      <div className={styles["monthly-tr"]}>
        {isLoading && <Loader />}
        {weekDays?.map((item) => (
          <div
            className={
              styles["monthly-th"] +
              " " +
              styles["monthly-col"] +
              " border-right-none"
            }
          >
            {item.slice(0, 3)}
          </div>
        ))}
      </div>

      <div className={styles["monthly-tr"]}>
        {tableData}
        {isModal?.open && (
          <MoveToApp
            isModal={isModal?.open}
            openModal={setIsModal}
            type={isModal?.type}
          />
        )}
      </div>
    </div>
  );
}

export default withTranslation()(MonthlyViewNew);
