import React, { Fragment } from 'react'
import Text from 'components/Text'
import styles from "../WaitingList/WaitingList.module.scss"
import moment from 'moment';
import { Daysarray } from '../../../../constants';

export default function AppointmentRequestDate({ t, appointmentRequestData }) {

    return (
        <Fragment>
            <Text size="16px" weight="600" color="#587E85">{t('waitingList.dateOfAppointment')}</Text>
            <div className={styles.grid_container}>
                <div>
                    <Text size="12px" weight="400" color="#6F7788">{t('waitingList.startDate')}</Text>
                    <Text size="14px" weight="600" color="#102C42">{moment(appointmentRequestData?.startDate).format('MMM DD, YYYY')}</Text>
                </div>
                <div>
                    <Text size="12px" weight="400" color="#6F7788">{t('waitingList.endDate')}</Text>
                    <Text size="14px" weight="600" color="#102C42">{moment(appointmentRequestData?.endDate).format('MMM DD, YYYY')}</Text>
                </div>
            </div>
            <div>
                {appointmentRequestData?.appointmentRequestTime?.map((schedule) => (
                    <Fragment key={schedule?.id}>
                        {schedule?.day ?
                            <div className={styles.day_date_grid}>
                                <Text size="14px" weight="600" color="#102C42">{Daysarray[schedule?.day]}</Text>
                                <p className={styles.waiting_list_date_box}>{`${schedule?.startTime} - ${schedule?.endTime}`}</p>
                            </div> :
                            <div className={styles.grid_container}>
                                <div >
                                    <Text size="12px" weight="400" color="#6F7788">{t('waitingList.startTime')}</Text>
                                    <Text size="14px" weight="600" color="#102C42">{schedule?.startTime}</Text>
                                </div>
                                <div>
                                    <Text size="12px" weight="400" color="#6F7788">{t('waitingList.endTime')}</Text>
                                    <Text size="14px" weight="600" color="#102C42">{schedule?.endTime}</Text>
                                </div>
                            </div>
                        }
                    </Fragment>
                ))}
            </div>
        </Fragment>
    )
}
