import React, { useEffect, useState } from "react";
import Empty from "components/Empty";
import Table from "components/table";
import constants from "./../../../../../../constants";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  getDownloadForcatedInvoice,
  getForthcomingInvoices,
} from "repositories/invoice-repository";
import styles from "./../../invoices.module.scss";
import downloadIcon from "assets/images/download-icon.svg";
import { downloadPDF, handleError, handleSuccess } from "utils";
import Loader from "components/Loader";

function ForecastedInvoice({ accountOwnerId, onItemClick, t }) {
  const PAGE_SIZE = constants.pageSize;
  const [pageNumber, setPageNumber] = useState(1);
  const [forecastData, setForecastData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPDFLoading, setPDFIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    fetchForthcomingInvoice();
  }, [pageNumber]);

  const fetchForthcomingInvoice = async () => {
    setIsLoading(true);
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    // Determine which month/year to use based on the current day
    let month, year;

    if (currentDay < 7) {
      // If date is less than 7, use previous month
      // For January (month 0), this will roll back to December of previous year
      month = currentDate.getMonth(); // Already the previous month (0-11)

      // If it was January (0), then we need previous year
      if (month === 0) {
        month = 12; // December
        year = currentDate.getFullYear() - 1;
      } else {
        year = currentDate.getFullYear();
      }
    } else {
      // For dates 7 and above, use current month
      month = currentDate.getMonth() + 1; // Convert from 0-11 to 1-12
      year = currentDate.getFullYear();
    }

    try {
      const result = await getForthcomingInvoices(
        accountOwnerId,
        pageNumber,
        PAGE_SIZE,
        month,
        year
      );
      setForecastData(result);

      // Process the data based on billing preference
      const processedRows = processDataBasedOnBillingPreference(
        result.data || []
      );
      setProcessedData(processedRows);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const processDataBasedOnBillingPreference = (data) => {
    if (!data || data.length === 0) return [];

    const billingPreference = data[0].billingPreference;

    if (billingPreference === 1) {
      // For billingPreference 1, merge all records into a single record
      const mergedRecord = mergeRecords(data);
      return [mergedRecord];
    } else {
      // For billingPreference !== 1, only merge records with the same officeId
      const groupedByOffice = {};

      data.forEach((record) => {
        const officeId = record.officeId;

        if (!groupedByOffice[officeId]) {
          groupedByOffice[officeId] = [];
        }

        groupedByOffice[officeId].push(record);
      });

      // Process each office group - don't combine different offices
      const processedRecords = [];

      Object.values(groupedByOffice).forEach((officeRecords) => {
        if (officeRecords.length > 1) {
          // Merge multiple records for the same office
          processedRecords.push(mergeRecords(officeRecords));
        } else {
          // Add single office records without merging
          processedRecords.push({
            ...officeRecords[0],
            _originalRecords: [officeRecords[0]],
          });
        }
      });

      return processedRecords;
    }
  };

  const mergeRecords = (records) => {
    if (!records || records.length === 0) return null;

    // Sort records by periodStart to ensure correct start/end dates
    const sortedRecords = [...records].sort(
      (a, b) => new Date(a.periodStart) - new Date(b.periodStart)
    );

    const firstRecord = sortedRecords[0];
    const lastRecord = sortedRecords[sortedRecords.length - 1];

    // Calculate total charge amount
    const totalChargeAmount = sortedRecords.reduce(
      (sum, record) => sum + record.totalChargeAmountInCents,
      0
    );

    return {
      ...firstRecord,
      totalChargeAmountInCents: totalChargeAmount,
      periodStart: firstRecord.periodStart,
      periodEnd: lastRecord.periodEnd,
      _originalRecords: sortedRecords, // Keep original records for popup display
      _mergedCount: sortedRecords.length,
    };
  };

  const handleRowClick = (row) => {
    onItemClick(row._originalRecords || [row]);
  };

  const downloadForcastedInvoice = async (param) => {
    try {
      setPDFIsLoading(true);
      const result = await getDownloadForcatedInvoice(param);
      downloadPDF(result?.data, "forcastedInvoice.pdf");
      handleSuccess(t("superAdmin.forcastedInvoiceDownloadMsg"));
    } catch (error) {
      handleError(error);
    } finally {
      setPDFIsLoading(false);
    }
  };

  const columns = [
    {
      attrs: { datatitle: t("superAdmin.subscriptionName") },
      text: t("superAdmin.subscriptionName"),
      dataField: "packageName",
      formatter: (cellContent, row) => {
        if (isLoading)
          return <div className="text-placeholder-150 shimmer-animation"></div>;

        let displayText = cellContent + " " + t("subscription");
        if (row._mergedCount > 1) {
          displayText = t("superAdmin.multipleSubscription");
        }

        return (
          <div
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer" }}
          >
            {displayText}
          </div>
        );
      },
    },
    {
      attrs: { datatitle: t("superAdmin.totalSubscriptionPrice") },
      text: t("superAdmin.totalSubscriptionPrice"),
      dataField: "totalChargeAmountInCents",
      formatter: (cellContent) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return `${t("cad")} ${(cellContent / 100).toFixed(2)}`;
      },
    },
    {
      attrs: { datatitle: t("superAdmin.officeName") },
      text: t("superAdmin.officeName"),
      dataField: "officeName",
      formatter: (cellContent, row) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        // If these are merged records with billingPreference 1, show "Multiple Offices"
        if (
          row._originalRecords &&
          row._originalRecords.length > 1 &&
          row._originalRecords[0].billingPreference === 1
        ) {
          return "--";
        }

        return ` ${cellContent ? cellContent : "--"}`;
      },
    },
    {
      attrs: { datatitle: t("superAdmin.startDate") },
      text: t("superAdmin.startDate"),
      dataField: "periodStart",
      formatter: (cellContent) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return moment(cellContent).format("MMM DD, YYYY");
      },
    },
    {
      attrs: { datatitle: t("superAdmin.expireDate") },
      text: t("superAdmin.expireDate"),
      dataField: "periodEnd",
      formatter: (cellContent) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return moment(cellContent).format("MMM DD, YYYY");
      },
    },
    {
      attrs: { datatitle: t("superAdmin.download") },
      text: "",
      dataField: "actions",
      formatter: (cellContent, row) => {
        return (
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() =>
              downloadForcastedInvoice(row._originalRecords || [row])
            }
          >
            <img src={downloadIcon} alt="" />
          </div>
        );
      },
    },
  ];

  let totalItems = 0;
  let rows;
  if (!isLoading) {
    // Use the processedData for display
    rows = processedData;
    totalItems = processedData.length;

    // If there's pagination data in forecastData, use that instead
    if (forecastData?.pagination?.totalItems) {
      totalItems = forecastData.pagination.totalItems;
    }
  } else {
    rows = new Array(PAGE_SIZE).fill({});
  }

  return (
    <>
      {isPDFLoading && <Loader />}
      <div className={`${styles.invoices_nav_tabs_wrapper} common-tabs`}>
        <Table
          columns={columns}
          data={rows}
          keyField="id"
          handlePagination={setPageNumber}
          pageNumber={pageNumber}
          pageSize={PAGE_SIZE}
          totalItems={totalItems}
        />
      </div>

      {!isLoading && totalItems === 0 && (
        <Empty Message={t("superAdmin.noInvoicesYet")} />
      )}
    </>
  );
}

export default withTranslation()(ForecastedInvoice);
