import { useEffect, useState } from "react";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import moment from "moment";
import { getStaffMembers } from "repositories/scheduler-repository";
import { addnotes, getOfficesforNotes } from "repositories/notes-repository";
import toast from "react-hot-toast";
import constants from "../../../../constants";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import useUploadService from "hooks/useUploadService";
import { getBlobnameFromUrl } from "utils";

const containerName = "globalnotesattachments";

export const useAddNoteController = (t, history) => {

    const profile = useSelector(state => state.userProfile.profile);
    const allowedTypes = constants.chat.allowedTypesForMessage;
    const { upload: uploadImage } = useUploadService();

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        assignType: 'self',
        startTime: null
    });
    const [toggleOn, setToggleOn] = useState(false);
    const [selectOffice, setOffice] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({ id: '', name: '' });
    const [confirmedStaff, setConfirmedStaff] = useState({ id: profile?.id });
    const [fileName, setFileName] = useState({ path: "", file: null });
    const [allMembersList, setAllMembersList] = useState([]);
    const [officeList, setOfficeList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState({});

    const [dates, setdates] = useState({
        from: moment().toDate(),
        to: moment().endOf("year").toDate(),
    });

    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value
        })
        if (name === 'assignType' && value === 'self') {
            setConfirmedStaff({ id: profile?.id })
        }
    }

    const handleTimer = (value) => {
        setFormData({
            ...formData,
            'startTime': value
        })
    }

    const handleFileChange = (event) => {
        if (!event || !event.target) return;

        const files = event.target.files;
        if (!files.length) return;

        if (!allowedTypes.includes(files[0]?.type)) {
            toast.error(t("fileNotSupported"));
            return;
        }
        setFileName({
            path: URL.createObjectURL(files[0]),
            file: files[0],
        });
    };

    const onBack = () => history.push(constants.routes.notes);

    const isValidEvent = () => {
        const errorsData = cloneDeep(errors);
        //Title
        if (!formData.title.trim().length) {
            errorsData.title = t("form.errors.emptyField", {
                field: t("title"),
            });
        } else {
            delete errorsData.title;
        }
        //Description
        if (!formData.description.trim().length) {
            errorsData.description = t("form.errors.emptyField", {
                field: t("description"),
            });
        } else {
            delete errorsData.description;
        }
        if (toggleOn) {
            //dates
            if (dates.from && !dates.to) {
                errorsData.dates = t('form.errors.emptyField', { field: t('end date') })
            } else {
                delete errorsData.dates;
            }
            //startTime
            if (!formData.startTime) {
                errorsData.startTime = t("form.errors.emptySelection", {
                    field: t("staff.startTime"),
                });
            } else {
                delete errorsData.startTime;
            }
        }

        //Office
        if (!selectOffice) {
            errorsData.selectOffice = t("form.errors.emptySelection", {
                field: t("form.fields.office"),
            });
        } else {
            delete errorsData.selectOffice;
        }
        //Staff
        if (selectOffice && formData?.assignType === 'staff' && !selectedStaff?.id) {
            errorsData.selectedStaff = t("form.errors.emptySelection", {
                field: t("form.fields.staff"),
            });
        } else {
            delete errorsData.selectedStaff;
        }

        setErrors(errorsData);
        return !Object.values(errorsData).some((err) => !!err);
    };

    const convertDateAndTime = () => {
        const datePart = moment(dates?.from);
        const timePart = moment(formData.startTime);
        const combinedDateTimeInIST = datePart.hour(timePart.hour()).minute(timePart.minute()).second(timePart.second());
        const combinedDateTimeInUTC = combinedDateTimeInIST.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        return combinedDateTimeInUTC;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isValidEvent()) {
            setShowLoader(true);
            const formateDateAndTime = convertDateAndTime();
            try {
                let imageBlobName;
                if (fileName && fileName.file && fileName.path) {
                    const [err, blobData] = await uploadImage(
                        fileName.file,
                        containerName
                    );

                    let file = blobData.blobUrl;

                    imageBlobName = `${containerName}/${blobData.blobName}`;

                    if (err) {
                        throw new Error(err);
                    }

                    setFileName({
                        path: file,
                        file: null,
                    });
                }

                if (fileName && fileName.path && !fileName.file) {
                    imageBlobName = `${containerName}/${getBlobnameFromUrl(
                        fileName.path,
                        containerName
                    )}`;
                }
                const params = {
                    title: formData?.title,
                    description: formData?.description,
                    assigneeUserId: confirmedStaff?.id,
                    officeId: selectOffice,
                    isReminderOn: toggleOn,
                    reminderDate: toggleOn ? formateDateAndTime : null,
                    fileLink: imageBlobName
                };

                let res = await addnotes(params);
                toast.success(res.message);
                if (res.status) onBack();
            } catch (err) {
                toast.error(err.message);
            } finally {
                setShowLoader(false);

            }
        }
    }

    const handleToggle = () => {
        setToggleOn(!toggleOn);
    };

    const startDatePickerRef = useReadOnlyDateTextInput();

    const updateDatesValues = (e, type) => {
        dates[type] = e;
        setdates({ ...dates });
    };

    const closeModal = () => setIsModalOpen(false);

    const handleStaffSelection = (staff) => {
        setSelectedStaff({ id: staff?.id, name: staff?.firstName + ' ' + staff?.lastName });
    };

    const handleApply = () => {
        setConfirmedStaff(selectedStaff);
        closeModal();
    };

    const handleCancel = () => {
        setSelectedStaff({});
        closeModal();
    };

    useEffect(() => {
        getAllOffices();
    }, [])

    useEffect(() => {
        if (selectOffice) {
            getOfficeMembers();
        }
    }, [selectOffice]);

    const getAllOffices = async () => {
        const pageNumber = 1;
        const pageSize = 20;
        try {
            const res = await getOfficesforNotes(pageNumber, pageSize);
            if (res && res?.items.length) {
                setOfficeList(res?.items);
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const getOfficeMembers = async () => {
        try {
            const res = await getStaffMembers(selectOffice);
            if (res && res.length) {
                setAllMembersList(res);
            }
        } catch (e) {
            console.log(e.message);
        }
    };

    const isVirtualOffice = selectOffice && officeList.length > 0 && officeList.find(val => val.id === selectOffice)?.isTempOffice;

    return {
        data: {
            formData,
            toggleOn,
            startDatePickerRef,
            dates,
            selectOffice,
            isModalOpen,
            confirmedStaff,
            selectedStaff,
            fileName,
            officeList,
            showLoader,
            allMembersList,
            isVirtualOffice
        },
        methods: {
            setFormData,
            handleSubmit,
            handleOnChange,
            handleToggle,
            updateDatesValues,
            setdates,
            setOffice,
            handleStaffSelection,
            handleCancel,
            handleApply,
            handleFileChange,
            closeModal,
            setIsModalOpen,
            setSelectedStaff,
            handleTimer,
            errors,
            onBack,
            setFileName
        }
    };
}
