import {
  getUnreadMessagesCount,
  useSendbirdMessageCount,
} from "hooks/useSendbirdMessageCount";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSbCalls } from "./calling/context";
import { ADD_UNREAD_MESSAGE_COUNT } from "reducers/ProfileRedcuer";

const MessageCountHook = () => {
  const dispatch = useDispatch();
  const { sdk } = useSbCalls();
  useEffect(() => {
    if ("groupChannel" in sdk) {
      getUnreadMessagesCount(sdk).then((res) => {
        if ("live" in res) {
          dispatch({
            type: ADD_UNREAD_MESSAGE_COUNT,
            payload: res,
          });
        }
      });
    }
  }, [sdk]);
  useSendbirdMessageCount({ dispatch });
  return null;
};
export default MessageCountHook;
