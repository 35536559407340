import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import moment from "moment";
import { cacheSideBarActive, getStorage } from "utils";
import DatePicker from "react-datepicker";
import { Card } from "reactstrap";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Page from "components/Page";
import styles from "../Appointments.module.scss"
import Loader from "components/Loader";
import AppointmentsTable from "../AppointmentsTable/AppointmentsTable";
import { useAllTodaysAppointments } from "repositories/patient-appointment-repository";
import GlobalListFilter from '../filter/index'
import constants from "../../../../constants";
import useTodaysAppointmetModule from "../hooks/useTodaysAppointmentModule";

function SeeTodaySchedule({ t, history }) {
    const officeState = getStorage('officeState')
    const {
        data: {
            isLoading,
            totalItems,
            pageNumber,
            appointments,
            showLoader,
            officeFilter,
            isFilterApply,
            doctorFilter,
            currentDates
        },
        methods: {
            setPageNumber,
            appointmentDetailAction,
            setOfficeFilter,
            handleApplyFilters,
            resetFilter,
            setDocotrFilter,
            updateCurrentDateValue
        }
    } = useTodaysAppointmetModule(useAllTodaysAppointments, officeState?.officeState,);

    const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

    const handleSidebarToggle = () => {
        setSidebarActive(!isSidebarActive);
        localStorage.setItem("isSidebarActive", !isSidebarActive);
    };

    const [dates, setdates] = useState({
        from: moment().toDate(),
        to: moment().endOf("year").toDate(),
    });

    const updateDatesValues = (e, type) => {
        dates[type] = e;
        setdates({ ...dates });
    };

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };

    const startDatePickerRef = useReadOnlyDateTextInput();
    const [openDatePicker, setDatePicker] = useState(false);

    return (
        <Page className="messenger-page-content scheduler-page" onBack={() => { history.push(`/appointments`) }} >
            <div className={styles["first-row-top"]}>
                <div className={styles["first-row"]}>
                    <div className={styles["first-row-left-part"]}>

                        <span className={styles["mobile-text-size"]}> {t("patientAppointmentMembers.todayPatientAppointments")}</span>
                        <div className={styles["change-date-box"]}>
                            <Text size="14px" weight="300" color="#000">{moment(currentDates).format('dd, MMM DD, YYYY')}</Text>
                            <span onClick={() => setDatePicker(!openDatePicker)} className={styles["change-date"]}>{t("patientAppointmentMembers.changeDate")}</span>
                            {openDatePicker &&(
                                 <div className={styles["open-datepicker-box"] + " " + "c-field"}>
                                    <div className="d-flex inputdate">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            className="c-form-control"
                                            selected={currentDates}
                                            open={openDatePicker}
                                            onChange={(e) => updateCurrentDateValue(e)}
                                            onSelect={() => setDatePicker(false)}
                                            onClickOutside={() => setDatePicker(false)}
                                            popperPlacement="bottom-start"
                                            popperContainer={CalendarContainer}
                                            ref={startDatePickerRef}
                                        />
                                    </div>
                            </div>)}
                        </div>


                    </div>
                    <div className={styles["see-todays-schedule-btn-wrapper"]}>
                        <Link to="/appointments" >
                            <button className={styles["manage-schedule"]}>
                                {t("patientAppointmentMembers.seeAllAppointments")}
                            </button>
                        </Link>
                        <Link to="/patient-new-appointment">
                            <button className="button button-round button-shadow">
                                {t("patientAppointmentMembers.newAppointment")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <Card className={`form-wrapper ${styles.card_new_wrapper}`}>
                <div className={styles.flex_wrapper}>
                    {isLoading && <Loader />}
                    <GlobalListFilter
                        isSidebarActive={isSidebarActive}
                        handleSidebarToggle={handleSidebarToggle}
                        resetFilter={resetFilter}
                        applyFilter={handleApplyFilters}
                        officeFilter={officeFilter}
                        setOfficeFilter={setOfficeFilter}
                        statusFilter={[]}
                        setStatusFilter={() => { }}
                        dates={dates}
                        updateDatesValues={updateDatesValues}
                        appointmentStatusList={constants.upComingAppointmentStatus}
                        state={officeState?.officeState}
                        isFilterApply={isFilterApply}
                        doctorFilter={doctorFilter}
                        setDocotrFilter={setDocotrFilter}
                        isFromTodaysSchedule={true}
                    />
                    <div className={styles.table_ui}>
                        <AppointmentsTable
                            appointments={appointments}
                            totalItems={totalItems}
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            appointmentDetailAction={appointmentDetailAction}
                            showLoader={showLoader}
                            searchValue={""}
                            handleInputChange={() => { }}
                            state={officeState?.officeState}
                            isFrompatientDetail={true}
                            manageTab={() => { }}
                        />
                    </div>
                </div>
            </Card>
        </Page>
    )
}
export default withTranslation()(SeeTodaySchedule);