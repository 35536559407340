
import { useEffect, useState } from "react";
import { decodeId } from "utils";
import moment from "moment";
import { useTaskPerformanceDetail } from "repositories/task-management-repository";
import { uniqBy } from "lodash";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const defaultDate = {
    from: moment().startOf('year').toDate(),
    to: moment().toDate()
  }

const defaultUtcDate = {
    from: moment().startOf('year').utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
    to: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
  }

const pageSize = 10
const usePerformanceDetailsController = () => {
    const [dates, setdates] = useState(defaultDate);
    const [utcdates, setUtcdates] = useState(defaultUtcDate);
    const [pageNumber, setPageNumber] = useState(1);
    const [assigneeList, setAssigneeList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [appliedStatusFilters, setAppliedStatusFilters] = useState(null);
    const queryClient = useQueryClient();
    let { userId, officeId } = useParams();
    userId = decodeId(userId);
    officeId = decodeId(officeId)
    const { isLoading, data, refetch } = useTaskPerformanceDetail(
        pageNumber,
        pageSize,
        decodeURIComponent(utcdates.from),
        decodeURIComponent(utcdates.to),
        userId,
        officeId,
        appliedStatusFilters?.value
      );

      useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setAssigneeList(uniqBy([...data?.items], "id"));
                setTotalItems(data?.pagination?.totalItems);
            }
        }

        else if (!isLoading && data && data.items) {
            setAssigneeList(prev => uniqBy([...prev, ...data?.items], "id"));
            setTotalItems(data?.pagination?.totalItems);
        }

    }, [isLoading, data]);

    useEffect(() => {
        setPageNumber(1);
        queryClient.invalidateQueries(["/tasks/Performance/details", pageNumber, pageSize, utcdates.from, utcdates.to, userId, officeId, appliedStatusFilters]);
        refetch();
    }, [dates, appliedStatusFilters])

    const updateDatesValues = (e, type) => {
        setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }))
        setUtcdates(prevState => ({
            ...prevState,
            from: type === 'from'
                ? moment(e).utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                : prevState.from,
                to: type === 'to'
                ? moment(e).utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                : prevState.to,
        }));
    }

    const handleRequestStatusFilter = (selectedStatus) => {
        setAppliedStatusFilters(selectedStatus)
    }
    const performanceLoader = isLoading

    return {
        data: {
            performanceLoader,
            appliedStatusFilters,
            assigneeList,
            totalItems,
            dates
        },
        methods: {
            setPageNumber,
            handleRequestStatusFilter,
            updateDatesValues
        }
    }
}

export default usePerformanceDetailsController;
