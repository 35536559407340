import React, { useState } from "react";
import Text from "components/Text";
import styles from "../PatientMessageCenter.module.scss";
import MessageDetails from "../components/MessageDetails";
import { withTranslation } from "react-i18next";
import AttachmentIconFile from "../../../../../assets/images/attachment_ico_file.png";
import ToggleSwitch from "components/ToggleSwitch";

const NewMessageDetails = ({
  t,
  messageNotifications,
  handleNotificationsChange,
  appliedMessageTemplate,
  isAttachMent,
  handleToggleAttachment,
  handlePreview,
  contentForPreview,
  error
}) => {
  const options = [
    { id: 1, label: "Email", name: "isEmail" },
    { id: 2, label: "SMS", name: "isSms" },
    { id: 3, label: "Notification", name: "isNotifications" },
  ];

  const messageDetailsData = [
    {
      id: 1,
      title: "Message Title",
      message: appliedMessageTemplate?.notificationTitle,
      condition:
        messageNotifications?.isSms || messageNotifications?.isNotifications,
    },
    {
      id: 2,
      title: "Content for Push Notifications & SMS",
      message: appliedMessageTemplate?.notificationContent,
      condition:
        messageNotifications?.isSms || messageNotifications?.isNotifications,
    },
    {
      id: 3,
      title: "Content for Emails",
      message: appliedMessageTemplate?.emailContent,
      condition: messageNotifications?.isEmail,
    },
  ];

  const getAttachmentName = (link) => {
    return link.substring(link.lastIndexOf("/") + 1);
  };

  const handleDownload = async (fileLink, fileName) => {
    // setShowLoader(true);
    try {
      const response = await fetch(fileLink);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      //   setShowLoader(false);
    }
  };

  return (
    <div className={styles.bg_wrapper}>
      <Text size="13px" weight="400" color="#79869A">
        Mode of Message
      </Text>
      <div className={styles.details_checkbox}>
        {options.map((option) => (
          <div
            key={option.id}
            className="ch-checkbox c-field all-event-checkbox m-0"
          >
            <label>
              <input
                type="checkbox"
                name={option?.name}
                checked={messageNotifications[option?.name]}
                onChange={handleNotificationsChange}
              />
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </div>

      <div className={styles.message_details_com_container}>
        {messageDetailsData
          .filter((detail) => detail.condition)
          .map((detail) => (
            <MessageDetails
              t={t}
              messageDetail={detail}
              appliedMessageTemplate={appliedMessageTemplate}
              handlePreview={handlePreview}
              contentForPreview={contentForPreview}
            />
          ))}
        {error && (
            <span className="error-msg">{error}</span>
          )}
        {appliedMessageTemplate?.attachmentLink && (
          <>
            <div>
              <div className="d-flex align-items-center mt-4">
                <Text color="#111B45" weight="600" size="16px">
                  {t("accountPreferences.attachment")}
                </Text>
                <ToggleSwitch
                  className="mt-0"
                  label="bill-me-later-toggle"
                  value={isAttachMent}
                  onChange={handleToggleAttachment}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <div className="mr-2">
                  <img src={AttachmentIconFile} alt="message template icon" />
                </div>
                <span>
                  {getAttachmentName(appliedMessageTemplate?.attachmentLink)}
                </span>
              </div>
              <button
                className={styles.download_btn}
                onClick={() =>
                  handleDownload(
                    appliedMessageTemplate?.attachmentLink,
                    getAttachmentName(appliedMessageTemplate?.attachmentLink)
                  )
                }
              >
                {t("accountPreferences.download")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(NewMessageDetails);
