import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";
import { withTranslation } from "react-i18next";
import { handleError } from "utils";
import constants from "../../../../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderIcon } from "react-hot-toast";
import { defaultOffice } from "Messenger/hooks/useChannelListHeaderState";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const CustomChannelList = ({ t, isFromDashBoard = false, ...props }) => {
  const {
    isSdkError,
    renderChannelPreview,
    onLeaveChannel,
    renderHeader = () => {},
    filteredOffices,
    currentUser,
    selectedOwner,
    externalTabActive,
    internalTabActive,
    searchText = "",
    doctorChatCheck,
    setAllChannelsOfficesForDoctor,
    toggleChat,
    isFromPatient = false,
    setCurrentChannel,
  } = props;
  const { allChannels, channelListQuery, channelListDispatcher, loading } =
    useChannelListContext();
  const loadMoreChannels = async () => {
    if (channelListQuery?._hasNext) {
      try {
        channelListDispatcher?.({
          type: "FETCH_CHANNELS_START",
          payload: null,
        });
        const nextChannels = await channelListQuery?.next?.();
        channelListDispatcher?.({
          type: "FETCH_CHANNELS_SUCCESS",
          payload: nextChannels,
        });
      } catch (err) {
        handleError(err);
      }
    }
  };

  const filterChannelsForOffices = (eachChannel) => {
    if (externalTabActive) return true;
    const { data, members } = eachChannel;
    const memberUserIds = members.map((m) => m.userId);
    if (
      !filteredOffices ||
      !filteredOffices.length ||
      filteredOffices.includes(constants.chat.ALL_OFFICES)
    ) {
      return true;
    } else {
      if (!data) return true;
      let parsedData = null;
      let currentChannelOffices = [];
      try {
        parsedData = JSON.parse(data);
      } catch (err) {
        handleError(err);
        return true;
      }
      if (parsedData?.members) {
        currentChannelOffices = parsedData.members
          .filter(({ id }) => memberUserIds.includes(`${id}`))
          .map(({ officeId }) => officeId)
          .filter((val) => !!val);
        const idx = currentChannelOffices?.findIndex((ofId) =>
          filteredOffices.includes(ofId)
        );
        return idx > -1 ? true : false;
      }
    }
  };

  const filterChannelsForAccountOwners = (eachChannel) => {
    if (externalTabActive) return true;
    const { data } = eachChannel;
    let showChannel = true;
    if (!currentUser.isAccountOwner && currentUser.isStaff && data) {
      const channelOwner = JSON.parse(data).accountOwner;
      if (channelOwner?.id?.toString() !== selectedOwner?.id?.toString()) {
        showChannel = false;
      }
    }
    return showChannel;
  };
  const currentUserId = useMemo(
    () => currentUser?.id?.toString(),
    [currentUser.id]
  );
  const filterByName = useCallback(
    (item) => {
      if (!searchText.trim()) return true;
      const [user] = item.members.filter((res) => currentUserId !== res.userId);
      const trimString = (val) => val.toLowerCase().trim();
      return trimString(user.nickname).includes(trimString(searchText));
    },
    [currentUserId, searchText]
  );

  const filteredChannels = useMemo(() => {
    return allChannels
      .filter(filterByName)
      .filter(filterChannelsForOffices)
      .filter(filterChannelsForAccountOwners);
  }, [
    allChannels,
    filteredOffices,
    selectedOwner,
    externalTabActive,
    searchText,
  ]);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (filteredChannels.length && location.search) {
      const params = new URLSearchParams(location.search);
      if (params.has("chat")) {
        const channel = filteredChannels.find(
          (res) => res._url === params.get("chat")
        );
        setCurrentChannel(channel);
        params.delete("chat");
        history.replace({
          search: params.toString(),
        });
      }
    }
  }, [filteredChannels, location]);
  useEffect(() => {
    if (doctorChatCheck) {
      setAllChannelsOfficesForDoctor(
        [defaultOffice].concat(
          allChannels.reduce(
            (prev, curr) => {
              const office = JSON.parse(curr.data)?.members;
              if (!prev.addedOffices.get(office[0].officeId)) {
                prev.addedOffices.set(office[0].officeId, office[0].officeId);
                prev.offices.push({
                  value: office?.length ? office[0].officeId : 0,
                  label: office?.length ? office[0].officeName : "",
                });
              }
              return prev;
            },
            {
              addedOffices: new Map(),
              offices: [],
            }
          ).offices
        )
      );
    }
  }, [allChannels]);

  useEffect(() => {
    const channelList = document.querySelector(".sendbird-channel-list");
    if (isFromDashBoard && channelList) {
      if (isSdkError || (!loading && filteredChannels?.length === 0)) {
        channelList.style.paddingRight = "0px";
        if (isFromPatient) {
          channelList.style.height = "415px";
        }
      } else {
        channelList.style.paddingRight = "";
      }
    }
  }, [isSdkError, loading, filteredChannels]);

  const noChatHeadingSubheading = useMemo(() => {
    if (internalTabActive) {
      return {
        heading: t("messenger.noInternalChat"),
        subheading: t("messenger.noInternalConversationDesc"),
      };
    }
    if (externalTabActive) {
      return {
        heading: t("messenger.noExternalChat"),
        subheading: t("messenger.noExternalConversationDesc"),
      };
    }
    if (doctorChatCheck) {
      return {
        heading: t("messenger.noDoctorChat"),
        subheading: t("messenger.noDoctorConversationDesc"),
      };
    }
    return {
      heading: t("messenger.noPatientChat"),
      subheading: t("messenger.noPatientConversationDesc"),
    };
  }, [doctorChatCheck, internalTabActive, externalTabActive]);
  return (
    <Fragment>
      <div className="channel-list-header">{renderHeader()}</div>
      {!isSdkError && loading && (
        <div
          className={
            isFromDashBoard ? "empty-channel-list h-100" : "empty-channel-list"
          }
        >
          <LoaderIcon style={{ margin: "0px auto", padding: "10px" }} />
        </div>
      )}
      {(isSdkError || (!loading && filteredChannels?.length === 0)) && (
        <div className="h-100 d-flex align-items-center">
          <div
            className="empty-channel-list"
            style={{
              height: isFromDashBoard ? "auto" : "",
            }}
          >
            <img
              src={require("assets/images/empty-chat-img.svg").default}
              alt="no-chat"
            />
            <h3> {noChatHeadingSubheading.heading}</h3>
            <p> {noChatHeadingSubheading.subheading}</p>
          </div>
        </div>
      )}
      {!isSdkError && !loading && filteredChannels?.length > 0 && (
        <div className="channel-chat-list d-" id="channel-list">
          <InfiniteScroll
            dataLength={allChannels?.length}
            hasMore={channelListQuery?._hasNext}
            next={loadMoreChannels}
            scrollableTarget="channel-list"
            loader={
              <LoaderIcon style={{ margin: "10px auto", padding: "10px" }} />
            }
          >
            <div
              className={`default-style ${
                isFromDashBoard
                  ? isFromPatient
                    ? "dashboard-patient"
                    : "dashboard"
                  : ""
              }`}
            >
              {
                filteredChannels
                  ?.map((channel) =>
                    renderChannelPreview({
                      channel,
                      onLeaveChannel,
                      toggleChat,
                    })
                  )
                  .concat(
                    channelListQuery?._hasNext && filteredChannels?.length < 7
                      ? [
                          <div
                            key="scroll-provider"
                            style={{
                              height: `${(7 - filteredChannels.length) * 88}px`,
                            }}
                          />,
                        ]
                      : []
                  )
                // The concat only adds a div to provide a scroll if less than 7 results appear and query still has some pages left.
              }
            </div>
          </InfiniteScroll>
        </div>
      )}
    </Fragment>
  );
};

export default withTranslation()(CustomChannelList);
