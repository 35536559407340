import React, { useState } from 'react'
import { Button } from 'reactstrap';
import Input from 'components/Input'
import styles from "../../../Appointments.module.scss"

const AddAppointmentNotes = ({t, onHide, handleCreateNotes, errors }) => {
    const [notes ,setNotes] = useState("");

  return (
    <div id="results" className={styles["add-notes-box"]}>
    <Input
        className={styles["appointment-input-box"]}
        Type="text"
        Placeholder="Add Note"
        Value = {notes}
        Name={"BusinessLegalName"}
        HandleChange = {(e) => setNotes(e.target.value)}
        Error={errors?.MessageError}
    />
    <div className={styles['common-appointment-btn-container']}>
        <Button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={() => handleCreateNotes(notes)} >
            {t("patientAppointmentMembers.saveNote")}
        </Button>
        <Button className="button button-round button-border button-dark btn-mobile-link" onClick={onHide}>

            {t("patientAppointmentMembers.cancel")}
        </Button>
    </div>
</div>
  )
}

export default AddAppointmentNotes