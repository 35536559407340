import React from "react";
import styles from "../../../../../accountOwner/pages/Appointments/Appointments.module.scss";
import Text from "components/Text";
import crossIcon from "../../../../../assets/images/cross.svg";

export default function InviteesPopup({
  handleInvitePopupToggle,
  position,
  list,
  officeName,
}) {
  return (
    <div
      className={styles.schedular_popup_invite_view}
      style={{
        top: position?.top,
        right: position?.right,
        position: "absolute",
      }}
    >
      <span className={styles.close_btn} onClick={handleInvitePopupToggle}>
        <img src={crossIcon} alt="close" />
      </span>

      <div className={styles.invitees_data_container}>
        {list?.length &&
          list?.map((val) => (
            <div className={styles.container_box} key={val?.id}>
              <img
                src={
                  val?.user?.profilePic ||
                  require("assets/images/default-image.svg").default
                }
                alt="DummyImg"
                className={styles.invitees_img}
              />
              <div>
                <Text size="14px" weight="600" color="#102C42">
                  {val?.user?.fullName}
                </Text>
                <Text size="12px" weight="400" color="#87928D">
                  {officeName}
                </Text>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
