import { useState, useEffect } from 'react';
import constants from "../../../../constants";
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { decodeId, encodeId, formatUTCDateTime, setStorage } from 'utils';
import { useOfficeDetail } from 'repositories/office-repository';




const pageSize = 10;
const useDashBoardModule = (useDaskboard, history) => {
    const profile = useSelector(state => state.userProfile.profile);
    const [dates, setdates] = useState({
        from: null,
        to: null
    });
    const [utcdates, setUtcdates] = useState({
            from: null,
            to: null
        });
    const [dashBoardData ,setDashBoardData] = useState(null)
    let { officeId } = useParams();
    const location = useLocation();
	const { state } = location;
    officeId = decodeId(officeId);
    let isAdmin = state?.isAdmin || false;
    const queryClient = useQueryClient();

    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(officeId, {
        enabled: !!officeId,
    });

    const { isLoading, data, refetch} = useDaskboard(
        officeId,
        isAdmin,
        utcdates?.from,
        utcdates?.to,
    );


    useEffect(() => {
        if (!isLoading && data?.length) {
            setDashBoardData(data[0]);
        }
         return () => {
            setStorage(constants.appointmentList.cache.isTabClicked, {
                isTabClicekd: false,
            });
        }
    }, [isLoading ,data])


    const updateDatesValues = (e, type) => {
        setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }))
        setUtcdates(prevState => ({
            ...prevState,
            from: type === 'from'
                ? formatUTCDateTime(e)
                : prevState.from,
                to: type === 'to'
                ? formatUTCDateTime(e)
                : prevState.to,
        }));
        queryClient.invalidateQueries(["/Doctor/Dashboard", officeId, utcdates?.from, utcdates?.to, isAdmin],);
        refetch();
    }


    const onBack = () => {
        history.push(
            constants.routes.accountOwner.officeOptions.replace(":officeId", encodeId(officeId))
        );
    };


    const handleRedirect = (activeTabs, status) => {
        history.push(
            {pathname : constants.routes.appointment.appointmentScreen , state : {officeId :officeId, activetabs : activeTabs, statusFilter : status, isfromPaitentDetail : true, isAdmin : state?.isAdmin}}
        );
    };

    const handleRedirecttoPaitents = () => {
        history.push(constants.routes.appointment.patients.replace(":officeId", encodeId(officeId)));
        setStorage('officeState', {
          officeState: {},
        });
      };

    return {
        data: {
            isLoading,
            dashBoardData,
            dates,
            officeDetail
        },
        methods: {
            updateDatesValues,
            onBack,
            handleRedirect,
            handleRedirecttoPaitents
        }
    }
}

export default useDashBoardModule;