/** State */
export const initialState = {
  user: undefined,
  appointmentUser: undefined,
  calls: [],
  rooms: [],
  audioInputDeviceInfo: { current: undefined, available: [] },
  audioOutputDeviceInfo: { current: undefined, available: [] },
  videoInputDeviceInfo: { current: undefined, available: [] },
  appIntialized: false,
  appointmentDetail: {},
};
