import TypeOfAppointment from 'accountOwner/pages/Appointments/components/TypeOfAppointment'
import Page from 'components/Page';
import React from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

function SelectAppointmentType() {
    const history = useHistory();
    return (
        <Page onBack={() => { history.push(`/create-appointment`) }}>
            <TypeOfAppointment />
        </Page>
    )
}
export default withTranslation()(SelectAppointmentType)