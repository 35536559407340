import React, { useState } from "react";
import Text from "components/Text";
import DatePicker from "react-datepicker";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import "../UpcomingTabs/UpcomingTabs.scss";
import { Portal } from "react-overlays";

const DateFilter = ({ t, dates, updateDatesValues }) => {
  const startDatePickerRef = useReadOnlyDateTextInput();
  const endDatePickerRef = useReadOnlyDateTextInput();
  const [errors, seterrors] = useState({});

  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };

  return (
    <>
      <Text size="12px" marginBottom="12px" weight="400" color="#102C42">
        {t("accountOwner.dateRange")}
      </Text>

      <div className="invitation_date_picker">
        <div className="invitation_date">
          <div className="c-field">
            <label>{t("from")}</label>
            <div className="d-flex inputdate">
              <DatePicker
                dateFormat="dd-MM-yyyy"
                className="c-form-control"
                selected={dates.from}
                onChange={(e) => updateDatesValues(e, "from")}
                maxDate={dates.to}
                popperPlacement="bottom-start"
                popperContainer={CalendarContainer}
                ref={startDatePickerRef}
              />
              {errors.from && (
                <span className="error-msg" style={{ display: "inline" }}>
                  {errors.from}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="invitation_date">
          <div className="c-field">
            <label>{t("to")}</label>
            <div className="d-flex inputdate">
              <DatePicker
                dateFormat="dd-MM-yyyy"
                className="c-form-control"
                selected={dates.to}
                minDate={dates.from}
                onChange={(e) => updateDatesValues(e, "to")}
                popperPlacement="bottom-end"
                popperContainer={CalendarContainer}
                ref={endDatePickerRef}
              />
              {errors.to && (
                <span className="error-msg" style={{ display: "inline" }}>
                  {errors.to}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateFilter;
