import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'rc-time-picker/assets/index.css'
import { Modal } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import crossIcon from '../../../../assets/images/cross.svg'
import Text from 'components/Text'
import styles from '../../Scheduler/components/Modals/Modals.module.scss';
import Input from 'components/Input'
import "../TaskManagement.scss"

function CompletionModal({ t, isCompletionModalOpen, handleCompletionModal, confirmComletion, AssigneeId }) {

    const [completiondata, setComplitionData] = useState({ comment: "", hours: 0, minutes: 0 });

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'minutes' && (value < 0 || value > 59)) {
            return;
        }

        if (name === 'hours' && value !== '' && isNaN(value)) {
            return;
        }

        setComplitionData({ ...completiondata, [name]: value });
    }
    const submitCompleteTask = async () => {
        let text = completiondata.comment.trim();
        let totalMinutes = (+completiondata.hours) * 60 + (+completiondata.minutes);
        let params = {
            completionTimeInMinutes: totalMinutes,
            comment: text,
        }
        let res = await confirmComletion(AssigneeId, "Complete", params);
        if (res?.status) {
            handleCompletionModal();
        }
    }

    return (
        <>
            <Modal
                isOpen={isCompletionModalOpen}
                toggle={handleCompletionModal}
                className={"modal-dialog-centered modal-lg" + styles['rejection-modal-dialog']}
                modalClassName='custom-modal'>
                <span className='close-btn' onClick={handleCompletionModal}>
                    <img src={crossIcon} alt='close' />
                </span>

                <ModalBody>

                    <div className="c-field">
                        <label>{t("superAdmin.comment")}</label>
                        <textarea
                            className="c-form-control"
                            placeholder="Enter"
                            name="comment"
                            value={completiondata.comment}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="task_completion_wrapper">
                        <Text size="14px" weight="600" color="#102C42">{t("taskManagement.completionTime")}</Text>
                        <div className="task_completion">
                            <Input
                                Title="Hours"
                                Type="Number"
                                Placeholder="Hours"
                                Name="hours"
                                HandleChange={(e) => handleChange(e)}
                                value={completiondata.hours}
                                Classes="task_completion_input"
                            />
                            <Input
                                Title="Minutes"
                                Type="Number"
                                Placeholder="Minutes"
                                Name="minutes"
                                HandleChange={(e) => handleChange(e)}
                                maxLength={60}
                                value={completiondata.minutes}
                                Classes="task_completion_input"
                            />
                        </div>
                    </div>
                    <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                        title={t("accountOwner.cancelSchedule")}
                        onClick={submitCompleteTask}
                    >
                        {t('submit')}
                    </button>
                    <button className="button button-round button-border button-dark btn-mobile-link"
                        title={t('accountOwner.goBack')} onClick={handleCompletionModal}>
                        {t("cancel")}
                    </button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default withTranslation()(CompletionModal)