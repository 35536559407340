import React, { Fragment, useEffect, useState } from "react";
import EditIcon from "../../../../assets/images/edit-icon.svg";
import styles from "./../DiscussionNotes.module.scss";
import Card from "components/Card";

export default function NoteDetail({ t, selectNote, setIsEditModalOpen }) {
	const [emptyMessage, setEmptyMessage] = useState("");

	const updateEmptyMessage = () => {
		if (window.innerWidth <= 767) {
			setEmptyMessage("");
		} else {
			setEmptyMessage(t('discussionNote.selectNote'));
		}
	};

	useEffect(() => {
		updateEmptyMessage();
		window.addEventListener("resize", updateEmptyMessage);
		return () => {
			window.removeEventListener("resize", updateEmptyMessage);
		};
	}, [t]);

	return (
		<Fragment>
			{selectNote && <div className={styles.notes_detail_wrapper}>
				<div>{t('discussionNote.noteDetail')}</div>
				<img src={EditIcon} alt="icon" onClick={() => setIsEditModalOpen(true)} />
			</div>}
			{selectNote ? (
				<div className={styles.notes_container}>
					<Card className={styles.notes_msg_container}>
						<p className={styles.notes_msg}>
							{selectNote?.description}
						</p>
					</Card>
				</div>
			)
				:
				(
					<div className={styles.notes_detail_container}>{emptyMessage}</div>
				)
			}
		</Fragment>
	);
}
