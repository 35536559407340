import axios from "axios";
import { useQuery } from "react-query";
import { Config } from "../config";

const baseUrl = Config.serviceUrls.officeBaseUrl;
const notesUrl = Config.serviceUrls.notesBaseUrl;
const OFFICE_POINT_URL = Config.serviceUrls.officeBaseUrl;
const USER_POINT_URL = Config.serviceUrls.userBaseUrl;

export async function getAllNotes(pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen) {
    const response = await axios.post(`${notesUrl}/GlobalNote/List`, {
        pageNumber,
        pageSize,
        officeIds: officeFilter,
        UserIds: staffFilter,
        searchTerm: searchValue,
        isArchive: isArchive,
        isSeen: isSeen
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAllNotes(pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen) {
    return useQuery(
        ["/notes/myNote", pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen],
        () => getAllNotes(pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen),
        {
            staleTime: 0,
            cacheTime: 0
        }
    );
}

export async function getTransferredNotes(pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen) {
    const response = await axios.post(`${notesUrl}/GlobalNote/TransferList`, {
        pageNumber,
        pageSize,
        officeIds: officeFilter,
        UserIds: staffFilter,
        searchTerm: searchValue,
        isArchive: isArchive,
        isSeen: isSeen
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useTransferredNotes(pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen) {
    return useQuery(
        ["/notes/transferred", pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen],
        () => getTransferredNotes(pageNumber, pageSize, officeFilter, staffFilter, searchValue, isArchive, isSeen), {
        staleTime: 0,
        cacheTime: 0
    }
    );
}


export async function getOfficesforNotes(
    pageNumber,
    pageSize
) {
    const response = await axios.get(`${baseUrl}/Office/AllActiveAndTemp`, {
        params: {
            pageNumber,
            pageSize,
        },
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useOffices(
    pageNumber,
    pageSize,
    options = {}
) {
    return useQuery(
        ["notes/Office", pageNumber, pageSize],
        () => getOfficesforNotes(pageNumber, pageSize),
        options
    );
}

export const addnotes = async (params) => {
    const response = await axios.post(
        `${notesUrl}/GlobalNote`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const getNoteById = async (noteId) => {
    const response = await axios.get(`${notesUrl}/GlobalNote/${noteId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data
}

export function useMyNoteById(noteId) {
    return useQuery(
        ["mynotes/id", noteId],
        () => getNoteById(noteId)
    );
}

export async function getAllActiveAndTempOffices(pageNumber, pageSize) {
    const response = await axios.get(`${OFFICE_POINT_URL}/Office/AllActiveAndTemp`, {
        params: {
            pageNumber,
            pageSize
        },
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data;
}

export function useAllActiveAndTempOffices(
    pageNumber,
    pageSize,
    options = {}
) {
    return useQuery(
        ["/Office/AllActiveAndTemp", pageNumber, pageSize],
        () => getAllActiveAndTempOffices(pageNumber, pageSize),
        options
    );
}

export async function getAllStaff() {
    const response = await axios.get(`${notesUrl}/GlobalNote/AssignerList`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data;
}

export function useAllStaff() {
    return useQuery(
        ["/staff/All"],
        () => getAllStaff());
}

export async function getAssigneeList() {
    const response = await axios.get(`${notesUrl}/GlobalNote/AssigneeList`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data;
}

export function useAssigneeList() {
    return useQuery(
        ["/assignee/All"],
        () => getAssigneeList());
}

export async function archieveNote(noteId) {
    const response = await axios.patch(`${notesUrl}/GlobalNote/ArchiveByAssignee?GlobalNoteId=${noteId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function archieveTransferredNote(noteId) {
    const response = await axios.patch(`${notesUrl}/GlobalNote/ArchiveByAssigner?GlobalNoteTransferHistoryId=${noteId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function updateReminder(noteId, payload) {
    const response = await axios.put(`${notesUrl}/GlobalNote/${noteId}/Reminder`, payload);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function updateNoteSeen(GlobalNoteTransferHistoryId) {
    const response = await axios.patch(`${notesUrl}/GlobalNote/seen?GlobalNoteTransferHistoryId=${GlobalNoteTransferHistoryId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteNote(noteId) {
    const response = await axios.delete(`${notesUrl}/GlobalNote/${noteId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteTransferredNote(noteId) {
    const response = await axios.delete(`${notesUrl}/GlobalNote/TransferHistory?GlobalNoteTransferHistoryId=${noteId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function unArchieveNote(noteId) {
    const response = await axios.patch(`${notesUrl}/GlobalNote/UnarchiveByAssignee?GlobalNoteId=${noteId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function unArchieveTransferredNote(noteId) {
    const response = await axios.patch(`${notesUrl}/GlobalNote/UnarchiveByAssigner?GlobalNoteTransferHistoryId=${noteId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function transferNote(noteId, assigneeUserId) {
    const response = await axios.put(`${notesUrl}/GlobalNote/Transfer?globalNoteId=${noteId}&newAssigneeUserId=${assigneeUserId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function updateProfile() {
    const response = await axios.put(`${USER_POINT_URL}/Preferences/NeverShowTransferGlobalNoteMessage`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getStaffByDesignationIdAndOfficeId(DesignationIds, OfficeIds, OwnerId) {
    const response = await axios.post(`${USER_POINT_URL}/Staff/ByDesignationIdAndOfficeId`, {
            DesignationIds,
            OfficeIds,
            OwnerId 
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data;
}

export function useAllStaffByDesignationIdAndOfficeId(
    DesignationIds,
    OfficeIds,
    OwnerId,
    options = {}
) {
    return useQuery(
        ["/Staff/ByDesignationIdAndOfficeId", DesignationIds, OfficeIds, OwnerId],
        () => getStaffByDesignationIdAndOfficeId(DesignationIds, OfficeIds, OwnerId),
        options
    );
}