import styles from "../PerformanceReview.module.scss";
import moment from "moment";
import Button from "components/Button";
import { Fragment } from "react";

const dateKeys = ['dateOfEmployment', 'lastRaiseDate', 'proposedIncreaseDate'];

const ReviewDetailSection = ({ t, review, formData, initialValues, giveFeedback, setFeedback, isMyPerformanceReview, showGiveFeebackButton, showFinalize, handleFinalize }) => {

    const firstRowKeys = Object.keys(initialValues).slice(0, 4);
    const secondColumnKeys = Object.keys(initialValues).slice(4);

    return <Fragment>
        {/*  we have to use grid here for better structure*/}
        <div className={styles.grid_container_details}>
            <div className={styles.review_sent_tag} >
                <p className={styles.text_element}>
                    {t("performanceReview.reviewSent")}
                </p>
                <p className={styles.text_data}>
                    {review && moment(review?.createdAt).format('MMM D, YYYY')}
                </p>
                {firstRowKeys.map((key) => (
                    <div key={key}>
                        <p className={styles.text_element}>{t(`performanceReview.form.${key}`)}</p>
                        <p className={styles.text_data}>
                            {dateKeys.includes(key) ? moment(formData[key]).format('MMM D, YYYY') : formData[key]}
                        </p>
                    </div>
                ))}
            </div>
            <div className={styles.review_sent_tag}>
                {secondColumnKeys.map((key) => (
                    <div key={key}>
                        <p className={styles.text_element}>{t(`performanceReview.form.${key}`)}</p>
                        <p className={styles.text_data}>
                            {dateKeys.includes(key) ? moment(formData[key]).format('MMM D, YYYY') : formData[key]}
                        </p>
                    </div>
                ))}
            </div>
        </div>
        {(showGiveFeebackButton && !giveFeedback && !isMyPerformanceReview) && <div className={styles.btn_wrapper + " " + styles.margin40}>
            <Button className="w-sm-100" onClick={() => setFeedback(true)} type='submit'>{t('performanceReview.giveFeedback')}</Button>
        </div>}
        {(showFinalize && !giveFeedback && !isMyPerformanceReview) && <div className={styles.btn_wrapper + " " + styles.margin40}>
            <Button className="w-sm-100" type='submit' onClick={handleFinalize}>{t('performanceReview.finalize')}</Button>
            <Button className="w-sm-100" borderedSecondary type="button" onClick={() => setFeedback(true)}>
                {t('performanceReview.update')}
            </Button>
        </div>}
    </Fragment>
}

export default ReviewDetailSection;