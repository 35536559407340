import React from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import  {
    taskStatusOptions
  } from "../../../../constants";

function StatusCheckFitler({ setStatusFilter, seletedStatus}) {
    
    const handleChange = (e, item) => {
       
        const status = [...seletedStatus];
        const staffIndex = status?.findIndex((v) => +item.value === +v);
        if (e.target.checked) {
            status.push(item.value);
        } else {
            if (staffIndex > -1) status.splice(staffIndex, 1);
        }
        setStatusFilter(status);
    };

    return (
        <ul className={["filter-list"]}>
            {taskStatusOptions?.slice(1).map((item, key) => (
                    <li key={key}>
                        <div className="ch-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleChange(e, item)}
                                    checked={seletedStatus?.includes(item.value)}
                                />{" "}
                                <span> {item.label}</span>
                            </label>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default StatusCheckFitler;
