import React from 'react'
import Page from "components/Page";
import { withTranslation } from 'react-i18next';
import Text from 'components/Text';
import styles from "../../../Appointments.module.scss"
import usePatientAppointmentDetailModule from 'accountOwner/pages/Appointments/hooks/usePatientAppointmentDetailModule';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import constants from "../../../../../../constants";
import { encodeId } from 'utils';
import Loader from 'components/Loader';

const CardListItem = ({ t ,character }) => {
  return (
    <li>
      <div className={styles["submitted-questionnaire-card"]}>
        <Text size="16px" weight="600" color="#587E85" marginBottom="35px"> {character.question} </Text>
        {character?.patientQuestionnaireOptionResponse?.map((item) => {
          if (!item?.isSeleted) return null; 
          if(character?.questionOrder === 3 && item?.isSeleted) {
            return (
              <Text size="14px" weight="600" color="#102C42">
                  {item?.optionName || ""}
                </Text>
            )
          }
          return (
            <div key={item.optionName}>
              <div className="ch-radio">
                <label className="mr-5">
                  <input
                    type="radio"
                    name={item?.patientQuestionnaireResponseId}
                    checked={true}
                    readOnly
                  />
                  <span>{item?.optionName}</span>
                </label>
                <Text size="12px" weight="400" color="#87928D;">
                  {item?.timeRequired || ""}
                </Text>
              </div>

              {item?.isSubOption &&
                item?.patientQuestionnaireSubOptionResponse?.map((subItem) => {
                  if (!subItem.isSeleted) return null;

                  return (
                    <div className={styles["sub-option"]} key={subItem.subOptionName}>
                      <Text size="13px" weight="400" color="#587E85">
                        {item.patientQuestionnaireSubOptionResponse.length} {t("patientAppointmentMembers.suboptions")}
                      </Text>
                      <div
                        className={`${styles["sub-option-radio"]} ch-radio`}
                      >
                        <label className="mr-5">
                          <input
                            type="radio"
                            name="blockTimeslotAallDoctors"
                            checked={true}
                            readOnly
                          />
                          <span>{subItem.subOptionName}</span>
                        </label>
                        <Text size="12px" weight="400" color="#87928D;">
                          {subItem.timeRequired || ""}
                        </Text>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}

        <Text size="14px" weight="600" color="#102C42" >{character.quesionnaireTextResponse || ""}</Text>
      </div>
    </li>
  );
};

const CardList = ({ t,questionnaireResponse }) => {
  return (
    <ul>
      {questionnaireResponse?.map(char => {
        return <CardListItem t={t} character={char} key={char.id} />;
      })}
    </ul>
  );
};


const SubmittedQuestionnaire = ({ t }) => {
  const history = useHistory();
  const {
    data: {
      appointmentId,
      isLoading,
      questionnaireResponse,
      appointmentDetail
    }
  } = usePatientAppointmentDetailModule();

  const goBack = () => {
    history.push(constants.routes.appointment.patientAppointmentDetails.replace(":appointmentId", encodeId(appointmentId)));
  };


  return (
    <Page onBack={goBack}>
      {isLoading && <Loader />}
      <Text size="25px" weight="500" color="#111B45" marginBottom="10px">{appointmentDetail?.office?.name}</Text>
      <Text size="14px" weight="300" color="#000000" marginBottom="30px">{t("patientAppointmentMembers.submittedQuestionnaire")}</Text>
      <CardList questionnaireResponse={questionnaireResponse} t={t}/>
    </Page>
  )
}
export default withTranslation()(SubmittedQuestionnaire);