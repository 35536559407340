import React, { useEffect, useState } from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useAssigneeOfficeList, useOfficeList } from "repositories/task-management-repository";
import {  useQueryClient } from "react-query";

function OfficeFilter({ setOfficeFilter, selectedOffice, t, isStaff = false, accountOwnerId = null }) {
  const queryClient = useQueryClient();
  const { data : officeListData, refetch } = useOfficeList(accountOwnerId ,{
      enabled: !isStaff
    });
    const { data : assigneeOfficeListData } = useAssigneeOfficeList({
      enabled: isStaff
  });
    const [selectAll, setSelectAll] = useState(false);

    const data = isStaff ? assigneeOfficeListData : officeListData;

    useEffect(() => {
      queryClient.invalidateQueries(["/task/officeList", accountOwnerId]);
      refetch();
  }, [accountOwnerId, refetch])

    useEffect(() => {
        if (selectedOffice?.length === data?.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedOffice, data]);

    const handleChange = (e, item) => {
        const offices = [...selectedOffice];
        const officeIndex = offices.findIndex((v) => +item.id === +v);
        if (e.target.checked) {
            offices.push(item.id);
        } else {
            if (officeIndex > -1) offices.splice(officeIndex, 1);
        }
        setOfficeFilter(offices);
        setSelectAll(false);
    };

    const handleAllChange = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setOfficeFilter(data?.length ? data?.map((item) => item.id) : []);
        } else {
            setOfficeFilter([]);
        }
    };
    return (
        <ul className={["filter-list"]}>
            {data?.length > 0  && (
                <ul>
                    <li>
                        <div className="ch-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={handleAllChange}
                                    checked={selectAll}
                                />{" "}
                                <span>
                                  {t("notes.allFilters")}
                                  </span>
                            </label>
                        </div>
                    </li>
                    <hr className="mr-4" />
                </ul>
            )}
            {data?.length > 0  && 
                data?.map((item, key) => (
                    <li key={key}>
                        <div className="ch-checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleChange(e, item)}
                                    checked={selectedOffice.includes(item.id)}
                                />{" "}
                                <span> {item.name}</span>
                            </label>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default OfficeFilter;