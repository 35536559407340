import React, { Fragment, useState } from "react";
import DashboardFilter from "./Filters/DashboardFilter";
import Page from "components/Page";
import Card from "components/Card";
import Chat from "./Chat/Chat";
import styles from "./Dashboard.module.scss";
import Tasks from "./Tasks/Tasks";
import Notes from "./Notes/Notes";
import RequestApproval from "./RequestApproval";
import SchedulerDashboard from "./SchedulerDashboard/SchedulerDashboard";
import useDashBoardModule from "./hooks/DashBoardModule";
import { sortBy } from "lodash";
import { withTranslation } from "react-i18next";
import AccountOwnerModal from "../TaskManagement/Modals/AccountOwnerModal";
import { useSelector } from "react-redux";
import { getStorage, setStorage } from "utils";

function ManagerDashboard({ t, history }) {
  const isActiveSidebar = getStorage("isDashboardSidebarActive");
  const [isSidebarActive, setIsSidebarActive] = useState(
    isActiveSidebar !== false
  );
  const profile = useSelector((state) => state.userProfile.profile);
  const sortedOwnerList = (ownerList) => {
    return sortBy(ownerList, [(owner) => owner?.firstName?.toLowerCase()]);
  };

  const handleSidebarToggle = () => {
    setIsSidebarActive(!isSidebarActive);
    setStorage("isDashboardSidebarActive", !isSidebarActive);
  };
  const goBack = () => history.push("/");
  const {
    data: {
      officeFilter,
      staffFilter,
      agendaTypes,
      viewSettig,
      selectedRoles,
      appliedOfficeFilters,
      appliedStaffFilters,
      appliedAgendaTypes,
      appliedViewSettig,
      ownerId,
      ownerData,
      isFilterApply,
      currentDate,
      ownerList,
      selectedOwner,
      isAdminModalOpen,
      isAccountOwner,
      isAdminData,
      isSchedulerOnly,
    },
    methods: {
      setOfficeFilter,
      setStaffFilter,
      setSelectedRoles,
      setAgendaTypes,
      setViewSetting,
      handleApplyFilter,
      resetFilter,
      onDateChange,
      onActiveStartDateChange,
      switchNewAccountOwner,
      handleToggleAccountOwner,
      setIsAdminData,
      setIsSchedulerOnly,
      handleToggleScheduler,
    },
  } = useDashBoardModule();

  return (
    <Page
      className="scheduler-page"
      onBack={() => {
        goBack();
      }}
    >
      <div className={styles.title_change_btn_wrapper}>
        <h1 className="title">
          {isAccountOwner || profile?.isAdmin
            ? t("dashBoard.managerDashBoard")
            : t("dashBoard.teamDashboard")}
        </h1>

        {!isAccountOwner && (
          <div className={styles.change_btn_wrapper}>
            <p>
              {t("dashBoard.showDashBoard")}{" "}
              <span>
                {selectedOwner?.firstName} {selectedOwner?.lastName}
              </span>
            </p>
            <button className="link-btn" onClick={handleToggleAccountOwner}>
              {t("change")}
            </button>
          </div>
        )}
      </div>

      <Card className={styles.manager_dashboard_card}>
        <div
          className={`${styles.dashboard_card_filter_container} scheduler-tabs-main-wrapper m-0 `}
          id="targetElement"
        >
          <DashboardFilter
            isSidebarActive={isSidebarActive}
            handleSidebarToggle={handleSidebarToggle}
            officeFilter={officeFilter}
            setOfficeFilter={setOfficeFilter}
            staffFilter={staffFilter}
            setStaffFilter={setStaffFilter}
            handleApplyFilter={handleApplyFilter}
            resetFilter={resetFilter}
            setSelectedRoles={setSelectedRoles}
            selectedRoles={selectedRoles}
            ownerId={ownerId}
            ownerData={ownerData}
            isFilterApply={isFilterApply}
            currentDate={currentDate}
            onDateChange={onDateChange}
            onActiveStartDateChange={onActiveStartDateChange}
            agendaTypes={agendaTypes}
            viewSettig={viewSettig}
            setAgendaTypes={setAgendaTypes}
            setViewSetting={setViewSetting}
            setIsAdminData={setIsAdminData}
            isSchedulerOnly={isSchedulerOnly}
            setIsSchedulerOnly={setIsSchedulerOnly}
            handleToggleScheduler={handleToggleScheduler}
          />
          <div className={styles.dashboad_right_container}>
            <div className={styles.grid_system}>
              <div className={styles.box}>
                <SchedulerDashboard
                  currentDate={currentDate}
                  onDateChange={onDateChange}
                  appliedAgendaTypes={appliedAgendaTypes}
                  appliedViewSettig={appliedViewSettig}
                  appliedOfficeFilters={appliedOfficeFilters}
                  appliedStaffFilters={appliedStaffFilters}
                  isSidebarActive={isSidebarActive}
                  handleSidebarToggle={handleSidebarToggle}
                />
              </div>            
                <div className={styles.box}>
                  <Chat
                    appliedOfficeFilters={
                      !isSchedulerOnly ? appliedOfficeFilters : []
                    }
                    dashBoardSelectedOwner={selectedOwner}
                  />
                </div>           
              <div className={styles.box}>
                <Notes
                  appliedOfficeFilters={appliedOfficeFilters}
                  appliedStaffFilters={appliedStaffFilters}
                  isSchedulerOnly={isSchedulerOnly}
                />
              </div>
              <div className={styles.box}>
                <RequestApproval
                  isAdminData={isAdminData}
                  appliedOfficeFilters={appliedOfficeFilters}
                  appliedStaffFilters={appliedStaffFilters}
                  isSchedulerOnly={isSchedulerOnly}
                />
              </div>
              <div className={styles.box}>
                <Tasks
                  appliedOfficeFilters={appliedOfficeFilters}
                  appliedStaffFilters={appliedStaffFilters}
                  selectedOwner={selectedOwner}
                  isSchedulerOnly={isSchedulerOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>

      {isAdminModalOpen && (
        <AccountOwnerModal
          isModalOpen={isAdminModalOpen}
          closeModal={handleToggleAccountOwner}
          handleCancel={handleToggleAccountOwner}
          allMembersList={sortedOwnerList(ownerList || [])}
          selectedOwner={selectedOwner}
          handleOnChange={switchNewAccountOwner}
        />
      )}
    </Page>
  );
}

export default withTranslation()(ManagerDashboard);
