import React, { Fragment } from "react";
import Text from "components/Text";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import Card from "components/Card";
import alertNotification from "../../../../../assets/images/alert_notification.svg";
import styles from "../../Notes.module.scss";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import ReminderModel from "../../modal/ReminderModel";
import DeleteModel from "../../modal/DeleteModel";
import StaffModel from "../../modal/StaffModel";
import Loader from "components/Loader";
import useCardModule from "../../hooks/useCardModule";
import ConfirmationPopup from "../../modal/ConfirmationPopup";

const NotesCard = ({
	t,
	history,
	myNote,
	transferredNote,
	handleArchive,
	isArchive,
	handleUpdateReminder,
	updateStateAfterDelete,
	handleUnArchieveNote,
	handleTransferNote,
	className
}) => {

	const {
		data: {
			noteCardDetail,
			profile,
			dropdownOpen,
			isModalOpen,
			isDeleteModelOpen,
			isStaffModalOpen,
			allMembersList,
			selectedStaff,
			isStaffLoading,
			status,
			isConfirmModalOpen
		},
		methods: {
			handleCardClick,
			capitalizeAllLetters,
			getButtonClass,
			getBackgroundColor,
			handleCancel,
			handleApply,
			handleStaffSelection,
			closeStaffModal,
			handleTransfer,
			toggleDropdown,
			closeModal,
			setIsDeleteModel,
			setIsModalOpen,
			closeAlertModal,
			handleConfirm,
			handleConfirmCheck,
			handleCancelConfirmMessage
		}
	} = useCardModule(myNote, transferredNote, handleTransferNote, history)

	return (
		<Fragment>
			<Card
				className={className}
				style={{ backgroundColor: getBackgroundColor() }}
				onClick={(e) => handleCardClick(e)}
			>
				<div className={styles["dropdown_wrapper"]}>
					<div className={styles["btn_date_wrapper"]}>
						<button className={getButtonClass()}>
							{capitalizeAllLetters(status)}
						</button>
						<div className={styles["notes_date"]}>
							{moment(noteCardDetail.note_date).format(
								"MMM D, YYYY h:mm A"
							)}
						</div>
					</div>
					<Dropdown
						isOpen={dropdownOpen}
						toggle={toggleDropdown}
						className="interactive-element"
					>
						<DropdownToggle
							caret={false}
							tag="div"
							className="dropdown-btn px-3"
						>
							<img
								src={require("assets/images/dots-icon.svg").default}
								alt="icon"
							/>
						</DropdownToggle>
						<DropdownMenu right className={styles["custom_dropdown_wrapper"]}>
							<DropdownItem className={`${styles["dropdown_list"]} p-0`}>
								<p
									className={`${styles["dropdown_link"]} mb-0`}
									onClick={() => setIsDeleteModel(true)}
								>
									{t("notes.delete")}
								</p>
							</DropdownItem>
							{!isArchive && (
								<>
									{!transferredNote && !myNote?.office?.isTempOffice && <DropdownItem className={`${styles["dropdown_list"]} p-0`}>
										<Text
											className={styles["dropdown_link"]}
											onClick={() => handleTransfer(myNote?.office?.id)}
										>
											{t("notes.transfer")}
										</Text>
									</DropdownItem>}
									<DropdownItem className={`${styles["dropdown_list"]} p-0`}>
										<Text
											className={styles["dropdown_link"]}
											onClick={() => handleArchive(myNote?.id || transferredNote?.id)}
										>
											{t("notes.archive")}
										</Text>
									</DropdownItem>
								</>
							)}
							{isArchive && (
								<DropdownItem className={`${styles["dropdown_list"]} p-0`}>
									<Text
										className={styles["dropdown_link"]}
										onClick={() => handleUnArchieveNote(myNote?.id || transferredNote?.id)}
									>
										{t("notes.unarchive")}
									</Text>
								</DropdownItem>
							)}
							{!isArchive && !transferredNote && (
								<DropdownItem className={`${styles["dropdown_list"]} p-0`}>
									<Text
										onClick={() => {
											if (!isArchive) {
												setIsModalOpen(true);
											}
										}}
										className={styles["dropdown_link"]}
									>
										{t("notes.changeReminder")}
									</Text>
								</DropdownItem>
							)}
						</DropdownMenu>
					</Dropdown>
				</div>
				<Text className={styles["patient_list"]}>{noteCardDetail?.title}</Text>
				<div className={!isArchive && !transferredNote ? styles.office_doctor_wrapper : styles.office_doctor_wrapper_new}>
					<div className={styles["office_name_data"]}>
						<Text size="12px" weight="400" color="#6F7788">
							{t("notes.officeName")}
						</Text>
						<Text size="14px" weight="600" color="#102C42" className={styles.break_word}>
							{noteCardDetail.officeName}
						</Text>
					</div>
					<div className={styles["office_name_data"]}>
						<Text size="12px" weight="400" color="#6F7788" className={styles.break_word}>
							{myNote ? t("notes.assignedBy") : t("notes.transferredTo")}
						</Text>
						<Text size="14px" weight="600" color="#102C42" className={styles.break_word}>
							{profile?.id === myNote?.assignerId
								? t("notes.you")
								: noteCardDetail.name}
						</Text>
					</div>
				</div>
				{!transferredNote && <div
					className={`${styles["office_doctor_wrapper"]} + " " + border-none border-0 pb-0 align-items-center`}
				>
					<div className={styles["image_no_reminder"]}>
						<img src={alertNotification} alt="download-icon" />
						{myNote?.isReminderOn && myNote?.reminderDate ? (
							<Text size="12px" weight="400" color="#6F7788">
								{moment(noteCardDetail.reminderDate).format(
									"MMMM D, YYYY h:mm A"
								)}
							</Text>
						) : (
							<span className={styles["notification_text"]}>
								{t("notes.noReminderSet")}
							</span>
						)}
					</div>
					{!isArchive && (
						<Text
							className="link-btn interactive-element"
							onClick={(e) => {
								e.stopPropagation();
								if (!isArchive) {
									setIsModalOpen(true);
								}
							}}
						>
							{t("notes.change")}
						</Text>
					)}
				</div>}
			</Card>
			{
				isModalOpen && (
					<ReminderModel
						isModalOpen={isModalOpen}
						closeModal={closeModal}
						noteId={myNote?.id || transferredNote?.id}
						handleUpdateReminder={handleUpdateReminder}
						reminderDate={noteCardDetail?.reminderDate}
						isReminderOn={myNote?.isReminderOn}
					/>
				)
			}
			{
				isDeleteModelOpen && (
					<DeleteModel
						noteId={myNote?.id || transferredNote?.id}
						updateStateAfterDelete={updateStateAfterDelete}
						isModalOpen={isDeleteModelOpen}
						closeModal={() => setIsDeleteModel(false)}
					/>
				)
			}
			{
				isStaffModalOpen && (
					<div>
						{isStaffLoading && <Loader />}
						<StaffModel
							isModalOpen={isStaffModalOpen}
							closeModal={closeStaffModal}
							handleApply={handleApply}
							handleCancel={handleCancel}
							handleStaffSelection={handleStaffSelection}
							allMembersList={allMembersList}
							selectedStaff={selectedStaff}
						/>
					</div>
				)
			}
			{
				isConfirmModalOpen && (
					<ConfirmationPopup
						isModalOpen={isConfirmModalOpen}
						closeModal={closeAlertModal}
						handleConfirm={handleConfirm}
						handleConfirmCheck={handleConfirmCheck}
						handleCancel={handleCancelConfirmMessage}
					/>

				)
			}
		</Fragment >
	);
};

export default withTranslation()(NotesCard);
