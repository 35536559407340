import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { handleSuccess } from 'utils';
import { uniqBy } from 'lodash';
import { archieveEmploymentProof, deleteEmploymentProof, unArchieveEmploymentProof, useOfficeEmploymentProof } from 'repositories/employment-proof';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const pageSize = 12;

const useEmploymentProofModule = (isArchive, officeId) => {
    const [employments, setEmployment] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isDeleteModalpen, setDeleteModal] = useState(null);
    const location = useLocation();
    
    const isAdmin = !location?.pathname.includes('my-employment-proof');

    const { isLoading, data, error, refetch } = useOfficeEmploymentProof(
        isAdmin,
        pageNumber,
        pageSize,
        officeId,
        isArchive,
    );

    const { mutate: archieveEmploymentMutate, isLoading: archieveLoading } = useMutation((proofId) => archieveEmploymentProof(proofId));

    const { mutate: unArchieveEmploymentMutate, isLoading: unarchiveLoading } = useMutation((proofId) => unArchieveEmploymentProof(proofId));

    const { mutate: deleteEmploymentMutate, isLoading: deleteLoading } = useMutation((proofId) => deleteEmploymentProof(proofId));

    const closeDeleteModal = () => setDeleteModal(null);

    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setEmployment(uniqBy([...data.items], "id"));
                setTotalItems(data.pagination.totalItems);
            }
        }
        else if (!isLoading && data && data.items) {
            setEmployment(prev => uniqBy([...prev, ...data.items], "id"));
            setTotalItems(data.pagination.totalItems);
        }
    }, [isLoading, data]);

    useEffect(() => {
        setPageNumber(1);
        refetch();
    }, [isArchive, refetch])


    const handleArchive = async (proofId) => {
        archieveEmploymentMutate(proofId, {
            onSuccess: (res) => {
                setEmployment((prevProofs) => prevProofs.filter(proof => proof.id !== proofId));
                handleSuccess(res.message);
                refetch();
            }
        })
    };

    const handleUnArchieve = async (proofId) => {
        unArchieveEmploymentMutate(proofId, {
            onSuccess: (res) => {
                setEmployment((prevProofs) => prevProofs.filter(proof => proof.id !== proofId));
                handleSuccess(res.message);
                refetch();
            }
        });
    }



    const handleDeleteProof = (proofId) => {
        deleteEmploymentMutate(proofId, {
            onSuccess: (data) => {
                setEmployment((prevProofs) => prevProofs.filter(proof => proof.id !== proofId));
                handleSuccess(data.message);
                closeDeleteModal();
            },
        });
    };

    const isAnyLoading = isLoading || archieveLoading || unarchiveLoading || deleteLoading;

    return {
        employments,
        pageNumber,
        totalItems,
        isLoading: isAnyLoading,
        error,
        setPageNumber,
        handleArchive,
        handleDeleteProof,
        handleUnArchieve,
        isAdmin,
        isDeleteModalpen,
        closeDeleteModal,
        setDeleteModal
    };
};

export default useEmploymentProofModule;