import React, { Fragment, useState } from "react";
import NotesCard from "../NoteCard";
import styles from "../../Notes.module.scss";
import { withTranslation } from "react-i18next";
import Loader from "components/Loader";
import {
  archieveTransferredNote,
  unArchieveTransferredNote,
  useAssigneeList,
  useTransferredNotes,
} from "repositories/notes-repository";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterNotes from "../Filters";
import SearchBar from "../Filters/SearchBar";
import useNoteModule from "../../hooks/useNoteModule";
import Empty from "components/Empty";
import { cacheSideBarActive } from "utils";

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const isMyNote = false;

const TransferredNotes = ({ t, history, isArchive }) => {
  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };
  const {
    officeFilter,
    staffFilter,
    searchValue,
    isLoading,
    setOfficeFilter,
    setStaffFilter,
    handleApplyFilters,
    handleSearchTerm,
    resetFilter,
    handleArchive,
    totalItems,
    setPageNumber,
    notes,
    isModalOpen,
    closeModal,
    setIsModalOpen,
    updateStateAfterDelete,
    handleUpdateReminder,
    setStatusFilter,
    selectedStatus,
    handleUnArchieveNote,
    isFilterApply,
  } = useNoteModule(
    useTransferredNotes,
    isArchive,
    archieveTransferredNote,
    unArchieveTransferredNote,
    isMyNote
  );

  const statuses = [t("notes.delivered"), t("notes.seen")];

  let items = (
    <div className={styles["notes_card_grid"]}>
      {notes.map((note, index) => (
        <NotesCard
          className={
            isSidebarActive
              ? styles.notes_card_wrapper_active
              : styles.notes_card_wrapper_not_active
          }
          transferredNote={note}
          key={note?.id}
          handleArchive={handleArchive}
          isArchive={isArchive}
          setIsModalOpen={setIsModalOpen}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          updateStateAfterDelete={updateStateAfterDelete}
          handleUpdateReminder={handleUpdateReminder}
          handleUnArchieveNote={handleUnArchieveNote}
          history={history}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      {isLoading && <Loader />}
      <SearchBar
        searchValue={searchValue}
        placeholder={t("notes.searchPlaceHolder")}
        t={t}
        handleSearchTerm={handleSearchTerm}
      />
      <div className="scheduler-tabs-main-wrapper w-100">
        <FilterNotes
          setStaffFilter={setStaffFilter}
          officeFilter={officeFilter}
          setOfficeFilter={setOfficeFilter}
          staffFilter={staffFilter}
          setStatusFilter={setStatusFilter}
          selectedStatus={selectedStatus}
          handleApplyFilters={handleApplyFilters}
          resetFilter={resetFilter}
          isFilterApply={isFilterApply}
          getStaffList={useAssigneeList}
          statuses={statuses}
          isSidebarActive={isSidebarActive}
          handleSidebarToggle={handleSidebarToggle}
        />
        {notes.length === 0 ? (
          <div className={styles["not_found"]}>
            <Empty Message={t("notes.notesNotFound")} />
          </div>
        ) : (
          <motion.div
            variants={animationVariants}
            initial="hidden"
            animate="show"
            className="w-100"
          >
            <InfiniteScroll
              className="w-100"
              dataLength={notes.length}
              hasMore={notes.length < totalItems}
              next={() => setPageNumber((v) => v + 1)}
            >
              {items}
            </InfiniteScroll>
          </motion.div>
        )}
      </div>
    </Fragment>
  );
};
export default withTranslation()(TransferredNotes);
