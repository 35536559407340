import { useState } from "react";
import Card from "components/Card";
import Input from "components/Input";
import styles from "../../../Notes/Notes.module.scss";
import { withTranslation } from "react-i18next";
import DeleteIcon from "../../../../../assets/images/delete-red.svg";
import { cloneDeep } from "lodash";
import useUploadService from "hooks/useUploadService";
import constants from "../../../../../constants";
import { decodeId, getBlobnameFromUrl } from "utils";
import toast from "react-hot-toast";
import Page from "components/Page";
import { useOfficeDetail } from "repositories/office-repository";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "components/Loader";
import Text from "components/Text";
import { addPatientnote } from "repositories/patient-appointment-repository";

const containerName = "patientnotesattachments";

function AddPatienNote({ t, history, location }) {
    const { upload: uploadImage } = useUploadService();
    let { officeId, patientId } = useParams();
    const decodeOfficeId = parseInt(decodeId(officeId));
    const decodePatientId = parseInt(decodeId(patientId));

    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);
    const allowedTypes = constants.chat.allowedTypesForMessage;
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    });
    const [fileName, setFileName] = useState({ path: "", file: null });
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState({});

    const onBack = () => { history.push({pathname: constants.routes.appointment.patientNotes.replace(':officeId',officeId).replace(':patientId',patientId), state: {...location?.state}}) };


    const isValidEvent = () => {
        const errorsData = cloneDeep(errors);
        //Title
        if (!formData.title.trim().length) {
            errorsData.title = t("form.errors.emptyField", {
                field: t("title"),
            });
        } else {
            delete errorsData.title;
        }
        //Description
        if (!formData.description.trim().length) {
            errorsData.description = t("form.errors.emptyField", {
                field: t("description"),
            });
        } else {
            delete errorsData.description;
        }

        setErrors(errorsData);
        return !Object.values(errorsData).some((err) => !!err);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValidEvent()) {
            setShowLoader(true);
            try {
                let imageBlobName;
                if (fileName && fileName.file && fileName.path) {
                    const [err, blobData] = await uploadImage(
                        fileName.file,
                        containerName
                    );

                    let file = blobData.blobUrl;

                    imageBlobName = `${containerName}/${blobData.blobName}`;

                    if (err) {
                        throw new Error(err);
                    }

                    setFileName({
                        path: file,
                        file: null,
                    });
                }
                if (fileName && fileName.path && !fileName.file) {
                    imageBlobName = `${containerName}/${getBlobnameFromUrl(
                        fileName.path,
                        containerName
                    )}`;
                }
                const params = {
                    Title: formData?.title,
                    Description: formData?.description,
                    AttachmentLink: imageBlobName,
                    OfficeId: decodeOfficeId,
                    PatientId: decodePatientId
                };
                let res = await addPatientnote(params);
                toast.success(res.message);
                if (res.status) onBack();
            } catch (err) {
                toast.error(err.message);
            } finally {
                setShowLoader(false);
            }
        }

    }

    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleFileChange = (event) => {
        if (!event || !event.target) return;

        const files = event.target.files;
        if (!files.length) return;

        if (!allowedTypes.includes(files[0]?.type)) {
            toast.error(t("fileNotSupported"));
            return;
        }
        setFileName({
            path: URL.createObjectURL(files[0]),
            file: files[0],
        });
    };

    return (
        <Page onBack={onBack} title={officeDetail?.name} className={styles["global-main-page"]}>
            <Text size="14px" weight="300" color="#000000" className='mb-2'>{t("patients.addNewNote")}</Text>
            {(showLoader || isLoadingOfficeDetail) && <Loader />}
            <form onSubmit={handleSubmit} className="w-100">
                <Card className={styles["add_note_card"]}>
                    <div className={styles["add_note_form_wrapper"]}>
                        <Input
                            Title={t("notes.title")}
                            Type="text"
                            Placeholder="E.g. Routine Visit"
                            Name={"title"}
                            HandleChange={handleOnChange}
                            Error={errors.title}
                            Value={formData?.title}
                            MaxLength="35"
                        />

                        <div className="c-field">
                            <label> {t("notes.noteDescription")}</label>
                            <textarea
                                className={styles["height60"] + " " + "c-form-control"}
                                placeholder="Enter"
                                name="description"
                                maxLength="400"
                                onChange={handleOnChange}
                                value={formData?.description}
                            />
                            {errors.description && (
                                <span className="error-msg">{errors.description}</span>
                            )}
                        </div>
                        <input
                            name="uploadFile"
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <div className={styles["heading-data-Wrapper"]}>
                            <div className={styles["head-link-wrapper"]}>
                                <div className={styles["appointment-head"]}>
                                    {t("notes.attachment")}
                                </div>
                                <label htmlFor="fileInput" className={styles["appointment-link"]}>
                                    {t("notes.uploadFile")}
                                </label>
                            </div>
                            <div className={styles["file_upload_wrapper"]}>
                                <div>
                                    {!fileName?.file?.name ? (
                                        <div className={styles["appointment-text"]}>
                                            {t("notes.noAttachments")}
                                        </div>
                                    ) : (
                                        fileName?.file?.name
                                    )}
                                </div>

                                {fileName?.file?.name ? (
                                    <img
                                        src={DeleteIcon}
                                        alt="DeleteIcon"
                                        className={styles["trash_image"]}
                                        onClick={() => setFileName({ path: "", file: null })}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div
                            className={styles["main_btn_container"] + " " + "btn-box d-md-flex"}
                        >
                            <button
                                className="button button-round button-shadow w-sm-100"
                                title="add"
                                type="submit"
                            >
                                {t("patients.save")}
                            </button>
                            <button
                                className="button button-round button-border btn-mobile-link button-dark"
                                title="cancel"
                                onClick={() => onBack()}
                            >
                                {t("notes.cancel")}
                            </button>
                        </div>
                    </div>
                </Card>
            </form>
        </Page>
    );
}

export default withTranslation()(AddPatienNote)
