import TypeOfAppointment from 'accountOwner/pages/Appointments/components/TypeOfAppointment'
import Page from 'components/Page'
import React from 'react'
import { withTranslation } from 'react-i18next'

function RecallAppointmentType({ history }) {
    return (
        <Page onBack={() => { history.push(`/create-recall-appointment`) }}>
            <TypeOfAppointment />
        </Page>
    )
}
export default withTranslation()(RecallAppointmentType)