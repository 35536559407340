import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../../TaskManagement/TaskManagement.module.scss";
import { useSikkaOperatory } from "repositories/office-repository";
import Loader from "components/Loader";
import Empty from "components/Empty";

export default function OperatoryModal({ t, isDeleteModalOpen, closeDeleteModal, officeId, setselectedOperatoryRadio, selectedOperatoryRadio, handleSelectOperatory }) {
    const { isLoading, data : OperatoryData } = useSikkaOperatory(
        officeId
    );
   console.log(selectedOperatoryRadio)
    return (
        <Modal
            isOpen={isDeleteModalOpen}
            toggle={closeDeleteModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            {isLoading && <Loader />}
            <span className="close-btn" onClick={closeDeleteModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles["operator_radio_btn"] + " " + "ch-radio"}>
                    {OperatoryData?.length ? 
                        OperatoryData?.map(operatory => {
                            return (
                                <label>
                                <input
                                    type="radio"
                                    name="blockTimeslotAallDoctors"
                                    checked={selectedOperatoryRadio?.appointments_operatory === operatory?.appointments_operatory}
                                    onChange={() => setselectedOperatoryRadio(operatory)}
                                />
                                <span> {operatory?.appointments_operatory} </span>
                            </label>
                            )
                        }) : <Empty Message={"Not Availabel"} />
                    }
                </div>
                <div className={styles["common-appointment-btn-container"] + " " + "mt-5"}>
                    <button className='button button-round button-shadow mr-md-4 mb-3 w-sm-100' onClick={handleSelectOperatory}>
                        {t("Select")}
                    </button>
                    <button className='button button-round button-border button-dark btn-mobile-link' onClick={closeDeleteModal}>
                        {t("Cance")}
                    </button>
			</div>
            </ModalBody>
           
        </Modal>
    )
}
