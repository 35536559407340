import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import styles from "../../Notes/Notes.module.scss"
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import crossIcon from "../../../../assets/images/cross.svg"
import Button from "components/Button";

const CancelNextVisitModal = ({ t, isModalOpen, closeModal, confirmAction, appointmentID, recallDate }) => {

  const handleApply = async (AppointmentRecallId, RecallDate) => {
    let res = await confirmAction(AppointmentRecallId, RecallDate);
    if (res?.status) {
      closeModal();
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-lg modal-dialog-centered "
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModal}><img src={crossIcon} alt="close" /></span>
      <ModalBody>
        <div className={styles["delete_task_modal"]}>
          <Text size="25px" weight="500" color="#111B45">{t("patientAppointmentMembers.confirm")}</Text>
          <Text size="16px" weight="300" color="#535B5F" marginTop="11px">{t("patientAppointmentMembers.confirmationNextVisitMsg")}</Text>
          <div className={styles.main_btn_container}>
            <Button title="save" onClick={() => handleApply(appointmentID, recallDate)}>{t('submit')}</Button>
            <Button borderedSecondaryNew title="cancel" onClick={closeModal}>
              <span>{t('patientAppointmentMembers.cancel')}</span>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(CancelNextVisitModal);
