import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styles from "../Appointments.module.scss";
import { Card } from 'reactstrap';
import { useSlot } from 'repositories/patient-appointment-repository';
import Loader from 'components/Loader';
import moment from 'moment'; 

const BookTimeslot = ({ t, officeId, date, doctorId, setSelectedSlots, selectedSlots, errors }) => {
    const { data: timeSlots, isLoading } = useSlot(officeId, date, doctorId);
    const [error, setError] = useState('');

    const isSelected = (slot) => {
        return selectedSlots?.find(selected => selected.startTime === slot.startTime);
    };

    const checkContinuous = (slots) => {
        if (slots.length <= 1) return true;

        for (let i = 1; i < slots.length; i++) {
            const prevSlot = moment(slots[i - 1].startTime, 'hh:mm A');
            const currSlot = moment(slots[i].startTime, 'hh:mm A');
            const diffInMinutes = currSlot.diff(prevSlot, 'minutes');
            if (diffInMinutes !== 15) {
                return false;
            }
        }
        return true;
    };

    const handleSlotClick = (slot) => {
        const isSlotSelected = isSelected(slot);
        if (slot.slotStatus == 3 || slot.slotStatus == 2) {
            return;
        }
        if (isSlotSelected) {
            const updatedSlots = selectedSlots.filter(selected => selected.startTime !== slot.startTime);
            
            if (checkContinuous(updatedSlots)) {
                setSelectedSlots(updatedSlots);
                setError('');
            } else {
                setError(t('waitingList.slotBreak'));
            }
        } else {
            const updatedSlots = [...selectedSlots, slot].sort((a, b) =>
                moment(a.startTime, 'hh:mm A').diff(moment(b.startTime, 'hh:mm A'))
            );
    
            const startSlotTime = moment(updatedSlots[0].startTime, 'hh:mm A');
            const endSlotTime = moment(updatedSlots[updatedSlots.length - 1].startTime, 'hh:mm A');
            const timeRange = endSlotTime.diff(startSlotTime, 'minutes');
    
            if (timeRange > 45) {
                setError(t('waitingList.slotMustBeAnHour'));
                return;
            }
    
            let isContinuous = true;
            for (let i = 1; i < updatedSlots.length; i++) {
                const prevSlotTime = moment(updatedSlots[i - 1].startTime, 'hh:mm A');
                const currSlotTime = moment(updatedSlots[i].startTime, 'hh:mm A');
                const interval = currSlotTime.diff(prevSlotTime, 'minutes');
    
                if (interval !== 15) {
                    isContinuous = false;
                    break;
                }
            }
    
            if (isContinuous) {
                setSelectedSlots(updatedSlots);
                setError('');
            } else {
                setError(t('waitingList.slotContinous'));
            }
        }
    };
    
    

    const getSlotBackgroundColor = (slot) => {
        if (slot.slotStatus === 1) {
            return isSelected(slot) ? 'rgb(88,126,133)' : '#fff'; 
        } else if (slot?.slotStatus === 2) {
            return 'rgb(229,231,223)'; 
        } else if (slot?.slotStatus === 3) {
            return 'rgb(169,207,61)'; 
        }
    };

    useEffect(() => {
        setSelectedSlots([]);
    }, [date])


    return (
        <div className={styles['urban-dental-reassign-card-wrapper'] + " " + "d-flex flex-wrap"}>
            {isLoading && <Loader />}
            {(error || errors?.slots) && <span className="error-msg mt-4">{error || errors?.slots}</span>}
            {timeSlots?.length > 0 && <Card className={styles['urban-dental-reassign-card'] + " " + "mt-3"}>
                <div className={styles['show-time-box']}>
                    {timeSlots?.map((slot, index) =>
                        <Card
                            className={`${styles['common-time-box']} ${isSelected(slot) ? styles['selected-slot'] : ''}`} 
                            key={index}
                            onClick={() => handleSlotClick(slot)} 
                            style={{
                                backgroundColor: getSlotBackgroundColor(slot),
                                color: isSelected(slot) ? '#fff' : slot?.slotStatus !== 3 ? '' : '#fff' , 
                                cursor: slot.slotStatus === 1 ? 'pointer' : 'not-allowed'
                            }}
                        >
                            {slot?.startTime}
                        </Card>
                    )}
                </div>
            </Card>}
        </div>
    );
};

export default withTranslation()(BookTimeslot);
