import React from 'react'
import { motion } from "framer-motion";
import constants from "../../../../constants"
import { Link } from "react-router-dom";
import styles from "../AppointmentDashboard.module.scss"
import Text from 'components/Text';

const DashboardCard = ({ onclick, icon, title, numberOfAppointments }) => {
    return (
        <div onClick={onclick} className={styles.link_container}>
            <motion.div
                whileHover={{ scale: constants.animation.hoverScale }}
                whileTap={{ scale: constants.animation.hoverScale }}
                className='h-100'
            >
                <div className={styles.card_container} >
                    <div className={styles.title_number_wrapper}>
                        <Text size="12px" weight="400" color="#79869A">{title}</Text>
                        <Text size="25px" weight="600" color="#111B45">{numberOfAppointments}</Text>
                    </div>
                    <div>
                        <img className={styles.icon} src={icon} alt="icon" />
                    </div>
                </div>
            </motion.div>
        </div>
    )
}
export default DashboardCard;