import React from "react";
import { withTranslation } from "react-i18next";
import InfoIcon from "../../../../assets/images/info_black-tooltip.svg";

function NewConversationToast({ text }) {
  return (
    <div className="edit-profile-warning-container container mb-4 pl-0 pr-0">
      <div className="edit-profile-warning-bg ">
        <img
          className="edit-profile-warning-icon"
          src={InfoIcon}
          alt="warning icon"
        />
        <div className="edit-profile-warning-text">
          {text}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(NewConversationToast);
