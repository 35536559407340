import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Table from "components/table";
import constants from "./../../../../constants";
import "../Appointments.scss";
import styles from "../Appointments.module.scss";
import { formatUTCDateTime } from "utils";
import useCommonModule from "../hooks/useCommonModule";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import RejectionModal from "../modal/RejectionModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import Empty from "components/Empty";
import SearchIcon from "../../../../assets/images/search.svg";
import AlertRed from "../../../../assets/images/alert_red.svg";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const PAGE_SIZE = constants.pageSize;

const AppointmentsTable = ({
  t,
  appointments,
  totalItems,
  pageNumber,
  setPageNumber,
  appointmentDetailAction,
  showLoader,
  searchValue,
  handleInputChange,
  state,
  isFrompatientDetail,
  manageTab,
}) => {
  const history = useHistory();
  const {
    data: { isRejectModal, appointmentDetail, isConfirmModal, isCancelModal },
    methods: {
      getStatus,
      getStatusClass,
      getMenuoptions,
      handleRejectToggle,
      handleConfirmToggle,
      handleCancelToggle,
      handleRedirectPatientAppointDetail,
      getPatientMenuoptions,
    },
  } = useCommonModule(history, t, state, isFrompatientDetail);
  let location = useLocation();
  const handleApply = async () => {
    const params = {
      appointmentId: appointmentDetail?.id,
      status: 7,
      message: "",
    };

    let res = await appointmentDetailAction(params);
    if (res?.status) {
      handleConfirmToggle();
    }
  };

  const columns = [
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.patientName"
        ),
      },
      dataField: "patientName",
      text: t("patientAppointmentMembers.appointmentTableHeading.patientName"),
      formatter: (cellContent, row) => (
        <div
          className={styles["appointment-table-row-main-link"]}
          onClick={(e) => handleRedirectPatientAppointDetail(e, row)}
        >
          <span
            className={styles["appointment-table-patient-name"]}
          >{`${row?.patient?.firstName} ${row?.patient?.lastName}`}</span>
        </div>
      ),
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.office"
        ),
      },
      dataField: "office",
      text: state?.officeId
        ? "Doctor Name"
        : t("patientAppointmentMembers.appointmentTableHeading.office"),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {state?.officeId
            ? `${row?.doctor?.firstName} ${row?.doctor?.lastName}`
            : row?.office?.name}
        </span>
      ),
      sort: true,
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentDate"
        ),
      },
      dataField: "appointmentDate",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentDate"
      ),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {formatUTCDateTime(row?.appointmentDate)}
        </span>
      ),
      sort: true,
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentTime"
        ),
      },
      dataField: "appointmentTime",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentTime"
      ),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.appointmentTime?.length > 1
            ? `${row?.appointmentTime[0]} - ${
                row?.appointmentTime[row?.appointmentTime?.length - 1]
              }`
            : row?.appointmentTime[0]}
        </span>
      ),
      sort: true,
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentType"
        ),
      },
      dataField: "appointmentType",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentType"
      ),
      formatter: (cellContent, row, rowIndex) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.appointmentType?.title}
        </span>
      ),
      sort: true,
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.bookedFor"
        ),
      },
      dataField: "bookedFor",
      text: t("patientAppointmentMembers.appointmentTableHeading.bookedFor"),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {`${row?.bookedByUser?.firstName} ${row?.bookedByUser?.lastName}`}
        </span>
      ),
      sort: true,
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.status"
        ),
      },
      dataField: "status",
      text: t("patientAppointmentMembers.appointmentTableHeading.status"),
      formatter: (cellContent, row) => (
        <div className={styles["table-status-box"]}>
          <Button
            className={`appointment-table-custom-btn ${getStatusClass(
              row.status
            )}`}
          >
            <span>{getStatus(row?.status)}</span>
          </Button>
          {row?.userCovidResponseId ? (
            <span className={styles["red-alert-box"]}>
              <img
                src={AlertRed}
                alt="alert"
                className={styles["red-alert-icon"]}
              />
            </span>
          ) : null}
        </div>
      ),
    },
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.action"
        ),
      },
      dataField: "action",
      text: t("patientAppointmentMembers.appointmentTableHeading.action"),
      formatter: (cellContent, row) => {
        const isCreateTemplate = location?.pathname.includes("patient-detail");
        const menuOptions = isCreateTemplate
          ? getPatientMenuoptions(row)
          : getMenuoptions(row);
        return (
          <UncontrolledDropdown>
            <DropdownToggle caret={false} tag="div" className="cursor-pointer">
              <div className={`ellipsis-icon`}>
                <ThreeDotsVertical />
              </div>
            </DropdownToggle>
            <DropdownMenu left className={styles["office-dropdown-menu"]}>
              {menuOptions.map((option, index) => (
                <DropdownItem
                  key={index}
                  className={styles["office-dropdown-item"]}
                >
                  <p className="m-0" onClick={(e) => option?.onclick(e, row)}>
                    {option?.text}
                  </p>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  return (
    <div className={`${styles["appointment-table-box"]}`}>
      {!isFrompatientDetail && (
        <div
          className={`${styles["appointment-search-box"]} search-box float-right mb-4`}
        >
          <input
            type="text"
            placeholder="Search by Patient Name"
            value={searchValue}
            onChange={handleInputChange}
          />
          <span className="ico">
            <img src={SearchIcon} alt="icon" />
          </span>
        </div>
      )}
      {appointments?.length === 0 ? (
        <div className={styles["not_found"]}>
          <Empty Message={t("waitingList.notFound")} />
        </div>
      ) : (
        <div className="test-table-box">
          <Table
            keyField="id"
            className="change-heading"
            data={appointments}
            columns={columns}
            bordered={false}
            handlePagination={setPageNumber}
            pageNumber={pageNumber}
            totalItems={totalItems}
            pageSize={PAGE_SIZE}
          />
        </div>
      )}
      {isRejectModal && (
        <RejectionModal
          showLoader={showLoader}
          isRejectionModalOpen={isRejectModal}
          setIsRejectionModalOpen={handleRejectToggle}
          confirmReject={appointmentDetailAction}
          isCancel={handleRejectToggle}
          appointmentID={appointmentDetail?.id}
          actionStatus={3}
          title={t("patientAppointmentMembers.reasonOfRejection")}
          manageTab={manageTab}
        />
      )}
      {isCancelModal && (
        <RejectionModal
          showLoader={showLoader}
          isRejectionModalOpen={isCancelModal}
          setIsRejectionModalOpen={handleCancelToggle}
          confirmReject={appointmentDetailAction}
          isCancel={handleCancelToggle}
          appointmentID={appointmentDetail?.id}
          actionStatus={4}
          title={t("patientAppointmentMembers.reasonOfCancellation")}
          manageTab={manageTab}
        />
      )}
      {isConfirmModal && (
        <ConfirmationModal
          closeModal={handleConfirmToggle}
          isModalOpen={isConfirmModal}
          handleApply={handleApply}
          showLoader={showLoader}
          message={t("patientAppointmentMembers.confirmationMsg")}
        />
      )}
    </div>
  );
};

export default withTranslation()(AppointmentsTable);
