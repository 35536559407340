import React, { Fragment, useState, useEffect } from "react";
import notesIcon from "./../../../assets/images/notes.svg";
import Card from "../OfficeOptions/Card";
import Page from "components/Page";
import constants from "./../../../constants";
import styles from "./Contracts/Contracts.module.scss";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId } from "utils";
import { useOfficeById, useOfficeDetail } from "repositories/office-repository";
import useSubscriptionAccess from "hooks/useSubscriptionAccess";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";

function Forms({ t, history, location }) {
  let { officeId } = useParams();
  const modulesAccess = useSelector((prev) => prev);
  const profile = useSelector((state) => state.userProfile.profile);
  const decodeOfficeId = parseInt(decodeId(officeId));
  const { data: officeDetail } = useOfficeDetail(decodeOfficeId);
  const { data: officeData } = useOfficeById(decodeOfficeId);
  const isStaff = profile?.role?.systemRole === constants?.systemRoles?.staff;
  const { redirectWithCheck, isModuleDisabledClass } = useSubscriptionAccess();
  const [selectedPlanFeature, setSelectedPlanFeature] = useState({});

  useEffect(() => {
    if (modulesAccess && isStaff) getFormContractsModuleAccess();
  }, [modulesAccess]);

  const getFormContractsModuleAccess = () => {
    try {
      const subscription = modulesAccess?.Subscription;

      if (subscription?.length) {
        const selectedPlanData = subscription?.find(
          (val) => val?.ownerId === officeDetail?.ownerId
        );
        setSelectedPlanFeature(selectedPlanData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    const backToPrevious =
      profile?.role?.systemRole === constants.systemRoles.staff
        ? constants.routes.staff.officeOptions
        : constants.routes.accountOwner.officeOptions;
    history.push({
      pathname: backToPrevious.replace(":officeId", officeId),
      state: {
        officeData,
      },
    });
  };

  const isMyForms = location?.pathname.includes("my-forms");

  const redirectPathToContacts = isMyForms
    ? constants.routes.staff.officeContracts
    : constants.routes.accountOwner.contracts;
  const redirectPathToEmploymentProof = isMyForms
    ? constants.routes.accountOwner.myEmployementProof
    : constants.routes.accountOwner.employementProof;

  return (
    <Fragment>
      <Page onBack={goBack} title={officeDetail?.name}>
        <div className={styles.gridContainer}>
          <div
            className={isModuleDisabledClass(
              constants.moduleNameWithId.formAndContracts,
              isStaff ? selectedPlanFeature : null
            )}
          >
            <Card
              to={redirectPathToContacts.replace(":officeId", officeId)}
              icon={notesIcon}
              title={t("contracts.viewContract")}
              officeName={officeDetail?.name}
              redirectTo={() =>
                redirectWithCheck(
                  {
                    pathname: redirectPathToContacts.replace(
                      ":officeId",
                      officeId
                    ),
                    state: location.state,
                  },
                  !!isModuleDisabledClass(
                    constants.moduleNameWithId.formAndContracts,
                    selectedPlanFeature
                  )
                )
              }
            />
          </div>
          <Card
            to={redirectPathToEmploymentProof.replace(":officeId", officeId)}
            icon={notesIcon}
            title={t("employmentProof.employmentProof")}
            officeName={officeDetail?.name}
          />
        </div>
      </Page>
    </Fragment>
  );
}

export default withTranslation()(Forms);
