import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../Notes.module.scss";
import { withTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { deleteNote, deleteTransferredNote } from "repositories/notes-repository";
import { getStorage, handleSuccess } from "utils";
import constants from "../../../../constants";

const DeleteModel = ({
  t,
  noteId,
  isModalOpen,
  closeModal,
  updateStateAfterDelete,
}) => {
  const cachedActiveTab = getStorage(constants.notes.cache.Noteslisting);
  const deletMethod = cachedActiveTab?.activeTab === 2 ? deleteTransferredNote : deleteNote;

  const { mutate: deleteMutate } = useMutation((id) => deletMethod(id), {
    onSuccess: (data) => {
      if (updateStateAfterDelete) updateStateAfterDelete(noteId);
      handleSuccess(data.message);
      closeModal();
    },
  });

  const handleDelete = () => {
    deleteMutate(noteId);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-dialog-centered modal-lg"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody className={styles["custom_modal_body"]}>
        <h1 className={styles["modal_heading"]}>{t("notes.deleteNote")}</h1>
        <div className={styles.paragraph_wrapper}>
          <p className={styles["modal_data"] + " " + "mb-0"}>
            {t("notes.deleteNodeMessage")}
          </p>
          <p className={styles["modal_data"] + " " + "mb-0"}>
            {t("notes.deleteConfirmationMessage")}
          </p>
        </div>
        <div className={styles.delete_modal_container + " " + "btn-box"}>
          <button
            className={
              styles.green_btn +
              " " +
              "button button-round button-shadow mr-md-4 mb-3 w-sm-100"
            }
            title="save"
            onClick={handleDelete}
          >
            {t("notes.yesDelete")}
          </button>
          <button
            className={
              styles.white_btn +
              " " +
              "mb-md-3 button button-round button-border btn-mobile-link button-dark"
            }
            title="cancel"
            onClick={closeModal}
          >
            {t("notes.cancel")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(DeleteModel);
