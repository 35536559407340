
import { useEffect, useState } from "react";
import {  getStorage } from "utils";
import constants from "../../../../constants";
import moment from "moment";
import { useAllTasksPerformance } from "repositories/task-management-repository";
import { uniqBy } from "lodash";
import { useQueryClient } from "react-query";

const defaultDate = {
    from: moment().startOf('year').toDate(),
    to: moment().toDate()
  }

const defaultUtcDate = {
    from: moment().startOf('year').utc().format('YYYY-MM-DD HH:mm:ss.SSS'),
    to: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
  }

const pageSize = 10
const useTaskPerformanceController = () => {
    const cachedSelectedOwnerTab = getStorage(constants.tasks.cache.accountownercheck);
    const [dates, setdates] = useState(defaultDate);
    const [utcdates, setUtcdates] = useState(defaultUtcDate);
    const [pageNumber, setPageNumber] = useState(1);
    const [performaceList, setPerformaceList] = useState([]);
    const [officeFilter ,setOfficeFitler] = useState(null);
    const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const queryClient = useQueryClient();
    let accountOwnerId = cachedSelectedOwnerTab?.owner?.id || null;
    const { isLoading, data, refetch } = useAllTasksPerformance(
        pageNumber,
        pageSize,
        accountOwnerId,
        decodeURIComponent(utcdates.from),
        decodeURIComponent(utcdates.to),
        appliedOfficeFilters
      );

      useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setPerformaceList(uniqBy([...data?.items]));
                setTotalItems(data?.pagination?.totalItems);
            }
        }

        else if (!isLoading && data && data.items) {
            setPerformaceList(prev => uniqBy([...prev, ...data?.items]));
            setTotalItems(data?.pagination?.totalItems);
        }

    }, [isLoading, data]);

    useEffect(() => {
        setPageNumber(1);
        queryClient.invalidateQueries(["/tasks/Performance/List", pageNumber, pageSize, accountOwnerId, utcdates.from, utcdates.to]);
        refetch();
    }, [dates])

    const updateDatesValues = (e, type) => {
        setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }))
        setUtcdates(prevState => ({
            ...prevState,
            from: type === 'from'
                ? moment(e).utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                : prevState.from,
                to: type === 'to'
                ? moment(e).utc().format('YYYY-MM-DD HH:mm:ss.SSS')
                : prevState.to,
        }));
    }

    const handleApply = () => {
        setPageNumber(1);
        setAppliedOfficeFilters(officeFilter?.id);
        queryClient.invalidateQueries(["/tasks/Performance/List", pageNumber, pageSize, accountOwnerId, utcdates.from, utcdates.to]);
        refetch();
    };

    const resetFilter = () => {
        setAppliedOfficeFilters(null);
        setOfficeFitler(null);
        setPageNumber(1);
        setdates(defaultDate);
        setUtcdates(defaultUtcDate);
        queryClient.invalidateQueries(["/tasks/Performance/List", pageNumber, pageSize, accountOwnerId, utcdates.from, utcdates.to]);
        refetch();
    };
    const performanceLoader = isLoading

    return {
        data: {
            performanceLoader,
            dates,
            performaceList,
            totalItems,
            officeFilter,
            accountOwnerId
        },
        methods: {
            updateDatesValues,
            setPageNumber,
            setOfficeFitler,
            handleApply,
            resetFilter
        }
    }
}

export default useTaskPerformanceController;
