import React from "react";
import { Modal, ModalBody } from "reactstrap";
import crossIcon from "../../../../../assets/images/cross.svg";
import AddNewNote from "accountOwner/pages/TaskManagement/AddNewNote/AddNewNote";

export default function AddNewTaskModal({ isModalOpen, closeModalHandler }) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModalHandler}
      className="modal-lg modal-dialog-centered"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModalHandler}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <h1 className="title">ndnd</h1>
        <AddNewNote isInsideModal={true} />
      </ModalBody>
    </Modal>
  );
}
