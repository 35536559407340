import React, { Fragment } from "react";
import styles from "../../Contracts/Contracts.module.scss";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";
import Empty from "components/Empty";
import EmploymentProofCard from "./EmploymentProofCard";
import useEmploymentProofModule from "../hooks/useEmploymentProofModule";
import DeleteModal from "../modals/DeleteModal";

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};


const EmploymentProofModule = ({ t, isArchive, officeId }) => {
    const {
        employments,
        totalItems,
        isLoading,
        handleArchive,
        handleDeleteProof,
        handleUnArchieve,
        setPageNumber,
        isAdmin,
        isDeleteModalpen,
        closeDeleteModal,
        setDeleteModal
    } = useEmploymentProofModule(isArchive, officeId);


    let items = employments.length > 0 && <div className={styles.gridContainer}>
        {employments.map((proof) => (
            <EmploymentProofCard
                key={proof?.id}
                t={t}
                employmentProof={proof}
                isArchive={isArchive}
                handleArchive={handleArchive}
                handleUnArchieve={handleUnArchieve}
                handleDeleteProof={handleDeleteProof}
                officeId={officeId}
                isAdmin={isAdmin}
                setDeleteModal={setDeleteModal}
            />
        ))}
    </div>;

    return (
        <Fragment>
            {isLoading && <Loader />}
            <div className="scheduler-tabs-main-wrapper w-100 justify-content-center">
                {employments.length === 0 ? <div className={styles["not_found"]}>
                    <Empty Message={t('employmentProof.notFound')} />
                </div>
                    :
                    <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                        <InfiniteScroll
                            className="w-100"
                            dataLength={employments.length}
                            hasMore={employments.length < totalItems}
                            next={() => setPageNumber((v) => v + 1)}
                        >
                            {items}
                        </InfiniteScroll>
                    </motion.div>
                }
            </div>
            {isDeleteModalpen &&
                <DeleteModal t={t} isDeleteModalpen={isDeleteModalpen} closeDeleteModal={closeDeleteModal} handleDelete={() => handleDeleteProof(isDeleteModalpen)} loading={isLoading} />
            }
        </Fragment>
    );
};

export default withTranslation()(EmploymentProofModule);
