import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Text from "components/Text";
import styles from "../Dashboard.module.scss";
import OfficeFilter from "./OfficeFilter";
import StaffFilter from "./StaffFilter";
import ToggleSwitch from "components/ToggleSwitch";
import moment from "moment";
import SchedulerCalendar from "accountOwner/pages/Scheduler/components/SchedulerCalendar";
import AgendaFilter from "accountOwner/pages/Scheduler/components/SchedulerFilters/AgendaFilter";
import "./../Chat/DashboardChat.scss";

const DashboardFilter = ({
  t,
  officeFilter,
  setOfficeFilter,
  staffFilter,
  setStaffFilter,
  handleApplyFilter,
  resetFilter,
  setSelectedRoles,
  selectedRoles,
  ownerId,
  ownerData,
  isFilterApply,
  currentDate,
  onDateChange,
  onActiveStartDateChange,
  agendaTypes,
  viewSettig,
  setAgendaTypes,
  setViewSetting,
  isSidebarActive,
  handleSidebarToggle,
  setIsAdminData,
  isSchedulerOnly,
  handleToggleScheduler = { handleToggleScheduler },
}) => {
  const [expandedItems, setExpandedItems] = useState(["a"]);

  const handleApply = () => {
    handleApplyFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleReset = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const [selectedDates, setSelectedDates] = useState([]);
  const [eventDetails, setEventDetails] = useState(null);

  const onChange = (date) => {
    const clickedDate = moment(date).format("YYYY-MM-DD");

    setSelectedDates((prevDates) => {
      const isDateSelected = prevDates.includes(clickedDate);

      return isDateSelected
        ? prevDates.filter((d) => d !== clickedDate)
        : [...prevDates, clickedDate];
    });
  };
  const getTileClassName = ({ date, view }) => {
    if (view !== "month") return "";

    const dateStr = moment(date).format("YYYY-MM-DD");
    const isSelected = selectedDates.includes(dateStr);

    return `${styles.calendar_tile} ${isSelected ? styles.selected : ""}`;
  };

  const FilterEventsData = [
    { title: t("accountOwner.createdByYou"), type: 0 }, // here 0 is not a type taking to check user filter by there id
    { title: t("accountOwner.createdByOthers"), type: -1 }, // here -1 is not a type taking to check user filter by there id
    { title: t("accountOwner.eventsNotAccepted"), type: 4 },
    { title: t("accountOwner.busy"), type: 3 },
    // { title: t('accountOwner.nonAvailableDays'), type: -2 }, // here -2 is not a type taking to check user filter by there id
    { title: t("accountOwner.onLeave"), type: 2 },
    { title: t("scheduler.agenda.appointmentTitle"), type: 1 },
  ];

  const ViewSettingFilter = [
    { title: "Title", type: "title" },
    { title: "Clinic Name", type: "officeName" },
    { title: "Date and Time", type: "timeString" },
  ];

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={resetFilter}
    >
      <div className={styles.dashboard_calendar}>
        <SchedulerCalendar
          value={currentDate}
          onChange={onDateChange}
          onActiveStartDateChange={onActiveStartDateChange}
        />
      </div>

      <span className={styles.notes_filter}>
        {t("notes.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>

      <div className={styles.toggle_btn_wrapper + " " + "toggle_btn_wrapper"}>
        <Text color="#2D3245" weight="500" size="14px">
          {t("dashBoard.allModules")}
        </Text>
        <ToggleSwitch
          label="bill-me-later-toggle-dashboard"
          value={isSchedulerOnly}
          onChange={handleToggleScheduler}
        />
        <Text color="#2D3245" weight="500" size="14px">
          {t("dashBoard.schedulerOnly")}
        </Text>
      </div>
      <Accordion
        preExpanded={expandedItems}
        className="filter-accordion"
        allowZeroExpanded
        allowMultipleExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>
              {t("taskManagement.offices")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <OfficeFilter
              selectedOffice={officeFilter}
              setOfficeFilter={setOfficeFilter}
              setStaffFilter={setStaffFilter}
              setExpandedItems={setExpandedItems}
              ownerId={ownerId}
              setIsAdminData={setIsAdminData}
            />
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="c">
          <AccordionItemHeading>
            <AccordionItemButton>
              {t("taskManagement.staffMembers")}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StaffFilter
              selectedOffice={staffFilter}
              setOfficeFilter={setStaffFilter}
              officeFilter={officeFilter}
              selectedRoles={selectedRoles}
              ownerId={ownerId}
              ownerData={ownerData}
              setSelectedRoles={setSelectedRoles}
            />
          </AccordionItemPanel>
        </AccordionItem>
        {isSchedulerOnly && (
          <AccordionItem uuid="d">
            <AccordionItemHeading>
              <AccordionItemButton>{t("Events fitler")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <AgendaFilter
                setAgendaTypes={setAgendaTypes}
                agendaTypes={agendaTypes}
                FilterEventsData={FilterEventsData}
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {isSchedulerOnly && (
          <AccordionItem uuid="e">
            <AccordionItemHeading>
              <AccordionItemButton>{t("Display Settings")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <AgendaFilter
                setAgendaTypes={setViewSetting}
                agendaTypes={viewSettig}
                FilterEventsData={ViewSettingFilter}
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className={"button button-round button-shadow mr-3"}
          title="apply"
          onClick={handleApply}
        >
          {t("taskManagement.apply")}
        </button>
        <button
          className={"button button-round button-border button-dark reset-btn"}
          title="reset"
          onClick={handleReset}
        >
          {t("taskManagement.reset")}
        </button>
        <button
          className={"button button-round button-border button-dark cancel-btn"}
          title="close"
          onClick={handleSidebarToggle}
        >
          {t("taskManagement.close")}
        </button>
      </div>
    </StickySidebar>
  );
};

export default withTranslation()(DashboardFilter);
