import axios from "axios";
import { useQuery } from "react-query";
import { Config } from "../config";

const noticesBaseUrl = Config.serviceUrls.noticesBaseUrl;

export async function getAllNotices(pageNumber, pageSize, officeId ,isMynotices) {
    const url = isMynotices? `${noticesBaseUrl}/Notice` : `${noticesBaseUrl}/Notice/Office`;

    const response = await axios.get(`${url}?pageNumber=${pageNumber}&pageSize=${pageSize}&officeId=${officeId}`);
   
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}


export function useAllNotices(pageNumber, pageSize, officeId, isMynotices) {
    return useQuery(
        ["/notices/accountowner", pageNumber, pageSize, officeId, isMynotices],
        () => getAllNotices(pageNumber, pageSize, officeId, isMynotices)
    );
}

export async function getSingleNotices(noticeId) {
    const response = await axios.get(`${noticesBaseUrl}/Notice/${noticeId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data
}


export function useSingleNotices(noticeId) {
    return useQuery(
        ["/singleNotice/accountowner", noticeId],
        () => getSingleNotices(noticeId)
    );
}

export const addNewNotice = async (params) => {
    const response = await axios.post(
        `${noticesBaseUrl}/Notice`,
        params
    );
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

