import React from "react";
import styles from "../Dashboard.module.scss";
import InvitationIcon from "../../../../assets/images/invitation.svg";
import RejectIcon from "../../../../assets/images/decline.svg";
import AcceptIcon from "../../../../assets/images/heavy_check_new.svg";
import Separator from "../../../../assets/images/Separator.svg";
import { withTranslation } from "react-i18next";

const RequestInvitationCard = ({ t, invitation, handleInvitationActions }) => {
  return (
    <div className={styles.request_approval_card}>
      <div className={styles.invitation_image_text}>
        <img src={InvitationIcon} alt="invitation icon" />
        <p className={styles.invitation_text}>
          {`${invitation?.officeName} has invited you to join as a ${invitation?.staffRoleId === 1 ? "temporary" : "Permanent"} member.`}
        </p>
      </div>
      <div className={styles.action_buttons}>
        <button className={styles.accept_reject_button} onClick={() => handleInvitationActions(invitation?.inviteId, false)}>
          <img src={RejectIcon} alt="reject icon" />
          <p className={styles.btn_text}>
            {t("patientAppointmentMembers.reject")}
          </p>
        </button>
        <div>
          <img src={Separator} alt="seprator" />
        </div>
        <button className={styles.accept_reject_button} onClick={() => handleInvitationActions(invitation?.inviteId, true)}>
          <img src={AcceptIcon} alt="accept icon" />
          <p className={styles.btn_text}>
            {t("patientAppointmentMembers.accept")}
          </p>
        </button>
      </div>
    </div>
  );
};
export default withTranslation()(RequestInvitationCard);
