import React, { Suspense } from "react";
import Card from "components/Card";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Loader from "components/Loader";
import styles from "../../Notes/Notes.module.scss";
import { withTranslation } from "react-i18next";
import { taskTabStatus } from "../../../../constants";
import StaffTasks from '../StaffTask';
import { useSelector } from "react-redux";
import MyTasks from "../MyTasks";

const AdminTask = ({
    t,
    activeTab,
    manageTab,
    tasks,
    searchValue,
    handleSearchTerm,
    officeFilter,
    handleApplyFilters,
    setOfficeFilter,
    staffFilter,
    setStaffFilter,
    resetFilter,
    statusFilter,
    priorityFilter,
    setStatusFilter,
    setPriorityFilter,
    isLoading,
    totalItems,
    setPageNumber,
    handleAction,
    deleteAction,
    isArchive,
    isFilterApply,
    accountOwnerId,
    sortBy,
    setSortBy,
    priority,
    setPriority,
    status,
    setStatus,
    handleArchiveApi
}) => {
    const profile = useSelector((state) => state.userProfile.profile);
    const tabName = taskTabStatus;

    return (
        <Card className={styles["notes_main_card"]}>
            {profile?.isAdmin ? (<div className="common-tabs scheduler-tabs">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab == tabName.assigntoMe ? "active" : ""}
                            onClick={() => manageTab(tabName.assigntoMe)}
                        >
                            {t("taskManagement.assignedToYou")}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab == tabName.assignedByMe ? "active" : ""}
                            onClick={() => manageTab(tabName.assignedByMe)}
                        >{t("taskManagement.assignedByYou")}</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <Suspense fallback={<Loader />}>
                        <TabPane tabId={1}>
                            {activeTab === tabName.assigntoMe && (
                                <StaffTasks
                                    isArchive={isArchive}
                                    isStaff={true}
                                    accountOwnerId={accountOwnerId}
                                />
                            )}
                        </TabPane>
                        <TabPane tabId={2}>
                            {activeTab === tabName.assignedByMe && (
                                <MyTasks
                                    tasks={tasks}
                                    searchValue={searchValue}
                                    handleSearchTerm={handleSearchTerm}
                                    officeFilter={officeFilter}
                                    setOfficeFilter={setOfficeFilter}
                                    handleApplyFilters={handleApplyFilters}
                                    staffFilter={staffFilter}
                                    setStaffFilter={setStaffFilter}
                                    resetFilter={resetFilter}
                                    statusFilter={statusFilter}
                                    priorityFilter={priorityFilter}
                                    setStatusFilter={setStatusFilter}
                                    setPriorityFilter={setPriorityFilter}
                                    isLoading={isLoading}
                                    totalItems={totalItems}
                                    setPageNumber={setPageNumber}
                                    handleAction={handleAction}
                                    deleteAction={deleteAction}
                                    isArchive={isArchive}
                                    isFilterApply={isFilterApply}
                                    accountOwnerId= {accountOwnerId}
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    priority ={priority}
                                    setPriority ={setPriority}
                                    status={status}
                                    setStatus ={setStatus}
                                    handleArchiveApi={handleArchiveApi}
                                />
                            )}
                        </TabPane>
                    </Suspense>
                </TabContent>
            </div>) : <StaffTasks
                isArchive={isArchive}
                isStaff={true}
                accountOwnerId={accountOwnerId}
            />
            }
        </Card>
    );
};
export default withTranslation()(AdminTask);
