import React, { useEffect } from "react";
import styles from "../Dashboard.module.scss";
import Text from "components/Text";
import Button from "components/Button";
import useRequestAcceptModule from "../hooks/RequestAcceptModule";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import constants from "../../../../constants";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { convertTimeMinuteToHour, encodeId } from "utils";
import EditIcon from "../../../../assets/images/edit-icon.svg";
import clsx from "clsx"; // If using clsx for better className handling

function RequestApprovalCard({ t, list, handleAction }) {
  const history = useHistory();
  const leaveStatus = constants.LeaveStatus;
  const timesheetListingStatus = constants.TimesheetListingStatus;

  const startTime = moment(list?.timesheet_StartTime, "h:mm A");
  const endTime = moment(list?.timesheet_EndTime, "h:mm A");
  let duration = moment.duration(moment(endTime).diff(moment(startTime)));

  // Subtract the break duration from the total duration
  const totalDurationWithoutBreak = duration.subtract(
    list?.timesheet_BreakTime,
    "minutes"
  );
  let totalHours = totalDurationWithoutBreak.asHours().toFixed(2);

  const appliedForDate = (startDate, endDate) => {
    let appliedDate = `${moment(startDate).format("MMM D")} - ${moment(
      endDate
    ).format("ll")}`;
    return appliedDate;
  };

  const handlePendingRequestAction = (
    actionType,
    actionTypeId,
    statusId,
    isApproved
  ) => {
    handleAction(actionType, actionTypeId, statusId, isApproved);
  };

  const handleLeaveStatus = (statusId) => (
    <>
      {statusId === leaveStatus[1].value && <span>{t("pending")}</span>}
      {statusId === leaveStatus[2].value && (
        <span>{t("staffTimesheet.approved")}</span>
      )}
      {statusId === leaveStatus[3].value && <span>{t("rejected")}</span>}
    </>
  );

  return (
    <div className={styles.request_approval_body}>
      <div className={styles.name_btn_wrapper}>
        <Text size="14px" weight="600" color="#587E85">
          {list?.timesheetId > 0 && <span> {list?.timesheet_Name}</span>}
          {list?.leave_Id > 0 && <span> {list?.leave_Name}</span>}
          {list?.clockInOutTimeSheetEditRequestId > 0 && (
            <span> {list?.editRequest_Name}</span>
          )}
        </Text>

        <button
          className={clsx(
            list?.leave_Id && styles.leave_btn_new,
            list?.timesheetId && styles.timesheet_btn_new,
            list?.clockInOutTimeSheetEditRequestId &&
              styles.edit_timesheet_btn_new
          )}
        >
          {list?.timesheetId > 0 && (
            <span className={styles["approval-status"]}>
              {t("contracts.timesheet")}
            </span>
          )}
          {list?.leave_Id > 0 && (
            <span className={clsx(styles["approval-status"], styles["leave"])}>
              {t("staffTimesheet.leave")}
            </span>
          )}
          {list?.clockInOutTimeSheetEditRequestId > 0 && (
            <span className={styles["approval-status"]}>
              {t("staffTimesheet.editTimesheet")}
            </span>
          )}
        </button>
      </div>
      <div className={styles.content_column_grid}>
        {/* For Pendig timesheets */}
        {list?.timesheetId > 0 && (
          <>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.date")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheet_Date
                  ? moment(list.timesheet_Date).format("ll")
                  : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.started")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheet_StartTime ? list.timesheet_StartTime : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.finished")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheet_EndTime ? list.timesheet_EndTime : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.break")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheet_BreakTime
                  ? `${convertTimeMinuteToHour(list.timesheet_BreakTime)} Hrs`
                  : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.total")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {totalHours ? `${totalHours} Hrs` : "--"}
              </Text>
            </div>
          </>
        )}
        {list?.leave_Id > 0 && (
          <>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.appliedFor")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {appliedForDate(list.leave_FromDate, list.leave_ToDate)}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.duration")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list?.leave_Duration ? `${list.leave_Duration} Days` : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.typeOfLeave")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.leave_Type}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.status")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list?.leave_Status
                  ? handleLeaveStatus(list?.leave_Status)
                  : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffLeaves.reasonForLeave")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list?.leave_Description ? list?.leave_Description : "--"}
              </Text>
            </div>
          </>
        )}
        {list?.clockInOutTimeSheetEditRequestId > 0 && (
          <>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.date")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheetRequest_Date
                  ? moment(list.timesheetRequest_Date).format("ll")
                  : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.started")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheet_StartTime ? list.timesheet_StartTime : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" weight="400" color="#6f7788">
                {t("staffTimesheet.finished")}
              </Text>
              <Text size="14px" weight="600" color="#102c42">
                {list.timesheet_EndTime ? list.timesheet_EndTime : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
                {t("staffTimesheet.break")}
              </Text>
              <Text
                size="14px"
                marginBottom="25px"
                weight="600"
                color="#102c42"
              >
                {list.timesheet_BreakTime
                  ? `${convertTimeMinuteToHour(list.timesheet_BreakTime)} Hrs`
                  : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
                {t("staffTimesheet.total")}
              </Text>
              <Text
                size="14px"
                marginBottom="25px"
                weight="600"
                color="#102c42"
              >
                {list.timesheet_EndTime &&
                list.timesheet_StartTime &&
                totalHours
                  ? `${totalHours} Hrs`
                  : "--"}
              </Text>
            </div>
            <div>
              <Text size="12px" marginBottom="5px" weight="400" color="#6f7788">
                {t("staffTimesheet.reasonForEditTimesheet")}
              </Text>
              <Text
                size="14px"
                marginBottom="25px"
                weight="600"
                color="#102c42"
              >
                {list?.reason ? list?.reason : "--"}
              </Text>
            </div>
          </>
        )}
      </div>
      <div className={styles.btn_container_edit}>
        <div className={styles.inner_btn_container}>
          <Button
            height="35px"
            padding="11px"
            onClick={() => {
              if (list?.timesheetId > 0) {
                handlePendingRequestAction(
                  "Timesheet",
                  list.timesheetId,
                  timesheetListingStatus[2].value
                ); //actionType, actionTypeId, statusId, isApproved
              }
              if (list?.leave_Id > 0) {
                handlePendingRequestAction("Leave", list.leave_Id, null, true); //actionType, actionTypeId, statusId, isApproved
              }
              if (list?.clockInOutTimeSheetEditRequestId > 0) {
                handlePendingRequestAction(
                  "EditTimesheetRequest",
                  list.clockInOutTimeSheetEditRequestId,
                  null,
                  true
                ); //actionType, actionTypeId, statusId, isApproved
              }
            }}
          >
            Accept
          </Button>

          <Button
            borderedSecondary
            height="35px"
            bgColor="#F9FCEE"
            padding="11px"
            onClick={() => {
              if (list?.timesheetId > 0) {
                handlePendingRequestAction(
                  "Timesheet",
                  list.timesheetId,
                  timesheetListingStatus[3].value
                );
              }
              if (list?.leave_Id > 0) {
                handlePendingRequestAction("Leave", list.leave_Id, null, false);
              }
              if (list?.clockInOutTimeSheetEditRequestId > 0) {
                handlePendingRequestAction(
                  "EditTimesheetRequest",
                  list.clockInOutTimeSheetEditRequestId,
                  null,
                  false
                );
              }
            }}
          >
            Decline
          </Button>
        </div>
        {(list?.leave_Status !== 1 && list?.timesheet_Date) && (
          <button
            className={styles.edit_icon}
            onClick={() => {
              list["timesheetDate"] = list?.timesheet_Date;
              history.push({
                pathname: constants.routes.accountOwner.editTimesheetForStaff
                  .replace(":officeId", encodeId(list?.officeId))
                  .replace(":staffId", encodeId(list?.userId)),
                state: {
                  detail: list,
                  staffDetail: {
                    name:
                      list?.timesheetId > 0
                        ? list?.timesheet_Name
                        : list?.editRequest_Name,
                  },
                },
              });
            }}
          >
            <img src={EditIcon} alt="EditIcon" />
          </button>
        )}
      </div>
    </div>
  );
}

export default withTranslation()(RequestApprovalCard);
