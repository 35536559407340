import React, { Fragment, useState } from "react";
import Card from "components/Card";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Text from "components/Text";
import constants, {
  PERFORMANCE_REVIEW_FORM_TYPES,
  PERFORMANCE_REVIEW_STATUS,
  ReviewStatus,
} from "../../../../constants";
import styles from "../PerformanceReview.module.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal";
import CancelConfirmationModal from "../modals/CancelConfirmationModal";
import { encodeId, getStorage } from "utils";
import { useHistory } from "react-router-dom";

export default function PerformanceReviewCard({
  t,
  review,
  className,
  isArchive,
  handleArchive,
  handleUnArchieve,
  handleDelete,
  isLoading,
  handleCancel,
  isMyPerformanceReview,
  officeId,
  handleFinalize,
  officeData,
}) {
  const history = useHistory();

  const profile = useSelector((state) => state.userProfile.profile);
  const cachedActiveTab = getStorage(
    constants.performanceReview.cache.performanceReviewlisting
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [isDeleteModalOpen, setIsDeleteModel] = useState(false);
  const [isCancelModalOpen, setIsCancelModel] = useState(false);

  const getButtonClass = (performaceStatus) => {
    const buttonClassMap = {
      submitted: styles.submitted,
      completed: styles.completed,
      finalized: styles.finalized,
      general: styles.general,
      cancelled: styles.cancelled,
    };
    return `${styles.notes_btn} ${buttonClassMap[performaceStatus] || ""}`;
  };

  function capitalizeAllLetters(string) {
    return string.toUpperCase();
  }

  const cardStatus = PERFORMANCE_REVIEW_STATUS.find(
    (status) => status.id === review?.status
  );
  const cardType = PERFORMANCE_REVIEW_FORM_TYPES[review?.type];
  const cardName = isMyPerformanceReview
    ? `${review?.initiatorUser?.firstName} ${review?.initiatorUser?.lastName}`
    : `${review?.respondentUser?.firstName} ${review?.respondentUser?.lastName}`;

  const redirect = (giveFeedback) =>
    history.push({
      pathname: constants.routes.accountOwner.performanceReviewForm.replace(
        ":officeId",
        encodeId(officeId)
      ),
      state: {
        review,
        isMyPerformanceReview,
        isGiveFeedback: giveFeedback,
        officeData,
      },
    });
  const isRedirect =
    cardStatus?.id === ReviewStatus?.submitted ||
    cardStatus?.id === ReviewStatus?.finalized ||
    cardStatus?.id === ReviewStatus?.completed;

  const handleRedirect = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    if (
      !isArchive &&
      (isRedirect ||
        (isMyPerformanceReview && cardStatus?.id === ReviewStatus?.pending))
    ) {
      history.push({
        pathname: constants.routes.accountOwner.performanceReviewForm.replace(
          ":officeId",
          encodeId(officeId)
        ),
        state: {
          review,
          isMyPerformanceReview,
          isGiveFeedback: false,
          officeData,
        },
      });
    }
  };

  return (
    <Fragment>
      <Card className={`${className}`} onClick={handleRedirect}>
        <div className={styles.btn_date_dropdown_wrapper}>
          <div className={styles.btn_date_wrapper}>
            <button className={getButtonClass(cardStatus?.title)}>
              {capitalizeAllLetters(
                t(`performanceReview.${cardStatus?.title}`)
              )}
            </button>
            <button className={`${styles.notes_btn} ${styles.general}`}>
              {capitalizeAllLetters(t(`performanceReview.${cardType}`))}
            </button>
          </div>
          {(!cachedActiveTab ||
            cachedActiveTab?.activeTab === 1 ||
            isMyPerformanceReview) && (
            <Dropdown
              className="interactive-element"
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
            >
              <DropdownToggle
                caret={false}
                tag="div"
                className="dropdown-btn px-3"
              >
                <img
                  src={require("assets/images/dots-icon.svg").default}
                  alt="icon"
                />
              </DropdownToggle>
              <DropdownMenu right className={styles.custom_dropdown_wrapper}>
                {!isMyPerformanceReview &&
                  cardStatus?.id === ReviewStatus?.pending &&
                  !isArchive && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => setIsCancelModel(true)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.cancelReview")}
                      </p>
                    </DropdownItem>
                  )}
                {isMyPerformanceReview &&
                  cardStatus?.id === ReviewStatus?.pending &&
                  !isArchive && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => redirect(false)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.fillPerformanceReview")}
                      </p>
                    </DropdownItem>
                  )}
                {cardStatus?.id === ReviewStatus?.submitted &&
                  !isArchive &&
                  isMyPerformanceReview && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => redirect(false)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.viewPerformanceReview")}
                      </p>
                    </DropdownItem>
                  )}
                {cardStatus?.id === ReviewStatus?.submitted &&
                  !isArchive &&
                  !isMyPerformanceReview && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => redirect(true)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.giveFeedback")}
                      </p>
                    </DropdownItem>
                  )}
                {(cardStatus?.id === ReviewStatus?.finalized ||
                  cardStatus?.id === ReviewStatus?.completed) &&
                  !isArchive && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => redirect(false)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.viewFeedback")}
                      </p>
                    </DropdownItem>
                  )}
                {!isArchive && (
                  <DropdownItem
                    className={styles.dropdown_list}
                    onClick={() => handleArchive(review?.id)}
                  >
                    <p className={styles.dropdown_link}>
                      {t("performanceReview.archive")}
                    </p>
                  </DropdownItem>
                )}
                {isArchive && (
                  <DropdownItem
                    className={styles.dropdown_list}
                    onClick={() => handleUnArchieve(review?.id)}
                  >
                    <p className={styles.dropdown_link}>
                      {t("performanceReview.unarchive")}
                    </p>
                  </DropdownItem>
                )}
                {cardStatus?.id === ReviewStatus?.completed &&
                  !isArchive &&
                  !isMyPerformanceReview && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => handleFinalize(review)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.finalizeFeedback")}
                      </p>
                    </DropdownItem>
                  )}
                {cardStatus?.id !== ReviewStatus?.submitted &&
                  cardStatus?.id !== ReviewStatus?.completed && (
                    <DropdownItem
                      className={styles.dropdown_list}
                      onClick={() => setIsDeleteModel(true)}
                    >
                      <p className={styles.dropdown_link}>
                        {t("performanceReview.delete")}
                      </p>
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>

        <Text size="16px" weight="600" color="#587E85" marginTop="16px">
          {cardName}
        </Text>
        <div className={styles.grid_container}>
          {!isMyPerformanceReview && (
            <div>
              <Text size="12px" weight="400" color="#6F7788">
                {t("performanceReview.reviewSentBy")}
              </Text>
              <Text size="14px" weight="600" color="#102C42">
                {profile?.id === review?.initiatorUserId
                  ? "You"
                  : `${review?.initiatorUser?.firstName} ${review?.initiatorUser?.lastName}`}
              </Text>
            </div>
          )}
          <div>
            <Text size="12px" weight="400" color="#6F7788">
              {t("performanceReview.reviewSent")}
            </Text>
            <Text size="14px" weight="600" color="#102C42">
              {review && moment(review?.createdAt).format("MMM D, YYYY")}
            </Text>
          </div>
          {(review?.cancelledOn || review?.finalizedOn) && (
            <div>
              <Text size="12px" weight="400" color="#6F7788">
                {review?.cancelledOn
                  ? t("performanceReview.cancelledOn")
                  : t("performanceReview.finalizedOn")}
              </Text>
              <Text size="14px" weight="600" color="#102C42">
                {moment(review?.cancelledOn || review?.finalizedOn).format(
                  "MMM D, YYYY"
                )}
              </Text>
            </div>
          )}
        </div>
      </Card>
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          reviewId={review?.id}
          handleDelete={handleDelete}
          isDeleteModalOpen={isDeleteModalOpen}
          closeDeleteModal={() => setIsDeleteModel(false)}
          showLoader={isLoading}
        />
      )}
      {isCancelModalOpen && (
        <CancelConfirmationModal
          reviewId={review?.id}
          handleCancel={handleCancel}
          isCancelModalOpen={isCancelModalOpen}
          closeCancelModal={() => setIsCancelModel(false)}
          showLoader={isLoading}
        />
      )}
    </Fragment>
  );
}
