import Card from "components/Card";
import Page from "components/Page";
import { Store } from "containers/routes";
import React, { useEffect, useState, useContext } from "react";
import styles from "./Notification.module.scss";
import { withTranslation } from "react-i18next";
import { useGetNotifications } from "repositories/notification-repository";
import { checkNotificationAccesibility } from "repositories/subscription-repository";
import Loader from "components/Loader";
import { encodeId, formatDate, handleError, setStorage } from "utils";
import { unionBy } from "lodash";
import { useHistory } from "react-router-dom";
import Text from "components/Text";
import constants from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { getEventListDetails } from "repositories/scheduler-repository";
import moment from "moment-timezone";

const PAGE_SIZE = 3;

function Notification({ t }) {
  const history = useHistory();
  const profile = useSelector((state) => state.userProfile.profile);

  const goBack = () => {
    history.push("/");
  };

  const { setIsSubscriptionModel } = useContext(Store);
  const { setIsModelOpenForNotification } = useContext(Store);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { isLoading, data: apiData } = useGetNotifications(
    PAGE_SIZE,
    currentPage,
    true
  );
  const { data, pagination } = apiData || {};
  const { totalPages } = pagination || {};
  const { notification_list = [] } = data || {};
  const dispatch = useDispatch();

  useEffect(() => {
    setNotifications((prev) => [...unionBy(prev, notification_list, "id")]);
    //eslint-disable-next-line
  }, [apiData]);

  useEffect(() => {
    dispatch({ type: constants.NOTIFICATIONREADED });
  }, []);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const isNotificationAccessible = async (
    id,
    redirectionCode,
    officeId,
    globalNoteId,
    taskAssignmentId,
    performanceReviewId,
    employmentProofId,
    appointmentRequestId,
    timesheetId,
    dateCreated
  ) => {
    setShowLoader(true);
    setStorage(constants.notes.cache.isChatFromDashBoard, false);
    try {
      let response = await checkNotificationAccesibility(officeId);
      const accessResponse = response;
      const schedulerNotification = accessResponse?.planFeature?.find(
        (val) => val.id === constants.moduleNameWithId.scheduler
      );
      const TaskNotification =
        accessResponse?.length > 0 &&
        accessResponse?.map((item) => {
          return item?.planFeature?.find(
            (val) => val.id === constants.moduleNameWithId.scheduler
          );
        });

      if (schedulerNotification?.isAvailable || TaskNotification) {
        redirectToNotification(
          id,
          redirectionCode,
          officeId,
          globalNoteId,
          taskAssignmentId,
          performanceReviewId,
          employmentProofId,
          appointmentRequestId,
          timesheetId,
          dateCreated
        );
      } else {
        setIsSubscriptionModel(true);
        setIsModelOpenForNotification(true);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const redirectToNotification = async (
    id,
    redirectionCode,
    officeId,
    globalNoteId,
    taskAssignmentId,
    performanceReviewId,
    employmentProofId,
    appointmentRequestId,
    timesheetId,
    dateCreated
  ) => {
    const {
      eventAcceptAndReject,
      RequestToJoin,
      PulishEvent,
      EventRequestToJoin,
      EventEmployee,
      eventJoinandInvite,
      globalNotes,
      globalNoteReminder,
      cancaltask,
      newComment,
      newTaskassigned,
      taskActions,
      taskdelete,
      ReviewAssigned,
      ReviewSubmitted,
      ReviewFinalized,
      ReviewCompleted,
      ReviewCancelled,
      employmentProofRequest,
      employementProofSubmitted,
      notices,
      newAppointmentRequest,
      cancelAppointmentRequest,
      busySlotDetail,
      eventDetail,
      timesheetViewDetail,
    } = constants.notificationRedirectionCode;

    const schedularEventDate = moment(dateCreated).format("YYYY-MM-DD");

    if (
      redirectionCode === eventAcceptAndReject ||
      redirectionCode === RequestToJoin
    ) {
      history.push({
        pathname: constants.routes.scheduler.eventDetails.replace(
          ":eventId",
          encodeId(id)
        ),
        state: {
          from: "notifications",
          requestedDate: schedularEventDate,
        },
      });
    } else if (
      redirectionCode === PulishEvent ||
      redirectionCode === EventRequestToJoin
    ) {
      history.push({
        pathname: constants.routes.scheduler.EventWorkingDetails.replace(
          ":eventId",
          encodeId(id)
        ),
        state: {
          from: "notifications",
          requestedDate: schedularEventDate,
        },
      });
    } else if (
      redirectionCode === EventEmployee ||
      redirectionCode === eventJoinandInvite
    ) {
      try {
        let response = await getEventListDetails(id, schedularEventDate);
        if (response?.data?.createdById === profile.id) {
          history.push({
            pathname: constants.routes.scheduler.eventDetails.replace(
              ":eventId",
              encodeId(id)
            ),
            state: { requestedDate: schedularEventDate },
          });
        } else {
          history.push({
            pathname: constants.routes.scheduler.eventRequestDetails.replace(
              ":eventId",
              encodeId(id)
            ),
            state: {
              from: "notifications",
              requestedDate: schedularEventDate,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (redirectionCode === globalNotes) {
      history.push({
        pathname: constants.routes.notes,
        state: {
          from: "notifications",
        },
      });
    } else if (redirectionCode === globalNoteReminder) {
      history.push({
        pathname: constants.routes.noteDetails.replace(
          ":noteId",
          encodeId(globalNoteId)
        ),
        state: {
          from: "notifications",
        },
      });
    } else if (
      redirectionCode === cancaltask ||
      redirectionCode === newTaskassigned ||
      redirectionCode === taskActions ||
      redirectionCode === taskdelete
    ) {
      history.push({
        pathname: constants.routes.taskManagements.taskManagement,
        state: {
          from: "notifications",
        },
      });
    } else if (redirectionCode === newComment) {
      history.push({
        pathname: constants.routes.taskManagements.taskDetails.replace(
          ":taskId",
          encodeId(taskAssignmentId)
        ),
        state: {
          from: "notifications",
        },
      });
    } else if (redirectionCode === ReviewAssigned) {
      history.push({
        pathname: constants.routes.accountOwner.performanceReviewForm.replace(
          ":officeId",
          encodeId(officeId)
        ),
        state: {
          review: { id: performanceReviewId },
          isMyPerformanceReview: true,
          isGiveFeedback: false,
        },
      });
    } else if (
      redirectionCode === ReviewSubmitted ||
      redirectionCode === ReviewFinalized ||
      redirectionCode === ReviewCompleted
    ) {
      history.push({
        pathname: constants.routes.accountOwner.performanceReviewForm.replace(
          ":officeId",
          encodeId(officeId)
        ),
        state: {
          review: { id: performanceReviewId },
          isMyPerformanceReview: false,
          isGiveFeedback: false,
        },
      });
    } else if (redirectionCode === ReviewCancelled) {
      history.push({
        pathname: constants.routes.accountOwner.myPerformanceReview.replace(
          ":officeId",
          encodeId(officeId)
        ),
      });
    } else if (redirectionCode === employmentProofRequest) {
      history.push({
        pathname: constants.routes.accountOwner.employementProof.replace(
          ":officeId",
          encodeId(officeId)
        ),
      });
    } else if (redirectionCode === employementProofSubmitted) {
      history.push({
        pathname: constants.routes.accountOwner.employmentProofFormReview,
        state: {
          isViewMode: true,
          proofId: employmentProofId,
          officeId: officeId,
        },
      });
    } else if (redirectionCode === notices) {
      history.push({
        pathname: constants.routes.accountOwner.myNotices.replace(
          ":officeId",
          encodeId(officeId)
        ),
        state: { requestedDate: schedularEventDate },
      });
    } else if (
      redirectionCode === newAppointmentRequest ||
      redirectionCode === cancelAppointmentRequest
    ) {
      history.push({
        pathname: constants.routes.waitingAppoimentDetail
          .replace(":officeId", encodeId(officeId))
          .replace(":requestId", encodeId(appointmentRequestId)),
        state: { requestedDate: schedularEventDate },
      });
    } else if (redirectionCode === busySlotDetail) {
      history.push({
        pathname: constants.routes.scheduler.busySlotDetail
          .replace(":busySlotId", encodeId(id))
          .replace(":requestId", encodeId(appointmentRequestId)),
        state: { requestedDate: schedularEventDate },
      });
    } else if (redirectionCode === eventDetail) {
      history.push({
        pathname: constants.routes.scheduler.eventDetails.replace(
          ":eventId",
          encodeId(id)
        ),
        state: { requestedDate: schedularEventDate },
      });
    } else if (redirectionCode === timesheetViewDetail) {
      history.push({
        pathname: constants.routes.staff.timesheet.replace(
          ":officeId",
          encodeId(officeId)
        ),
        state: {
          from: "notifications",
          timesheetId,
        },
      });
    } else {
      history.push({
        pathname: constants.routes.scheduler.eventDetailsOnly.replace(
          ":eventId",
          encodeId(id)
        ),
        state: {
          from: "notifications",
          requestedDate: schedularEventDate,
        },
      });
    }
  };
  return (
    <Page onBack={goBack} title={t("notificationsPage.title")}>
      {(isLoading || showLoader) && <Loader />}
      <Card
        className={styles["notification-card"]}
        radius="10px"
        marginBottom="18px"
        shadow="0 0 15px 0 rgba(0, 0, 0, 0.08)"
      >
        <ul className={styles["notification-list"]}>
          {notifications.length > 0 ? (
            notifications.map((n) => {
              const {
                id,
                dateCreated,
                title,
                schedulerEventId,
                redirectionCode,
                officeId,
                globalNoteId,
                taskAssignmentId,
                performanceReviewId,
                employmentProofId,
                appointmentRequestId,
                timesheetId,
              } = n;
              const formatedDate = formatDate(dateCreated);
              return (
                <li key={id}>
                  <div className={styles["notify-icon"]}>
                    <img
                      src={
                        require("assets/images/Notification-icon.svg").default
                      }
                      alt="icon"
                    />
                  </div>
                  <div className={styles["content-box"]}>
                    <div className={styles["notify-text"]}>{title}</div>
                    <div className={styles["notify-date"]}>{formatedDate}</div>
                    <span
                      className="link-btn"
                      onClick={() =>
                        isNotificationAccessible(
                          schedulerEventId,
                          redirectionCode,
                          officeId,
                          globalNoteId,
                          taskAssignmentId,
                          performanceReviewId,
                          employmentProofId,
                          appointmentRequestId,
                          timesheetId,
                          dateCreated
                        )
                      }
                    >
                      {t("superAdmin.viewDetails")}
                    </span>
                  </div>
                </li>
              );
            })
          ) : (
            <div className="scheduler-empty-box">
              <p>
                <img
                  src={require("assets/images/notification_bell.svg").default}
                  alt="icon"
                />{" "}
              </p>
              <Text size="25px" marginBottom="0" weight="500" color="#111B45">
                {t("accountOwner.NoNotificationsFound")}
              </Text>
            </div>
          )}
        </ul>
      </Card>
      {totalPages && totalPages > currentPage ? (
        <div className="text-center mb-5">
          <button
            className="button button-round button-dark button-border"
            title={t("loadMore")}
            onClick={handleLoadMore}
          >
            {t("loadMore")}
          </button>
        </div>
      ) : null}
    </Page>
  );
}

export default withTranslation()(Notification);
