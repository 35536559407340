import React, { useState } from "react";
import Card from "components/Card";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styles from "../../Contracts/Contracts.module.scss";
import Text from "components/Text";
import { Link } from "react-router-dom";
import constants, { PERFORMANCE_REVIEW_STATUS, ReviewStatus } from "../../../../../constants";
import moment from "moment";
import { encodeId } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EmploymentProofCard({ t, employmentProof, isArchive, handleArchive, handleUnArchieve, officeId, isAdmin, setDeleteModal }) {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const getButtonClass = (performaceStatus) => {
    const buttonClassMap = {
      submitted: styles.submitted,
      completed: styles.completed,
      finalized: styles.finalized,
      general: styles.general,
      cancelled: styles.cancelled
    };
    return `${styles.notes_btn} ${buttonClassMap[performaceStatus] || ""}`;
  };

  const cardStatus = PERFORMANCE_REVIEW_STATUS.find(status => status.id === employmentProof?.status);
  function capitalizeAllLetters(string) {
    return string.toUpperCase();
  }

  const handleRedirect = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    if ((cardStatus?.id === ReviewStatus?.pending) && isAdmin) {
      history.push({ pathname: constants.routes.accountOwner.employmentProofForm.replace(":officeId", encodeId(officeId)), state: { proofId: employmentProof?.id, employeeName: employmentProof?.employeeName } })
    }
    if ((cardStatus?.id === ReviewStatus?.submitted)) {
      history.push({ pathname: constants.routes.accountOwner.employmentProofFormReview, state: { isViewMode: true, proofId: employmentProof?.id, officeId: officeId } })
    }
  }

  return (
    <Card className={styles.employement_proof_card} onClick={handleRedirect}>
      <div className={styles.btn_date_dropdown_wrapper}>
        <div className={styles.btn_date_wrapper}>
          <button className={getButtonClass(cardStatus?.title)}>
            {capitalizeAllLetters(t(`performanceReview.${cardStatus?.title}`))}
          </button>
          <div className={styles.date}>{moment(employmentProof?.createdAt).format("MMM D, YYYY h:mm A")}</div>
        </div>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="interactive-element">
          <DropdownToggle caret={false} tag="div" className="dropdown-btn px-3">
            <img
              src={require("assets/images/dots-icon.svg").default}
              alt="icon"
            />
          </DropdownToggle>
          <DropdownMenu right className={styles.custom_dropdown_wrapper}>
            {(cardStatus?.id === ReviewStatus?.pending && (isAdmin)) && <DropdownItem className={styles.dropdown_list}>
              <Link
                to={
                  {
                    pathname: constants.routes.accountOwner.employmentProofForm.replace(":officeId", encodeId(officeId)),
                    state: { proofId: employmentProof?.id, employeeName: employmentProof?.employeeName }
                  }}
                className={styles.dropdown_link}
              >
                {t("employmentProof.fillEmploymentProof")}
              </Link>
            </DropdownItem>}
            {!isArchive && <DropdownItem className={styles.dropdown_list}>
              <p className={styles.dropdown_link} onClick={() => handleArchive(employmentProof?.id)}>{t("employmentProof.archive")}</p>
            </DropdownItem>}
            {isArchive && <DropdownItem className={styles.dropdown_list}>
              <p className={styles.dropdown_link} onClick={() => handleUnArchieve(employmentProof?.id)}>{t("employmentProof.unarchive")}</p>
            </DropdownItem>}
            {(cardStatus?.id !== ReviewStatus?.pending) &&<DropdownItem className={styles.dropdown_list}>
              <p className={styles.dropdown_link} onClick={() => setDeleteModal(employmentProof?.id)}>{t("employmentProof.delete")}</p>
            </DropdownItem>}
          </DropdownMenu>
        </Dropdown>
      </div>
      {isAdmin && <Text size="16px" weight="600" color="#587E85" marginTop="16px">{employmentProof?.employeeName}</Text>}
      <Text size="12px" weight="400" color="#6F7788" marginTop="16px">{t("employmentProof.reason")}</Text>
      <p className={styles.employment_proof_text}>{employmentProof?.reasonForRequest}</p>
    </Card>
  );
}
