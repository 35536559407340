import axios from "axios";
import { useQuery } from "react-query";
import { Config } from "config";

const notesUrl = Config.serviceUrls.notesBaseUrl;
const USER_POINT_URL = Config.serviceUrls.userBaseUrl;

export async function getstaffMembers(officeId, pageNumber, pageSize) {
    const response = await axios.get(`${USER_POINT_URL}/Staff`, {
        params: {
            officeId,
            pageNo: pageNumber,
            pageSize,
            type: 4
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useStaffMembers(officeId, pageNumber, pageSize) {
    return useQuery(
        ["/discussion/staffMembers", officeId, pageNumber, pageSize],
        () => getstaffMembers(officeId, pageNumber, pageSize), {
                staleTime: 0,
                cacheTime: 0
        }
    );
}

export async function addDiscussionNote(staffId, description) {
    const response = await axios.post(`${notesUrl}/Note`, {
        staffId,
        description
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function updateDiscussionNote(noteId, description) {
    const response = await axios.put(`${notesUrl}/Note`, {
        noteId,
        description
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteDiscussionNote(noteId) {
    const response = await axios.delete(`${notesUrl}/Note/${noteId}`,);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getAllDiscussionNotes(staffId, pageNumber, pageSize) {
    const response = await axios.get(`${notesUrl}/Note`, {
        params: {
            staffId,
            pageNumber,
            pageSize
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useAllDiscussionNotes(staffId, pageNumber, pageSize) {
    return useQuery(
        ["/discussion/notes", staffId, pageNumber, pageSize],
        () => getAllDiscussionNotes(staffId, pageNumber, pageSize), {
            staleTime: 0,
            cacheTime: 0
        }
    );
}