import React from "react";
import Button from "components/Button";
import Text from "components/Text";
import ModalBody from "reactstrap/lib/ModalBody";
import { Modal } from "reactstrap";
import { withTranslation } from "react-i18next";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../../PerformanceReview/PerformanceReview.module.scss";
import Loader from "components/Loader";

const UpdateReminderConfirmModal = ({
  t,
  isModalOpen,
  closeModal,
  showLoader,
  handleReplace,
  handleWithoutSave,
  templateName,
  appointmentId,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-lg modal-dialog-centered"
      modalClassName="custom-modal"
    >
      {showLoader && <Loader />}
      <span className="close-btn" onClick={closeModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <div className={styles.confirmation_modal_container}>
          <Text size="25px" weight="500" color="#111B45">
            {appointmentId
              ? t("patientAppointmentMembers.confirm")
              : t("patientAppointmentMembers.changeOfficereminder")}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {appointmentId
              ? t(`patientAppointmentMembers.updateReminderMsg`, {
                  templateName: `"${templateName}"`,
                })
              : t(`patientAppointmentMembers.createOfficeRemidner`, {templateName: `"${templateName}"`})}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {appointmentId
              ? t("patientAppointmentMembers.updateReminderConfirmMsg")
              : t("patientAppointmentMembers.wishToChange")}
          </Text>
          <div className={styles.home_btn_wrapper_new + " " + styles.margin40}>
            <Button
              padding="0 10px"
              onClick={handleReplace}
              className={styles.green_btn}
            >
              {appointmentId
                ? t("patientAppointmentMembers.replace")
                : t("patientAppointmentMembers.changeAllAppointments")}
            </Button>
            <Button
              borderedSecondaryNew
              padding="0 10px"
              onClick={handleWithoutSave}
              className={styles.green_btn}
            >
              <span>
                {appointmentId
                  ? t("patientAppointmentMembers.applyWithoutSaving")
                  : t("patientAppointmentMembers.changeForFutureAppointments")}
              </span>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(UpdateReminderConfirmModal);
