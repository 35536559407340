import React, { useState } from "react";
import Text from "components/Text";
import styles from "../../TaskManagement.module.scss";
import CommentSendIcon from "../../../../../assets/images/send_comment_icon.svg";
import { formatUTCDateTime } from "utils";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import Empty from "components/Empty";

const animationVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.05,
        },
    },
};

const RightSection = ({ t, addComments, taskAssignmentId, setPageNumber, comments, totalItems }) => {
    const profile = useSelector(state => state.userProfile.profile);
    const [comment, setComment] = useState("");
    const [error, setError] = useState("");

    const handleChang = (e) => {
        if (e.target.value.length < 256) {
            setError("");
        }
        setComment(e.target.value);
    }

    const handleSubmit = () => {
        let params = {
            comment
        }
        if (comment && comment.length < 256) {
            addComments(taskAssignmentId, params);
            setComment("");
            setError("");
        } else {
            setError(t("taskManagement.commentError"));
        }
    }

    const handleCommentOwner = (user) => {
        if (user?.id == profile?.id) {
            return 'You'
        } else {
            return `${user?.firstName} ${user?.lastName}`
        }
    }

    return (
        <div className={styles.staff_right_section}>
            <div className={styles.comment_container}>
                <div className={styles.comment_topbar}>
                    <Text size="16px" weight="600" color="#2D3245">{t("taskManagement.comments")} ({comments?.length})</Text>
                </div>
                <div className={styles.comment_body}>
                    {comments?.length === 0 ? (<div className={styles["not_found"]}>
                        <Empty Message={t('taskManagement.commentNotFound')} />
                    </div>) : (
                        <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                            <InfiniteScroll
                                className="task_assignee w-100"
                                dataLength={comments?.length}
                                hasMore={comments?.length < totalItems}
                                next={() => setPageNumber((v) => v + 1)}
                            >
                                {
                                    comments?.map(comment => {
                                        return (
                                            <div className={`${styles.comment_text} ${comment?.user?.id == profile?.id ? styles.my_comment : ""} mt-1`}>
                                                <p className={styles.patient_name}>{handleCommentOwner(comment?.user)}</p>
                                                <div className={styles.date_time_wrapper}>
                                                    <p className={styles.date_time + " " + "m-0 pr-2"}>{formatUTCDateTime(comment?.createdAt)}</p>
                                                    <p className={styles.date_time + " " + "m-0"}>{moment.utc(comment?.createdAt).local().format('hh:mm A')}</p>
                                                </div>
                                                <p className={styles.date_time}>{comment?.text}</p>
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </motion.div>)
                    }
                </div>
                <div className={styles.comment_footer}>
                    <input type="text" placeholder="Your comment" value={comment} onChange={handleChang} />
                    <img src={CommentSendIcon} alt="CommentSendIcon" onClick={handleSubmit} />
                </div>
                {error && (
                    <span className="error-msg">{error}</span>
                )}
            </div>
        </div>
    )
}

export default RightSection