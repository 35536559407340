import React from "react";
import Button from "components/Button";
import Text from "components/Text";
import ModalBody from "reactstrap/lib/ModalBody";
import { Modal } from "reactstrap";
import { withTranslation } from "react-i18next";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../PerformanceReview.module.scss";
import Loader from "components/Loader";

const ConfirmationModal = ({ t, isDeleteModalOpen, closeDeleteModal, handleSendReview, showLoader }) => {
	return (
		<Modal
			isOpen={isDeleteModalOpen}
			toggle={closeDeleteModal}
			className="modal-dialog-centered modal-lg"
			modalClassName="custom-modal"
		>
			{showLoader && <Loader />}
			<span className="close-btn" onClick={closeDeleteModal}>
				<img src={crossIcon} alt="close" />
			</span>
			<ModalBody>
				<div className={styles.confirmation_modal_container}>
					<Text size="25px" weight="500" color="#111B45">
						{t("performanceReview.confirmation")}
					</Text>
					<Text size="16px" weight="300" color="#535B5F">
						{t("performanceReview.confirmDes")}
					</Text>
					<Text size="16px" weight="300" color="#535B5F">
						{t("performanceReview.confirmMessage")}

					</Text>
					<div className={styles.home_btn_wrapper + " " + styles.margin40}>
						<Button padding="0 63px" onClick={handleSendReview}>
							{t("performanceReview.sendReview")}
						</Button>
						<Button borderedSecondaryNew padding="0 83px" onClick={closeDeleteModal}>
							<span>{t("performanceReview.cancel")}</span>
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};
export default withTranslation()(ConfirmationModal);
