import React from "react";
import Page from "components/Page";
import { Col, Row } from "reactstrap";
import styles from "./AccountAdmin.module.scss";
import contractsIcon from "./../../../assets/images/contracts.svg";
import staffMembersIcon from "./../../../assets/images/staff-members.svg";
import timesheetIcon from "./../../../assets/images/timesheet-icon.svg";
import leavesIcon from "./../../../assets/images/leaves-icon.svg";
import notesIcon from "./../../../assets/images/notes.svg";
import performanceReviewIcon from "./../../../assets/images/performance_review.svg";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import { motion } from "framer-motion";
import { useOfficeDetail } from "repositories/office-repository";
import tooltipIcon from "./../../../assets/images/ico_admin.svg";
import { useLocation } from "react-router";
import { decodeId, setStorage } from "utils";
import patientAppointmentIcon from "./../../../assets/images/patient_apppointment.svg";
import waitingListIcon from "./../../../assets/images/waiting_list.svg";
import patientMessageCenterIcon from "./../../../assets/images/patient_message_center.svg";
import DashboardIcon from "./../../../assets/images/Dashboard.svg";
import noticesIcon from "./../../../assets/images/notices.svg";
import PatientsIcon from "./../../../assets/images/patients.svg";
import timesheetReportIcon from "../../../assets/images/timesheetReport.svg";

function AccountAdmin({ history, match, t }) {
  const location = useLocation();

  const goBack = () => {
    history.push({
      pathname: constants.routes.staff.officeOptions.replace(
        ":officeId",
        match.params.officeId
      ),
      state: location.state,
    });
  };

  const handleRedirect = (officeId) => {
    history.push({
      pathname: constants.routes.appointment.appointmentScreen,
      state: {
        officeId: officeId,
        activetabs: 1,
        statusFilter: [],
        isfromPaitentDetail: true,
        isAdmin: true,
      },
    });
  };
  const handleRedirectDashBoard = (officeId) => {
    history.push({
      pathname: constants.routes.superAdmin.appointmentDashboard.replace(
        ":officeId",
        officeId
      ),
      state: {
        officeId: officeId,
        activetabs: 1,
        statusFilter: [],
        isfromPaitentDetail: true,
        isAdmin: true,
      },
    });
  };

  const handlePatientRedirect = (officeId) => {
    history.push(
      constants.routes.appointment.patients.replace(":officeId", officeId)
    );
    setStorage("officeState", {
      officeState: {},
    });
  };

  let officeName = null;
  if (location.state && location.state.officeName) {
    officeName = location.state.officeName;
  }

  const { isLoading, data } = useOfficeDetail(decodeId(match.params.officeId));

  return (
    <Page
      onBack={goBack}
      isTitleLoading={!officeName && isLoading}
      title={officeName || (data && data.name)}
      className={styles["page"]}
    >
      <div className={styles["page-subheading"]}>{t("adminControls")}</div>
      <>
        {/* Office Management */}
        <div>
          <p className={styles["mobile-text-size"]}>
            {t("officeMangementCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.staffGrid.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={staffMembersIcon}
                title={t("contracts.staffMembers")}
              />
            </Col>
          </Row>
        </div>

        {/* Staff Management */}
        <div className="mt-4">
          <p className={styles["mobile-text-size"] + " " + "mt-4"}>
            {t("staffManagementCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.timesheet.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timesheetIcon}
                title={t("contracts.timesheet")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.leaves.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={leavesIcon}
                title={t("contracts.leaves")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.timesheetReport.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timesheetReportIcon}
                title={t("staffTimesheet.timesheetReport")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.forms.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={contractsIcon}
                title={t("contracts.formsAndContract")}
                officeName={officeName}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.discussionNotes.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={notesIcon}
                title={t("discussionNote.discussionNote")}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.notices.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={noticesIcon}
                title={t("notices.notice")}
                data={location.state}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.performanceReview.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={performanceReviewIcon}
                title={t("performanceReview.performanceReview")}
                officeName={officeName}
              />
            </Col>
          </Row>
        </div>

        {/* Patient Management */}
        <div>
          <p className={styles["mobile-text-size"] + " " + "mt-4"}>
            {t("pateintManagementCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                redirect={() => handlePatientRedirect(match.params.officeId)}
                icon={PatientsIcon}
                title={t("patients.patients")}
              />
            </Col>
            <Col lg="4">
              <Card
                redirect={() => handleRedirect(decodeId(match.params.officeId))}
                icon={patientAppointmentIcon}
                title="Patient Appointments"
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.waitingList.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={waitingListIcon}
                title={t("waitingList.waitingList")}
              />
            </Col>
            <Col lg="4">
              <Card
                redirect={() => handleRedirectDashBoard(match.params.officeId)}
                icon={DashboardIcon}
                title="Patient Dashboard"
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.appointment.patientMessageCenter
                  .replace(":officeId", match.params.officeId)
                  .replace(
                    "/:patientId?",
                    match?.params?.patientId
                      ? `/${match?.params?.patientId}`
                      : ""
                  )
                  .replace(
                    "/:appointmentId?",
                    match?.params?.appointmentId
                      ? `/${match?.params?.appointmentId}`
                      : ""
                  )}
                icon={patientMessageCenterIcon}
                title="Patient Message Center"
              />
            </Col>
          </Row>
        </div>
      </>
    </Page>
  );
}

function Card({ to, icon, title, officeName, redirect }) {
  const location = useLocation();
  if (redirect) {
    return (
      <motion.div
        whileHover={{ scale: constants.animation.hoverScale }}
        whileTap={{ scale: constants.animation.hoverScale }}
      >
        <span onClick={redirect} className="pointer">
          <div className={styles["card"]}>
            <div className={styles["tooltip-icon"]}>
              <img src={tooltipIcon} alt="icon" />
            </div>
            <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
            <h4>{title}</h4>
          </div>
        </span>
      </motion.div>
    );
  }
  return (
    <motion.div
      whileHover={{ scale: constants.animation.hoverScale }}
      whileTap={{ scale: constants.animation.hoverScale }}
    >
      <Link
        to={{
          pathname: to,
          state: location.state,
        }}
      >
        <div className={styles["card"]}>
          <div className={styles["tooltip-icon"]}>
            <img src={tooltipIcon} alt="icon" />
          </div>
          <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
          <h4>{title}</h4>
        </div>
      </Link>
    </motion.div>
  );
}

export default withTranslation()(AccountAdmin);
