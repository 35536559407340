import React, { useEffect, useState } from "react";
import "../../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useDoctorOffices } from "repositories/doctor-repository";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";

function TemPlateFilter({ t, selectedOffice, setOfficeFilter, templateLists }) {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectedOffice?.length === templateLists?.data?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedOffice, templateLists?.data]);

  const handleChange = (e, item) => {
    const offices = [...selectedOffice];
    const officeIndex = offices.findIndex((v) => +item.id === +v);
    if (e.target.checked) {
      offices.push(item.id);
    } else {
      if (officeIndex > -1) offices.splice(officeIndex, 1);
    }
    setOfficeFilter(offices);
    setSelectAll(false);
  };

  const handleAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setOfficeFilter(
        templateLists?.data?.length
          ? templateLists?.data?.map((item) => item.id)
          : []
      );
    } else {
      setOfficeFilter([]);
    }
  };

  return (
    <ul className={["filter-list"]}>
      {templateLists?.data?.length > 0 && (
        <ul>
          <li>
            <div className="ch-checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={handleAllChange}
                  checked={selectAll}
                />{" "}
                <span>{t("All Templates")}</span>
              </label>
            </div>
          </li>
          <hr className="mr-4" />
        </ul>
      )}
      {templateLists?.data?.length > 0 &&
        templateLists?.data?.map((item) => (
          <li key={item?.id}>
            <div className="ch-checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => handleChange(e, item)}
                  checked={selectedOffice?.includes(item.id)}
                />{" "}
                <span> {item?.templateName}</span>
              </label>
            </div>
          </li>
        ))}
    </ul>
  );
}

export default TemPlateFilter;
