
import { useEffect, useState } from "react";
import { decodeId, getStorage } from "utils";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { uniqBy } from "lodash";
import { addComment, deleteTask, taskAssignmentAction, upDateNotifications, useGetComment } from "repositories/task-management-repository";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import constants from "../../../../constants";
const pageSize = 10;

const useTaskDetails = (useTaskById, isFromOwner = false) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [OfficeList, setOffice] = useState([{ value: "", label: "All Office" }]);
    const [StatuList, SetStatus] = useState([{ value: "", label: "All" }]);
    let { taskId } = useParams();
    taskId = decodeId(taskId);
    const queries  = useTaskById(taskId, pageSize, pageNumber);
    const [taskDetail, assigneeData] = queries.map(query => query?.data);
    const isLoading = queries.some(query => query?.isLoading);
    const refetch =  queries.map(query => query.refetch);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState({ value: "", label: "All Offices" });
    const [selectedStatus, setSelectedStatus] = useState({ value: "", label: "All Status" });
    const [Assignee, setAssignee] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [notifications, setNotifications] = useState({notifyAssignees : false , notifyAssignor : false});
    const [comments, setComments] = useState([]);
    const history = useHistory();
    const queryClient = useQueryClient();
     const isChatFromDashBoard = getStorage("isChatFromDashBoard");

    const { isLoading : commentLoading, data : CommentData,  refetch : refetchComment } = useGetComment(
        taskId,
        pageNumber,
        pageSize,
        {enabled : !isFromOwner}
    );
    const goToPreviousScreen = () => {
        history.goBack();
    };


    const getStatus = (overallStatus) => {
        const statusMap = {
            1: "In Progress",
            2: "Pending",
            3: "Accepted",
            4: "Started",
            5: "Completed",
            6: "Rejected",
            7: "Cancelled"
        };
        return statusMap[overallStatus] || "";
    };

    function checkOverdueTasks(oldDates, datesStatus) {
        const now = moment.utc();
        const dueDate = moment.utc(oldDates);
        const Overdue = dueDate?.isBefore(now);

        let status = getStatus(datesStatus);
        if (Overdue) {
            if (status === "Pending" || status === "Accepted" || status === "Started") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function extractFromAssigneesList(data) {
        let uniqueOffices = [];
        let uniqueSatus = [];
        let officeIds = [];
        let statusIds = [];
        let extractedData = data.map((obj, index) => {
            const office = obj?.office?.name;
            const status = obj?.status;
            if (!officeIds.includes(obj?.office?.id)) {
                officeIds.push(obj?.office?.id);
                uniqueOffices.push({
                    label: office,
                    value: obj?.office?.id,
                });
            }

            if (!statusIds.includes(status)) {
                statusIds.push(status);
                uniqueSatus.push({
                    label: getStatus(status),
                    value: status,
                });
            }

        });

        let objRet = {
            data: extractedData,
            uniqueOffices: uniqueOffices,
            uniqueSatus: uniqueSatus,
        };
        return objRet;
    }

    useEffect(() => {
        if (Assignee?.length) {
            let obj = extractFromAssigneesList(Assignee)
            setOffice([{ value: "", label: "All Offices" }, ...obj.uniqueOffices]);
            SetStatus([{ value: "", label: "All Status" }, ...obj.uniqueSatus]);
        }
    }, [Assignee])

    useEffect(() => {
        setNotifications({
            notifyAssignees : taskDetail?.notifyAssignees || false,
            notifyAssignor :  taskDetail?.notifyAssignor || false
        })
    }, [taskDetail?.notifyAssignees])

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOfficeChange = (selectedOption) => {
        setSelectedOffice(selectedOption);
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };

    useEffect(() => {
        if (pageNumber === 1) {
            if (!commentLoading && CommentData && CommentData?.data?.length) {
                setComments(uniqBy([...CommentData?.data], "id"));
                setTotalItems(CommentData?.data?.pagination?.totalItems);
            }
        }
        else if (!commentLoading && CommentData?.data && CommentData?.data) {
            setComments(prev => uniqBy([...prev, ...CommentData?.data], "id"));
            setTotalItems(CommentData?.data?.pagination?.totalItems);
        }
    }, [commentLoading, CommentData?.data]);


    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && assigneeData && assigneeData.items) {
                setAssignee(uniqBy([...assigneeData?.items], "id"));
                setFilteredTasks(uniqBy([...assigneeData?.items], "id"));
                setTotalItems(assigneeData?.pagination?.totalItems);
            }
        }
        else if (!isLoading && assigneeData && assigneeData.items) {
            setAssignee(prev => uniqBy([...prev, ...assigneeData?.items], "id"));
            setFilteredTasks(prev => uniqBy([...prev, ...assigneeData?.items], "id"))
            setTotalItems(assigneeData?.pagination?.totalItems);
        }
    }, [isLoading, assigneeData]);

    useEffect(() => {
        if (Assignee?.length) {
            let filtered = Assignee;
            if (selectedOffice?.value) {
                filtered = filtered.filter(task => task?.office?.id === selectedOffice.value);
            }
            if (selectedStatus?.value) {
                filtered = filtered.filter(task => task.status === selectedStatus.value);
            }
            setFilteredTasks(filtered);
        }
    }, [Assignee, selectedOffice, selectedStatus]);

    const handleActiontask = async (taskId, TaskAction, param) => {
        setShowLoader(true);
        try {
          const res = await taskAssignmentAction(taskId, TaskAction, param);
          if (res?.status) {
            setShowLoader(false);
            setPageNumber(1);
            toast.success(res.message);
            queryClient.invalidateQueries(["mytasks/id", taskId]);
            refetch.forEach(fn => fn());
          }
        } catch (e) {
         toast.error(e.message);
          setShowLoader(false);
        }
      }

      const deleteAction = async (taskId) => {
        setShowLoader(true);
        try {
          const res = await deleteTask(taskId);
          if (res?.status) {
            setShowLoader(false);
            setPageNumber(1);
            history.push(constants.routes.taskManagements.taskManagement);
            toast.success(res.message);
          }
        } catch (e) {
          setShowLoader(false);
        }
      }

      const UpdateNotifications =async (taskId, updatedNotifications) => {
        setShowLoader(true);
        try {
          let params = {
            ...updatedNotifications
          }
          const res = await upDateNotifications(taskId, params);
          if (res?.status) {
            setShowLoader(false);
            setPageNumber(1);
            queryClient.invalidateQueries(["mytasks/id", taskId]);
            refetch.forEach(fn => fn());
            toast.success(res.message);
          }
        } catch (e) {
          setShowLoader(false);
        }
      }

      const addComments =async (taskAssignmentId, params) => {
        setShowLoader(true);
        try {
          const res = await addComment(taskAssignmentId, params);
          if (res?.status) {
            setShowLoader(false);
            setPageNumber(1);
            queryClient.invalidateQueries(["/addComment/staff", taskAssignmentId, pageNumber, pageSize]);
            refetchComment();
            toast.success(res.message);
          }
        } catch (e) {
          setShowLoader(false);
          toast.error(e.message);
        }
      }

    const handleNotifications = (e, taskId) => {
        const { name,  checked } = e.target;
        setNotifications((prevNotifications) => {
            const updatedNotifications = {
                ...prevNotifications,
                [name]: checked
            };
            UpdateNotifications(taskId, updatedNotifications);
            return updatedNotifications;
        });
    }

      const anyLoading =  isLoading || showLoader || commentLoading
      console.log(isFromOwner, "isFromOwner");
    return {
        data: {
            anyLoading,
            isLoading,
            taskDetail,
            assigneeData,
            filteredTasks,
            Assignee,
            totalItems,
            OfficeList,
            StatuList,
            selectedOffice,
            selectedStatus,
            isModalOpen,
            taskId,
            notifications,
            comments
        },
        methods: {
            goToPreviousScreen,
            getStatus,
            checkOverdueTasks,
            handleOfficeChange,
            handleStatusChange,
            setPageNumber,
            closeModal,
            handleActiontask,
            deleteAction,
            handleNotifications,
            addComments
        }
    }
}

export default useTaskDetails;
