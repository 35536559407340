import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Calendar } from "react-calendar";
import moment from "moment";
import { cloneDeep } from "lodash";
import Page from "components/Page";
import Card from "components/Card";
import Text from "components/Text";
import Loader from "components/Loader";
import {
  deleteEvent,
  getEventListDetails,
} from "repositories/scheduler-repository";
import { decodeId, encodeId } from "utils";
import constants from "../../../constants";
import styles from "./DeleteEvent.module.scss";
import SelectEmployeeModal from "../AddEvent/components/SelectEmployeeModal";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import "./DeleteEvent.scss";

function DeleteEvents({ t }) {
  const history = useHistory();
  const location = useLocation();
  let { eventId } = useParams();
  eventId = decodeId(eventId);

  const [deleteForAll, setDeleteForAll] = useState(true);
  const [deleteOption, setDeleteOption] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [loaderShow, setLoaderShow] = useState(true);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);
  const [userIds, setUserIds] = useState([]);

  const requestedDate = location?.state?.requestedDate;

  const getDetails = async () => {
    try {
      setLoaderShow(true);
      const schedularEventDate = moment(requestedDate).format("YYYY-MM-DD");
      let response = await getEventListDetails(eventId, schedularEventDate);
      if (response?.data) {
        setEventDetails(response.data);
      }
      setLoaderShow(false);
    } catch (error) {
      setLoaderShow(false);
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);

  const goBack = () =>
    history.push({
      pathname: constants.routes.scheduler.calendar,
      state: { requestedDate },
    });

  const handleCancel = () =>
    history.push({
      pathname: constants.routes.scheduler.eventDetails.replace(
        ":eventId",
        encodeId(eventId)
      ),
      state: { requestedDate },
    });

  const onChange = (date) => {
    const clickedDate = moment(date).format("YYYY-MM-DD");

    setSelectedDates((prevDates) => {
      const isDateSelected = prevDates.includes(clickedDate);

      return isDateSelected
        ? prevDates.filter((d) => d !== clickedDate)
        : [...prevDates, clickedDate];
    });
  };

  const getTileClassName = ({ date, view }) => {
    if (view !== "month") return "";

    const dateStr = moment(date).format("YYYY-MM-DD");
    const isSelected = selectedDates.includes(dateStr);

    return `${styles.calendar_tile} ${isSelected ? styles.selected : ""}`;
  };

  const isDateDisabled = (date) => {
    const currentDate = moment(date).startOf("day");

    if (!eventDetails?.date || !eventDetails?.repeatedEndDate) {
      return true;
    }

    const startDate = moment(eventDetails.date).startOf("day");
    const endDate = moment(eventDetails.repeatedEndDate).endOf("day");

    const isWithinRange = currentDate.isBetween(
      startDate,
      endDate,
      "day",
      "[]"
    );
    if (!isWithinRange) {
      return true;
    }

    if (eventDetails.repeatedType === 2) {
      const currentDayOfWeek = currentDate.day() || 7;
      const repeatedDay = eventDetails.repeatedDay;

      if (currentDayOfWeek !== repeatedDay) {
        return true;
      }
    }

    if (eventDetails?.schedulerEventDeleteHistory?.length > 0) {
      const allStaffDeletes = eventDetails.schedulerEventDeleteHistory.filter(
        (history) => history.isForAllStaff
      );

      for (const history of allStaffDeletes) {
        if (
          history.deletionType === 2 &&
          history.deletedDate &&
          history.deletedDate !== "0001-01-01T00:00:00"
        ) {
          const deletedStartDate = moment(history.deletedDate).startOf("day");
          const deletedEndDate = moment(eventDetails.repeatedEndDate).endOf(
            "day"
          );

          if (
            currentDate.isBetween(deletedStartDate, deletedEndDate, "day", "[]")
          ) {
            return true;
          }
        }

        if (history.schedulerEventDeletedDate?.length > 0) {
          const isDateDeleted = history.schedulerEventDeletedDate.some(
            (deletedDate) =>
              moment(deletedDate.selectedDate)
                .startOf("day")
                .isSame(currentDate, "day")
          );
          if (isDateDeleted) return true;
        } else if (
          history.deletedDate &&
          history.deletedDate !== "0001-01-01T00:00:00" &&
          history.deletionType !== 2
        ) {
          if (
            moment(history.deletedDate)
              .startOf("day")
              .isSame(currentDate, "day")
          ) {
            return true;
          }
        }
      }

      if (!deleteForAll && userIds?.length > 0) {
        const userSpecificDeletes =
          eventDetails.schedulerEventDeleteHistory.filter((history) => {
            if (history.isForAllStaff) return false;

            return history.schedulerEventDeletedEmployee?.some(
              (deletedEmployee) =>
                userIds.some(
                  (selectedUser) =>
                    selectedUser.userId === deletedEmployee.userId
                )
            );
          });

        for (const history of userSpecificDeletes) {
          if (
            history.deletionType === 2 &&
            history.deletedDate &&
            history.deletedDate !== "0001-01-01T00:00:00"
          ) {
            const deletedStartDate = moment(history.deletedDate).startOf("day");
            const deletedEndDate = moment(eventDetails.repeatedEndDate).endOf(
              "day"
            );

            if (
              currentDate.isBetween(
                deletedStartDate,
                deletedEndDate,
                "day",
                "[]"
              )
            ) {
              return true;
            }
          }

          if (history.schedulerEventDeletedDate?.length > 0) {
            const isDateDeleted = history.schedulerEventDeletedDate.some(
              (deletedDate) =>
                moment(deletedDate.selectedDate)
                  .startOf("day")
                  .isSame(currentDate, "day")
            );
            if (isDateDeleted) return true;
          } else if (
            history.deletedDate &&
            history.deletedDate !== "0001-01-01T00:00:00" &&
            history.deletionType !== 2
          ) {
            if (
              moment(history.deletedDate)
                .startOf("day")
                .isSame(currentDate, "day")
            ) {
              return true;
            }
          }
        }
      }
    }

    return false;
  };

  const isDeleteFutureDisabled = () => {
    if (eventDetails?.repeatedType == 1) return true;
    if (!requestedDate || !eventDetails?.schedulerEventDeleteHistory) {
      return false;
    }

    const allStaffType2Dates = eventDetails.schedulerEventDeleteHistory
      .filter(
        (history) =>
          history.isForAllStaff &&
          history.deletionType === 2 &&
          history.deletedDate &&
          history.deletedDate !== "0001-01-01T00:00:00"
      )
      .map((history) => moment(history.deletedDate).startOf("day"));

    const requestedMoment = moment(requestedDate).startOf("day");
    const isDisabledDueToAllStaff = allStaffType2Dates.some((deletedDate) => {
      const diffInDays = deletedDate.diff(requestedMoment, "days");
      return diffInDays <= 1;
    });

    if (isDisabledDueToAllStaff) {
      return true;
    }

    if (!deleteForAll && userIds?.length > 0) {
      const userSpecificType2Dates = eventDetails.schedulerEventDeleteHistory
        .filter((history) => {
          if (history.isForAllStaff) return false;

          const hasMatchingUser = history.schedulerEventDeletedEmployee?.some(
            (deletedEmployee) =>
              userIds.some(
                (selectedUser) => selectedUser.userId === deletedEmployee.userId
              )
          );

          return (
            hasMatchingUser &&
            history.deletionType === 2 &&
            history.deletedDate &&
            history.deletedDate !== "0001-01-01T00:00:00"
          );
        })
        .map((history) => moment(history.deletedDate).startOf("day"));

      return userSpecificType2Dates.some((deletedDate) => {
        const diffInDays = deletedDate.diff(requestedMoment, "days");
        return diffInDays <= 1;
      });
    }

    return false;
  };

  const isDateAlreadyDeleted = (date) => {
    if (!eventDetails?.schedulerEventDeleteHistory?.length) {
      return false;
    }

    const currentDate = moment(date).startOf("day");

    const allStaffDeletes = eventDetails.schedulerEventDeleteHistory.filter(
      (history) => history.isForAllStaff
    );

    for (const history of allStaffDeletes) {
      if (
        history.deletionType === 2 &&
        history.deletedDate &&
        history.deletedDate !== "0001-01-01T00:00:00"
      ) {
        const deletedStartDate = moment(history.deletedDate).startOf("day");
        const deletedEndDate = moment(eventDetails.repeatedEndDate).endOf(
          "day"
        );

        if (
          currentDate.isBetween(deletedStartDate, deletedEndDate, "day", "[]")
        ) {
          return true;
        }
      }

      if (history.schedulerEventDeletedDate?.length > 0) {
        const isDateDeleted = history.schedulerEventDeletedDate.some(
          (deletedDate) =>
            moment(deletedDate.selectedDate)
              .startOf("day")
              .isSame(currentDate, "day")
        );
        if (isDateDeleted) return true;
      } else if (
        history.deletedDate &&
        history.deletedDate !== "0001-01-01T00:00:00" &&
        history.deletionType !== 2
      ) {
        if (
          moment(history.deletedDate).startOf("day").isSame(currentDate, "day")
        ) {
          return true;
        }
      }
    }

    if (!deleteForAll && userIds?.length > 0) {
      const userSpecificDeletes =
        eventDetails.schedulerEventDeleteHistory.filter((history) => {
          if (history.isForAllStaff) return false;

          return history.schedulerEventDeletedEmployee?.some(
            (deletedEmployee) =>
              userIds.some(
                (selectedUser) => selectedUser.userId === deletedEmployee.userId
              )
          );
        });

      for (const history of userSpecificDeletes) {
        if (
          history.deletionType === 2 &&
          history.deletedDate &&
          history.deletedDate !== "0001-01-01T00:00:00"
        ) {
          const deletedStartDate = moment(history.deletedDate).startOf("day");
          const deletedEndDate = moment(eventDetails.repeatedEndDate).endOf(
            "day"
          );

          if (
            currentDate.isBetween(deletedStartDate, deletedEndDate, "day", "[]")
          ) {
            return true;
          }
        }

        if (history.schedulerEventDeletedDate?.length > 0) {
          const isDateDeleted = history.schedulerEventDeletedDate.some(
            (deletedDate) =>
              moment(deletedDate.selectedDate)
                .startOf("day")
                .isSame(currentDate, "day")
          );
          if (isDateDeleted) return true;
        } else if (
          history.deletedDate &&
          history.deletedDate !== "0001-01-01T00:00:00" &&
          history.deletionType !== 2
        ) {
          if (
            moment(history.deletedDate)
              .startOf("day")
              .isSame(currentDate, "day")
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const handleDelete = async () => {
    if (isValidate()) {
      try {
        setLoaderShow(true);
        const data = {
          SchedulerEventId: eventDetails?.id,
          UserIds: userIds.map((val) => val.userId),
          IsForAllStaff: deleteForAll,
          DeletionType: deleteOption,
          SelectedDates: deleteOption === 3 ? selectedDates : [],
          Reason: reason,
          DeletedDate: requestedDate,
        };

        const res = await deleteEvent(data);
        toast.success(res?.message);
        if (res) {
          setLoaderShow(false);
          goBack();
        }
      } catch (err) {
        toast.error(err.message);
        setLoaderShow(false);
      }
    }
  };

  const firstUserName = () => {
    let users = userIds;
    if (users?.length === 1 && eventDetails?.eventEmployees?.length) {
      let data = eventDetails?.eventEmployees?.find(
        (e) => e?.user?.id === users?.[0]?.userId
      );
      return `${data?.user?.firstName} ${data?.user?.lastName}`;
    } else {
      return `${users?.length} ${t("Selected")}`;
    }
  };

  const isValidate = () => {
    const errorsData = cloneDeep(errors);

    if (deleteOption === 1 && requestedDate) {
      if (isDateAlreadyDeleted(requestedDate)) {
        errorsData["event"] = t("scheduler.eventAlreadyDeleted");
        setErrors(errorsData);
        return false;
      } else {
        delete errorsData?.event;
      }
    } else {
      delete errorsData?.event;
    }

    if (deleteOption === 3 && selectedDates.length === 0) {
      errorsData["dates"] = t("scheduler.selectAlteastOneDate");
    } else {
      delete errorsData.dates;
    }

    if (!userIds.length && !deleteForAll) {
      errorsData["selectEmployees"] = t("form.errors.emptySelection", {
        field: t("accountOwner.employees"),
      });
    } else {
      delete errorsData["selectEmployees"];
    }

    setErrors(errorsData);
    return !Object.values(errorsData).some((err) => !!err);
  };

  return (
    <Page onBack={goBack} title={t("scheduler.deleteEvent")}>
      {loaderShow && <Loader />}
      <Card
        className={styles.delete_event_card}
        radius="10px"
        marginBottom="18px"
        shadow="0 0 15px 0 rgba(0, 0, 0, 0.08)"
      >
        <div
          className={`content_wrapper_new ${styles.content_wrapper}`}
          id="unique-content-wrapper"
        >
          <div className="ch-radio">
            <label className="mr-5">
              <input
                type="radio"
                name="deleteForAllOptions"
                checked={deleteForAll}
                onChange={() => setDeleteForAll(true)}
              />
              <span>{t("scheduler.deleteForAll")}</span>
            </label>
            <label
              className={`${
                eventDetails?.eventEmployees?.length === 1 && "disabled"
              }`}
            >
              <input
                type="radio"
                name="deleteForSelectedOptions"
                checked={!deleteForAll}
                disabled={eventDetails?.eventEmployees?.length === 1}
                onChange={() => {
                  if (eventDetails?.eventEmployees?.length > 1) {
                    setDeleteForAll(false);
                  }
                }}
              />
              <span>{t("scheduler.deleteForSelectedEmployee")}</span>
            </label>
          </div>

          {!deleteForAll && (
            <div className={styles.link_btn_wrapper}>
              <div className={styles.text_wrapper}>
                <Text size="13px" weight="400" color="#79869A">
                  {t("accountOwner.employees")}
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  {userIds?.length === 0
                    ? t("accountOwner.noEmployeesSelected")
                    : firstUserName()}
                </Text>
              </div>
              <span className="link-btn" onClick={() => setIsModalOpen(true)}>
                {t("accountOwner.selectEmployees")}
              </span>
            </div>
          )}

          {!deleteForAll && !userIds.length && errors.selectEmployees && (
            <span className="error-msg">{errors.selectEmployees}</span>
          )}

          <Text
            size="13px"
            marginBottom="16px"
            marginTop="25px"
            weight="400"
            color="#79869A"
          >
            {t("scheduler.selectToDeleteEvent")}:
          </Text>
          <div className={`ch-radio ${styles.delete_radio_btn_wrapper}`}>
            <label>
              <input
                type="radio"
                name="deleteOptions"
                checked={deleteOption === 1}
                onChange={() => {
                  setDeleteOption(1);
                }}
              />
              <span>{t("scheduler.deleteEventOnly")}</span>
            </label>
            <label className={`${isDeleteFutureDisabled() && "disabled"}`}>
              <input
                type="radio"
                name="deleteOptions"
                checked={deleteOption === 2}
                disabled={isDeleteFutureDisabled()}
                onChange={() => {
                  if (!isDeleteFutureDisabled()) {
                    setDeleteOption(2);
                  }
                }}
              />
              <span>{t("scheduler.deleteAllFutureEvents")}</span>
            </label>
            <label
              className={`${eventDetails?.repeatedType == 1 && "disabled"}`}
            >
              <input
                type="radio"
                name="deleteOptions"
                checked={deleteOption === 3}
                disabled={eventDetails?.repeatedType == 1}
                onChange={() => setDeleteOption(3)}
              />
              <span>{t("scheduler.deletForSpecificeDate")}</span>
            </label>
          </div>

          {deleteOption === 3 && (
            <>
              <Calendar
                onChange={onChange}
                className={`scheduler-calendar ${styles.custom_calendar}`}
                tileDisabled={({ date }) => isDateDisabled(date)}
                minDate={
                  eventDetails?.date ? new Date(eventDetails.date) : null
                }
                maxDate={
                  eventDetails?.repeatedEndDate
                    ? new Date(eventDetails.repeatedEndDate)
                    : null
                }
                tileClassName={getTileClassName}
                value={null}
              />
              {errors?.dates && (
                <span className="error-msg mt-2">{errors.dates}</span>
              )}
            </>
          )}

          <div className="c-field mt-4">
            <label>{t("scheduler.reasonForDelete")}</label>
            <textarea
              className="c-form-control"
              placeholder="Write a reason"
              name="Reason for delete event"
              maxLength="500"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
            {errors?.reason && (
              <span className="error-msg mt-2">{errors.reason}</span>
            )}
          </div>
          {errors?.event && (
            <span className="error-msg mt-2 mb-2">{errors.event}</span>
          )}
          <button
            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
            onClick={handleDelete}
          >
            {t("scheduler.deleteEvent")}
          </button>
          <button
            className="button button-round button-border button-dark btn-mobile-link"
            onClick={handleCancel}
          >
            {t("notes.cancel")}
          </button>
        </div>
      </Card>

      {isModalOpen && (
        <SelectEmployeeModal
          t={t}
          issaveEmployeeModalOpen={isModalOpen}
          setIsSaveEmployeeModalOpen={setIsModalOpen}
          allMembersList={
            eventDetails?.eventEmployees
              ?.filter((item) => !item?.isDeletedForThisSchedulerEvent)
              ?.map((val) => ({
                ...val,
                ...val?.user,
              })) || []
          }
          memberIds={userIds}
          setMemberIds={setUserIds}
          officeType={eventDetails?.officeId}
        />
      )}
    </Page>
  );
}

export default withTranslation()(DeleteEvents);
