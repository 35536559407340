import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import 'rc-time-picker/assets/index.css'
import { Modal } from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import crossIcon from '../../../../assets/images/cross.svg'
import Text from 'components/Text'
import styles from "../../Notes/Notes.module.scss"
import Loader from 'components/Loader'
import Button from 'components/Button'


function CancelRequestModal({ t, openModal, closeModal, showLoader, title, inputLabel, errorMsg, handleChange, reason, submitReason }) {

    return (
        <Fragment>
            <Modal
                isOpen={openModal}
                toggle={closeModal}
                className="modal-lg modal-dialog-centered"
                modalClassName="custom-modal"
            >
                <span className='close-btn' onClick={closeModal}><img src={crossIcon} alt='close' /></span>
                <ModalBody>
                    {showLoader && <Loader />}
                    <Text size='20px' marginBottom="15px" weight='500' color='#111b45' >
                        <span className='modal-title-25'>{title}</span>
                    </Text>
                    <div className="c-field">
                        <label>{inputLabel} </label>
                        <textarea
                            placeholder={"add a Reason"}
                            className={"c-form-control " + styles["custom-textarea-control"]}
                            name="reason"
                            maxLength="400"
                            onChange={(e) => handleChange(e)}
                            value={reason}
                        ></textarea>
                        {errorMsg && <span className="error-msg mt-2 mb-2">{errorMsg}</span>}
                    </div>
                    <div className={styles.main_btn_container}>
                        <Button onClick={submitReason}>{t('submit')}</Button>
                        <Button borderedSecondaryNew onClick={closeModal}>
                            <span>{t('patientAppointmentMembers.cancel')}</span>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default withTranslation()(CancelRequestModal)