import { Fragment } from "react";
import QuestionSection from "./QuestionSection";

const StaffRating = ({ t, questionId, ratingCriteria, handleCommentChange, handleRadioChange, questionnaireResponses, isView, title, giveFeedback, review }) => {
    return (
        <Fragment>
            <QuestionSection
                t={t}
                questionId={questionId}
                ratingCriteria={ratingCriteria}
                handleCommentChange={handleCommentChange}
                handleRadioChange={handleRadioChange}
                questionnaireResponses={questionnaireResponses}
                isView={isView}
                giveFeedback={giveFeedback}
                uniqueId={`staff-${questionId}`}
                review={review}
                title={title}
                isSeeRating={true}
            />
        </Fragment>
    )
}

export default StaffRating;