import React, { useState, Suspense } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { withTranslation } from 'react-i18next'
import Text from "components/Text";
import { Button, Modal, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import crossIcon from "../../../../../../assets/images/cross.svg"
import Loader from "components/Loader";
import Empty from 'components/Empty';
import "../../../Appointments.scss"
import styles from "../../../Appointments.module.scss"
import { useAppointmentCovidHistory, useAppointmentHistory, useAppointmentNotes } from 'repositories/patient-appointment-repository';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { decodeId, formatUTCDateTime } from 'utils';
import useCommonModule from 'accountOwner/pages/Appointments/hooks/useCommonModule';
import { useSelector } from 'react-redux';

const AddUpcomingHistory = ({ t }) => {
	const profile = useSelector((state) => state.userProfile.profile);
	const [modalOpen, setModalOpen] = useState(false);
	const [activeTab, setActiveTab] = useState("1");
	const [value, setValue] = useState(1);
	const [historyDetail, setHistoryDetail] = useState({})
	const history = useHistory();
	let { appointmentId } = useParams();
	appointmentId = decodeId(appointmentId);
	let param = {
		pageNumber: 1,
		pageSize: 100,
		appointmentId
	}

	const {
		methods: {
			getStatus,
		},
	} = useCommonModule(history, t);
	const { isLoading, data: appointmentHistory } = useAppointmentHistory(param);

	let params = {
		pageNumber: 1,
		pageSize: 100,
		appointmentId: historyDetail?.id
	}

	const { data: appointmentNotes } = useAppointmentNotes(params, { enabled: !!historyDetail?.id });
	const { data: appointmentCovindHistory } = useAppointmentCovidHistory(historyDetail?.id, { enabled: !!historyDetail?.id });

	const covidFormQuestions = [
		{
			question: t("patientAppointmentMembers.covidFormQuestions.questionOne"),
			answer: `${appointmentCovindHistory?.temperature} ${appointmentCovindHistory?.temperatureUnit}`,
		},
		{
			question: t("patientAppointmentMembers.covidFormQuestions.questionTwo"),
			answer: appointmentCovindHistory?.symptoms?.length && appointmentCovindHistory?.symptoms[0]?.name,
		},
		{
			question: t("patientAppointmentMembers.covidFormQuestions.questionThree"),
			answer: appointmentCovindHistory?.hasTravelled ? "YES" : "NO",
		},
		{
			question: t("patientAppointmentMembers.covidFormQuestions.questionFour"),
			answer: appointmentCovindHistory?.hasContacted ? "YES" : "NO",
		},
		{
			question: t("patientAppointmentMembers.covidFormQuestions.questionFive"),
			answer: appointmentCovindHistory?.hasTestedAndAwaitingResult ? "YES" : "NO",
		},
	];

	const columns = [
		{
			formatter: (cellContent, row, rowIndex) => (
				<Button className={styles["appointment-history-tab-modal-btn"]} onClick={() => { setModalOpen(!modalOpen); setHistoryDetail(row) }}>
					<span className={styles["appointment-table-patient-name"]}>{formatUTCDateTime(row.appointmentDate)} </span>
				</Button>
			),
		},
		{
			formatter: (cellContent, row, rowIndex) => (
				<div className={styles["history-table-patientName-columns"]}>
					{`${row?.doctor?.firstName} ${row?.doctor?.lastName}`}
				</div>
			),
		},
		{
			formatter: (cellContent, row, rowIndex) => (
				<div className={styles["history-table-status-columns"]}>
					{getStatus(row.status)}
				</div>
			),
		},
	];

	const DetailsTabContent = () => (
		<div>
			<Text size="12px" weight="400" color="#6F7788" marginBottom="12px">{t("patientAppointmentMembers.DescriptionTreatmentNeeded")}</Text>
			<Text size="16px" weight="300" color="#535B5F">{historyDetail?.description}</Text>
		</div>
	)

	const NotesTabContent = () => (
		<>
			<div>
				<Text size="12px" weight="400" color="#6F7788" marginBottom="12px">Notes</Text>

				{appointmentNotes?.items?.length ? (appointmentNotes?.items?.map(notes => {
					return (
						<>
							<Text size="12px" weight="400" color="#6F7788" marginBottom="12px">
								{t("patientAppointmentMembers.addedBy")} {profile?.id === notes?.creator?.id ? 'You' : `${notes?.creator?.firstName} ${notes?.creator?.lastName}`}
							</Text>
							<Text size="16px" weight="300" color="#535B5F">{notes?.note}</Text>
						</>
					)
				})) : <Empty
					Message="No Notes Added"
				/>}
			</div>
		</>
	)

	const CovidFormQuestion = ({ question, answer }) => (
		<div className={styles["covid-form-question"]}>
			<Text size="16px" weight="600" color="#587E85" marginBottom="12px">{question}</Text>
			<Text size="14px" weight="400" color="#2F3245">{t("patientAppointmentMembers.yourAnswer")}: <span>{answer}</span></Text>
		</div>
	)

	const CovidFormTabContent = () => (
		<>
			{appointmentCovindHistory ?
				<div >
					<div className={styles['appointment-history-modal-scrollbar']}>
						{covidFormQuestions?.map((item, index) => {
							return (
								<CovidFormQuestion key={index} question={item.question} answer={item.answer} />
							)
						})}
					</div>
				</div>
				: <Empty Message={t("patientAppointmentMembers.covidFormQuestions.formNotFilled")} />
			}
		</>
	)

	const ModalCardData = () => (
		<div>
			<div className="common-tabs nav_tabs_wrapper">
				<Nav tabs className="nav-tabs history-nav-tabs">
					<NavItem className="nav-item history-nav-item">
						<NavLink
							className={activeTab === "1" ? "active" : "not-active"} onClick={() => setActiveTab("1")}>
							{t("patientAppointmentMembers.details")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={activeTab === "2" ? "active" : "not-active"} onClick={() => setActiveTab("2")}>
							{t("patientAppointmentMembers.notes")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink className={activeTab === "3" ? "active" : "not-active"} onClick={() => setActiveTab("3")}>
							{t("patientAppointmentMembers.covidForm")}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<Suspense fallback={<Loader />}>
						<TabPane tabId="1">
							{activeTab === "1" && <DetailsTabContent />}
						</TabPane>
						<TabPane tabId="2">
							{activeTab === "2" && <NotesTabContent />}
						</TabPane>
						<TabPane tabId="3">
							{activeTab === "3" && <CovidFormTabContent />}
						</TabPane>
					</Suspense>
				</TabContent>
			</div>
		</div>
	)

	return (
		<div>
			{isLoading && <Loader />}
			<Text size="18px" weight="600" color="#587E85" marginBottom="10px">{t("patientAppointmentMembers.patientAppointmentHisotry")}</Text>
			<div className="patient_appointment_history_table">
				<BootstrapTable
					keyField='id'
					data={appointmentHistory?.items || []}
					columns={columns}
					bordered={false}

				/>
			</div>
			<Modal 
				toggle={() => setModalOpen(!modalOpen)} 
				isOpen={modalOpen} 
				centered={true} 
				className={"modal-dialog-centered modal-width-660 "} 
				modalClassName='custom-modal'
			>
				<span className="close-btn" onClick={() => setModalOpen(!modalOpen)}>
					<img src={crossIcon} alt="close" />
				</span>
				<ModalBody>
					<Text size="25px" weight="500" color="#111B45" marginBottom="40px">
						{t("patientAppointmentMembers.patientHistory")} - {formatUTCDateTime(historyDetail?.appointmentDate)}
					</Text>
					<ModalCardData />
				</ModalBody>
			</Modal>
		</div>
	)
}
export default withTranslation()(AddUpcomingHistory)