import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../Dashboard.module.scss";
import { useStaffTaskList } from "repositories/task-management-repository";
import StaffTaskCard from "./StaffTaskCard";
import useStaffTaskModule from "accountOwner/pages/TaskManagement/hooks/useStaffTaskModule";
import NotAccessible from "../components/NotAccessible";
import { LoaderIcon } from "react-hot-toast";

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const StaffTasks = ({
  t,
  isArchive,
  appliedOfficeFilters,
  appliedStaffFilters,
  handleRedirect,
  isSchedulerOnly,
}) => {
  const {
    isLoading,
    tasks,
    pageNumber,
    totalPages,
    setPageNumber,
    setAppliedOfficeFilters,
    setAppliedAssignorFilter,
    handleActiontask,
    handleArchiveApi,
  } = useStaffTaskModule(useStaffTaskList, isArchive);
  
  let items = (
    <>
      {tasks?.map((task) => {
        return (
          <StaffTaskCard
            myTask={task}
            key={task.taskId}
            handleRedirect={handleRedirect}
            handleActiontask={handleActiontask}
            handleArchiveApi={handleArchiveApi}
            isArchive={isArchive}
          />
        );
      })}
    </>
  );

  useEffect(() => {
    if (!isSchedulerOnly) {
      setAppliedOfficeFilters(appliedOfficeFilters);
      setAppliedAssignorFilter(appliedStaffFilters);
      setPageNumber(1);
    }
  }, [appliedOfficeFilters, appliedStaffFilters, isSchedulerOnly]);

  return (
    <>
      {isLoading && pageNumber === 1 ? (
        <LoaderIcon className={styles.loader_style} />
      ) : (
        <>
          {!tasks.length ? (
            <div className={styles["not_found"] + " " +"d-flex justify-content-center align-items-center"}>
              <NotAccessible
                isNotFound={true}
                Message={t("taskManagement.taskNotFound")}
              />
            </div>
          ) : (
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
              className="w-100"
            >
              <InfiniteScroll
                dataLength={tasks.length}
                hasMore={pageNumber < totalPages}
                loader={<LoaderIcon className={styles.loader_style} />}
                next={() => {
                  pageNumber < totalPages && setPageNumber((prev) => prev + 1);
                }}
                scrollableTarget="scrollableDivTwo"
              >
                {items}
              </InfiniteScroll>
            </motion.div>
          )}
        </>
      )}
    </>
  );
};
export default withTranslation()(StaffTasks);
