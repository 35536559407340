import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Card from "components/Card";
import Text from "components/Text";
import fileIcon from "../../../../../assets/images/new_file_icon.svg";
import BellIcon from "../../../../../assets/images/alert_notification.svg";
import styles from "../../Notes.module.scss";
import { withTranslation } from "react-i18next";
import Loader from "components/Loader";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { updateNoteSeen, useMyNoteById } from "repositories/notes-repository";
import moment from "moment-timezone";
import ReminderModel from "../../modal/ReminderModel";
import { useMutation, useQueryClient } from "react-query";
import constants from "../../../../../constants";
import { useSelector } from "react-redux";
import { decodeId, handleError } from "utils";

const NoteDetails = ({ history, t }) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const location = useLocation();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState("seen");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteDetail, setNoteDetail] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [isMyNotes, setIsMyNotes] = useState(false);

  let { noteId } = useParams();
  noteId = decodeId(noteId);
  const { isLoading, data } = useMyNoteById(noteId);

  const { mutate: seenStatusMutate } = useMutation(
    (globalNoteTransferHistoryId) => updateNoteSeen(globalNoteTransferHistoryId)
  );

  const updateKey = () => queryClient.invalidateQueries("mynotes/id");

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToPreviousScreen = () => {
    history.push(constants.routes.notes);
  };

  useEffect(() => {
    if (!isLoading && data) {
      const isMyNoteData = location?.pathname.includes("my-note-details");
      setIsMyNotes(isMyNoteData);
      if (location?.state?.status) {
        setStatus(location?.state?.status);
      } else {
        if (data?.globalNoteTransferHistory[0]?.isSeen) {
          setStatus("seen");
        } else {
          if (isMyNoteData) {
            setStatus("new");
          } else {
            setStatus("delivered");
          }
        }
      }
      if (isMyNoteData) {
        if (!data?.globalNoteTransferHistory?.[0]?.isSeen) {
          setMutationLoading(true);
          seenStatusMutate(data?.globalNoteTransferHistory?.[0]?.id, {
            onSuccess: () => {
              setStatus("seen");
              setMutationLoading(false);
            },
            onError: (err) => {
              handleError(err);
              setMutationLoading(false);
            },
          });
        }
      }
      setNoteDetail(data);
    }
  }, [isLoading, data]);

  const getButtonClass = () => {
    const buttonClassMap = {
      new: styles.new,
      seen: styles.seen,
      delivered: styles.delivered,
    };
    return `${styles.notes_btn} ${buttonClassMap[status] || ""}`;
  };

  function capitalizeAllLetters(string) {
    return string.toUpperCase();
  }

  const getAttachmentName = (link) => {
    return link.substring(link.lastIndexOf("/") + 1);
  };

  const handleDownload = async (fileLink, fileName) => {
    setShowLoader(true);
    try {
      const response = await fetch(fileLink);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <Page onBack={goToPreviousScreen} title={t("notes.note")}>
      {(isLoading || showLoader || mutationLoading) && <Loader />}

      <Card className={styles["add_note_card"]}>
        <div className={styles["note_detail_container"]}>
          <div className={styles["btn_date_wrapper"]}>
            <button className={getButtonClass()}>
              {capitalizeAllLetters(status)}
            </button>
            <div className={styles["notes_date"]}>
              {noteDetail &&
                moment(
                  noteDetail?.transferredAt || noteDetail?.createdAt
                ).format("MMM Do, YYYY h:mm A")}
            </div>
          </div>
          <Text size="20px" weight="500" color="#111B45" marginTop="30px">
            {noteDetail?.title}
          </Text>
          <div className={styles["office_doctor_wrapper"]}>
            <div className={styles["office_name_data"]}>
              <Text size="12px" weight="400" color="#6F7788">
                {t("notes.officeName")}
              </Text>
              <Text size="14px" weight="600" color="#102C42">
                {noteDetail?.office?.name}
              </Text>
            </div>
            {isMyNotes ? (
              <div>
                <Text size="12px" weight="400" color="#6F7788">
                  {t("notes.assignedBy")}
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  {profile?.id === noteDetail?.assignerId
                    ? t("notes.you")
                    : noteDetail?.assigner?.fullName}
                </Text>
              </div>
            ) : (
              <div>
                <Text size="12px" weight="400" color="#6F7788">
                  {t("notes.transferredTo")}
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                  {noteDetail?.assignee?.fullName}
                </Text>
              </div>
            )}
          </div>
          <div>
            <Text size="12px" weight="400" color="#6F7788">
              {t("notes.description")}
            </Text>
            <Text size="14px" weight="600" color="#102C42">
              {noteDetail?.description}
            </Text>
          </div>
          {noteDetail?.fileLink && (
            <div className={styles["margin_top"]}>
              <Text size="12px" weight="400" color="#6F7788">
                {t("notes.fileAttached")}
              </Text>
              <div className="d-flex gap-2">
                <img src={fileIcon} alt="close" className="mr-2" />
                <Text
                  size="14px"
                  weight="600"
                  color="#102C42"
                  ellipsis="nowrap"
                >
                  {getAttachmentName(noteDetail?.fileLink)}
                </Text>
              </div>
              <button
                className={styles["download_btn"] + " " + "link-btn"}
                onClick={() =>
                  handleDownload(
                    noteDetail?.fileLink,
                    getAttachmentName(noteDetail?.fileLink)
                  )
                }
              >
                {t("notes.download")}
              </button>
            </div>
          )}
          {isMyNotes && (
            <div className={styles["notification_change"]}>
              <Text size="12px" weight="400" color="#6F7788">
                {t("notes.reminder")}
              </Text>
              <div className={styles.reminder_change}>
                <img src={BellIcon} alt="close" />
                {noteDetail?.reminderDate && noteDetail?.isReminderOn ? (
                  <Text size="14px" weight="600" color="#102C42">
                    {moment(noteDetail?.reminderDate).format(
                      "MMMM Do, YYYY h:mm A"
                    )}
                  </Text>
                ) : (
                  <Text size="14px" weight="400" color="#EE4F4F">
                    {t("notes.noReminderSet")}
                  </Text>
                )}
              </div>
              {!noteDetail?.isArchivedByAssignee && (
                <p
                  className={"link-btn"}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  {t("notes.change")}
                </p>
              )}
            </div>
          )}
        </div>
      </Card>
      {isModalOpen && (
        <ReminderModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          noteId={noteDetail?.id}
          updateKey={updateKey}
          reminderDate={noteDetail?.reminderDate}
          isReminderOn={noteDetail?.isReminderOn}
        />
      )}
    </Page>
  );
};
export default withTranslation()(NoteDetails);
