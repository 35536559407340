import { useEffect, useMemo } from "react";
import { useSbCalls } from "../context";
import DialView from "../views/DialView/DialView";
import CallViewNew from "../views/CallView/CallViewNew";
import IncomingCall from "../views/IncomingCall/IncomingCall";
import { handleError } from "utils";

const DirectCallMain = ({ dial = true }) => {
  const { calls, clearCalls } = useSbCalls();
  const onCall = useMemo(() => {
    return calls.find((call) => call.isOngoing && call.callState !== "ended");
  }, [calls]);
  useEffect(() => {
    const value = calls.find((res) => res.callState === "ended");
    if (value) {
      const message = value?.endResult || value?._endResult;
      if (message) {
        handleError(new Error(message.split("_").join(" ")));
      }
      clearCalls();
    }
  }, [calls]);
  return (
    <>
      {dial && <DialView />}
      {onCall && onCall?.callState === "ringing" ? (
        <IncomingCall call={onCall} />
      ) : (
        onCall && <CallViewNew call={onCall} />
      )}
    </>
  );
};

export default DirectCallMain;
