import Page from "components/Page";
import React from "react";
import Invoices from "./Invoices";
import Subscription from "./Subscription";
import { decodeId, encodeId } from "utils";
import { useSelector } from "react-redux";
import constants from "../../../../constants";

function AccountOwnerSubscriptionAndInvoices({ history, match }) {
  const profile = useSelector((pre) => pre?.userProfile?.profile);
  const accountOwnerId = decodeId(match.params.accountOwnerId);

  const goBack = () => {
    if (profile?.role?.systemRole === constants.systemRoles.superAdmin) {
      history.push(`/account-owner/${encodeId(accountOwnerId)}`);
    } else {
      history.push("/manage-plan");
    }
  };

  return (
    <Page
      titleKey="superAdmin.accountOwnersSubscriptionDetails"
      onBack={goBack}
    >
      <Subscription accountOwnerId={accountOwnerId} />

      <Invoices accountOwnerId={accountOwnerId} />
    </Page>
  );
}

export default AccountOwnerSubscriptionAndInvoices;
