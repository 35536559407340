import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import { Card } from "reactstrap";
import Text from "components/Text";
import CustomDropdown from "components/Dropdown";
import Input from "components/Input";
import SchedulerCalendar from "accountOwner/pages/Scheduler/components/SchedulerCalendar";
import styles from "../../../../Appointments.module.scss";
import BookTimeslot from "accountOwner/pages/Appointments/components/BookTimeslot";
import useReAssigneAppointmentModule from "accountOwner/pages/Appointments/hooks/useReAssigneAppointmentModule";
import moment from "moment";
import { useOfficeDoctor } from "repositories/patient-appointment-repository";
import constants from "../../../../../../../constants";
import Loader from "components/Loader";
import { useSelector } from "react-redux";

const ReassignAppointment = ({ t, history }) => {
  const [errors, setErrors] = useState([]);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [repeatOptions, setRepeatOptions] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSlotDate, setSlotDate] = useState(new Date());
  const profile = useSelector((state) => state.userProfile.profile);
  const {
    data: { appointmentId, officeId, appointmentDetail, loader },
    methods: { goToPreviousScreen, handleSubmit },
  } = useReAssigneAppointmentModule();

  const selectedDate = moment(selectedSlotDate).startOf("day");

  const { data: doctorData, isLoading } = useOfficeDoctor(officeId, 1, 100);

  useEffect(() => {
    const options = doctorData?.items
      ?.map((item) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
      }))
      ?.filter((doctor) => doctor?.id !== profile?.id) || [] ;
      if (options.length > 0) {
        setSelectedDoctor(options?.[0]?.id);
      } 
    setRepeatOptions(options);
  }, [isLoading]);

  const onActiveStartDateChange = (value) => {
    if (
      constants.calanderActions.includes(value?.action) ||
      (value?.action === constants.calanderActionKey.drillDown &&
        (value?.view === "month" || value?.view === "year"))
    ) {
      value?.activeStartDate && setSlotDate(value.activeStartDate);
    }
  };

  const handleReAssignSubmit = async (e) => {
    const startTimes = appointmentSlots
      ?.filter((app) => app.startTime)
      ?.map((app) => app.startTime);
    const params = {
      doctorId: selectedDoctor,
      appointmentId: appointmentId,
      appointmentDate: moment(selectedSlotDate).format("YYYY-MM-DD"),
      reminderDate: moment
        .utc(selectedSlotDate)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      slots: startTimes,
    };
    await handleSubmit(e, params);
  };

  return (
    <Page onBack={goToPreviousScreen} className={styles["main-page"]}>
      {loader || (isLoading && <Loader />)}
      <Text size="25px" weight="500" color="#111B45">
        {appointmentDetail?.office?.name}
      </Text>
      <Text size="14px" weight="300" color="#000">
        {t("patientAppointmentMembers.reassignAppointment")}
      </Text>
      <Card className="form-wrapper">
        <div className={styles["content-part"]}>
          <div
            className={
              styles["reassign-appointment-left-part"] + " " + "c-field mb-3"
            }
          >
            <Text size="12px" weight="400" color="#79869A">
              {t("patientAppointmentMembers.SelectDoctorSchedule")}
            </Text>
            <div className="c-field">
              <Text size="13px" weight="400" color="#79869A" marginTop="30px">
                {t("patientAppointmentMembers.reassignDoctor")}
              </Text>
              <div className="custom-dropdown-only">
                <CustomDropdown
                  options={repeatOptions}
                  selectedOption={selectedDoctor}
                  selectOption={(id) => {
                    setSelectedDoctor(id);
                  }}
                  defaultValue={
                    selectedDoctor ||
                    t("patientAppointmentMembers.selectDoctor")
                  }
                />
              </div>
            </div>
            <div className={styles["new-appointment-input-box"]}>
              <Input
                className="appointment-input-box w-50"
                Title="Patient Full Name"
                Type="text"
                Placeholder={t("form.placeholder1", { field: t("Full Name") })}
                Name={"PatientFullName"}
                Value={`${appointmentDetail?.patient?.firstName} ${appointmentDetail?.patient?.lastName}`}
                Disabled={true}
              />
            </div>
            <div className={styles["new-appointment-input-box"]}>
              <Input
                className="appointment-input-box w-50"
                Title="Patient Email Address"
                Type="text"
                Placeholder={t("form.placeholder1", {
                  field: t("Email Address"),
                })}
                Name={"PatientEmailAddress"}
                Value={appointmentDetail?.patient?.emailId}
                Disabled={true}
              />
            </div>
            <div className={styles["new-appointment-input-box"]}>
              <Input
                className="appointment-input-box w-50"
                Title="Description"
                Type="text"
                Placeholder={t("form.placeholder1", {
                  field: t("Description here"),
                })}
                Name={"Description"}
                Value={appointmentDetail?.description}
                Disabled={true}
              />
            </div>
          </div>
          <div className="w-100">
            <div className={styles["book-slot"] + " " + "d-flex flex-column"}>
              <div className="schedular-calendar-box">
                <SchedulerCalendar
                  value={selectedSlotDate}
                  onChange={setSlotDate}
                  onActiveStartDateChange={onActiveStartDateChange}
                />
              </div>
              <div>
                <Text size="12px" weight="400" color="#6F7788">
                  {t("patientAppointmentMembers.reassignTimezone")}
                </Text>
                <Text size="14px" weight="600" color="#102C42" marginTop="6px">
                  {t("patientAppointmentMembers.reassignPst")}
                </Text>
              </div>

              <div
                className={styles["scheduler-calendar-timezone-separator"]}
              ></div>
              <div className={styles["book-slot-new-appointment"]}>
                <div>
                  <Text
                    color="#587E85"
                    weight="600"
                    size="16px"
                    className={styles["appointment-bookslot-heading"]}
                  >
                    {t("patientAppointmentMembers.reassignBookingTimeslot")}
                  </Text>
                </div>
                <div className={styles["notify-checkbox"] + " " + " d-flex "}>
                  <div
                    className={
                      styles["checkbox-container"] + " " + " d-flex flex-row "
                    }
                  >
                    <div
                      className={
                        styles["show-checkedbox"] + " " + styles["box1"]
                      }
                    ></div>
                    {t("patientAppointmentMembers.booked")}
                  </div>
                  <div
                    className={
                      styles["checkbox-container"] + " " + " d-flex flex-row"
                    }
                  >
                    <div
                      className={
                        styles["show-checkedbox"] + " " + styles["box2"]
                      }
                    ></div>
                    {t("patientAppointmentMembers.available")}
                  </div>
                  <div
                    className={
                      styles["checkbox-container"] + " " + "d-flex flex-row"
                    }
                  >
                    <div
                      className={
                        styles["show-checkedbox"] + " " + styles["box3"]
                      }
                    ></div>
                    {t("patientAppointmentMembers.blocked")}
                  </div>
                </div>
              </div>
            <div className={styles["book-timeslot-container"]}>
                <BookTimeslot
                  t={t}
                  officeId={officeId}
                  date={moment(selectedDate).format("YYYY-MM-DD")}
                  doctorId={selectedDoctor}
                  setSelectedSlots={setAppointmentSlots}
                  selectedSlots={appointmentSlots}
                  errors={errors}
                />
              </div>
            </div>
            <div className={styles["btn-container"] + " " + " d-flex mt-4"}>
              <button
                className="button button-round button-shadow mt-md-3 w-sm-100"
                onClick={handleReAssignSubmit}
              >
                {t("patientAppointmentMembers.reassign")}
              </button>
              <button
                className="button button-round button-border button-dark btn-mobile-link mt-md-3"
                onClick={goToPreviousScreen}
              >
                {t("patientAppointmentMembers.reassignCancelBtn")}
              </button>
              <button className="button button-round button-shadow  d-none">
                {t("patientAppointmentMembers.reassignUnblockTimeslot")}
              </button>
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
};
export default withTranslation()(ReassignAppointment);
