import React from "react";
import { withTranslation } from "react-i18next";
import StickySidebar from "../../../pages/Scheduler/components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import OfficeFilterList from "./OfficeFilterList";
import StatusCheckFitler from "./StatusFilter";
import DateFilter from "./DateFilter";
import RecallTypeList from "./RecallTypeFilter";
import styles from "../WaitingList/WaitingList.module.scss";
import DoctorFilterList from "./DoctoerFilter";

const GlobalListFilter = ({
  t,
  isSidebarActive,
  handleSidebarToggle,
  resetFilter,
  applyFilter,
  officeFilter,
  setOfficeFilter,
  statusFilter,
  setStatusFilter,
  dates,
  updateDatesValues,
  appointmentStatusList,
  recallTypeFilter,
  setRecallTypeFilter,
  isRecall = false,
  state,
  isFilterApply,
  doctorFilter,
  setDocotrFilter,
  isFromTodaysSchedule = false,
}) => {
  const handleApplyFilter = () => {
    applyFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleResetFliter = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleClose = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };
  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleResetFliter}
    >
      <span className={styles.waiting_filter}>
        {t("accountOwner.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </span>
      <Accordion
        preExpanded={["a", "b"]}
        className="filter-accordion"
        allowZeroExpanded
      >
        {!state?.officeId ? (
          <AccordionItem uuid="b">
            <AccordionItemHeading>
              <AccordionItemButton>{t("offices")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <OfficeFilterList
                t={t}
                selectedOffice={officeFilter}
                setOfficeFilter={setOfficeFilter}
              />
            </AccordionItemPanel>
          </AccordionItem>
        ) : (
          <AccordionItem uuid="b">
            <AccordionItemHeading>
              <AccordionItemButton>{t("doctors")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <DoctorFilterList
                t={t}
                selectedOffice={doctorFilter}
                setOfficeFilter={setDocotrFilter}
                officeId={state?.officeId}
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {!isFromTodaysSchedule && (
          <AccordionItem uuid="a">
            <AccordionItemHeading>
              <AccordionItemButton>
                {t("patientAppointments")}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <StatusCheckFitler
                selectedStatus={statusFilter}
                setStatusFilter={setStatusFilter}
                appointmentStatusList={appointmentStatusList}
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}
        {isRecall ? (
          <AccordionItem uuid="c">
            <AccordionItemHeading>
              <AccordionItemButton>Recall Type</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <RecallTypeList
                t={t}
                recallTypeFilter={recallTypeFilter}
                setRecallTypeFilter={setRecallTypeFilter}
                state={state}
              />
            </AccordionItemPanel>
          </AccordionItem>
        ) : (
          ""
        )}
      </Accordion>

      {!isRecall && !isFromTodaysSchedule && (
        <DateFilter t={t} dates={dates} updateDatesValues={updateDatesValues} />
      )}

      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className={"button button-round button-shadow mr-3"}
          title={t("apply")}
          onClick={handleApplyFilter}
        >
          {t("apply")}
        </button>
        <button
          className={"button button-round button-border button-dark reset-btn"}
          title={t("reset")}
          onClick={handleResetFliter}
        >
          {t("reset")}
        </button>
        <button
          className={"button button-round button-border button-dark cancel-btn"}
          onClick={handleClose}
          title={t("close")}
        >
          {t("close")}
        </button>
      </div>
    </StickySidebar>
  );
};
export default withTranslation()(GlobalListFilter);
