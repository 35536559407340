import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Text from "components/Text";
import DiscussionNotesCard from "./DiscussionNotesCard";
import { withTranslation } from "react-i18next";
import styles from "./DiscussionNotes.module.scss";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { useStaffMembers } from "repositories/discussion-notes-repository";
import { uniqBy } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId } from "utils";
import Loader from "components/Loader";
import { useOfficeDetail } from "repositories/office-repository";

const pageSize = 10;

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const DiscussionNote = ({ t, history, location }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [staffMemberList, setStaffMemberList] = useState([]);

  let { officeId } = useParams();
  officeId = parseInt(decodeId(officeId));

  const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(officeId);

  let officeName = null;
  if (location?.state?.officeName) {
    officeName = location.state.officeName;
  }

  const { isLoading, data } = useStaffMembers(
    officeId,
    pageNumber,
    pageSize,
  );

  useEffect(() => {
    if (!isLoading && data && data.items?.staff_list.length > 0) {
      setStaffMemberList(prev => uniqBy([...prev, ...data.items?.staff_list], "staffId"));
      setTotalItems(data.pagination.totalItems);
    }
  }, [isLoading, data]);

  const handleUpdateList = (staffId) => {
    setStaffMemberList((prev) =>
      prev.map((staff) => (staff.staffId === staffId ? { ...staff, ...{ assignedNoteCount: staff.assignedNoteCount + 1 } } : staff))
    );
  };

  let items = <div className={styles.gridContainer}>
    {staffMemberList.length > 0 && staffMemberList.filter(staff => staff.staffId).map((staff) => (
      <DiscussionNotesCard
        key={staff?.staffId}
        t={t}
        staff={staff}
        history={history}
        handleUpdateList={handleUpdateList}
        officeName={officeName || (officeDetail && officeDetail.name)}
      />
    ))}
  </div>;

  return (
    <Page onBack={history.goBack} title={officeName || (officeDetail && officeDetail.name)}>
      {(isLoading || isLoadingOfficeDetail) && <Loader />}
      <Text size="14px" weight="300" color="#000000">
        {t('discussionNote.discussionNote')}
      </Text>
      <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
        <InfiniteScroll
          className="w-100"
          dataLength={staffMemberList.length}
          hasMore={staffMemberList.length < totalItems}
          next={() => setPageNumber((v) => v + 1)}
        >
          {items}
        </InfiniteScroll>
      </motion.div>
    </Page>
  );
};
export default withTranslation()(DiscussionNote);
