import React, { useState } from "react";
import Text from "components/Text";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Card from "components/Card";
import alertNotification from "../../../../assets/images/alert_notification.svg";
import pinIcon from "../../../../assets/images/mir_attachment.svg";
import office from "../../../../assets/images/Offices.svg";
import styles from "../Dashboard.module.scss";
import useCardModule from "accountOwner/pages/Notes/hooks/useCardModule";
import moment from "moment";
import { withTranslation } from "react-i18next";
import DeleteModel from "accountOwner/pages/Notes/modal/DeleteModel";
import ReminderModel from "accountOwner/pages/Notes/modal/ReminderModel";
import StaffModel from "accountOwner/pages/Notes/modal/StaffModel";
import Loader from "components/Loader";

const NotesCard = ({
  t,
  myNote,
  transferredNote,
  handleTransferNote,
  history,
  handleArchive,
  isArchive,
  handleUpdateReminder,
  updateStateAfterDelete,
  handleUnArchieveNote,
  className,
  handleRedirect,
}) => {
  const {
    data: {
      noteCardDetail,
      dropdownOpen,
      isModalOpen,
      isDeleteModelOpen,
      isStaffModalOpen,
      allMembersList,
      selectedStaff,
      isStaffLoading,
      status,
    },
    methods: {
      capitalizeAllLetters,
      handleCancel,
      handleApply,
      handleStaffSelection,
      closeStaffModal,
      handleTransfer,
      toggleDropdown,
      closeModal,
      setIsDeleteModel,
      setIsModalOpen,
    },
  } = useCardModule(myNote, transferredNote, handleTransferNote, history);

  const getButtonClass = () => {
    const buttonClassMap = {
      new: styles.new,
      seen: styles.seen,
      delivered: styles.delivered,
    };
    return `${styles.notes_btn} ${buttonClassMap[status] || ""}`;
  };

  return (
    <>
      <Card className={styles.mynote_card_wrapper} onClick={handleRedirect}>
        <div className={styles.dropdown_wrapper}>
          <div className={styles.btn_date_wrapper}>
            <button className={getButtonClass()}>
              {capitalizeAllLetters(status)}
            </button>
            <div className={styles.notes_date}>
              {moment(noteCardDetail.note_date).format("MMM D, YYYY h:mm A")}
            </div>
          </div>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="interactive-element"
          >
            <DropdownToggle
              caret={false}
              tag="div"
              className="dropdown-btn px-3"
            >
              <img
                src={require("assets/images/dots-icon.svg").default}
                alt="icon"
              />
            </DropdownToggle>
            <DropdownMenu right className={styles["custom_dropdown_wrapper"]}>
              <DropdownItem className={`${styles["dropdown_list"]} p-0`}>
                <p
                  className={`${styles["dropdown_link"]} mb-0`}
                  onClick={() => setIsDeleteModel(true)}
                >
                  {t("notes.delete")}
                </p>
              </DropdownItem>
              {!isArchive && (
                <>
                  {!transferredNote && !myNote?.office?.isTempOffice && (
                    <DropdownItem className={`${styles["dropdown_list"]} p-0`}>
                      <Text
                        className={styles["dropdown_link"]}
                        onClick={() => handleTransfer(myNote?.office?.id)}
                      >
                        {t("notes.transfer")}
                      </Text>
                    </DropdownItem>
                  )}
                  <DropdownItem className={`${styles["dropdown_list"]} p-0`}>
                    <Text
                      className={styles["dropdown_link"]}
                      onClick={() =>
                        handleArchive(myNote?.id || transferredNote?.id)
                      }
                    >
                      {t("notes.archive")}
                    </Text>
                  </DropdownItem>
                </>
              )}
              {isArchive && (
                <DropdownItem className={`${styles["dropdown_list"]} p-0`}>
                  <Text
                    className={styles["dropdown_link"]}
                    onClick={() =>
                      handleUnArchieveNote(myNote?.id || transferredNote?.id)
                    }
                  >
                    {t("notes.unarchive")}
                  </Text>
                </DropdownItem>
              )}
              {!isArchive && !transferredNote && (
                <DropdownItem className={`${styles["dropdown_list"]} p-0`}>
                  <Text
                    onClick={() => {
                      if (!isArchive) {
                        setIsModalOpen(true);
                      }
                    }}
                    className={styles["dropdown_link"]}
                  >
                    {t("notes.changeReminder")}
                  </Text>
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
        <Text
          size="14px"
          weight="600"
          color="#587E85"
          marginTop="4px"
          marginBottom="7px"
        >
          {noteCardDetail?.title}
        </Text>
        <div className={styles.text_icon_wrapper}>
          <div className={styles.text_icon}>
            <img src={pinIcon} alt="download-icon" />
            {noteCardDetail?.fileLink ? (
              <Text size="12px" weight="400" color="#6F7788">
                1 Attachment
              </Text>
            ) : (
              <Text size="12px" weight="400" color="#6F7788">
                No Attachment
              </Text>
            )}
          </div>
          <div className={styles.text_icon}>
            <img src={office} alt="download-icon" />
            <Text size="12px" weight="400" color="#6F7788">
              {noteCardDetail?.officeName}
            </Text>
          </div>
        </div>
        <div className={styles.text_icon_one}>
          <img src={alertNotification} alt="download-icon" />
          <Text size="12px" weight="300" color="#EE4F4F">
            {myNote?.isReminderOn && myNote?.reminderDate ? (
              <Text size="12px" weight="400" color="#6F7788">
                {moment(noteCardDetail.reminderDate).format(
                  "MMMM D, YYYY h:mm A"
                )}
              </Text>
            ) : (
              <span className={styles["notification_text"]}>
                {t("notes.noReminderSet")}
              </span>
            )}
          </Text>
        </div>
      </Card>
      {isModalOpen && (
        <ReminderModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          noteId={myNote?.id || transferredNote?.id}
          handleUpdateReminder={handleUpdateReminder}
          reminderDate={noteCardDetail?.reminderDate}
          isReminderOn={myNote?.isReminderOn}
        />
      )}
      {isDeleteModelOpen && (
        <DeleteModel
          noteId={myNote?.id || transferredNote?.id}
          updateStateAfterDelete={updateStateAfterDelete}
          isModalOpen={isDeleteModelOpen}
          closeModal={() => setIsDeleteModel(false)}
        />
      )}
      {isStaffModalOpen && (
        <div>
          {isStaffLoading && <Loader />}
          <StaffModel
            isModalOpen={isStaffModalOpen}
            closeModal={closeStaffModal}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleStaffSelection={handleStaffSelection}
            allMembersList={allMembersList}
            selectedStaff={selectedStaff}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(NotesCard);
