import Select from "react-select";
import React from 'react'
import { withTranslation } from 'react-i18next';
import  {
    taskStatusOptions
  } from "../../../../constants";

const StatusFilter = ({ status, setStatus }) => {

    return (
        <>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={taskStatusOptions}
                value={status}
                onChange={(value) => setStatus(value)}
            />
        </>
    )
}

export default withTranslation()(StatusFilter)