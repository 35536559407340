import { useEffect, useState } from 'react';
import constants from "../../../../constants";
import { useSelector } from 'react-redux';
import { useAccountOwners } from 'repositories/scheduler-repository';
import { getStorage, setStorage } from 'utils';


const useDashBoardModule = () => {
    const profile = useSelector((state) => state.userProfile.profile);
    const cacheDashBoardFilter = constants.notes.cache.dashboardFilter;
    const cacheFilters = getStorage(cacheDashBoardFilter);
    const cachedSelectedOwnerTab = getStorage(constants.notes.cache.dashboardAccountOwnerCheck);
    const isFilterApply = cacheFilters?.offices?.length > 0 || cacheFilters?.staff?.length > 0 || cacheFilters?.agenda?.length > 0 || cacheFilters?.viewSettig?.length > 0;
    const [officeFilter, setOfficeFilter] = useState(cacheFilters?.offices || []);
    const [staffFilter, setStaffFilter] = useState(cacheFilters?.staff || []);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [appliedOfficeFilters, setAppliedOfficeFilters] = useState(cacheFilters?.offices || []);
    const [appliedStaffFilters, setAppliedStaffFilters] = useState(cacheFilters?.staff || []);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [currentDate, onDateChange] = useState(new Date());
    const [agendaTypes, setAgendaTypes] = useState(cacheFilters?.agenda || []);
    const [appliedAgendaTypes, setappliedAgendaTypes] = useState(cacheFilters?.agenda || []);
    const [viewSettig, setViewSetting] = useState(cacheFilters?.viewSettig || []);
    const [appliedViewSettig, setappliedViewSettig] = useState(cacheFilters?.viewSettig || []);
    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
    const [isAdminData ,setIsAdminData] = useState(null);
    const [isSchedulerOnly, setIsSchedulerOnly] = useState( cacheFilters?.isScheduler|| false);

    const isAccountOwner = profile?.role?.systemRole === constants.systemRoles.accountOwner;
    const { data: ownerList, loading: OwnerLoading } = useAccountOwners();
    let ownerId = isAccountOwner ? profile?.id : selectedOwner?.id;
    let ownerData = isAccountOwner ? profile : selectedOwner;

    const scrollToElement = () => {
        const element = document.getElementById("targetElement");
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      };

      useEffect (() => {
           setStorage(constants.notes.cache.isChatFromDashBoard, true);
       }, [])

    useEffect(() => {
        if (!ownerList?.length) return;
    
        let selectedOwner = cachedSelectedOwnerTab?.owner;
        const existingOwner = ownerList?.find(item => item?.id === selectedOwner?.id);
    
        const finalOwner = existingOwner || ownerList[0];
        setSelectedOwner(finalOwner);
        setStorage(constants.notes.cache.dashboardAccountOwnerCheck, { owner: finalOwner });
    }, [ownerList, cachedSelectedOwnerTab]);

    const handleApplyFilter = () => {
        scrollToElement();
        setAppliedOfficeFilters(officeFilter);
        setAppliedStaffFilters(staffFilter);
        setappliedAgendaTypes(agendaTypes);
        setappliedViewSettig(viewSettig);
        setStorage(cacheDashBoardFilter, {
            offices: officeFilter,
            staff: staffFilter,
            agenda: agendaTypes,
            viewSettig: viewSettig,
            isScheduler:isSchedulerOnly
        });
    }

    const onActiveStartDateChange = (value) => {
        if (
            constants.calanderActions.includes(value?.action) ||
            (value?.action === constants.calanderActionKey.drillDown &&
                (value?.view === "month" || value?.view === "year"))
        ) {
            value?.activeStartDate && onDateChange(value.activeStartDate);
        }
    };

    const switchNewAccountOwner = (ownerData) => {
        if (ownerData?.id) {
            setSelectedOwner(ownerData);
            setStorage(constants.notes.cache.dashboardAccountOwnerCheck, {
                owner: ownerData
            });
        setOfficeFilter([]);
        setStaffFilter([]);
        setSelectedRoles([]);
        setAgendaTypes([]);
        setViewSetting([]);
        setAppliedOfficeFilters([]);
        setAppliedStaffFilters([]);
        setappliedAgendaTypes([]);
        setappliedViewSettig([]);
        setStorage(cacheDashBoardFilter, {
            offices: [],
            staff: [],
            agenda: [],
            viewSettig: [],
            isScheduler:isSchedulerOnly
        });
        }
    }


    const resetFilter = () => {
        scrollToElement();
        setOfficeFilter([]);
        setStaffFilter([]);
        setSelectedRoles([]);
        setAgendaTypes([]);
        setViewSetting([]);
        setAppliedOfficeFilters([]);
        setAppliedStaffFilters([]);
        setappliedAgendaTypes([]);
        setappliedViewSettig([]);
        setIsSchedulerOnly(false);
        setStorage(cacheDashBoardFilter, {
            offices: [],
            staff: [],
            agenda: [],
            viewSettig: [],
            isScheduler:false
        });
    }

    const handleToggleScheduler = (e) => {
        setIsSchedulerOnly(e.target.checked)
        setOfficeFilter([]);
        setStaffFilter([]);
        setSelectedRoles([]);
        setAgendaTypes([]);
        setViewSetting([]);
        setAppliedOfficeFilters([]);
        setAppliedStaffFilters([]);
        setappliedAgendaTypes([]);
        setappliedViewSettig([]);
        setStorage(cacheDashBoardFilter, {
            offices: [],
            staff: [],
            agenda: [],
            viewSettig: [],
            isScheduler:e.target.checked
        });
    }

    const handleToggleAccountOwner = () => {
        setIsAdminModalOpen(!isAdminModalOpen);
    }
    
  let StaffDataForApi = appliedStaffFilters?.map(staff => staff?.userId);

    return {
        data: {
            officeFilter,
            staffFilter,
            agendaTypes,
            viewSettig,
            selectedRoles,
            appliedOfficeFilters,
            appliedStaffFilters : StaffDataForApi,
            appliedAgendaTypes,
            appliedViewSettig,
            ownerId,
            ownerData,
            isFilterApply,
            currentDate,
            ownerList,
            selectedOwner,
            isAdminModalOpen,
            isAccountOwner,
            isAdminData,
            isSchedulerOnly
        },
        methods: {
            setOfficeFilter,
            setStaffFilter,
            setSelectedRoles,
            setAgendaTypes,
            setViewSetting,
            handleApplyFilter,
            resetFilter,
            onDateChange,
            onActiveStartDateChange,
            switchNewAccountOwner,
            handleToggleAccountOwner,
            setIsAdminData,
            setIsSchedulerOnly,
            handleToggleScheduler
        }
    };
};

export default useDashBoardModule;