import React, { useState, useEffect } from "react";
import Empty from "components/Empty";
import Table from "components/table";
import constants from "./../../../../../../constants";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { useInvoiceEntries } from "repositories/invoice-repository";
import styles from "./../../invoices.module.scss";

/**
 * Merges invoice items based on the specified business rules
 * @param {Array} invoiceItems - Raw invoice items from API
 * @returns {Array} - Processed invoice items after applying merging logic
 */
function processInvoiceItems(invoiceItems) {
  if (!invoiceItems || invoiceItems.length === 0) {
    return [];
  }

  // Clone items to avoid mutating the original data
  const items = JSON.parse(JSON.stringify(invoiceItems));

  // Group items by month and year
  const itemsByMonth = {};

  items.forEach((item) => {
    const month = item.month;
    const year = item.year;
    const monthKey = `${year}-${month}`;

    if (!itemsByMonth[monthKey]) {
      itemsByMonth[monthKey] = [];
    }

    itemsByMonth[monthKey].push(item);
  });

  const result = [];

  // Process each month group
  Object.keys(itemsByMonth).forEach((monthKey) => {
    const monthItems = itemsByMonth[monthKey];

    if (monthItems.length <= 1) {
      // No need to merge if there's only one item for this month
      result.push(...monthItems);
      return;
    }

    // Check billing preference from the first record of this month group
    const billingPreference = monthItems[0].billingPreference;

    // Sort items by startDate for proper date range calculation
    monthItems.sort(
      (a, b) => new Date(a.periodStart) - new Date(b.periodStart)
    );

    if (billingPreference === 1) {
      // Case: Billing preference is 1, merge all items for the month
      const mergedItem = createMergedItem(monthItems);
      result.push(mergedItem);
    } else {
      // Case: Billing preference is not 1, merge by officeId
      const itemsByOfficeId = {};

      // Group items by officeId
      monthItems.forEach((item) => {
        const officeId = item.officeId || "null";

        if (!itemsByOfficeId[officeId]) {
          itemsByOfficeId[officeId] = [];
        }

        itemsByOfficeId[officeId].push(item);
      });

      // Process each officeId group
      Object.keys(itemsByOfficeId).forEach((officeId) => {
        const officeItems = itemsByOfficeId[officeId];

        if (officeItems.length > 1) {
          // Merge items with the same officeId
          const mergedItem = createMergedItem(officeItems);
          result.push(mergedItem);
        } else {
          // No need to merge if there's only one item for this officeId
          result.push(...officeItems);
        }
      });
    }
  });

  return result;
}

/**
 * Creates a merged item from multiple items
 * @param {Array} items - Array of items to merge
 * @returns {Object} - Merged item
 */
function createMergedItem(items) {
  if (!items || items.length === 0) {
    return null;
  }

  // Sort items by start date
  items.sort((a, b) => new Date(a.periodStart) - new Date(b.periodStart));

  const firstItem = items[0];
  const lastItem = items[items.length - 1];

  // Calculate total charge amount
  const totalChargeAmountInCents = items.reduce(
    (sum, item) => sum + (item.totalChargeAmountInCents || 0),
    0
  );

  // Create merged item
  const mergedItem = {
    ...firstItem,
    id: `merged-${firstItem.id}`,
    periodStart: firstItem.periodStart,
    periodEnd: lastItem.periodEnd,
    totalChargeAmountInCents: totalChargeAmountInCents,
    originalItems: items, // Store original items for popup display
    isMerged: true,
  };

  return mergedItem;
}

function PreviousInvoice({ accountOwnerId, onItemClick, t }) {
  const PAGE_SIZE = constants.pageSize;
  const [pageNumber, setPageNumber] = useState(1);
  const [processedData, setProcessedData] = useState([]);

  const {
    isLoading,
    data: apiRes,
    error,
  } = useInvoiceEntries(accountOwnerId, pageNumber, PAGE_SIZE);

  // Process and merge invoice items when API data changes
  useEffect(() => {
    if (!isLoading && apiRes?.statusCode === 200 && apiRes?.data) {
      const mergedItems = processInvoiceItems(apiRes.data);
      setProcessedData(mergedItems);
    }
  }, [isLoading, apiRes]);

  if (error || (!isLoading && apiRes?.statusCode !== 200)) {
    return null;
  }

  // Handle invoice item click
  const handleItemClick = (row) => {
    if (row.isMerged) {
      // If this is a merged item, pass the original items to the popup
      onItemClick(row.originalItems);
    } else {
      // If this is a single item, pass it directly
      onItemClick([row]);
    }
  };

  const columns = [
    {
      attrs: { datatitle: t("superAdmin.subscriptionName") },
      text: t("superAdmin.subscriptionName"),
      dataField: "packageName",
      formatter: (cellContent, row) => {
        if (isLoading)
          return <div className="text-placeholder-150 shimmer-animation"></div>;

        let displayText = cellContent + " " + t("subscription");
        if (row?.isMerged) {
          displayText = t("superAdmin.multipleSubscription");
        }

        return (
          <div
            onClick={() => handleItemClick(row)}
            style={{ cursor: "pointer" }}
          >
            {displayText}
          </div>
        );
      },
    },
    {
      attrs: { datatitle: t("superAdmin.totalSubscriptionPrice") },
      text: t("superAdmin.totalSubscriptionPrice"),
      dataField: "totalChargeAmountInCents",
      formatter: (cellContent) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return `${t("cad")} ${(cellContent / 100).toFixed(2)}`;
      },
    },
    {
      attrs: { datatitle: t("superAdmin.officeName") },
      text: t("superAdmin.officeName"),
      dataField: "officeName",
      formatter: (cellContent, row) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return ` ${cellContent ? cellContent : "--"}`;
      },
    },
    {
      attrs: { datatitle: t("superAdmin.startDate") },
      text: t("superAdmin.startDate"),
      dataField: "periodStart",
      formatter: (cellContent) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return moment(cellContent).format("MMM DD, YYYY");
      },
    },
    {
      attrs: { datatitle: t("superAdmin.expireDate") },
      text: t("superAdmin.expireDate"),
      dataField: "periodEnd",
      formatter: (cellContent) => {
        if (isLoading)
          return <div className="text-placeholder-100 shimmer-animation"></div>;

        return moment(cellContent).format("MMM DD, YYYY");
      },
    },
  ];

  let totalItems = 0;
  let rows;
  if (!isLoading) {
    totalItems = apiRes.pagination.totalItems;
    rows = processedData; // Use the processed data instead of raw API data
  } else {
    rows = new Array(PAGE_SIZE).fill({});
  }

  return (
    <>
      <div className={` ${styles.invoices_nav_tabs_wrapper} common-tabs `}>
        <Table
          columns={columns}
          data={rows}
          keyField="id"
          handlePagination={setPageNumber}
          pageNumber={pageNumber}
          pageSize={PAGE_SIZE}
          totalItems={totalItems}
        />
      </div>

      {!isLoading && totalItems === 0 && (
        <Empty Message={t("superAdmin.noInvoicesYet")} />
      )}
    </>
  );
}

export default withTranslation()(PreviousInvoice);
