import React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import Select from "react-select";
import crossIcon from "../../../../assets/images/cross.svg";
import PatientCard from "../PatientCard";
import styles from "../TaskManagement.module.scss"

const AssignStaffMembersModal = ({
  t,
  isModalOpen,
  closeModal,
  options,
  selectedOption,
  handleRequestStatusFilter,
  OptionsStaff,
  handleCheckboxChange,
  handleApplyCheck,
  handleCancelCheck,
  selectedStaff
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-dialog-centered interactive-element modal-lg"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <h2 className="title">{t("notes.selectStaffMember")}</h2>
        <div>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={options}
            value={selectedOption}
            onChange={handleRequestStatusFilter}
          />
        </div>
        <div className={styles.assignes_staff_scroll}>
          <div>
            {OptionsStaff?.map((staff) => (
              <PatientCard
                key={staff.id}
                staff={staff}
                onCheckboxChange={handleCheckboxChange}
                selectedStaff={selectedStaff}
              />
            ))}
          </div>
        </div>
        <div className="pt-4">
          <button
            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
            title="save"
            onClick={handleApplyCheck}
          >
            {t("notes.apply")}
          </button>
          <button
            className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
            title="cancel"
            onClick={handleCancelCheck}
          >
            {t("notes.cancel")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(AssignStaffMembersModal);
