import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ToggleButton from "../components/ToggleButton";
import styles from "../Dashboard.module.scss";
import MyTasks from "./MyTask";
import { useSelector } from "react-redux";
import StaffTasks from "./StaffTasks";

const AdminTask = ({
  isArchive,
  appliedOfficeFilters,
  appliedStaffFilters,
  selectedOwner,
  handleRedirect,
  isSchedulerOnly
}) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const [activeToggle, setActiveToggle] = useState(1);

  const handleToggleChange = (toggle) => {
    setActiveToggle(toggle);
  };

  return (
    <>
      {profile?.isAdmin ? (
        <>
          <ToggleButton
            activeToggle={activeToggle}
            setActiveToggle={handleToggleChange}
            firstText="ASSIGNED TO YOU"
            secondText="ASSIGNED BY YOU"
          />
          {activeToggle === 1 && (
            <div className={styles.task_card_container} id="scrollableDivTwo">
              <StaffTasks
                isArchive={isArchive}
                appliedOfficeFilters={appliedOfficeFilters}
                appliedStaffFilters={appliedStaffFilters}
                handleRedirect={handleRedirect}
                isSchedulerOnly={isSchedulerOnly}
              />
            </div>
          )}
          {activeToggle === 2 && (
            <div className={styles.task_card_container} id="scrollableDivTwo">
              <MyTasks
                isArchive={isArchive}
                appliedOfficeFilters={appliedOfficeFilters}
                appliedStaffFilters={appliedStaffFilters}
                selectedOwner={selectedOwner}
                handleRedirect={handleRedirect}
                isSchedulerOnly={isSchedulerOnly}
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.task_card_container_staff} id="scrollableDivTwo">
          <StaffTasks
            isArchive={isArchive}
            appliedOfficeFilters={appliedOfficeFilters}
            appliedStaffFilters={appliedStaffFilters}
            handleRedirect={handleRedirect}
          />
        </div>
      )}
    </>
  );
};
export default withTranslation()(AdminTask);
