import React from 'react';
import Card from 'components/Card';
import Text from 'components/Text';
import styles from "../Notices.module.scss";
import moment from 'moment';

export default function NoticesCard({ t, notice, handleOwnerCardClick}) {

    return (
        <Card className={styles.notices_card}>
                <Text size="16px" weight="600" color="#587E85" marginBottom="16px">
                    {`${notice?.user?.firstName} ${notice?.user?.lastName}`}
                </Text>
          
            <div className={styles.title_date_grid}>
                <div className={styles.title_date_wrapper}>
                    <Text size="12px" weight="400" color="#6F7788">{t("notices.receivedOn")}</Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {moment.utc(notice.sentAt).local().format('MMMM D, YYYY h:mm A')}
                        </Text>
                </div>
                <div className={styles.title_date_wrapper}>
                    <Text size="12px" weight="400" color="#6F7788">{t("notices.sentBy")}</Text>
                    <Text size="14px" weight="600" color="#102C42">
                    {`${notice?.sender?.firstName} ${notice?.sender?.lastName}`}
                        </Text>
                </div>
            </div>
            
                <p onClick={(e) => handleOwnerCardClick(e, notice?.id)} className='link-btn mt-3 mb-0'>{t("notices.viewDetails")}</p>
          
        </Card>
    );
}
