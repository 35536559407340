import React from "react";
import { withTranslation } from "react-i18next";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import styles from "../Appointments.module.scss";
import "../Appointments.scss";
import Table from "components/table";
import constants from "./../../../../constants";
import useCommonModule from "../hooks/useCommonModule";
import { formatUTCDateTime } from "utils";
import Empty from "components/Empty";
import SearchIcon from "../../../../assets/images/search.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import FeedBackModal from "../modal/FeedBackModal";
import AlertRed from "../../../../assets/images/alert_red.svg";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const PAGE_SIZE = constants.pageSize;

const PastAppointmentTable = ({
  t,
  appointments,
  totalItems,
  pageNumber,
  setPageNumber,
  searchValue,
  handleInputChange,
  state,
  isFrompatientDetail = false,
  addReviewComment,
}) => {
  const history = useHistory();
  let location = useLocation();
  const {
    data: { appointmentDetail, feedbackModalOpen },
    methods: {
      getStatus,
      getStatusClass,
      getMenuoptions,
      handleRedirectPatientAppointDetail,
      setFeedbackModalOpen,
      getPatientMenuoptions,
    },
  } = useCommonModule(history, t, state, isFrompatientDetail);

  const columns = [
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.patientName"
        ),
      },
      dataField: "patientName",
      text: t("patientAppointmentMembers.appointmentTableHeading.patientName"),
      formatter: (cellContent, row) => (
        <div
          className={styles["appointment-table-row-main-link"]}
          onClick={(e) => handleRedirectPatientAppointDetail(e, row)}
        >
          <span
            className={styles["appointment-table-patient-name"]}
          >{`${row?.patient?.firstName} ${row?.patient?.lastName}`}</span>
        </div>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.office"
        ),
      },
      dataField: "office",
      text: state?.officeId
        ? "Doctor Name"
        : t("patientAppointmentMembers.appointmentTableHeading.office"),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {state?.officeId
            ? `${row?.doctor?.firstName} ${row?.doctor?.lastName}`
            : row?.office?.name}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentDate"
        ),
      },
      dataField: "appointmentDate",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentDate"
      ),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {formatUTCDateTime(row?.appointmentDate)}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentTime"
        ),
      },
      dataField: "appointmentTime",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentTime"
      ),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.appointmentTime?.length > 1
            ? `${row?.appointmentTime[0]} - ${row?.appointmentTime[row?.appointmentTime?.length - 1]
            }`
            : row?.appointmentTime[0]}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentType"
        ),
      },
      dataField: "appointmentType",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentType"
      ),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.appointmentType?.title}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.bookedFor"
        ),
      },
      dataField: "bookedFor",
      text: t("patientAppointmentMembers.appointmentTableHeading.bookedFor"),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {`${row?.bookedByUser?.firstName} ${row?.bookedByUser?.lastName}`}
        </span>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.status"
        ),
      },
      dataField: "status",
      text: t("patientAppointmentMembers.appointmentTableHeading.status"),
      formatter: (cellContent, row) => (
        <div className={styles["table-status-box"]}>
          <Button
            className={`appointment-table-custom-btn ${getStatusClass(
              row.status
            )}`}
          >
            <span>{getStatus(row.status)}</span>
          </Button>
          {row?.userCovidResponseId ? (
            <span className={styles["red-alert-box"]}>
              <img
                src={AlertRed}
                alt="alert"
                className={styles["red-alert-icon"]}
              />
            </span>
          ) : null}
        </div>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.action"
        ),
      },
      dataField: "abc",
      text: t("patientAppointmentMembers.appointmentTableHeading.action"),
      formatter: (cellContent, row) => {
        const isCreateTemplate = location?.pathname.includes("patient-detail");
        const menuOptions = isCreateTemplate
          ? getPatientMenuoptions(row)
          : getMenuoptions(row);
        return (
          <>
            {row?.status == 5 || isCreateTemplate ? (
              <UncontrolledDropdown>
                <DropdownToggle
                  caret={false}
                  tag="div"
                  className="cursor-pointer"
                >
                  <ThreeDotsVertical />
                </DropdownToggle>
                <DropdownMenu right className={styles["office-dropdown-menu"]}>
                  {menuOptions.map((option, index) => (
                    <DropdownItem
                      key={index}
                      className={styles["office-dropdown-item"]}
                    >
                      <p
                        className="m-0"
                        onClick={(e) => option?.onclick(e, row)}
                      >
                        {option?.text}
                      </p>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className={styles["appointment-table-box"] + " " + "w-100"}>
      {!isFrompatientDetail && (
        <div
          class={
            styles["appointment-search-box"] +
            " " +
            "search-box float-right mb-4"
          }
        >
          <input
            type="text"
            placeholder="Search for tags, events title"
            value={searchValue}
            onChange={handleInputChange}
          />
          <span class="ico">
            <img src={SearchIcon} alt="icon" />
          </span>
        </div>
      )}
      {appointments?.length === 0 ? (
        <div className={styles["not_found"]}>
          <Empty Message={t("waitingList.notFound")} />
        </div>
      ) : (
        <div className="test-table-box">
          <Table
            keyField="id"
            data={appointments}
            columns={columns}
            bordered={false}
            handlePagination={setPageNumber}
            pageNumber={pageNumber}
            totalItems={totalItems}
            pageSize={PAGE_SIZE}
          />
        </div>
      )}
      {feedbackModalOpen && (
        <FeedBackModal
          t={t}
          setModalOpen={setFeedbackModalOpen}
          modalOpen={feedbackModalOpen}
          appointmentDetail={appointmentDetail}
          addReviewComment={addReviewComment}
        />
      )}
    </div>
  );
};
export default withTranslation()(PastAppointmentTable);
