import Card from "components/Card";
import Input from "components/Input";
import Text from "components/Text";
import CustomDropdown from "components/Dropdown";
import ToggleSwitch from "components/ToggleSwitch";
import styles from "../../Notes.module.scss";
import ReactDatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import { Portal } from "react-overlays";
import TimePicker from "rc-time-picker";
import moment from "moment";
import DeleteIcon from "../../../../../assets/images/delete-red.svg";

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");
  return <Portal container={el}>{children}</Portal>;
};

function Form({
  t,
  setIsModalOpen,
  formData,
  toggleOn,
  dates,
  startDatePickerRef,
  selectOffice,
  officeList,
  handleSubmit,
  handleOnChange,
  handleToggle,
  updateDatesValues,
  setOffice,
  fileName,
  handleFileChange,
  handleTimer,
  errors,
  onBack,
  setFileName,
  isVirtualOffice,
  confirmedStaff,
  isModal,
}) {
  return (
    <form onSubmit={handleSubmit} className="w-100">
      <Card className={!isModal ? styles.add_note_card : "p-0"}>
        <div className={styles["add_note_form_wrapper"]}>
          <Input
            Title={t("notes.title")}
            Type="text"
            Placeholder="E.g. Routine Visit"
            Name={"title"}
            HandleChange={handleOnChange}
            Error={errors.title}
            Value={formData?.title}
            MaxLength="35"
          />

          <div className="c-field">
            <label> {t("notes.noteDescription")}</label>
            <textarea
              className={styles["height60"] + " " + "c-form-control"}
              placeholder="Enter"
              name="description"
              maxLength="400"
              onChange={handleOnChange}
              value={formData?.description}
            />
            {errors.description && (
              <span className="error-msg">{errors.description}</span>
            )}
          </div>
          <div className="row">
            <div className="d-flex align-items-center">
              <ToggleSwitch
                className="mt-0"
                label="bill-me-later-toggle"
                onChange={handleToggle}
              />
              <Text
                size="14px"
                marginBottom="0px"
                weight="600"
                className="mr-2 mt-2"
                color="#2F3245"
              >
                {t("notes.reminder")}
              </Text>
            </div>
          </div>
          {toggleOn && (
            <div className={styles["addnote_calendar_box"] + " w-100"}>
              <div className="c-field mb-0">
                <Text size="13px" weight="400" color="#79869A">
                  {t("notes.date")}
                </Text>
                <div className="d-flex inputdate">
                  <ReactDatePicker
                    dateFormat="dd-MM-yyyy"
                    className="c-form-control"
                    selected={dates.from}
                    onChange={(e) => updateDatesValues(e, "from")}
                    maxDate={dates.to}
                    popperPlacement="bottom-start"
                    popperContainer={CalendarContainer}
                    ref={startDatePickerRef}
                    minDate={moment().toDate()}
                  />
                </div>
                {errors.dates && (
                  <span className="error-msg">{errors.dates}</span>
                )}
              </div>
              <div className={styles["datpicker_width"] + " " + "mb-0"}>
                <Text size="13px" weight="400" color="#79869A">
                  {t("notes.time")}
                </Text>
                <TimePicker
                  showSecond={false}
                  placeholder="--"
                  format="h:mm A"
                  use12Hours
                  onChange={handleTimer}
                  className={"busy-slot-time-picker"}
                  value={formData?.startTime}
                  minuteStep={5}
                />
                {errors.startTime && (
                  <span className="error-msg">{errors.startTime}</span>
                )}
              </div>
            </div>
          )}

          <div className="c-field">
            <Text size="13px" weight="400" color="#79869A" marginTop="30px">
              {t("notes.selectOffice")}
            </Text>
            <div className="custom-dropdown-only">
              <CustomDropdown
                options={officeList}
                selectedOption={selectOffice}
                selectOption={(id) => {
                  setOffice(id);
                }}
                defaultValue={t("notes.noOfficeSelected")}
              />
            </div>
            {errors.selectOffice && (
              <span className="error-msg">{errors.selectOffice}</span>
            )}
          </div>
          <div className={styles["change_staff"] + " " + "c-field"}>
            <div className={styles["add_note_radio_btn_wrapper"]}>
              <div>
                <Text
                  size="13px"
                  weight="400"
                  color="#79869A"
                  marginBottom="10px"
                >
                  {t("notes.assignNoteTo")}
                </Text>
                <div
                  className={styles["add_note_radio_btn"] + " " + "ch-radio"}
                >
                  <label className="mr-5">
                    <input
                      type="radio"
                      name="assignType"
                      checked={formData?.assignType === "self"}
                      onChange={handleOnChange}
                      value={"self"}
                    />
                    <span> {t("notes.mySelf")} </span>
                  </label>
                  {!isVirtualOffice && (
                    <label className="pb-0">
                      <input
                        type="radio"
                        name="assignType"
                        checked={formData?.assignType === "staff"}
                        onChange={handleOnChange}
                        value={"staff"}
                      />
                      <span>{t("notes.toStaff")}</span>
                    </label>
                  )}
                </div>
              </div>
              {!isVirtualOffice && (
                <p
                  className={`${
                    formData?.assignType === "self" || !selectOffice
                      ? `${styles["disable_opacity"]} disabled link-btn`
                      : "link-btn"
                  }`}
                  onClick={() => {
                    if (selectOffice && formData?.assignType === "staff") {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {!confirmedStaff?.name
                    ? t("notes.selectStaff")
                    : t("notes.change")}
                </p>
              )}
            </div>
            {formData?.assignType === "staff" && (
              <div className={styles["changed_staff_name_wrapper"]}>
                <Text
                  size="13px"
                  weight="400"
                  color="#79869A"
                  marginBottom="10px"
                >
                  {t("notes.selectedStaff")}
                </Text>
                <div className={styles["changed_staff_name"]}>
                  {confirmedStaff?.name || t("notes.noStaff")}
                </div>
              </div>
            )}
          </div>
          <input
            name="uploadFile"
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <div className={styles["heading-data-Wrapper"]}>
            <div className={styles["head-link-wrapper"]}>
              <div className={styles["appointment-head"]}>
                {t("notes.attachment")}
              </div>
              <label htmlFor="fileInput" className={styles["appointment-link"]}>
                {t("notes.uploadFile")}
              </label>
            </div>
            <div className={styles["file_upload_wrapper"]}>
              <div>
                {!fileName?.file?.name ? (
                  <div className={styles["appointment-text"]}>
                    {t("notes.noAttachments")}
                  </div>
                ) : (
                  fileName?.file?.name
                )}
              </div>

              {fileName?.file?.name ? (
                <img
                  src={DeleteIcon}
                  alt="DeleteIcon"
                  className={styles["trash_image"]}
                  onClick={() => setFileName({ path: "", file: null })}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className={styles["main_btn_container"] + " " + "btn-box d-md-flex"}
          >
            <button
              className="button button-round button-shadow w-sm-100"
              title="add"
              type="submit"
            >
              {t("notes.addNote")}
            </button>
            <button
              className="button button-round button-border btn-mobile-link button-dark"
              title="cancel"
              onClick={() => onBack()}
            >
              {t("notes.cancel")}
            </button>
          </div>
        </div>
      </Card>
    </form>
  );
}

export default withTranslation()(Form);
