import React, { Suspense } from "react";
import Card from "components/Card";
import Page from "components/Page";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Loader from "components/Loader";
import MyNotes from "./component/MyNotes";
import TransferredNotes from "./component/TransferredNotes";
import { Link } from "react-router-dom";
import styles from "./Notes.module.scss";
import { withTranslation } from "react-i18next";
import constants from "../../../constants";
import { getStorage, setStorage } from "utils";

const Notes = ({ t, history }) => {
  const cachedActiveTab = getStorage(constants.notes.cache.Noteslisting);
  const cacheActiveArchived = getStorage(constants.notes.cache.archivedListing);
  const isChatFromDashBoard = getStorage("isChatFromDashBoard");

  const [activeTab, setActiveTab] = useState(
    cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1
  );
  const [isArchive, setArchive] = useState(
    cacheActiveArchived?.isArchived ? cacheActiveArchived.isArchived : false
  );

  const tabName = constants.notesTabStatus;

  const goToPreviousScreen = () => {
    if(isChatFromDashBoard) {
      history.push(constants.routes.dashboard.managerDashboard);
    } else {
         history.push("/");
    }
  };

  const manageTab = (tab) => {
    window.scrollTo(0, 0);
    setActiveTab(tab);
    setStorage(constants.notes.cache.Noteslisting, {
      activeTab: tab,
    });
  };

  return (
    <Page
      onBack={goToPreviousScreen}
      className={styles["notes_main_parent_wrapper"] + " " + "scheduler-page"}
    >
      <div className={styles["notes_heading_btn_wrapper"]}>
        <h1 className={styles["modal_heading"] + " " + "m-0"}>
          {!isArchive ? t("notes.onGoingNote") : t("notes.archiveNote")}
        </h1>
        <div
          className={
            styles["main_btn_container"] + " " + "btn-box d-md-flex  mt-0"
          }
        >
          <button
            className="button button-round button-border button-dark w-sm-100"
            onClick={() => {
              setArchive(!isArchive);
              setStorage(constants.notes.cache.archivedListing, {
                isArchived: !isArchive,
              });
            }}
          >
            {!isArchive ? t("notes.archivedNotes") : t("notes.onGoingNotes")}
          </button>
          <Link
            to={`/addNote`}
            className="button button-round button-shadow w-sm-100"
            onClick={() => {
              setStorage(constants.notes.cache.Noteslisting, {
                activeTab: 1,
              });
            }}
          >
            {t("notes.addNewNote")}
          </Link>
        </div>
      </div>
      <Card className={styles["notes_main_card"]}>
        <div className="common-tabs scheduler-tabs">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab == tabName.myNote ? "active" : ""}
                onClick={() => manageTab(tabName.myNote)}
              >
                {t("notes.myNotes")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              className={activeTab == tabName.transfferedNote ? "active" : ""}
              onClick={() => manageTab(tabName.transfferedNote)}
              >{t("notes.transferredNotes")}</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <Suspense fallback={<Loader />}>
              <TabPane tabId={1}>
                {activeTab === tabName.myNote && (
                  <MyNotes
                    status={activeTab}
                    isArchive={isArchive}
                    manageTab={manageTab}
                    tabName={tabName}
                    history={history}
                  />
                )}
              </TabPane>
              <TabPane tabId={2}>
                {activeTab === tabName.transfferedNote && (
                  <TransferredNotes
                    status={activeTab}
                    isArchive={isArchive}
                    history={history}
                  />
                )}
              </TabPane>
            </Suspense>
          </TabContent>
        </div>
      </Card>
    </Page>
  );
};
export default withTranslation()(Notes);
