import React from "react";
import styles from "./TimesheetReportHeader.module.scss";
import Button from "components/Button";
import CustomSortDropdown from "components/CustomSortDropdown";

export default function TimesheetReportHeader({
  t,
  handleNext,
  handlePrevious,
  goToCurrentDate,
  showCurrentDateButton,
  sortByList,
  setSelectedSort,
  selectedSort,
}) {
  return (
    <div className={styles["staff-availability-header"]}>
      <ul className={styles["view-color-list"]}>
        <li>
          <div className={styles["color-box"] + " " + styles["color-green"]} />
          <div className={styles["text-box"]}>
            {t("staffTimesheet.currentDay")}
          </div>
        </li>
        <li>
          <div className={styles["color-box"] + " " + styles["color-pink"]} />
          <div className={styles["text-box"]}>
            {t("staffTimesheet.holiday")}
          </div>
        </li>
        <li>
          <div className={styles["color-box"] + " " + styles["color-yellow"]} />
          <div className={styles["text-box"]}>{t("staffTimesheet.leaves")}</div>
        </li>
        <li>
          <div className={styles["color-box"] + " " + styles["color-orange"]} />
          <div className={styles["text-box"]}>
            {t("staffTimesheet.pendingForApproval")}
          </div>
        </li>
        <li>
          <div className={styles["color-box"] + " " + styles["color-grey"]} />
          <div className={styles["text-box"]}>
            {t("staffTimesheet.approved")}
          </div>
        </li>
        <li>
          <div className={styles["color-box"] + " " + styles["rejected"]} />
          <div className={styles["text-box"]}>
            {t("staffTimesheet.rejected")}
          </div>
        </li>
        <li>
          <div
            className={styles["color-box"] + " " + styles["color-parrot-green"]}
          />
          <div className={styles["text-box"]}>{t("staffTimesheet.paid")}</div>
        </li>
      </ul>
      <div className={styles.btn_container}>
        <CustomSortDropdown
          setSortBy={setSelectedSort}
          sortBy={selectedSort}
          sortByList={sortByList}
        />

        {showCurrentDateButton && (
          <button
            className={styles.link + " " + "link-btn"}
            onClick={goToCurrentDate}
          >
            {t("staffTimesheet.goToCurrentDate")}
          </button>
        )}
        <div className={styles.prev_next_container}>
          <Button borderedSecondary height="36px" onClick={handlePrevious}>
            {t("staffTimesheet.previous")}
          </Button>
          <Button borderedSecondary height="36px" onClick={handleNext}>
            {t("staffTimesheet.next")}
          </Button>
        </div>
      </div>
    </div>
  );
}
