import React, { useState } from 'react';
import { Modal, ModalBody } from "reactstrap";
import styles from "../../Appointments/Appointments.module.scss"
import { unblockRepeatDateSlot, unblockSelectedDateSlot } from 'repositories/patient-appointment-repository';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import Loader from 'components/Loader';
import moment from 'moment';


const UnBlockTimeSlot = ({ t, setUnBlocked, unBlocked, officeId, selectedSlotDate, doctorId, selectedSlots, setSelectedSlots, setBlockReason }) => {
    const [radioState, setRadioState] = useState({
        blockOnly: true,
        repeatday: false,
        repeatEveryday: false,
    });
    
    const { mutate: unBlockSelectedDate, isLoading: unblockSelectedDateLoading } = useMutation((data) => unblockSelectedDateSlot(data));
    const { mutate: unBlockRepeatDate, isLoading: unblockRepeatDateSlotLoading } = useMutation((data) => unblockRepeatDateSlot(data));


    const handleRadioChange = (e) => {
        const { name, checked } = e.target;
        setRadioState({
            blockOnly: false,
            repeatday: false,
            repeatEveryday: false,
            [name]: checked,
        });
    };


    const handleClick = () => {
        const startTimes = selectedSlots?.filter(app => app.startTime)?.map(app => app.startTime);
        let date = moment(selectedSlotDate).format('YYYY-MM-DD')
        const Params = {
            officeId: officeId,
            date: date,
            doctorId: doctorId,
            slots: startTimes,
        }
        if (radioState.blockOnly) {
            unBlockSelectedDate(Params, {
                onSuccess: (res) => {
                    setSelectedSlots([]);
                    setBlockReason("");
                    toast.success(res.message);
                    setUnBlocked(false)
                },
                onError: (err) => {
                    toast.error(err.message);
                },
                onSettled: () => {
                    setUnBlocked(false);
                }
            });
        } else {
            let day = radioState.repeatday ? moment(selectedSlotDate)?.day() : null;
            Params.day = day;
            unBlockRepeatDate(Params, {
                onSuccess: (res) => {
                    setSelectedSlots([]);
                    setBlockReason("");
                    toast.success(res.message);
                    setUnBlocked(false)
                }
            });
        }
    }

    let loader = unblockSelectedDateLoading || unblockRepeatDateSlotLoading

    return (
        <Modal toggle={() => setUnBlocked(!unBlocked)} isOpen={unBlocked} className="modal-dialog-centered deactivate-modal modal-width-660">
             {loader && <Loader />}
            <div className={styles['timeslot-modal']}>
                <ModalBody className={styles[' modal-content-part '] + " " + "d-flex flex-column p-0"}>
                    <div>
                        <span className="close-btn d-flex justify-content-end" onClick={() => setUnBlocked(!unBlocked)}>
                            <img src={require('assets/images/cross.svg').default} alt="close" />
                        </span>
                        <div>
                            <h3 className='m-0'>{t('patientAppointmentMembers.reassignUnblockTimeslot')}</h3>
                        </div>
                    </div>
                    <div className='select-reason ch-radio d-flex flex-column mt-2'>
                        <label>
                            <input
                                type="radio"
                                name="blockOnly"
                                checked={radioState.blockOnly}
                                onChange={handleRadioChange}
                            />
                            <span> {t('patientAppointmentMembers.unblockSelectedDate')} </span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="repeatday"
                                checked={radioState.repeatday}
                                onChange={handleRadioChange}
                            />
                            <span>{t('patientAppointmentMembers.repeatUnblock')} {moment(selectedSlotDate).format('dddd')}</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="repeatEveryday"
                                checked={radioState.repeatEveryday}
                                onChange={handleRadioChange}
                            />
                            <span>{t('patientAppointmentMembers.repeatUnblockforAll')}</span>
                        </label>
                    </div>
                    <div className={styles.btn__container}>
                        <button className='button button-round button-shadow  w-sm-100' onClick={handleClick}> {t('patientAppointmentMembers.submit')} </button>
                        <button className='button button-round button-border button-dark btn-mobile-link' onClick={() => setUnBlocked(!unBlocked)}> {t('patientAppointmentMembers.cancel')} </button>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default UnBlockTimeSlot