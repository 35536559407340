import Text from "components/Text";
import React from "react";
import NotificationIcon from "../../../../assets/images/bordered_notification.svg";
import styles from "../Dashboard.module.scss";

export default function NotificationCard({ title, formatedDate, onClick }) {
  return (
    <div className={styles.notificard_wrapper}>
      <img src={NotificationIcon} alt="invitation icon" />
      <div>
        <Text size="16px" weight="400" color="#535B5F" lineHeight="24px">
          {title}
        </Text>
        <Text
          size="12px"
          weight="400"
          color="#6F7788"
          lineHeight="12px"
          marginTop="3px"
          marginBottom="6px"
        >
          {formatedDate}
        </Text>
        <button className="link-btn" onClick={onClick}>View Details</button>
      </div>
    </div>
  );
}
