import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Card from "components/Card";
import Input from "components/Input";
import Text from "components/Text";
import CustomDropdown from "components/Dropdown";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import { withTranslation } from "react-i18next";
import AssignStaffMembersModal from "../Modals/AssignStaffMembersModal";
import { useAddTaskController } from "../hooks/useAddTaskController";
import Loader from "components/Loader";
import DeleteIcon from "../../../../assets/images/delete-red.svg";
import "../TaskManagement.scss";
import styles from "../TaskManagement.module.scss";

const AddNewNote = ({ t, history, isInsideModal }) => {
  let addTask = true;
  const {
    data: {
      isModalOpen,
      isAnyLoading,
      formData,
      priority,
      prioritiesOptions,
      dates,
      errors,
      OptionsStaff,
      fileName,
      staffSelectedCount,
      selectedOption,
      selectedStaff,
      taskId,
      taskDetail,
    },
    methods: {
      handleOnChange,
      setPriority,
      updateDatesValues,
      handleSubmit,
      handleToggle,
      handleApplyCheck,
      handleCheckboxChange,
      handleFileChange,
      handleCancelCheck,
      onBack,
      setSelectedOption,
      setFileName,
    },
  } = useAddTaskController(t, history, addTask);
  const [OfficeOptions, setOfficeOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);

  const startDatePickerRef = useReadOnlyDateTextInput();

  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };

  const ConverOptions = (staffOptions) => {
    let newOtion = { value: "all_offices", label: "All Offices" };
    const uniqueLabels = new Set();
    let uniqueOffice = staffOptions
      .filter((office) => {
        if (uniqueLabels.has(office?.officeId)) {
          return false;
        }
        uniqueLabels.add(office?.officeId);
        return true;
      })
      .map((office) => ({ value: office?.officeId, label: office.officeName }));
    let updateUniqueOffice = [newOtion, ...uniqueOffice];
    setOfficeOptions(updateUniqueOffice);
  };

  useEffect(() => {
    setStaffOptions(OptionsStaff);
  }, [OptionsStaff]);

  useEffect(() => {
    ConverOptions(OptionsStaff);
  }, [isModalOpen, OptionsStaff]);

  const handleRequestStatusFilter = (selectedOffice) => {
    setSelectedOption(selectedOffice);
    if (selectedOffice?.value === "all_offices") {
      let FilterData = OptionsStaff?.filter((office) => office);
      setStaffOptions(FilterData);
    } else {
      let FilterData = OptionsStaff?.filter(
        (office) => office?.officeId === selectedOffice.value
      );
      setStaffOptions(FilterData);
    }
  };

  const getMemberText = (count) => {
    if (count === 0) {
      return t("taskManagement.noMember");
    }
    return `${count} ${
      count === 1 ? t("taskManagement.member") : t("taskManagement.member")
    }`;
  };

  return (
    <Page onBack={onBack}>
      {isAnyLoading && <Loader />}
      <h2 className="title">
        {taskId
          ? t("taskManagement.editNewTask")
          : t("taskManagement.addNewTask")}
      </h2>
      <form onSubmit={handleSubmit} className="w-100 mt-4">
        <Card
          className={!isInsideModal ? styles.add_new_note_card_wrapper : "p-0"}
        >
          <div className={styles["add_new_note_parent_wrapper"]}>
            <Input
              Title={t("title")}
              Type="text"
              Placeholder={t("taskManagement.enterTitle")}
              Name={"title"}
              HandleChange={handleOnChange}
              Error={errors.title}
              Value={formData?.title}
              MaxLength="35"
            />
            <div className="c-field">
              <label>{t("taskManagement.taskDescrition")}</label>
              <textarea
                className="c-form-control"
                Placeholder={t("taskManagement.enterDescription")}
                name="description"
                maxLength="400"
                onChange={handleOnChange}
                error={errors.description}
                value={formData?.description}
              ></textarea>
              {errors.description && (
                <span className="error-msg">{errors.description}</span>
              )}
            </div>
            <div className={styles["date_wrapper"] + " " + "c-field mb-0"}>
              <div className={styles.new_inputdate + " " + "inputdate"}>
                <Text size="13px" weight="400" color="#79869A">
                  {t("superAdminVendorManagement.startDate")}
                </Text>
                <ReactDatePicker
                  dateFormat="dd-MM-yyyy"
                  className="c-form-control"
                  selected={dates.startDate}
                  onChange={(e) => updateDatesValues(e, "startDate")}
                  popperPlacement="bottom-start"
                  popperContainer={CalendarContainer}
                  ref={startDatePickerRef}
                  openToDate={moment().toDate()}
                  minDate={moment().toDate()}
                  maxDate={!taskId && dates?.DueDate}
                />
                {errors.startDate && (
                  <span className="error-msg">{errors.startDate}</span>
                )}
              </div>
              <div className={styles.new_inputdate + " " + "inputdate"}>
                <Text size="13px" weight="400" color="#79869A">
                  {t("taskManagement.dueDate")}
                </Text>
                <ReactDatePicker
                  dateFormat="dd-MM-yyyy"
                  className="c-form-control"
                  selected={dates.DueDate}
                  onChange={(e) => updateDatesValues(e, "DueDate")}
                  popperPlacement="bottom-start"
                  popperContainer={CalendarContainer}
                  openToDate={moment().toDate()}
                  ref={startDatePickerRef}
                  minDate={taskId ? moment().toDate() : dates?.startDate}
                />
                {errors.DueDate && (
                  <span className="error-msg">{errors.DueDate}</span>
                )}
              </div>
            </div>
            <div className="c-field">
              <Text size="13px" weight="400" color="#79869A" marginTop="30px">
                {t("taskManagement.priority")}
              </Text>
              <div className="custom-dropdown-only">
                <CustomDropdown
                  options={prioritiesOptions}
                  selectedOption={priority}
                  selectOption={(id) => {
                    setPriority(id);
                  }}
                />
              </div>
            </div>
            <div className={styles["assiness_input"]}>
              <div className="d-flex justify-content-between">
                <Text size="13px" weight="400" color="#79869A">
                  {t("taskManagement.assignees")}
                </Text>
                <p
                  className="link-btn m-0"
                  onClick={() => {
                    handleToggle();
                  }}
                >
                  {staffSelectedCount == 0
                    ? t("taskManagement.assginStaffMemeber")
                    : t("taskManagement.add")}
                </p>
              </div>
              <Text size="14px" weight="600" color="#102C42" marginTop="8px">
                {getMemberText(staffSelectedCount)}
              </Text>
            </div>
            <input
              name="uploadFile"
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div className={styles["heading-data-Wrapper"]}>
              <div className={styles["head-link-wrapper"]}>
                <div className={styles["appointment-head"]}>
                  {t("notes.attachment")}
                </div>
                <label
                  htmlFor="fileInput"
                  className={styles["appointment-link"]}
                >
                  {t("notes.uploadFile")}
                </label>
              </div>
              <div className={styles["file_upload_wrapper"]}>
                <div>
                  {!fileName?.file?.name && !taskDetail?.filePath ? (
                    <div className={styles["appointment-text"]}>
                      {t("notes.noAttachments")}
                    </div>
                  ) : (
                    fileName?.file?.name || taskDetail?.filePath
                  )}
                </div>

                {fileName?.file?.name ? (
                  <img
                    src={DeleteIcon}
                    alt="DeleteIcon"
                    className={styles["trash_image"]}
                    onClick={() => setFileName({ path: "", file: null })}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className={styles["checkbox_wrapper_one"]}>
              <div
                className={
                  styles["check_box_width"] +
                  " " +
                  "ch-checkbox c-field all-event-checkbox m-0"
                }
              >
                <label>
                  <input
                    type="checkbox"
                    name="notifyAssignees"
                    checked={formData?.notifyAssignees}
                    onChange={handleOnChange}
                  />
                  <span>{t("taskManagement.notifyAssigneesabouttheTask")}</span>
                </label>
              </div>
              <div
                className={
                  styles["check_box_width"] +
                  " " +
                  "ch-checkbox c-field all-event-checkbox m-0"
                }
              >
                <label>
                  <input
                    type="checkbox"
                    name="notifyAssignor"
                    checked={formData?.notifyAssignor}
                    onChange={handleOnChange}
                  />
                  <span>
                    {t("taskManagement.notifyAssigneesabouttheUpdates")}
                  </span>
                </label>
              </div>
            </div>
            <div className={styles["btn_wrapper"]}>
              <button
                className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                title="save"
                type="submit"
              >
                {taskId
                  ? t("taskManagement.update")
                  : t("taskManagement.addNewTask")}
              </button>
              <button
                className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
                title="cancel"
                onClick={() => onBack()}
              >
                {t("taskManagement.cancel")}
              </button>
            </div>
          </div>
        </Card>
      </form>
      {isModalOpen && (
        <AssignStaffMembersModal
          isModalOpen={isModalOpen}
          closeModal={handleToggle}
          options={OfficeOptions}
          selectedOption={selectedOption}
          handleRequestStatusFilter={handleRequestStatusFilter}
          OptionsStaff={staffOptions}
          handleCheckboxChange={handleCheckboxChange}
          handleApplyCheck={handleApplyCheck}
          handleCancelCheck={handleCancelCheck}
          selectedStaff={selectedStaff}
        />
      )}
    </Page>
  );
};
export default withTranslation()(AddNewNote);
