import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Card from "components/Card";
import Text from "components/Text";
import fileIcon from "../../../../../assets/images/new_file_icon.svg";
import styles from "../../../../../pages/AccountPreferences/accountpreferences.module.scss";
import { withTranslation } from "react-i18next";
import Loader from "components/Loader";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import { useQueryClient } from "react-query";
import constants from "../../../../../constants";
import { useSelector } from "react-redux";
import { decodeId } from "utils";
import { usePatientNoteById } from "repositories/patient-appointment-repository";
import { useOfficeDetail } from "repositories/office-repository";

const PatientNoteDetail = ({ history, t }) => {
    const profile = useSelector((state) => state.userProfile.profile);

    const [noteDetail, setNoteDetail] = useState(null);
    const [showLoader, setShowLoader] = useState(false);

    let { officeId, patientId, patientNoteId } = useParams();
    const decodePatientNoteId = decodeId(patientNoteId);
    const decodeOfficeId = decodeId(officeId);
    const { isLoading, data } = usePatientNoteById(decodePatientNoteId);
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);

    const goToPreviousScreen = () => {
        history.push(constants.routes.appointment.patientNotes.replace(':officeId',officeId).replace(':patientId',patientId));
    };

    useEffect(() => {
        if (!isLoading && data?.items) {
            setNoteDetail(data?.items);
        }
    }, [isLoading, data]);

    const getAttachmentName = (link) => {
        return link.substring(link.lastIndexOf("/") + 1);
    };

    const handleDownload = async (fileLink, fileName) => {
        setShowLoader(true);
        try {
            const response = await fetch(fileLink);
            const blob = await response.blob();

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <Page onBack={goToPreviousScreen} title={officeDetail?.name}>
            <Text size="14px" weight="300" color="#000000" className='mb-2'>
                {t("patients.patientNoteDetail")}
            </Text>
            {(isLoading || showLoader || isLoadingOfficeDetail) && <Loader />}

            <Card className={styles["message-templates-card"] + " " + "form-wrapper cursor-pointer"} >
                <div className={styles["schedule-update"]}>
                    <Text color="#587E85" weight="600" size="16px" marginBottom="20px" className={styles["schedule-update-text"]}>
                        {noteDetail?.title}
                    </Text>
                </div>
                <div>
                    <div className="d-flex justify-content-between w-75">
                        <div>
                            <Text color="#6F7788" weight="400" size="12px">
                                {t("patients.date")}
                            </Text>

                            <Text color="#102C42" weight="600" size="14px">
                                {moment(noteDetail?.createdOn).format('MMM DD, YYYY')}
                            </Text>
                        </div>
                        <div>
                            <Text color="#6F7788" weight="400" size="12px">
                                {t("patients.time")}
                            </Text>
                            <Text color="#102C42" weight="600" size="14px">
                                {moment(noteDetail?.createdOn).format('h:mm A')}
                            </Text>
                        </div>
                        <div>
                            <Text color="#6F7788" weight="400" size="12px">
                                {t("patients.createdBy")}
                            </Text>
                            <Text color="#102C42" weight="600" size="14px">
                                {noteDetail?.creator?.id === profile?.id
                                    ? t("accountPreferences.you")
                                    : `${noteDetail?.creator?.firstName} ${noteDetail?.creator?.lastName}`}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <Text color="#6F7788" weight="400" size="12px">
                        {t("patients.description")}
                    </Text>

                    <Text color="#102C42" weight="600" size="14px">
                        {noteDetail?.description}
                    </Text>
                </div>
                <div className="mt-4">
                    {noteDetail?.attachmentLink && (
                        <div className={styles["margin_top"]}>
                            <Text size="12px" weight="400" color="#6F7788">
                                {t("notes.fileAttached")}
                            </Text>
                            <div className="d-flex gap-2">
                                <img src={fileIcon} alt="close" className="mr-2" />
                                <Text size="14px" weight="600" color="#102C42">
                                    {getAttachmentName(noteDetail?.attachmentLink)}
                                </Text>
                            </div>
                            <button
                                className={styles["download_btn"] + " " + "link-btn"}
                                onClick={() =>
                                    handleDownload(
                                        noteDetail?.attachmentLink,
                                        getAttachmentName(noteDetail?.attachmentLink)
                                    )
                                }
                            >
                                {t("notes.download")}
                            </button>
                        </div>
                    )}
                </div>
            </Card>
        </Page>
    );
};
export default withTranslation()(PatientNoteDetail);
