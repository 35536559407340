import { useEffect, useState } from "react";
import styled from "styled-components";

const CallCountDown = () => {
  const [countDown, setCountDown] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  useEffect(() => {
    const startTime = new Date().getTime();
    const timeInterval = setInterval(() => {
      const currentTime = new Date().getTime() - startTime;
      const hours = Math.floor(currentTime / 3600000);
      const minutes = Math.floor((currentTime % 3600000) / 60000);
      const seconds = Math.floor((currentTime % 60000) / 1000);
      setCountDown({
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      });
    }, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);
  return (
    <CallDuration>
      {(+countDown.hours > 0 ? countDown.hours + ": " : "") +
        (countDown.minutes + ": " + countDown.seconds)}
    </CallDuration>
  );
};
export default CallCountDown;

const CallDuration = styled.div`
  color: white;
`;
