import React, { useEffect, useState } from "react";
import SchedulerCalendar from "../../../components/SchedulerCalendar";
import StickySidebar from "../../../components/StickySidebar";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Text from "components/Text";
import "./../../../Scheduler.scss";
import OfficeFilter from "../../../components/SchedulerFilters/OfficeFilter";
import { withTranslation } from "react-i18next";
import EmployeesFilter from "../../../components/SchedulerFilters/EmployeesFilter";
import styles from "./StaffAvailabilityTab.module.scss";
import StaffAvailabilityWeeklyView from "./StaffAvailabilityView/StaffAvailabilityWeeklyView";
import {
  getStaffMembersList,
  useGetOfficeAgendaMonthly,
} from "repositories/scheduler-repository";
import constants from "../../../../../../constants";
import { useSelector } from "react-redux";
import { getStorage, isMobileTab, setStorage } from "utils";
import MonthlyViewNew from "./StaffAvailabilityView/MonthlyViewNew";
import moment from "moment";
import CustomDropdown from "components/Dropdown";
import Loader from "components/Loader";
import toast from "react-hot-toast";
import { cacheSideBarActive } from "utils";
import AgendaFilter from "accountOwner/pages/Scheduler/components/SchedulerFilters/AgendaFilter";
import DailyViewNew from "./StaffAvailabilityView/DailyViewNew";

function StaffAvailabilityTab({
  t,
  staffAvailFiltersData,
  staffAvailabilityViewSelectedFromDashBoard,
  currentDateFromDashBoard,
  setCurrentDateFromDashBoard,
  isFromDashBoard = false,
  appliedAgendaTypes,
  appliedViewSettig,
  appliedOfficeFilters,
}) {
  const ViewSettingFilter = [
    { title: t("title"), type: "title" },
    { title: t("waitingList.clinicName"), type: "officeName" },
    { title: t("dateAndTime"), type: "timeString" },
  ];
  let cachedEventFilter = getStorage(
    constants.localStorageKeys.agenda.eventFilter
  );
  cachedEventFilter = cachedEventFilter ? cachedEventFilter : [];
  let cacheDashBoardFilter = getStorage(constants.notes.cache.dashboardFilter);
  const cachedSelectedOwnerTab = getStorage(
    constants.notes.cache.dashboardAccountOwnerCheck
  );
  let agendaEventFilter = cacheDashBoardFilter?.agenda
    ? cacheDashBoardFilter?.agenda
    : [];
  let cachedSettingFilter = getStorage(
    constants.localStorageKeys.agenda.settingFilter
  );
  cachedSettingFilter = cachedSettingFilter ? cachedSettingFilter : [];

  const profile = useSelector((state) => state.userProfile.profile);
  const [isLoader, setIsLoader] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [agendaTypes, setAgendaTypes] = useState(cachedEventFilter);
  const [agendaFilter, setAgendaFilter] = useState([]);
  const [viewSettig, setViewSetting] = useState([]);
  const [settingFilter, setSettingFilter] = useState([]);
  const [displaySetting, setDisplaySetting] = useState(ViewSettingFilter);

  const FilterEventsData = [
    { title: t("accountOwner.createdByYou"), type: 0 }, // here 0 is not a type taking to check user filter by there id
    { title: t("accountOwner.createdByOthers"), type: -1 }, // here -1 is not a type taking to check user filter by there id
    { title: t("accountOwner.eventsNotAccepted"), type: 4 },
    { title: t("accountOwner.busy"), type: 3 },
    // { title: t('accountOwner.nonAvailableDays'), type: -2 }, // here -2 is not a type taking to check user filter by there id
    { title: t("accountOwner.onLeave"), type: 2 },
    { title: t("scheduler.agenda.appointmentTitle"), type: 1 },
  ];

  let selectedOwnerId = 0;
  let ownerData = null;
  let currentUserId = null;
  if (profile && profile.role) {
    if (profile.role.systemRole !== constants.systemRoles.staff) {
      selectedOwnerId = profile.id;
      ownerData = profile;
    } else {
      if (isFromDashBoard) {
        selectedOwnerId = cachedSelectedOwnerTab?.owner
          ? cachedSelectedOwnerTab?.owner?.id
          : selectedOwnerId;
      } else {
        selectedOwnerId = localStorage.getItem("selectedOwner")
          ? JSON.parse(localStorage.getItem("selectedOwner")).id
          : selectedOwnerId;
        ownerData = JSON.parse(localStorage.getItem("selectedOwner"));
      }
    }
    currentUserId = profile.id;
  }

  let cachedDate = sessionStorage.getItem(constants.staffAvalibilityDate);
  cachedDate = cachedDate ? new Date(cachedDate) : new Date();
  const [currentDate, onDateChange] = useState(cachedDate);
  useEffect(() => {
    sessionStorage.setItem(constants.staffAvalibilityDate, moment(currentDate));
  }, [currentDate]);

  const staffAvailabilityView = [
    { id: "1", name: "Monthly" },
    { id: "2", name: "Weekly " },
  ];
  let setView = sessionStorage.getItem(constants.staffAvalibility)
    ? sessionStorage.getItem(constants.staffAvalibility)
    : staffAvailabilityView[1].id;
  const [staffAvailabilityViewSelected, setStaffAvailabilityViewSelected] =
    useState(setView);
  const {
    apiOffices,
    setApiOffices,
    apiEmps,
    setApiEmps,
    selectedOfficeFilter,
    setOfficeFilter,
    selectedEmpFilter,
    setEmpFilter,
    setEmployeeList,
    resetFilters,
    appliedOfficeFilter,
    appliedStaffFilter,
    setAppliedOfficeFilter,
    setAppliedStaffFilter,
    selectedRoles,
    setSelectedRoles,
  } = staffAvailFiltersData;

  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());

  const [dateRange, setDateRange] = useState(null);
  const enableFetch = selectedOwnerId && dateRange ? true : false;
  const {
    isLoading,
    data: monthlyData,
    error,
  } = useGetOfficeAgendaMonthly(
    selectedOwnerId,
    dateRange?.startDate,
    dateRange?.endDate,
    apiOffices,
    apiEmps,
    { enabled: enableFetch }
  );

  useEffect(() => {
    if (!isLoading && monthlyData?.data) {
      setAgenda(monthlyData?.data);
      setAgendaFilter(monthlyData?.data);
    }
  }, [isLoading, monthlyData]);

  useEffect(() => {
    if (agendaTypes?.length > 0) {
      filterByEvents();
    }
  }, [agenda]);

  const filterByEvents = () => {
    const updatedAgenda = agenda?.map((item) => {
      let eventsCreatedByUser = [];
      let eventsNotCreatedByUser = [];
      let filteredSchedulerEvents = [];

      if (agendaTypes?.length > 0) {
        if (agendaTypes.includes(0)) {
          eventsCreatedByUser =
            item?.schedulerEvents?.filter(
              (event) => event?.createdById === currentUserId
            ) || [];
        }

        if (agendaTypes.includes(-1)) {
          eventsNotCreatedByUser =
            item?.schedulerEvents?.filter(
              (event) => event?.createdById !== currentUserId
            ) || [];
        }

        filteredSchedulerEvents =
          item?.schedulerEvents?.filter((event) =>
            agendaTypes.includes(event.agendaType)
          ) || [];

        return {
          ...item,
          schedulerEvents: [
            ...eventsCreatedByUser,
            ...eventsNotCreatedByUser,
            ...filteredSchedulerEvents,
          ],

          appointments:
            item?.appointments?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          appointmentSlots:
            item?.appointmentSlots?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          leaves:
            item?.leaves?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          schedulerBusySlots:
            item?.schedulerBusySlots?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],
        };
      }

      return {
        ...item,
        schedulerEvents: [],
        appointments: [],
        leaves: [],
        schedulerBusySlots: [],
        appointmentSlots: [],
      };
    });
    setAgendaFilter(updatedAgenda);
  };

  const resetAgenda = () => {
    scrollToElement();
    resetFilters();
    setAgendaFilter(agenda);
    setAgendaTypes([]);
    setStorage(constants.localStorageKeys.agenda.eventFilter, []);
    setViewSetting([]);
    setSettingFilter([]);
    setAppliedOfficeFilter([]);
    setAppliedStaffFilter([]);
    setStorage(constants.localStorageKeys.staffAvailablilityFilter, {
      apiOffices: [],
      apiEmps: [],
    });
    setStorage(constants.localStorageKeys.agenda.monthlySettingFilter, []);
    setStorage(constants.localStorageKeys.agenda.weeklySettingFilter, []);
  };

  useEffect(() => {
    sessionStorage.setItem(
      constants.staffAvalibility,
      staffAvailabilityViewSelected
    );

    setDisplaySetting((prev) => {
      if (staffAvailabilityViewSelected !== "2") {
        if (!prev.some((item) => item.type === "staffMembers")) {
          return [
            ...prev,
            { title: t("taskManagement.staffMembers"), type: "staffMembers" },
          ];
        }
        return prev;
      } else {
        return prev.filter((item) => item.type !== "staffMembers");
      }
    });
    let monthlySettingFilter = getStorage(
      constants.localStorageKeys.agenda.monthlySettingFilter
    );
    let weeklySettingFilter = getStorage(
      constants.localStorageKeys.agenda.weeklySettingFilter
    );
    let settingFilter =
      staffAvailabilityViewSelected !== "2"
        ? monthlySettingFilter
        : weeklySettingFilter;
    settingFilter = settingFilter?.length > 0 ? settingFilter : [];
    setViewSetting(settingFilter);
    setSettingFilter(settingFilter);
  }, [staffAvailabilityViewSelected]);

  const getEmployeeList = async (id) => {
    setIsLoader(true);
    try {
      let res = await getStaffMembersList(selectedOwnerId, id);
      res && setEmployeeList(res.data);
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoader(false);
  };

  useEffect(() => {
  if(selectedOwnerId) {
    getEmployeeList(apiOffices || []);
  }
  }, [selectedOwnerId]);

  const scrollToElement = () => {
    const element = document.getElementById("targetElementId");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  const applyFilter = () => {
    scrollToElement();
    setApiOffices(selectedOfficeFilter);
    setApiEmps(selectedEmpFilter);
    setAppliedOfficeFilter(selectedOfficeFilter);
    setAppliedStaffFilter(selectedEmpFilter);
    setStorage(constants.localStorageKeys.staffAvailablilityFilter, {
      apiOffices: selectedOfficeFilter,
      apiEmps: selectedEmpFilter,
    });
    if (isMobileTab()) {
      handleSidebarToggle();
    }

    if (agendaTypes?.length > 0) {
      filterByEvents();
      setStorage(constants.localStorageKeys.agenda.eventFilter, agendaTypes);
    } else {
      setAgendaFilter(agenda);
      setStorage(constants.localStorageKeys.agenda.eventFilter, agendaTypes);
    }
    if (viewSettig?.length > 0) {
      setSettingFilter((prev) => {
        let updatedTypes = viewSettig;

        if (staffAvailabilityViewSelected !== "2") {
          updatedTypes = viewSettig;
        } else {
          updatedTypes = viewSettig.filter((type) => type !== "staffMembers");
        }

        return updatedTypes;
      });
      if (viewSettig.includes("staffMembers")) {
        const weeklyData = viewSettig.filter((type) => type !== "staffMembers");
        setStorage(
          constants.localStorageKeys.agenda.weeklySettingFilter,
          weeklyData
        );
      } else {
        setStorage(
          constants.localStorageKeys.agenda.weeklySettingFilter,
          viewSettig
        );
      }
      let monthlySettingFilter = getStorage(
        constants.localStorageKeys.agenda.monthlySettingFilter
      );
      if (
        !viewSettig.includes("staffMembers") &&
        monthlySettingFilter?.includes("staffMembers")
      ) {
        setStorage(constants.localStorageKeys.agenda.monthlySettingFilter, [
          ...viewSettig,
          "staffMembers",
        ]);
      } else {
        setStorage(
          constants.localStorageKeys.agenda.monthlySettingFilter,
          viewSettig
        );
      }
    } else {
      setSettingFilter([]);
      setStorage(
        constants.localStorageKeys.agenda.weeklySettingFilter,
        viewSettig
      );
      setStorage(
        constants.localStorageKeys.agenda.monthlySettingFilter,
        viewSettig
      );
    }
  };

  const isFilterApply =
    appliedOfficeFilter?.length ||
    appliedStaffFilter?.length ||
    cachedEventFilter?.length ||
    settingFilter?.length;

  useEffect(() => {
    if (isFromDashBoard) {
      scrollToElement();
      setAgendaTypes(appliedAgendaTypes);
      setSettingFilter(appliedViewSettig);
    }
  }, [appliedAgendaTypes, appliedViewSettig]);

  const dateChange = (type) => {
    let changeDate = staffAvailabilityViewSelected === "2" ? "week" : "month";
    let date = null;
    if (staffAvailabilityViewSelected === "2") {
      date =
        type === constants.arrow.PREVIOUS
          ? new Date(
              moment(currentDate).subtract(1, changeDate).startOf("isoweek")
            )
          : new Date(moment(currentDate).add(1, changeDate).startOf("isoweek"));
    } else {
      date =
        type === constants.arrow.PREVIOUS
          ? new Date(
              moment(currentDate).subtract(1, changeDate).startOf("month")
            )
          : new Date(moment(currentDate).add(1, changeDate).startOf("month"));
    }

    date && onDateChange(date);
  };

  const onActiveStartDateChange = (value) => {
    if (
      constants.calanderActions.includes(value?.action) ||
      (value?.action === constants.calanderActionKey.drillDown &&
        (value?.view === "month" || value?.view === "year"))
    ) {
      value?.activeStartDate && onDateChange(value.activeStartDate);
    }
  };

  return (
    <div
      className={
        isFromDashBoard
          ? `${styles.dynamic_height} scheduler-tabs-main-wrapper m-0 h-100`
          : "scheduler-tabs-main-wrapper"
      }
    >
      {isLoader && <Loader />}
      {!isFromDashBoard && (
        <StickySidebar
          isSidebarActive={isSidebarActive}
          handleSidebarToggle={handleSidebarToggle}
          resetFilter={resetAgenda}
        >
          <SchedulerCalendar
            value={currentDate}
            onChange={onDateChange}
            onActiveStartDateChange={onActiveStartDateChange}
          />
          <span className={styles.notes_filter}>
            {t("notes.filters")}
            {!!isFilterApply && <span className={styles.red_dot}></span>}
          </span>
          <Accordion
            preExpanded={["a", "b", "c", "d"]}
            className="filter-accordion"
            allowZeroExpanded
            allowMultipleExpanded
          >
            <AccordionItem uuid="a">
              <AccordionItemHeading>
                <AccordionItemButton>{t("offices")}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <OfficeFilter
                  getEmployeeList={getEmployeeList}
                  selectedOffice={selectedOfficeFilter}
                  setOfficeFilter={setOfficeFilter}
                  selectedOwnerId={selectedOwnerId}
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="b">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("accountOwner.staff")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <EmployeesFilter
                  selectedEmpFilter={selectedEmpFilter}
                  setEmpFilter={setEmpFilter}
                  ownerId={selectedOwnerId}
                  ownerData={ownerData || {}}
                  selectedOfficeFilter={selectedOfficeFilter}
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="c">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("accountOwner.filterEvents")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <AgendaFilter
                  setAgendaTypes={setAgendaTypes}
                  agendaTypes={agendaTypes}
                  FilterEventsData={FilterEventsData}
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="d">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {t("accountOwner.viewSetting")}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <AgendaFilter
                  setAgendaTypes={setViewSetting}
                  agendaTypes={viewSettig}
                  FilterEventsData={displaySetting}
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div className="filter-btn-box filter-btn-box--sticky-footer">
            <button
              className={"button button-round button-shadow mr-3"}
              title={t("apply")}
              onClick={() => applyFilter()}
            >
              {" "}
              {t("apply")}
            </button>
            <button
              className={
                "button button-round button-border button-dark reset-btn"
              }
              onClick={resetAgenda}
              title={t("reset")}
            >
              {t("reset")}
            </button>
            <button
              className={
                "button button-round button-border button-dark cancel-btn"
              }
              title={t("close")}
              onClick={handleSidebarToggle}
            >
              {t("close")}
            </button>
          </div>
        </StickySidebar>
      )}
      <div
        className={
          isFromDashBoard ? "tabs-right-col p-0 h-100" : "tabs-right-col"
        }
        id="targetElementId"
      >
        {!isFromDashBoard && (
          <div className={styles["staff-availability-header"]}>
            <ul className={styles["view-color-list"]}>
              <li>
                <div className={styles["color-box"]} />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.createdByYou")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-grey"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.createdByOthers")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-blue"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.eventsNotAccepted")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-orange"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.busy")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-red"]}
                />
                <div className={styles["text-box"]}>
                  {" "}
                  {t("accountOwner.onLeave")}{" "}
                </div>
              </li>
              <li>
                <div
                  className={
                    styles["color-box"] + " " + styles["light-purple-bg"]
                  }
                />
                <div className={styles["text-box"]}> {t("appointments")} </div>
              </li>
              <li>
                <div
                  className={styles["color-box"] + " " + styles["color-green"]}
                />
                <div className={styles["text-box"]}> {t("staff.holiday")} </div>
              </li>
            </ul>
            <div className="d-flex staff-calendar-select-box">
              <div className="monthly-calendar-arrows">
                {staffAvailabilityViewSelected && (
                  <>
                    <div
                      className="arrow-img"
                      onClick={() => dateChange(constants.arrow.PREVIOUS)}
                    >
                      <img
                        src={
                          require("assets/images/monthly-arrow-left.svg")
                            .default
                        }
                        alt="icon"
                      />
                    </div>

                    <div className="monthly-calendar-text">
                      {staffAvailabilityViewSelected === "1" &&
                        moment(currentDate).format("MMM YYYY")}
                      {staffAvailabilityViewSelected === "2" && (
                        <>
                          {`${moment(currentDate)
                            .startOf("isoweek")
                            .format("ddd, MMM DD")} - ${moment(currentDate)
                            .endOf("isoweek")
                            .format("ddd, MMM DD")} `}
                        </>
                      )}
                    </div>
                    <div
                      className="arrow-img"
                      onClick={() => dateChange(constants.arrow.NEXT)}
                    >
                      <img
                        src={
                          require("assets/images/monthly-arrow-right.svg")
                            .default
                        }
                        alt="icon"
                      />
                    </div>
                  </>
                )}
              </div>
              <CustomDropdown
                options={staffAvailabilityView}
                selectedOption={staffAvailabilityViewSelected}
                selectOption={setStaffAvailabilityViewSelected}
              />
            </div>
          </div>
        )}
        {isFromDashBoard ? (
          staffAvailabilityViewSelectedFromDashBoard == "1" ? (
            <DailyViewNew
              currentDate={currentDateFromDashBoard}
              currentUserId={currentUserId}
              selectedOwnerId={selectedOwnerId}
              apiOffices={apiOffices}
              apiEmps={apiEmps}
              onDateChange={setCurrentDateFromDashBoard}
              isSidebarActive={isSidebarActive}
              agendaTypes={agendaTypes}
              cachedEventFilter={agendaEventFilter}
              viewSettingFilter={settingFilter}
              isDaily={staffAvailabilityViewSelectedFromDashBoard == "1"}
              isFromDashBoard={true}
            />
          ) : (
            <StaffAvailabilityWeeklyView
              currentDate={currentDateFromDashBoard}
              selectedOwnerId={selectedOwnerId}
              currentUserId={currentUserId}
              apiOffices={apiOffices}
              apiEmps={apiEmps}
              onDateChange={onDateChange}
              isSidebarActive={isSidebarActive}
              isWeekly={staffAvailabilityViewSelectedFromDashBoard == "2"}
              agendaTypes={agendaTypes}
              cachedEventFilter={agendaEventFilter}
              viewSettingFilter={settingFilter}
              isFromDashBoard={isFromDashBoard}
            />
          )
        ) : staffAvailabilityViewSelected === "2" ? (
          <StaffAvailabilityWeeklyView
            currentDate={currentDate}
            selectedOwnerId={selectedOwnerId}
            currentUserId={currentUserId}
            apiOffices={apiOffices}
            apiEmps={apiEmps}
            onDateChange={onDateChange}
            isSidebarActive={isSidebarActive}
            isWeekly={staffAvailabilityViewSelected === "2"}
            agendaTypes={agendaTypes}
            cachedEventFilter={cachedEventFilter}
            viewSettingFilter={settingFilter}
          />
        ) : (
          <MonthlyViewNew
            currentDate={currentDate}
            selectedOwnerId={selectedOwnerId}
            currentUserId={currentUserId}
            onDateChange={onDateChange}
            setDateRange={setDateRange}
            isLoading={isLoading}
            agenda={agendaFilter}
            viewSettingFilter={settingFilter}
            error={error}
          />
        )}
      </div>
    </div>
  );
}

export default withTranslation()(StaffAvailabilityTab);
