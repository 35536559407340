import Page from 'components/Page'
import React, { useContext, useState } from 'react'
import constants from "../../../../constants"
import Card from 'components/Card'
import Input from 'components/Input'
import { withTranslation } from 'react-i18next'
import styles from "../WaitingList/WaitingList.module.scss"

import appointmentStyles from "../Appointments.module.scss";
import BookTimeslot from 'accountOwner/pages/Appointments/components/BookTimeslot'
import Text from 'components/Text'
import SchedulerCalendar from 'accountOwner/pages/Scheduler/components/SchedulerCalendar'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { decodeId, handleSuccess } from 'utils'
import { createAppointment, useAppointmentRequestDetail } from 'repositories/patient-appointment-repository'
import AppointmentDetail from '../components/AppointmentDetail'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query'
import { AppointmentsContext } from '../AppointmentsContext'
import { cloneDeep } from 'lodash'
import Loader from 'components/Loader'

const KEY_NAME = 'waiting-reminder';

const CreateAppointment = ({ history, t, location }) => {
    let { requestId, officeId } = useParams();
    const decodeRequestId = parseInt(decodeId(requestId));
    const decodeOfficeId = parseInt(decodeId(officeId))
    const { data: appointmentRequestData, isLoading } = useAppointmentRequestDetail(decodeRequestId);

    const { mutate: createAppointmentMutate, isLoading: createAppointmentLoading } = useMutation((data) => createAppointment(data));

    const [errors, setErrors] = useState([]);

    const {
        patientMsg,
        selectedAppointmentType,
        selectedSlotDate,
        reminderPlan,
        appointmentSlots,
        setPatientMsg,
        setSlotDate,
        setAppointmentSlots,
        options,
        setReminderSets,
        setReminderPlan,
        setSelectAppointmentType,
        setSelectReminderPlan
    } = useContext(AppointmentsContext);

    const selectedDate = moment(selectedSlotDate).startOf('day');
    const currentDate = moment().startOf('day');
    const isPastDate = selectedDate.isBefore(currentDate);


    const onActiveStartDateChange = (value) => {
        if (constants.calanderActions.includes(value?.action) || (value?.action === constants.calanderActionKey.drillDown && (value?.view === 'month' || value?.view === 'year'))) {
            value?.activeStartDate && setSlotDate(value.activeStartDate);
        }
    }

    const goBack = () => { 
        if(location?.state?.fromWaitingDetail)history.push(constants.routes.waitingAppoimentDetail.replace(':officeId', officeId).replace(":requestId", requestId));
        else {
            history.push(constants.routes.waitingList.replace(':officeId', officeId))
        } 
    }

    const appointmentsData = [
        {
            title: t('waitingList.patientAndAppointmentDetail'),
            details: [
                { label: t('waitingList.patientName'), value: appointmentRequestData?.bookedForFamilyMember ? appointmentRequestData?.bookedForFamilyMember?.firstName + ' ' + appointmentRequestData?.bookedForFamilyMember?.lastName : appointmentRequestData?.patient?.firstName + ' ' + appointmentRequestData?.patient?.lastName },
                { label: t('waitingList.patientEmailAddress'), value: appointmentRequestData?.bookedForFamilyMember ? appointmentRequestData?.bookedForFamilyMember?.email : appointmentRequestData?.patient?.emailId },
                { label: t('waitingList.doctor'), value: appointmentRequestData?.doctor?.firstName + ' ' + appointmentRequestData?.doctor?.lastName },
                { label: t('waitingList.clinicName'), value: appointmentRequestData?.office?.name },
            ]
        },
        {
            title: t('waitingList.dateOfAppointment'),
            details: [
                { label: t('waitingList.startDate'), value: moment(appointmentRequestData?.startDate).format('MMM DD, YYYY') },
                { label: t('waitingList.endDate'), value: moment(appointmentRequestData?.endDate).format('MMM DD, YYYY') }
            ],
            appointmentTime: appointmentRequestData?.appointmentRequestTime
        }
    ];

    const isValidEvent = () => {
        const errorsData = cloneDeep(errors);
        if (!selectedAppointmentType?.[KEY_NAME]?.title) {
            errorsData.typeOfAppointment = t("form.errors.emptySelection", {
                field: t('waitingList.typeOfAppointment').toLowerCase(),
            });
        } else {
            delete errorsData.typeOfAppointment;
        }
        if (!patientMsg) {
            errorsData.patientMsg = t("form.errors.emptyField", {
                field: t('patientAppointmentMembers.messageToPatients').toLowerCase(),
            });
        } else {
            delete errorsData.patientMsg;
        }

        if (appointmentSlots?.length === 0) {
            errorsData.slots = t("form.errors.emptySelection", {
                field: t("patientAppointmentMembers.reassignBookingTimeslot").toLowerCase(),
            });
        } else {
            delete errorsData.slots;
        }

        setErrors(errorsData);
        return !Object.values(errorsData).some((err) => !!err);
    };

    const handleCreate = () => {
        if (isValidEvent()) {
            const data = {
                "date": moment(selectedSlotDate).format('YYYY-MM-DD'),
                "slots": appointmentSlots?.map(val => val?.startTime),
                "Description": patientMsg,
                "officeId": decodeOfficeId,
                "doctorId": appointmentRequestData?.doctorId,
                "patientId": appointmentRequestData?.patientId,
                "appointmentReminderPlanId": reminderPlan?.[KEY_NAME]?.id,
                "appointmentTypeId": selectedAppointmentType?.[KEY_NAME]?.id,
                "appointmentRequestId": decodeRequestId,
                "patientFamilyMemberId": null
            }
            createAppointmentMutate(data, {
                onSuccess: (res) => {
                    handleSuccess(res.message);
                    setReminderSets(prev => ({
                        ...prev,
                        [KEY_NAME]: []
                    }));
                    setReminderPlan(prev => ({
                        ...prev,
                        [KEY_NAME]: null
                    }))
                    setSlotDate(new Date);
                    setSelectAppointmentType(null);
                    setSelectReminderPlan(null)
                    setPatientMsg(null);
                    goBack();
                }
            });
        }
    }

    return (
        <Page title={t('waitingList.createAppointment')} onBack={goBack}>
            {(isLoading || createAppointmentLoading) && <Loader />}
            <Card>
                {appointmentsData.map((appointment, index) => (
                    <React.Fragment key={index}>
                        <AppointmentDetail t={t} appointment={appointment} />
                        {index < appointmentsData.length - 1 && <hr />}
                    </React.Fragment>
                ))}
            </Card>
            {appointmentRequestData?.bookedForFamilyMember && (
                <div className='mt-5'>
                    <div className={styles.details_ui}>
                        <Text size="14px" weight="400" color="#102C42">{t("patientAppointmentMembers.faimlyMemberMsg")}</Text>
                    </div>
                    <Card>
                        <div className={styles.recall_data_new_grid}>
                            <div>
                                <Text size="12px" weight="400" color="#6F7788">{t("patientAppointmentMembers.name")}</Text>
                                <Text size="14px" weight="600" color="#102C42" marginTop="10px">{appointmentRequestData?.bookedByUser?.firstName}</Text>
                            </div>
                            <div>
                                <Text size="12px" weight="400" color="#6F7788">{t("patientAppointmentMembers.email")}</Text>
                                <Text size="14px" weight="600" color="#102C42" marginTop="10px">{appointmentRequestData?.bookedByUser?.emailId}</Text>
                            </div>
                            <div>
                                <Text size="12px" weight="400" color="#6F7788">{t("patientAppointmentMembers.contact")}</Text>
                                <Text size="14px" weight="600" color="#102C42" marginTop="10px">{appointmentRequestData?.bookedByUser?.contactNumber}</Text>
                            </div>
                        </div>
                    </Card>

                </div>
            )}
            <Card className="form-wrapper">
                <div className={styles['recall-patient-appointment-details-wrapper']}>
                    <div className={styles["recall-patientAppointmentDetails-left-part"]}>
                        <Input
                            className={styles["appointment-input-box"]}
                            Title={t('patientAppointmentMembers.messageToPatients')}
                            Type="text"
                            Placeholder={t('form.placeholder1', { field: t('patientAppointmentMembers.messageToPatients') })}
                            Name={"Description"}
                            Value={patientMsg}
                            HandleChange={(e) => setPatientMsg(e.target.value)}
                            MaxLength={"400"}
                        />
                        {errors?.patientMsg && <span className="error-msg mt-4">{errors?.patientMsg}</span>}
                        <div className={appointmentStyles['heading-data-Wrapper']}>
                            <div className={appointmentStyles['head-link-wrapper']}>
                                <div className={appointmentStyles['appointment-head']}>{t('waitingList.typeOfAppointment')}</div>
                                <Link className={appointmentStyles["appointment-link"]} to={{pathname: constants.routes.appointment.appointmentType.replace(':officeId', officeId).replace(':requestId', requestId), state: location?.state}}>{selectedAppointmentType?.[KEY_NAME]?.title ? t('waitingList.changeType') : t('waitingList.selectType')}</Link>
                            </div>
                            <Text size="14px" weight="600" color="#102C42" marginTop="6px">{selectedAppointmentType?.[KEY_NAME]?.title || ''}</Text>
                        </div>
                        {errors?.typeOfAppointment && <span className="error-msg mt-4">{errors?.typeOfAppointment}</span>}
                        <div className={appointmentStyles['heading-data-Wrapper']}>
                            <div className={appointmentStyles['head-link-wrapper']}>
                                <div className={appointmentStyles['appointment-head']}>{t('waitingList.ReminderPlan')}</div>
                                <Link className={appointmentStyles["appointment-link"]} to={{pathname: constants.routes.appointment.appointmentReminder.replace(':officeId', officeId).replace(':requestId', requestId), state: location?.state}}>{reminderPlan?.[KEY_NAME]?.title ? t('waitingList.changeReminderPlan') : t('waitingList.slectReminder')}</Link>
                            </div>
                            {reminderPlan?.[KEY_NAME] && <div className={appointmentStyles['appointment-modal-card']}>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{reminderPlan?.[KEY_NAME]?.title || ''}</Text>
                                {reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting?.map(plan =>
                                    <div className='mt-2' key={plan?.id}>
                                        <Text size="14px" weight="600" color="#102C42" >{plan?.reminderBefore + ' ' +
                                            options.find(
                                                m => m.id == plan?.reminderBeforeType,
                                            )?.name +
                                            ' ' +
                                            t("patientAppointmentMembers.before")}
                                        </Text>
                                        <Text size="14px" weight="600" color="#102C42" >
                                            {[plan.isEmail && t("patientAppointmentMembers.isEmail"), plan.isSms && t("patientAppointmentMembers.isSms"), plan.isPushNotification && t("patientAppointmentMembers.isPushNotification")]
                                                .filter(Boolean)
                                                .join(', ')}
                                        </Text>
                                    </div>
                                )}
                            </div>}
                        </div>
                    </div>
                    <Card className={styles["recall-patientAppointmentDetails-right-part-card"]}>
                        <div className={styles['book-slot'] + " " + 'd-flex flex-column'}>
                            <div>
                                <div className='schedular-calendar-box mb-3'>
                                    <SchedulerCalendar value={selectedSlotDate} onChange={setSlotDate} onActiveStartDateChange={onActiveStartDateChange} />
                                </div>
                                <Text size="12px" weight="400" color="#6F7788">{t("patientAppointmentMembers.reassignTimezone")}</Text>
                                <Text size="14px" weight="600" color="#102C42" marginTop="6px" marginBottom="30px">{t("patientAppointmentMembers.reassignPst")}</Text>
                                <div className={styles['scheduler-calendar-timezone-separator']}></div>
                            </div>
                            <div className={styles["book-slot-new-appointment"]}>
                                <div>
                                    <Text color='#587E85' weight='600' size='16px' >{t("patientAppointmentMembers.reassignBookingTimeslot")}</Text>
                                </div>
                                <div className={styles['notify-checkbox'] + " " + " d-flex "}>
                                    <div className={styles['checkbox-container'] + " " + " d-flex flex-row "}>
                                        <div className={styles['show-checkedbox'] + " " + styles["box1"]}></div>
                                        {t('waitingList.booked')}
                                    </div>
                                    <div className={styles['checkbox-container'] + " " + " d-flex flex-row"}>
                                        <div className={styles['show-checkedbox'] + " " + styles['box2']}></div>
                                        {t('waitingList.available')}
                                    </div>
                                    <div className={styles['checkbox-container'] + " " + "d-flex flex-row"}>
                                        <div className={styles['show-checkedbox'] + " " + styles["box3"]}></div>
                                        {t('waitingList.blocked')}
                                    </div>
                                </div>
                            </div>
                            {isPastDate ?
                                <Card className={styles['urban-dental-reassign-card'] + " " + "mt-3"}>
                                    <Text>{t("waitingList.selectedDateMsg1")} </Text>
                                    <Text marginTop='2px'>{t("waitingList.selectedDateMsg2")} </Text>
                                </Card>
                                :
                                <BookTimeslot t={t} officeId={decodeOfficeId} date={moment(selectedSlotDate).format('YYYY-MM-DD')} doctorId={appointmentRequestData?.doctorId} setSelectedSlots={setAppointmentSlots} selectedSlots={appointmentSlots} errors={errors} />}
                        </div>
                        <div className={styles.recall_appointment_btn}>
                            <button className='button button-round button-shadow w-sm-100 text-nowrap' onClick={handleCreate}>
                                {t("patientAppointmentMembers.reassigncreateAppointment")}
                            </button>
                            <button className='button button-round button-border button-dark btn-mobile-link text-nowrap' onClick={goBack}>
                                {t("patientAppointmentMembers.cancel")}
                            </button>
                        </div>
                    </Card>
                </div>
            </Card>
        </Page>
    )
}
export default withTranslation()(CreateAppointment) 