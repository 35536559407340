import React from "react";
import SearchIcon from "../../../../assets/images/search.svg";
import styles from "../TaskManagement.module.scss"
import { withTranslation } from "react-i18next";

const SearchBar = ({ handleSearchTerm, searchValue, t }) => {
    return (
        <div className={styles.new_search_bar}>
            <span className={styles.search_icon}>
                <img src={SearchIcon} alt="search icon" />
            </span>
            <input
                className={styles.search_input}
                type="text"
                placeholder={t("taskManagement.searchPlaceHolder")}
                onChange={handleSearchTerm}
                value={searchValue}
            />
        </div>
    );
};

export default withTranslation()(SearchBar);

