import React, { useEffect } from "react";
import styles from "../../../Appointments.module.scss";
import { withTranslation } from "react-i18next";
import constants from "../../../../../../constants";
import { decodeId, encodeId, formatUTCDateTime } from "utils";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AlertRed from "assets/images/alert_red.svg";
import DirectCallMain from "Messenger/calling/DirectCallMain/DirectCallMain";
import { useSbCalls } from "Messenger/calling/context";
function Details({ t, profile, appointmentDetail, getNameById }) {
  let { appointmentId } = useParams();
  const { dispatch } = useSbCalls();
  useEffect(() => {
    if (dispatch && appointmentDetail?.id) {
      dispatch({
        type: "AppointMent_User",
        payload: {
          appointmentUser: appointmentDetail.patient,
          appointmentDetail,
        },
      });
    }
  }, [appointmentDetail?.id]);
  const appointmentIdDecoded = decodeId(appointmentId);
  const data = [
    {
      id: 0,
      detailsHeading: t("patientAppointmentMembers.contactNo"),
      detailData:
        appointmentDetail?.patient?.contactNumber ||
        t("patientAppointmentMembers.noPhoneNumber"),
    },
    {
      id: 1,
      detailsHeading: t("patientAppointmentMembers.emailAddress"),
      detailData: appointmentDetail?.patient?.emailId,
    },
    {
      id: 2,
      detailsHeading: t("patientAppointmentMembers.gender"),
      detailData: getNameById(appointmentDetail?.patient?.gender),
    },
    {
      id: 3,
      detailsHeading: t("patientAppointmentMembers.dateOfBirth"),
      detailData: appointmentDetail?.patient?.dateOfBirth
        ? `${formatUTCDateTime(appointmentDetail?.patient?.dateOfBirth)} ${
            appointmentDetail?.patient?.age
          }`
        : t("patientAppointmentMembers.notDateOfBirth"),
    },
    {
      id: 4,
      detailsHeading: t("patientAppointmentMembers.location"),
      detailData:
        appointmentDetail?.patient?.address ||
        t("patientAppointmentMembers.noLocation"),
    },
    {
      id: 5,
      detailsHeading: t("patientAppointmentMembers.DescriptionTreatmentNeeded"),
      detailData: appointmentDetail?.description,
    },
  ];

  const listItems = data.map((person) => (
    <div key={person.id} className="d-flex justify-content-between">
      <div>
        <div className={styles["details-heading"]}>{person.detailsHeading}</div>
        <div className={styles["details-data"]}>{person.detailData}</div>
      </div>
      <div>
        {/*show in case "Pending", "Accepted" and "Confirmed"*/}
        {appointmentDetail?.isPatientVideoCall &&
          person.detailsHeading === t("patientAppointmentMembers.contactNo") &&
          [1, 2, 7].includes(appointmentDetail?.status) && <DirectCallMain />}
      </div>
    </div>
  ));

  return (
    <article>
      <p>{t("patientAppointmentMembers.patientPersonalDetail")}</p>
      {appointmentDetail?.isCovidFormFilled && (
        <div className={styles["View-submitted-question-wrapper"]}>
          <div className={styles.covid_form_icon_wrapper}>
            <Link
              className={styles["View-submitted-question"]}
              to={constants.routes.appointment.covingQuestionnaire.replace(
                ":appointmentId",
                encodeId(appointmentIdDecoded)
              )}
            >
              {t("patientAppointmentMembers.viewCovidForm")}
            </Link>
            <span className={styles["red-alert-box"]}>
              <img
                src={AlertRed}
                alt="alert"
                className={styles["red-alert-icon"]}
              />
            </span>
          </div>
        </div>
      )}
      <ul>{listItems}</ul>
      <hr />
      {appointmentDetail?.isQuestionnaireFilled && (
        <div className={styles["View-submitted-question-wrapper"]}>
          <Link
            className={styles["View-submitted-question"]}
            to={constants.routes.appointment.submittedQuestionnaire.replace(
              ":appointmentId",
              encodeId(appointmentIdDecoded)
            )}
          >
            {t("patientAppointmentMembers.viewSubmittedQuestions")}
          </Link>
        </div>
      )}
    </article>
  );
}
export default withTranslation()(Details);
