import React, { useEffect, useState } from 'react'
import Page from 'components/Page'
import constants from "../../../../constants"
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Suspense } from "react";
import Card from 'components/Card'
import styles from "../Appointments.module.scss"
import Text from 'components/Text'
import userDefaultImage from "./../../../../assets/images/staff-default.svg";
import Loader from 'components/Loader'
import { decodeId, encodeId, getStorage, setStorage } from 'utils'
import { useOfficeDetail } from 'repositories/office-repository'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import UpcomingTabs from '../UpcomingTabs';
import RecallTabs from "../RecallTab";
import PastAppointmentTab from '../PastAppointmentTab';
import { useSelector } from 'react-redux';
import { useUser } from 'repositories/user-repository';

function PatientDetail({ t, history, location }) {

    const cachedActiveTab = getStorage(constants.patientScheduling.cache.eventlisting)
    const [activeTab, setActiveTab] = useState(cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1);
    let { officeId, patientId } = useParams();
    const decodePatientId = decodeId(patientId);
    const tabName = constants.patientAppointMentTab;
    let state = { officeId: decodeId(officeId), patientId: decodePatientId }
    const { data: patientData } = useUser(decodePatientId);

    const manageTab = (tab) => {
        window.scrollTo(0, 0);
        setActiveTab(tab);
        setStorage(constants.patientScheduling.cache.eventlisting, {
            activeTab: tab,
        });
    };

    const goBack = () => history.push(constants.routes.appointment.patients.replace(':officeId', officeId));

    function getAge(dateOfBirth) {
        const dob = new Date(dateOfBirth);

        const today = new Date();

        let ageYears = today.getFullYear() - dob.getFullYear();
        const monthDifference = today.getMonth() - dob.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
            ageYears--;
        }

        const lastBirthday = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
        const months = Math.floor((today - lastBirthday) / (1000 * 60 * 60 * 24 * 30));

        const totalAge = ageYears + (months / 12);

        return totalAge.toFixed(1) + " years";
    }

    const patientPersonalData = {
        "Contact No.": patientData?.data?.contactNumber || '-',
        "Email Address": patientData?.data?.emailId || '-',
        "Gender": !patientData?.data?.gender ? '-' : patientData?.data?.gender === 1 ? 'Male' : 'Female',
        "Date of Birth & Age": !patientData?.data?.dateOfBirth ? '-' : moment(patientData?.data?.dateOfBirth).format('MMM DD, YYYY') + '   ' + `${patientData?.data?.dateOfBirth ? getAge(patientData?.data?.dateOfBirth) : ''}`,
        "Location": `${patientData?.data?.address ? patientData?.data?.address + ',' : ''} ${patientData?.data?.city?.name ? patientData?.data?.city?.name + ',' : ''} ${patientData?.data?.state?.name ? patientData?.data?.state?.name : ''}` || '-'
    }


    const decodeOfficeId = parseInt(decodeId(officeId));
    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(decodeOfficeId);


    useEffect(() => {
        setStorage('officeState', {
            officeState: state,
        });
        setStorage('isFromAppointmentDetail', {
            isFromAppointmentDetail: false,
        });
        setStorage('isFromManagePage', {
            isFromManagePage: false,
        });
        setStorage('createRecall', {
            createRecall: false,
        });
        setStorage('createPage', {
            isFromCreatePage: false,
        });
        setStorage('appointmentReminder', {
            appointmentReminder: false,
        });
    }, [])

    return (



        <Page onBack={goBack} title={officeDetail?.name} className="scheduler-page">
            <Text size="14px" weight="300" color="#000000" className='mb-2'>
                {t("waitingList.patientAppointmentDetails")}
            </Text>
            {(isLoadingOfficeDetail) && <Loader />}


            <Card className={styles.details_card}>
                <div className={styles.waiting_list_details_left_section}>
                    <img src={patientData?.data?.profilePic || userDefaultImage} alt='' className={styles.patient_image} />
                    <Text size="18px" weight="600" color="#587E85" marginTop="12px" marginBottom="30px" paddingLeft="18px">{patientData?.data?.firstName + ' ' + patientData?.data?.lastName}</Text>
                    {Object.keys(patientPersonalData).map((key, index) =>
                        <div className='flex column w-100 mb-4' key={index}>

                            <Text size="12px" weight="400" color="#6F7788" >{key}</Text>
                            <Text size="14px" weight="600" color="#102C42" marginTop="5px">{patientPersonalData[key]}</Text>
                        </div>)}

                    <button className="button button-round button-shadow w-sm-100 w-100" onClick={() => history.push({ pathname: constants.routes.appointment.patientNotes.replace(':officeId', officeId).replace(':patientId', patientId), state: { isFrompatientDetail: true } })}>{t('patients.viewPatienNote')}</button>

                </div>
                <div className={styles["appointment-tabs-new"] + " " + "p-0 m-0"}>
                    <Nav tabs className={styles["nav-tabs"]}>
                        <NavItem className={styles["nav-items"]}>
                            <NavLink className={activeTab == tabName.upCommingAppointment ? styles["active"] : styles["not-active"]} onClick={() => manageTab(1)}>
                                {t("patientAppointmentMembers.upcoming")}
                            </NavLink>
                        </NavItem>
                        <NavItem className={styles["nav-items"]}>
                            <NavLink className={activeTab == tabName.recallAppointment ? styles["active"] : styles["not-active"]} onClick={() => manageTab(2)}>
                                {t("patientAppointmentMembers.recall")}
                            </NavLink>
                        </NavItem>
                        <NavItem className={styles["nav-items"]}>
                            <NavLink className={activeTab == tabName.pastAppointment ? styles["active"] : styles["not-active"]} onClick={() => manageTab(3)}>
                                {t("patientAppointmentMembers.past")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <Suspense fallback={<Loader />}>
                            <TabPane tabId={1}>
                                {activeTab === tabName.upCommingAppointment && <UpcomingTabs state={state} isFrompatientDetail={true} manageTab={manageTab} />}
                            </TabPane>
                            <TabPane tabId={2}>
                                {activeTab === tabName.recallAppointment && <RecallTabs state={state} isFrompatientDetail={true} />}
                            </TabPane>
                            <TabPane tabId={3}>
                                {activeTab === tabName.pastAppointment && <PastAppointmentTab state={state} isFrompatientDetail={true} />}
                            </TabPane>
                        </Suspense>
                    </TabContent>
                </div>
            </Card>
        </Page >
    )
}

export default withTranslation()(PatientDetail);