const registerDirectCallListeners = (call, dispatchUpdate) => {
  call.onEstablished = (call) => {
    dispatchUpdate({ callState: "established" });
  };
  call.onConnected = (call) => {
    dispatchUpdate({ callState: "connected" });
  };
  call.onReconnected = (call) => {
    dispatchUpdate({ callState: "reconnected" });
  };
  call.onReconnecting = (call) => {
    dispatchUpdate({ callState: "reconnecting" });
  };
  call.onEnded = (call) => {
    dispatchUpdate({ callState: "ended" });
  };
  call.onRemoteAudioSettingsChanged = (call) => {
    dispatchUpdate({
      isRemoteAudioEnabled: call.isRemoteAudioEnabled,
    });
  };
  call.onRemoteVideoSettingsChanged = (call) => {
    dispatchUpdate({
      isRemoteVideoEnabled: call.isRemoteVideoEnabled,
    });
  };
  // onCustomItemsUpdated() {
  //
  // },
  // onCustomItemsDeleted() {
  //
  // },
};

export const statefyDirectCall = (call, dispatch, registerListener = true) => {
  const dispatchUpdate = (part) => {
    const payload = {
      callId: call.callId,
      ...part,
    };
    dispatch({ type: "UPDATE_CALL", payload });
  };

  if (registerListener) {
    registerDirectCallListeners(call, dispatchUpdate);
  }

  return {
    callState:
      call.localUser.userId === call.caller.userId ? "dialing" : "ringing",
    callId: call.callId,
    caller: call.caller, // This should not mutate
    callee: call.callee, // This should not mutate
    isVideoCall: call.isVideoCall,
    localUser: call.localUser, // This should not mutate
    remoteUser: call.remoteUser, // This should not mutate
    isLocalAudioEnabled: call.isLocalAudioEnabled,
    isRemoteAudioEnabled: call.isRemoteAudioEnabled,
    isLocalVideoEnabled: call.isLocalVideoEnabled,
    isRemoteVideoEnabled: call.isRemoteVideoEnabled,
    myRole: call.myRole,
    isOngoing: call.isOngoing,
    endedBy: call.endedBy, // This should not mutate
    isEnded: call.isEnded,
    endResult: call.endResult,
    // callLog: call.callLog, // This should not mutate
    // customItems: call.customItems, // This should not mutate
    localMediaView: call.localMediaView,
    remoteMediaView: call.remoteMediaView,

    setLocalMediaView(mediaView) {
      dispatchUpdate({ localMediaView: mediaView });
      return call.setLocalMediaView(mediaView);
    },
    setRemoteMediaView(mediaView) {
      dispatchUpdate({ remoteMediaView: mediaView });
      return call.setRemoteMediaView(mediaView);
    },

    stopVideo() {
      dispatchUpdate({ isLocalVideoEnabled: false });
      return call.stopVideo();
    },
    startVideo() {
      dispatchUpdate({ isLocalVideoEnabled: true });
      return call.startVideo();
    },
    getDuration() {
      return call.getDuration();
    },
    accept(params) {
      return call.accept(params);
    },
    end() {
      return call.end();
    },

    muteMicrophone() {
      dispatchUpdate({ isLocalAudioEnabled: false });
      return call.muteMicrophone();
    },
    unmuteMicrophone() {
      dispatchUpdate({ isLocalAudioEnabled: true });
      return call.unmuteMicrophone();
    },

    // captureLocalVideoView(callback?) {
    //   return call.captureLocalVideoView(callback);
    // },
    // captureRemoteVideoView(callback?) {
    //   return call.captureRemoteVideoView(callback);
    // },

    // updateCustomItems(customItems, callback?): Promise<> {
    // },
    // deleteCustomItems(customItemsKeys: string[], callback?): Promise<> {
    // },
    // deleteAllCustomItems(callback?): Promise<> {
    // },

    // startRecording(options): string {
    // },
    // stopRecording(recordingId: string): boolean {
    // },
  };
};
