import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import Text from "components/Text";
import "./PreviewModal.scss";
import { withTranslation } from "react-i18next";
import moment from "moment";

const PreviewModal = ({
  t,
  showPreview,
  setShowPreview,
  title,
  messageTitle,
  content,
  isPreviewForEmailContent,
  setIsPreviewForEmailContent,
  btnText = "",
}) => {
  const OfficeName = "Urban Dental";
  const DoctorName = "Dr. Florian Smith";
  const PatientName = "Charles Smith";

  const setPreviewContent = (data) => {
    if (typeof String.prototype.replaceAll === "undefined") {
      String.prototype.replaceAll = function (match, replace) {
        return this.replace(new RegExp(match, "g"), () => replace);
      };
    }

    let finalContent = String(data).replaceAll(
      /DATE_OF_APPOINTMENT/g,
      moment().add("day", 1).format("ll")
    );
    finalContent = String(finalContent).replaceAll(/OFFICE_NAME/g, OfficeName);
    finalContent = String(finalContent).replaceAll(/DOCTOR_NAME/g, DoctorName);
    finalContent = String(finalContent).replaceAll(
      /PATIENT_NAME/g,
      PatientName
    );
    finalContent = String(finalContent).replaceAll(/[[\]]/g, "");

    return finalContent;
  };

  return (
    <>
      <Modal
        isOpen={showPreview}
        className="modal-dialog-centered delete-modal modal-lg"
        modalClassName="custom-modal"
        toggle={setShowPreview}
      >
        <span
          className="preview-close-btn"
          onClick={() => {
            setIsPreviewForEmailContent(false);
            setShowPreview(false);
          }}
        >
          <img src={require("assets/images/cross.svg").default} alt="close" />
        </span>
        <ModalBody className="text-center">
          {isPreviewForEmailContent ? (
            <div>
              <Text
                size="25px"
                marginBottom="10px"
                weight="500"
                color="#111b45"
                marginTop="25px"
              >
                <span className="modal-title-25"> {title}</span>{" "}
              </Text>

              {content || messageTitle ? (
                <div className="email-content-container change-modal-content">
                  {messageTitle && (
                    <Text
                      size="16px"
                      marginBottom="10px"
                      weight="500"
                      color="#111b45"
                      dangerouslySetInnerHTML={{
                        __html: setPreviewContent(messageTitle),
                      }}
                    ></Text>
                  )}
                  {content && (
                    <Text
                      size="16px"
                      marginBottom="20px"
                      weight="300"
                      color=" #535b5f"
                      dangerouslySetInnerHTML={{
                        __html: setPreviewContent(content),
                      }}
                    ></Text>
                  )}
                </div>
              ) : (
                <Text
                  size="16px"
                  marginBottom="20px"
                  weight="300"
                  color=" #535b5f"
                >
                  {t("accountPreferences.noPreview")}
                </Text>
              )}
              <div className="btn-box">
                <button
                  className="button button-round button-shadow mr-md-4 w-sm-100"
                  title={btnText}
                  onClick={() => {
                    setIsPreviewForEmailContent(false);
                    setShowPreview(false);
                  }}
                >
                  {btnText}
                </button>
              </div>
            </div>
          ) : (
            <div className="change-modal-content">
              <Text
                size="25px"
                marginBottom="10px"
                weight="500"
                color="#111b45"
                marginTop="25px"
              >
                <span className="modal-title-25"> {title}</span>{" "}
              </Text>
              {content ? (
                <Text
                  size="16px"
                  marginBottom="20px"
                  weight="300"
                  color=" #535b5f"
                  dangerouslySetInnerHTML={{
                    __html: setPreviewContent(content),
                  }}
                ></Text>
              ) : (
                <Text
                  size="16px"
                  marginBottom="20px"
                  weight="300"
                  color=" #535b5f"
                >
                  {t("accountPreferences.noPreview")}
                </Text>
              )}

              <div className="btn-box">
                <button
                  className="button button-round button-shadow mr-md-4 w-sm-100"
                  title={btnText}
                  onClick={() => {
                    setIsPreviewForEmailContent(false);
                    setShowPreview(false);
                  }}
                >
                  {btnText}
                </button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(PreviewModal);
