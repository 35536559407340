import React from "react";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import ToggleSwitch from "components/ToggleSwitch";
import styles from "../../TaskManagement.module.scss";
import Exlamatory_Icon from "../../../../../assets/images/red_exclamatory_icon.svg";
import { formatUTCDateTime } from "utils";

const LeftSection = ({
    t,
    taskDetail,
    checkOverdueTasks,
    getButtonClass,
    getStatus,
    notifications,
    handleNotifications
}) => {

    const getPriorityClass = (taskPriority) => {
        switch (taskPriority) {
            case "HIGH":
                return {
                    wrapperClass: styles["high-priority-wrapper"],
                    dotClass: styles["high-priority-dot"],
                    textClass: styles["high-priority-text"],
                };
            case "MEDIUM":
                return {
                    wrapperClass: styles["medium-priority-wrapper"],
                    dotClass: styles["medium-priority-dot"],
                    textClass: styles["medium-priority-text"],
                };
            case "LOW":
                return {
                    wrapperClass: styles["low-priority-wrapper"],
                    dotClass: styles["low-priority-dot"],
                    textClass: styles["low-priority-text"],
                };
            default:
                return {
                    wrapperClass: "",
                    dotClass: "",
                    textClass: "",
                };
        }
    };

    const getPriority = (priority) => {
        const priorityMap = {
            1: "HIGH",
            2: "MEDIUM",
            3: "LOW"
        };
        return priorityMap[priority] || "All";
    };

    const { wrapperClass, dotClass, textClass } = getPriorityClass(getPriority(taskDetail?.priority));

    return (
        <div className={styles["left_section"]}>
            {checkOverdueTasks(taskDetail?.dueDate, taskDetail?.overallStatus) && <div className="d-flex align-items-center gap-3">
                <img
                    src={Exlamatory_Icon}
                    alt="Exlamatory_Icon"
                    className="mr-2"
                />
                <p className={styles["overdue_warning"] + " " + "m-0"}>
                    {t("taskManagement.taskOverdue")}
                </p>
            </div>}
            <div className={styles["status_priority_date_wrapper"]}>
                <div className={styles["priority_status"]}>
                    <button className={getButtonClass(getStatus(taskDetail?.overallStatus))}>{getStatus(taskDetail?.overallStatus)}</button>
                    <div
                        className={`${styles["circle_priority_text_wrapper"]} ${wrapperClass}`}
                    >
                        <span
                            className={`${styles["circular_dot"]} ${dotClass}`}
                        ></span>
                        <p className={`${styles["priority_text"]} ${textClass}`}>
                            {getPriority(taskDetail?.priority)}
                        </p>
                    </div>
                </div>
                <div className={styles["date"]}> {formatUTCDateTime(taskDetail?.createdAt)}</div>
            </div>
            <Text size="20px" weight="500" color="#111B45" marginTop="30px">
                {taskDetail?.title}
            </Text>
            <div className={styles["office_assignby_wrapper"]}>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.startDate")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {formatUTCDateTime(taskDetail?.startDate)}
                    </Text>
                </div>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.dueDate")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        {formatUTCDateTime(taskDetail?.dueDate)}
                    </Text>
                </div>
            </div>
            <div className={styles["office_assignby_wrapper"]}>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.assignedBy")}
                    </Text>
                    <Text size="14px" weight="600" color="#102C42">
                        You
                    </Text>
                </div>
                <div className={styles["office_name"]}>
                    <Text size="12px" weight="400" color="#6F7788">
                        {t("taskManagement.assignedTo")}
                    </Text>
                    {taskDetail?.totalAssignees === 1 ? (
                        <Text size="14px" weight="600" color="#102C42">
                            {`${taskDetail?.oneAssignee?.firstName} ${taskDetail?.oneAssignee?.lastName}`}
                        </Text>
                    ) : (
                        <Text size="14px" weight="600" color="#102C42">
                            {taskDetail?.totalAssignees} {t("messenger.members")}
                        </Text>
                    )}
                </div>
            </div>

            <div className={styles["description"]}>
                <Text size="12px" weight="400" color="#6F7788">
                    {t("taskManagement.description")}
                </Text>
                <Text size="14px" weight="600" color="#102C42">
                    {taskDetail?.description}
                </Text>
            </div>

            <div className={styles.toggle_btn_wrapper}>
                <div className="row">
                    <div className="d-flex align-items-center">
                        <ToggleSwitch
                            className="mt-0"
                            label="notifyAssignees"
                            onChange={(e) => handleNotifications(e, taskDetail?.id)}
                            value={notifications.notifyAssignees}
                        />
                        <Text
                            size="13px"
                            marginBottom="0px"
                            weight="400"
                            className="mt-2"
                            color="#79869A"
                        >
                            {t("taskManagement.notifyAssigneesabouttheTask")}
                        </Text>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex align-items-center">
                        <ToggleSwitch
                            className="mt-0"
                            label="notifyAssignor"
                            onChange={(e) => handleNotifications(e, taskDetail?.id)}
                            value={notifications.notifyAssignor}
                        />
                        <Text
                            size="13px"
                            marginBottom="0px"
                            weight="400"
                            className="mr-2 mt-2"
                            color="#79869A"
                        >
                            {t("taskManagement.notifyAssigneesabouttheUpdates")}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withTranslation()(LeftSection);
