import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import styles from "../Appointments.module.scss";
import "../Appointments.scss";
import Table from "components/table";
import SortDown from "../../../../assets/images/pms-icons/sort_down.svg";
import SortUp from "../../../../assets/images/pms-icons/sort_up.svg";
import constants from "./../../../../constants";
import useCommonModule from "../hooks/useCommonModule";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatUTCDateTime } from "utils";
import CancelNextVisitModal from "../modal/CancelNextVisitModal";
import Empty from "components/Empty";
import SearchIcon from "../../../../assets/images/search.svg";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const PAGE_SIZE = constants.pageSize;

function RecallAppointmentTable({
  t,
  appointments,
  totalItems,
  pageNumber,
  setPageNumber,
  searchValue,
  handleInputChange,
  state,
  cancelNextRecallDate,
  isFrompatientDetail = false,
}) {
  const history = useHistory();
  const {
    data: { appointmentDetail, isConfirmModal },
    methods: { getStatus, getStatusClass, getMenuoptions, handleConfirmToggle },
  } = useCommonModule(history, t);

  const columns = [
    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.patientName"
        ),
      },
      dataField: "patientName",
      text: t("patientAppointmentMembers.appointmentTableHeading.patientName"),
      formatter: (cellContent, row) => (
        <div className={styles["appointment-table-row-main-link"]}>
          <span
            className={styles["appointment-table-patient-name"]}
          >{`${row?.patient?.firstName} ${row?.patient?.lastName}`}</span>
        </div>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.office"
        ),
      },
      dataField: "office",
      text: state?.officeId
        ? "Doctor Name"
        : t("patientAppointmentMembers.appointmentTableHeading.office"),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {state?.officeId
            ? `${row?.doctor?.firstName} ${row?.doctor?.lastName}`
            : row?.office?.name}
        </span>
      ),
      sort: true,
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentDate"
        ),
      },
      dataField: "appointmentDate",
      text: "Previous Appointment Date",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {formatUTCDateTime(row?.previousAppointment)}
        </span>
      ),
      sort: true,
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentTime"
        ),
      },
      dataField: "appointmentTime",
      text: t(
        "patientAppointmentMembers.appointmentTableHeading.appointmentTime"
      ),
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.previousAppointmentTimeSlot?.length > 1
            ? `${row?.previousAppointmentTimeSlot[0]} - ${
                row?.previousAppointmentTimeSlot[
                  row?.previousAppointmentTimeSlot?.length - 1
                ]
              }`
            : row?.previousAppointmentTimeSlot[0]}
        </span>
      ),
      sort: true,
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentDate"
        ),
      },
      dataField: "appointmentDate",
      text: "Due Date",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {formatUTCDateTime(row?.recallDate)}
        </span>
      ),
      sort: true,
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.appointmentType"
        ),
      },
      dataField: "appointmentType",
      text: "Recall Type",
      formatter: (cellContent, row) => (
        <span className={styles["patient-appointment-table-common-columns"]}>
          {row?.recallType?.title}
        </span>
      ),
      sort: true,
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.status"
        ),
      },
      dataField: "status",
      text: t("patientAppointmentMembers.appointmentTableHeading.status"),
      formatter: (cellContent, row) => (
        <div class={styles["table-status-box"]}>
          <Button
            className={`appointment-table-custom-btn ${getStatusClass(
              row?.recallStatus
            )}`}
          >
            <span>{getStatus(row?.recallStatus)}</span>
          </Button>
        </div>
      ),
    },

    {
      attrs: {
        datatitle: t(
          "patientAppointmentMembers.appointmentTableHeading.action"
        ),
      },
      dataField: "action",
      text: t("patientAppointmentMembers.appointmentTableHeading.action"),
      formatter: (cellContent, row) => {
        const menuOptions = getMenuoptions(row);
        return (
          <>
            {row?.recallStatus != 10 ? (
              <UncontrolledDropdown>
                <DropdownToggle
                  caret={false}
                  tag="div"
                  className="cursor-pointer"
                >
                  <div className={`ellipsis-icon`}>
                    <ThreeDotsVertical />
                  </div>
                </DropdownToggle>
                <DropdownMenu right className={styles["office-dropdown-menu"]}>
                  {menuOptions.map((option, index) => (
                    <DropdownItem
                      key={index}
                      className={styles["office-dropdown-item"]}
                    >
                      <p
                        className="m-0"
                        onClick={(e) => option?.onclick(e, row)}
                      >
                        {option?.text}
                      </p>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div className={styles["appointment-table-box"] + " " + "w-100"}>
      {!isFrompatientDetail && (
        <div
          class={
            styles["appointment-search-box"] +
            " " +
            "search-box float-right mb-4"
          }
        >
          <input
            type="text"
            placeholder="Search by Patient Name"
            value={searchValue}
            onChange={handleInputChange}
          />
          <span class="ico">
            <img src={SearchIcon} alt="icon" />
          </span>
        </div>
      )}
      {appointments?.length === 0 ? (
        <div className={styles["not_found"]}>
          <Empty Message={t("waitingList.notFound")} />
        </div>
      ) : (
        <div className="test-table-box">
          <Table
            keyField="id"
            data={appointments}
            columns={columns}
            bordered={false}
            handlePagination={setPageNumber}
            pageNumber={pageNumber}
            totalItems={totalItems}
            pageSize={PAGE_SIZE}
          />
        </div>
      )}
      {isConfirmModal && (
        <CancelNextVisitModal
          closeModal={handleConfirmToggle}
          isModalOpen={isConfirmModal}
          confirmAction={cancelNextRecallDate}
          appointmentID={appointmentDetail?.id}
          recallDate={appointmentDetail?.recallDate}
        />
      )}
    </div>
  );
}
export default withTranslation()(RecallAppointmentTable);
