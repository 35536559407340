import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import styles from "../DiscussionNotes.module.scss";
import { useMutation } from "react-query";
import { handleError, handleSuccess } from "utils";
import { deleteDiscussionNote } from "repositories/discussion-notes-repository";
import Loader from "components/Loader";

const DeleteModal = ({ t, selectNote, isDeleteModalOpen, closeDeleteModal, deleteNotes }) => {

  const { mutate: deleteDiscussionMutate, isLoading: updateLoading } = useMutation(
    (id) => deleteDiscussionNote(id), {
    onSuccess: (data) => {
      handleSuccess(data.message);
      if (deleteNotes) deleteNotes(selectNote?.id)
      closeDeleteModal();
    },
    onError: (err) => {
      handleError(err);
    }
  });

  const handleDelete = async () => {
    deleteDiscussionMutate(selectNote?.id)
  }
  return (
    <Modal
      isOpen={isDeleteModalOpen}
      toggle={closeDeleteModal}
      className="modal-dialog-centered modal-lg"
      modalClassName="custom-modal"
    >
      {updateLoading && <Loader />}
      <span className="close-btn" onClick={closeDeleteModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <div className={styles.delete_modal_container}>
          <Text size="25px" weight="500" color="#111B45">
            {t('discussionNote.deleteNote')}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {t("discussionNote.deleteMessagse")}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {t("discussionNote.stillDelete")}
          </Text>

          <div className={styles.btn_container + " " + "btn-box d-md-flex"}>
            <button
              className={
                styles.green_btn + " " + "button button-round button-shadow"
              }
              title="save"
              onClick={handleDelete}
            >
              {t("discussionNote.delete")}
            </button>
            <button
              className={
                styles.white_btn + " button button-round button-border btn-mobile-link button-dark"
              }
              title="cancel"
              onClick={closeDeleteModal}
            >
              {t("notes.cancel")}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(DeleteModal);
