import React, { useEffect, useState } from "react";
import Page from "components/Page";
import styles from "../../accountpreferences.module.scss";
import "../../AccountPreference.scss";
import { Button, Card } from "reactstrap";
import ChatIcon from "./../../../../assets/images/chat-input-icon.svg";
import AttachmentIconFile from "./../../../../assets/images/attachment_ico_file.png";
import deleteIcon from "./../../../../assets/images/delete-icon.svg";
import Input from "components/Input";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import Editor from "react-pell";
import colorPicker from "./../../../../assets/images/color-picker.svg";
import PlaceholdersModal from "pages/AccountPreferences/modals/PlaceholdersModal";
import PreviewModal from "pages/AccountPreferences/modals/PreviewModal";
import toast from "react-hot-toast";
import useUploadService from "hooks/useUploadService";
import { decodeId, getBlobnameFromUrl } from "utils";
import { useSelector } from "react-redux";
import {
  addMessageTemplate,
  updateMessageTemplate,
  viewMessageTemplate,
} from "repositories/message-templates-repository";
import constants from "./../../../../constants";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryClient } from "react-query";
import useAddNewHistoryMessageModule from "accountOwner/pages/Appointments/hooks/useAddNewHistoryMessageModule";

let scheme = {
  templateName: "",
  notificationTitle: "",
  notificationContent: "",
  emailContent: "",
  attachmentLink: "",
};

const options = [
  { id: 1, label: "Email", name: "isEmail" },
  { id: 2, label: "SMS", name: "isSms" },
  { id: 3, label: "Notification", name: "isNotifications" },
];

const pageNumber = 1;
const pageSize = 100;

const ManageMessageTemplates = ({ history, t }) => {
  const location = useLocation();
  let { officeId, requestId } = useParams();
  let { patientId } = useParams();

  const isCreateTemplate = location?.pathname.includes(
    "create-message-template"
  );
  let { state } = location;
  const redirectUrl = isCreateTemplate
    ? constants.routes.appointment.addAppointmentReminderPlan
      .replace(":officeId", officeId)
      .replace(":requestId", requestId)
    : constants.routes.accountPreference.messageTemplates;
  const CustomInputClass = "global-preference-custom-input";
  const profile = useSelector((state) => state?.userProfile?.profile);
  const isAccountOwner =
    profile?.role?.systemRole === constants.systemRoles.accountOwner;
  const _selectedOwner = JSON.parse(localStorage.getItem("selectedOwner"));

  const accept = constants.chat.acceptForMessageInput;
  const allowedTypes = constants.chat.allowedTypesForMessage;
  let { templateId } = useParams();
  templateId = decodeId(templateId);

  const [formFields, setformFields] = useState(scheme);
  const [errors, setErrors] = useState({});
  const [showPlaceholderModal, setShowPlaceholderModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isPreviewForEmailContent, setIsPreviewForEmailContent] =
    useState(false);
  const [placeholderSelectedFor, setPlaceholderSelectedFor] = useState("");
  const [contentForPreview, setContentForPreview] = useState({
    content: "",
    title: "",
  });
  const [attachmentFile, setAttachmentFile] = useState({
    path: "",
    file: null,
  });

  const {
    data: { messageNotifications, officeDetail, error },
    methods: { handleSubmit, handleNotificationsChange },
  } = useAddNewHistoryMessageModule(t);
  const { upload: uploadImage } = useUploadService();
  const queryClient = useQueryClient();

  const { templateName, notificationTitle, notificationContent, emailContent } =
    formFields;

  const containerName = "globalnotesattachments";

  useEffect(() => {
    if (templateId) {
      getMessageTemplateDetails();
    }
  }, []);

  const getMessageTemplateDetails = async () => {
    setShowLoader(true);
    try {
      let response = await viewMessageTemplate(templateId);
      const templateData = response?.data;

      if (templateData) {
        setformFields(templateData);

        if (templateData.attachmentLink) {
          setAttachmentFile({
            path: templateData.attachmentLink,
            file: null,
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    }

    setShowLoader(false);
  };

  const goBack = () => {
    if (state?.fromWaitingDetail) {
      history.push({
        pathname: constants.routes.appointment.addAppointmentReminderPlan
          .replace(":officeId", officeId)
          .replace(":requestId", requestId), state
      })
    } else {
      history.goBack();
    }
  };

  const errorMessage = {
    templateName: t("form.errors.emptyField", {
      field: t("accountPreferences.templateName"),
    }),
    notificationTitle: t("form.errors.emptyField", {
      field: t("accountPreferences.messageTitle"),
    }),
    notificationContent: t("form.errors.emptyField", {
      field: t("accountPreferences.contentPushNotification"),
    }),
    emailContent: t("form.errors.emptyField", {
      field: t("accountPreferences.contentForEmail"),
    }),
  };

  const handleChange = (event) => {
    const errorCopy = cloneDeep(errors);
    const { name, value } = event.target;

    if (!value.trim()?.length) {
      errorCopy[name] = errorMessage[name];
    }

    if (value?.trim()?.length) {
      delete errorCopy[name];
    }

    setformFields((prevProps) => ({ ...prevProps, [name]: value }));
    setErrors(errorCopy);
  };

  const handleEditorChnage = (value) => {
    handleChange({ target: { name: "emailContent", value: value } });
  };

  const handlePlaceholderValue = (value) => {
    switch (placeholderSelectedFor) {
      case t("accountPreferences.messageTitle"):
        setformFields({
          ...formFields,
          notificationTitle: `${notificationTitle} ${value}`,
        });
        break;

      case t("accountPreferences.contentPushNotification"):
        setformFields({
          ...formFields,
          notificationContent: `${notificationContent} ${value}`,
        });
        break;

      case t("accountPreferences.contentForEmail"):
        setformFields({
          ...formFields,
          emailContent: `${emailContent} ${value}`,
        });
        break;

      default:
        break;
    }
  };

  const handlePreview = (previewValue, previewName) => {
    setContentForPreview({
      content: previewValue,
      title: previewName,
    });
  };

  const getAttchmentFile = (event) => {
    if (!event || !event.target) return;

    const files = event.target.files;
    if (!files.length) return;

    if (!allowedTypes.includes(files[0]?.type)) {
      toast.error(t("fileNotSupported"));
      return;
    }

    const fileSizeInBytes = files[0]?.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMB > 5) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        attachmentLink: t("form.errors.fileSize"),
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        attachmentLink: "",
      }));

      setAttachmentFile({
        path: URL.createObjectURL(files[0]),
        file: files[0],
      });
    }
  };

  const removeAttachment = () => {
    setAttachmentFile({
      path: "",
      file: null,
    });
  };

  const isValid = () => {
    const errorCopy = {};
    let isValidField = true;

    if (!templateName?.trim()?.length) {
      errorCopy.templateName = errorMessage.templateName;
      isValidField = false;
    }

    if (
      (messageNotifications?.isSms || messageNotifications?.isNotifications) &&
      !notificationTitle?.trim()?.length
    ) {
      errorCopy.notificationTitle = errorMessage.notificationTitle;
      isValidField = false;
    }

    if (
      (messageNotifications?.isSms || messageNotifications?.isNotifications) &&
      !notificationContent?.trim()?.length
    ) {
      errorCopy.notificationContent = errorMessage.notificationContent;
      isValidField = false;
    }

    if (messageNotifications?.isEmail && !emailContent?.trim()?.length) {
      errorCopy.emailContent = errorMessage.emailContent;
      isValidField = false;
    }

    setErrors({ ...errorCopy });
    return isValidField;
  };

  const saveMessageTemplate = async (e) => {
    if (!isValid()) return;
    setShowLoader(true);

    try {
      let imageBlobName;
      if (attachmentFile && attachmentFile.file && attachmentFile.path) {
        const [err, blobData] = await uploadImage(
          attachmentFile.file,
          containerName
        );

        let fileName = blobData.blobUrl;

        imageBlobName = `${containerName}/${blobData.blobName}`;

        if (err) {
          throw new Error(err);
        }

        setAttachmentFile({
          path: fileName,
          file: null,
        });
      }

      if (attachmentFile && attachmentFile.path && !attachmentFile.file) {
        imageBlobName = `${containerName}/${getBlobnameFromUrl(
          attachmentFile.path,
          containerName
        )}`;
      }

      const params = {
        ownerId: isAccountOwner ? profile?.id : _selectedOwner?.id,
        templateName,
        notificationTitle,
        notificationContent,
        emailContent,
        attachmentLink: imageBlobName,
      };

      let res = templateId
        ? await updateMessageTemplate({
          ...params,
          messageTemplateId: +templateId,
        })
        : await addMessageTemplate(params);
      if (patientId || state?.length) {
        let patientIds = state?.length
          ? state?.map((patient) => patient?.id)
          : [];
        await handleSubmit(e, res?.data?.id, patientIds);
      } else {
        queryClient.invalidateQueries([
          "get-message-templates-list",
          pageNumber,
          pageSize,
          params?.ownerId,
        ]);
        toast.success(res.message);
        // history.push(redirectUrl);
        goBack();
      }
    } catch (err) {
      toast.error(err.message);
    }

    setShowLoader(false);
  };

  const getAttachmentName = (link) => {
    return link.substring(link.lastIndexOf("/") + 1);
  };

  const renderHeader = () => (
    <div className={styles["message-templates-heading"]}>
      <p className={styles["select-office"] + " " + "mb-0"}>
        {templateId
          ? t("accountPreferences.editTemplate")
          : t("accountPreferences.newTemplate")}
      </p>
      {officeDetail?.name ? <p>{officeDetail?.name}</p> : ""}
    </div>
  );

  const renderFooter = () => (
    <div className={styles["btn-container"]}>
      <Button
        className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
        onClick={(e) => saveMessageTemplate(e)}
      >
        {t("Save")}
      </Button>
      <Button
        className="button button-round button-border button-dark btn-mobile-link "
        onClick={() => goBack()}
      >
        {t("cancel")}
      </Button>
    </div>
  );

  const renderTemplateForm = () => (
    <Card className={styles["global-preference-form"] + " " + "form-wrapper"}>
      <div className={styles["message-form-container"]}>
        <div>
          <Input
            Title={t("accountPreferences.templateName")}
            Type="text"
            Name={"templateName"}
            Placeholder={t("accountPreferences.templateName")}
            Error={errors.templateName}
            Value={templateName}
            HandleChange={handleChange}
            MaxLength={120}
          />
        </div>
        {(patientId || state?.length) && (
          <div className={styles.details_checkbox}>
            {options.map((option) => (
              <div
                key={option.id}
                className="ch-checkbox c-field all-event-checkbox m-0"
              >
                <label>
                  <input
                    type="checkbox"
                    name={option?.name}
                    checked={messageNotifications[option?.name]}
                    onChange={handleNotificationsChange}
                  />
                  <span>{option.label}</span>
                </label>
              </div>
            ))}
          </div>
        )}
        {error && (
          <span className="error-msg">{error}</span>
        )}
        <div className={styles["message-form"]}>
          {(messageNotifications?.isSms ||
            messageNotifications?.isNotifications) && (
              <div className={styles["form-input-box"]}>
                {t("accountPreferences.messageTitle")}
                <div className={styles["form-icon-box"]}>
                  <div
                    className={styles["form-input-icon"] + " " + "cursor-pointer"}
                    onClick={() => {
                      setPlaceholderSelectedFor(
                        t("accountPreferences.messageTitle")
                      );
                      setShowPlaceholderModal(true);
                    }}
                  >
                    <img src={ChatIcon} alt="message template icon" />
                  </div>
                  <button
                    className={styles["preview-btn"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPreview(true);
                      handlePreview(
                        notificationTitle,
                        t("accountPreferences.previewTitleSubject")
                      );
                    }}
                  >
                    {t("accountPreferences.preview")}
                  </button>
                </div>
                <Input
                  CustomClass={CustomInputClass}
                  Type="text"
                  Name={"notificationTitle"}
                  Placeholder={t("accountPreferences.messageTitle")}
                  Error={errors.notificationTitle}
                  Value={notificationTitle}
                  HandleChange={handleChange}
                  MaxLength={120}
                />
              </div>
            )}
          {(messageNotifications?.isSms ||
            messageNotifications?.isNotifications) && (
              <div className={styles["form-input-box"]}>
                {t("accountPreferences.contentPushNotification")}
                <div className={styles["form-icon-box"]}>
                  <div
                    className={styles["form-input-icon"] + " " + "cursor-pointer"}
                    onClick={() => {
                      setPlaceholderSelectedFor(
                        t("accountPreferences.contentPushNotification")
                      );
                      setShowPlaceholderModal(true);
                    }}
                  >
                    <img src={ChatIcon} alt="message template icon" />
                  </div>
                  <button
                    className={styles["preview-btn"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPreview(true);
                      handlePreview(
                        notificationContent,
                        t("accountPreferences.previewForNotification")
                      );
                    }}
                  >
                    {t("accountPreferences.preview")}
                  </button>
                </div>
                <Input
                  CustomClass={CustomInputClass}
                  Type="text"
                  Name={"notificationContent"}
                  Placeholder={t("accountPreferences.contentPushNotification")}
                  Error={errors.notificationContent}
                  Value={notificationContent}
                  HandleChange={handleChange}
                  MaxLength={240}
                />
              </div>
            )}
          {messageNotifications?.isEmail && (
            <div className={styles["form-input-box"]}>
              {t("accountPreferences.contentForEmail")}
              <div className={"text-right"}>
                <button
                  className={styles["preview-btn"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPreview(true);
                    setIsPreviewForEmailContent(true);
                    handlePreview(
                      emailContent,
                      t("accountPreferences.previewForEmail")
                    );
                  }}
                >
                  {t("accountPreferences.preview")}
                </button>
              </div>
              <Editor
                defaultContent={emailContent}
                actions={[
                  "bold",
                  "italic",
                  "underline",
                  "link",
                  {
                    icon: `<span> <img src=${colorPicker} alt= "picker" /> <input type="color" class="color-picker-input" oninput="document.execCommand('styleWithCSS', true, null);document.execCommand('foreColor', false, this.value); " /></span>`,
                    title: "Change Text Color",
                    result: () => true,
                  },
                  {
                    icon: `<span> <img src=${ChatIcon} alt="message template icon" /></span>`,
                    title: "Placeholder",
                    result: () => {
                      setPlaceholderSelectedFor(
                        t("accountPreferences.contentForEmail")
                      );
                      setShowPlaceholderModal(true);
                    },
                  },
                ]}
                actionBarClass="my-custom-class"
                onChange={handleEditorChnage}
              />
              {errors.emailContent && (
                <span className="error-msg">{errors.emailContent}</span>
              )}
            </div>
          )}

          {messageNotifications?.isEmail && (
            <div className={styles["file-input-container"]}>
              <span> {t("accountPreferences.attachment")}</span>

              <div className={styles["file-icon-box"]}>
                <div className={styles["form-input-icon"]}>
                  <img src={AttachmentIconFile} alt="message template icon" />
                </div>
                {!templateId ? (
                  <>
                    {attachmentFile?.file?.name ? (
                      <div className="attachemnt-container">
                        <span>{attachmentFile?.file?.name}</span>
                        <span
                          className="cursor-pointer"
                          onClick={() => removeAttachment()}
                        >
                          <img src={deleteIcon} alt="delete" />
                        </span>
                      </div>
                    ) : (
                      <span>{t("accountPreferences.noAttachment")}</span>
                    )}
                  </>
                ) : (
                  <>
                    {attachmentFile?.file?.name || attachmentFile?.path ? (
                      <div className="attachemnt-container">
                        <span>
                          {attachmentFile?.file?.name
                            ? attachmentFile?.file?.name
                            : getAttachmentName(attachmentFile?.path)}
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() => removeAttachment()}
                        >
                          <img src={deleteIcon} alt="delete" />
                        </span>
                      </div>
                    ) : (
                      <span>{t("accountPreferences.noAttachment")}</span>
                    )}
                  </>
                )}
              </div>
              {((!templateId && !attachmentFile?.file?.name) ||
                (templateId && !attachmentFile?.path)) && (
                  <label
                    htmlFor="fileUpload"
                    className={styles["attach-file-btn"] + " " + "cursor-pointer"}
                  >
                    {t("accountPreferences.attachFile")}
                  </label>
                )}
              <input
                id="fileUpload"
                className={styles["file-input-box"]}
                type="file"
                accept={accept}
                onChange={getAttchmentFile}
                multiple={false}
              />
            </div>
          )}
          {errors?.attachmentLink && (
            <span className="error-msg">{errors.attachmentLink}</span>
          )}
        </div>
      </div>
      {renderFooter()}
    </Card>
  );

  return (
    <div>
      <Page onBack={goBack} className={styles["global-main-page"]}>
        {showLoader && <Loader />}
        <div className={styles["message-templates"]}>
          {renderHeader()}
          {renderTemplateForm()}
        </div>
        {showPlaceholderModal && (
          <PlaceholdersModal
            subTitle={t("accountPreferences.template")}
            showPlaceholderModal={showPlaceholderModal}
            setShowPlaceholderModal={setShowPlaceholderModal}
            handleSelectedPlaceholder={(selectedPlaceholder) => {
              handlePlaceholderValue(selectedPlaceholder);
            }}
          />
        )}
        {showPreview && (
          <PreviewModal
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            title={t("accountPreferences.previewModalTitle", {
              previewFor: contentForPreview.title,
            })}
            messageTitle={notificationTitle}
            content={contentForPreview.content}
            isPreviewForEmailContent={isPreviewForEmailContent}
            setIsPreviewForEmailContent={setIsPreviewForEmailContent}
            btnText={t("accountPreferences.done")}
          />
        )}
      </Page>
    </div>
  );
};

export default withTranslation()(ManageMessageTemplates);
