import React, { useEffect } from "react";
import styles from "../Dashboard.module.scss";
import { withTranslation } from "react-i18next";
import NotAccessible from "../components/NotAccessible";
import { LoaderIcon } from "react-hot-toast";
import useRequestAcceptModule from "../hooks/RequestAcceptModule";
import RequestApprovalCard from "./RequestApprovalCard";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuidv4 } from "uuid"; 

const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const RequestAndApprovals = ({
  t,
  appliedOfficeFilters,
  appliedStaffFilters,
  isSchedulerOnly,
}) => {
  const {
    data: { isLoading, pageNumber, acceptAndReject, totalPages },
    methods: {
      setPageNumber,
      setOfficeFilter,
      setStaffFilter,
      acceptRejectTimesheet,
      acceptRejectLeave,
      acceptRejectEditTimesheetRequest,
    },
  } = useRequestAcceptModule();

  useEffect(() => {
    if (!isSchedulerOnly) {
      setOfficeFilter(appliedOfficeFilters);
      setStaffFilter(appliedStaffFilters);
    }
  }, [appliedOfficeFilters, appliedStaffFilters, isSchedulerOnly]);

  let items = (
    <div className={styles.flex_req_container}>
      {acceptAndReject?.map((updateList) => {
        return (
          <RequestApprovalCard
            list={updateList}
            key={uuidv4()}
            handleAction={(actionType, actionTypeId, statusId, isApproved) => {
              if (actionType === "Timesheet") {
                acceptRejectTimesheet(actionTypeId, statusId);
              }
              if (actionType === "Leave") {
                acceptRejectLeave(actionTypeId, isApproved);
              }
              if (actionType === "EditTimesheetRequest") {
                acceptRejectEditTimesheetRequest(actionTypeId, isApproved);
              }
            }}
          />
        );
      })}
    </div>
  );

  return (
    <div
      className={`${styles.request_and_approval_container} ${
        !acceptAndReject?.length ? styles.request_and_approval_centered : ""
      } ${isLoading ? styles.request_and_approval_loading : ""}`}
    >
      {isLoading && pageNumber === 1 ? (
        <LoaderIcon className={styles.loader_style} />
      ) : (
        <>
          {!acceptAndReject?.length ? (
            <div className={styles["not_found"]}>
              <NotAccessible
                isNotFound={true}
                Message={t("dashBoard.approvalsNotFound")}
              />
            </div>
          ) : (
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
              className="w-100"
            >
              <InfiniteScroll
                dataLength={acceptAndReject?.length}
                hasMore={pageNumber < totalPages}
                loader={<LoaderIcon className={styles.loader_style} />}
                next={() => {
                  pageNumber < totalPages && setPageNumber((v) => v + 1);
                }}
                scrollableTarget="scrollableNotifications"
              >
                {items}
              </InfiniteScroll>
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

export default withTranslation()(RequestAndApprovals);
