import React, { useState } from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "../../../DiscussionNotes/DiscussionNotes.module.scss";
import Loader from "components/Loader";
import { SaveEmploymentProof } from "repositories/employment-proof";
import toast from "react-hot-toast";

const SaveTemplateModal = ({ t, isSaveTemplateOpen, closeSaveTemplateModal, proofId }) => {

    const [loader, setLoader] = useState(false);

    const handleSave = async () => {
        setLoader(true);
        try {
            let res = await SaveEmploymentProof(proofId);
            toast.success(res.message);
            if (res.status) closeSaveTemplateModal();
        } catch (err) {
            toast.error(err.message);
            setLoader(false);
        } finally {
            setLoader(false);

        }
    }

    return (
        <Modal
            isOpen={isSaveTemplateOpen}
            toggle={closeSaveTemplateModal}
            className="modal-dialog-centered modal-lg"
            modalClassName="custom-modal"
        >
            {loader && <Loader />}
            <span className="close-btn" onClick={closeSaveTemplateModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles.delete_modal_container}>
                    <Text size="25px" weight="500" color="#111B45">
                        {t('employmentProof.saveTemplateTitle')}
                    </Text>
                    <Text size="16px" weight="300" color="#535B5F">
                        {t("employmentProof.saveTemplateMsg")}
                    </Text>

                    <div className={styles.btn_container + " " + "btn-box d-md-flex"}>
                        <button
                            className={
                                styles.green_btn + " " + "button button-round button-shadow"
                            }
                            title="save"
                            onClick={handleSave}
                        >
                            {t("employmentProof.save")}
                        </button>
                        <button
                            className={
                                styles.white_btn + " button button-round button-border btn-mobile-link button-dark"
                            }
                            title="cancel"
                            onClick={closeSaveTemplateModal}
                        >
                            {t("employmentProof.form.cancel")}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default SaveTemplateModal
