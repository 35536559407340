import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Text from 'components/Text';
import Button from 'components/Button';
import { withTranslation } from 'react-i18next';
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../../Notes/Notes.module.scss"
import Loader from 'components/Loader';

const ConfirmationPopup = ({ t, isModalOpen, closeModal, handleConfirm, id, loader }) => {
    return (
        <Modal
            isOpen={isModalOpen}
            toggle={closeModal}
            className="modal-lg modal-dialog-centered"
            modalClassName="custom-modal"
        >
            {loader && <Loader/>}
            <span className="close-btn" onClick={closeModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles.alert_popup_content_wrapper}>
                    <h2 className="page-title">{t('scheduler.confirmationTitle')}</h2>
                    <Text size="16px" weight="300" color="#535B5F" marginTop="30px" lineHeight="24px">{t(`scheduler.${id}`)}</Text>
                    
                    <div className={styles.main_btn_container}>
                        <Button onClick={handleConfirm}>{t('scheduler.confirm')}</Button>
                        <Button borderedSecondaryNew onClick={closeModal}>
                            <span>{t('cancel')}</span>
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
export default withTranslation()(ConfirmationPopup);