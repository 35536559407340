import { useState, useEffect } from 'react';
import constants from "../../../../constants";
import { decodeId, encodeId } from 'utils';
import { useSelector } from 'react-redux';
import { sendDoctorMessageHistory } from 'repositories/patient-appointment-repository';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useReminderModule from './useReminderModule';
import { useOfficeDetail } from 'repositories/office-repository';
import toast from 'react-hot-toast';

const pageSize = 10;

const useAddNewHistoryMessageModule = (t) => {
    const history = useHistory();
    const location = useLocation();
    const profile = useSelector(state => state.userProfile.profile);
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(null);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [appliedMessageTemplate, setAppliedMessageTemplate] = useState(null);
    const [appliedSelectedPatients, setAppliedSelectedPatients] = useState(null);
    const [isSelectMessageModalOpen, setIsSelectMessageModalOpen] = useState(false);
    const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
    const [isAttachMent, setIsAttachMent] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [messageNotifications, setMessageNotifications] = useState({
        isEmail: true,
        isSms: true,
        isNotifications: true,
    });
    const [contentForPreview, setContentForPreview] = useState({
        content: "",
        title: "",
    });
    const [error, setError] = useState("");
    let { officeId, patientId, appointmentId } = useParams();
    officeId = +decodeId(officeId);
    patientId = +decodeId(patientId);
    appointmentId = +decodeId(appointmentId);
    const {
        data: {
            templateOptions,
            templateLists
        }
    } = useReminderModule(t) || {};

    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(officeId);


    const goBack = () => {
        history.push(constants.routes.appointment.patientMessageCenter.replace(':officeId', encodeId(officeId)).replace("/:patientId?", patientId ? `/${encodeId(patientId)}` : "").replace('/:appointmentId', appointmentId ? `/${encodeId(appointmentId)}` : ""), { ...location.state } )
    };


    const handleRedirectAddCustomeMessage = () => {
        history.push({ pathname: constants.routes.appointment.createMessageHistoryTemplates.replace(':officeId', encodeId(officeId)).replace("/:patientId?", patientId ? `/${encodeId(patientId)}` : ""), state: appliedSelectedPatients });
    };

    const handleToggleModal = () => {
        if (appliedSelectedPatients?.length || patientId) {
            setIsSelectMessageModalOpen(!isSelectMessageModalOpen);
        }
        if (!patientId && !appliedSelectedPatients?.length) {
            setError(t("patientAppointmentMembers.atLeastPaitent"));
        }
    }

    const handleTogglePatientModal = () => {
        setIsPatientModalOpen(!isPatientModalOpen);
    }

    const handleToggleAttachment = () => {
        setIsAttachMent(!isAttachMent);
    }

    const handleNotificationsChange = (e) => {
        const { name, checked } = e.target;

        const updatedNotifications = { ...messageNotifications, [name]: checked };
        const isValid = Object.values(updatedNotifications).some((value) => value);
        if (!isValid) {
            setError(t("patientAppointmentMembers.atleastMessage"));
            return;
        }
        setError("");
        setMessageNotifications(updatedNotifications);
    };


    const handleSelectTemplate = () => {
        if (selectedMessageTemplate?.id) {
            setAppliedMessageTemplate(selectedMessageTemplate)
            setIsSelectMessageModalOpen(false);
        }
    }

    const handleSelectedPatients = () => {
        if (selectedPatients?.length) {
            setAppliedSelectedPatients(selectedPatients);
            setIsPatientModalOpen(false);
            setError("");
        }
    }

    const handlePreview = (previewValue, previewName) => {
        setContentForPreview({
            content: previewValue,
            title: previewName,
        });
    };

    const handleSubmit = async (e, messageTempId = "", patient = []) => {
        e.preventDefault();
        setShowLoader(true);
        try {
            let params = {
                appointmentId: appointmentId,
                patientIds: [
                    patientId
                ],
                messageTemplateId: appliedMessageTemplate?.id || messageTempId,
                isSms: messageNotifications.isSms,
                isEmail: messageNotifications.isEmail,
                isPushNotification: messageNotifications.isNotifications,
                officeId: officeId,
                doctorId: profile?.id
            }
            if (!patientId) {
                let patientIds = appliedSelectedPatients?.map(patient => patient?.id);
                params.patientIds = patientIds || patient;
                params.doctorId = null;
                delete params.appointmentId;
            }
            if (appliedMessageTemplate?.attachmentLink) {
                params.isAttachment = isAttachMent
            }
            if (appliedMessageTemplate?.attachmentLink && isAttachMent) {
                params.attachmentLink = appliedMessageTemplate?.attachmentLink
            }
            let res = await sendDoctorMessageHistory(params);
            if (res?.statusCode === 200) {
                goBack();
                toast.success(res.message);
            }
        } catch (err) {
            setShowLoader(true);
            toast.error(err.message);
        } finally {
            setShowLoader(false);
        }
    }


    const loading = isLoadingOfficeDetail || showLoader;

    return {
        data: {
            loading,
            templateLists,
            officeDetail,
            templateOptions,
            selectedMessageTemplate,
            isSelectMessageModalOpen,
            appliedMessageTemplate,
            messageNotifications,
            isAttachMent,
            patientId,
            isPatientModalOpen,
            officeId,
            selectedPatients,
            appliedSelectedPatients,
            contentForPreview,
            error
        },
        methods: {
            goBack,
            setSelectedMessageTemplate,
            handleToggleModal,
            handleSelectTemplate,
            handleNotificationsChange,
            handleToggleAttachment,
            handleSubmit,
            handleRedirectAddCustomeMessage,
            handleTogglePatientModal,
            setSelectedPatients,
            handleSelectedPatients,
            handlePreview
        }
    }
};

export default useAddNewHistoryMessageModule;