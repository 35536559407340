import React, { useState } from "react";
import styles from "../Dashboard.module.scss";
import Text from "components/Text";
import OpenInIcon from "../../../../assets/images/open_in_new.svg";

import AddNewTaskModal from "./modals/AddNewTaskModal";
import MyTasks from "./MyTask";
import AdminTask from "./AdminTask";
import { useSelector } from "react-redux";
import constants from "../../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setStorage } from "utils";
import Tooltip from "reactstrap/lib/Tooltip";
import { withTranslation } from "react-i18next";

const Tasks = ({
  appliedOfficeFilters,
  appliedStaffFilters,
  selectedOwner,
  isSchedulerOnly,
  t,
}) => {
  const history = useHistory();
  const [activeToggle, setActiveToggle] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipEditOpen, setTooltipEditOpen] = useState(false);
  const [isArchive, setArchive] = useState(false);
  const openModalHandler = () => setIsModalOpen(true);
  const closeModalHandler = () => setIsModalOpen(false);
  const profile = useSelector((state) => state.userProfile.profile);

  const handleToggleChange = (toggle) => {
    setActiveToggle(toggle);
  };

  const handleRedirect = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    history.push(constants.routes.taskManagements.taskManagement);
    setStorage("isChatFromDashBoard", true);
  };

  return (
    <>
      <div
        className={
          styles.notes_tab_wrapper + " " + styles.border_top_left_right
        }
      >
        <div className={`p-3 ${styles.header_wrapper}`}>
          <Text size="14px" weight="600" color="#2D3245" lineHeight="26px">
            {t("dashBoard.tasks")}
          </Text>
          <div onClick={handleRedirect} id="task_tooltip">
            <img src={OpenInIcon} alt="open-icon" />
          </div>
          <Tooltip
            isOpen={tooltipEditOpen}
            placement="left"
            target="task_tooltip"
            toggle={() => {
              setTooltipEditOpen(!tooltipEditOpen);
            }}
          >
            {t("dashBoard.gotoTasks")}
          </Tooltip>
        </div>
      </div>
      {profile?.role?.systemRole === constants.systemRoles.accountOwner ? (
        <div
          className={
            profile?.role?.systemRole === constants.systemRoles.accountOwner
              ? styles.task_card_container_staff
              : styles.task_card_container
          }
          id="scrollableDivTwo"
        >
          <MyTasks
            isArchive={isArchive}
            appliedOfficeFilters={appliedOfficeFilters}
            appliedStaffFilters={appliedStaffFilters}
            selectedOwner={selectedOwner}
            handleRedirect={handleRedirect}
            isSchedulerOnly={isSchedulerOnly}
          />
        </div>
      ) : (
        <AdminTask
          isArchive={isArchive}
          appliedOfficeFilters={appliedOfficeFilters}
          appliedStaffFilters={appliedStaffFilters}
          selectedOwner={selectedOwner}
          handleRedirect={handleRedirect}
          isSchedulerOnly={isSchedulerOnly}
        />
      )}
      <AddNewTaskModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeModalHandler={closeModalHandler} // Add this prop to handle closing
      />
    </>
  );
};

export default withTranslation()(Tasks);
