import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../TaskManagement.module.scss";
import Text from "components/Text";
import { withTranslation } from "react-i18next";

const CalcelModal = ({ t, isModalOpen, toggleModal, handleAction, taskId }) => {
  const handleApply = () => {
    handleAction(taskId, "Cancel");
    toggleModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className="modal-dialog-centered modal-lg"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={toggleModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <div className={styles["delete_task_modal"]}>
          <Text size="25px" weight="500" color="#111B45">
            {t("taskManagement.cancelTask")}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {t("taskManagement.cancelConfirmation")}
          </Text>

          <div
            className={styles["delete_task_btn_wrapper"] + " btn-box d-md-flex"}
          >
            <button
              className={
                styles["delete_task_btn"] +
                " button button-round button-shadow mr-md-4 mb-3 w-sm-100"
              }
              title="save"
              onClick={handleApply}
            >
              {t("taskManagement.yesCancel")}
            </button>
            <button
              className={
                styles["delete_task_btn"] +
                " mb-md-3 button button-round button-border btn-mobile-link button-dark"
              }
              title="cancel"
              onClick={toggleModal}
            >
              {t("taskManagement.noCancel")}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(CalcelModal);
