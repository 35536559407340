import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import styles from "../../../pages/Scheduler/OfficeSchedulerTab/SubTabs/EventsShiftsTab/EventsShiftsTab.module.scss";
import { cacheSideBarActive } from "utils";
import "../UpcomingTabs/UpcomingTabs.scss";
import RecallAppointmentTable from "../RecallAppointmentTable";
import useUpCommingModule from "../hooks/useUpCommingModule";
import { useAllRecallListAppointments } from "repositories/patient-appointment-repository";
import Loader from "components/Loader";
import GlobalListFilter from '../filter/index';
import constants from "../../../../constants";

const RecallTabs = ({ t, state, isFrompatientDetail=false }) => {

  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());
   let isRecall = true
  const {
    data: {
      isLoading,
      totalItems,
      pageNumber,
      appointments,
      showLoader,
      searchValue,
      officeFilter,
      statusFilter,
      dates,
      recallTypeFilter,
      isFilterApply,
      doctorFilter
    },
    methods: {
      setPageNumber,
      handleInputChange,
      setOfficeFilter,
      handleApplyFilters,
      resetFilter,
      setStatusFilter,
      updateDatesValues,
      setRecallTypeFilter,
      cancelNextRecallDate,
      setDocotrFilter
    }
  } = useUpCommingModule(useAllRecallListAppointments, state, "recall", isRecall);

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  return (
    <>
      <div className="scheduler-tabs-main-wrapper appointment-main-wrapper-one">
        {isLoading && <Loader />}
        {!isFrompatientDetail && <div >
          <GlobalListFilter
            isSidebarActive={isSidebarActive}
            handleSidebarToggle={handleSidebarToggle}
            resetFilter={resetFilter}
            applyFilter={handleApplyFilters}
            officeFilter={officeFilter}
            setOfficeFilter={setOfficeFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            dates={dates}
            updateDatesValues={updateDatesValues}
            appointmentStatusList={constants.recallAppointmentStatus}
            recallTypeFilter={recallTypeFilter}
            setRecallTypeFilter={setRecallTypeFilter}
            isRecall ={true}
            state={state}
            isFilterApply={isFilterApply}
            doctorFilter={doctorFilter}
            setDocotrFilter={setDocotrFilter}
          />
        </div>}

        <div className="tabs-right-col appointment-tab-right-col">
          <div className={styles["event-shift-header"]}>
            <RecallAppointmentTable
              appointments={appointments}
              totalItems={totalItems}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              state={state}
              searchValue={searchValue}
              handleInputChange={handleInputChange}
              cancelNextRecallDate={cancelNextRecallDate}
              isFrompatientDetail={isFrompatientDetail}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(RecallTabs);


{/* <Link to='/create-recall-appointment' >Recall</Link> */ }
