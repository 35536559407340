import React from "react";
import SearchIcon from "../../../../../assets/images/search.svg";
import styles from "../PatientMessageCenter.module.scss"
const PatientSearchBar = ({ handleSearchTerm, searchValue, t }) => {
    return (
        <div className={styles.new_search_bar}>
            <span className={styles.search_icon}>
                <img src={SearchIcon} alt="search icon" />
            </span>
            <input
                type="text"
                className={styles.search_input}
                placeholder="Search Patient"
                onChange={handleSearchTerm}
                value={searchValue}
            />
        </div>
    );
};

export default PatientSearchBar;
