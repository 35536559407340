import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import StickySidebar from "accountOwner/pages/Scheduler/components/StickySidebar";
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import TemPlateFilter from "./TemPlateFilter";
import DatePicker from "react-datepicker";
import { Portal } from "react-overlays";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import styles from "../../WaitingList/WaitingList.module.scss";
import DoctorFilter from "./DoctorFilter";
import PatientFilter from "./PatientFilter";

const PatientMessageFilter = ({
  t,
  isSidebarActive,
  handleSidebarToggle,
  templateLists,
  setMessageTemplateFilter,
  messageTemplateFilter,
  handleApplyFilters,
  resetFilter,
  isFilterApply,
  patientId,
  officeId,
  setDoctorFilter,
  setPatientFilter,
  doctorFilter,
  patientFilter,
  dates,
  updateDatesValues,
}) => {
  const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");
    return <Portal container={el}>{children}</Portal>;
  };

  const startDatePickerRef = useReadOnlyDateTextInput();
  const endDatePickerRef = useReadOnlyDateTextInput();
  const handleApplyFilter = () => {
    handleApplyFilters();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleResetFliter = () => {
    resetFilter();
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  const handleClose = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    if (mediaQuery.matches) handleSidebarToggle();
  };

  return (
    <StickySidebar
      isSidebarActive={isSidebarActive}
      handleSidebarToggle={handleSidebarToggle}
      resetFilter={handleResetFliter}
    >
      <Text size="15px" marginBottom="12px" weight="600" color="#102C42">
        {t("accountOwner.filters")}
        {isFilterApply && <span className={styles.red_dot}></span>}
      </Text>
      <Accordion
        preExpanded={["a"]}
        className="filter-accordion"
        allowZeroExpanded
      >
        <AccordionItem uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>{t("Type of Templates")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <TemPlateFilter
              t={t}
              selectedOffice={messageTemplateFilter}
              setOfficeFilter={setMessageTemplateFilter}
              templateLists={templateLists}
            />
          </AccordionItemPanel>
        </AccordionItem>
        {!patientId && (
          <AccordionItem uuid="b">
            <AccordionItemHeading>
              <AccordionItemButton>{t("Patient Name")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <PatientFilter
                t={t}
                selectedOffice={patientFilter}
                setOfficeFilter={setPatientFilter}
                officeId={officeId}
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}

        {!patientId && (
          <AccordionItem uuid="c">
            <AccordionItemHeading>
              <AccordionItemButton>{t("Doctor Name")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <DoctorFilter
                t={t}
                selectedOffice={doctorFilter}
                setOfficeFilter={setDoctorFilter}
                officeId={officeId}
              />
            </AccordionItemPanel>
          </AccordionItem>
        )}
      </Accordion>
      {!patientId && (
        <>
          <Text size="12px" marginBottom="12px" weight="400" color="#102C42">
            {t("accountOwner.dateRange")}
          </Text>

          <div className="invitation_date_picker">
            <div className="invitation_date">
              <div className="c-field">
                <label>{t("from")}</label>
                <div className="d-flex inputdate">
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="c-form-control"
                    selected={dates.from}
                    onChange={(e) => updateDatesValues(e, "from")}
                    maxDate={dates.to}
                    popperPlacement="bottom-start"
                    popperContainer={CalendarContainer}
                    ref={startDatePickerRef}
                  />
                </div>
              </div>
            </div>
            <div className="invitation_date">
              <div className="c-field">
                <label>{t("to")}</label>
                <div className="d-flex inputdate">
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="c-form-control"
                    selected={dates.to}
                    minDate={dates.from}
                    onChange={(e) => updateDatesValues(e, "to")}
                    popperPlacement="bottom-end"
                    popperContainer={CalendarContainer}
                    ref={endDatePickerRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="filter-btn-box filter-btn-box--sticky-footer">
        <button
          className={"button button-round button-shadow mr-3"}
          title={t("apply")}
          onClick={handleApplyFilter}
        >
          {t("apply")}
        </button>
        <button
          className={"button button-round button-border button-dark reset-btn"}
          title={t("reset")}
          onClick={handleResetFliter}
        >
          {t("reset")}
        </button>
        <button
          className={"button button-round button-border button-dark cancel-btn"}
          onClick={handleClose}
          title={t("close")}
        >
          {t("close")}
        </button>
      </div>
    </StickySidebar>
  );
};
export default withTranslation()(PatientMessageFilter);
