/* eslint-disable no-useless-concat */
import React, { useState, Suspense, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import Text from "components/Text";
import {
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Details from "../../../../pages/Appointments/AppointmentsTable/AddNewAppointment/Details";
import Loader from "components/Loader";
import AddUpcomingNotes from "./AddUpcomingNotes";
import AddUpcomingHistory from "./AddUpcomingHistory";
import PendingCard from "./PendingCard";
import styles from "../../Appointments.module.scss";
import "../../Appointments.scss";
import usePatientAppointmentDetailModule from "../../hooks/usePatientAppointmentDetailModule";
import useCommonModule from "../../hooks/useCommonModule";
import DefaultImage from "../../../../../assets/images/staff-default-rounded.png";
import RejectionModal from "../../modal/RejectionModal";
import ConfirmationModal from "../../modal/ConfirmationModal";
import { getStorage, setStorage } from "utils";

const AddNewAppointment = ({ t, history }) => {
  const [activeTab, setActiveTab] = useState("1");
  const officeState = getStorage("officeState");

  const {
    data: {
      appointmentDetail,
      isLoading,
      showLoader,
      appointmentCovindHistory,
      profile,
    },
    methods: { goToPreviousScreen, appointmentDetailAction, addReviewComment },
  } = usePatientAppointmentDetailModule();

  const {
    data: { isRejectModal, isConfirmModal, isCancelModal },
    methods: {
      getStatus,
      getNameById,
      handleRedirectAppointmentReAssign,
      handleRedirectAppointmentReSchedule,
      getStatusClass,
      handleRejectToggle,
      handleConfirmToggle,
      handleCancelToggle,
      getMenuoptionsTwo,
    },
  } = useCommonModule(history, t, officeState?.officeState);

  const menuOptions = getMenuoptionsTwo(appointmentDetail);

  const handleApply = async () => {
    const params = {
      appointmentId: appointmentDetail?.id,
      status: 7,
      message: "",
    };

    let res = await appointmentDetailAction(params);
    if (res?.status) {
      handleConfirmToggle();
    }
  };

  useEffect(() => {
    setStorage("isFromAppointmentDetail", {
      isFromAppointmentDetail: true,
    });
  }, []);

  return (
    <Page onBack={goToPreviousScreen}>
      {isLoading && <Loader />}
      <Text size="25px" weight="500" color="#111B45">
        {appointmentDetail?.office?.name}
      </Text>
      <Text size="14px" weight="300" color="#000000" marginBottom="30px">
        {t("patientAppointmentMembers.patientAppointmentDetails")}
      </Text>
      <Card className="form-wrapper">
        <div className={styles["patient-appointment-detail-container"]}>
          <div className={styles["patient-appointment-detail-left"]}>
            <div className={styles["profile-image-container"]}>
              <img
                className={styles["profile-pic"]}
                src={appointmentDetail?.patient?.profilePic || DefaultImage}
                alt="PatientImage"
              />
            </div>
            <div
              className={styles["patient-name"]}
            >{`${appointmentDetail?.patient?.firstName} ${appointmentDetail?.patient?.lastName}`}</div>
            <div className={styles["profile-btn-box"]}>
              {menuOptions.map((option, index) => (
                <div key={index} className={styles["office-dropdown-item"]}>
                  <button
                    className={
                      index === 0
                        ? "button button-round button-border button-dark sm-w-100"
                        : "button button-round button-shadow text-nowrap sm-w-100"
                    }
                    onClick={(e) => option?.onclick(e, appointmentDetail)}
                  >
                    {option?.text}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className={styles["patient-appointment-detail-right"]}>
            <PendingCard
              profile={profile}
              appointmentDetail={appointmentDetail}
              getStatus={getStatus}
              handleRedirectAppointmentReAssign={
                handleRedirectAppointmentReAssign
              }
              handleRedirectAppointmentReSchedule={
                handleRedirectAppointmentReSchedule
              }
              getStatusClass={getStatusClass}
              addReviewComment={addReviewComment}
            />

            <div className={"common-tabs nav_tabs_wrapper"}>
              <Nav tabs className="nav-tabs">
                <NavItem className="nav-item">
                  <NavLink
                    className={activeTab === "1" ? "active" : "not-active"}
                    onClick={() => setActiveTab("1")}
                  >
                    {t("patientAppointmentMembers.details")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : "not-active"}
                    onClick={() => setActiveTab("2")}
                  >
                    {t("patientAppointmentMembers.notes")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active" : "not-active"}
                    onClick={() => setActiveTab("3")}
                  >
                    {t("patientAppointmentMembers.history")}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <Suspense fallback={<Loader />}>
                  <TabPane tabId="1">
                    {activeTab === "1" && (
                      <Details
                        profile={profile}
                        appointmentDetail={appointmentDetail}
                        getNameById={getNameById}
                        appointmentCovindHistory={appointmentCovindHistory}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {activeTab === "2" && <AddUpcomingNotes />}
                  </TabPane>
                  <TabPane tabId="3">
                    {activeTab === "3" && <AddUpcomingHistory />}
                  </TabPane>
                </Suspense>
              </TabContent>
            </div>
          </div>
        </div>
      </Card>
      {isRejectModal && (
        <RejectionModal
          showLoader={showLoader}
          isRejectionModalOpen={isRejectModal}
          setIsRejectionModalOpen={handleRejectToggle}
          confirmReject={appointmentDetailAction}
          isCancel={handleRejectToggle}
          appointmentID={appointmentDetail?.id}
          actionStatus={3}
          title={t("patientAppointmentMembers.reasonOfRejection")}
          isFromDetail={true}
        />
      )}
      {isCancelModal && (
        <RejectionModal
          showLoader={showLoader}
          isRejectionModalOpen={isCancelModal}
          setIsRejectionModalOpen={handleCancelToggle}
          confirmReject={appointmentDetailAction}
          isCancel={handleCancelToggle}
          appointmentID={appointmentDetail?.id}
          actionStatus={4}
          title={t("patientAppointmentMembers.reasonOfCancellation")}
          isFromDetail={true}
        />
      )}
      {isConfirmModal && (
        <ConfirmationModal
          closeModal={handleConfirmToggle}
          isModalOpen={isConfirmModal}
          handleApply={handleApply}
          showLoader={showLoader}
          message={t("patientAppointmentMembers.confirmationMsg")}
        />
      )}
    </Page>
  );
};
export default withTranslation()(AddNewAppointment);
