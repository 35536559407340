import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import plusImg from "../../../../../../assets/images/Group 6.svg"
import Text from 'components/Text'
import styles from "../../../Appointments.module.scss"
import { addAppointmentNotes, useAppointmentNotes } from 'repositories/patient-appointment-repository'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { decodeId } from 'utils'
import Loader from 'components/Loader'
import { useSelector } from 'react-redux'
import AddAppointmentNotes from './AddAppointmentNotes'
import { useMutation, useQueryClient } from 'react-query'

const AddUpcomingNotes = ({ t }) => {
    const profile = useSelector((state) => state.userProfile.profile);
    const [showResults, setShowResults] = useState(false)
    const onClick = () => setShowResults(true)
    const onHide = () => setShowResults(false)
    const [errors, setErrors] = useState({});
    let { appointmentId } = useParams();
    const queryClient = useQueryClient();
    appointmentId = decodeId(appointmentId);
    let param = {
        pageNumber: 1,
        pageSize: 100,
        appointmentId
    }
    const { isLoading: loading, data: appointmentNotes, refetch } = useAppointmentNotes(param);

    const { mutate: createNotesMutate, isLoading: createNotesLoading } = useMutation((data) => addAppointmentNotes(data));
    const isValidEvent = (notes) => {
        const errorsData = { ...errors };

        if (!notes.trim().length) {
            errorsData.MessageError = 'please add notes';
        }
        else if (notes.trim().length > 400) {
            errorsData.MessageError = 'note is  more than 400 charater';
        }
        else {
            delete errorsData.MessageError;
        }
        setErrors(errorsData);

        return !Object.values(errorsData).some(Boolean);
    };
    
    const handleCreateNotes = (note) => {
        if (isValidEvent(note)) {
            const data = {
               appointmentId: appointmentId,
                note: note
            }
             
              createNotesMutate(data, {
                onSuccess: (res) => {
                    queryClient.invalidateQueries(["/Appointment/Notes", param]);
                    refetch();
                    onHide();
                }
            });
        }
    }
    
    
    return (
        <>
            {loading && <Loader />}
            {!appointmentNotes?.items?.length ? <div>
                <Text size="18px" weight="600" color="#587E85" marginBottom="25px">{t("patientAppointmentMembers.notesSmall")}</Text>
                <button onClick={onClick} className={styles['illness-type-save-cancel-btn']}>
                    <span>
                        <img onClick={onClick} src={plusImg} alt="ds" className="mr-1" />
                    </span>
                    {t("patientAppointmentMembers.addNote")}
                </button>
                {showResults ? <AddAppointmentNotes t={t} onHide={onHide} handleCreateNotes={handleCreateNotes}  errors={errors}/> : null}
            </div> :
                <div>
                    <Text size="18px" weight="600" color="#587E85" marginBottom="25px">{t("patientAppointmentMembers.notesSmall")}</Text>
                    {
                        appointmentNotes?.items?.map(notes => {
                            return (
                                <>
                                    <Text size="12px" weight="400" color="#6F7788" marginBottom="12px">
                                        {t("patientAppointmentMembers.addedBy")} {profile?.id === notes?.creator?.id ? 'You' : `${notes?.creator?.firstName} ${notes?.creator?.lastName}`}
                                    </Text>
                                    <Text size="16px" weight="300" color="#535B5F">{notes?.note}</Text>
                                </>
                            )
                        })
                    }

                </div>
            }
        </>
    )
}

export default withTranslation()(AddUpcomingNotes);
