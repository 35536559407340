
import { decodeId, encodeId } from "utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { reasonsList } from "../../../../constants";
import constants from "../../../../constants";
import { useOfficeDetail } from "repositories/office-repository";

const useNoticesDetailModule = (useSingleNotices, history, isMynoticeDetails) => {
    let { noticeId } = useParams();
    noticeId = decodeId(noticeId);
    const { isLoading, data: noticesDetail } = useSingleNotices(noticeId)

    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(noticesDetail?.officeId, {
        enabled: !!noticesDetail?.officeId,
    });

    const reasonsDescription = noticesDetail?.reasonsOfCorrectiveAction?.map(
        reason => reasonsList.find(item => item?.value === reason)?.label
    );

    const goToPreviousScreen = () => {
        const route = isMynoticeDetails
            ? constants.routes.accountOwner.myNotices : constants.routes.accountOwner.notices;
        history.push(route.replace(":officeId", encodeId(noticesDetail?.officeId)));
    };


    let loader = isLoading || isLoadingOfficeDetail
    return {
        data: {
            loader,
            noticesDetail,
            reasonsDescription,
            officeDetail
        }, methods: {
            goToPreviousScreen
        }
    }
}

export default useNoticesDetailModule;
