import { useSbCalls } from "Messenger/calling/context";
import styles from "../../../../accountOwner/pages/Appointments/Appointments.module.scss";
import VideoCall from "assets/images/ico_video_call.svg";
import AudioCall from "assets/images/ico_audio_call.svg";
import { Modal, ModalBody } from "reactstrap";
import { useState } from "react";
import Text from "components/Text";
import Button from "components/Button";
import { withTranslation } from "react-i18next";

function getCallOption(callOption = {}) {
  return {
    localMediaView: undefined,
    remoteMediaView: undefined,
    videoEnabled: true,
    audioEnabled: true,
    ...callOption,
  };
}

const DialView = ({ t }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVideoCall, setIsVideoCall] = useState(false);
  const sbCall = useSbCalls();
  const toggleModal = () => setIsOpen(!isOpen);

  const callSelectionAction = (isVideo = false) => {
    setIsVideoCall(isVideo);
    toggleModal();
  };
  const callAction = () => {
    toggleModal();
    sbCall.dial(isVideoCall, getCallOption());
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className="modal-dialog-centered modal-md new-conversation-popup"
        modalClassName="custom-modal"
      >
        <span className="close-btn" onClick={toggleModal}>
          <img src={require("assets/images/cross.svg").default} alt="close" />
        </span>
        <ModalBody>
          <Text size="20px" marginBottom="24px" weight="500" color="#111b45">
            {t("messenger.doYouReallyWantToCall")}
          </Text>
          <div className={styles.btn_container_new}>
            <Button onClick={callAction}>{t("superAdmin.yes")}</Button>
            <Button borderedSecondary onClick={toggleModal}>
              {t("superAdmin.no")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className={styles["chat-icon-box"]}>
        <span>
          <img
            src={AudioCall}
            alt="alert"
            onClick={callSelectionAction.bind(null, false)}
          />
        </span>
        <span>
          <img
            src={VideoCall}
            alt="alert"
            onClick={callSelectionAction.bind(null, true)}
          />
        </span>
      </div>
    </>
  );
};

export default withTranslation()(DialView);
