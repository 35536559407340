import { sortBy } from "lodash";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import constants from "../../constants";
import { useGetMessangerOwnerList } from "repositories/chat-repository";
import useSubscriptionAccess from "hooks/useSubscriptionAccess";
import { useHistory } from "react-router-dom";
import { useGetPatientChatAccountOwnerList } from "repositories/family-member-repository";
import { useQueryClient } from "react-query";
import { getStorage } from "utils";

const useSelectedAccountOwner = ({
  localStorageKey,
  setCurrentChannel,
  isPatient = false,
  isFromDashBoard = false,
  doctorChatCheck = false,
}) => {
  const cachedSelectedDashBoardTab = getStorage(
    constants.notes.cache.dashboardAccountOwnerCheck
  );
  const history = useHistory();
  const profile = useSelector((state) => state.userProfile.profile);
  const modulesAccess = useSelector((prev) => prev);
  const { isModuleDisabledClass } = useSubscriptionAccess();
  const queryClient = useQueryClient();
  const [isAccessible, setIsAccessible] = useState(true);

  const [showSwitchOwnerModal, setShowSwitchOwnerModal] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  const { isLoading: loadinOwnerData, data: ownerData } =
    useGetMessangerOwnerList({
      enabled: !isPatient,
    });
  const { isLoading: loadinOwnerDataForPatient, data: ownerDataForPatient } =
    useGetPatientChatAccountOwnerList({
      enabled:
        !!isPatient &&
        profile.role.systemRole !== constants.systemRoles.accountOwner &&
        !doctorChatCheck,
    });

  const list = isPatient ? ownerDataForPatient : ownerData;

  let isStaff = false;
  let isAccountOwner = false;
  if (profile && profile.role) {
    isStaff = profile.role.systemRole === constants.systemRoles.staff;
    isAccountOwner =
      profile.role.systemRole === constants.systemRoles.accountOwner;
    if (!isStaff) localStorage.removeItem(localStorageKey);
  }

  const sortedOwnerList = (ownerList) => {
    return sortBy(ownerList, [(owner) => owner.firstName?.toLowerCase()]);
  };

  useEffect(() => {
    if (
      !loadinOwnerData &&
      !ownerDataForPatient &&
      modulesAccess &&
      list?.length > 0
    ) {
      if (isStaff) {
        getStaffHaveModuleAccess();
      } else if (
        isModuleDisabledClass(constants.moduleNameWithId.teamLiveChat)
      ) {
        window.location.href = "/";
      }
    }
    return () => {
      queryClient.invalidateQueries([
        "/Doctor/DoctorOfficesUnderOwner",
        "/Doctor/OwnerListByPatientChatPreference",
      ]);
    };
  }, [modulesAccess, list]);

  useEffect(() => {
    if (isFromDashBoard) {
      if (!list?.length) return;
      let selectedOwner = cachedSelectedDashBoardTab?.owner;
      const existingOwner = list?.find((item) => item.id === selectedOwner?.id);
      const finalOwner = existingOwner || false;
      if (existingOwner) {
        setSelectedOwner(finalOwner);
        setIsAccessible(true);
      } else {
        setIsAccessible(false);
      }
    }
  }, [list, cachedSelectedDashBoardTab]);

  function getStaffHaveModuleAccess() {
    try {
      let subscription = modulesAccess?.Subscription;
      let accessibleModule;
      if (subscription?.length) {
        const ownerIds = list?.map((messanger) => messanger.id);
        subscription = subscription?.filter((subs) =>
          ownerIds.includes(subs?.ownerId)
        );

        for (let i = 0; i < subscription.length; i++) {
          const val = subscription[i];
          accessibleModule = val?.planFeature?.find((plan) =>
            plan?.id === isPatient
              ? constants.moduleNameWithId.patientLiveChat
              : constants.moduleNameWithId.teamLiveChat && plan?.isAvailable
          );

          if (accessibleModule) {
            accessibleModule.ownerId = val.ownerId;
            break; // Break out of the loop
          }
        }
        if (!accessibleModule) {
          window.location.href = "/";
          return;
        }

        const selectedOwnerData = list?.find(
          (owner) => owner.id === accessibleModule.ownerId
        );
        const isExists = localStorage.getItem(localStorageKey);
        if (!isExists) {
          setSelectedOwner(selectedOwnerData);
        }
      } else {
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const switchNewAccountOwner = (owner) => {
    if (owner) {
      setSelectedOwner(owner);
      localStorage.setItem(localStorageKey, JSON.stringify(owner));
      setCurrentChannel(null);
    }
  };

  if (
    !loadinOwnerData &&
    isStaff &&
    list &&
    !list.length &&
    localStorage.getItem(localStorageKey)
  ) {
    localStorage.removeItem(localStorageKey);
  }

  if (
    !loadinOwnerData &&
    isStaff &&
    list &&
    list[0] &&
    selectedOwner === null
  ) {
    if (localStorage.getItem(localStorageKey)) {
      const _selectedOwner = JSON.parse(localStorage.getItem(localStorageKey));
      const ownerExist = list.find((v) => v.id === _selectedOwner.id);
      if (ownerExist) {
        switchNewAccountOwner(ownerExist);
      } else {
        switchNewAccountOwner(sortedOwnerList(list)[0]);
      }
    } else {
      switchNewAccountOwner(sortedOwnerList(list)[0]);
    }
  }

  if (
    profile &&
    profile.role &&
    !isStaff &&
    isAccountOwner &&
    selectedOwner === null
  ) {
    setSelectedOwner({
      id: profile.id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      emailId: profile.emailId,
    });
  }

  return {
    state: {
      showSwitchOwnerModal,
      selectedOwner,
      ownerData: sortedOwnerList(list),
      loadinOwnerData: loadinOwnerData || loadinOwnerDataForPatient,
      isAccessible,
    },
    updateMethods: {
      setSelectedOwner,
      setShowSwitchOwnerModal,
    },
    otherMethods: {
      switchNewAccountOwner,
    },
  };
};

export default useSelectedAccountOwner;
