import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cancelAppointmentRequest, deleteAppointmentRequest } from "repositories/patient-appointment-repository";
import { handleSuccess } from "utils";
import constants from "../../../../constants";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";

export const useWaitingRequestModal = (t, handleUpdateList) => {
    const location = useLocation();
	const history = useHistory();

    let { officeId, requestId } = useParams();

    const [cancelAppointmentModal, setCancelAppointmentMoal] = useState(null);
    const [deleteAppointmModal, setDeleteAppointment] = useState(null);

    const [cancelReason, setCancelRason] = useState('');
    const [errorReasonMsg, setErrorReasonMsg] = useState('');

    const { mutate: cancelAppointmentRequestMutate, isLoading: cancelRequsetLoading } = useMutation((data) => cancelAppointmentRequest(data));
    const { mutate: deleteAppointmentRequestMutate, isLoading: deleteRequsetLoading } = useMutation((requestId) => deleteAppointmentRequest(requestId));

	const isWaitingList = location?.pathname.includes('waiting-list');

    const closeCancelModal = () => {
        setCancelAppointmentMoal(null);
        setErrorReasonMsg('');
    };

    const closeDeleteModal = () => setDeleteAppointment(null);

    const handleCancelRason = (e) => {
        setCancelRason(e.target.value);
    }

    const handleCancelRequest = () => {
        if (!cancelReason) {
            const errorMsg = t("form.errors.emptyField", {
                field: t('patientAppointmentMembers.cancelRequestTitle').toLowerCase(),
            });
            setErrorReasonMsg(errorMsg)
        } else {
            if (errorReasonMsg) setErrorReasonMsg('');

            const cancelParams = {
                AppointmentRequestId: cancelAppointmentModal,
                ReasonForCancel: cancelReason
            }
            cancelAppointmentRequestMutate(cancelParams, {
                onSuccess: (res) => {
                    handleSuccess(res.message);
                    closeCancelModal();
                    if(handleUpdateList) handleUpdateList();
                    if(!isWaitingList) history.push(constants.routes.waitingList.replace(':officeId',officeId).replace(':requestId',requestId))
                }
            });

        }
    }

    const handleDeleteRequest = () => {
        deleteAppointmentRequestMutate(deleteAppointmModal, {
            onSuccess: (res) => {
                handleSuccess(res.message);
                closeDeleteModal();
                if(handleUpdateList) handleUpdateList();
                if(!isWaitingList) history.push(constants.routes.waitingList.replace(':officeId',officeId).replace(':requestId',requestId))
            }
        });
        
    }

    return {
        data: {
            cancelAppointmentModal,
            cancelReason,
            errorReasonMsg,
            cancelRequsetLoading,
            deleteRequsetLoading,
            deleteAppointmModal
        },
        methods: {
            closeCancelModal,
            handleCancelRason,
            handleCancelRequest,
            setCancelAppointmentMoal,
            setDeleteAppointment,
            closeDeleteModal,
            handleDeleteRequest
        }
    }
}