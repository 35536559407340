import Text from 'components/Text'
import React, { Fragment } from 'react'
import styles from "../WaitingList/WaitingList.module.scss"
import { Daysarray } from '../../../../constants'

export default function AppointmentDetail({ t, appointment }) {

    return (
        <Fragment>
            <Text size="16px" weight="600" color="#587E85">{appointment?.title}</Text>
            <div className={styles.recall_data_grid}>
                {appointment?.details?.map((item, index) => (
                    <div key={index}>
                        <Text size="12px" weight="400" color="#6F7788">{item.label}</Text>
                        <Text size="14px" weight="600" color="#102C42" marginTop="10px">{item.value}</Text>
                    </div>
                ))}
                <div className={styles.flex_col_container}>
                    {appointment?.appointmentTime?.length > 0 && appointment?.appointmentTime.map(val =>
                        val?.day ?
                            <Fragment>
                                <div className={styles.day_date_recall_flex}>
                                    <Text size="14px" weight="600" color="#102C42" marginTop="10px">{Daysarray[val?.day]}</Text>
                                    <p className={styles.waiting_list_date_box} >{`${val?.startTime} - ${val?.endTime}`}</p>
                                </div>
                            </Fragment>
                            :
                            <div key={val?.id} className={styles.name_date_wrapper}>
                                <div>
                                    <Text size="12px" weight="400" color="#6F7788">{t('waitingList.startTime')}</Text>
                                    <Text size="14px" weight="600" color="#102C42" marginTop="10px">{val?.startTime}</Text>
                                </div>
                                <div>
                                    <Text size="12px" weight="400" color="#6F7788">{t('waitingList.endTime')}</Text>
                                    <Text size="14px" weight="600" color="#102C42" marginTop="10px">{val?.endTime}</Text>
                                </div>
                            </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
