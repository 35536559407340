import React, { useState, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Loader from "components/Loader";
import styles from "./invoices.module.scss";
import PreviousInvoice from "./components/PreviousInvoice";
import ForecastedInvoice from "./components/ForecastedInvoice";
import InvoiceDetailModal from "./InvoiceDetailModal";

function Invoices({ accountOwnerId, t }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceEntry, setInvoiceEntry] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  return (
    <>
      <h2 className="page-title mb-4 mt-5">{t("superAdmin.invoices")}</h2>
      <div className={` ${styles.invoices_nav_tabs_wrapper} common-tabs `}>
        <Nav tabs className={`${styles.invoices_nav_tabs} nav-tabs `}>
          <NavItem className={`${styles.invoices_nav_item} nav-item `}>
            <NavLink
              className={activeTab === "1" ? "active" : "not-active"}
              onClick={() => setActiveTab("1")}
            >
              {t("superAdmin.previousInvoice.previousInvoice")}
            </NavLink>
          </NavItem>
          <NavItem className={`${styles.invoices_nav_item} nav-item `}>
            <NavLink
              className={activeTab === "2" ? "active" : "not-active"}
              onClick={() => setActiveTab("2")}
            >
              {t("superAdmin.previousInvoice.forecatedInvoice")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <Suspense fallback={<Loader />}>
            <TabPane tabId="1">
              {activeTab === "1" && (
                <PreviousInvoice
                  accountOwnerId={accountOwnerId}
                  onItemClick={(_invoiceEntry) => {
                    setIsModalOpen(true);
                    setInvoiceEntry(_invoiceEntry);
                  }}
                />
              )}
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" && (
                <ForecastedInvoice
                  accountOwnerId={accountOwnerId}
                  onItemClick={(_invoiceEntry) => {
                    setIsModalOpen(true);
                    setInvoiceEntry(_invoiceEntry);
                  }}
                />
              )}
            </TabPane>
          </Suspense>
        </TabContent>
      </div>

      <InvoiceDetailModal
        invoiceEntry={invoiceEntry}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setInvoiceEntry(null);
        }}
      />
    </>
  );
}

export default withTranslation()(Invoices);
