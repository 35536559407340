import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

/**
 * @param {{id: any, name: string }[]} options - Array of options
 * @param {id} selectedOption - Selected option's Id
 * @param {function} selectOption - function to select the option
 */
const CustomDropdown = ({ options, selectedOption, selectOption, defaultValue='', disabled = false }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle = () => {
    if (!disabled) setDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="select-common-dropdown"
    >
      <DropdownToggle caret={false} className="selected-item" tag="div">
        <span>{options?.find((op) => op?.id == selectedOption)?.name || defaultValue}</span>
        <img src={require("assets/images/caret.svg").default} alt="caret" />
      </DropdownToggle>

      <DropdownMenu right>
        {options?.length ? options?.map((op) => {
          const { id, name } = op;
          return (
            <DropdownItem key={id} onClick={() => selectOption(id, name)} disabled={disabled}>
              <span>{name}</span>
            </DropdownItem>
          );
        }) :  <span>Not Found</span>}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CustomDropdown;
