import { useState, useEffect } from 'react';
import constants from "../../../../constants";
import { decodeId, encodeId, getStorage, setStorage } from 'utils';
import { useSelector } from 'react-redux';
import { useAllDoctorMessageHistory } from 'repositories/patient-appointment-repository';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useReminderModule from './useReminderModule';
import { useOfficeDetail } from 'repositories/office-repository';
import moment from 'moment';

const defaultDate = {
    from: moment().startOf('year').toDate(),
    to: moment().toDate()
}

const pageSize = 10;

const usePatientMessageHistoryModule = (t) => {
    const history = useHistory();
    const location = useLocation();
    const cachePatientListMessageHistoryFilter = constants.patientsList.cache.patientListMessageHistory;
    const cacheFilters = getStorage(cachePatientListMessageHistoryFilter);
    const isFilterApply = cacheFilters?.messageTemplate?.length > 0 || cacheFilters?.doctorFilter?.length > 0 || cacheFilters?.patientFilter?.length > 0;
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    let { officeId, patientId, appointmentId } = useParams();
    const [patientMessageHistory, setPatientMessageHistory] = useState([]);
    const [messageTemplateFilter, setMessageTemplateFilter] = useState(cacheFilters?.messageTemplate || []);
    const [appliedMessageTemplateFilter, setAppliedMessageTemplateFilter] = useState(cacheFilters?.messageTemplate || []);
    const [doctorFilter, setDoctorFilter] = useState(cacheFilters?.doctorFilter || []);
    const [appliedDoctorFilter, setAppliedDoctorFilter] = useState(cacheFilters?.doctorFilter || []);
    const [patientFilter, setPatientFilter] = useState(cacheFilters?.patientFilter || []);
    const [appliedPatientFilter, setAppliedPatientFilter] = useState(cacheFilters?.patientFilter || []);
    const [dates, setdates] = useState(defaultDate);
    const [utcdates, setUtcdates] = useState({ from: null, to: null });
    officeId = +decodeId(officeId);
    patientId = +decodeId(patientId);
    appointmentId = +decodeId(appointmentId);
    const {
        data: {
            templateLists
        }
    } = useReminderModule(t) || {};

    let params = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        patientId: patientId,
        officeId: officeId,
        appointmentId: appointmentId,
        messageTemplateIds: appliedMessageTemplateFilter
    }

    if (!patientId) {
        params.doctorIds = appliedDoctorFilter;
        params.patientIds = appliedPatientFilter;
        params.startDate = utcdates.from;
        params.endDate = utcdates.to;
        delete params.patientId;
        delete params.appointmentId;
    }

    const { isLoading, data } = useAllDoctorMessageHistory(
        params
    );

    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(officeId);

    useEffect(() => {
        if (!isLoading && data && data.items) {
            setPatientMessageHistory([...data?.items]);
            setTotalItems(data?.pagination?.totalItems);
        }
    }, [isLoading, data]);


    const goBack = () => {
        if (location?.state?.isFrompatientDetail) {
            history.push({
                pathname: constants.routes.appointment.patientDetail.replace(':officeId', encodeId(officeId)).replace(":patientId", encodeId(patientId)),
            });
        }
        else if (patientId) {
            history.push(constants.routes.appointment.patients.replace(':officeId', encodeId(officeId)));
        } else {
            history.push(constants.routes.accountOwner.officeOptions.replace(':officeId', encodeId(officeId)))
        }
    };

    const handleRedirectAddMessage = () => {
        history.push({pathname: constants.routes.appointment.patientNewMessage.replace(":officeId", encodeId(officeId)).replace("/:patientId?", patientId ? `/${encodeId(patientId)}` : "").replace("/:appointmentId?", appointmentId ? `/${encodeId(appointmentId)}` : ""), state: {...location?.state}});
    };

    const handleRedirectViewMessage = (messagehistory) => {
        history.push(constants.routes.accountPreference.viewMessageTemplates.replace(':templateId', encodeId(messagehistory?.messageTemplateId)));
    };

    const handleApplyFilters = () => {
        setAppliedMessageTemplateFilter(messageTemplateFilter);
        setAppliedDoctorFilter(doctorFilter);
        setAppliedPatientFilter(patientFilter);
        setStorage(cachePatientListMessageHistoryFilter, {
            messageTemplate: messageTemplateFilter,
            doctorFilter: doctorFilter,
            patientFilter: patientFilter
        });
        setPageNumber(1);
    };

    const resetFilter = () => {
        setPageNumber(1);
        setMessageTemplateFilter([]);
        setAppliedMessageTemplateFilter([]);
        setDoctorFilter([]);
        setAppliedDoctorFilter([]);
        setPatientFilter([]);
        setAppliedPatientFilter([]);
        setdates(defaultDate);
        setUtcdates({ from: null, to: null });
        setStorage(cachePatientListMessageHistoryFilter, {
            messageTemplate: [],
            doctorFilter: [],
            patientFilter: []
        });
    };

    const updateDatesValues = (e, type) => {
        const currentDate = moment().utc().local().format("YYYY-MM-DD");
        const yearStartDate = moment().startOf('year').utc().local().format("YYYY-MM-DD");
        setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }));
        setUtcdates(prevState => ({
            ...prevState,
            from: type === 'from'
                ? moment.utc(e).local().format("YYYY-MM-DD")
                : prevState.from || yearStartDate,
            to: type === 'to'
                ? moment.utc(e).local().format("YYYY-MM-DD")
                : prevState.to || currentDate,
        }));
    }


    const loading = isLoading || isLoadingOfficeDetail;

    return {
        data: {
            loading,
            totalItems,
            pageNumber,
            pageSize,
            patientMessageHistory,
            templateLists,
            messageTemplateFilter,
            isFilterApply,
            officeDetail,
            patientId,
            officeId,
            doctorFilter,
            patientFilter,
            dates
        },
        methods: {
            setPageNumber,
            goBack,
            setMessageTemplateFilter,
            handleApplyFilters,
            resetFilter,
            handleRedirectAddMessage,
            handleRedirectViewMessage,
            setDoctorFilter,
            setPatientFilter,
            updateDatesValues
        }
    }
};

export default usePatientMessageHistoryModule;