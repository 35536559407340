import React, { useState, useEffect } from "react";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import constants from "./../../../../constants";
import { decodeId } from "utils";
import Toast from "components/Toast/Alert";
import styles from "../../Timesheet/Timesheet.module.scss";
import { useOfficeDetail } from "repositories/office-repository";
import { useSelector } from "react-redux";
import { useTimesheet } from "repositories/timesheet-repository";
import DetailView from "staff/pages/Timesheet/DetailView";
import moment from "moment";
import { useIP } from "repositories/ip-repository";

function EditTimesheet({ history, match, location, t }) {
  const [selectedDay, setSelectedDay] = useState(null);

  const { data: officeDetail } = useOfficeDetail(
    decodeId(match.params.officeId)
  );

  const profile = useSelector((state) => state.userProfile.profile);
  const staffId = decodeId(match.params.staffId);
  const details = location?.state?.detail || null;

  const goBack = () => {
    history.goBack();
  };

  const [errorMessage, setErrorMessage] = useState("");

  let officeId = decodeId(match.params.officeId);

  const {
    isLoading: isLoadingIP,
    isFetching: isFetchingIP,
    data: IP,
  } = useIP();
  const {
    isLoading: isLoadingTimesheet,
    isFetching: isFetchingTimesheet,
    error: timesheetError,
    data: timesheetData,
  } = useTimesheet(
    staffId || profile.id,
    officeId,
    details?.timesheetDate,
    details?.timesheetDate
  );
  function addTimesheetDays(timesheetData) {
    return timesheetData?.timesheet_list?.map((it) => {
      return {
        type: "timesheet",
        timesheetId: it.id,
        timesheetisAdvance: it.isTypeAdvance,
        timesheetType: it.advanceTimesheetType,
        timesheetStatus: it.statusId,
        timesheetStartTime: it.startTime,
        timesheetEndTime: it.endTime,
        timesheetBreakDuration: it.breakTime,
        timesheetRejectionReason: it.reason,
        note: it.note,
        updatedBy: it.updatedBy,
      };
    });
  }

  const selectedDate = moment(details.timesheetDate, "YYYY-MM-DD").toDate();

  useEffect(() => {
    const selectedDay = addTimesheetDays(timesheetData);

    if (selectedDay && selectedDay?.length > 0) {
      setSelectedDay({ ...selectedDay?.[0] });
    }
  }, [isLoadingTimesheet, timesheetData]);

  return (
    <Page onBack={goBack}>
      {officeDetail && officeDetail?.name && (
        <h2 className={"page-title " + styles["heading"]}>
          {officeDetail.name}
        </h2>
      )}

      <div className={styles["sub-head"]}>
        {t("staffTimesheet.editTimesheet")}
      </div>
      {errorMessage && (
        <Toast
          errorToast
          message={errorMessage}
          handleClose={() => setErrorMessage("")}
        />
      )}
      <div className="account-setup-block pt-0">
        <div className={"form-wrapper " + styles["leave-form-wrapper"]}>
          <div
            className={
              "common-tabs scheduler-tabs edit-profile-tabs " +
              " " +
              styles["edit_timesheet_width"]
            }
          >
            <DetailView
              userId={staffId || profile.id}
              officeId={officeId}
              selectedDate={selectedDate}
              selectedDay={selectedDay}
              viewOnly={false}
              viewOnlyReason={false}
              IP={IP}
              t={t}
              officeDetail={officeDetail}
              match={match}
              profile={profile}
              updateTimesheet={true}
              isEditTimesheetScreen={true}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withTranslation()(EditTimesheet);
