import React from 'react'
import MyNotices from './MyNotices';
import { useOfficeDetail } from 'repositories/office-repository';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { decodeId } from 'utils';

const Notices = () => {
    let { officeId } = useParams();
    officeId = parseInt(decodeId(officeId));
    const location = useLocation();

    const { isLoading: isLoadingOfficeDetail, data: officeDetail } = useOfficeDetail(officeId, {
        enabled: !!officeId,
    });
    const isMynotices = location?.pathname.includes('my-notices');
    return (
        <MyNotices isLoadingOfficeDetail={isLoadingOfficeDetail} officeDetail={officeDetail} isMynotices={isMynotices}/>
    )
}
export default Notices; 