import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import styles from "../Dashboard.module.scss";
import { handleError } from "utils";
import { withTranslation } from "react-i18next";

const ToggleButton = ({ t, activeToggle, setActiveToggle, firstText, secondText, isFromChat = false, isAccessible }) => {
  const toggleOptions = {
    internal: 1,
    external: 2,
  };

  return (
    <div className={styles.toggle_btn_container}>
      <div className={styles.tabs_name_wrapper}>
        <Nav tabs className={styles["nav-tabs"]}>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeToggle === toggleOptions.internal
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => setActiveToggle(toggleOptions.internal)}
            >
              {firstText}
            </NavLink>
          </NavItem>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeToggle === toggleOptions.external
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => {
                if (isFromChat) {
                  if (isAccessible) {
                    setActiveToggle(toggleOptions.external);
                  } else {
                    handleError(
                      new Error(t("messenger.externalChatAccessError"))
                    );
                  }
                } else {
                  setActiveToggle(toggleOptions.external)

                }
              }}
            >
              {secondText}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default withTranslation()(ToggleButton);
