import ChannelUI from "@sendbird/uikit-react/Channel/components/ChannelUI";
import { ChannelProvider } from "@sendbird/uikit-react/Channel/context";
import { ChannelListProvider } from "@sendbird/uikit-react/ChannelList/context";
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import Loader from "components/Loader";
import CustomChannelList from "Messenger/pages/TeamConversation/components/SendbirdCustomComponents/CustomChannelList";
import CustomChannelPreview from "Messenger/pages/TeamConversation/components/SendbirdCustomComponents/CustomChannelPreview";
import CustomConversationHeader from "Messenger/pages/TeamConversation/components/SendbirdCustomComponents/CustomConversationHeader";
import CustomMessageInput from "Messenger/pages/TeamConversation/components/SendbirdCustomComponents/CustomMessageInput";
import CustomMessageItem from "Messenger/pages/TeamConversation/components/SendbirdCustomComponents/CustomMessageItem";
import "../DashboardChat.scss";
import constants from "../../../../../constants";
import { useSelector } from "react-redux";
import ImageViewer from "Messenger/pages/TeamConversation/components/Modals/ImageViewer";
import useConversation from "Messenger/hooks/conversation/useConversation";
import ChatLoader from "Messenger/chatLoader";

const DashboardCommonChat = ({
  t,
  currentUser,
  currentChannel,
  setCurrentChannel,
  externalTabActive,
  isInternalChat,
  // patient chat
  isFromPatient,
  selectedOwner,
  appliedOfficeFilters,
}) => {
  const { state, otherData, updateMethods, otherMethods } = useConversation({
    currentUser,
    currentChannel,
    setCurrentChannel,
    t,
    ...(isInternalChat !== undefined && {
      isInternalChat,
    }),
    ...(externalTabActive !== undefined && {
      isExternalChat: externalTabActive,
    }),
  });
  const { profileContactInfo, channelListQuery, fileMessage, fileViewer } =
    state;
  const { isSdkLoading, isUserLoading, sdk, isSdkError } = otherData;
  const profile = useSelector((state) => state.userProfile.profile);
  const isAccountOwner =
    profile?.role?.systemRole === constants.systemRoles.accountOwner;
  const { setFileMessage, setFileViewer } = updateMethods;
  const {
    handleProfileBtn,
    handleProfileBtnClose,
    handleChannelSelect,
    handleBackBtn,
    handleOnBeforeSendUserMessage,
    sendUserMessage,
    handleSort,
    sendFileMessage,
    handleOnBeforeSendFileMessage,
  } = otherMethods;

  const isMobile = window.innerWidth < 767;
  const minHeight = isMobile ? "350px" : isAccountOwner ? "373px" : "350px";
  const noChatDetail = useMemo(() => {
    if (isInternalChat) {
      return t("messenger.pleaseInternalSelectConversationToStart");
    }
    if (externalTabActive) {
      return t("messenger.pleaseExternalSelectConversationToStart");
    }
    return t("messenger.pleasePatientSelectConversationToStart");
  }, [isInternalChat, externalTabActive, isFromPatient]);
  return (
    <div
      className={`miraxis-messenger ${
        isFromPatient ? "miraxis_new1" : "miraxis_new"
      } `}
    >
      {!currentChannel ? (
        <>
          {(isSdkLoading || isUserLoading) && <Loader />}
          {/* Chat list */}

          <ChannelListProvider
            className={!currentChannel ? "" : "hide-channel-list"}
            queries={channelListQuery}
            onChannelSelect={handleChannelSelect}
            sortChannelList={handleSort}
            disableAutoSelect={true}
          >
            <CustomChannelList
              isSdkError={isSdkError}
              currentUser={currentUser}
              externalTabActive={externalTabActive}
              isFromDashBoard={true}
              {...(isFromPatient !== undefined && {
                filteredOffices: appliedOfficeFilters,
                selectedOwner,
                isFromPatient,
              })}
              {...(isInternalChat !== undefined && {
                filteredOffices: appliedOfficeFilters,
                selectedOwner,
                internalTabActive: isInternalChat,
              })}
              renderChannelPreview={(props) => (
                <CustomChannelPreview
                  {...props}
                  key={props.channel.url}
                  currentUser={currentUser}
                  currentChannelUrl={currentChannel?.url}
                  setCurrentChannel={setCurrentChannel}
                  externalTabActive={externalTabActive}
                  handleProfileBtnClose={handleProfileBtnClose}
                  isFromDashBoard={true}
                  sdk={sdk}
                />
              )}
            />
          </ChannelListProvider>
        </>
      ) : (
        <div
          style={
            isFromPatient
              ? { "--wrapper-min-height": "436px" }
              : isInternalChat
              ? { "--wrapper-min-height": "373px" }
              : {
                  "--wrapper-min-height": minHeight,
                }
          }
          className={`conversation-wrapper 
                ${!currentChannel ? "" : "show-conversation"}
                ${!profileContactInfo ? "" : "contact-info-active"}`}
        >
          <ChannelProvider
            channelUrl={currentChannel?.url}
            onBeforeSendFileMessage={handleOnBeforeSendFileMessage}
            onBeforeSendUserMessage={handleOnBeforeSendUserMessage}
          >
            <ChannelUI
              renderTypingIndicator={() => <></>}
              renderPlaceholderInvalid={() => (
                <div className="empty-chat-box">
                  {noChatDetail}{" "}
                </div>
              )}
              renderPlaceholderLoader={() => <ChatLoader />}
              renderPlaceholderEmpty={() => <></>}
              renderChannelHeader={() => (
                <header className="dashboard_chat_header">
                  <CustomConversationHeader
                    currentUser={currentUser}
                    channel={currentChannel}
                    handleProfileBtn={handleProfileBtn}
                    handleBackBtn={handleBackBtn}
                    isFromDashBoard={true}
                  />
                </header>
              )}
              renderMessage={(props) => (
                <CustomMessageItem
                  {...props}
                  t={t}
                  currentUser={currentUser}
                  setFileMessage={setFileMessage}
                  setFileViewer={setFileViewer}
                  currentChannel={currentChannel}
                />
              )}
              renderMessageInput={() => (
                <CustomMessageInput
                  t={t}
                  sendUserMessage={sendUserMessage}
                  sendFileMessage={sendFileMessage}
                  currentChannel={currentChannel}
                  sdk={sdk}
                />
              )}
            />
          </ChannelProvider>
          {fileViewer && fileMessage && (
            <ImageViewer
              imageUrl={fileMessage.url}
              isOpen={fileViewer}
              closeImageViewerModal={() => setFileViewer(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(DashboardCommonChat);
