import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../TaskManagement.module.scss";
import Text from "components/Text";
import DefaultImage from '../../../../assets/images/staff-default-rounded.png';
import { withTranslation } from "react-i18next";
import Loader from "components/Loader";

const AssigneesModal = ({
  t,
  isModalOpen,
  closeModal,
  assignees,
  isLoading
}) => {

  const getStatus = (overallStatus) => {
    const statusMap = {
      1: "In Progress",
      2: "Pending",
      3: "Accepted",
      4: "Started",
      5: "Completed",
      6: "Rejected",
      7: "Cancelled"
    };
    return statusMap[overallStatus] || "";
  };

  const getButtonClass = (status) => {
    const statusClasses = {
      Pending: styles.Pending,
      Cancelled: styles.Cancelled,
      Started: styles.Started,
      Accepted: styles.Accepted,
      Rejected: styles.Rejected,
      "In Progress": styles.InProgress,
      Completed: styles.Completed,
    };

    return `${styles.task_management_card_btn} ${statusClasses[status] || ""}`;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-dialog-centered modal-lg"
      modalClassName="custom-modal"
    >
       {isLoading && <Loader />}
      <span className="close-btn" onClick={closeModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <Text size="25px" weight="500" color="#111B45">
          {t("taskManagement.assignees")}
        </Text>
        <div className={styles["modal_scroll"]}>
          {assignees.map((assignee) => (
            <div
              key={assignee.id}
              className={styles["patient_name_image_btn_wrapper"]}
            >
              <div className={styles["patient_image_name_wrapper"]}>
                <img
                  src={assignee?.user?.profilePic || DefaultImage}
                  alt="patientImage"
                  className={styles["popup_patient_image"]}
                />
                <div className={styles["name_office"]}>
                  <Text size="14px" weight="600" color="#102C42">
                    {`${assignee?.user?.firstName} ${assignee?.user?.lastName}`}
                  </Text>
                  <Text size="12px" weight="400" color="#87928D">
                    {assignee?.office?.name}
                  </Text>
                </div>
              </div>
              <button className={getButtonClass(getStatus(assignee?.status))}>
                {getStatus(assignee?.status)}
              </button>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(AssigneesModal);
