
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import constants from "../../../../constants";
import { cacheSideBarActive } from "utils";
import PastAppointmentTable from "../PastAppointmentTable";
import styles from "../Appointments.module.scss"
import "../Appointments.scss"
import "../../Scheduler/components/StickySidebar/SchedulerSidebar.module.scss";
import useUpCommingModule from "../hooks/useUpCommingModule";
import { useAllPastAppointments } from "repositories/patient-appointment-repository";
import Loader from "components/Loader";
import GlobalListFilter from '../filter/index'

const PastAppointmentTab = ({ t, state, isFrompatientDetail = false }) => {

  const profile = useSelector((state) => state.userProfile.profile);
  let selectedOwnerId = 0;
  if (profile && profile.role) {
    if (profile.role.systemRole !== constants.systemRoles.staff) {
      selectedOwnerId = profile.id;
    } else {
      selectedOwnerId = localStorage.getItem("selectedOwner")
        ? JSON.parse(localStorage.getItem("selectedOwner")).id
        : selectedOwnerId;
    }
  }

  const {
    data: {
      isLoading,
      totalItems,
      pageNumber,
      appointments,
      showLoader,
      searchValue,
      officeFilter,
      statusFilter,
      dates,
      isFilterApply,
      doctorFilter
    },
    methods: {
      setPageNumber,
      appointmentDetailAction,
      handleInputChange,
      setOfficeFilter,
      handleApplyFilters,
      resetFilter,
      setStatusFilter,
      updateDatesValues,
      setDocotrFilter,
      addReviewComment
    }
  } = useUpCommingModule(useAllPastAppointments, state, "past");

  const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());
 

  const handleSidebarToggle = () => {
    setSidebarActive(!isSidebarActive);
    localStorage.setItem("isSidebarActive", !isSidebarActive);
  };

  return (

    <div className="scheduler-tabs-main-wrapper appointment-main-wrapper-one">
      {isLoading && <Loader />}
      {!isFrompatientDetail && <div>
        <GlobalListFilter
          isSidebarActive={isSidebarActive}
          handleSidebarToggle={handleSidebarToggle}
          resetFilter={resetFilter}
          applyFilter={handleApplyFilters}
          officeFilter={officeFilter}
          setOfficeFilter={setOfficeFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          dates={dates}
          updateDatesValues={updateDatesValues}
          appointmentStatusList = {constants.pastAppointmentStatus}
          state={state}
          isFilterApply={isFilterApply}
          doctorFilter={doctorFilter}
          setDocotrFilter={setDocotrFilter}
        />
      </div>}

      <div className="tabs-right-col appointment-tab-right-col">
        <div className={styles["event-shift-header"]}>
          <PastAppointmentTable
            appointments={appointments}
            totalItems={totalItems}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            appointmentDetailAction={appointmentDetailAction}
            showLoader={showLoader}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
            state = {state}
            isFrompatientDetail={isFrompatientDetail}
            addReviewComment={addReviewComment}
          />
        </div>
      </div>
    </div>

  )
}
export default withTranslation()(PastAppointmentTab)