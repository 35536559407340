import React, { useState, useEffect, createRef, useRef } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import styles from "./../../../../Scheduler.module.scss";
import Text from "components/Text";
import { withTranslation } from "react-i18next";
import { encodeId, isMobileTab } from "utils";
import * as moment from "moment";
import { useGetOfficeAgenda } from "repositories/scheduler-repository";
import { cloneDeep, findKey } from "lodash";
import constants from "../../../../../../../constants.js";
import { useHistory } from "react-router-dom";
import defautUserImage from "../../../../../../../assets/images/staff-default.svg";
import Loader from "components/Loader";
import toast from "react-hot-toast";
import HolidayImage from "../../../../../../../assets/images/early_on.svg";
import crossIcon from "../../../../../../../assets/images/cross.svg";
import MessageIcon from "../../../../../../../assets/images/mail_icon.svg";
import CallIcon from "../../../../../../../assets/images/call-icon.svg";

function DailyViewNew({
  t,
  currentDate,
  selectedOwnerId,
  currentUserId,
  onDateChange,
  apiOffices,
  apiEmps,
  isSidebarActive,
  isWeekly,
  agendaTypes,
  cachedEventFilter,
  viewSettingFilter,
  isDaily,
  isFromDashBoard,
}) {
  const history = useHistory();
  const calendarRef = createRef(null);

  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [seeMoreUsers, setSeeMoreUsers] = useState([]);
  const [seeMoreOnCalender, setSeeMoreOnCalender] = useState("");
  const [hoverKey, setHoverKey] = useState("");
  const [agenda, setAgenda] = useState(null);
  const [agendaFilter, setAgendaFilter] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const nameRef = useRef(null);

  const handlePopupToggle = (userId) => {
    setPopupOpen((prev) => (prev === userId ? null : userId));
  };

  const filterByEvents = () => {
    const updatedAgenda = agenda?.map((item) => {
      const schedulerList = Array.isArray(item.officeSchedulerList)
        ? item.officeSchedulerList
        : [item.officeSchedulerList];

      const filteredSchedulerList = schedulerList.map((schedulerItem) => {
        let eventsCreatedByUser = [];
        let eventsNotCreatedByUser = [];
        let filteredSchedulerEvents = [];

        if (agendaTypes?.length > 0) {
          if (agendaTypes.includes(0)) {
            eventsCreatedByUser =
              schedulerItem?.schedulerEvents?.filter(
                (event) => event?.createdById === currentUserId
              ) || [];
          }

          if (agendaTypes.includes(-1)) {
            eventsNotCreatedByUser =
              schedulerItem?.schedulerEvents?.filter(
                (event) => event?.createdById !== currentUserId
              ) || [];
          }

          filteredSchedulerEvents =
            schedulerItem?.schedulerEvents?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [];
        }

        return {
          ...schedulerItem,
          schedulerEvents: [
            ...eventsCreatedByUser,
            ...eventsNotCreatedByUser,
            ...filteredSchedulerEvents,
          ],

          appointments:
            schedulerItem?.appointments?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          appointmentSlots:
            schedulerItem?.appointmentSlots?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          leaves:
            schedulerItem?.leaves?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],

          schedulerBusySlots:
            schedulerItem?.schedulerBusySlots?.filter((event) =>
              agendaTypes.includes(event.agendaType)
            ) || [],
        };
      });

      return {
        ...item,
        officeSchedulerList: filteredSchedulerList,
      };
    });
    setAgendaFilter(updatedAgenda);
  };

  useEffect(() => {
    if (cachedEventFilter?.length > 0) {
      filterByEvents();
    } else if (cachedEventFilter?.length === 0) {
      setAgendaFilter(agenda);
    }
  }, [agenda, cachedEventFilter]);

  const changeWeek = (type) => {
    if (type === "next") {
      onDateChange(moment(currentDate).add(1, "day").toDate());
    } else {
      onDateChange(moment(currentDate).subtract(1, "day").toDate());
    }
  };

  useEffect(() => {
    if (currentDate) {
      setDateRange({
        startDate: moment(currentDate).format("YYYY-MM-DD"),
        endDate: moment(currentDate).format("YYYY-MM-DD"),
      });
    }
    if (isMobileTab() && isSidebarActive) {
      toast.success(t("accountOwner.dateApply"));
    }
    //eslint-disable-next-line
  }, [currentDate]);

  const enableFetch = selectedOwnerId && dateRange ? true : false;
  const {
    isLoading,
    isFetching,
    data: weeklyData,
    error,
  } = useGetOfficeAgenda(
    selectedOwnerId,
    dateRange?.startDate,
    dateRange?.endDate,
    apiOffices,
    apiEmps,
    { enabled: enableFetch }
  );

  useEffect(() => {
    if (!isLoading && weeklyData?.data) {
      setAgenda(weeklyData?.data);
      setAgendaFilter(weeklyData?.data);
    }
  }, [isLoading, weeklyData]);

  useEffect(() => {
    if (!isLoading && !isFetching && error?.message) {
      toast.error(error.message);
    }
    //eslint-disable-next-line
  }, [error]);

  let tableDates;
  if (isWeekly) {
    tableDates = weekData.map((v, i) => <th key={i}>{v.mDate}</th>);
  } else {
    tableDates = monthData.map((v, i) => <th key={i}>{v.mDate}</th>);
  }

  const resetHoverKey = (e) => {
    e.stopPropagation();
    setHoverKey("");
  };
  const getPopupData = (item, requestedDate) => {
    const data = {};
    data.title = item?.title || item?.reasonForBlock || null;
    data.officeName = item?.officeName || null;
    data.patientFullName = item?.patientFullName || null;
    data.startTime = moment(item?.startTime).format("h:mm a");
    data.endTime = moment(item?.endTime).format("h:mm a");
    data.date = moment(requestedDate).format("MMM D, YYYY [-] ddd");
    const { agendaType } = item;
    switch (agendaType) {
      case constants.agendaType.EVENT: {
        data.tag = t("scheduler.agenda.eventTag");
        break;
      }
      case constants.agendaType.BUSY_SLOT: {
        data.tag = t("scheduler.agenda.busyTag");
        break;
      }
      case constants.agendaType.BLOCKED: {
        data.tag = t("scheduler.agenda.busyTag");
        break;
      }
      case constants.agendaType.APPOINTMENT: {
        data.tag = t("scheduler.agenda.appointmentTag");
        data.title = t("scheduler.agenda.patient", {
          name: item.patientFullName,
        });
        data.isAppointment = true;
        const status = findKey(
          constants.appointmentStatus,
          (s) => s === item.appointmentStatus
        );
        data.appointmentStatus = status
          ? t(`appointmentStatus.${status}`)
          : "--";
        break;
      }
      case constants.agendaType.LEAVE: {
        data.tag = t("scheduler.agenda.leaveTag");
        data.title = t("scheduler.agenda.leaveTitle");
        data.isLeave = true;
        break;
      }
      default: {
        break;
      }
    }
    return data;
  };
  const getPopupView = (
    item,
    backgroudColor,
    tagBackgroundColor,
    requestedDate,
    staffName
  ) => {
    const data = getPopupData(item, requestedDate);
    return (
      <div className={styles["scheduler-popup-box"]}>
        <span className={styles["close-icon"]} onClick={resetHoverKey}>
          &times;
        </span>
        <div
          className={
            styles["scheduler-event-box"] + " " + styles[backgroudColor]
          }
        >
          <div
            className={styles["event-tag"] + " " + styles[tagBackgroundColor]}
          >
            {data.tag}
          </div>
          <div className={styles["event-type"]}>{data.title}</div>
          <Row className={styles.row}>
            <Col sm="6">
              <Text size="10px" marginBottom="0px" weight="400" color="#87928D">
                {constants.agendaType.APPOINTMENT === item.agendaType
                  ? t("scheduler.agenda.doctorName")
                  : t("scheduler.agenda.staffName")}
              </Text>
              <Text
                size="12px"
                marginBottom="10px"
                weight="500"
                color="#2F3245"
              >
                {staffName}
              </Text>
            </Col>
            <Col sm="6">
              <Text size="10px" marginBottom="0px" weight="400" color="#87928D">
                {t("accountOwner.officeName")}
              </Text>
              <Text
                size="12px"
                marginBottom="10px"
                weight="500"
                color="#2F3245"
              >
                {data.officeName}
              </Text>
            </Col>
            <Col sm="6">
              <Text size="10px" marginBottom="0px" weight="400" color="#87928D">
                {t("accountOwner.date")}
              </Text>
              <Text
                size="12px"
                marginBottom="10px"
                weight="500"
                color="#2F3245"
              >
                {data.date}
              </Text>
            </Col>
            {!data.isLeave && (
              <Col sm="6">
                <Text
                  size="10px"
                  marginBottom="0px"
                  weight="400"
                  color="#87928D"
                >
                  {data.isAppointment
                    ? t("timeOfAppointment")
                    : t("staff.startTime")}
                </Text>
                <Text
                  size="12px"
                  marginBottom="12px"
                  weight="500"
                  color="#2F3245"
                >
                  {data.startTime}
                </Text>
              </Col>
            )}
            {!data.isLeave && (
              <Col sm="6">
                <Text
                  size="10px"
                  marginBottom="0px"
                  weight="400"
                  color="#87928D"
                >
                  {data.isAppointment ? t("status") : t("staff.endTime")}
                </Text>
                <Text
                  size="12px"
                  marginBottom="12px"
                  weight="500"
                  color="#2F3245"
                >
                  {data.isAppointment ? data?.appointmentStatus : data.endTime}
                </Text>
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  };
  const goToDetails = (id, type, createdByMe, requestedDate) => {
    if (!id || !type) return;
    switch (type) {
      case constants.agendaType.EVENT: {
        let pathname = "/";
        pathname = createdByMe
          ? constants.routes.scheduler.eventDetails.replace(
              ":eventId",
              encodeId(id)
            )
          : constants.routes.scheduler.eventDetailsOnly.replace(
              ":eventId",
              encodeId(id)
            );
        history.push({ pathname, state: { requestedDate } });
        break;
      }
      case constants.agendaType.BUSY_SLOT: {
        const pathname = constants.routes.scheduler.busySlotDetail.replace(
          ":busySlotId",
          encodeId(id)
        );
        history.push(pathname);
        break;
      }
      default: {
        return;
      }
    }
  };
  const getUsefulInfo = (item) => {
    const id = item?.id;
    const officeName = item?.officeName || null;
    let title = item?.title || item?.reasonForBlock || null;
    title =
      title && title.length > constants.agendaTitleLength
        ? title.slice(0, constants.agendaTitleLength).concat(" ...")
        : title;
    const timezoneCode = item?.timezoneCode || null;
    const isAllDayEvent = item?.isAllDayEvent;
    const timeString = item?.isAllDayEvent
      ? t("scheduler.agenda.allDayEvent")
      : moment(item?.startTime).format("h:mm A") +
        " - " +
        moment(item?.endTime).format("h:mm A");
    //sepecific to Schedular events
    const createdByMe = item.createdById === currentUserId;
    const isInvite = !!item.eventEmployeeStatus;
    const status = item.eventStatus;
    let backgroudColorClass;
    backgroudColorClass =
      status === constants.SCHEDULERSTATUS.PENDING
        ? "color-blue"
        : createdByMe
        ? ""
        : "color-grey";
    return {
      officeName,
      title,
      timezoneCode,
      timeString,
      id,
      isAllDayEvent,
      createdByMe,
      isInvite,
      status,
      backgroudColorClass,
    };
  };
  const getAppointmentsView = (
    item,
    index,
    searchKey,
    requestedDate,
    staffName
  ) => {
    const thisHoverKey = `${searchKey}_${index}`;
    const data = getUsefulInfo(item);
    return (
      <div
        key={index}
        className={`
                    ${styles["scheduler-event-box"]} ${styles["box-flex"]} ${
          !viewSettingFilter.length || viewSettingFilter.includes("event-color")
            ? styles["light-purple-bg"]
            : styles["no-bg-theme"]
        }}
                `}
        onClick={() => setHoverKey(thisHoverKey)}
      >
        <div className={styles["event-tag"] + " " + styles["dark-grey-bg"]}>
          {t("scheduler.agenda.appointmentTag")}
        </div>
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("timeString")) && (
          <div className={styles["event-date"]}>
            {data.timeString}
            {data.timezoneCode ? ` (${data.timezoneCode})` : null}
          </div>
        )}
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("officeName")) && (
          <div className={styles["event-office"]}>{data.officeName}</div>
        )}
        {(!viewSettingFilter.length || viewSettingFilter.includes("title")) && (
          <div className={styles["event-type"]}>
            {t("scheduler.agenda.appointmentTitle")}
          </div>
        )}
        {thisHoverKey === hoverKey &&
          getPopupView(
            item,
            "light-purple-bg",
            "dark-grey-bg",
            requestedDate,
            staffName
          )}
      </div>
    );
  };
  const getSchedulerEventsView = (
    item,
    index,
    searchKey,
    requestedDate,
    staffName
  ) => {
    const thisHoverKey = `${searchKey}_${index}`;
    const data = getUsefulInfo(item);
    return (
      <div
        key={index}
        className={`${styles["scheduler-event-box"]} ${styles["box-flex"]} ${
          !viewSettingFilter.length || viewSettingFilter.includes("event-color")
            ? styles[data.backgroudColorClass]
            : styles["no-bg-theme"]
        } cursor-pointer`}
        onClick={() => {
          if (data.createdByMe) {
            goToDetails(
              data.id,
              constants.agendaType.EVENT,
              data.createdByMe,
              requestedDate
            );
          } else {
            setHoverKey(thisHoverKey);
          }
        }}
      >
        <div className={styles["event-tag"] + " " + styles["theme-green-bg"]}>
          {t("scheduler.agenda.eventTag")}
        </div>
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("timeString")) && (
          <div className={styles["event-date"]}>
            {data.timeString}
            {data.timezoneCode ? ` (${data.timezoneCode})` : null}
          </div>
        )}
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("officeName")) && (
          <div className={styles["event-office"]}>{data.officeName}</div>
        )}
        {(!viewSettingFilter.length || viewSettingFilter.includes("title")) && (
          <div className={styles["event-type"]}> {data.title} </div>
        )}
        {thisHoverKey === hoverKey &&
          getPopupView(
            item,
            data.backgroudColorClass,
            "theme-green-bg",
            requestedDate,
            staffName
          )}
      </div>
    );
  };
  const getSchedulerBusySlotsView = (
    item,
    index,
    isSchedulerBusySlot,
    searchKey,
    requestedDate,
    staffName
  ) => {
    const thisHoverKey = `${searchKey}_${index}`;
    const data = getUsefulInfo(item);
    return (
      <div
        key={index}
        className={`
                    ${styles["scheduler-event-box"]} ${styles["box-flex"]} ${
          !viewSettingFilter.length || viewSettingFilter.includes("event-color")
            ? styles["light-orange-bg"]
            : styles["no-bg-theme"]
        } cursor-pointer`}
        onClick={() => {
          if (data.createdByMe) {
            goToDetails(
              data.id,
              constants.agendaType.BUSY_SLOT,
              data.createdByMe,
              requestedDate
            );
          } else {
            setHoverKey(thisHoverKey);
          }
        }}
      >
        <div className={styles["event-tag"] + " " + styles["dark-orange-bg"]}>
          {t("scheduler.agenda.busyTag")}
        </div>
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("timeString")) && (
          <div className={styles["event-date"]}>
            {data.timeString}
            {data.timezoneCode ? ` (${data.timezoneCode})` : null}
          </div>
        )}
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("officeName")) && (
          <div className={styles["event-office"]}>{data.officeName} </div>
        )}
        {(!viewSettingFilter.length || viewSettingFilter.includes("title")) && (
          <div className={styles["event-type"]}> {data.title} </div>
        )}
        {thisHoverKey === hoverKey &&
          getPopupView(
            item,
            "light-orange-bg",
            "dark-orange-bg",
            requestedDate,
            staffName
          )}
      </div>
    );
  };
  const getLeavesView = (item, index, searchKey, requestedDate, staffName) => {
    const thisHoverKey = `${searchKey}_${index}`;
    const data = getUsefulInfo(item);
    return (
      <div
        key={index}
        className={`${styles["scheduler-event-box"]} ${styles["box-flex"]} ${
          !viewSettingFilter.length || viewSettingFilter.includes("event-color")
            ? styles["light-red-bg"]
            : styles["no-bg-theme"]
        }}`}
        onClick={() => setHoverKey(thisHoverKey)}
      >
        <div className={styles["event-tag"] + " " + styles["dark-red-bg"]}>
          {t("scheduler.agenda.leaveTag")}
        </div>
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("timeString")) && (
          <div className={styles["event-date"]}>
            {t("scheduler.agenda.leaveTitle")}
          </div>
        )}
        {(!viewSettingFilter.length ||
          viewSettingFilter.includes("officeName")) && (
          <div className={styles["event-office"]}>{data.officeName}</div>
        )}
        {(!viewSettingFilter.length || viewSettingFilter.includes("title")) && (
          <div className={styles["event-type"]}>
            {t("scheduler.agenda.onLeave")}
          </div>
        )}
        {thisHoverKey === hoverKey &&
          getPopupView(
            item,
            "light-red-bg",
            "dark-red-bg",
            requestedDate,
            staffName
          )}
      </div>
    );
  };
  const shouldShowUserSeeMore = (data) => {
    return data.some((item) => {
      let {
        appointments,
        leaves,
        schedulerBusySlots,
        schedulerEvents,
        appointmentSlots,
      } = item;
      appointments = appointments || [];
      leaves = leaves || [];
      schedulerBusySlots = schedulerBusySlots || [];
      schedulerEvents = schedulerEvents || [];
      appointmentSlots = appointmentSlots || [];
      return (
        [
          appointments,
          leaves,
          schedulerBusySlots,
          schedulerEvents,
          appointmentSlots,
        ].flat().length > 2
      );
    });
  };
  const toggleSeeMoreForUser = (userId) => {
    if (seeMoreUsers.includes(userId)) {
      setSeeMoreUsers((state) => state.filter((id) => id !== userId));
    } else {
      setSeeMoreUsers((state) => [...state, userId]);
    }
    setSeeMoreOnCalender("");
  };
  const toggleSeeMoreOnCalender = (searchKey) => {
    if (seeMoreOnCalender === searchKey) {
      setSeeMoreOnCalender("");
    } else {
      setSeeMoreOnCalender(searchKey);
    }
  };
  const getDifferentEvents = (events) => {
    return (events || []).reduce(
      (acc, item) => {
        const { isAllDayEvent } = item;
        if (isAllDayEvent) {
          acc[0].push(item);
        } else {
          acc[1].push(item);
        }
        return acc;
      },
      [[], []]
    );
  };
  let userData = [];
  let tableJsx = null;
  let holidayRowJsx = null;

  if (agendaFilter?.length) {
    userData = cloneDeep(agendaFilter?.[0].officeSchedulerList).map((item) => ({
      user: item.user,
      data: [],
    }));

    agendaFilter?.forEach((date) => {
      const { requestedDate, officeSchedulerList, holidayList } = date;
      officeSchedulerList.forEach((data, userIndex) => {
        const { user, ...rest } = data;
        userData[userIndex].data.push({ requestedDate, holidayList, ...rest });
      });
    });

    tableJsx =
      userData?.length &&
      userData?.map((ud, ui) => {
        const { user, data } = ud;
        const {
          fullName,
          profilePic,
          id: userId,
          emailId,
          designationNames,
          contactNumber,
          officeNames,
        } = user || {};
        const isSeeLessVisible = seeMoreUsers.includes(userId);
        return (
          <tr key={ui}>
            <th
              className={isFromDashBoard && styles.th_dashboard}
              style={
                isFromDashBoard && {
                  maxWidth: "150px",
                  minWidth: "150px",
                  borderLeft: "none",
                }
              }
            >
              <div className={styles["scheduler-client-box"]}>
                <div
                  className={styles["client-img"]}
                  onClick={() => handlePopupToggle(userId)}
                >
                  <img
                    src={profilePic || defautUserImage}
                    alt="icon"
                    onError={(e) => {
                      e.currentTarget.src = defautUserImage;
                    }}
                  />
                </div>
                <div
                  className={styles["client-name"]}
                  ref={nameRef}
                  onClick={() => handlePopupToggle(userId)}
                >
                  {fullName}
                </div>
                {shouldShowUserSeeMore(data) && (
                  <div
                    className={styles["see-btn"]}
                    onClick={() => toggleSeeMoreForUser(userId)}
                  >
                    <span>
                      {isSeeLessVisible
                        ? t("scheduler.agenda.seeLess")
                        : t("scheduler.agenda.seeMore")}
                    </span>
                    <img
                      className={
                        isSeeLessVisible ? styles["rotate-caret-icon"] : ""
                      }
                      src={require("assets/images/caret-587E85.svg").default}
                      alt="caret"
                    />
                  </div>
                )}

                {popupOpen === userId && (
                  <div className={styles.schedular_popup_daily_view}>
                    <span
                      className={styles.close_btn}
                      onClick={() => handlePopupToggle(userId)}
                    >
                      <img src={crossIcon} alt="close" />
                    </span>
                    <div className={styles.content_wrapper}>
                      <div className={styles.client_img}>
                        <img src={profilePic || defautUserImage} alt="icon" />
                      </div>
                      <Text
                        size="14px"
                        weight="600"
                        color="#587E85"
                        marginTop="6px"
                      >
                        {fullName}
                      </Text>
                      <Text size="12px" weight="500" color="#87928D">
                        {designationNames?.map(
                          (designationName) => designationName
                        )}
                      </Text>
                      <Text size="12px" weight="500" color="#2A4642">
                        {officeNames?.join(", ")}
                      </Text>
                      <div className={styles.email_number}>
                        <div className={styles.wrap_cont}>
                          <img src={MessageIcon} alt="CallIcon" />
                          <Text size="12px" weight="500" color="#587E85" break>
                            {emailId}
                          </Text>
                        </div>
                        <div className={styles.wrap_cont}>
                          <img
                            src={CallIcon}
                            alt="CallIcon"
                            className={styles.call_icon_new}
                          />
                          <Text size="12px" weight="500" color="#587E85">
                            {contactNumber}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </th>
            {data.map((_item) => {
              const {
                requestedDate,
                appointments,
                leaves,
                schedulerBusySlots,
                schedulerEvents,
                appointmentSlots,
                holidayList,
              } = _item;

              // console.log(holidayList);
              const [allDayEvents, allOtherEvents] = getDifferentEvents(
                schedulerEvents || []
              );
              let allItems = [
                appointments || [],
                schedulerBusySlots || [],
                allOtherEvents || [],
                appointmentSlots || [],
              ]
                .flat()
                .sort((a, b) => {
                  if (
                    moment(
                      moment(b.startTime).format("HH:mm"),
                      "HH:mm"
                    ).isAfter(
                      moment(moment(a.startTime).format("HH:mm"), "HH:mm")
                    )
                  ) {
                    return -1;
                  } else {
                    return 1;
                  }
                });
              allItems = [...(leaves || []), ...allDayEvents, ...allItems];
              let slicedItems = [...allItems];
              const showSeeMore = allItems.length > 2;
              const searchKey = `${userId}_${requestedDate}`;

              if (!seeMoreUsers.includes(userId)) {
                slicedItems = allItems.slice(0, 2);
              }
              if (
                !seeMoreUsers.includes(userId) &&
                seeMoreOnCalender.includes(searchKey)
              ) {
                slicedItems = [...allItems];
              }

              return (
                <td
                  key={requestedDate}
                  className={`${
                    holidayList?.length > 0 &&
                    (!viewSettingFilter.length ||
                      viewSettingFilter.includes("event-color"))
                      ? styles["light-red-bg"]
                      : `${styles["no-bg-theme"]} w-100`
                  }`}
                >
                  {slicedItems.map((item, index) => {
                    const { agendaType } = item;
                    switch (agendaType) {
                      case constants.agendaType.EVENT: {
                        return getSchedulerEventsView(
                          item,
                          index,
                          searchKey,
                          requestedDate,
                          fullName
                        );
                      }
                      case constants.agendaType.BUSY_SLOT:
                      case constants.agendaType.BLOCKED: {
                        return getSchedulerBusySlotsView(
                          item,
                          index,
                          agendaType === constants.agendaType.BUSY_SLOT,
                          searchKey,
                          requestedDate,
                          fullName
                        );
                      }
                      case constants.agendaType.APPOINTMENT: {
                        return getAppointmentsView(
                          item,
                          index,
                          searchKey,
                          requestedDate,
                          fullName
                        );
                      }
                      case constants.agendaType.LEAVE: {
                        return getLeavesView(
                          item,
                          index,
                          searchKey,
                          requestedDate,
                          fullName
                        );
                      }
                      default: {
                        return null;
                      }
                    }
                  })}
                  {!seeMoreUsers.includes(userId) && showSeeMore && (
                    <div className={styles["more-event-box"]}>
                      <span
                        className={styles["more-event-btn"]}
                        onClick={() => toggleSeeMoreOnCalender(searchKey)}
                      >
                        {seeMoreOnCalender.includes(searchKey)
                          ? t("scheduler.agenda.seeLess")
                          : t("scheduler.agenda.seeMoreWithCount", {
                              count: allItems.length - 2,
                            })}
                      </span>
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
        );
      });

    holidayRowJsx = (
      <tr>
        {agendaFilter.map((date, index) => {
          const adjustedIndex = index - 1;
          const validIndex = adjustedIndex >= 0 && agendaFilter[adjustedIndex];

          const holidayList = validIndex
            ? agendaFilter[adjustedIndex].holidayList
            : null;

          if (
            holidayList?.length > 0 &&
            validIndex?.requestedDate === holidayList?.[0]?.date
          ) {
            return (
              <td key={`holiday-${index}`} className={styles["holiday-cell"]}>
                <div className={styles["holiday_title"]}>
                  <img
                    src={HolidayImage}
                    alt="holiday"
                    className="account_owner_image mr-2"
                  />
                  <span>{holidayList[0].title}</span>
                </div>
              </td>
            );
          }
          return (
            <td
              key={`holiday-${index}`}
              style={isFromDashBoard ? { borderLeft: "none" } : {}}
            ></td>
          );
        })}
      </tr>
    );
  }

  return (
    <div
      className={"table-card-border "}
      style={isFromDashBoard ? { border: "none" } : {}}
    >
      {isLoading && <Loader />}
      <div className={styles["scheduler-table-wrapper"]}>
        <div
          ref={calendarRef}
          className={
            isFromDashBoard
              ? styles["scheduler-fixed-table-new"]
              : styles["scheduler-fixed-table"]
          }
        >
          <table
            className={
              "table table-bordered " +
              styles["schedular-table"] +
              " " +
              styles["staff-weekly-table"]
            }
            style={isFromDashBoard ? { borderLeft: "none" } : {}}
          >
            {!isFromDashBoard && (
              <thead>
                <tr>
                  <th>
                    <div
                      className={
                        styles["arrow-btn"] + " " + styles["prev-btn"] + " "
                      }
                      onClick={() => changeWeek("prev")}
                    >
                      <img
                        src={
                          require("assets/images/scheduler-table-arrow-left.svg")
                            .default
                        }
                        alt="icon"
                      />
                    </div>
                    {moment(currentDate).format("MMM DD, ddd")}
                    <div
                      className={
                        styles["arrow-btn"] + " " + styles["next-btn"] + " "
                      }
                      onClick={() => changeWeek("next")}
                    >
                      <img
                        src={
                          require("assets/images/scheduler-table-arrow-right.svg")
                            .default
                        }
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
            )}
            <tbody>
              {holidayRowJsx}
              {tableJsx}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(DailyViewNew);
