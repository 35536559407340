import React, { useState } from "react";
import styles from "../Dashboard.module.scss";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Suspense } from "react";
import Loader from "components/Loader";
import { withTranslation } from "react-i18next";

import OpenInIcon from "../../../../assets/images/open_in_new.svg";

import MyNotes from "./MyNotes";
import TransferredNotes from "./TransferredNotes";
import { getStorage, setStorage } from "utils";
import constants from "../../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tooltip from "reactstrap/lib/Tooltip";

const Notes = ({
  t,
  appliedOfficeFilters,
  appliedStaffFilters,
  isSchedulerOnly,
}) => {
  const cachedActiveTab = getStorage(constants.notes.cache.Noteslisting);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1
  );
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [tooltipEditOpen, setTooltipEditOpen] = useState(false);
  const [isArchive, setArchive] = useState(false);
  const toggleAddNoteModal = () => setIsAddNoteModalOpen(!isAddNoteModalOpen);

  const tabName = constants.notesTabStatus;

  const manageTab = (tab) => {
    setActiveTab(tab);
    setStorage(constants.notes.cache.Noteslisting, {
      activeTab: tab,
    });
  };

  const handleRedirect = (e) => {
    if (e.target.closest(".interactive-element")) {
      return;
    }
    history.push(constants.routes.notes);
    setStorage("isChatFromDashBoard", true);
  };

  return (
    <div
      className={`${styles.notes_tab_wrapper} + " " + ${styles.rounded_container}`}
    >
      <div className={styles.header_wrapper}>
        <Nav tabs className={styles["nav-tabs"]}>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeTab === tabName.myNote
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => manageTab(tabName.myNote)}
            >
              {t("notes.myNotes")}
            </NavLink>
          </NavItem>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeTab === tabName.transfferedNote
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => manageTab(tabName.transfferedNote)}
            >
              {t("notes.transferredNotes")}
            </NavLink>
          </NavItem>
        </Nav>
        <div className={styles.padding_right} onClick={handleRedirect}>
          <img src={OpenInIcon} alt="open-icon" id="notes_tooltip" />
        </div>
        <Tooltip
          isOpen={tooltipEditOpen}
          placement="top"
          target="notes_tooltip"
          toggle={() => {
            setTooltipEditOpen(!tooltipEditOpen);
          }}
        >
          {t("dashBoard.gotoNotes")}
        </Tooltip>
      </div>
      <TabContent
        activeTab={activeTab}
        className={styles.notes_tab_content}
        id="scrollableDiv"
      >
        <Suspense fallback={<Loader />}>
          <TabPane tabId={tabName.myNote} className="w-100">
            {activeTab === tabName.myNote && (
              <MyNotes
                manageTab={manageTab}
                tabName={tabName}
                isArchive={isArchive}
                appliedOfficeFilters={appliedOfficeFilters}
                appliedStaffFilters={appliedStaffFilters}
                handleRedirect={handleRedirect}
                isSchedulerOnly={isSchedulerOnly}
              />
            )}
          </TabPane>
          <TabPane tabId={tabName.transfferedNote} className="w-100">
            {activeTab === tabName.transfferedNote && (
              <TransferredNotes
                manageTab={manageTab}
                tabName={tabName}
                isArchive={isArchive}
                appliedOfficeFilters={appliedOfficeFilters}
                appliedStaffFilters={appliedStaffFilters}
                handleRedirect={handleRedirect}
                isSchedulerOnly={isSchedulerOnly}
              />
            )}
          </TabPane>
        </Suspense>
      </TabContent>
    </div>
  );
};

export default withTranslation()(Notes);
