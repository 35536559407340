import React from 'react'
import { withTranslation } from 'react-i18next'
import TypeOfAppointment from '../../components/TypeOfAppointment'
import Page from 'components/Page'

function SelectPatientAppointmentType({ history }) {
  return (
    <Page onBack={() => { history.push(`/patient-new-appointment`) }}>
      <TypeOfAppointment />
    </Page>
  )
}
export default withTranslation()(SelectPatientAppointmentType)