import Page from "components/Page";
import React, { useEffect, useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import styles from "./AddBusySlots.module.scss";
import "./AddBusySlots.scss";
import CustomSelect from "components/CustomSelect";
import CustomDropdown from "components/Dropdown";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import { Row, Col } from "reactstrap";
import Text from "components/Text";
import Alert from "reactstrap/lib/Alert";
import {
  createBusySlot,
  getStaffMembers,
  useAllActiveOffices,
} from "repositories/scheduler-repository";
import constants from "../../constants.js";
import { useOfficeDetail } from "repositories/office-repository";
import Loader from "components/Loader";
import moment from "moment";
import {
  scrollToError,
  updateTimeToUpcomingQuater,
  testRegexCheck,
  testRegexCheckDescription,
} from "utils";
import { cloneDeep } from "lodash";
import toast from "react-hot-toast";
import useReadOnlyDateTextInput from "hooks/useReadOnlyDateTextInput";
import ToggleSwitch from "components/ToggleSwitch";
import SelectEmployeeModal from "accountOwner/pages/AddEvent/components/SelectEmployeeModal";

const AddBusySlots = ({ t, history }) => {
  const goBack = () => history.push(constants.routes.scheduler.calendar);

  const [selectedOfficeId, setSelectedOfficeId] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [errors, setErrors] = useState({});
  const [eventDate, setEventDate] = useState(null);
  const [repeatType, setRepeatType] = useState("1");
  const [repeatEndDate, setRepeatEndDate] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const startDatePickerRef = useReadOnlyDateTextInput();
  const endDatePickerRef = useReadOnlyDateTextInput();
  const [toggleOn, setToggleOn] = useState(false);
  const [issaveEmployeeModalOpen, setIsSaveEmployeeModalOpen] = useState(false);
  const [allMembersList, setAllMembersList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userIds, setUserIds] = useState([]);

  const selectedOwner = localStorage.getItem("selectedOwner");
  const selectedOwnerId = selectedOwner ? JSON.parse(selectedOwner)?.id : null;

  const { isLoading: officeListLoading, data: officeList } =
    useAllActiveOffices(1, 100, selectedOwnerId);

  const { isLoading: officeDetailsLoading, data: officeDetails } =
    useOfficeDetail(selectedOfficeId, { enabled: !!selectedOfficeId });

  //for event Date
  const selectedOfficeCurrentDate = useMemo(() => {
    if (officeDetails?.state?.timezoneId) {
      return moment(
        moment
          .tz(officeDetails.state.timezoneId)
          .format("MMMM Do YYYY, h:mm:ss a"),
        "MMMM Do YYYY, h:mm:ss a"
      ).toDate();
    }
    return moment().toDate();
  }, [officeDetails]);

  useEffect(() => {
    setEventDate(selectedOfficeCurrentDate);
    setRepeatEndDate(selectedOfficeCurrentDate);
    setStartTime(null);
    setEndTime(null);
  }, [selectedOfficeCurrentDate]);

  const RepeatOptions = [
    { id: 1, name: `${t("scheduler.never")}` },
    {
      id: 2,
      name: `${t("scheduler.repeatForAll")} ${moment(
        eventDate || selectedOfficeCurrentDate
      ).format("dddd")}s`,
    },
    { id: 3, name: `${t("scheduler.repeatForAllFuture")}` },
  ];

  //for office
  let OfficeOptions = [];
  if (!officeListLoading && officeList?.data?.length) {
    OfficeOptions = officeList.data;
  }

  useEffect(() => {
    if (officeList?.data?.length === 1) {
      setSelectedOfficeId(officeList.data[0].id);
    }
  }, [officeList]);

  useEffect(() => {
    if (selectedOfficeId) {
      getOfficeMembers();
      setAllMembersList([]);
      setUserIds([]);
    }
  }, [selectedOfficeId]);

  const getOfficeMembers = async () => {
    setLoader(true);

    try {
      const res = await getStaffMembers(selectedOfficeId);

      if (res?.length) {
        setAllMembersList(res);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e.message);
    }
  };

  const handleSelectOffice = (value, errorsData) => {
    setSelectedOfficeId(value);
    if (!value) {
      errorsData.officeList = t("form.errors.emptySelection", {
        field: t("form.fields.office"),
      });
    } else {
      delete errorsData.officeList;
    }
  };

  const handleStartTime = (value, errorsData) => {
    setStartTime(value);
    if (value) delete errorsData.startTime;
    if (value && endTime) {
      const duration = moment
        .duration(moment(endTime).diff(moment(value)))
        .asMinutes();
      if (duration < 15) {
        setEndTime(moment(value).add(15, "minutes"));
        delete errorsData.endTime;
      }
    }
  };

  const handleEndTime = (value, errorsData) => {
    const newEndTimeSlot = updateTimeToUpcomingQuater(value);
    setEndTime(newEndTimeSlot);
    if (newEndTimeSlot) delete errorsData.endTime;
    if (startTime) {
      const duration = moment
        .duration(moment(newEndTimeSlot).diff(moment(startTime)))
        .asMinutes();
      if (duration > 14) {
        delete errorsData.endTime;
      } else {
        errorsData.endTime = t("form.errors.endTimeShouldBeGreater");
      }
    }
  };

  const handleTextField = (
    fieldName,
    value,
    errorsData,
    errorKey,
    errorField
  ) => {
    const setterMap = {
      eventLocation: setEventLocation,
      eventDescription: setEventDescription,
      eventTitle: setEventTitle,
    };
    setterMap[fieldName](value);

    if (!value.trim().length) {
      errorsData[errorKey] = t("form.errors.emptyField", {
        field: t(errorField),
      });
    } else {
      delete errorsData[errorKey];
    }
  };

  const handleChange = (field, value) => {
    let errorsData = cloneDeep(errors);

    switch (field) {
      case "selectOffice":
        handleSelectOffice(value, errorsData);
        break;
      case "startTime":
        handleStartTime(value, errorsData);
        break;
      case "endTime":
        handleEndTime(value, errorsData);
        break;
      case "eventLocation":
        handleTextField(
          "eventLocation",
          value,
          errorsData,
          "eventLocation",
          "location"
        );
        break;
      case "eventDescription":
        handleTextField(
          "eventDescription",
          value,
          errorsData,
          "eventDescription",
          "form.fields.description"
        );
        break;
      case "eventTitle":
        handleTextField("eventTitle", value, errorsData, "eventTitle", "title");
        break;
      default:
        break;
    }

    setErrors(errorsData);
  };

  const isValidEvent = () => {
    const errorsData = cloneDeep(errors);

    const validateField = (condition, errorKey, errorMessage) => {
      if (condition) {
        errorsData[errorKey] = errorMessage;
      } else {
        delete errorsData[errorKey];
      }
    };

    // Office
    validateField(
      !selectedOfficeId,
      "officeList",
      t("form.errors.emptySelection", { field: t("form.fields.office") })
    );

    // Title
    validateField(
      !eventTitle.trim().length,
      "eventTitle",
      t("form.errors.emptyField", { field: t("title") })
    );

    // Start Time
    validateField(
      !startTime,
      "startTime",
      t("form.errors.emptySelection", { field: t("staff.startTime") })
    );

    // End Time
    validateField(
      !endTime,
      "endTime",
      t("form.errors.emptySelection", { field: t("staff.endTime") })
    );

    // Start & End Time gap
    if (startTime && endTime) {
      const duration = moment
        .duration(moment(endTime).diff(moment(startTime)))
        .asMinutes();
      validateField(
        duration <= 14,
        "endTime",
        t("form.errors.endTimeShouldBeGreater")
      );
    }

    // Location
    validateField(
      !eventLocation.trim().length,
      "eventLocation",
      t("form.errors.emptyField", { field: t("location") })
    );

    // Description
    validateField(
      !eventDescription.trim().length,
      "eventDescription",
      t("form.errors.emptyField", { field: t("form.fields.description") })
    );

    // Employees
    validateField(
      !userIds.length && toggleOn,
      "selectEmployees",
      t("form.errors.emptySelection", { field: t("accountOwner.employees") })
    );

    setErrors(errorsData);

    const hasErrors = Object.values(errorsData).some((err) => !!err);
    if (hasErrors) scrollToError();

    return !hasErrors;
  };

  const handleBlock = async () => {
    if (isValidEvent() && officeDetails) {
      const body = {
        OwnerId: officeDetails.ownerId,
        OfficeId: officeDetails.id,
        Title: eventTitle,
        Date: moment(eventDate).format("YYYY-MM-DD"),
        StartTime:
          moment(eventDate).format("YYYY-MM-DDT") +
          moment(startTime).format("HH:mm"),
        EndTime:
          moment(eventDate).format("YYYY-MM-DDT") +
          moment(endTime).format("HH:mm"),
        repeatedType: +repeatType,
        isRepeated: +repeatType > 1,
        repeatedDay: +repeatType === 1 ? null : moment(eventDate).isoWeekday(),
        RepeatedEndDate:
          +repeatType === 1 ? null : moment(repeatEndDate).format("YYYY-MM-DD"),
        Location: eventLocation,
        Description: eventDescription,
        IsNotifyEmployee: toggleOn,
        UserIds: userIds.map((val) => val.userId),
      };
      setInProgress(true);
      try {
        await createBusySlot(body);
        toast.success(t("scheduler.busySlotAdded"));
        setInProgress(false);
        goBack();
      } catch (err) {
        toast.error(err.message);
        setInProgress(false);
      }
    }
  };

  const getSelectedOption = () => {
    const selectedData =
      OfficeOptions.find(
        (val) => val.id.toString() === selectedOfficeId?.toString()
      ) || {};
    return selectedData.name;
  };

  const openEmployeePopUp = () => {
    let errorsData = JSON.parse(JSON.stringify(errors));

    if (selectedOfficeId) {
      setIsSaveEmployeeModalOpen(true);
      delete errorsData["selectEmployees"];
    } else {
      errorsData["selectEmployees"] = t("form.errors.emptySelection", {
        field: t("superAdmin.office"),
      });
    }
    setErrors(errorsData);
    scrollToError();
  };

  const handleToggle = () => {
    setToggleOn(!toggleOn);
  };

  const firstUserName = () => {
    if (userIds?.length === 1 && allMembersList?.length) {
      let { firstName, lastName } = allMembersList?.find(
        (e) => e.id === userIds?.[0]?.userId
      );
      return `${firstName} ${lastName}`;
    } else {
      return `${userIds?.length} ${t("Selected")}`;
    }
  };

  return (
    <Page
      onBack={() => {
        goBack();
      }}
    >
      {(officeListLoading || officeDetailsLoading || inProgress || loader) && (
        <Loader />
      )}
      <div className="mx-auto p-0 container container-smd">
        <h2 className="page-title mb-3">
          {t("accountOwner.addBusyTimeslots")}
        </h2>
        <Text size="14px" marginBottom="25px" weight="300" color="#000">
          {" "}
          {t("accountOwner.addBusyTimeslotsDesc")}
        </Text>
        <div className="form-wrapper mb-5">
          {!officeListLoading && !(OfficeOptions && OfficeOptions.length) && (
            <Text size="14px" marginBottom="25px" weight="400" color="red">
              {t("scheduler.noOfficeFoundForBusyslots")}
            </Text>
          )}
          <div className={styles["busy-timeslots-form"]}>
            <div className="custom-dropdown-only">
              <CustomSelect
                Title={t("superAdmin.office")}
                options={OfficeOptions}
                id={"OfficeOptions"}
                dropdownClasses={"custom-select-scroll"}
                Classes={
                  !(OfficeOptions && OfficeOptions.length)
                    ? "custom-disabled-field"
                    : ""
                }
                selectedOption={{ name: getSelectedOption() }}
                selectOption={(value) =>
                  handleChange("selectOffice", value.id.toString())
                }
              />
            </div>
            {errors.officeList && (
              <span className="error-msg">{errors.officeList}</span>
            )}

            {!!officeDetails && (
              <Alert color="warning" className="event-alert-box">
                This event will be created using your office time zone:{" "}
                {officeDetails.state.timezoneName}
              </Alert>
            )}
            <div className="c-field">
              <label>{t("title")}</label>
              <textarea
                className="c-form-control"
                placeholder={t("form.placeholder1", { field: t("title") })}
                name="title"
                maxLength="400"
                value={eventTitle}
                onChange={(e) =>
                  testRegexCheck(e.target.value) &&
                  handleChange("eventTitle", e.target.value)
                }
              ></textarea>
              {errors.eventTitle && (
                <span className="error-msg">{errors.eventTitle}</span>
              )}
            </div>
            <div className="c-field">
              <label>{t("accountOwner.date")}</label>
              <div className="d-flex inputdate">
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  className="c-form-control"
                  onChange={(e) => {
                    setEventDate(e);

                    setRepeatEndDate(e);
                  }}
                  minDate={selectedOfficeCurrentDate}
                  selected={eventDate || selectedOfficeCurrentDate}
                  ref={startDatePickerRef}
                />
              </div>
            </div>
            <Row>
              <Col xs="6">
                <div className="c-field">
                  <label>{t("start")}</label>
                  <TimePicker
                    showSecond={false}
                    placeholder="--"
                    format="h:mm A"
                    use12Hours
                    onChange={(e) => handleChange("startTime", e)}
                    className={"busy-slot-time-picker"}
                    value={startTime}
                    minuteStep={15}
                  />
                  {errors.startTime && (
                    <span className="error-msg">{errors.startTime}</span>
                  )}
                </div>
              </Col>
              <Col xs="6">
                <div className="c-field">
                  <label>{t("end")}</label>
                  <TimePicker
                    showSecond={false}
                    placeholder="--"
                    format="h:mm A"
                    use12Hours
                    onChange={(e) => handleChange("endTime", e)}
                    className={"busy-slot-time-picker"}
                    value={endTime}
                    minuteStep={15}
                  />
                  {errors.endTime && (
                    <span className="error-msg">{errors.endTime}</span>
                  )}
                </div>
              </Col>
            </Row>
            <div className="c-field">
              <label>{t("repeat")}</label>
              <div className="custom-dropdown-only">
                <CustomDropdown
                  options={RepeatOptions}
                  selectedOption={repeatType}
                  selectOption={(id) => {
                    setRepeatType(id.toString());
                  }}
                />
              </div>
            </div>
            {repeatType !== "1" && (
              <div className="c-field">
                <label>{t("accountOwner.endDateRepeatedEvents")}</label>
                <div className="d-flex inputdate">
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    className="c-form-control"
                    minDate={eventDate}
                    selected={repeatEndDate || selectedOfficeCurrentDate}
                    onChange={(e) => setRepeatEndDate(e)}
                    ref={endDatePickerRef}
                  />
                </div>
              </div>
            )}

            <div className="c-field">
              <label>{t("location")}</label>
              <textarea
                className="c-form-control"
                placeholder={t("form.placeholder1", { field: t("location") })}
                name="location"
                maxLength="400"
                value={eventLocation}
                onChange={(e) =>
                  testRegexCheckDescription(e.target.value) &&
                  handleChange("eventLocation", e.target.value)
                }
              ></textarea>
              {errors.eventLocation && (
                <span className="error-msg">{errors.eventLocation}</span>
              )}
            </div>

            <div className="c-field">
              <label>{t("superAdmin.description")}</label>
              <textarea
                className="c-form-control"
                placeholder={t("form.placeholder1", {
                  field: t("superAdmin.description"),
                })}
                name="Description"
                maxLength="1000"
                value={eventDescription}
                onChange={(e) =>
                  handleChange("eventDescription", e.target.value)
                }
              ></textarea>
              {errors.eventDescription && (
                <span className="error-msg">{errors.eventDescription}</span>
              )}
            </div>
            <div className="c-field">
              <div className="d-flex align-items-center">
                <ToggleSwitch
                  className="mt-0"
                  label="bill-me-later-toggle"
                  onChange={handleToggle}
                />
                <div>
                  <Text
                    size="14px"
                    marginBottom="0px"
                    weight="600"
                    className="mr-2 mt-2"
                    color="#2F3245"
                  >
                    {t("accountOwner.notifyEmployee")}
                  </Text>
                  <Text size="12px">
                    {t("accountOwner.timeslotNotifyEmployee")}
                  </Text>
                </div>
              </div>
            </div>
            {toggleOn && (
              <div className="c-field">
                <label className="c-field-label  d-flex justify-content-between">
                  {t("accountOwner.employe")}
                  <span
                    className="link-btn"
                    onClick={() => {
                      openEmployeePopUp();
                    }}
                  >
                    {t("accountOwner.selectEmployees")}
                  </span>
                </label>

                <div className="c-form-control ">
                  <span>
                    {userIds?.length === 0
                      ? t("accountOwner.noEmployeesSelected")
                      : firstUserName()}
                  </span>
                </div>
                {toggleOn && !userIds.length && errors.selectEmployees && (
                  <span className="error-msg">{errors.selectEmployees}</span>
                )}
              </div>
            )}
            <div className="mt-2">
              <button
                className="button button-round button-shadow mr-4 mb-3"
                title={t("accountOwner.blockSlot")}
                onClick={handleBlock}
              >
                {t("accountOwner.blockSlot")}
              </button>
              <button
                className="button button-round button-dark button-border"
                title={t("cancel")}
                onClick={goBack}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {issaveEmployeeModalOpen && (
        <SelectEmployeeModal
          issaveEmployeeModalOpen={issaveEmployeeModalOpen}
          setIsSaveEmployeeModalOpen={setIsSaveEmployeeModalOpen}
          allMembersList={allMembersList}
          setAllMembersList={setAllMembersList}
          memberIds={userIds}
          setMemberIds={setUserIds}
          officeType={selectedOfficeId}
        />
      )}
    </Page>
  );
};

export default withTranslation()(AddBusySlots);
