import axios from "axios";
import { useQuery } from "react-query";
import { Config } from "../config";

const employmentProofBaseUrl = Config.serviceUrls.employmentProofBaseUrl;

export async function getOfficeEmploymentProof(isAdmin, pageNumber, pageSize, officeId, isArchive) {
    const endPath = isAdmin ? 'ListByOffice' : 'ListForStaff';
    const response = await axios.get(`${employmentProofBaseUrl}/EmploymentProof/${endPath}`, {
        params: {
            pageNumber,
            pageSize,
            officeId,
            isArchived: isArchive
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return {
        items: response.data.data,
        pagination: response.data.pagination,
    };
}

export function useOfficeEmploymentProof(isAdmin, pageNumber, pageSize, officeId, isArchive) {
    return useQuery(
        ["performance-review", isAdmin, pageNumber, pageSize, officeId, isArchive],
        () => getOfficeEmploymentProof(isAdmin, pageNumber, pageSize, officeId, isArchive),
        {
            staleTime: 0,
            cacheTime: 0
        }
    );
}

export async function archieveEmploymentProof(proofId) {
    const response = await axios.patch(`${employmentProofBaseUrl}/EmploymentProof/${proofId}/Archive`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function unArchieveEmploymentProof(proofId) {
    const response = await axios.patch(`${employmentProofBaseUrl}/EmploymentProof/${proofId}/Unarchive`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteEmploymentProof(proofId) {
    const response = await axios.delete(`${employmentProofBaseUrl}/EmploymentProof/${proofId}`);
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getFormTemplate(officeId, isSavedTemplate) {
    const response = await axios.get(`${employmentProofBaseUrl}/EmploymentProof/Template`, {
        params: {
            officeId,
            isSavedTemplate
        }
    });

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }

    return response.data?.data;
}

export function useGetFormTemplate(officeId, isSavedTemplate) {
    return useQuery(
        ["performance-review", officeId, isSavedTemplate],
        () => getFormTemplate(officeId, isSavedTemplate)
    );
}

export async function getFormDataById(proofId) {
    const response = await axios.get(`${employmentProofBaseUrl}/EmploymentProof/${proofId}`);

    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data?.data;
}

export function useGetFormDataById(proofId) {
    return useQuery(
        ["performance-review-by-id", proofId],
        () => getFormDataById(proofId)
    );
}

export const exportEmploymentProof = async (officeId, body) => {
    const response = await axios.post(`${employmentProofBaseUrl}/EmploymentProof/Pdf`, { officeId, ...body });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data.data;
};

export const submitEmploymentProof = async (EmploymentProofId, body) => {
    const response = await axios.post(`${employmentProofBaseUrl}/EmploymentProof/Submit`, { EmploymentProofId, ...body });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function requestEmploymentProof(officeId, position, employeeName, ReasonForRequest) {
    const response = await axios.post(`${employmentProofBaseUrl}/EmploymentProof/Request?officeId=${officeId}&position=${position}&employeeName=${employeeName}&ReasonForRequest=${ReasonForRequest}`, {
        params: {
            officeId,
            position,
            employeeName,
            ReasonForRequest
        }
    });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export const SaveEmploymentProof = async (EmploymentProofId) => {
    const response = await axios.post(`${employmentProofBaseUrl}/EmploymentProof/SaveTemplate`, { EmploymentProofId });
    if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
}
