import React, { useState } from "react";
import styles from "../Dashboard.module.scss";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import constants from "../../../../constants";
import { getStorage, setStorage } from "utils";
import { Suspense } from "react";
import Loader from "components/Loader";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RequestInvitation from "./RequestInvitation";
import DashBoardNotification from "./RequestApprovalNotificationCard";
import MessageDot from "components/MessageDot";
import RequestAndApprovals from "./RequestAndApprovals";

const RequestApproval = ({
  t,
  isAdminData,
  appliedOfficeFilters,
  appliedStaffFilters,
  isSchedulerOnly,
}) => {
  const profile = useSelector((state) => state.userProfile.profile);
  const newNotification = useSelector((e) => e.notification);
  let isOwnerOrAdmin =
    profile?.role?.systemRole === constants.systemRoles.accountOwner ||
    isAdminData?.isAdmin;
  const [activeTab, setActiveTab] = useState(1);

  const tabName = constants.requestApprovalTabStatus;
  const manageTab = (tab) => {
    setActiveTab(tab);
    setStorage(constants.notes.cache.Noteslisting, {
      activeTab: tab,
    });
  };

  return (
    <div className={styles.request_approval_container + " " + "h-100"}>
      <div className={styles.header_wrapper}>
        <Nav tabs className={styles["nav-tabs"]}>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeTab === tabName.requestsInvitations
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => manageTab(tabName.requestsInvitations)}
            >
              {isOwnerOrAdmin
                ? t("requestAndApproval")
                : t("requestAndInvitations")}
            </NavLink>
          </NavItem>
          <NavItem className={styles["nav-items"]}>
            <NavLink
              className={
                activeTab === tabName.Notifications
                  ? styles["active"]
                  : styles["not-active"]
              }
              onClick={() => manageTab(tabName.Notifications)}
            >
              {t("notificationsPage.title")}
              {newNotification && (
                <span className="notification-red-dot">
                  <img
                    alt="red-dot"
                    src={require("assets/images/red-dot.svg").default}
                  />
                </span>
              )}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent
        activeTab={activeTab}
        className={styles.notes_tab_content}
        id="scrollableNotifications"
      >
        <Suspense fallback={<Loader />}>
          <TabPane tabId={tabName.requestsInvitations}>
            {activeTab === tabName.requestsInvitations && (
              <div className={styles.request_approval_card_container}>
                {isOwnerOrAdmin ? (
                  <RequestAndApprovals
                    appliedOfficeFilters={appliedOfficeFilters}
                    appliedStaffFilters={appliedStaffFilters}
                    isSchedulerOnly={isSchedulerOnly}
                  />
                ) : (
                  <RequestInvitation />
                )}
              </div>
            )}
          </TabPane>
          <TabPane tabId={tabName.Notifications}>
            {activeTab === tabName.Notifications && (
              <div className={styles.request_approval_card_container}>
                <DashBoardNotification />
              </div>
            )}
          </TabPane>
        </Suspense>
      </TabContent>
    </div>
  );
};
export default withTranslation()(RequestApproval);
