import React, { useEffect, useState } from "react";
import Card from "components/Card";
import Page from "components/Page";
import styles from "../DiscussionNotes.module.scss";
import NoteDetailsCard from "./NoteDetailsCard";
import NoteDetail from "./NoteDetail";
import { useAllDiscussionNotes } from "repositories/discussion-notes-repository";
import { uniqBy } from "lodash";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/Loader";
import { withTranslation } from "react-i18next";
import DiscussionNoteModal from "../modals/DiscussionNoteModal";
import Empty from "components/Empty";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { decodeId } from "utils";

const pageSize = 10;
const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

function DiscussionNoteDetails({ t, history, location }) {
  let { staffId } = useParams();
  staffId = decodeId(staffId);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [notes, setNotes] = useState([]);
  const [selectNote, setSelectNote] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOPen, setIsEditModalOpen] = useState(false);
  const [hideAllCards, setHideAllCards] = useState(false);
  const [isMobileView, setMobileView] = useState(false);

  const closeModal = () => setIsModalOpen(false);
  const closeEditModal = () => setIsEditModalOpen(false);

  const { isLoading, data, refetch } = useAllDiscussionNotes(
    staffId,
    pageNumber,
    pageSize,
  );

  useEffect(() => {
    if (!isLoading && data && data.items) {
      setNotes(prev => uniqBy([...prev, ...data.items], "id"));
      setTotalItems(data.pagination.totalItems);
    }
  }, [isLoading, data]);

  const officeName = notes?.[0]?.office?.name || location?.state?.officeName;
  const staffUserName = notes?.[0]?.user?.fullName || location?.state?.staffName;

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 768);
    };
    if (selectNote) setHideAllCards(true);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleUpdateNotes = (noteId, updatedNote) => {
    setSelectNote(prev => prev.id === noteId ? { ...prev, ...updatedNote } : { ...prev })
    setNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteId ? { ...note, ...updatedNote } : note))
    );
  };

  const deleteNotes = (noteId) => {
    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
    setSelectNote(null);
  };

  const refetchNote = () => {
    setNotes([]);
    setPageNumber(1);
    refetch();
  }

  const handleCardClick = (note) => {
    setSelectNote(note);
    if (window.innerWidth <= 767) {
      setHideAllCards(true);
    }
  };

  let items = notes.length > 0 && notes.map((note) => (
    <NoteDetailsCard
      key={note?.id}
      t={t}
      note={note}
      setSelectNote={handleCardClick}
      selectNote={selectNote}
      staffId={staffId}
      handleUpdateNotes={handleUpdateNotes}
      deleteNotes={deleteNotes}
    />
  ));

  const goBack = () => {
    if (window.innerWidth <= 767 && selectNote) {
      setSelectNote(null);
      setHideAllCards(false);
    } else {
      history.goBack()
    }
  }

  return (
    <Page onBack={goBack}>
      {(isLoading) && <Loader />}
      <div className={styles.title_subtitle_btn_wrapper}>
        <div className={styles.title_subtitle_wrapper}>
          <div className={styles.title}>{officeName}</div>
          <div className={styles.subtitle}>{staffUserName}</div>
        </div>
        <button
          className={styles.green_btn + " " + "button button-round button-shadow w-sm-100"}
          onClick={() => setIsModalOpen(true)}
        >
          {t('discussionNote.addNote')}
        </button>
      </div>
      <Card className={styles.discuss_note_details_card}>
        {notes.length === 0 ?
          <div className={styles["not_found"]}>
            <Empty Message={t('discussionNote.noteAvailable')} />
          </div>
          :
          <div className={styles.left_right_wrapper}>
            {(isMobileView ? !hideAllCards : true) && <div className={styles.left_part}>
              <motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
                <InfiniteScroll
                  className="w-100"
                  dataLength={notes.length}
                  hasMore={notes.length < totalItems}
                  next={() => setPageNumber((v) => v + 1)}
                >
                  <div className={styles.note_details_card_wrapper}>{items}</div>
                </InfiniteScroll>
              </motion.div>
            </div>}
            {(isMobileView ? hideAllCards : true) && <div className={styles.right_part}>
              <NoteDetail t={t} selectNote={selectNote} setIsEditModalOpen={setIsEditModalOpen} />
            </div>}
          </div>}
      </Card>
      {isModalOpen && (
        <DiscussionNoteModal
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          refetchNote={refetchNote}
          staffId={staffId}
          isEditing={false}
        />
      )}
      {isEditModalOPen && (
        <DiscussionNoteModal
          closeModal={closeEditModal}
          isModalOpen={isEditModalOPen}
          staffId={staffId}
          selectNote={selectNote}
          isEditing={true}
          handleUpdateNotes={handleUpdateNotes}
        />
      )}
    </Page>
  );
}

export default withTranslation()(DiscussionNoteDetails);
