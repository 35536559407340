import React, { useState } from "react";
import { Card } from "reactstrap";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import Text from "components/Text";
import { Modal, ModalBody } from "reactstrap";
import SchedulerCalendar from "accountOwner/pages/Scheduler/components/SchedulerCalendar";
import styles from "../../../../Appointments.module.scss";
import BookTimeslot from "accountOwner/pages/Appointments/components/BookTimeslot";
import useReAssigneAppointmentModule from "accountOwner/pages/Appointments/hooks/useReAssigneAppointmentModule";
import moment from "moment";
import constants from "../../../../../../../constants";
import Loader from "components/Loader";

function RescheduleAppointment({ t }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [selectedSlotDate, setSlotDate] = useState(new Date());

  const {
    data: { appointmentId, officeId, appointmentDetail, loader },
    methods: { goToPreviousScreen, handleRescheduleSubmit },
  } = useReAssigneAppointmentModule();

  const onActiveStartDateChange = (value) => {
    if (
      constants.calanderActions.includes(value?.action) ||
      (value?.action === constants.calanderActionKey.drillDown &&
        (value?.view === "month" || value?.view === "year"))
    ) {
      value?.activeStartDate && setSlotDate(value.activeStartDate);
    }
  };

  const handleReAssignSubmit = async (e) => {
    const startTimes = appointmentSlots
      ?.filter((app) => app.startTime)
      ?.map((app) => app.startTime);
    const params = {
      doctorId: appointmentDetail?.doctor?.id,
      appointmentId: appointmentId,
      appointmentDate: moment(selectedSlotDate).format("YYYY-MM-DD"),
      reminderDate: moment
        .utc(selectedSlotDate)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      slots: startTimes,
    };
    await handleRescheduleSubmit(e, params);
  };

  const selectedDate = moment(selectedSlotDate).startOf("day");

  return (
    <Page onBack={goToPreviousScreen} className={styles["main-page"]}>
      {loader && <Loader />}
      <div
        className={
          styles["manage-schedule-heading"] +
          " " +
          " d-flex justify-content-between"
        }
      >
        <div>
          <h3>{appointmentDetail?.office?.name}</h3>
          <Text color="#000000" weight="300" size="14px">
            Reschedule Appointment
          </Text>
        </div>
      </div>
      <Card className="form-wrapper">
        <div className={styles["content-part"]}>
          <div>
            <SchedulerCalendar
              value={selectedSlotDate}
              onChange={setSlotDate}
              onActiveStartDateChange={onActiveStartDateChange}
            />
            <div
              className={styles["scheduler-calendar-timezone-separator"]}
            ></div>
            <Text size="12px" weight="400" color="#6F7788">
              Timezone
            </Text>
            <Text size="14px" weight="600" color="#102C42">
              PST
            </Text>
          </div>
          <div className="w-100">
            <div className={styles["book-slot"] + " " + "d-flex flex-column"}>
              <div className={styles["book-slot-new-appointment"]}>
                <div>
                  <Text
                    color="#587E85"
                    weight="600"
                    size="16px"
                    className={styles["appointment-bookslot-heading"]}
                  >
                    {t("patientAppointmentMembers.reassignBookingTimeslot")}
                  </Text>
                </div>
                <div className={styles["notify-checkbox"] + " " + " d-flex "}>
                  <div
                    className={styles["checkbox-container"] + " " + " d-flex "}
                  >
                    <div
                      className={
                        styles["show-checkedbox"] + " " + styles["box1"]
                      }
                    ></div>
                    {t("patientAppointmentMembers.reassignBooked")}
                  </div>
                  <div
                    className={styles["checkbox-container"] + " " + "d-flex "}
                  >
                    <div
                      className={
                        styles["show-checkedbox"] + " " + styles["box2"]
                      }
                    ></div>
                    {t("patientAppointmentMembers.reassignAvailable")}
                  </div>
                  <div
                    className={styles["checkbox-container"] + " " + "d-flex "}
                  >
                    <div
                      className={
                        styles["show-checkedbox"] + " " + styles["box3"]
                      }
                    ></div>
                    {t("patientAppointmentMembers.reassignBlocked")}
                  </div>
                </div>
              </div>

              <BookTimeslot
                t={t}
                officeId={officeId}
                date={moment(selectedDate).format("YYYY-MM-DD")}
                doctorId={appointmentDetail?.doctor?.id}
                setSelectedSlots={setAppointmentSlots}
                selectedSlots={appointmentSlots}
              />
            </div>
            <div className={styles["btn-container"] + " " + "d-flex mt-4"}>
              <button
                className="button button-round button-shadow mt-md-3 w-sm-100"
                onClick={handleReAssignSubmit}
              >
                {t("patientAppointmentMembers.reschedule")}
              </button>
              <button
                className="button button-round button-border button-dark btn-mobile-link mt-md-3"
                onClick={goToPreviousScreen}
              >
                {t("patientAppointmentMembers.reassignCancelBtn")}
              </button>
              {/* <button className='button button-round button-shadow  d-none' >
                {t("patientAppointmentMembers.reassignUnblockTimeslot")}
              </button> */}
            </div>
          </div>
        </div>
      </Card>
      {/* Modal for selecting the reason of block timeslot  */}
      <Modal
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        className="modal-dialog-centered deactivate-modal modal-width-660"
      >
        <div className="timeslot-modal">
          <ModalBody className="d-flex flex-column modal-content-part p-0">
            <div>
              <span
                className="close-btn d-flex justify-content-end"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <img
                  src={require("assets/images/cross.svg").default}
                  alt="close"
                />
              </span>
              <div className="text-center">
                <Text
                  color="#111B45"
                  size="25px"
                  weight="500"
                  marginBottom="11px"
                >
                  Confirmation
                </Text>
                <Text color="#535B5F" weight="300" size="16px">
                  Are you sure you want to Cancel Next Visit?
                </Text>
              </div>
            </div>
            <div className="btn-container w-100 d-flex modal-btn-container justify-content-center">
              <button className="button button-round button-shadow">
                {" "}
                Yes, Confirm{" "}
              </button>
              <button className="button button-round button-border button-dark">
                {" "}
                Cancel{" "}
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </Page>
  );
}
export default withTranslation()(RescheduleAppointment);
