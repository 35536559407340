import React from "react";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../TaskManagement.module.scss";
import Text from "components/Text";
import { withTranslation } from "react-i18next";

const DeleteModal = ({
  t,
  isDeleteModalOpen,
  closeDeleteModal,
  deleteAction,
  taskId,
}) => {
  const handleApply = () => {
    deleteAction(taskId);
    closeDeleteModal();
  };

  return (
    <Modal
      isOpen={isDeleteModalOpen}
      toggle={closeDeleteModal}
      className="modal-dialog-centered modal-lg"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeDeleteModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <div className={styles["delete_task_modal"]}>
          <Text size="25px" weight="500" color="#111B45">
            {t("staff.deleteTask")}?
          </Text>

          <Text size="16px" weight="300" color="#535B5F" marginTop="11px">
            {t("taskManagement.deleteMsg")}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {t("taskManagement.deleteConfirmation")}
          </Text>

          <div
            className={styles["delete_task_btn_wrapper"] + " btn-box d-md-flex"}
          >
            <button
              className={
                styles["delete_task_btn"] +
                " button button-round button-shadow mr-md-4 mb-3 w-sm-100"
              }
              title="save"
              onClick={handleApply}
            >
              {t("YesDelete")}
            </button>
            <button
              className={
                styles["delete_task_btn"] +
                " mb-md-3 button button-round button-border btn-mobile-link button-dark"
              }
              title="cancel"
              onClick={closeDeleteModal}
            >
              {t("notes.cancel")}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(DeleteModal);
