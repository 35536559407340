import { withTranslation } from "react-i18next";
import MyOwnTask from "./MyOwnTask";
import Card from "components/Card";
import styles from "../TaskManagement.module.scss";
import constants from "../../../../constants";
import { useSelector } from "react-redux";


const MyTasks = ({ tasks,
    searchValue,
    handleSearchTerm,
    officeFilter,
    handleApplyFilters,
    setOfficeFilter,
    staffFilter,
    setStaffFilter,
    resetFilter,
    statusFilter,
    priorityFilter,
    setStatusFilter,
    setPriorityFilter,
    isLoading,
    totalItems,
    setPageNumber,
    handleAction,
    deleteAction,
    isArchive,
    isFilterApply,
    accountOwnerId,
    sortBy,
    setSortBy,
    priority,
    setPriority,
    status,
    setStatus,
    handleArchiveApi
}) => {
    const profile = useSelector((state) => state.userProfile.profile);

    return (
        <>
            {profile?.role?.systemRole === constants.systemRoles.accountOwner ? (
                <Card className={styles["task_management_main_card"]}>
                    <MyOwnTask
                        tasks={tasks}
                        searchValue={searchValue}
                        handleSearchTerm={handleSearchTerm}
                        officeFilter={officeFilter}
                        setOfficeFilter={setOfficeFilter}
                        handleApplyFilters={handleApplyFilters}
                        staffFilter={staffFilter}
                        setStaffFilter={setStaffFilter}
                        resetFilter={resetFilter}
                        statusFilter={statusFilter}
                        priorityFilter={priorityFilter}
                        setStatusFilter={setStatusFilter}
                        setPriorityFilter={setPriorityFilter}
                        isLoading = {isLoading}
                        totalItems = {totalItems}
                        setPageNumber = {setPageNumber}
                        handleAction={handleAction}
                        deleteAction={deleteAction}
                        isArchive={isArchive}
                        isFilterApply={isFilterApply}
                        accountOwnerId={accountOwnerId}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        priority ={priority}
                        setPriority ={setPriority}
                        status={status}
                        setStatus ={setStatus}
                        handleArchiveApi ={handleArchiveApi}
                    />
                </Card>
            )
                : (<MyOwnTask
                    tasks={tasks}
                    searchValue={searchValue}
                    handleSearchTerm={handleSearchTerm}
                    officeFilter={officeFilter}
                    setOfficeFilter={setOfficeFilter}
                    handleApplyFilters={handleApplyFilters}
                    staffFilter={staffFilter}
                    setStaffFilter={setStaffFilter}
                    resetFilter={resetFilter}
                    statusFilter={statusFilter}
                    priorityFilter={priorityFilter}
                    setStatusFilter={setStatusFilter}
                    setPriorityFilter={setPriorityFilter}
                    isLoading = {isLoading}
                    totalItems = {totalItems}
                    setPageNumber = {setPageNumber}
                    handleAction={handleAction}
                    deleteAction={deleteAction}
                    isArchive={isArchive}
                    isFilterApply={isFilterApply}
                    accountOwnerId={accountOwnerId}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    priority ={priority}
                    setPriority ={setPriority}
                    status={status}
                   setStatus ={setStatus}
                   handleArchiveApi={handleArchiveApi}
                />)}
        </>
    );
};
export default withTranslation()(MyTasks);
