import React, { useEffect, useState } from 'react';
import "../../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";

function StatusFilter({ setStatusFilter, selectedStatus, statuses }) {
    const [status, setStatus] = useState(selectedStatus);

    useEffect(() => {
        if(selectedStatus) {
            setStatus([...selectedStatus])
        } else {
            setStatus([]);
        }
    },[selectedStatus]);

    const handleChange = (e, key) => {
        let newStatus = status ? status : [];
        if (e.target.checked) {
            newStatus.push(key);
        } else {
            newStatus = newStatus.filter(s => s !== key);
        }
        setStatus(newStatus);
        setStatusFilter(newStatus)
    };

    return (
        <ul className="filter-list">
            {statuses.map((key, index) => (
                <li key={index}>
                    <div className="ch-checkbox">
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleChange(e, key)}
                                checked={status?.includes(key)}
                            />
                            <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                        </label>
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default StatusFilter;
