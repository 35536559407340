import { useState, useEffect } from 'react';
import constants, { PERFORMANCE_REVIEW_STATUS } from "../../../../constants";
import { uniqBy } from 'lodash';
import { getStorage, handleSuccess, setStorage } from 'utils';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { cancelReview, finalizeForm } from 'repositories/performance-review-repository';
import toast from 'react-hot-toast';


const pageSize = 12;

const defaultDate = {
    from: moment().startOf('year').toDate(),
    to: moment().toDate()
}

const parseDates = (dates) => ({
    from: new Date(dates.from),
    to: new Date(dates.to)
});
const usePerformanceListingModule = (usePerformanceList, officeId, isArchive, archieveReview, unArchieveReview, deleteReview) => {
    const { performanceReviewFilter } = constants.performanceReview.cache;
    const cacheFilters = getStorage(performanceReviewFilter);
    const queryClient = useQueryClient();

    const isFilterApply = cacheFilters?.statuses > 0 || cacheFilters?.dates;

    const [performanceList, setPerformanceList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [appliedStatusFilters, setAppliedStatusFilters] = useState(cacheFilters?.statuses || []);
    const [selectedStatus, setStatusFilter] = useState(cacheFilters?.statuses || []);
    const [appliedDatesFilters, setAppliedDatesFilters] = useState(cacheFilters?.dates ? parseDates(cacheFilters?.dates) : defaultDate);
    const [showLoader, setShowLoader] = useState(false);
    const [dates, setdates] = useState(cacheFilters?.dates ? parseDates(cacheFilters?.dates) : defaultDate);

    const datesMatch = (d1, d2) => {
        return d1.from.getFullYear() === d2.from.getFullYear() &&
            d1.from.getMonth() === d2.from.getMonth() &&
            d1.from.getDate() === d2.from.getDate() &&
            d1.to.getFullYear() === d2.to.getFullYear() &&
            d1.to.getMonth() === d2.to.getMonth() &&
            d1.to.getDate() === d2.to.getDate();
    };

    const { isLoading, data, refetch } = usePerformanceList(
        pageNumber,
        pageSize,
        officeId,
        appliedDatesFilters,
        appliedStatusFilters,
        isArchive
    );

    const { mutate: archieveReviewMutate, isLoading: archieveLoading } = useMutation((formId) => archieveReview(formId));

    const { mutate: unArchieveReviewMutate, isLoading: unarchiveLoading } = useMutation((formId) => unArchieveReview(formId));

    const { mutate: deleteReviewMutate, isLoading: deleteLoading } = useMutation((formId) => deleteReview(formId));

    const { mutate: cancelReviewMutate, isLoading: cancelLoading } = useMutation((formId) => cancelReview(formId));


    useEffect(() => {
        if (pageNumber === 1) {
            if (!isLoading && data && data.items) {
                setPerformanceList(uniqBy([...data.items], "id"));
                setTotalItems(data.pagination.totalItems);
            }
        }
        else if (!isLoading && data && data.items) {
            setPerformanceList(prev => uniqBy([...prev, ...data.items], "id"));
            setTotalItems(data.pagination.totalItems);
        }
    }, [isLoading, data]);

    useEffect(() => {
        setPageNumber(1);
        refetch();
    }, [isArchive, refetch]);

    const updateDatesValues = (e, type) => {
        setdates(prevDates => ({
            ...prevDates,
            [type]: e
        }))
    }


    const resetFilter = () => {
        setStatusFilter([]);
        setdates(defaultDate);
        setAppliedStatusFilters([]);
        setAppliedDatesFilters(defaultDate);
        setStorage(performanceReviewFilter, {
            statuses: [],
            dates: null
        });
        setPageNumber(1);
    };

    const handleApplyFilters = () => {
        const isMatch = datesMatch(dates, defaultDate);
        setAppliedStatusFilters(selectedStatus);
        setAppliedDatesFilters(dates);
        setStorage(performanceReviewFilter, {
            statuses: selectedStatus,
            dates: isMatch ? null : dates,
        });
        setPageNumber(1);
    };

    const updateStateAfterAnyChange = (formId, res) => {
        setPerformanceList((preReviews) => preReviews.filter(review => review.id !== formId));
        handleSuccess(res.message);
        refetch();
    }

    const handleArchive = async (formId) => {
        archieveReviewMutate(formId, {
            onSuccess: (res) => {
                updateStateAfterAnyChange(formId, res);
            }
        })
    };

    const handleDelete = async (formId) => {
        deleteReviewMutate(formId, {
            onSuccess: (res) => {
                updateStateAfterAnyChange(formId, res);
            }
        })
    };

    const handleUnArchieve = async (formId) => {
        unArchieveReviewMutate(formId, {
            onSuccess: (res) => {
                updateStateAfterAnyChange(formId, res);
            }
        });
    }

    const handleCancel = async (formId) => {
        const currtDate = new Date().toISOString();
        const status = PERFORMANCE_REVIEW_STATUS.find(s => s.title === 'cancelled');
        cancelReviewMutate(formId, {
            onSuccess: (res) => {
                setPerformanceList((preReviews) => preReviews.map(review => review.id === formId ? { ...review, ...{ status: status?.id, cancelledOn: currtDate } } : review));
                handleSuccess(res.message);
                refetch();
            }
        })
    }

    const handleFinalize = async (review) => {
        try {
            setShowLoader(true);
            const params = {
                formId: review?.id,
            };
            let res = await finalizeForm(params);
            toast.success(res.message);
            queryClient.invalidateQueries(["performance-review"]);
            refetch();
        } catch (err) {
            toast.error(err.message);
        } finally {
            setShowLoader(false);
        }
    }

    const isAnyLoading = isLoading || archieveLoading || unarchiveLoading || deleteLoading || cancelLoading || showLoader;

    return {
        data: {
            performanceList,
            pageNumber,
            totalItems,
            dates,
            isFilterApply,
            isLoading: isAnyLoading
        },
        methods: {
            setPageNumber,
            updateDatesValues,
            setStatusFilter,
            selectedStatus,
            handleApplyFilters,
            resetFilter,
            handleArchive,
            handleUnArchieve,
            handleDelete,
            handleCancel,
            handleFinalize
        }
    };
};

export default usePerformanceListingModule;