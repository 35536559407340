import React, { useEffect, useState } from 'react'
import { Card } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom/cjs/react-router-dom";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import Page from "components/Page";
import styles from "../Appointments.module.scss"
import { useSelector } from 'react-redux';
import { useDoctorOffices } from 'repositories/doctor-repository';
import DefaultImage from '../../../../assets/images/staff-default.svg'
import { uniqBy } from 'lodash';
import Empty from 'components/Empty';
import Loader from 'components/Loader';
import constants from "../../../../constants";
import { encodeId } from 'utils';

let pageSize = 10;
const animationVariants = {
	hidden: {},
	show: {
		transition: {
			staggerChildren: 0.05,
		},
	},
};
const ManageSchedule = ({ t }) => {

	const profile = useSelector(state => state.userProfile.profile);
	const [doctorOffices, setDoctorOffices] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const { data, isLoading: DoctorOfficeLoading } = useDoctorOffices(profile?.id, pageNumber, pageSize);

	const history = useHistory();
	const goBack = () => {
		history.push(
			'/appointments'
		);
	};

	useEffect(() => {
		if (pageNumber === 1) {
			if (!DoctorOfficeLoading && data && data.items) {
				setDoctorOffices(uniqBy([...data?.items], "id"));
				setTotalItems(data?.totalItems);
			}
		}
		else if (!DoctorOfficeLoading && data && data.items) {
			setDoctorOffices(prev => uniqBy([...prev, ...data?.items], "id"));
			setTotalItems(data?.totalItems);
		}
	}, [DoctorOfficeLoading]);

	return (
		<Page onBack={goBack} className={styles["main-page"]}>
			{DoctorOfficeLoading && <Loader />}
			<div className={styles['manage-schedule-heading']}>
				<div>
					<p className={styles["mobile-text-size"] + " " + "mb-0"}>{t('patientAppointmentMembers.manageSchedule')}</p>
					<span className={styles['select-office']}>{t('patientAppointmentMembers.selectOffice')}</span>
				</div>
			</div>
			<div className={styles['']} >
				{doctorOffices?.length === 0 ? (<div className={styles["not_found"]}>
					<Empty Message={t('taskManagement.taskNotFound')} />
				</div>) :
					<motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
						<InfiniteScroll
							className="w-100"
							dataLength={doctorOffices?.length}
							hasMore={doctorOffices?.length < totalItems}
							next={() => setPageNumber((v) => v + 1)}
						>
							<div className={styles['card-con']}>
								{doctorOffices?.map((office) => (
									<Link key={office?.id} to={constants.routes.appointment.officeSelection.replace(":officeId", encodeId(office?.id))}>
										<div className={styles["urban-dental-card"]}>
											<div className={styles["urban-dental-logo"]}>
												<img heigth={100} width={100} src={office?.officeLogo || DefaultImage} alt="urban dental logo" />
											</div>
											<div className={styles['urban-dental-text']}>
												{office?.name}
											</div>
										</div>
									</Link>
								))}
							</div>
						</InfiniteScroll>
					</motion.div>
				}
			</div>
		</Page>
	);
}
export default withTranslation()(ManageSchedule);
