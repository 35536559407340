import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Text from 'components/Text';
import Button from 'components/Button';
import { withTranslation } from 'react-i18next';
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../Notes.module.scss"

const ConfirmationPopup = ({ t, isModalOpen, closeModal, handleConfirm, handleConfirmCheck, handleCancel }) => {
    return (
        <Modal
            isOpen={isModalOpen}
            toggle={closeModal}
            className="modal-lg modal-dialog-centered"
            modalClassName="custom-modal"
        >
            <span className="close-btn" onClick={closeModal}>
                <img src={crossIcon} alt="close" />
            </span>
            <ModalBody>
                <div className={styles.alert_popup_content_wrapper}>
                    <h2 className="page-title">{t('notes.confirm')}</h2>
                    <Text size="16px" weight="300" color="#535B5F" marginTop="30px" lineHeight="24px">{t('notes.transferMessage')}</Text>
                    <div className="ch-checkbox c-field all-event-checkbox mt-4">
                        <label>
                            <input type="checkbox" onChange={(e) => handleConfirmCheck(e)} />
                            <span>{t('notes.doNotShowMessage')}</span>
                        </label>
                    </div>
                    <div className={styles.main_btn_container}>
                        <Button onClick={handleConfirm}>{t('notes.confirm')}</Button>
                        <Button borderedSecondaryNew onClick={handleCancel}>
                            <span>{t('cancel')}</span>
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
export default withTranslation()(ConfirmationPopup);