import React, { Fragment, useEffect } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import Text from "components/Text";
import "../../TeamConversation/components/Modals/ChatModal.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import PerformanceReviewSearchBar from "accountOwner/pages/PerformanceReview/components/PerformanceReviewSearchBar";
import userDefaultImage from "../../../../assets/images/staff-default-rounded.png";
import styles from "../PatientConversation.module.scss";
import { LoaderIcon } from "react-hot-toast";
import NewConversationToast from "./NewConversationToast";
import OutsideClickHandler from "react-outside-click-handler";
import Loader from "components/Loader";

function NewConversation(props) {
  const {
    t,
    stateData,
    selectedOfficesLabel,
    selectedOption,
    handleRequestStatusFilter,
    setFilteredOfficesForNew,
    setSelectedOption,
    isNewConversationOfficePopupOpen,
    setIsNewConversationOfficePopupOpen,
    resetOfficePagination,
  } = props;
  const { state, otherMethods } = stateData;
  const {
    patientList,
    searchPatient,
    hasMorePatientAppoinments,
    isPatientListLoading,
    hasMoreOffices,
    offices,
    selectedPatient,
    currentPatientPage,
  } = state;
  const {
    handleSelectPatient,
    closeStepOneModal,
    handleStepOne,
    loadMorePatientAppoinments,
    searchPatientHandler,
    loadMoreOffices,
  } = otherMethods;

  useEffect(() => {
    return () => {
      setFilteredOfficesForNew([]);
      setSelectedOption([]);
      resetOfficePagination();
      searchPatientHandler("");
    };
  }, []);

  const handler = (of) => {
    resetOfficePagination();
    handleRequestStatusFilter(of, offices);
  };
  return (
    <Fragment>
      <Modal
        isOpen={true}
        toggle={closeStepOneModal}
        className="modal-dialog-centered modal-width-660 new-grop-modal"
        modalClassName="custom-modal"
      >
        {isPatientListLoading && <Loader />}
        <span className="close-btn" onClick={() => closeStepOneModal(true)}>
          <img src={require("assets/images/cross.svg").default} alt="close" />
        </span>
        <ModalBody>
          <Text size="25px" marginBottom="10px" weight="500" color="#111b45">
            {t("messenger.newPatientChat")}
          </Text>
          <NewConversationToast text={t("messenger.newConversationToast")} />
          <div className={styles.searchbar_dropdown_wrapper}>
            <PerformanceReviewSearchBar
              searchValue={searchPatient}
              handleSearchTerm={(ev) => searchPatientHandler(ev.target.value)}
              placeholderText="Search by name"
              isFromPatientConversation={true}
            />

            <OutsideClickHandler
              onOutsideClick={() => setIsNewConversationOfficePopupOpen(false)}
            >
              <Dropdown
                isOpen={isNewConversationOfficePopupOpen}
                toggle={() => {}}
                className="cursor-pointer "
              >
                <DropdownToggle
                  caret={false}
                  onClick={() =>
                    setIsNewConversationOfficePopupOpen(
                      !isNewConversationOfficePopupOpen
                    )
                  }
                  className="dropdown-btn dropdown-new"
                  tag="div"
                >
                  <span className="dropdown-name-toggle">
                    {selectedOfficesLabel(selectedOption)}
                    {selectedOption?.some((option) => option.value !== 0) && (
                      <span className="notification-dot-new">•</span>
                    )}
                  </span>

                  <img
                    src={require("assets/images/chat-down-arrow.svg").default}
                    className="caret-img"
                    alt="caret"
                  />
                </DropdownToggle>
                <DropdownMenu right id="offices-list" className="menu-new">
                  <InfiniteScroll
                    dataLength={offices?.length}
                    hasMore={hasMoreOffices}
                    next={loadMoreOffices}
                    scrollableTarget="offices-list"
                    loader={
                      <DropdownItem tag="div">
                        <LoaderIcon style={{ margin: "0 auto" }} />
                      </DropdownItem>
                    }
                    scrollThreshold={0.5}
                  >
                    {offices.map((of) => (
                      <DropdownItem key={of.value} tag="div" title={of.label}>
                        <div className="ch-checkbox">
                          <label className="mb-0">
                            <input
                              value={of.value}
                              name={of.name}
                              checked={selectedOption?.some(
                                (item) => item.value === of.value
                              )}
                              onChange={() => handler(of)}
                              type="checkbox"
                            />
                            <span> {of.label} </span>
                          </label>
                        </div>
                      </DropdownItem>
                    ))}
                  </InfiniteScroll>
                </DropdownMenu>
              </Dropdown>
            </OutsideClickHandler>
          </div>
          {patientList && (
            <InfiniteScroll
              dataLength={patientList?.length}
              hasMore={hasMorePatientAppoinments}
              next={loadMorePatientAppoinments}
              scrollableTarget="scrollableDiv"
            >
              <ul
                className={"modal-employee-list group-list"}
                id="scrollableDiv"
              >
                {isPatientListLoading &&
                patientList?.length > 0 &&
                currentPatientPage === 1 ? (
                  <div style={{ height: "242px" }} />
                ) : (
                  patientList?.map((item, idx) => {
                    const { office, patient } = item;
                    return (
                      <li
                        key={`${office.id}-${patient.id}-${idx}`}
                        className="timesheet-report-radio-btn"
                      >
                        <div className="ch-radio">
                          <label
                            onClick={(e) => handleSelectPatient(e, item)}
                            className="pt-1 pb-1"
                          >
                            <input type="radio" name="patient" />
                            <span className="d-flex">
                              <img
                                src={patient.profilePic || userDefaultImage}
                                width={40}
                                height={40}
                                alt="profile"
                                style={{ borderRadius: "5px" }}
                              />
                              <div
                                className={styles.patient_office_email_wrapper}
                              >
                                <Text
                                  size="14px"
                                  weight="600"
                                  color="#102C42"
                                  lineHeight="14px"
                                >
                                  {`${patient.firstName} ${patient.lastName}`}{" "}
                                </Text>
                                <Text
                                  size="12px"
                                  weight="400"
                                  color="#87928D"
                                  lineHeight="12px"
                                >
                                  {office?.name || ""}
                                </Text>
                                <Text
                                  size="12px"
                                  weight="400"
                                  color="#87928D"
                                  lineHeight="12px"
                                >
                                  {`${patient?.emailId || ""}, ${
                                    patient?.contactNumber || ""
                                  }`}
                                </Text>
                                <small></small>
                              </div>
                            </span>
                          </label>
                        </div>
                      </li>
                    );
                  })
                )}
              </ul>
            </InfiniteScroll>
          )}
          {!patientList?.length ? (
            <div className="empty-channel-list text-center col-md-7 mx-auto py-5">
              <img
                src={require("assets/images/empty-icon.svg").default}
                alt="no-staff"
              />
              <h3> {t("messenger.noUserFound")}</h3>
            </div>
          ) : (
            <div className="d-sm-flex btn-box">
              <button
                type="button"
                disabled={!selectedPatient}
                onClick={() => handleStepOne(true)}
                className={
                  "button button-round button-shadow  w-sm-100  mr-sm-3 mb-2"
                }
              >
                {t("messenger.startChat")}
              </button>

              <button
                type="button"
                onClick={closeStepOneModal}
                className="button mb-md-2 btn-mobile-link button-round button-border button-dark"
              >
                {t("cancel")}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default withTranslation()(NewConversation);
