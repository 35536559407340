import React, { useEffect, useState } from "react";
import "../../Scheduler/components/SchedulerFilters/SchedulerFilters.scss";
import { useSelector } from "react-redux";
import { useAssignedStaffList, useAssignorList } from "repositories/task-management-repository";
import { useQueryClient } from "react-query";

  function StaffFilter({ setStaffFilter, seletedStaff, t, isStaff=false, accountOwnerId = null }) {
    const queryClient = useQueryClient();
    const { data : staffListData, refetch } = useAssignedStaffList(accountOwnerId, {
        enabled: !isStaff
    });
    const { data : assigneeStaffListData } = useAssignorList({
        enabled: isStaff
    });
    const profile = useSelector((state) => state.userProfile.profile);
    const data = isStaff ? assigneeStaffListData : staffListData;

    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
      queryClient.invalidateQueries(["/task/AssignedStaffList", accountOwnerId]);
      refetch();
  }, [accountOwnerId, refetch])

    useEffect(() => {
        if (seletedStaff?.length === data?.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [seletedStaff, data]);

    const handleChange = (e, item) => {
        const staffs = [...seletedStaff];

        const staffIndex = staffs?.findIndex((v) => +item.id === +v);
        if (e.target.checked) {
            staffs.push(item.id);
        } else {
            if (staffIndex > -1) staffs.splice(staffIndex, 1);
        }
        setStaffFilter(staffs);
    };

    const handleAllChange = (e) => {
      setSelectAll(e.target.checked);
      if (e.target.checked) {
        setStaffFilter(data?.length ? data?.map((item) => item.id) : []);
      } else {
        setStaffFilter([]);
      }
  };

    return (
        <ul className={["filter-list"]}>
            {data?.length > 0 && (
               <ul>
               <li>
                   <div className="ch-checkbox">
                       <label>
                           <input
                               type="checkbox"
                               onChange={handleAllChange}
                               checked={selectAll}
                           />{" "}
                           <span>
                             {t("taskManagement.allStaff")}
                             </span>
                       </label>
                   </div>
               </li>
               <hr className="mr-4" />
           </ul>
            )}
            {data?.length > 0 &&
                data?.filter((d) => profile?.id !== d?.id)?.map((item, key) => (
                        <li key={key}>
                            <div className="ch-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleChange(e, item)}
                                        checked={seletedStaff?.includes(item.id)}
                                    />{" "}
                                    <span> {`${item.firstName} ${item.lastName}`}</span>
                                </label>
                            </div>
                        </li>
                    ))}
        </ul>
    );
}

export default StaffFilter;
