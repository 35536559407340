import { useState } from "react";
import { handleError, sliceText } from "utils";
import constants from "../../constants";

const useConversationHeaderState = (dependencies) => {
  const { t, channel, currentUser, externalTabActive, sdk, setCurrentChannel } =
    dependencies;

  const [isPopOpen, setIsPopOpen] = useState(false);
  const [isConversationStateLoading, setIsConversationStateLoading] =
    useState(false);
  const isConversationClosed =
    channel.customType === constants.chat.patient.close;
  const getChannelData = () => {
    const { name: groupName, coverUrl, memberCount, members, data } = channel;

    let channelName = groupName;
    let officeName = "";
    let officeId = "";
    let id = "";
    let profilePic =
      coverUrl || require("assets/images/staff-default-rounded.png").default;
    let subTitle = externalTabActive
      ? t("messenger.externalChat")
      : t("messenger.internalChat");
    if (data && memberCount === 2) {
      const customMembers = JSON.parse(data)?.members || [];
      const otherCustomUser = customMembers?.filter(
        (mem) => mem.id.toString() !== currentUser.id.toString()
      )?.[0];
      const otherSendbirdMember = members?.filter(
        (mem) => mem.userId !== currentUser.id.toString()
      )?.[0];
      officeName = otherCustomUser?.officeName;
      channelName = externalTabActive
        ? channelName
        : otherSendbirdMember?.nickname;
      profilePic =
        otherSendbirdMember?.plainProfileUrl ||
        require("assets/images/staff-default-rounded.png").default;
      subTitle = !externalTabActive ? officeName : subTitle;
      id = otherCustomUser.id;
      officeId = otherCustomUser.officeId;
    }
    return {
      officeName,
      channelName: sliceText(channelName, 40),
      channelImage: profilePic,
      subTitle,
      id,
      officeId,
    };
  };

  const toggleChat = async (channelUrl, customType) => {
    if (!channelUrl) {
      return;
    }
    setIsConversationStateLoading(true);
    try {
      const getgrpChannel = await sdk.groupChannel.getChannel(channelUrl);
      const updateChannel = await getgrpChannel.updateChannel({
        customType: customType,
      });
      setIsPopOpen(false);
      if (updateChannel) {
        setCurrentChannel(updateChannel);
      }
      return updateChannel;
    } catch (error) {
      handleError(error);
    } finally {
      setIsConversationStateLoading(false);
    }
  };

  const toggleChatOpenState = async () => {
    try {
      if (isConversationClosed) {
        return await toggleChat(channel._url, constants.chat.patient.open);
      }
      return await toggleChat(channel._url, constants.chat.patient.close);
    } catch (error) {
      handleError(error);
    }
  };

  return {
    state: {
      isConversationClosed,
      isPopOpen,
      isConversationStateLoading,
    },
    updateMethods: {},
    otherMethods: {
      getChannelData,
      toggleChatOpenState,
      setIsPopOpen,
    },
  };
};

export default useConversationHeaderState;
