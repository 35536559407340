import React, { Fragment, useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import Page from 'components/Page';
import Text from 'components/Text';
import { Card } from 'reactstrap';
import plusImg from "../../../../../../../assets/images/Group 6.svg"
import Empty from 'components/Empty';
import CustomDropdown from 'components/Dropdown';
import styles from "../../../../Appointments.module.scss"
import AppointmentReminder from 'accountOwner/pages/Appointments/components/AppointmentReminder';
import { addNewAppointmentReminder, addNewGlobalAppointmentReminder, updateAppointmentReminder, updateAppointmentRemindermapping, useAppointmentReminder, useAppointmentSingleReminder, useGlobalRemidnerPlanDetail, useRecallReminder } from 'repositories/patient-appointment-repository';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { calculateReminderHours, decodeId, getStorage, handleSuccess } from 'utils';
import { uniqBy } from 'lodash';
import constants from '../../../../../../../constants';
import { AppointmentsContext } from 'accountOwner/pages/Appointments/AppointmentsContext';
import { useContext } from 'react';
import DeleteReminderModal from 'accountOwner/pages/Appointments/modal/DeleteReminderModal';
import UpdateReminderConfirmModal from 'accountOwner/pages/Appointments/modal/UpdateReminderConfirmModal';
import { useMutation, useQueryClient } from 'react-query';
import Input from 'components/Input';

const pageSize = 100;
const pageNumber = 1;

const KEY_NAME_ONE = 'waiting-reminder';
const KEY_NAME_TWO = 'acceptance-reminder';
const KEY_NAME_THREE = 'complete-reminder';
const KEY_NAME_FOUR = 'add-new-appointment';
const KEY_NAME_FIVE = 'appointment-reminder';
const KEY_NAME_GLOBAL = 'global-appointment-reminder';
const PatientAppointmentReminder = ({ t }) => {
    let { officeId, requestId } = useParams();
    let { patientId } = useParams();
    const queryClient = useQueryClient();
    const isAccept = getStorage('accept')
    const isComplete = getStorage('complete')
    const createPage = getStorage('createPage');
    const createRecall = getStorage("createRecall");
    const appointmentReminder = getStorage("appointmentReminder");
    const isRecallReminderPlan = getStorage("isRecallReminderPlan");
    const profile = useSelector(state => state.userProfile.profile);
    const decodeOfficeId = parseInt(decodeId(officeId));
    const decodeAppointmentId = parseInt(decodeId(requestId));
    const decodePatientId = parseInt(decodeId(patientId));
    const [appointmenteReminderList, setAppointmenRemindertList] = useState([]);
    const [openConfirmModal, setConfirmModal] = useState(false);
    const cachedActiveTab = getStorage(constants.patientScheduling.cache.eventlisting)
    const location = useLocation();
    const { state } = location;
    let KEY_NAME = (() => {
        if (isAccept?.isFromGloabList || createRecall?.createRecall) return KEY_NAME_TWO;
        if (isComplete?.isFromCompletePage) return KEY_NAME_THREE;
        if (createPage?.isFromCreatePage) return KEY_NAME_FOUR;
        if (appointmentReminder?.appointmentReminder && requestId) return KEY_NAME_FIVE;
        if (!requestId && (patientId || (!patientId && !officeId) || (officeId && !patientId))) return KEY_NAME_GLOBAL;
        return KEY_NAME_ONE;
    })();
    const isGlobalReminder = location?.pathname.includes(
        "patient-appointment-reminder"
    );
    const closeModal = () => setConfirmModal(false);

    let reminderPlanType = 2;
    if (patientId && !isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 2;
    }

    if (patientId && isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 5;
    }

    if (officeId && !patientId && !isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 3;
    }

    if (officeId && !patientId && isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 6;
    }

    if (!patientId && !officeId && !isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 4;
    }

    if (!patientId && !officeId && isRecallReminderPlan?.isRecallReminderPlan) {
        reminderPlanType = 7;
    }

    const { data, isLoading, refetch } = useAppointmentReminder(pageNumber, pageSize, decodeOfficeId, profile?.id);
    const { mutate: updateReminderMutate, isLoading: updateReminderLoading } = useMutation((data) => updateAppointmentReminder(data));
    const { mutate: updateAppointmentRemindermappingMutate, isLoading: updateAppointmentRemindermappingMutateLoading } = useMutation((data) => updateAppointmentRemindermapping(data));
    const { mutate: addAppointmentReminderMutate, isLoading: addReminderLoading } = useMutation((data) => addNewAppointmentReminder(data));
    const { data: appointmentRecallDetail } = useRecallReminder(decodeAppointmentId);
    const { mutate: addGlobalAppointmentReminderMutate, isLoading: addGlobalReminderLoading } = useMutation((data) => addNewGlobalAppointmentReminder(data));
    const {
        templateLoading,
        reminderSets,
        templateOptions,
        options,
        setReminderSets,
        handleOnChange,
        handleAddReminderClick,
        history,
        selectedReminderPlan,
        setSelectReminderPlan,
        deleteReminderKeys,
        setDeleteReminderKeys,
        deleteReminderItem,
        errorMsg,
        validateForm,
        setReminderPlan,
        reminderPlan,
        ownerId
    } = useContext(AppointmentsContext);

    const [reminderName, setReminderName] = useState(reminderPlan?.[KEY_NAME]?.title || "");

    const { isLoading: globalReminderLoading, data: globalRemidnerData, refetch: globalReminderFetch } = useGlobalRemidnerPlanDetail(
        decodeOfficeId, ownerId, decodePatientId, reminderPlanType);

    const fetchDefaultGlobalData = async () => {
        queryClient.invalidateQueries(["/AppointmentReminder/ByPlanType", decodeOfficeId, ownerId, decodePatientId, reminderPlanType]);
        const refetchData = await globalReminderFetch();
        if (refetchData?.data?.appointmentReminderPlanSetting?.length) {
            setReminderPlan(prevReminderPlan => ({
                ...prevReminderPlan,
                [KEY_NAME]: {
                    title: refetchData?.data?.title,
                    id: refetchData?.data?.id,
                    reminderPlanType: refetchData?.data?.reminderPlanType,
                    appointmentReminderPlanSetting: [...refetchData?.data?.appointmentReminderPlanSetting]
                }
            }));
            setReminderName(refetchData?.data?.title);
        }
    }

    useEffect(() => {
        if (isGlobalReminder && !globalReminderLoading) {
            fetchDefaultGlobalData()
        }
    }, [globalReminderLoading, globalRemidnerData]);

    useEffect(() => {
        if (!isLoading && data && data.items) {
            setAppointmenRemindertList(prev => uniqBy([...prev, ...data.items], "id"));
        }
    }, [isLoading, data]);

    const RepeatOptions = appointmenteReminderList?.map(val => ({ id: val?.id, name: val?.title }));

    const filterReminderList = appointmenteReminderList?.find(reminder => reminder?.id === selectedReminderPlan?.appointmentReminderPlanId)?.appointmentReminderPlanSetting;

    const defaultReminderList = !filterReminderList ? reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting ? reminderPlan?.[KEY_NAME]?.appointmentReminderPlanSetting : filterReminderList : filterReminderList;

    const reminderAppointmentList = defaultReminderList?.map(val => ({
        selectTemplate: val?.messageTemplateId,
        selectBeforeType: val?.reminderBeforeType,
        reminderBefore: val?.reminderBefore,
        isEmail: val?.isEmail,
        isPushNotification: val?.isPushNotification,
        isSms: val?.isSms,
        id: val?.id
    }))


    useEffect(() => {
        if (defaultReminderList?.length > 0) {
            setReminderSets(prev => ({
                ...prev,
                [KEY_NAME]: [...reminderAppointmentList]
            }));
        }

    }, [selectedReminderPlan, defaultReminderList]);

    const areRemindersEqual = (reminderA, reminderB) => {
        return (
            reminderA.selectTemplate === reminderB.selectTemplate &&
            reminderA.selectBeforeType === reminderB.selectBeforeType &&
            reminderA.reminderBefore === parseInt(reminderB.reminderBefore) &&
            reminderA.isEmail === reminderB.isEmail &&
            reminderA.isPushNotification === reminderB.isPushNotification &&
            reminderA.isSms === reminderB.isSms &&
            reminderA.id === reminderB.id
        );
    };

    const areArraysEqual = (arrA, arrB) => {
        if (arrA.length !== arrB.length) return false;

        for (let i = 0; i < arrA.length; i++) {
            if (!areRemindersEqual(arrA[i], arrB[i])) {
                return false;
            }
        }
        return true;
    };

    const goBack = () => {
        if (requestId) {
            if (createRecall?.createRecall) {
                history.push(constants.routes.appointment.createRecallPatientAppointment.replace(':appointmentId', requestId))
            }
            else if (isAccept?.isFromGloabList) {
                history.push(constants.routes.appointment.acceptPatientAppointment.replace(':appointmentId', requestId))
            }
            else if (isComplete?.isFromCompletePage) {
                history.push(constants.routes.appointment.feedbackConfirmation.replace(':officeId', officeId).replace(':appointmentId', requestId))
            }
            else if (createPage?.isFromCreatePage) {
                history.push(constants.routes.appointment.patientNewAppointment.replace(":officeId?", officeId ? officeId : ""))
            } else if (appointmentReminder?.appointmentReminder && (cachedActiveTab?.activeTab === 3) && requestId) {
                setSelectReminderPlan(null);
                history.push(constants.routes.appointment.recallReminderEdit.replace(":appointmentId", requestId).replace(':officeId', officeId))
            }
            else if (appointmentReminder?.appointmentReminder && requestId) {
                setSelectReminderPlan(null);
                history.push(constants.routes.appointment.reminder.replace(":appointmentId", requestId).replace(':officeId', officeId))
            }
            else {
                history.push({ pathname: constants.routes.appointment.createAppointment.replace(':officeId', officeId).replace(':requestId', requestId), state })
            }
        } else {
            history.goBack();
        }
    }
    const saveData = () => {
        const updatedReminderData = reminderSets?.[KEY_NAME]?.map(reminder => {
            const matchingReminder = reminderAppointmentList?.find(r => r?.id === reminder?.id);
            const updateData = {
                ...reminder,
                messageTemplateId: reminder?.selectTemplate,
                reminderBeforeType: reminder?.selectBeforeType,
                reminderBeforeHours: calculateReminderHours(reminder?.reminderBefore, reminder?.selectBeforeType),
                id: matchingReminder ? reminder?.id : 0
            };
            delete updateData?.selectBeforeType;
            delete updateData?.selectTemplate;
            return updateData;
        });

        const addReminderData = {
            Title: selectedReminderPlan?.title || reminderPlan?.[KEY_NAME]?.title,
            officeId: decodeOfficeId,
            AppointmentReminderPlanId: selectedReminderPlan?.appointmentReminderPlanId || reminderPlan?.[KEY_NAME]?.id,
            AppointmentReminderSetting: updatedReminderData
        };
        return addReminderData;
    }

    const saveReminderData = () => {
        const replaceData = saveData();
        let obj = {
            Title: replaceData?.Title,
            officeId: replaceData?.officeId,
            AppointmentReminderPlanMappings: replaceData?.AppointmentReminderSetting,
            AppointmentReminderPlanId: replaceData?.AppointmentReminderPlanId,
            appointmentId: decodeAppointmentId,
            appointmentRecallId: null,
        }
        if (cachedActiveTab?.activeTab === 3) {
            obj.appointmentId = null;
        }

        if (cachedActiveTab?.activeTab === 3) {
            obj.appointmentRecallId = appointmentRecallDetail?.id
        }
        return obj;
    }

    const saveGlobalReminderData = () => {
        const replaceData = saveData();
        let obj = {
            Title: reminderName,
            officeId: replaceData?.officeId,
            isTempPlan: false,
            ownerId: ownerId,
            AppointmentReminderSetting: replaceData?.AppointmentReminderSetting,
            reminderPlanType: reminderPlanType,
            patientId: decodePatientId,
            AppointmentReminderPlanId: replaceData?.AppointmentReminderPlanId,
            UpdateOldAppointments: true
        }

        return obj;
    }

    const handleUpdateReminderMapping = (data) => {
        updateAppointmentRemindermappingMutate(data, {
            onSuccess: async (res) => {
                handleSuccess(res.message);
                goBack();
            }
        });
    }

    const handleUpdateGlobalReminderMapping = (data) => {
        addGlobalAppointmentReminderMutate(data, {
            onSuccess: async (res) => {
                handleSuccess(res.message);
                history.goBack();
            }
        });
    }

    const handleUpdate = (data) => {
        updateReminderMutate(data, {
            onSuccess: async (res) => {
                if (appointmentReminder?.appointmentReminder) {
                    handleAdd(data);
                } else {
                    handleSuccess(res.message);
                    queryClient.invalidateQueries(["/appointment-reminder/list", pageNumber, pageSize, decodeOfficeId, profile?.id],);
                    const refetchedData = await refetch();
                    const updatedData = refetchedData?.data?.items?.find(item => item.id === data?.AppointmentReminderPlanId);
                    setReminderPlan(prev => ({
                        ...prev,
                        [KEY_NAME]: updatedData
                    }));
                    setReminderSets(prev => ({
                        ...prev,
                        [KEY_NAME]: []
                    }));
                    goBack();
                }
            }
        });
    }

    const handleAdd = (data) => {
        addAppointmentReminderMutate(data, {
            onSuccess: (res) => {
                handleSuccess(res.message);

                setReminderPlan(prev => ({
                    ...prev,
                    [KEY_NAME]: res?.data
                }));

                setReminderSets(prev => ({
                    ...prev,
                    [KEY_NAME]: []
                }));
                setSelectReminderPlan(null);
                goBack();
            }
        });
    }

    const handleReplace = () => {
        if (requestId) {
            const replaceData = saveData();
            replaceData['isTempPlan'] = false;
            if (appointmentReminder?.appointmentReminder) {
                let replaceData1 = saveReminderData();
                let replaceEditData = {
                    ...replaceData1,
                    isTempPlan: false
                }
                replaceEditData.AppointmentReminderSetting = replaceEditData?.AppointmentReminderPlanMappings;
                delete replaceEditData?.AppointmentReminderPlanMappings;
                handleUpdate(replaceEditData, false);
            } else {
                handleUpdate(replaceData, false);
            }
        } else {
            const replaceData = saveGlobalReminderData();
            replaceData['UpdateOldAppointments'] = true;
            handleUpdateGlobalReminderMapping(replaceData)
        }

    }

    const handleWithoutSave = () => {
        if (requestId) {
            const replaceData = saveData();
            replaceData['isTempPlan'] = true;
            if (appointmentReminder?.appointmentReminder) {
                let replaceData1 = saveReminderData();
                let replaceEditData = {
                    ...replaceData1,
                    isTempPlan: true
                }
                replaceEditData.AppointmentReminderSetting = replaceEditData?.AppointmentReminderPlanMappings;
                delete replaceEditData?.AppointmentReminderPlanMappings;
                handleAdd(replaceEditData);
            } else {
                handleAdd(replaceData);
            }
        } else {
            const replaceData = saveGlobalReminderData();
            replaceData['UpdateOldAppointments'] = false;
            handleUpdateGlobalReminderMapping(replaceData)
        }
    }

    const handleSave = () => {
        const isReminderName = false;
        if (requestId) {
            if (validateForm(KEY_NAME, isReminderName)) {
                if (appointmentReminder?.appointmentReminder) {
                    const replaceData = saveReminderData();
                    if (selectedReminderPlan?.appointmentReminderPlanId) {
                        const isEqual = areArraysEqual(reminderAppointmentList, reminderSets?.[KEY_NAME]);
                        if (isEqual) {
                            let replaceEditData = {
                                ...replaceData
                            }
                            replaceEditData.AppointmentReminderSetting = replaceEditData?.AppointmentReminderPlanMappings;
                            delete replaceEditData?.AppointmentReminderPlanMappings;
                            handleAdd(replaceEditData);
                        } else {
                            setConfirmModal(true);
                        }

                    } else {
                        handleUpdateReminderMapping(replaceData)
                    }
                } else {
                    const isEqual = areArraysEqual(reminderAppointmentList, reminderSets?.[KEY_NAME]);

                    if (isEqual) {
                        setReminderPlan(prev => ({
                            ...prev,
                            [KEY_NAME]: {
                                title: selectedReminderPlan?.title || reminderPlan?.[KEY_NAME]?.title,
                                id: selectedReminderPlan?.appointmentReminderPlanId || reminderPlan?.[KEY_NAME]?.id,
                                appointmentReminderPlanSetting: [...defaultReminderList]
                            }
                        }));
                        goBack();
                    } else {
                        setConfirmModal(true);
                    }
                }
            }
        } else {
            if (validateForm(KEY_NAME, isReminderName)) {
                setConfirmModal(true);
            }
        }
    };

    const handleAddRemidnerRedirection = () => {
        history.push({ pathname: constants.routes.appointment.addAppointmentReminderPlan.replace(':officeId', officeId).replace(':requestId', requestId), state });
    }

    return (
        <Page
            onBack={goBack}
        >
            {requestId && <div className={styles["reminder-plan-modal"]}>
                <span className={styles["mobile-text-size"]}>{t('patientAppointmentMembers.selectAppointmentReminderPlan')}</span>
                <div onClick={handleAddRemidnerRedirection} className="w-sm-100 mt-2" >
                    <button className="button button-round button-shadow mr-md-4 w-sm-100">
                        {t('patientAppointmentMembers.addNewReminderPlan')}
                    </button>
                </div>
            </div>}
            <Card className="form-wrapper">
                {(appointmenteReminderList?.length > 0 || isGlobalReminder) ? <Fragment>
                    {requestId ? (<div className={styles["select-appointment-reminder-dropdown"] + " " + "c-field appointment-custom-dropdown"}>
                        <div className={styles["custom-dropdown-only"] + " " + "custom-dropdown-only w-100"}>
                            <Text size="13px" weight="400" color="#79869A">{t('patientAppointmentMembers.selectReminderPlan')}</Text>
                            <CustomDropdown
                                options={RepeatOptions}
                                selectedOption={selectedReminderPlan?.appointmentReminderPlanId}
                                selectOption={(id, name) => {
                                    setSelectReminderPlan({ appointmentReminderPlanId: id, title: name });
                                }}
                                defaultValue={reminderPlan?.[KEY_NAME]?.title || t('patientAppointmentMembers.selectReminderPlan')}
                            />
                        </div>
                        <div className={styles['reject-yellow-warning-box']}>
                            <Text size="10px" weight="500" color="#2F3245">{t('patientAppointmentMembers.ReminderPlanText')}</Text>
                        </div>
                    </div>) :
                        (<div className={styles["select-appointment-reminder-dropdown"] + " " + "c-field appointment-custom-dropdown"}>
                            <div className={styles["custom-dropdown-only"] + " " + "custom-dropdown-only w-100"}>
                                <Text size="13px" weight="400" color="#79869A">Specify Reminder Name</Text>
                                <Input
                                    className={styles["appointment-input-box"]}
                                    Type="text"
                                    Placeholder="Add Note"
                                    Value={reminderName}
                                    Name={"BusinessLegalName"}
                                    HandleChange={(e) => setReminderName(e.target.value)}
                                />
                            </div>
                        </div>)
                    }
                    {((reminderSets?.[KEY_NAME]?.length > 0) || selectedReminderPlan || isGlobalReminder) ?
                        <div className={styles['select-appointment-reminder-plan']}>
                            {!templateLoading && reminderSets?.[KEY_NAME]?.map((appointmentReminder, index) => (
                                <div className={styles['appointment-reminder-modal-card-Wrapper']} key={appointmentReminder?.id}>
                                    <AppointmentReminder
                                        appointmentReminder={appointmentReminder}
                                        setReminderSets={setReminderSets}
                                        handleOnChange={handleOnChange}
                                        templateOptions={templateOptions}
                                        options={options}
                                        isCreateTemplateVisable={false}
                                        officeId={officeId}
                                        requestId={requestId}
                                        setDeleteReminderKeys={setDeleteReminderKeys}
                                        keyName={KEY_NAME}
                                        index={index}
                                    />
                                </div>
                            ))}
                            <button className={styles['illness-type-save-cancel-btn'] + " " + styles["add-new-member-btn"]} onClick={() => handleAddReminderClick(KEY_NAME)}>
                                <span>
                                    <img src={plusImg} alt="ds" className="mr-1" />
                                </span>
                                {t('patientAppointmentMembers.addNewReminder')}
                            </button>
                            {errorMsg && <span className="error-msg mt-4">{errorMsg}</span>}
                            <div className={styles['common-appointment-btn-container']}>
                                <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100" onClick={handleSave}>{t('patientAppointmentMembers.apply')}</button>
                                <button className="button button-round button-border button-dark btn-mobile-link" onClick={goBack}>{t('patientAppointmentMembers.cancel')}</button>
                            </div>
                        </div> :
                        <div className="empty-block">
                            <h4>{t('patientAppointmentMembers.selectReminderPlanMsg')}</h4>
                        </div>
                    }
                </Fragment>
                    :
                    <div className={styles["not_found"]}>
                        <Empty Message={t('patientAppointmentMembers.remiderPlanNotFound')} />
                    </div>
                }
            </Card>
            {!!deleteReminderKeys?.[KEY_NAME] && <DeleteReminderModal isModalOpen={deleteReminderKeys?.[KEY_NAME]} keyName={KEY_NAME} closeModal={() =>
                setDeleteReminderKeys(prev => ({
                    ...prev,
                    [KEY_NAME]: null
                }))}
                deleteReminderItem={deleteReminderItem}
                title={t('patientAppointmentMembers.confirm')}
            />}
            {openConfirmModal && <UpdateReminderConfirmModal isModalOpen={openConfirmModal} closeModal={closeModal} showLoader={updateReminderLoading || addReminderLoading || addGlobalReminderLoading} handleReplace={handleReplace} handleWithoutSave={handleWithoutSave} templateName={selectedReminderPlan?.title || reminderPlan?.[KEY_NAME]?.title} appointmentId={requestId} />}
        </Page>
    )
}
export default withTranslation()(PatientAppointmentReminder);
