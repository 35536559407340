import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // For prop validation
import Text from "components/Text";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Card from "components/Card";
import dotsIcon from "../../../../assets/images/dots-icon.svg";
import alertIcon from "../../../../assets/images/redAlert.svg";
import { withTranslation } from "react-i18next";
import styles from "../Dashboard.module.scss";
import CommentIcon from "../../../../assets/images/comment_icon.svg";
import moment from "moment";
import RejectionModal from "accountOwner/pages/TaskManagement/Modals/RejectionModal";
import CompletionModal from "accountOwner/pages/TaskManagement/Modals/CompleteModal";
import useCardModule from "accountOwner/pages/TaskManagement/hooks/useCardModule";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StaffTaskCard = ({
  onActionClick,
  t,
  myTask,
  handleRedirect,
  handleActiontask,
  handleArchiveApi,
  isArchive,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOverdue, setIsOverdue] = useState(true);
  const history = useHistory();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const {
    data: { isConfirmModalOpen, isCompletionModalOpen },
    methods: { handleToggle, handleCardClick, handleCompletionModal },
  } = useCardModule();

  function checkOverdueTasks() {
    const now = moment.utc();
    const dueDate = moment.utc(myTask?.dueDate);
    const Overdue = dueDate?.isBefore(now);

    let status = getStatus(myTask?.overallStatus);
    if (Overdue) {
      if (status === "Pending" || status === "Accepted" || status === "Started")
        setIsOverdue(true);
    } else {
      setIsOverdue(false);
    }
  }

  const getStatus = (overallStatus) => {
    const statusMap = {
      1: "In Progress",
      2: "Pending",
      3: "Accepted",
      4: "Started",
      5: "Completed",
      6: "Rejected",
      7: "Cancelled",
    };
    return statusMap[overallStatus] || "";
  };

  const getPriority = (priority) => {
    const priorityMap = {
      1: "HIGH",
      2: "MEDIUM",
      3: "LOW",
    };
    return priorityMap[priority] || "All";
  };

  const getPriorityClass = (priority) => {
    switch (priority) {
      case "HIGH":
        return {
          wrapperClass: styles["high-priority-wrapper"],
          dotClass: styles["high-priority-dot"],
          textClass: styles["high-priority-text"],
        };
      case "MEDIUM":
        return {
          wrapperClass: styles["medium-priority-wrapper"],
          dotClass: styles["medium-priority-dot"],
          textClass: styles["medium-priority-text"],
        };
      case "LOW":
        return {
          wrapperClass: styles["low-priority-wrapper"],
          dotClass: styles["low-priority-dot"],
          textClass: styles["low-priority-text"],
        };
      default:
        return {
          wrapperClass: "",
          dotClass: "",
          textClass: "",
        };
    }
  };

  const getButtonClass = (status) => {
    const statusClasses = {
      Pending: styles.Pending,
      Cancelled: styles.Cancelled,
      Started: styles.Started,
      Accepted: styles.Accepted,
      Rejected: styles.Rejected,
      "In Progress": styles.InProgress,
      Completed: styles.Completed,
    };

    return `${styles.status_btn} ${statusClasses[status] || ""}`;
  };

  useEffect(() => {
    checkOverdueTasks();
  }, [getPriority]);

  const { wrapperClass, dotClass, textClass } = getPriorityClass(
    getPriority(myTask?.priority)
  );

  return (
    <>
      <Card
        className={styles.task_card}
        onClick={(e) => handleCardClick(e, myTask, history, true)}
      >
        {isOverdue && (
          <div className={styles["alert_text_wrapper"]}>
            <img src={alertIcon} alt="close" />
            <Text size="11px" weight="400" color="#EE4F4F">
              {t("taskManagement.taskOverdue")}
            </Text>
          </div>
        )}
        <div className={styles.dropdown_data_wrapper}>
          <div className={styles["status_priority_date_wrapper"]}>
            <button className={getButtonClass(getStatus(myTask?.status))}>
              {getStatus(myTask?.status)}
            </button>
            <div
              className={`${styles["circle_priority_text_wrapper"]} ${wrapperClass}`}
            >
              <span className={`${styles["circular_dot"]} ${dotClass}`}></span>
              <p className={`${styles["priority_text"]} ${textClass}`}>
                {getPriority(myTask?.priority)}
              </p>
            </div>
            <div className={styles["date"]}>
              {moment.utc(myTask?.createdAt).format("MMM D, YYYY")}
            </div>
          </div>

          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="interactive-element"
          >
            <DropdownToggle
              caret={false}
              tag="div"
              className="dropdown-btn px-3"
            >
              <img
                src={dotsIcon}
                alt="More Options"
                className={styles.min_width}
              />
            </DropdownToggle>
            <DropdownMenu right className={styles.custom_dropdown_wrapper}>
              {getStatus(myTask?.status) === "Pending" ? (
                <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                  <p
                    className={styles["dropdown_link"] + " " + "mb-0"}
                    onClick={() => handleActiontask(myTask?.id, "Accept")}
                  >
                    {t("taskManagement.accept")}
                  </p>
                </DropdownItem>
              ) : (
                ""
              )}
              {getStatus(myTask?.status) === "Pending" ? (
                <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                  <p
                    className={styles["dropdown_link"] + " " + "mb-0"}
                    onClick={() => handleToggle(myTask?.id)}
                  >
                    {t("taskManagement.reject")}
                  </p>
                </DropdownItem>
              ) : (
                ""
              )}
              {getStatus(myTask?.status) === "Accepted" ? (
                <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                  <p
                    className={styles["dropdown_link"] + " " + "mb-0"}
                    onClick={() => handleActiontask(myTask?.id, "Start")}
                  >
                    {t("taskManagement.start")}
                  </p>
                </DropdownItem>
              ) : (
                ""
              )}
              {getStatus(myTask?.status) === "Started" ? (
                <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                  <p
                    className={styles["dropdown_link"] + " " + "mb-0"}
                    onClick={handleCompletionModal}
                  >
                    {t("taskManagement.markComplete")}
                  </p>
                </DropdownItem>
              ) : (
                ""
              )}
              {!isArchive ? (
                <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                  <p
                    className={styles["dropdown_link"] + " " + "m-0"}
                    onClick={() => handleArchiveApi(myTask?.id, "Archive")}
                  >
                    {t("taskManagement.archive")}
                  </p>
                </DropdownItem>
              ) : (
                <DropdownItem className={styles["dropdown_list"] + " " + "p-0"}>
                  <p
                    className={styles["dropdown_link"] + " " + "m-0"}
                    onClick={() => handleArchiveApi(myTask?.id, "Unarchive")}
                  >
                    {t("taskManagement.unarchive")}
                  </p>
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
        <p className={styles["card_main_heading"]}>{myTask?.title}</p>
        <div className={styles.office_details_wrapper}>
          <div>
            <Text size="12px" weight="400" color="#6F7788" lineHeight="16px">
              {t("taskManagement.officeName")}
            </Text>
            <Text size="12px" weight="600" color="#102C42" lineHeight="18px">
              {myTask?.office?.name}
            </Text>
          </div>
          <div>
            <Text size="12px" weight="400" color="#6F7788" lineHeight="16px">
              {t("taskManagement.assignedBy")}
            </Text>
            <Text size="12px" weight="600" color="#102C42" lineHeight="18px">
              {`${myTask?.assignor?.firstName} ${myTask?.assignor?.lastName}`}
            </Text>
          </div>
        </div>
        <hr></hr>
        <div className={styles["comment_date_wrapper"]}>
          <div>
            <img
              className={styles.flex_inline_block + " " + "mr-1"}
              src={CommentIcon}
              alt="download-icon"
            />
            <span className={styles["comment_text"]}>
              {myTask?.totalComments} {t("taskManagement.comments")}
            </span>
          </div>
          <div className={styles["comment_text"]}>
            {t("taskManagement.due")}{" "}
            {moment.utc(myTask?.dueDate).format("MMM D, YYYY")}
          </div>
        </div>
      </Card>
      {isConfirmModalOpen && (
        <RejectionModal
          isRejectionModalOpen={isConfirmModalOpen}
          setIsRejectionModalOpen={handleToggle}
          confirmReject={handleActiontask}
          isCancel={handleToggle}
          AssigneeId={myTask?.id}
        />
      )}
      {isCompletionModalOpen && (
        <CompletionModal
          isCompletionModalOpen={isCompletionModalOpen}
          handleCompletionModal={handleCompletionModal}
          confirmComletion={handleActiontask}
          AssigneeId={myTask?.id}
        />
      )}
    </>
  );
};
StaffTaskCard.propTypes = {
  profilePic: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
};

StaffTaskCard.defaultProps = {
  onActionClick: () => {},
};
export default withTranslation()(StaffTaskCard);
