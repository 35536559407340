import React, { useState } from "react";
import PerformanceReviewCard from "../components/PerformanceReviewCard";
import styles from "./../PerformanceReview.module.scss";
import Sidebar from "../components/Sidebar";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { cacheSideBarActive } from "utils";
import usePerformanceListingModule from "../hooks/usePerformanceListingModule";
import { archieveByRespondent, deleteByRespondent, unArchieveByRespondent, usePerformanceByAdmin } from "repositories/performance-review-repository";
import Loader from "components/Loader";
import Empty from "components/Empty";
import { withTranslation } from "react-i18next";

const animationVariants = {
	hidden: {},
	show: {
		transition: {
			staggerChildren: 0.05,
		},
	},
};

function InitiatedByAdmin({ t, officeId, isArchive, isMyPerformanceReview }) {
	const [isSidebarActive, setSidebarActive] = useState(cacheSideBarActive());


	const handleSidebarToggle = () => {
		setSidebarActive(!isSidebarActive);
		localStorage.setItem("isSidebarActive", !isSidebarActive);
	};

	const {
		data: {
			totalItems,
			performanceList,
			isLoading,
			dates,
			isFilterApply,
		},
		methods: {
			setPageNumber,
			updateDatesValues,
			setStatusFilter,
			selectedStatus,
			handleApplyFilters,
			resetFilter,
			handleArchive,
			handleUnArchieve,
			handleDelete,
			handleCancel
		}
	} = usePerformanceListingModule(
		usePerformanceByAdmin,
		officeId,
		isArchive,
		archieveByRespondent,
		unArchieveByRespondent,
		deleteByRespondent,
	);

	let items = (
		<div className={!isSidebarActive ? styles.flex_container : styles.flex_container_active}>
			{performanceList.length > 0 && performanceList.map((item, index) => (
				<PerformanceReviewCard
					className={styles.employement_proof_card}
					t={t}
					key={index}
					review={item}
					isArchive={isArchive}
					handleArchive={handleArchive}
					handleUnArchieve={handleUnArchieve}
					handleDelete={handleDelete}
					isLoading={isLoading}
					handleCancel={handleCancel}
					isMyPerformanceReview={isMyPerformanceReview}
				/>
			))}
		</div>
	);

	return (
		<div className="scheduler-tabs-main-wrapper w-100">
			{isLoading && <Loader />}
			<Sidebar
				isSidebarActive={isSidebarActive}
				handleSidebarToggle={handleSidebarToggle}
				handleApplyFilters={handleApplyFilters}
				resetFilter={resetFilter}
				dates={dates}
				updateDatesValues={updateDatesValues}
				setStatusFilter={setStatusFilter}
				selectedStatus={selectedStatus}
				isFilterApply={isFilterApply}
				t={t}
			/>
			{performanceList.length === 0 ? (
				<div className="div_center">
					<Empty Message={t('performanceReview.noPerformanceReviewAvailable')} />
				</div>
			) :
				<motion.div variants={animationVariants} initial="hidden" animate="show" className="w-100">
					<InfiniteScroll
						className="w-100"
						dataLength={performanceList.length}
						hasMore={performanceList.length < totalItems}
						next={() => setPageNumber((v) => v + 1)}
					>
						{items}
					</InfiniteScroll>
				</motion.div>}
		</div>
	);
}

export default withTranslation()(InitiatedByAdmin);

