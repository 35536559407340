export const Config = {
  serviceUrls: {
    officeBaseUrl: process.env.REACT_APP_OFFICE_BASE_URL,
    userBaseUrl: process.env.REACT_APP_USER_BASE_URL,
    utilityBaseUrl: process.env.REACT_APP_UTILITY_BASE_URL,
    subscriptionBaseUrl: process.env.REACT_APP_SUBSCRIPTION_BASE_URL,
    holidayBaseUrl: process.env.REACT_APP_HOLIDAY_BASE_URL,
    cmsBaseUrl: process.env.REACT_APP_CMS_BASE_URL,
    leaveBaseUrl: process.env.REACT_APP_LEAVE_BASE_URL,
    timesheetBaseUrl: process.env.REACT_APP_TIMESHEET_BASE_URL,
    patientSchedulingBaseUrl: process.env.REACT_APP_PATIENT_SCHEDULING_BASE_URL,
    contractBaseUrl: process.env.REACT_APP_CONTRACT_BASE_URL,
    performanceReviewBaseUrl: process.env.REACT_APP_PERFORMANCE_REVIEW_BASE_URL,
    schedulerBaseUrl: process.env.REACT_APP_SCHEDULER_BASE_URL,
    notesBaseUrl: process.env.REACT_APP_NOTES_BASE_URL,
    taskManagementBaseUrl: process.env.REACT_APP_TASK_MANAGEMENT_BASE_URL,
    notificationsBaseUrl: process.env.REACT_APP_NOTIFICATIONS_BASE_URL,
    supportHelpdeskBaseUrl: process.env.REACT_APP_SUPPORT_HELPDESK_BASE_URL,
    sendBirdApiUrl: process.env.REACT_APP_SENDBIRD_REQUEST_URL,
    taxManagementBaseUrl: process.env.REACT_APP_TAX_MANAGEMENT_BASE_URL,
    vendorManagementBaseUrl: process.env.REACT_APP_VENDOR_MANAGEMENT_BASE_URL,
    customerOrderBaseUrl: process.env.REACT_APP_CUSTOMER_ORDER_BASE_URL,
    promocodesBaseUrl: process.env.REACT_APP_PROMOCODES_BASE_URL,
    promotionBaseUrl: process.env.REACT_APP_PROMOTION_BASE_URL,
    noticesBaseUrl: process.env.REACT_APP_NOTICES_BASE_URL,
    personnelBaseUrl: process.env.REACT_APP_PERSONNEL_BASE_URL,
    employmentProofBaseUrl: process.env.REACT_APP_EMPLOYMENT_PROOF_BASE_URL
  },
};
