import Card from "components/Card";
import React from "react";
import { withTranslation } from "react-i18next";
import styles from "../TaskManagement.module.scss";
import Text from "components/Text";
import { calculateCompleteionTime } from "utils";
import useCardModule from "../hooks/useCardModule";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TaskPerformanceCard = ({
  t,
  performace
}) => {
  const history = useHistory();
  const {
    methods: {
      handlePerformanceCardClick,
    }
  } = useCardModule()

  const labels = [
    { label: t("taskManagement.completedOn"), value: performace?.completedOnTime },
    { label: t("taskManagement.completedBefore"), value: performace?.completedBeforeTime },
    { label: t("taskManagement.notCompletedOntime"), value: performace?.notCompletedOnTime },
    { label: t("taskManagement.notCompletedAtAll"), value: performace?.notCompletedAtAll },
  ]

  return (
    <Card className={styles.task_performance_card} onClick={(e) => handlePerformanceCardClick(e, performace, history)}>
      <p className="text-decoration-none">
        <Text size="18px" weight="600" color="#587E85">
          {`${performace?.userFirstName} ${performace?.userLastName}`}
        </Text>
      </p>
      <Text size="12px" weight="400" color="#6F7788" marginTop="4px">
        {performace?.officeName}
      </Text>
      <div className={styles.grid_container}>
        {labels.map((item) => (
          <div key={item} className={styles.column}>
            <Text size="12px" weight="400" color="#6F7788">
              {item.label}
            </Text>
            <Text size="14px" weight="600" color="#102C42">
              {item.value}
            </Text>
          </div>
        ))}
      </div>
      <hr />
      <div className={styles.grid_container}>
        <div className={styles.column}>
          <Text size="12px" weight="400" color="#6F7788">
            {t("taskManagement.taskAssigned")}
          </Text>
          <Text size="14px" weight="600" color="#102C42">
            {performace?.taskAssigned}
          </Text>
        </div>
        <div className={styles.column}>
          <Text size="12px" weight="400" color="#6F7788">
            {t("taskManagement.totalTimeTaken")}
          </Text>
          <Text size="14px" weight="600" color="#102C42">
            {calculateCompleteionTime(performace?.totalTimeTaken)}
          </Text>
        </div>
      </div>
    </Card>
  );
};


export default withTranslation()(TaskPerformanceCard);
