import { withTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from '../../../../assets/images/cross.svg'
import styles from '../../Scheduler/components/Modals/Modals.module.scss';
import { Fragment, useEffect, useState } from "react";
import Empty from "components/Empty";


function AccountOwnerModal({
    t,
    isModalOpen,
    closeModal,
    allMembersList,
    selectedOwner,
    handleOnChange
}) {
   const [selectedVal, setSelectedVal] = useState(selectedOwner);
    const handleOwnerApply = () => {
        handleOnChange(selectedVal)
        closeModal();
    }

     useEffect(() => {
            if (selectedOwner?.id) {
                setSelectedVal(selectedOwner)
            }
        }, [selectedOwner])

    const handleCancelModal = () => {
        closeModal();
    }
    return (
        <Fragment>
            <Modal
                isOpen={isModalOpen}
                toggle={closeModal}
                className="modal-dialog-centered modal-lg"
                modalClassName="custom-modal"
            >
                <span className="close-btn" onClick={closeModal}>
                    <img src={crossIcon} alt="close" />
                </span>
                <ModalBody>
                    <h2 className="title">{t("taskManagement.selectOwnerMember")}</h2>
                    {allMembersList.length === 0 ? <div className={styles["not_found"]}>
                        <Empty Message={t('notes.noStaffMemberFound')} />
                    </div>
                        :
                        allMembersList?.map((owner, key) => (
                            <div
                                key={key}
                                className={`${styles["staff_popup_wrapper"]} ch-radio`}
                            >
                                <label
                                    className="mr-5 pt-3 pb-3"
                                >
                                    <input
                                        type="radio"
                                        name="blockTimeslotAllDoctors1"
                                        onChange={() => setSelectedVal(owner)}
                                        checked={selectedVal?.id === owner?.id}
                                    />
                                    <span> {owner?.firstName + ' ' + owner?.lastName} </span>
                                </label>
                            </div>
                            
                        ))}

                    <div
                        className={`${styles["btn_container"]} btn-box d-md-flex mt-4`}
                    >
                        <button
                            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                            title="save"
                            onClick={handleOwnerApply}
                            disabled={allMembersList.length === 0 || !selectedOwner?.id}
                        >
                            {t("notes.apply")}
                        </button>
                        <button
                            className="mb-md-3 button button-round button-border btn-mobile-link button-dark"
                            title="cancel"
                            onClick={handleCancelModal}
                        >
                            {t("notes.cancel")}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )

}

export default withTranslation()(AccountOwnerModal);