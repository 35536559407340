import React from "react";
import Button from "components/Button";
import Text from "components/Text";
import ModalBody from "reactstrap/lib/ModalBody";
import { Modal } from "reactstrap";
import { withTranslation } from "react-i18next";
import crossIcon from "../../../../assets/images/cross.svg";
import styles from "../PerformanceReview.module.scss";
import Loader from "components/Loader";

const CancelFormModal = ({
  t,
  isCancelModalOpen,
  closeCancelModal,
  saveDraft,
  showLoader,
  existWithoutSaving,
}) => {
  return (
    <Modal
      isOpen={isCancelModalOpen}
      toggle={closeCancelModal}
      className="modal-dialog-centered modal-lg"
      modalClassName="custom-modal"
    >
      {showLoader && <Loader />}
      <span className="close-btn" onClick={closeCancelModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        <div className={styles.confirmation_modal_container}>
          <Text size="25px" weight="500" color="#111B45">
            {t("performanceReview.cancelTitle")}
          </Text>
          <Text size="16px" weight="300" color="#535B5F">
            {t("performanceReview.cancelDes")}
          </Text>
          <div className={styles.home_btn_wrapper_new + " " + styles.margin40}>
            <Button padding="0 84px" onClick={() => saveDraft()}>
              {t("performanceReview.saveAsDraft")}
            </Button>
            <Button
              borderedSecondaryNew
              padding="0 63px"
              onClick={existWithoutSaving}
            >
              <span>{t("performanceReview.exitWithoutSaving")}</span>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withTranslation()(CancelFormModal);
