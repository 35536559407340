import React from "react";
import styles from "../../TaskManagement.module.scss";
import Card from "components/Card";
import Text from "components/Text";
import { formatUTCDateTime } from "utils";
import useCardModule from "../../hooks/useCardModule";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PerformanceDetailCard({ t, assigne }) {

  const history = useHistory();
  const {
    methods: {
      handleCardClick,
    }
  } = useCardModule()

  const getStatus = (overallStatus) => {
    const statusMap = {
      1: "In Progress",
      2: "Pending",
      3: "Accepted",
      4: "Started",
      5: "Completed",
      6: "Rejected",
      7: "Cancelled"
    };
    return statusMap[overallStatus] || "";
  };

  const getButtonClass = (taskStatus) => {
    const statusClasses = {
      Pending: styles.Pending,
      Cancelled: styles.Cancelled,
      Started: styles.Started,
      Accepted: styles.Accepted,
      Rejected: styles.Rejected,
      "In Progress": styles.InProgress,
      Completed: styles.Completed
    };

    return `${styles.task_management_card_btn} ${statusClasses[taskStatus] || ""}`;
  };

  const getPriorityClass = (taskPriority) => {
    switch (taskPriority) {
      case "HIGH":
        return {
          wrapperClass: styles["high-priority-wrapper"],
          dotClass: styles["high-priority-dot"],
          textClass: styles["high-priority-text"],
        };
      case "MEDIUM":
        return {
          wrapperClass: styles["medium-priority-wrapper"],
          dotClass: styles["medium-priority-dot"],
          textClass: styles["medium-priority-text"],
        };
      case "LOW":
        return {
          wrapperClass: styles["low-priority-wrapper"],
          dotClass: styles["low-priority-dot"],
          textClass: styles["low-priority-text"],
        };
      default:
        return {
          wrapperClass: "",
          dotClass: "",
          textClass: "",
        };
    }
  };

  const getPriority = (priority) => {
    const priorityMap = {
      1: "HIGH",
      2: "MEDIUM",
      3: "LOW"
    };
    return priorityMap[priority] || "All";
  };

  const { wrapperClass, dotClass, textClass } = getPriorityClass(getPriority(assigne?.priority));

  return (
    <Card className={styles["performance_detail_card"] + " " + "margin-top-6x"} onClick={(e) => handleCardClick(e, assigne, history,)}>
      <div className={styles["patient_status_priority_wrapper"]}>
        <Text size="16px" weight="600" color="#587E85">
          {assigne?.title}
        </Text>

        <div className={styles["status_priority_wrapper"]}>
          <div
            className={`${styles["circle_priority_text_wrapper"]} ${wrapperClass}`}
          >
            <span className={`${styles["circular_dot"]} ${dotClass}`}></span>
            <p className={`${styles["priority_text"]} ${textClass}`}>
              {getPriority(assigne?.priority)}
            </p>
          </div>
          <button className={getButtonClass(getStatus(assigne.status))}>{getStatus(assigne?.status)}</button>
        </div>
      </div>
      <div className={styles.gridContainer}>
        <div className={styles.column}>
          <Text size="12px" weight="400" color="#6F7788">
            {t("taskManagement.startDate")}
          </Text>
          <Text size="14px" weight="600" color="#102C42" marginTop="10px">
            {formatUTCDateTime(assigne?.startDate)}
          </Text>
        </div>
        <div className={styles.column}>
          <Text size="12px" weight="400" color="#6F7788">
            {t("taskManagement.dueDate")}
          </Text>
          <Text size="14px" weight="600" color="#102C42" marginTop="10px">
            {formatUTCDateTime(assigne?.dueDate)}
          </Text>
        </div>
        <div className={styles.column}>
          <Text size="12px" weight="400" color="#6F7788">
            {t("taskManagement.assignedBy")}
          </Text>
          <Text size="14px" weight="600" color="#102C42" marginTop="10px">
            {`${assigne?.assignor?.firstName} ${assigne?.assignor?.lastName}`}
          </Text>
        </div>
      </div>
    </Card>
  );
}
