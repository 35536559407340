import React, { useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  appointmentDetailActions,
  reviewComment,
  useAppointmentCovidHistory,
  useAppointmentDetail,
  useQuestionnaireResponse,
} from "repositories/patient-appointment-repository";
import { decodeId, encodeId } from "utils";
import constants from "../../../../constants";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

const usePatientAppointmentDetailModule = () => {
  const history = useHistory();
  const location = useLocation();
  const profile = useSelector((state) => state.userProfile.profile);
  const [showLoader, setShowLoader] = useState(false);
  let { appointmentId } = useParams();
  appointmentId = decodeId(appointmentId);
  const queryClient = useQueryClient();

  const {
    isLoading: appointmentLoading,
    data: appointmentDetail,
    refetch,
  } = useAppointmentDetail(appointmentId);

  const { data: appointmentCovindHistory } =
    useAppointmentCovidHistory(appointmentId);
  const { loading: questionnaireLoading, data: questionnaireResponse } =
    useQuestionnaireResponse(
      appointmentDetail?.office?.id,
      appointmentDetail?.doctor?.id,
      appointmentDetail?.patient?.id,
      appointmentDetail?.id,
      { enabled: !!appointmentDetail?.id }
    );

  const appointmentDetailAction = async (params) => {
    setShowLoader(true);
    try {
      let res = await appointmentDetailActions(params);
      if (res?.statusCode === 200) {
        setShowLoader(false);
        toast.success(res.message);
        queryClient.invalidateQueries([
          "/Appointment?appointmentId",
          appointmentId,
        ]);
        refetch();
      }
      return res;
    } catch (err) {
      setShowLoader(false);
      toast.error(err.message);
      return { status: false };
    }
  };

  const addReviewComment = async (params) => {
    setShowLoader(true);
    try {
      let res = await reviewComment(params);
      if (res?.statusCode === 200) {
        setShowLoader(false);
        toast.success(res.message);
        queryClient.invalidateQueries([
          "/Appointment?appointmentId",
          appointmentId,
        ]);
        refetch();
      }
      return res;
    } catch (err) {
      setShowLoader(false);
      toast.error(err.message);
      return { status: false };
    }
  };

  let isLoading = appointmentLoading || questionnaireLoading;
  
  const goToPreviousScreen = () => {
    if (location?.state?.isFromPatientChat) {
      history.push({
        pathname: constants.routes.patientChatModule,
        state: {
          isFromPatientAppointDetail: true,
        },
      });
    } else if (location?.state?.isFrompatientDetail) {
      history.push(
        constants.routes.appointment.patientDetail
          .replace(":officeId", encodeId(appointmentDetail?.office?.id))
          .replace(":patientId", encodeId(appointmentDetail?.patient?.id))
      );
    } else {
      history.push(constants.routes.appointment.appointmentScreen);
    }
  };

  return {
    data: {
      appointmentId,
      appointmentDetail,
      isLoading,
      showLoader,
      appointmentCovindHistory,
      questionnaireResponse,
      profile,
    },
    methods: {
      goToPreviousScreen,
      appointmentDetailAction,
      addReviewComment,
    },
  };
};

export default usePatientAppointmentDetailModule;
