const { LoaderIcon } = require("react-hot-toast");
const { default: styled } = require("styled-components");

const ChatLoader = () => {
  return (
    <GridCenter>
      <LoaderIcon style={{ margin: "auto auto", padding: "20px" }} />
    </GridCenter>
  );
};
const GridCenter = styled.div`
  display: grid;
  placeitems: center;
  height: 100%;
  div {
    margin: auto auto;
    padding: 20px;
  }
`;
export default ChatLoader;
