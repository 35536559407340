import React, { Fragment, useRef, useState } from "react";
import styles from "../../../accountOwner/pages/Scheduler/Scheduler.module.scss";
import Card from "components/Card";
import TimesheetReportHeader from "./TimesheetReportHeader";
import moment from "moment";
import constants from "../../../constants";
import { encodeId } from "utils";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import FamilyModal from "patient-scheduling/pages/FamilyMembers/components/AddedMembers/FamilyModal";
import RejectionModal from "../StaffListingTimesheet/component/RejectionModal";
import { updateTimesheetStatus } from "repositories/timesheet-repository";
import toast from "react-hot-toast";
import HolidayIcon from "../../../assets/images/early_on.svg";
import ThreeDotsGreenIcon from "../../../assets/images/ico_more_options_green.svg";
import ThreeDotsWhiteIcon from "../../../assets/images/ico_more_options_web.svg";
import TimesheetBreakModal from "./TimesheetBreakModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TimesheetReportView = ({
  t,
  officeId,
  setShowLoader,
  refetch,
  startDate,
  endDate,
  holidays,
  staffListTimesheet,
  handleNext,
  handlePrevious,
  goToCurrentDate,
  sortByList,
  setSelectedSort,
  selectedSort,
}) => {
  const calendarRef = useRef(null);
  const history = useHistory();
  const timesheetListingStatus = constants.TimesheetListingStatus;
  const [selectedTimesheetIds, setSelectedTimesheetIds] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [selectMemberName, setSelectMemberName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClockInOutBreakTime, setIsClockInOutBreakTime] = useState(null);

  const closeModal = () => {
    setIsClockInOutBreakTime(null);
    setIsModalOpen(false);
  };

  const generateDateHeaders = (startDate, endDate) => {
    let dates = [];
    let current = moment(new Date(startDate));
    let last = moment(new Date(endDate));

    while (current.isSameOrBefore(last, "day")) {
      dates.push(current.format("YYYY-MM-DD"));
      current.add(1, "day");
    }
    return dates;
  };

  const dateHeaders = generateDateHeaders(startDate, endDate);

  const getTimesheetForDate = (timesheets, date) => {
    return timesheets?.find((timesheet) => {
      const timesheetDate = moment(timesheet.date).format("YYYY-MM-DD");
      const headerDate = moment(date).format("YYYY-MM-DD");
      return timesheetDate === headerDate;
    });
  };

  const getHolidayForDate = (holidays, date) => {
    return holidays?.find((holiday) => {
      const holidayDate = moment(holiday.date).format("YYYY-MM-DD");
      const headerDate = moment(date).format("YYYY-MM-DD");
      return holidayDate === headerDate;
    });
  };

  const formatTime = (dateTimeStr) => {
    return dateTimeStr ? moment(dateTimeStr).format("h:mm A") : "-";
  };

  const isCurrentDateInRange = () => {
    const currentDate = moment().startOf("day");
    return currentDate.isBetween(
      moment(startDate).startOf("day"),
      moment(endDate).endOf("day"),
      "day",
      "[]"
    );
  };

  const formatBreakDuration = (minutes) => {
    if (!minutes) return "-";
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const formatTimeSpent = (minutes) => {
    if (!minutes) return "-";
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const decimal = (mins / 60).toFixed(2).substring(1);
    return `${hours}${decimal}`;
  };

  const updateStatus = async (statusId, timesheetId, reason) => {
    setShowLoader(true);

    try {
      let response = await updateTimesheetStatus({
        timesheetId:
          selectedTimesheetIds?.toString() || timesheetId?.toString(),
        statusId: statusId,
        reason: reason ? reason : "",
      });

      refetch();
      toast.success(response?.message);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setShowLoader(false);
    }

    setSelectedTimesheetIds([]);
  };

  const confirmSubmitRequest = async (statusId, reason) => {
    setConfirmModal(false);
    setIsRejectionModalOpen(false);
    setSelectMemberName("");
    await updateStatus(statusId, null, reason);
  };

  const isDateInLeaveRange = (date, leaves) => {
    if (!leaves || leaves.length === 0) return false;

    return leaves.some((leave) => {
      const leaveStartDate = moment(leave.startDate).startOf("day");
      const leaveEndDate = moment(leave.endDate).startOf("day");
      const checkDate = moment(date).startOf("day");

      return checkDate.isBetween(leaveStartDate, leaveEndDate, "day", "[]");
    });
  };

  const convertMinutesToHours = (minutes) => {
    if (!minutes) return "-";
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const decimal = (mins / 60).toFixed(2).substring(1);
    return `${hours}${decimal} Hrs`;
  };

  const isCurrentDay = (date) => {
    return moment(date).isSame(moment(), "day");
  };

  const renderHoursList = (hasTimesheets, timesheet, date, staff) => {
    const isLeaveDay = isDateInLeaveRange(date, staff?.leaves);

    if (!hasTimesheets || !timesheet) {
      return (
        <td
          className={
            "border text-center" + " " + styles["timesheet_report_table_td"]
          }
          style={{
            backgroundColor: isLeaveDay ? "#FCC539" : undefined,
          }}
        >
          <p className={styles.timesheet_table_text}>-</p>
        </td>
      );
    }

    const hasValidTimeSpent = timesheet?.timeSpent && timesheet.timeSpent > 0;
    const timeDisplay = hasValidTimeSpent
      ? formatTimeSpent(timesheet.timeSpent)
      : "-";

    if (!hasValidTimeSpent) {
      return (
        <td
          className={
            "border text-center" + " " + styles["timesheet_report_table_td"]
          }
        >
          <p className={styles.timesheet_table_text}>-</p>
        </td>
      );
    }

    const menuOptions = [];

    if (timesheet?.statusId === timesheetListingStatus[2]?.value) {
      menuOptions.push({
        text: t("staffTimesheet.markAsPaid"),
        onclick: () => {
          setSelectedTimesheetIds([timesheet?.id]);
          setConfirmModal(true);
        },
      });
    }

    if (timesheet?.statusId === timesheetListingStatus[1]?.value) {
      menuOptions.push({
        text: t("staffTimesheet.approve"),
        onclick: () =>
          updateStatus(timesheetListingStatus[2]?.value, timesheet?.id),
      });
      menuOptions.push({
        text: t("reject"),
        onclick: () => {
          setSelectedTimesheetIds([timesheet?.id]);
          setIsRejectionModalOpen(true);
          setSelectMemberName(
            staff?.user?.firstName + " " + staff?.user?.lastName
          );
        },
      });
    }

    if (timesheet?.statusId !== timesheetListingStatus[4]?.value) {
      timesheet["timesheetDate"] = timesheet?.date;
      menuOptions.push({
        text: t("edit"),
        onclick: () => {
          history.push({
            pathname: constants.routes.accountOwner.editTimesheetForStaff
              .replace(":officeId", encodeId(officeId))
              .replace(":staffId", encodeId(timesheet?.userId)),
            state: { detail: timesheet, staffDetail: staff?.user },
          });
        },
      });
    }

    const getStatusCell = () => {
      const isLeaveDay = isDateInLeaveRange(date, staff?.leaves);
      let baseStyle = isLeaveDay ? { backgroundColor: "#FCC539" } : {};

      switch (timesheet?.statusId) {
        case timesheetListingStatus[1]?.value: // Pending Approval
          return {
            ...baseStyle,
            backgroundColor: isLeaveDay ? "#FCC539" : "#F9FCEE",
            icon: ThreeDotsGreenIcon,
          };
        case timesheetListingStatus[2]?.value: // Approved
          return {
            ...baseStyle,
            backgroundColor: isLeaveDay ? "#FCC539" : "#FEF3D7",
            icon: ThreeDotsGreenIcon,
          };
        case timesheetListingStatus[3]?.value: // Rejected
          return {
            ...baseStyle,
            backgroundColor: isLeaveDay ? "#FCC539" : "#EE4F4F",
            color: "#ffffff",
            icon: ThreeDotsWhiteIcon,
          };
        case timesheetListingStatus[4]?.value: // Paid
          return {
            ...baseStyle,
            backgroundColor: isLeaveDay ? "#FCC539" : "#A9CF3D",
            icon: ThreeDotsGreenIcon,
          };
        default:
          return { ...baseStyle, icon: ThreeDotsGreenIcon };
      }
    };

    return (
      <td
        className={
          "border text-center" + " " + styles["timesheet_report_table_td_new"]
        }
        style={{
          backgroundColor: getStatusCell().backgroundColor,
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <div className={styles.dropdown_text_wrapper}>
          <p
            className={
              getStatusCell().backgroundColor === "#EE4F4F"
                ? styles.white_text
                : styles.break_finish_hours_text
            }
          >
            {timeDisplay}
          </p>
          {menuOptions.length > 0 && (
            <UncontrolledDropdown>
              <DropdownToggle
                caret={false}
                tag="div"
                className="cursor-pointer"
              >
                <div className="ellipsis-icon">
                  <img src={getStatusCell().icon} alt="menu" />
                </div>
              </DropdownToggle>
              <DropdownMenu right className={styles["office-dropdown-menu"]}>
                {menuOptions.map((option, index) => (
                  <DropdownItem
                    key={index}
                    className={styles["office-dropdown-item"]}
                    onClick={option.onclick}
                  >
                    <p className="m-0">{option.text}</p>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      </td>
    );
  };

  const handleClockInClockOut = (timesheet) => {
    if (timesheet?.clockInOutBreakTime?.length > 0) {
      setIsClockInOutBreakTime(timesheet);
      setIsModalOpen(true);
    }
  };

  const renderCell = (date, staff, type, hasTimesheets) => {
    const holiday = getHolidayForDate(holidays, date);
    const timesheet = getTimesheetForDate(staff.timesheets, date);

    if (holiday) {
      return (
        <td style={{ backgroundColor: "#DEE5E7", border: "none" }}>&nbsp;</td>
      );
    }

    switch (type) {
      case "start":
        return (
          <td
            className={
              "border text-center" + " " + styles["timesheet_report_table_td"]
            }
          >
            <p className={styles.timesheet_table_text}>
              {hasTimesheets ? formatTime(timesheet?.startedOn) : "-"}
            </p>
          </td>
        );
      case "break":
        return (
          <td
            onClick={() => handleClockInClockOut(timesheet)}
            className={
              "border text-center" + " " + styles["timesheet_report_table_td"]
            }
          >
            <p className={styles.timesheet_table_text}>
              {hasTimesheets
                ? formatBreakDuration(timesheet?.breakDuration)
                : "-"}
            </p>
          </td>
        );
      case "finish":
        return (
          <td
            className={
              "border text-center" + " " + styles["timesheet_report_table_td"]
            }
          >
            <p className={styles.timesheet_table_text}>
              {" "}
              {hasTimesheets ? formatTime(timesheet?.finishedOn) : "-"}
            </p>
          </td>
        );
      case "hours":
        return <>{renderHoursList(hasTimesheets, timesheet, date, staff)}</>;
      default:
        return (
          <td
            className={
              "border text-center" + " " + styles["timesheet_report_table_td"]
            }
          >
            <p className={styles.timesheet_table_text}>-</p>
          </td>
        );
    }
  };

  const renderTotalColumn = (type, staff) => {
    switch (type) {
      case "break":
        return (
          <Fragment>
            <p className={styles.total_hours_bold_text}>
              {" "}
              {convertMinutesToHours(staff.timesheets?.[0]?.totalBreakTime)}
            </p>
          </Fragment>
        );
      case "hours":
        return (
          <Fragment>
            <p className={styles.total_hours_bold_text}>
              {" "}
              {convertMinutesToHours(staff.timesheets?.[0]?.totalTime)}
            </p>
          </Fragment>
        );
      default:
        return (
          <td
            className={
              "border text-center" + " " + styles["timesheet_report_table_td"]
            }
          >
            -
          </td>
        );
    }
  };

  const renderListOfDetail = (staff, hasTimesheets) => {
    return (
      <Fragment>
        <tr>
          <td className={styles.heading_tag_text}>
            <p className={styles.break_finish_hours_text}>
              {t("staffTimesheet.break")}
            </p>
          </td>
          {dateHeaders.map((date, dateIdx) => (
            <Fragment key={dateIdx}>
              {renderCell(date, staff, "break", hasTimesheets)}
            </Fragment>
          ))}
          <td style={{ verticalAlign: "middle" }}>
            {renderTotalColumn("break", staff)}
          </td>
        </tr>
        <tr>
          <td className={styles.heading_tag_text}>
            <p className={styles.break_finish_hours_text}>
              {t("staffTimesheet.finish")}
            </p>
          </td>
          {dateHeaders.map((date, dateIdx) => (
            <Fragment key={dateIdx}>
              {renderCell(date, staff, "finish", hasTimesheets)}
            </Fragment>
          ))}
        </tr>
        <tr>
          <td className={styles.heading_tag_text}>
            <p className={styles.break_finish_hours_text}>
              {t("staffTimesheet.hours")}
            </p>
          </td>
          {dateHeaders.map((date, dateIdx) => (
            <Fragment key={dateIdx}>
              {renderCell(date, staff, "hours", hasTimesheets)}
            </Fragment>
          ))}
          <td style={{ verticalAlign: "middle" }}>
            {renderTotalColumn("hours", staff)}
          </td>
        </tr>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Card className={styles.timesheet_report_card_padding}>
        <TimesheetReportHeader
          t={t}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          goToCurrentDate={goToCurrentDate}
          showCurrentDateButton={!isCurrentDateInRange()}
          sortByList={sortByList}
          setSelectedSort={setSelectedSort}
          selectedSort={selectedSort}
        />
        <div
          className={
            styles["scheduler-table-wrapper"] +
            " " +
            styles["scheduler-table-wrapper-timesheet-new"] +
            " " +
            styles["fixed-header-table-wrapper"]
          }
        >
          <div
            ref={calendarRef}
            className={
              styles["scheduler-fixed-table"] +
              " " +
              styles["scheduler_fixed_table_timesheet_report"]
            }
          >
            <table
              className={
                "table table-bordered " +
                styles["schedular-table"] +
                " " +
                styles["agenda-weekly-table"] +
                " " +
                styles["fixed-header-table"] +
                " " +
                styles["timesheet_report_table_border"]
              }
            >
              <thead>
                <tr className={styles.timesheet_report_table_heading_row}>
                  <th className={styles.timesheet_report_table_heading_th_new}>
                    <p className={styles.timesheet_report_table_first_col_name}>
                      {t("staffTimesheet.name")}
                    </p>
                  </th>
                  <th className={styles.timesheet_report_table_heading_th}>
                    <p className={styles.timesheet_report_table_hading}>
                      {t("staffTimesheet.details")}
                    </p>
                  </th>
                  {dateHeaders.map((date, index) => (
                    <th
                      className={styles.timesheet_report_table_heading_th}
                      key={index}
                      style={{
                        backgroundColor: isCurrentDay(date)
                          ? "#587E85"
                          : undefined,
                      }}
                    >
                      <p
                        className={styles.timesheet_report_table_hading}
                        style={{
                          color: isCurrentDay(date) ? "#FFFFFF" : undefined,
                        }}
                      >
                        {moment(date).format("ddd DD, MMM")}
                      </p>
                    </th>
                  ))}
                  <th className={styles.timesheet_report_table_heading_th}>
                    <p className={styles.timesheet_report_table_hading}>
                      {t("staffTimesheet.total")}
                    </p>
                  </th>
                </tr>
                {dateHeaders.some((date) =>
                  getHolidayForDate(holidays, date)
                ) && (
                  <tr>
                    <td style={{ background: "#FFFFFF" }}></td>
                    <td style={{ background: "#FFFFFF" }}></td>
                    {dateHeaders.map((date, index) => {
                      const holiday = getHolidayForDate(holidays, date);
                      return (
                        <td
                          key={index}
                          style={{
                            textAlign: "center",
                            background: holiday ? "#587E85" : "#FFFFFF",
                            color: "#FFFFFF",
                            verticalAlign: "middle",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          <div className={styles.holiday_text_icon_wrapper}>
                            <img src={HolidayIcon} alt="holiday" />
                            <p className={styles.holiday_text}>
                              {holiday ? holiday.title : ""}
                            </p>
                          </div>
                        </td>
                      );
                    })}
                    <td style={{ background: "#FFFFFF" }}></td>
                  </tr>
                )}
              </thead>
              <tbody>
                {staffListTimesheet.length > 0 &&
                  staffListTimesheet.map((staff, idx) => {
                    const hasTimesheets =
                      staff.timesheets && staff.timesheets.length > 0;
                    return (
                      <Fragment key={staff.user.id}>
                        <tr>
                          <td rowSpan={4} className={styles.sticky_col}>
                            <div className={styles.first_col_border}>
                              <p className={styles.break_finish_hours_text}>
                                {`${staff?.user?.firstName || ""} ${
                                  staff?.user?.lastName || ""
                                }`}
                              </p>
                            </div>
                          </td>
                          <td className={styles.heading_tag_text}>
                            <p className={styles.break_finish_hours_text}>
                              {t("staffTimesheet.start")}
                            </p>
                          </td>
                          {dateHeaders.map((date, dateIdx) => (
                            <Fragment key={dateIdx}>
                              {renderCell(date, staff, "start", hasTimesheets)}
                            </Fragment>
                          ))}
                        </tr>
                        {renderListOfDetail(staff, hasTimesheets)}
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
      {confirmModal && (
        <FamilyModal
          isFamilyModalOpen={confirmModal}
          setIsFamilyModalOpen={setConfirmModal}
          title={t("staffTimesheet.markAsPaid")}
          subTitle1={t("staffTimesheet.cannotUndo")}
          subTitle2={t("staffTimesheet.markAsPaidConfirmMsg")}
          leftBtnText={t("Confirm")}
          rightBtnText={t("cancel")}
          onConfirm={() =>
            confirmSubmitRequest(timesheetListingStatus[4]?.value)
          }
        />
      )}
      {isRejectionModalOpen && (
        <RejectionModal
          isRejectionModalOpen={isRejectionModalOpen}
          setIsRejectionModalOpen={setIsRejectionModalOpen}
          staffMemberName={selectMemberName}
          onReject={(reason) =>
            confirmSubmitRequest(timesheetListingStatus[3]?.value, reason)
          }
        />
      )}

      {isModalOpen && (
        <TimesheetBreakModal
          t={t}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          clockInOutBreakTime={isClockInOutBreakTime}
        />
      )}
    </Fragment>
  );
};

export default TimesheetReportView;
