import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styles from "../../Appointments.module.scss";
import { Card } from 'reactstrap';
import { getSlotDetail, useSlot } from 'repositories/patient-appointment-repository';
import Loader from 'components/Loader';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import Empty from 'components/Empty';

const Timeslot = ({ t, officeId, date, doctorId, setSelectedSlots, selectedSlots, setconfirmBlocked, setBlockReason, setAppointmentdetails, setApptDetails, setShowLoader}) => {
    const { data: timeSlots, isLoading, refetch } = useSlot(officeId, date, doctorId);
    const queryClient = useQueryClient();

    const isSelected = (slot) => {
        return selectedSlots?.find(selected => selected.startTime === slot.startTime);
    };

    const getAppointmentById = async slotObj => {
        setShowLoader(true)
        const params = {
          date: slotObj.startDateTime,
          officeId: officeId,
          doctorId: doctorId,
        };
        try {
            const result = await getSlotDetail(params);
            if (result?.status) {
                    setShowLoader(false)
                    setApptDetails(true);
                    setAppointmentdetails(result?.data[0]);
                }
             
        } catch (error) {
            setShowLoader(false);
        }
      };

    const handleSlotClick = (slot) => {
        const isSlotSelected = isSelected(slot);
         if (slot.slotStatus == 3) {
            getAppointmentById(slot);
            return;
          }
        if (isSlotSelected) {
            const updatedSlots = selectedSlots.filter(selected => selected.startTime !== slot.startTime);
            if (selectedSlots.length == 1) {
                setBlockReason("")
            }
            setSelectedSlots(updatedSlots);
        } else {
            if (selectedSlots?.length && (selectedSlots[0]?.slotStatus !== slot?.slotStatus)) {
                 return;
            } else {
                const updatedSlots = [...selectedSlots, slot].sort((a, b) =>
                    moment(a.startTime, 'hh:mm A').diff(moment(b.startTime, 'hh:mm A'))
                );
                 setSelectedSlots(updatedSlots);
                 if (slot?.slotStatus === 2) {
                    if ( selectedSlots.length == 0 ) {
                        setconfirmBlocked(true);
                        setBlockReason([...slot?.reasonForBlock])
                    }
                }
               
            }
        }
    };



    const getSlotBackgroundColor = (slot) => {
        if (slot.slotStatus === 1) {
            return isSelected(slot) ? 'rgb(88,126,133)' : '#fff';
        } else if (slot?.slotStatus === 2) {
            return isSelected(slot) ? 'rgb(88,126,133)' : 'rgb(229,231,223)';
        } else if (slot?.slotStatus === 3) {
            return 'rgb(169,207,61)';
        }
    };

    useEffect(() => {
        queryClient.invalidateQueries(["/Doctor/Schedules", officeId, date, doctorId]);
        refetch();
    }, [date, selectedSlots])

    useEffect(() => {
        setSelectedSlots([]);
    }, [date])

    return (
        <div className={styles['urban-dental-reassign-card-wrapper'] + " " + "d-flex flex-wrap w-100"}>
            {isLoading && <Loader />}
            {timeSlots && timeSlots?.filter(m => m?.isAvailable)?.length > 0  ? <Card className={styles['urban-dental-reassign-card'] + " " + "mt-3"}>
                <div className={styles['show-time-box']}>
                    {timeSlots?.filter(m => m.isAvailable)?.map((slot, index) =>
                        <Card
                            className={`${styles['common-time-box']} ${styles['selected-slot']}`}
                            key={index}
                            onClick={() => handleSlotClick(slot)}
                            style={{
                                backgroundColor: getSlotBackgroundColor(slot),
                                color: isSelected(slot) ? '#fff' : slot?.slotStatus !== 3 ? '' : '#fff',
                                cursor: 'pointer'
                            }}
                        >
                            {slot?.startTime}
                        </Card>
                    )}
                </div>
            </Card> : <Empty Message={t("patientAppointmentMembers.selectedEmptyMsg")}/>}
        </div>
    );
};

export default withTranslation()(Timeslot);
