import Page from "components/Page";
import React, { useEffect, useState, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Loader from "components/Loader";
import styles from "./Appointments.module.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";
import alertImage from "../../../assets/images/alert-circle-black.svg";
import crossIcon from "../../../assets/images/mir_cross grey.svg"
import UpcomingTabs from "../Appointments/UpcomingTabs";
import RecallTabs from "../Appointments/RecallTab";
import Text from 'components/Text';
import PastAppointmentTab from "./PastAppointmentTab";
import constants from "../../../constants";
import { encodeId, getStorage, setStorage } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useStaffByUserId } from "repositories/office-repository";

const PatientAppointments = ({ t, history }) => {
	const cachedActiveTab = getStorage(constants.patientScheduling.cache.eventlisting)
	const officeState = getStorage('officeState')
	const [activeTab, setActiveTab] = useState(cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1);
	const [officeId, setOfficeId] = useState({});
	const profile = useSelector(state => state.userProfile.profile);
	const location = useLocation();
	const { state } = location;
	const tabName = constants.patientAppointMentTab;

	const { data: staffDetail } = useStaffByUserId(officeState?.officeState?.officeId, profile?.id);
	const goToPreviousScreen = () => {
		if (officeState?.officeState?.officeId) {
			if (profile?.role?.systemRole === constants.systemRoles.accountOwner) {
				history.push(constants.routes.accountOwner.officeOptions.replace(":officeId", encodeId(officeState?.officeState?.officeId)));
			}
			else if (staffDetail?.isAdmin) {
				history.push(constants.routes.staff.officeAdmin.replace(":officeId", encodeId(officeState?.officeState?.officeId)))
			} else {
				history.push(constants.routes.staff.officeOptions.replace(":officeId", encodeId(officeState?.officeState?.officeId)));
			}
		} else {
			history.push("/");
		}
	};

	const manageTab = (tab) => {
		window.scrollTo(0, 0);
		setActiveTab(tab);
		setStorage(constants.patientScheduling.cache.eventlisting, {
			activeTab: tab,
		});
	};

	useEffect(() => {
		if (state?.activetabs) {
			setActiveTab(state?.activetabs);
			setStorage('officeState', {
				officeState: state,
			});
		}
		setStorage('isFromAppointmentDetail', {
            isFromAppointmentDetail: false,
        });
		setStorage('isFromManagePage', {
			isFromManagePage: false,
		  });
		setStorage('createRecall', {
            createRecall: false,
        });
		setStorage('createPage', {
            isFromCreatePage: false,
        });
		setStorage('appointmentReminder', {
            appointmentReminder: false,
        });
		setOfficeId(officeState?.officeState);
	}, [])

	return (
		<Page className="scheduler-page" onBack={goToPreviousScreen}>
			<div className={styles["first-row-top"]}>
				<div className={styles["first-row"]}>
					<div className={styles["first-row-text-part"]}>
						<p className={styles["mobile-text-size"]}> {t("patientAppointmentMembers.patientAppointments")}</p>
					</div>
					<div className={styles["btn-box"]}>
					    {activeTab == tabName.upCommingAppointment && <Link to="/see-today-schedule">
							<button className="button button-round button-border button-dark w-sm-100">
								{t("patientAppointmentMembers.SeeTodayScheduleBtn")}
							</button>
						</Link>
						}
						<Link to={officeState?.officeState?.officeId ? `/office-manage-schedule/${encodeId(officeState?.officeState?.officeId)}` : `/manage-schedule`}>
							<button className="button button-round button-border button-dark w-sm-100">
								{t("patientAppointmentMembers.manageSchedule")}
							</button>
						</Link>
						<Link to={officeState?.officeState?.officeId ? `/patient-new-appointment/${encodeId(officeState?.officeState?.officeId)}` : `/patient-new-appointment`}>
							<button className="button button-round button-shadow w-sm-100">
								{t("patientAppointmentMembers.newAppointment")}
							</button>
						</Link>
					</div>
					{!officeState?.officeState?.officeId && <div className={styles['yellow-warning-box'] + " " + "d-flex"}>
						<img src={alertImage} alt="blackAlertImage" />
						<Text size="14px" weight="400" color="#2F3245" >{t('patientAppointmentMembers.appointmentReminderPlanText')}</Text>
						<img src={crossIcon} alt="blackCrossImage" />
					</div>}
				</div>
			</div>
			<div className={styles["appointment-tabs"] + " " + "form-wrapper"}>
				<Nav tabs className={styles["nav-tabs"]}>
					<NavItem className={styles["nav-items"]}>
						<NavLink className={activeTab == tabName.upCommingAppointment ? styles["active"] : styles["not-active"]} onClick={() => manageTab(1)}>
							{t("patientAppointmentMembers.upcoming")}
						</NavLink>
					</NavItem>
					<NavItem className={styles["nav-items"]}>
						<NavLink className={activeTab == tabName.recallAppointment ? styles["active"] : styles["not-active"]} onClick={() => manageTab(2)}>
							{t("patientAppointmentMembers.recall")}
						</NavLink>
					</NavItem>
					<NavItem className={styles["nav-items"]}>
						<NavLink className={activeTab == tabName.pastAppointment ? styles["active"] : styles["not-active"]} onClick={() => manageTab(3)}>
							{t("patientAppointmentMembers.past")}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<Suspense fallback={<Loader />}>
						<TabPane tabId={1}>
							{activeTab === tabName.upCommingAppointment && <UpcomingTabs state={state || officeState?.officeState} manageTab={manageTab} />}
						</TabPane>
						<TabPane tabId={2}>
							{activeTab === tabName.recallAppointment && <RecallTabs state={state || officeState?.officeState} />}
						</TabPane>
						<TabPane tabId={3}>
							{activeTab === tabName.pastAppointment && <PastAppointmentTab state={state || officeState?.officeState} />}
						</TabPane>
					</Suspense>
				</TabContent>
			</div>
		</Page>
	);
};
export default withTranslation()(PatientAppointments);
