import React, { Fragment } from "react";
import Page from "components/Page";
import { Col, Row } from "reactstrap";
import styles from "./StaffOfficeOptions.module.scss";
import timeSheetIcon from "./../../../assets/images/icTimeSheets.svg";
import contractsIcon from "./../../../assets/images/contracts.svg";
import accountIcon from "./../../../assets/images/accountadmin.svg";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import { motion } from "framer-motion";
import {
  useOfficeDetail,
  useStaffByUserId,
} from "repositories/office-repository";
import { useSelector } from "react-redux";
import performanceReviewIcon from "./../../../assets/images/performance_review.svg";
import { decodeId, setStorage } from "utils";
import noticesIcon from "./../../../assets/images/notices.svg";
import DashboardIcon from "./../../../assets/images/Dashboard.svg";
import waitingListIcon from "./../../../assets/images/waiting_list.svg";
import PatientsIcon from "./../../../assets/images/patients.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function StaffOfficeOptions({ history, location, match, t }) {
  const goBack = () => history.push(constants.routes.accountOwner.offices);
  const profile = useSelector((state) => state.userProfile.profile);
  let { officeId } = useParams();
  const decodeOfficeId = parseInt(decodeId(officeId));

  let officeName = null;

  if (
    location.state &&
    location.state.officeData &&
    location.state.officeData.officeName
  ) {
    officeName = location.state.officeData.officeName;
  }

  const { isLoading, data } = useOfficeDetail(decodeId(match.params.officeId));
  const { data: staffDetail } = useStaffByUserId(decodeOfficeId, profile?.id);

  if (!isLoading && data) {
    officeName = data.name;
  }

  const handleRedirect = (officeId) => {
    history.push({
      pathname: constants.routes.appointment.appointmentScreen,
      state: {
        officeId: officeId,
        activetabs: 1,
        statusFilter: [],
        isfromPaitentDetail: true,
      },
    });
  };

  const handlePatientRedirect = (officeId) => {
    history.push(
      constants.routes.appointment.patients.replace(":officeId", officeId)
    );
    setStorage("officeState", {
      officeState: {},
    });
  };

  return (
    <Page
      onBack={goBack}
      isTitleLoading={!officeName && isLoading}
      title={officeName || (data && data.name)}
      className={styles["page"]}
    >
      <>
        {/* Office Category */}
        <div>
          <p className={styles["mobile-text-size"]}>{t("officeCategory")}</p>
          <Row>
            {profile && staffDetail?.isAdmin && (
              <Col lg="4">
                <Card
                  to={constants.routes.staff.officeAdmin.replace(
                    ":officeId",
                    match.params.officeId
                  )}
                  icon={accountIcon}
                  title={t("adminControls")}
                  data={location.state}
                />
              </Col>
            )}
            {data && !data.isTempOffice && (
              <Col lg="4">
                <Card
                  to={constants.routes.accountOwner.myForms.replace(
                    ":officeId",
                    match.params.officeId
                  )}
                  icon={contractsIcon}
                  title={t("contracts.formsAndContract")}
                  data={location.state}
                />
              </Col>
            )}
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.timelinePreferences.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timeSheetIcon}
                title={t("accountOwner.preferences")}
                officeName={location.state}
                data={{ officeName: location?.state?.officeData?.name }}
              />
            </Col>
            {staffDetail?.isReceptionist && (
              <Col lg="4">
                <Card
                  redirectTo={() =>
                    handleRedirect(decodeId(match.params.officeId))
                  }
                  icon={DashboardIcon}
                  title="Receptionist"
                />
              </Col>
            )}
          </Row>
        </div>

        {/* My Records */}
        <div>
          <p className={styles["mobile-text-size"] + " " + "mt-4"}>
            {t("myRecordsCategory")}
          </p>
          <Row>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.myNotices.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={noticesIcon}
                title={t("notices.myPerformanceNotoces")}
                data={location.state}
              />
            </Col>
            <Col lg="4">
              <Card
                to={constants.routes.accountOwner.myPerformanceReview.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={performanceReviewIcon}
                title={t("staff.myPerformanceReview")}
                data={location.state}
              />
            </Col>
            <Col lg="4">
              {" "}
              <Card
                to={constants.routes.staff.timesheet.replace(
                  ":officeId",
                  match.params.officeId
                )}
                icon={timeSheetIcon}
                title={t("staff.myTimesheetsLeaves")}
                data={location.state}
              />
            </Col>
          </Row>
        </div>

        {/* Patient Management */}
        <div>
          <p className={styles["mobile-text-size"] + " " + "mt-4"}>
            {t("pateintManagementCategory")}
          </p>
          <Row>
            {(staffDetail?.isDoctor || staffDetail?.isReceptionist) && (
              <Col lg="4">
                <Card
                  redirectTo={() =>
                    handlePatientRedirect(match.params.officeId)
                  }
                  icon={PatientsIcon}
                  title={t("patients.patients")}
                />
              </Col>
            )}
            {(!staffDetail?.isAdmin ||
              (data?.isConnectedWithSikka &&
                data?.practiceManagementSystemName)) && (
              <Fragment>
                <Col lg="4">
                  <Card
                    to={constants.routes.waitingList.replace(
                      ":officeId",
                      match.params.officeId
                    )}
                    icon={waitingListIcon}
                    title={t("waitingList.waitingList")}
                  />
                </Col>
              </Fragment>
            )}
            {(staffDetail?.isDoctor || staffDetail?.isReceptionist) && (
              <Col lg="4">
                <Card
                  to={constants.routes.superAdmin.appointmentDashboard.replace(
                    ":officeId",
                    match.params.officeId
                  )}
                  icon={DashboardIcon}
                  title="Patient Dashboard"
                />
              </Col>
            )}
          </Row>
        </div>
      </>
    </Page>
  );
}

function Card({ to, icon, title, data, redirectTo }) {
  if (redirectTo) {
    return (
      <motion.div
        whileHover={{ scale: constants.animation.hoverScale }}
        whileTap={{ scale: constants.animation.hoverScale }}
      >
        <span onClick={redirectTo} className="pointer">
          <div className={styles["card"]}>
            <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
            <h4>{title}</h4>
          </div>
        </span>
      </motion.div>
    );
  }
  return (
    <motion.div
      whileHover={{ scale: constants.animation.hoverScale }}
      whileTap={{ scale: constants.animation.hoverScale }}
    >
      <Link
        to={{
          pathname: to,
          state: data,
        }}
      >
        <div className={styles["card"]}>
          <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
          <h4>{title}</h4>
        </div>
      </Link>
    </motion.div>
  );
}

export default withTranslation()(StaffOfficeOptions);
