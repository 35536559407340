import { useEffect } from "react";
import { useSelector } from "react-redux";
import constants from "../../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RedirectionForPatientAndDoctor = () => {
  const profile = useSelector((state) => state.userProfile.profile);
  const history = useHistory();
  useEffect(() => {
    if (profile.id) {
      if (profile.role.systemRole === constants.systemRoles.patient) {
        history.push(
          constants.routes.patientWithOwnerOrDoctorChatModule +
            window.location.search
        );
      } else {
        history.push(
          constants.routes.patientChatModule + window.location.search
        );
      }
    }
  }, [profile]);
  return null;
};
export default RedirectionForPatientAndDoctor;
