import React,{useState} from 'react'
import { Card} from "reactstrap";
// import '../ManageSchedule.scss';
import { useHistory } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Input from 'components/Input';
import Text from 'components/Text';
import {Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactDatePicker from 'react-datepicker';
import styles from "../../../../../Appointments.module.scss";



export default function ReassignDateTime() {
    const [modalOpen, setModalOpen] = React.useState(false);
  
    const history = useHistory();
    const numberOfDivs = 49; 
    const startTime = new Date();
    startTime.setHours(9, 30, 0);

   const goBack = () => {
    history.goBack();
  };

  const renderDivs = () => {
    const divs = [];
    for (let i = 0; i < numberOfDivs; i++) {
      const time = new Date(startTime.getTime() + i * 15 * 60000); // Calculate time with a difference of 15 minutes
      const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time as HH:mm
      divs.push(
        <Card className='common-time-box'key={i}>
          {formattedTime}
        </Card>
      );
    }
    return divs;
  };

  const ShowDate = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
      <ReactDatePicker selected={startDate} dateFormat="MMM d, yyyy" onChange={(date) => setStartDate(date)} className="c-form-control" />
    );
  };

  return (
    <Page onBack={goBack} className="main-page">
      <div className='manage-schedule-heading d-flex justify-content-between'>
        <div>
           <h3>Urban Dental</h3>
           <Text color='#000000' weight='300' size='14px'>Reassign Appointment</Text>
        </div>
      </div>
      

      <Card className='form-wrapper'>
        <div className='content-part d-flex flex-column'>
          <div className=' recall-date-box d-flex flex-column'>
            <div>
              <Text color='#6F7788' weight='400' size='12px' className='selected-date'> Doctor Name</Text>            
              <Text color='#102C42' weight='600' size='14px' className='selected-date'>Florian Smith </Text>  
            </div>
            <div className="c-field">
               <label>change Date</label>
               <div className="d-flex inputdate">
                  <ShowDate/>
               </div>                     
           </div>
         </div>

          <div className='book-slot d-flex flex-column'>
             <Text  color='#587E85' weight='600' size='16px' className='book-slot-heading'>Booking Timeslot</Text>
             <div className='d-flex flex-column'>
                <Text color='#87928D' weight='400' size='10px' className='time-zone'>Timezone</Text>
                <Text color='#2F3245' weight='500' size='12px' className='pst'>PST</Text>
             </div>
             <div>
                <Text color='#6F7788' weight='400' size='12px' className='timeslot-text'>Select timeslots and then choose if you want to block it.</Text>
             </div>

             <div className={styles['notify-checkbox'] +" "+" d-flex "}>
                <div className={styles['checkbox-container']+" "+" d-flex flex-row "}>
                  <div className={styles['show-checkedbox'] +" "+ styles["box1"]}></div>
                  Booked
                </div>
                <div className={styles['checkbox-container']+" "+" d-flex flex-row"}>
                  <div className={styles['show-checkedbox'] +" "+  styles['box2']}></div>
                  Available
                </div>
                <div className={styles['checkbox-container'] +" "+"d-flex flex-row"}>
                  <div className={styles['show-checkedbox'] +" "+ styles["box3"]}></div>
                  Blocked
                </div>
              </div>

             <div className='show-time-box d-flex flex-wrap'>
                {renderDivs()}
            </div>
          </div>

          <div className='btn-container d-flex'>
            <Link>
             <button  className='button button-round button-shadow w-100'>
             Reassign
             </button>
            </Link>
             
             <button className='button button-round button-border button-dark'>
             Cancel
             </button>
          </div>
        </div>
      </Card>

     
      
      </Page>
  )
}
